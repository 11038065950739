import React from "react";

export default function MiningConsultingServicesPage() {
  return (
    <div className="mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739278954/pexels-photo-4594023_ceogkk.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-10">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Mineral Exploration &amp; Mining Investment Facilitation
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Transforming the mining sector through innovation, strategy,
            and sustainable solutions.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 text-center">
        <h1 className="text-2xl font-bold text-gray-800">
          Mineral Exploration &amp; Mining Investment Facilitation
        </h1>
      </div>

      {/* Introduction */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
          Seismic Consulting Group: Advancing Mineral Exploration &amp; Mining Investments
        </h2>
        <p className="text-lg text-gray-700 mb-4">
          The mineral exploration and mining industries play a pivotal role in
          global economic development, providing the raw materials essential for
          numerous other sectors. Yet, these industries face a spectrum of
          challenges—ranging from regulatory hurdles and environmental
          considerations to complex investment and operational inefficiencies. At
          Seismic Consulting Group, we specialize in bridging these gaps, offering
          tailored solutions that drive both growth and sustainability.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Backed by deep expertise in strategic planning, resource assessment, and
          policy development, our mission is to guide you through every phase of
          exploration and mining—from identifying high-potential mineral deposits to
          facilitating investments and formulating robust policy frameworks. We’re
          committed to helping you realize maximum profitability and long-term
          sustainability in a rapidly evolving market.
        </p>
      </div>

      {/* Our Core Consulting Services */}
      <div className="py-16 px-4 lg:px-20">
        <div className="max-w-[1200px] mx-auto">
          <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
            Our Core Consulting Services
          </h2>
          <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
            Customized solutions designed to foster growth, enhance sustainability,
            and streamline operations throughout the mineral exploration and mining
            lifecycle.
          </p>

          {/* Service Cards */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Strategic Marketing Development */}
            <div className="border border-gray-200 shadow-md">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738233208/WhatsApp_Image_2025-01-29_at_17.25.43_n4qjof.jpg"
                alt="Strategic Marketing"
                className="w-full h-52 object-cover"
              />
              <div className="p-6">
                <h3 className="text-lg font-bold mb-2 text-purple-900">
                  Strategic Marketing Development
                </h3>
                <p className="text-gray-600 text-md leading-relaxed mb-4">
                  We craft targeted marketing strategies to promote mineral
                  exploration and attract investors. By identifying key
                  stakeholders and aligning marketing efforts with investment
                  facilitation initiatives, we enhance industry visibility and
                  drive sustainable growth.
                </p>
              </div>
            </div>

            {/* Topographic Mapping & Investment Facilitation */}
            <div className="border border-gray-200 shadow-md">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738233207/WhatsApp_Image_2025-01-29_at_17.25.41_2_yx6ns7.jpg"
                alt="Topographic Mapping"
                className="w-full h-52 object-cover"
              />
              <div className="p-6">
                <h3 className="text-lg font-bold mb-2 text-purple-900">
                  Topographic Mapping &amp; Investment Facilitation
                </h3>
                <p className="text-gray-600 text-md leading-relaxed mb-4">
                  Our advanced mapping techniques pinpoint mineral-rich zones,
                  enabling compelling investment proposals for prospective
                  investors. We develop structured approaches for investment
                  facilitation, helping mining companies secure the funding
                  they need for expansion.
                </p>
              </div>
            </div>

            {/* Geochemical Mapping & Resource Assessment */}
            <div className="border border-gray-200 shadow-md">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738233209/WhatsApp_Image_2025-01-29_at_17.25.44_yd4pr0.jpg"
                alt="Geochemical Analysis"
                className="w-full h-52 object-cover"
              />
              <div className="p-6">
                <h3 className="text-lg font-bold mb-2 text-purple-900">
                  Geochemical Mapping &amp; Resource Assessment
                </h3>
                <p className="text-gray-600 text-md leading-relaxed mb-4">
                  We conduct detailed analyses of soil, water, and rock samples
                  to determine economic viability for development—integrating
                  geochemical data into both investment and sustainability planning.
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
            {/* Development of Central Economic Corridors */}
            <div className="border border-gray-200 shadow-md">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739279035/pexels-photo-2101137_v0ivy4.jpg"
                alt="Central Economic Corridors"
                className="w-full h-52 object-cover"
              />
              <div className="p-6">
                <h3 className="text-lg font-bold mb-2 text-purple-900">
                  Development of Central Economic Corridors
                </h3>
                <p className="text-gray-600 text-md leading-relaxed mb-4">
                  We plan key routes connecting mineral markets and industrial
                  zones, establishing efficient export channels. By identifying
                  optimal placement of industries along high-sales routes,
                  operations become more streamlined and profitable.
                </p>
              </div>
            </div>

            {/* Formulation of Mineral Policy Frameworks */}
            <div className="border border-gray-200 shadow-md">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739279104/pexels-photo-2101140_qig1qg.jpg"
                alt="Mineral Policy Framework"
                className="w-full h-52 object-cover"
              />
              <div className="p-6">
                <h3 className="text-lg font-bold mb-2 text-purple-900">
                  Formulation of Mineral Policy Frameworks
                </h3>
                <p className="text-gray-600 text-md leading-relaxed mb-4">
                  Our team creates comprehensive mineral policy documents to
                  support sustainable mining development. By categorizing
                  mineral deposits and evaluating economic viability, we ensure
                  compliance with regulatory standards and best practices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How We Can Help */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col md:flex-row gap-12 items-center">
          {/* Text */}
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-medium mb-4">How We Can Help</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li className="mb-2">
                <strong>Data-Driven Decision Making:</strong> Leveraging
                topographic and geochemical mapping for actionable insights.
              </li>
              <li className="mb-2">
                <strong>Regulatory Compliance &amp; Policy Formulation:</strong>{" "}
                Developing frameworks that meet industry regulations and best
                practices.
              </li>
              <li className="mb-2">
                <strong>Sustainable Mining Practices:</strong> Embedding
                environmental considerations into resource extraction.
              </li>
              <li className="mb-2">
                <strong>Investment Facilitation:</strong> Connecting mining
                projects with key stakeholders, investors, and funding
                opportunities.
              </li>
              <li className="mb-2">
                <strong>Infrastructure &amp; Logistics:</strong> Designing
                central economic corridors for efficient transport and export
                channels.
              </li>
            </ul>
            <p className="text-gray-700">
              Whether you’re optimizing existing operations or venturing into
              new exploration projects, our comprehensive consulting services
              drive measurable impact and long-term success.
            </p>
          </div>

          {/* Image */}
          <div className="w-full md:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738233254/WhatsApp_Image_2025-01-29_at_17.25.40_1_tpfsrt.jpg"
              alt="Mining Consulting"
              className="w-full h-auto"
            />
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <div className="max-w-7xl mx-auto px-6 lg:px-12 mt-8 mb-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Why Choose Seismic Consulting Group?
        </h2>
        <ul className="list-disc list-inside text-gray-700 space-y-2">
          <li>
            <strong>Industry Expertise:</strong> Our team comprises seasoned
            professionals with a deep understanding of global mineral exploration
            and mining operations.
          </li>
          <li>
            <strong>Customized Solutions:</strong> Every engagement is
            tailored to your project’s unique requirements and strategic
            goals.
          </li>
          <li>
            <strong>Commitment to Sustainability:</strong> We prioritize
            responsible mining practices to safeguard the environment and
            foster community development.
          </li>
          <li>
            <strong>Proven Track Record:</strong> Our methodologies and
            strategic insights have contributed to the success of diverse
            mining projects worldwide.
          </li>
        </ul>
      </div>

                         {/*  Mineral Exploration Experts Section */}
                         <div className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
  <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
    Our Mineral Exploration and Minning Experts
  </h2>

  <div className="flex justify-center gap-16 flex-wrap">
    {[
    {
      name: "Prof. Samuel Olobaniyi",
      role: "Board Member, Mineral Exploration and Mining",
      imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739435925/bmxruumap7ykui61g8st.jpg",
    },
    {
      name: "Promise Ikpuri",
      role: "Head of Operations",
      imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739447842/WhatsApp_Image_2025-02-13_at_12.55.28_p35ctp.jpg",
    },
    ].map((expert, index) => (
      <div key={index} className="shadow-lg rounded-lg overflow-hidden text-left bg-white w-[280px]">
        <img src={expert.imageUrl} alt={expert.name} className="w-full h-[300px] object-cover" />
        <div className="p-4">
          <h3 className="text-xl font-bold text-gray-900">{expert.name}</h3>
          <p className="text-gray-600 mt-1">{expert.role}</p>
        </div>
      </div>
    ))}
  </div>
</div>
    </div>
  );
}

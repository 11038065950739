import React from "react";
import MethodologySectionCBCL from "./MethodologySectionCBCL";

const CapacityBuildingForChangeLeaders = () => {
  return (
    <div className="py-16">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736933370/8_1_q1oige.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
          <h1 className="text-3xl font-bold text-gray-800">
            Capacity Building for Change Leaders
          </h1>
        </div>
      </section>

      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Capacity Building for Change Leaders
        </h1>
      </div>

      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-28">
        {/* Context Section */}
        <div className="mb-16">
          <p className="text-gray-600 text-xl leading-relaxed">
          At the heart of any successful transformation is strong, visionary leadership. Through Capacity Building for Change Leaders, Seismic equips leaders with the tools, knowledge, and confidence needed to inspire, guide, and sustain change within their organizations. By empowering key decision-makers, we ensure that transformation initiatives are not only executed effectively but also embedded into the organizational culture for long-term success.
          </p>
        </div>
        {/* What We Do Section */}
        <div className="mb-12 pt-2">
          <h2 className="text-2xl font-bold text-center mb-1">What We Do</h2>
          <p className="text-gray-600 text-xl leading-relaxed mb-8">
            Equip leaders with the tools, knowledge, and confidence to guide
            their teams through change.
          </p>
          <ul className="list-none space-y-6 text-lg leading-relaxed">
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Understand Change Dynamics:</strong>
                Gain insights into the complexities of change management and the
                human factors that drive or resist transformation.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Develop Core Competencies:</strong>
                Enhance skills in communication, decision-making, and strategic
                thinking to lead with clarity and conviction.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Inspire Teams:</strong> Cultivate
                the ability to motivate and align teams around shared goals,
                ensuring a cohesive and productive response to change.
              </div>
            </li>
          </ul>
        </div>


        {/* Our Methodology Section */}
        <MethodologySectionCBCL />
        
      {/* Value to Clients Section */}
      <div className="mb-12">
        <h2 className="text-2xl font-bold mb-6 text-gray-900 text-center">
          Value to Clients
        </h2>
        <p className="text-gray-600 text-xl leading-relaxed mb-8 text-center">
          Stronger leadership capable of inspiring and driving sustained change.
          Seismic’s Capacity Building for Change Leaders delivers significant
          benefits that drive both immediate and sustained success:
        </p>
        <ul className="list-none space-y-8 text-lg leading-relaxed">
          <li className="flex items-start">
            <span
              className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
              style={{ backgroundColor: "#F9F1FA" }}
            ></span>
            <div>
              <strong className="text-xl text-gray-900">Stronger Leadership:</strong>
              <span className="text-gray-600 text-xl">
                {" "}
                Leaders gain the confidence and capability to navigate the complexities
                of change, making informed and effective decisions.
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <span
              className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
              style={{ backgroundColor: "#F9F1FA" }}
            ></span>
            <div>
              <strong className="text-xl text-gray-900">Increased Employee Engagement:</strong>
              <span className="text-gray-600 text-xl">
                {" "}
                Skilled leaders inspire trust and commitment, fostering a culture of
                collaboration and buy-in from employees at all levels.
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <span
              className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
              style={{ backgroundColor: "#F9F1FA" }}
            ></span>
            <div>
              <strong className="text-xl text-gray-900">Sustained Change:</strong>
              <span className="text-gray-600 text-xl">
                {" "}
                Equipped with the right tools and mindset, leaders ensure that
                transformation initiatives achieve their goals and are maintained over
                time.
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <span
              className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
              style={{ backgroundColor: "#F9F1FA" }}
            ></span>
            <div>
              <strong className="text-xl text-gray-900">Organizational Resilience:</strong>
              <span className="text-gray-600 text-xl">
                {" "}
                By investing in leadership capacity, organizations develop a strong
                foundation to weather future challenges and adapt to evolving
                environments.
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <span
              className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
              style={{ backgroundColor: "#F9F1FA" }}
            ></span>
            <div>
              <strong className="text-xl text-gray-900">Improved Outcomes:</strong>
              <span className="text-gray-600 text-xl">
                {" "}
                Effective leadership translates to better planning, execution, and
                monitoring of change initiatives, delivering measurable value to the
                organization.
              </span>
            </div>
          </li>
        </ul>
      </div>

      {/* Seismic’s Approach to Leadership Capacity Building */}
<section className="py-12 bg-white">
  <div className="max-w-6xl mx-auto px-6 lg:px-12">
    <h2 className="text-2xl font-bold mb-6 text-center text-gray-900">
      Seismic’s Approach to Leadership Capacity Building
    </h2>
    <p className="text-gray-600 text-xl leading-relaxed mb-8 text-center">
      Rooted in a deep understanding of organizational dynamics and the critical role of leadership in change.
    </p>
    <ul className="list-none space-y-8 text-lg leading-relaxed">
      <li className="flex items-start">
        <span
          className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
          style={{ backgroundColor: "#F9F1FA" }}
        ></span>
        <div>
          <strong className="text-xl text-gray-900">Tailored Solutions:</strong>
          <span className="text-gray-600 text-xl">
            {" "}Programs are customized to address the unique needs and challenges of each organization and its leaders.
          </span>
        </div>
      </li>
      <li className="flex items-start">
        <span
          className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
          style={{ backgroundColor: "#F9F1FA" }}
        ></span>
        <div>
          <strong className="text-xl text-gray-900">Expert Facilitators:</strong>
          <span className="text-gray-600 text-xl">
            {" "}Our team comprises seasoned consultants and coaches with extensive experience in change management and leadership development.
          </span>
        </div>
      </li>
      <li className="flex items-start">
        <span
          className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
          style={{ backgroundColor: "#F9F1FA" }}
        ></span>
        <div>
          <strong className="text-xl text-gray-900">Integrated Support:</strong>
          <span className="text-gray-600 text-xl">
            {" "}Beyond training, we offer ongoing mentorship, coaching, and resources to ensure sustained impact.
          </span>
        </div>
      </li>
    </ul>
  </div>
</section>


</div>



      {/* Seismic’s expertise in Capacity Building for Change Leaders */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-wrap lg:flex-nowrap gap-12 items-center">
          {/* Image Section */}
          <div className="flex-shrink-0 w-full lg:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736933370/8_1_q1oige.jpg"
              alt="Leadership Excellence"
              className="w-full"
            />
          </div>

          {/* Text Section */}
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl font-medium mb-6">
              Seismic’s Expertise in Capacity Building for Change Leaders
            </h2>
            <p className="text-gray-600 text-xl leading-relaxed mb-6">
              Through Capacity Building for Change Leaders, Seismic enables
              organizations to cultivate leadership excellence that drives and sustains
              transformation. Our approach ensures that leaders are not only equipped to
              manage change but also to inspire and empower their teams to embrace it.
            </p>
            <p className="text-gray-600 text-xl leading-relaxed">
              By fostering strong, adaptive leadership, we position organizations for
              long-term success in an ever-changing world.
            </p>
          </div>
        </div>
      </section>

      {/* Related Services Section */}
      <div className="max-w-8xl mx-auto px-6 lg:px-12 mt-8 lg:mt-12 ml-8">
        <h2 className="text-2xl font-bold mb-12 text-center">Related Services</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              title: "Change Management Frameworks",
              description:
                "Through robust Change Management Frameworks, we help businesses navigate transitions seamlessly, ensuring that people, processes, and systems adapt cohesively.",
              image:
                "https://res.cloudinary.com/dxhx45ing/image/upload/v1736933098/9_1_lgkpzt.jpg",
              link: "/consulting-services/change-management-page/change-management-frameworks",
            },
            {
              title: "Adaptive Strategy Development",
              description:
                "Central to Seismic's approach, this equips organizations with tools and insights to remain resilient, agile, and competitive in evolving environments.",
              image:
                "https://res.cloudinary.com/dxhx45ing/image/upload/v1736871131/4_pnllia.jpg",
              link: "/consulting-services/change-management-page/adaptive-strategy-development",
            },
            {
              title: "Digital Transformation",
              description:
                "Empowering organizations to embrace digital innovation, streamline operations, and enhance customer experiences.",
              image:
                "https://res.cloudinary.com/dxhx45ing/image/upload/v1736932864/5_1_ldv5s1.jpg",
              link: "/consulting-services/change-management-page/digital-transformation",
            },
          ].map((item, index) => (
            <a
              key={index}
              href={item.link}
              className="block text-left hover:shadow-lg transition-shadow duration-300"
            >
              <img
                src={item.image}
                alt={item.title}
                className="mb-4 w-full h-[300px] object-cover"
              />
              <h3 className="text-lg font-bold mb-2">{item.title}</h3>
              <p className="text-gray-600 text-sm mb-2">{item.description}</p>
              <span className="text-purple-900 font-bold text-sm">Read More</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CapacityBuildingForChangeLeaders;

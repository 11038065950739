import React from "react";

const Implementation = () => {
  return (
    <div className="bg-white">
      {/* Header Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1440x516')", // Replace this placeholder with the actual image URL
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white max-w-3xl text-center z-20">
          <h1 className="text-3xl font-bold text-gray-800">
            Implementation: Delivery of Engaging, Interactive, and Hands-On Training Sessions
          </h1>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Implementation: Delivery of Engaging, Interactive, and Hands-On Training Sessions
        </h1>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 py-12">
        {/* Introduction */}
        <div className="mb-12">
          <p className="text-lg text-gray-600 leading-relaxed mt-12">
            In Seismic’s Capacity Building & Human Resource Development methodology, Implementation is where the designed training programs come to life. Seismic focuses on delivering training that is not only engaging and interactive but also hands-on, ensuring that the content is effectively absorbed and applicable to real-world scenarios. The emphasis on active learning, along with the integration of technology, guarantees that participants are equipped with both the knowledge and skills they need to excel. Here's how Seismic applies this approach:
          </p>
        </div>

        {/* Delivery Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Delivery of Engaging, Interactive, and Hands-On Training Sessions
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed">
            Seismic believes that the effectiveness of training hinges on the engagement and interaction of participants throughout the learning process. Instead of traditional, lecture-based formats, Seismic utilizes a combination of active learning techniques to keep participants fully involved and invested in their learning. Key elements of this approach include:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Interactive Learning:</strong> Seismic prioritizes interactive sessions that encourage participants to actively engage with the material. This could include group discussions, role-playing, case studies, live problem-solving sessions, and Q&A sessions. These activities help participants make real-time connections between theory and practice, deepening their understanding of the content and improving retention.
            </li>
            <li>
              <strong>Hands-On Exercises:</strong> Hands-on learning is essential to practical application. Seismic integrates simulation-based activities where learners can apply their new skills in controlled, real-world scenarios. These exercises help learners experience situations similar to what they would encounter in their roles, allowing them to practice decision-making, problem-solving, and other core competencies. For instance, training in leadership might include interactive scenarios where participants must manage a team or navigate a complex organizational issue.
            </li>
            <li>
              <strong>Collaborative Learning:</strong> Seismic fosters a collaborative environment where participants can learn from each other’s experiences and insights. By using team-based activities, participants not only improve their individual skills but also develop the ability to collaborate effectively, which is a critical skill in today’s dynamic work environments. Peer feedback, brainstorming sessions, and team problem-solving help enhance participants' perspectives and enrich their learning experience.
            </li>
            <li>
              <strong>Real-World Contexts:</strong> Training is designed to be as contextual and relevant as possible. Whether participants are learning technical skills, leadership strategies, or communication tactics, the content is directly tied to the specific challenges they face in their roles. Seismic often uses industry-specific examples, real-world case studies, and local market dynamics to ensure the content is relatable and practical. This helps bridge the gap between theoretical knowledge and practical application.
            </li>
          </ul>
        </div>

        {/* Integration of Technology */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Integration of Technology to Enhance Learning Outcomes
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed">
            Seismic recognizes that technology is a powerful tool in enhancing the learning experience and improving training outcomes. By integrating digital solutions, Seismic ensures that training is not only accessible but also engaging and interactive. This approach includes a range of technological tools designed to support various learning styles and preferences. Key elements of this approach include:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>E-Learning Platforms:</strong> Seismic leverages e-learning platforms to provide participants with flexible, self-paced learning opportunities. These platforms can host online courses, webinars, and virtual workshops, making training more accessible for global teams or remote participants. E-learning modules can be used to deliver foundational knowledge, which is then reinforced through interactive live sessions.
            </li>
            <li>
              <strong>Virtual Simulations and Games:</strong> To make training more immersive, Seismic uses virtual simulations and gamification techniques. These tools create engaging, game-like environments where participants can practice skills in a safe, risk-free setting. For example, a leadership training session might include a simulation where participants must lead a virtual team through various challenges, making decisions that affect the outcome. This type of immersive learning helps to build confidence and provides immediate feedback.
            </li>
            <li>
              <strong>Mobile Learning:</strong> Seismic recognizes the need for mobile learning solutions that allow participants to engage with training materials anytime and anywhere. By utilizing mobile-compatible platforms and apps, participants can access learning content on their smartphones or tablets, enabling continuous learning even when they are on the go. This flexibility is especially important for organizations with a mobile workforce or those operating across multiple time zones.
            </li>
            <li>
              <strong>Data Analytics and Learning Management Systems (LMS):</strong> Seismic uses LMS tools to track and analyze participants' progress, engagement, and performance throughout the training program. These systems allow trainers to monitor the effectiveness of the sessions, identify areas where learners may need additional support, and adjust the content or delivery style accordingly. Data-driven insights from these systems enable Seismic to continually refine and optimize training programs to ensure the best possible outcomes.
            </li>
            <li>
              <strong>Interactive Digital Tools:</strong> To keep the sessions dynamic and engaging, Seismic incorporates interactive tools such as virtual whiteboards, live polling, and real-time quizzes during online sessions. These tools enable real-time feedback and help keep participants actively engaged. Polls and quizzes can also serve as a way to assess understanding and adapt the training content based on participants’ responses.
            </li>
            <li>
              <strong>Blended Learning Approaches:</strong> Seismic integrates both online and in-person learning experiences, creating a blended learning model that offers the best of both worlds. For example, participants may complete foundational e-learning modules before attending an in-person workshop that focuses on applying those skills through interactive exercises and real-time collaboration. Blended learning not only accommodates various learning preferences but also enhances learning retention by reinforcing concepts through multiple delivery methods.
            </li>
          </ul>
        </div>

        {/* Continuous Engagement */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Continuous Engagement and Feedback
          </h2>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Real-Time Feedback:</strong> During sessions, trainers provide real-time feedback to participants, helping them understand areas for improvement and reinforcing their strengths. This immediate feedback encourages active learning and empowers participants to make adjustments and improvements during the training itself.
            </li>
            <li>
              <strong>Post-Training Support:</strong> Seismic recognizes that learning doesn’t end once the training session concludes. To reinforce skills and ensure lasting impact, Seismic offers post-training support, such as coaching, mentoring, and follow-up sessions. This continued engagement ensures that learners have the resources and guidance they need to apply their newfound skills in their work environments effectively.
            </li>
            <li>
              <strong>Participant-Centered Approach:</strong> Seismic tailors the delivery style based on participant preferences and needs. By using a mix of delivery methods such as live sessions, pre-recorded content, interactive exercises, and peer discussions, Seismic ensures that every participant’s learning experience is personalized, ensuring maximum impact.
            </li>
          </ul>
        </div>
        {/* Final Overview Section */}
<div className="bg-white py-12">
  {/* Top Border */}
  <hr className="border-gray-300" />
  <div className="max-w-8xl mx-auto flex flex-col lg:flex-row items-center gap-8 py-8 px-6 lg:px-12">
    {/* Image */}
    <div className="lg:w-6/12 w-full">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738674455/WhatsApp_Image_2025-02-04_at_14.07.03_cnu79c.jpg" // Replace with relevant image URL
        alt="Implementation Phase"
        className="rounded-lg shadow-lg"
      />
    </div>

    {/* Text Content */}
    <div className="lg:w-6/12 w-full">
      <h3 className="text-2xl font-bold text-gray-800 mb-4">
        Ensuring Maximum Impact in Implementation
      </h3>
      <p className="text-lg text-gray-600 leading-relaxed">
        The Implementation phase of Seismic’s approach to Capacity Building & Human Resource Development emphasizes interactive, engaging, and hands-on training, ensuring that participants not only absorb new knowledge but also have the opportunity to practice and refine their skills. By integrating cutting-edge technology—from e-learning platforms to virtual simulations—Seismic ensures that training is dynamic, accessible, and impactful. This combination of interactive learning and technological integration drives improved performance, enhanced skills application, and, ultimately, organizational success.
      </p>
    </div>
  </div>
  {/* Bottom Border */}
  <hr className="border-gray-300" />
</div>

      </div>

    </div>
  );
};

export default Implementation;

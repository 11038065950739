import React from "react";

const DataDrivenTargeting = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="bg-white pb-16 mt-20">
        <section
          className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736851385/RESIZED_IOE-Masters_-Data-Analytics-and-Applied-Statistics_q7c8cw.jpg')", // Replace with your actual image URL
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          {/* Title Overlay for Large Screens */}
          <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
            <h1 className="text-3xl font-bold text-gray-800">
              Data-Driven Targeting and Predictive Analytics
            </h1>
          </div>
        </section>

        {/* Title Under Image for Small Screens */}
        <div className="block lg:hidden mt-4">
          <h1 className="text-2xl font-bold text-gray-800 text-center">
            Data-Driven Targeting and Predictive Analytics
          </h1>
        </div>
      </section>

      {/* Context Section */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed mb-6">
          Seismic employs big data and predictive analytics to identify and reach the
          most vulnerable populations efficiently. By using advanced algorithms and data
          insights, we ensure that resources are directed to those who need them most,
          optimizing impact and reducing waste.
        </p>
        <p className="text-xl text-gray-600 leading-relaxed">
          In the modern landscape of social protection and poverty reduction, traditional
          methods of identifying and reaching vulnerable populations can be inefficient,
          often leading to misallocation of resources. At Seismic Consulting Group, we
          harness the power of big data and predictive analytics to optimize the targeting
          of social programs and maximize their impact. This data-driven approach enables
          us to reach those who need support the most, while ensuring resources are used
          efficiently and effectively.
        </p>
      </section>

      {/* How We Execute Section */}
      <section className="py-8 px-8 lg:px-64">
        <h2 className="text-2xl lg:text-3xl font-medium text-gray-800 mb-6">
          Here's how Seismic applies this strategy:
        </h2>
        <div className="space-y-10">
          {/* Advanced Data Collection */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Advanced Data Collection
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              We begin by collecting comprehensive data from a variety of sources,
              including demographic surveys, social indicators, geographic information
              systems (GIS), and real-time data from mobile phones, social media, and
              other digital platforms. This allows us to capture detailed, up-to-date
              information on community needs, socioeconomic conditions, and local
              vulnerabilities.
            </p>
          </div>

          {/* Predictive Modeling and Algorithms */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Predictive Modeling and Algorithms
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Using advanced algorithms and machine learning techniques, Seismic analyzes
              the collected data to predict the future needs of vulnerable populations.
              Predictive analytics allows us to identify individuals or communities that
              may be at risk of falling into poverty or experiencing increased vulnerability
              in the near future. By anticipating these challenges, we can intervene
              proactively, preventing deeper crises and providing timely support.
            </p>
          </div>

          {/* Targeted Resource Allocation */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Targeted Resource Allocation
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              With data-driven insights, Seismic ensures that resources are allocated where
              they will have the most impact. By using predictive models to identify areas
              of highest need—whether it's access to healthcare, education, housing, or
              employment opportunities—we can direct interventions more effectively. This
              approach minimizes waste, ensuring that social protection resources are
              concentrated on those most in need.
            </p>
          </div>

          {/* Geospatial Analysis for Localized Interventions */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Geospatial Analysis for Localized Interventions
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Using geospatial data and GIS tools, Seismic can identify geographic areas
              with the highest concentrations of vulnerability. This allows us to tailor
              interventions to specific locations, whether rural or urban, and to design
              localized programs that address the unique challenges of each community. By
              doing so, we ensure that no region or population is left behind.
            </p>
          </div>

          {/* Real-Time Monitoring and Adjustment */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Real-Time Monitoring and Adjustment
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Seismic's use of data extends beyond initial targeting. We employ real-time
              monitoring systems that track the effectiveness of social programs as they
              unfold. This allows us to adapt and adjust strategies in response to emerging
              trends, shifting demographics, or changing local conditions. By continuously
              refining our approach, we ensure that social protection efforts remain
              relevant and impactful.
            </p>
          </div>

          {/* Integration of Data Across Sectors */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Integration of Data Across Sectors
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              A key strength of Seismic's data-driven approach is the integration of data
              across different sectors. By combining data from health, education,
              employment, and social services, we gain a holistic view of a community's
              needs. This integrated data approach enables us to design more comprehensive
              interventions that address the complex, interconnected challenges faced by
              vulnerable populations.
            </p>
          </div>

          {/* Transparency and Accountability */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Transparency and Accountability
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Our data-driven approach also supports transparency and accountability. By
              using clear metrics and evidence-based decision-making, we can demonstrate
              the effectiveness of our interventions to stakeholders, including governments,
              donors, and the communities we serve. This fosters trust and encourages
              continued investment in social protection initiatives.
            </p>
          </div>
          {/* Optimizing Impact and Reducing Waste*/}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
            Optimizing Impact and Reducing Waste
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
            Optimizing Impact and Reducing: Waste
The ultimate goal of leveraging big data
and predictive analytics is to reduce
inefficiencies and ensure that resources
are used where they are most needed. By
precisely targeting vulnerable populations,
we minimize waste—whether in terms of
time, money, or resources—and maximize
the return on investment in social
protection programs.
            </p>
          </div>
        </div>
      </section>

      {/* Conclusion */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed">
          By utilizing data-driven targeting and predictive analytics, Seismic ensures that
          our social protection programs are not only efficient but also highly effective.
          This approach allows us to reach the right people at the right time, deliver the
          right services, and adapt interventions as needed, ultimately driving greater
          social impact and contributing to long-term poverty reduction.
        </p>
      </section>

            {/* Seismic's Approach Section */}
<section className="py-16 px-4 lg:px-20">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Seismic's Approach to Reducing Poverty
    </h2>
    <p className="text-lg text-gray-600 text-center mb-10">
      Our approach combines rigorous analysis with creative problem-solving
      to address the root causes of poverty and inequality.
    </p>

    {/* Row 1 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 1 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851836/pexels-tima-miroshnichenko-6474494_nbhrkk.jpg"
          alt="Comprehensive Needs Assessment"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2">
              Comprehensive Needs Assessment and Analysis
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              By analyzing demographic data, economic trends, and social indicators,
              we tailor our social protection strategies to address root causes of
              poverty specific to each region.
            </p>
          </div>
          <a
            href="/consulting-services/social-protection/comprehensive-needs-assessment"
            className="text-purple-700 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>

      {/* Box 2 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Integrated Social Protection Frameworks
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            We create holistic social protection frameworks that integrate safety
            nets, job creation initiatives, and targeted social programs. Our
            frameworks combine interventions into a cohesive support system.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/integrated-social-protection"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>


    {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Community Empowerment and Capacity Building
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Sustainable poverty reduction requires building the capacity of
            communities to manage and lead their own social protection initiatives.
            Seismic provides training, resources, and guidance to empower local
            leaders and organizations, fostering a culture of self-reliance and
            social equity.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/community-empowerment"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Monitoring, Evaluation, and Adaptive Learning
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Seismic uses adaptive learning to refine and improve social protection
            programs over time, ensuring that each initiative remains responsive to
            evolving community needs and delivers measurable results.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/monitoring-evaluation"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default DataDrivenTargeting;

import React from "react";

function DigitalMarketing() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Digital Marketing and Brand Transformation
          </h1>
        </div>
      </section>

      {/* Intro Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            In the rapidly evolving digital landscape, establishing a strong online presence is crucial for brand
            visibility and customer engagement. At Seismic Consulting Group, we specialize in transforming brands through
            innovative digital marketing strategies that resonate with target audiences and drive measurable results.
          </p>
        </div>
      </section>

      {/* Personalized and Targeted Marketing */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Personalized and Targeted Marketing</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Leveraging advanced Artificial Intelligence (AI) and data analytics, we help organizations understand and
              anticipate customer behaviors and preferences. By analyzing customer data, we develop highly personalized
              and targeted marketing campaigns that deliver relevant content to the right audience at the right time.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Data-Driven Insights:</strong> We collect and interpret complex datasets to uncover actionable insights, enabling precise audience segmentation and personalized messaging.</li>
              <li><strong>AI-Powered Campaigns:</strong> Our AI algorithms optimize marketing efforts by predicting customer responses and adjusting strategies in real-time for maximum impact.</li>
              <li><strong>Enhanced Customer Engagement:</strong> Personalized marketing fosters deeper connections with customers, increasing engagement rates and fostering brand loyalty.</li>
            </ul>
            <p className="text-gray-700 mt-6">
              Example: For a retail client, Seismic implemented an AI-driven marketing platform that increased customer
              engagement by 35% and boosted conversion rates through tailored promotions and product recommendations.
            </p>
          </div>
        </div>
      </section>

      {/* Social Media and Content Strategy */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Social Media and Content Strategy</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Seismic helps clients harness the power of digital channels to amplify their brand message, enhance reputation,
              and expand reach.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Strategic Planning:</strong> We develop comprehensive social media strategies aligned with your business goals, identifying the most effective platforms and content types for your target audience.</li>
              <li><strong>Content Creation and Management:</strong> Our team crafts compelling content that reflects your brand identity and engages users, including blogs, videos, infographics, and interactive media.</li>
              <li><strong>Community Building:</strong> We foster active online communities through consistent engagement, responding to feedback, and encouraging user-generated content to build trust and loyalty.</li>
            </ul>
            <p className="text-gray-700 mt-6">
              Example: A technology firm partnered with Seismic to revitalize their social media presence, resulting in a 50% increase
              in followers and a significant uptick in website traffic driven by engaging content and targeted campaigns.
            </p>
          </div>
        </div>
      </section>

      {/* SEO and SEM */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">SEO and SEM</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Visibility is key in the digital marketplace. Seismic develops robust Search Engine Optimization (SEO) and
              Search Engine Marketing (SEM) strategies to enhance your online presence and attract high-quality traffic.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>SEO Strategy Development:</strong> We optimize website structure, content, and technical elements to improve organic search rankings and visibility.</li>
              <li><strong>Keyword Research and Optimization:</strong> Our team identifies high-value keywords relevant to your industry and integrates them seamlessly into your content.</li>
              <li><strong>SEM Campaigns:</strong> We design and manage pay-per-click (PPC) advertising campaigns that target specific demographics, maximizing ROI through continuous monitoring and optimization.</li>
            </ul>
            <p className="text-gray-700 mt-6">
              Example: Seismic's SEO and SEM efforts for an e-commerce client led to a 40% increase in organic traffic and a 25% rise in
              online sales within six months.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DigitalMarketing;

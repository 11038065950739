import React from "react";

const NeedsAssessment = () => {
  return (
    <div className="bg-white">

      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736851836/pexels-tima-miroshnichenko-6474494_nbhrkk.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div
          className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
        >
          <h1 className="text-3xl font-bold text-gray-800">
          Needs Assessment
          </h1>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Needs Assessment
        </h1>
      </div>


      {/* Content Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 py-12">
        {/* Context Section */}
        <div className="mb-12">
          <p className="text-lg text-gray-600 leading-relaxed mt-12">
            As part of Seismic’s approach to Capacity Building & Human Resource
            Development, the Needs Assessment is a critical first step in
            understanding the current state of an organization’s human capital
            and identifying areas for improvement. This in-depth evaluation
            helps Seismic tailor its capacity-building initiatives to the
            specific needs, challenges, and strategic goals of the organization.
            Here’s how Seismic conducts a thorough needs assessment to inform
            effective capacity-building strategies.
          </p>
        </div>

        {/* Comprehensive Evaluation Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Comprehensive Evaluation of Organizational Needs and Challenges
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed mb-6">
            Seismic begins by conducting a holistic evaluation of the
            organization, considering its structure, culture, and long-term
            goals. This assessment involves multiple layers of analysis:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Organizational Health Review:</strong> Seismic assesses
              the overall health of the organization, focusing on areas such as
              leadership effectiveness, communication flow, decision-making
              processes, and organizational culture. This review identifies
              bottlenecks or inefficiencies that may hinder the organization’s
              progress and affect its ability to meet objectives.
            </li>
            <li>
              <strong>Strategic Alignment:</strong> Strategic Alignment: We evaluate the alignment between the organization's human resources and its strategic goals. This ensures that the workforce is not only capable but also aligned with the mission and vision of the organization. A misalignment often leads to reduced productivity and dissatisfaction, so our goal is to optimize alignment to drive better outcomes. Internal and External Challenges: Seismic identifies both internal and external challenges the organization faces.
            </li>
            <li>
              <strong>Internal and External Challenges:</strong> Seismic
              identifies both internal and external challenges the organization
              faces. By understanding these challenges, Seismic can design
              tailored interventions to address them directly.
            </li>
          </ul>
        </div>

        {/* Skill Gaps Section */}
        <div>
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Identification of Skill Gaps and Capability Requirements
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed mb-6">
            The second part of the needs assessment focuses specifically on
            skill gaps and the capability requirements necessary for the
            organization to thrive. This includes:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Skill Inventory:</strong> Seismic conducts a detailed inventory of the existing skills and competencies within the organization. This involves reviewing job descriptions, conducting employee assessments, and analyzing performance metrics to understand what skills are available internally. Through this process, we can pinpoint areas where the workforce excels and areas that require development or enhancement.
            </li>
            <li>
              <strong>Future Skills Forecasting:</strong>We work with organizations to anticipate future needs. This forecasting process involves looking at industry trends, technological advancements, and evolving job roles to identify emerging skills that will be required in the near future. By staying ahead of the curve, Seismic helps organizations future-proof their human capital.
            </li>
            <li>
              <strong>Employee Feedback:</strong>Through surveys, focus groups, and one-on-one interviews with employees at all levels, Seismic gathers valuable insights into the perceived skill gaps from the perspective of those who are directly involved in day-to-day operations. Employees often identify areas for improvement that may not be immediately apparent from a management or leadership viewpoint.
            </li>
            <li>
              <strong>Benchmarking and Best Practices:</strong>Seismic uses industry benchmarks and global best practices to assess the competency levels within the organization. By comparing the organization’s capabilities to those of leading organizations in the same industry or sector, we can identify areas where improvements are needed to remain competitive.
            </li>
          </ul>
        </div>


        {/* Strategic Development Section */}
        <div className="mb-12 mt-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Strategic Development of Capacity Building Initiatives
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed mb-6">
            With a comprehensive understanding of the organization’s needs and
            challenges, Seismic moves to design tailored capacity-building
            programs that focus on closing the identified skill gaps and
            enhancing overall organizational capabilities. These initiatives are
            crafted to support the organization’s strategic goals, aligning them
            with specific skill-building activities to drive meaningful
            improvement.
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Leadership Development:</strong> Seismic helps
              organizations build strong leadership pipelines by identifying and
              nurturing high-potential leaders. This includes leadership
              training programs, mentorship, and succession planning to ensure
              the organization is prepared for future challenges.
            </li>
            <li>
              <strong>Customized Training Programs:</strong> Based on the
              results of the needs assessment, Seismic designs customized
              training programs that target specific skill gaps within the
              workforce. This can include technical skills, soft skills (such
              as communication, problem-solving, and team collaboration), and
              management competencies.
            </li>
            <li>
              <strong>Continuous Learning Systems:</strong> Seismic encourages
              a continuous learning culture within organizations by
              implementing systems that foster ongoing development. This
              includes creating access to e-learning platforms, internal
              knowledge-sharing networks, and peer learning groups that promote
              skill-building beyond formal training sessions.
            </li>
          </ul>
        </div>

        {/* Monitoring and Adjustments Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Ongoing Monitoring and Adjustments
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed mb-6">
            As part of the needs assessment process, Seismic establishes a
            framework for ongoing monitoring to track the effectiveness of
            capacity-building initiatives. This includes:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Tracking Skill Progress:</strong> We regularly assess
              employee progress in acquiring new skills through follow-up
              evaluations, assessments, and performance reviews. This helps
              identify areas that need additional focus or modification.
            </li>
            <li>
              <strong>Feedback Loops:</strong> Seismic establishes feedback
              loops to understand how the newly developed capabilities are
              being applied in real-world settings. We gather insights from
              both employees and leadership to assess the practical impact of
              the capacity-building initiatives and make adjustments as needed.
            </li>
            <li>
              <strong>Iterative Learning:</strong> Based on the feedback and
              the data collected, Seismic continuously refines and adapts
              capacity-building programs, ensuring they remain relevant and
              effective in addressing the organization’s evolving needs. Co-creation of training programs aligned with strategic goals.
            </li>
          </ul>
        </div>

        {/* Final Overview Section */}
        <div className="bg-white py-12">
          {/* Top Border */}
          <hr className="border-gray-300" />
          
          <div className="max-w-8xl mx-auto flex flex-col lg:flex-row items-center gap-8 py-8 px-6 lg:px-12">
            {/* Image */}
            <div className="lg:w-6/12 w-full">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738139815/WhatsApp_Image_2025-01-28_at_14.48.17_1_jqzvlw.jpg" // Replace with actual image URL
                alt="Seismic Needs Assessment"
                className="shadow-lg h-[450px] w-[400px]"
              />
            </div>

            {/* Text Content */}
            <div className="lg:w-6/12 w-full">
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                Seismic’s Approach to Needs Assessment
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Seismic ensures that capacity-building efforts are data-driven,
                aligned with organizational priorities, and responsive to future
                challenges. By comprehensively evaluating an organization's needs
                and identifying skill gaps, Seismic delivers targeted, strategic
                interventions that strengthen the workforce and enhance the
                organization’s overall performance. This results in a more capable,
                agile, and future-ready organization that is equipped to succeed in
                an ever-changing landscape.
              </p>
            </div>
          </div>

          {/* Bottom Border */}
          <hr className="border-gray-300" />
        </div>



      </div>
   
    </div>
  );
};

export default NeedsAssessment;

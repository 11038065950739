import React from "react";

function GRCTransformationPage() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Governance, Risk, and Compliance (GRC) Transformation
          </h1>
        </div>
      </section>

      {/* Intro Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            In an increasingly complex regulatory landscape, effective governance, risk management,
            and compliance are essential to sustaining organizational success and integrity. At Seismic Consulting
            Group, we specialize in transforming GRC frameworks to enhance accountability, mitigate risks, and ensure
            adherence to industry regulations and global standards. Our comprehensive approach integrates advanced
            technologies with strategic expertise to fortify your organization's resilience and competitive edge.
          </p>
        </div>
      </section>

      {/* Automated Compliance Tracking */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Automated Compliance Tracking</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Seismic implements cutting-edge tools that streamline compliance monitoring across all levels of
              your organization. By automating the tracking of regulatory requirements, we reduce the risk of
              non-compliance, fines, and reputational damage. Our solutions provide real-time visibility into
              compliance status, enabling proactive management and swift response to regulatory changes.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Regulatory Compliance Solutions:</strong> We deploy software systems that continuously monitor relevant laws and regulations, ensuring your policies and procedures remain up-to-date.</li>
              <li><strong>Custom Compliance Dashboards:</strong> Our customizable dashboards offer insights into compliance metrics, facilitating transparent reporting to stakeholders and regulatory bodies.</li>
              <li><strong>Workflow Automation:</strong> We automate compliance-related tasks and documentation, reducing manual effort and minimizing errors.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Enterprise Risk Management */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Enterprise Risk Management</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Understanding and managing risk is essential for strategic decision-making and long-term success.
              Seismic leverages digital solutions to analyze potential risks, predict disruptions, and develop
              robust response strategies.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Risk Assessment and Analysis:</strong> Utilizing advanced analytics and AI, we identify and evaluate risks across your enterprise, from operational to strategic levels.</li>
              <li><strong>Predictive Modeling:</strong> Our tools forecast potential disruptions, allowing you to anticipate challenges and mitigate their impact proactively.</li>
              <li><strong>Integrated Risk Frameworks:</strong> We develop cohesive risk management frameworks that align with your organizational objectives and industry best practices.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Cyber Governance and Data Protection */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Cyber Governance and Data Protection</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              In an era where cyber threats are increasingly sophisticated, safeguarding sensitive data and
              ensuring cyber resilience are paramount. Seismic establishes comprehensive cyber governance frameworks
              that secure your digital assets, monitor vulnerabilities, and ensure compliance with global standards
              such as GDPR, HIPAA, and ISO 27001.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Cybersecurity Strategy Development:</strong> We craft tailored cybersecurity strategies that address your unique risks and regulatory requirements.</li>
              <li><strong>Vulnerability Management:</strong> Our continuous monitoring solutions detect and address security weaknesses before they can be exploited.</li>
              <li><strong>Incident Response Planning:</strong> We help you develop and implement effective incident response plans to minimize the impact of security breaches.</li>
              <li><strong>Compliance Assurance:</strong> Seismic ensures your data protection practices meet or exceed global regulatory standards, reducing legal risks and enhancing stakeholder trust.</li>
            </ul>
          </div>
        </div>
      </section>

    </div>
  );
}

export default GRCTransformationPage;

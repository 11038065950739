import React from "react";

const IntegratedSocialProtection = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="bg-white pb-16 mt-28">
        <section
          className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736848539/p4h_social-protection-health-wellbeing_2024.x23411_wbpxdj.png')", // Replace with your actual image URL
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          {/* Title Overlay for Large Screens */}
          <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
            <h1 className="text-3xl font-bold text-gray-800">
              Integrated Social Protection Frameworks
            </h1>
          </div>
        </section>

        {/* Title Under Image for Small Screens */}
        <div className="block lg:hidden mt-4">
          <h1 className="text-2xl font-bold text-gray-800 text-center">
            Integrated Social Protection Frameworks
          </h1>
        </div>
      </section>

      {/* Context Section */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed mb-6">
          We create holistic social protection frameworks that integrate safety
          nets, job creation initiatives, and targeted social programs. This
          approach ensures that communities receive a network of support that
          addresses immediate needs, fosters employment opportunities, and
          promotes long-term self-sufficiency.
        </p>
        <p className="text-xl text-gray-600 leading-relaxed">
          At Seismic Consulting Group, we recognize that addressing poverty and
          enhancing social protection requires more than isolated initiatives.
          It demands a unified, multifaceted strategy that tackles immediate
          challenges while laying the groundwork for long-term community
          resilience and self-sufficiency. Our Integrated Social Protection
          Frameworks achieve this by combining various interventions into a
          cohesive support system.
        </p>
      </section>

      {/* How We Execute Section */}
      <section className="py-8 px-8 lg:px-64">
        <h2 className="text-2xl lg:text-3xl font-medium text-gray-800 mb-6">
          Here's how we execute this critical phase:
        </h2>
        <div className="space-y-10">
          {/* Safety Nets for Immediate Relief */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Safety Nets for Immediate Relief
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              We prioritize establishing safety nets such as cash transfers,
              food assistance, and healthcare subsidies to alleviate the
              immediate hardships faced by vulnerable populations. These
              interventions provide a crucial lifeline, ensuring that basic
              needs like nutrition, health, and shelter are met while reducing
              the risk of further destabilization.
            </p>
          </div>

          {/* Job Creation and Livelihood Development */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Job Creation and Livelihood Development
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Beyond immediate relief, we focus on creating pathways to economic
              independence. Our frameworks include job creation programs,
              vocational training, and microenterprise support tailored to the
              local economy. These initiatives equip individuals with the skills
              and opportunities needed to secure sustainable incomes and break
              the cycle of poverty.
            </p>
          </div>

          {/* Targeted Social Programs */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Targeted Social Programs
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Recognizing that every community has unique needs, we design and
              implement targeted programs addressing specific vulnerabilities.
              These may include initiatives for improving education access,
              gender equity, child protection, or support for persons with
              disabilities. By addressing these gaps, we ensure no segment of
              the population is left behind.
            </p>
          </div>

          {/* Synergy and Coordination */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Synergy and Coordination
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Our integrated approach ensures that these elements work in
              harmony rather than in isolation. For instance, vocational
              training programs are often paired with microfinance
              opportunities, and healthcare subsidies are complemented by public
              health awareness campaigns. This synergy amplifies the impact of
              individual initiatives, creating a robust support network.
            </p>
          </div>

          {/* Promoting Self-Sufficiency */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Promoting Self-Sufficiency
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              The ultimate goal of our integrated frameworks is to empower
              communities to achieve self-sufficiency. By equipping individuals
              with resources, skills, and opportunities, we reduce dependency on
              external aid and foster resilience against future challenges.
            </p>
          </div>

          {/* Monitoring and Adaptation */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Monitoring and Adaptation
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Our frameworks are dynamic, incorporating regular monitoring and
              feedback to refine and adapt programs based on their
              effectiveness. This ensures that our solutions remain relevant and
              impactful as community needs evolve.
            </p>
          </div>
        </div>
      </section>

      {/* Conclusion */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed">
          Seismic's Integrated Social Protection Frameworks deliver a
          comprehensive and inclusive approach to poverty reduction. By
          addressing both immediate needs and systemic barriers, we help
          communities transition from vulnerability to stability and prosperity,
          creating sustainable pathways out of poverty.
        </p>
      </section>

      {/* Seismic's Approach Section */}
      <section className="py-16 px-4 lg:px-20">
        <div className="max-w-[1200px] mx-auto">
          <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
            Seismic's Approach to Reducing Poverty
          </h2>
          <p className="text-lg text-gray-600 text-center mb-10">
            Our approach combines rigorous analysis with creative
            problem-solving to address the root causes of poverty and
            inequality.
          </p>

          {/* Row 1 */}
          <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
            {/* Box 1 - Text Only */}
            <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
              <div>
                <h3 className="text-lg font-bold mb-2">
                  Innovative Financing Mechanisms
                </h3>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Recognizing the financial challenges in funding large-scale
                  social protection programs, Seismic leverages innovative
                  financing models such as social impact bonds, micro-loans, and
                  public-private partnerships.
                </p>
              </div>
              <a
                href="/consulting-services/social-protection/innovative-financing-mechanisms"
                className="text-purple-700 font-bold text-md flex items-center mt-2"
              >
                Learn More
                <svg
                  className="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>

            {/* Box 2 - Image and Text */}
            <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851169/pexels-weekendplayer-187041_voljsh.jpg"
                alt="Innovative Financing Mechanisms"
                className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
              />
              <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
                <div>
                  <h3 className="text-lg font-bold mb-1">
                    Data-Driven Targeting and Predictive Analytics
                  </h3>
                  <p className="text-gray-600 text-md leading-relaxed">
                    In the modern landscape of social protection and poverty
                    reduction, traditional methods identifying and reaching
                    vulnerable populations can be inefficient, often leading to
                    misallocation of resources.
                  </p>
                </div>
                <a
                  href="/consulting-services/social-protection/data-driven-targeting"
                  className="text-purple-700 font-bold text-md flex items-center mt-2"
                >
                  Learn More
                  <svg
                    className="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          {/* Row 2 */}
          <div className="flex flex-wrap lg:flex-nowrap gap-8">
            {/* Box 3 - Text Only */}
            <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
              <div>
                <h3 className="text-lg font-bold mb-2">
                  Community Empowerment and Capacity Building
                </h3>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Sustainable poverty reduction requires building the capacity
                  of communities to manage and lead their own social protection
                  initiatives. Seismic provides training, resources, and
                  guidance to empower local leaders and organizations, fostering
                  a culture of self-reliance and social equity.
                </p>
              </div>
              <a
                href="/consulting-services/social-protection/community-empowerment"
                className="text-purple-700 font-bold text-md flex items-center mt-2"
              >
                Learn More
                <svg
                  className="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>

            {/* Box 4 - Text Only */}
            <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
              <div>
                <h3 className="text-lg font-bold mb-2">
                  Monitoring, Evaluation, and Adaptive Learning
                </h3>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Seismic uses adaptive learning to refine and improve social
                  protection programs over time, ensuring that each initiative
                  remains responsive to evolving community needs and delivers
                  measurable results.
                </p>
              </div>
              <a
                href="/consulting-services/social-protection/monitoring-evaluation"
                className="text-purple-700 font-bold text-md flex items-center mt-2"
              >
                Learn More
                <svg
                  className="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IntegratedSocialProtection;

import React from "react";

const ChangeManagement = () => {
  return (
    <section className="bg-white pb-16 mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1733493162/fmis_c8prqp.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
          <h1 className="text-3xl font-bold text-gray-800">
            Change Management as E-Governance Solutions
          </h1>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-12 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Change Management as E-Governance Solutions
        </h1>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        {/* Context Section */}
        <div className="mb-12">
          <p className="text-lg lg:text-xl text-gray-600 leading-relaxed mt-4 lg:mt-20">
            At Seismic Consulting, we recognize the implementation of
            e-governance solutions often involves significant organizational
            transformation. Successful adoption requires not only advanced
            technological solutions but also a structured and strategic
            approach to change management. Our change management framework
            ensures seamless integration, adoption, and sustainability of
            e-governance systems, enabling public institutions and
            organizations to achieve their digital transformation goals
            efficiently.
          </p>
        </div>

        {/* Approach Section */}
        <div>
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Our Approach to Change Management Stakeholder Engagement and Communication
          </h2>
          <div className="space-y-8">
            <div>
              <h3 className="font-bold text-xl text-gray-800 mb-2">
                Effective E-Governance
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Effective e-governance implementation begins with building trust
                and understanding among stakeholders. We work closely with
                government officials, employees, and external partners to align
                objectives and expectations. Tailored communication strategies
                ensure transparency and address resistance to change.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl text-gray-800 mb-2">
                Capacity Building and Training
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed mb-8">
                A core component of our change management services is
                empowering stakeholders with the necessary skills to use and
                maintain the e-governance systems. We design customized
                training programs that focus on:
              </p>
              <ul className="list-disc pl-5 text-lg text-gray-600 space-y-4">
                <li>Technical proficiency in new systems.</li>
                <li>Understanding process changes.</li>
                <li>Enhancing digital literacy among users at all levels.</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-xl text-gray-800 mb-2">
                Process Reengineering and Optimization
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Transitioning to e-governance often requires a review and
                optimization of existing processes. We help organizations map
                their current workflows, identify inefficiencies, and design
                streamlined processes that align with e-governance systems.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl text-gray-800 mb-2">
                Risk Management and Resistance
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed mb-8">
                Mitigation change often faces resistance, whether due to fear
                of the unknown or disruption of established practices. Seismic
                employs proactive risk management strategies to:
              </p>
              <ul className="list-disc pl-5 text-lg text-gray-600 space-y-4 ">
                <li>
                  Identify potential barriers early in the implementation
                  process.
                </li>
                <li>Develop mitigation plans to address concerns.</li>
                <li>
                  Foster a culture of adaptability and innovation.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-xl text-gray-800 mb-2">
                Monitoring and Evaluation
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Our change management strategy includes continuous monitoring
                and evaluation to assess progress and make necessary
                adjustments. Key performance indicators (KPIs) are established
                to measure adoption rates, user satisfaction, and operational
                efficiency.
              </p>
            </div>

            {/* Sustainability and Knowledge Transfer */}
            <div>
              <h3 className="font-bold text-xl text-gray-800 mb-2">
                Sustainability and Knowledge Transfer
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                We prioritize knowledge transfer to ensure long-term success. By
                embedding change agents within the organization and developing
                comprehensive documentation, we guarantee that the skills and
                knowledge needed to sustain the e-governance systems remain
                within the organization.
              </p>
            </div>

            {/* Value Proposition */}
            <div>
              <h3 className="font-bold text-xl text-gray-800 mb-2">
                Value Proposition
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Seismic Consulting’s change management services under
                e-governance solutions are designed to maximize the return on
                investment by ensuring that technological advancements lead to
                meaningful organizational improvements.
              </p>

              <h2 className="font-semibold text-lg text-gray-800 mt-4 mb-2">
                Our Solutions
              </h2>
              <ul className="list-disc pl-5 text-lg text-gray-600 space-y-4">
                <li>Increase system adoption rates.</li>
                <li>Enhance user satisfaction.</li>
                <li>Improve operational efficiency.</li>
                <li>
                  Build a foundation for continuous innovation in public service
                  delivery.
                </li>
              </ul>
              <p className="text-lg text-gray-600 leading-relaxed mt-4">
                With our expertise, public institutions can confidently navigate
                the complexities of digital transformation, unlocking the full
                potential of e-governance to deliver smarter, more efficient,
                and citizen-centric services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangeManagement;

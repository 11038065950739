import React, { useState } from 'react';

const RequestForProposal = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    company: '',
    title: '',
    industry: '',
    description: '',
    file: null,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess('');
    setError('');

    try {
      // Build a FormData object to handle file uploads
      const data = new FormData();
      data.append('first_name', formData.firstName);
      data.append('last_name', formData.lastName);
      data.append('email', formData.email);
      data.append('telephone_number', formData.phoneNumber);
      data.append('company', formData.company);
      data.append('title', formData.title);
      data.append('industry', formData.industry);
      data.append('details_description', formData.description);
      if (formData.file) {
        data.append('file', formData.file);
      }

      const response = await fetch('https://seismic-website-backend.onrender.com/api/request-for-proposal', {
        method: 'POST',
        body: data,
      });

      if (!response.ok) {
        throw new Error('Failed to submit your proposal');
      }

      setSuccess('RFP submitted successfully!');
      // Reset the form fields
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        company: '',
        title: '',
        industry: '',
        description: '',
        file: null,
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-gray-50 py-16 mt-20">
      <div className="max-w-7xl mx-auto px-4 lg:px-2 text-center">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Request for Proposal (RFP)</h2>
        <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
          SCG will be pleased to respond to your invitation and Request for Proposal (RFP). Please fill out the form below to submit your RFP.
        </p>

        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <div className="mb-4">
            <label htmlFor="firstName" className="block text-left text-lg text-gray-700">First Name *</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="lastName" className="block text-left text-lg text-gray-700">Last Name *</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-left text-lg text-gray-700">E-mail Address *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block text-left text-lg text-gray-700">Telephone Number *</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="company" className="block text-left text-lg text-gray-700">Company *</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="title" className="block text-left text-lg text-gray-700">Title *</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="industry" className="block text-left text-lg text-gray-700">Industry *</label>
            <input
              type="text"
              id="industry"
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="file" className="block text-left text-lg text-gray-700">Upload File</label>
            <input
              type="file"
              id="file"
              name="file"
              onChange={handleFileChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="description" className="block text-left text-lg text-gray-700">Detailed Description *</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              required
              rows="4"
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4 text-left">
            <label className="flex items-center text-gray-600 text-sm">
              <input type="checkbox" required className="mr-2" />
              By submitting your email address, you acknowledge that you have read the Privacy Statement and consent to our data processing as outlined in the Privacy Statement.
            </label>
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-purple-900 text-white font-semibold rounded-lg hover:bg-purple-700 transition-colors"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit Request"}
          </button>
          {success && <p className="text-green-600 mt-4">{success}</p>}
          {error && <p className="text-red-600 mt-4">{error}</p>}
        </form>
      </div>
    </section>
  );
};

export default RequestForProposal;

// About.js
import React from 'react';

const Digital = () => {
  return (
    <div className="container mx-auto py-16 mt-20">
      <h1 className="text-4xl font-bold mb-8">Digital</h1>
      <p className="text-lg">
        Welcome to the Digital page. Here you can learn more about our company, mission, and values.
      </p>
    </div>
  );
};

export default Digital;

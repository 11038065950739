import React, { useState } from "react";

const MethodologySectionASD = () => {
  const [open, setOpen] = useState({
    market: false,
    strategy: false,
    refinement: false,
  });

  const toggleCollapse = (key) => {
    setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div className="mb-16">
      <h2 className="text-2xl font-bold mb-4 text-center pt-12">Our Methodology</h2>
      <h3 className="text-xl text-center mb-4 text-gray-600">
        To develop strategies that are both robust and adaptable, Seismic employs a
        structured yet flexible methodology
      </h3>
      {/* Divider Line */}
      <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-8 mt-8"></div>

      <div className="max-w-4xl mx-auto space-y-16 mt-12">
        {/* Market and Organizational Assessments */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("market")}
          >
            Market and Organizational Assessments
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.market ? "rotate-180" : ""
              }`}
            >
              {open.market ? "-" : "+"}
            </span>
          </button>
          {open.market && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Environmental Scanning:</strong> Comprehensive analyses of
                  external factors, including market trends, industry developments,
                  technological innovations, and competitive positioning.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">SWOT Analysis:</strong> Identification of organizational
                  strengths, weaknesses, opportunities, and threats to align strategy
                  with internal capabilities and external realities.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Stakeholder Analysis:</strong> Evaluation of the needs,
                  expectations, and influence of stakeholders (customers, partners,
                  regulators, employees) to ensure alignment with strategic objectives.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Strategy Development */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("strategy")}
          >
            Strategy Development
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.strategy ? "rotate-180" : ""
              }`}
            >
              {open.strategy ? "-" : "+"}
            </span>
          </button>
          {open.strategy && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Vision and Goal Setting:</strong> Collaboratively define
                  clear, measurable objectives that align with the organization’s
                  mission and long-term ambitions.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Strategic Roadmaps:</strong> Create detailed blueprints
                  outlining milestones, resource allocation, and timelines to achieve
                  objectives efficiently.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Contingency Planning:</strong> Develop alternative strategies
                  to address potential disruptions, such as economic downturns,
                  regulatory changes, or technological breakthroughs. These
                  contingencies ensure operational continuity and resilience.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>
{/* Iterative Refinement */}
<div className="pl-4">
  <button
    className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
    onClick={() => toggleCollapse("refinement")}
  >
    Iterative Refinement
    <span
      className={`text-3xl font-bold text-purple-900 ${
        open.refinement ? "rotate-180" : ""
      }`}
    >
      {open.refinement ? "-" : "+"}
    </span>
  </button>
  {open.refinement && (
    <div className="flex flex-wrap md:flex-nowrap items-start mt-6 text-gray-800">
      {/* Text Section */}
      <div className="text-xl flex-1 leading-relaxed">
        <p>
          Seismic ensures strategies are dynamic and regularly revisited to
          incorporate new data, insights, and external changes. This iterative
          process prevents stagnation and keeps strategies relevant over time.
        </p>
      </div>
      {/* Image Section */}
      <div className="ml-6 flex-shrink-0">
        <img
          src="https://your-image-url.com/refinement.jpg"
          alt="Iterative Refinement"
          className="w-64 h-64 object-cover rounded"
        />
      </div>
    </div>
  )}
  {/* Divider Line */}
  <div className="border-t-2 border-purple-100 mt-8"></div>
</div>

      </div>
    </div>
  );
};

export default MethodologySectionASD;

import React from "react";
import KeyAreasOfExpertise from "./KeyAreasOfExpertise";
import DrivingImpactSection from "./DrivingImpactSection";

const TechnologyTelecomPage = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1738162182/pexels-photo-6153354_ozbmlj.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-2 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-10">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-2 lg:mt-12">
            Technology and Telecommunications
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Our solutions are designed to navigate the rapidly evolving
            financial landscape, fostering agility, operational efficiency, and
            resilience across all levels of the organization.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Technology and Telecommunications
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Shaping the Future of Innovation, Connectivity, and Smart Communities
        </h2>
        <p className="text-lg text-gray-700 mb-8">
          We believe the technology and telecommunications sectors are on the
          forefront of global transformation, driving modern economies, and
          shaping the way people live, work, and connect. At Seismic Consulting
          Group, we empower organizations to seize new opportunities by
          redefining their strategies, adopting innovative solutions, and
          embracing change.
        </p>
        <p className="text-lg text-gray-700">
          Our expertise enables our clients to stay ahead of the curve while
          delivering unmatched value to their customers by deploying advanced
          networks to pioneering digital products and services.
        </p>
      </div>

      {/* Transforming Financial Institutions Section */}
      <div>
        <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-16 mt-8"></div>
        <div className="mx-auto px-4 lg:px-32 mt-8 lg:mt-16">
          <div className="flex flex-col lg:flex-row items-center gap-6 lg:gap-12">
            {/* Text Section */}
            <div className="lg:w-1/2 text-center lg:text-left">
              <h3 className="text-xl lg:text-2xl font-bold mb-4">
                Our Approach to Technology and Telecommunications Innovation
              </h3>
              {/* Image Section for Small Screens */}
              <div className="lg:hidden mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733511908/pexels-onbab-29591863_ogwvp2.jpg"
                  alt="Transforming Financial Institutions"
                  className="w-full rounded-lg"
                />
              </div>
              <p className="text-gray-600 text-base lg:text-lg leading-relaxed">
                In a rapidly advancing sector, staying competitive requires
                strategic foresight, technical expertise, and operational
                excellence. We help our clients navigate complex challenges,
                embrace opportunities, and transform their operations to achieve
                long-term success.
              </p>
            </div>
            {/* Image Section for Larger Screens */}
            <div className="lg:w-1/2 hidden lg:block">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733511908/pexels-onbab-29591863_ogwvp2.jpg"
                alt="Transforming Financial Institutions"
                className="w-full rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>

      <KeyAreasOfExpertise />

      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737459528/free-photo-of-business-presentation-in-modern-office-setting_m4a3we.jpg"
              alt="Why Choose Seismic Consulting"
              className="w-full"
            />
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-4 mt-4 text-purple-900">Seismic Consulting</h2>
            <h3 className="text-xl font-semibold mb-4 mt-4 italic">
              Seismic Consulting: Your Partner in Navigating the Next Frontier
              of Technology and Telecommunications.
            </h3>
            <p className="text-gray-600 text-xl leading-relaxed">
            Our approach is rooted in a deep understanding of the technology and telecommunications landscape, combined with a commitment to delivering tangible results. By focusing on innovation, security, and transformative solutions, we help our clients shape the future of connectivity and unlock unparalleled value.
            </p>
          </div>
        </div>
      </section>
      <DrivingImpactSection />
    </div>
  );
};

export default TechnologyTelecomPage;

import React, { useState } from "react";

const HealthcareSystemsTransformation = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const approachData = [
    {
      title: "Process Reengineering for Operational Excellence",
      description:
        "We analyze existing healthcare operations to identify bottlenecks and inefficiencies, creating streamlined workflows that:",
      services: [
        "Reduce patient wait times and enhance service delivery.",
        "Optimize staff allocation and resource utilization, ensuring peak operational performance.",
        "Improve coordination across departments, minimizing delays and redundancies.",
      ],
    },
    {
      title: "Integrating Advanced Technologies",
      description: "We implement advanced technologies to enhance healthcare delivery.",
      subcategories: [
        {
          subheading: "Electronic Health Records (EHR)",
          details: [
            "Centralize patient information for seamless sharing across care teams.",
            "Enable data-driven decision-making by providing real-time insights.",
            "Enhance patient safety through features like medication alerts and clinical guidelines.",
          ],
        },
        {
          subheading:
          "AI-Powered Diagnostics and Predictive Analytics",
          details: [
            "Faster and more accurate diagnoses using machine learning algorithms.",
            "Predictive analytics to identify at-risk patients and enable early interventions.",
            "Automation of routine tasks, freeing up clinicians to focus on patient care.",
          ],
        },
        {
          subheading: "Digital Tools and Patient Portals",
          details: [
            "We integrate platforms that empower patients with self-service tools for scheduling, accessing test results, and communicating with providers, fostering a more engaged and informed patient population.",
          ],
        },
      ],
    },
    {
      title: "Patient-Centric Transformation",
      description:
        "Our solutions prioritize the patient experience, ensuring that every transformation aligns with a patient-first philosophy. This includes:",
      services: [
        "Designing workflows that minimize patient stress and maximize care quality.",
        "Implementing feedback mechanisms to continuously improve patient satisfaction.",
        "Enhancing accessibility for underserved populations through telehealth and digital outreach.",
      ],
    },
    {
      title: "Overcoming Barriers to Transformation",
      description:
        "Healthcare transformation often encounters challenges such as resistance to change, cost concerns, and data silos. We address these barriers by:",
      services: [
        "Engaging stakeholders at every level, from clinicians to administrators, to build consensus and drive adoption.",
        "Creating phased implementation plans that minimize disruption while maximizing ROI.",
        "Utilizing secure data integration methods to break down silos and foster interoperability across systems.",
      ],
    },
  ];
    

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739185139/pexels-photo-3938022_wn1cu3.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-10">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-4">
            Healthcare Systems Transformation
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Redesigning healthcare systems for efficiency, accessibility, and
            seamless delivery in an ever-evolving landscape.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Healthcare Systems Transformation
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Redesigning Healthcare for Efficiency, Accessibility, and Seamless
          Delivery
        </h2>
        <p className="text-gray-700 text-lg mb-4">
          At Seismic Consulting Group, we specialize in driving comprehensive
          healthcare systems transformations, helping organizations adapt to the
          rapidly evolving landscape of modern medicine. By reengineering
          processes and integrating advanced technologies like electronic health
          records (EHR), artificial intelligence (AI), and analytics, we enable
          healthcare providers to deliver patient-centered care with greater
          efficiency and accessibility.
        </p>
        <p className="text-gray-700 text-lg mb-4">
          Our approach focuses on creating a future-ready healthcare ecosystem
          where every aspect—from operations to patient interaction—is optimized
          for seamless delivery.
        </p>
        <h3 className="text-2xl font-semibold text-gray-900 mb-4 mt-8">
          The Need for Healthcare Systems Transformation
        </h3>
        <p className="text-gray-700 font-medium text-lg mb-4">
          The healthcare industry faces significant challenges, including:
        </p>
        <div className="mt-4">
          <ul className="list-none text-gray-700 text-lg space-y-4">
            <li className="relative pl-6 mb-4">
              <span className="absolute top-2 left-0 w-4 h-4 bg-purple-100 border-2 border-purple-900 rounded-full"></span>
              Rising patient volumes and increasing demand for personalized
              care.
            </li>
            <li className="relative pl-6 mb-4">
              <span className="absolute top-2 left-0 w-4 h-4 bg-purple-100 border-2 border-purple-900 rounded-full"></span>
              Complex and inefficient administrative processes.
            </li>
            <li className="relative pl-6 mb-4">
              <span className="absolute top-2 left-0 w-4 h-4 bg-purple-100 border-2 border-purple-900 rounded-full"></span>
              A growing need for integration of digital tools and advanced
              analytics.
            </li>
            <li className="relative pl-6 mb-4">
              <span className="absolute top-2 left-0 w-4 h-4 bg-purple-100 border-2 border-purple-900 rounded-full"></span>
              Evolving regulatory requirements and value-based care models.
            </li>
          </ul>
        </div>

        <p className="text-gray-700 text-lg mt-4">
          Seismic Consulting Group helps healthcare organizations overcome these
          challenges by transforming systems holistically, ensuring they are
          sustainable, scalable, and patient-first.
        </p>
      </div>

      {/* Impact Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Our Impact
        </h2>
        <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
          Seismic Consulting Group’s healthcare systems transformations have
          consistently delivered measurable results, including:
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          <div className="text-center">
            <h3 className="text-4xl font-bold text-purple-900">25%</h3>
            <p className="text-gray-700 mt-2">
              Reduction in patient wait times through optimized workflows.
            </p>
          </div>
          <div className="text-center">
            <h3 className="text-4xl font-bold text-purple-900">30%</h3>
            <p className="text-gray-700 mt-2">
              Improvement in diagnostic accuracy by integrating AI tools.
            </p>
          </div>
          <div className="text-center">
            <h3 className="text-4xl font-bold text-purple-900">Significant</h3>
            <p className="text-gray-700 mt-2">
              Cost savings from reduced redundancies and enhanced operational
              efficiency.
            </p>
          </div>
        </div>
      </div>

 {/* Approach Section */}
 <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Our Approach to Systems Transformation
  </h2>
  {approachData.map((item, index) => (
    <div key={index} className="mb-12 border-b border-pink-100 pb-8">
      <button
        onClick={() => toggleSection(index)}
        className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
      >
        {item.title}
        <span className="text-purple-900 text-3xl">
          {openSection === index ? "-" : "+"}
        </span>
      </button>
      {openSection === index && (
        <div className="mt-4">
          <p className="text-gray-700 text-lg mb-4">{item.description}</p>
          {item.services && item.services.length > 0 && (
            <ul className="list-none text-gray-700 text-lg space-y-4 mt-4 px-6 lg:px-32">
              {item.services.map((service, idx) => (
                <li key={idx} className="relative pl-6">
                  <span
                    className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 border-purple-900 rounded-full"
                  ></span>
                  {service}
                </li>
              ))}
            </ul>
          )}
          {item.subcategories &&
            item.subcategories.map((subcat, subIndex) => (
              <div key={subIndex} className="mt-6 px-6 lg:px-32">
                <h4 className="text-xl font-medium mb-4">{subcat.subheading}</h4>
                <ul className="list-none text-gray-700 text-lg space-y-4">
                  {subcat.details.map((detail, detailIdx) => (
                    <li key={detailIdx} className="relative pl-6">
                      <span
                        className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 border-purple-900 rounded-full"
                      ></span>
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      )}
    </div>
  ))}
</div>


      {/* Call to Action Section */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739182785/pexels-photo-7580259_jeaycc.jpg"
              alt="Call to Action"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
          <div>
            <h2 className="text-3xl font-bold mb-4">
              Transform your healthcare systems today to meet the challenges of
              tomorrow
            </h2>
            <p className="text-gray-600 text-lg mb-4">
              The healthcare industry is evolving, and organizations must adapt
              to meet rising expectations and demands. At Seismic Consulting
              Group, we are committed to helping you build a resilient and
              innovative healthcare ecosystem that delivers superior care to
              every patient.
            </p>
            <p className="text-gray-600 text-lg">
              Together, we will ensure alignment with industry trends and
              empower you to achieve sustainable success.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HealthcareSystemsTransformation;

const DrivingImpactSection = () => {
    const impactData = [
      {
        title: "Faster Innovation",
        points: [
          "Accelerated time-to-market for new technologies and services through streamlined development processes.",
          "Enhanced agility to respond to evolving consumer demands and technological trends.",
        ],
      },
      {
        title: "Improved Connectivity",
        points: [
          "Expanded access to reliable, high-speed networks that bridge the digital divide.",
          "Scalable solutions that meet the demands of future technologies and applications.",
        ],
      },
      {
        title: "Enhanced Security and Trust",
        points: [
          "Strengthened defense against cyber threats, ensuring customer data remains secure.",
          "Proactive compliance with global regulatory standards, reducing risk and building trust.",
        ],
      },
      {
        title: "Empowered Communities",
        points: [
          "Smarter urban ecosystems that enhance mobility, sustainability, and quality of life.",
          "IoT solutions that drive innovation across industries, from healthcare to transportation.",
        ],
      },
    ];
  
    return (
      <div className=" mt-20 py-12">
        <div className="max-w-6xl mx-auto px-6 lg:px-12">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
            Driving Measurable Impact in Technology and Telecommunications
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {impactData.map((item, index) => (
              <div key={index} className="bg-white shadow-md rounded-md p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {item.title}
                </h3>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                  {item.points.map((point, idx) => (
                    <li key={idx}>{point}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default DrivingImpactSection;
  
import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

const PerformanceMetricsSection = () => {
  const [isCounting, setIsCounting] = useState(false);
  const metricsRef = useRef(null);

  // Detects if the section is in view for animation on scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsCounting(true);
          observer.unobserve(metricsRef.current); // Only triggers once
        }
      },
      { threshold: 0.3 }
    );

    if (metricsRef.current) {
      observer.observe(metricsRef.current);
    }

    return () => {
      if (metricsRef.current) {
        observer.unobserve(metricsRef.current);
      }
    };
  }, []);

  // Start counting on hover
  const handleMouseEnter = () => {
    setIsCounting(true);
  };

  return (
    <section
      ref={metricsRef}
      className="py-16 px-6 lg:px-2 bg-white text-center"
      onMouseEnter={handleMouseEnter} // Trigger counting on hover
    >
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 mb-2">Performance Metrics</h2>
        <p className="text-gray-600 mb-12">Measuring Our Impact in Education</p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Metric 1 */}
          <div className="flex flex-col items-center text-center">
            <h3 className="text-5xl font-bold text-purple-900">
              {isCounting ? <CountUp end={50} duration={5} /> : '50'}+
            </h3>
            <h4 className="text-xl font-semibold text-gray-800 mt-2">Education Projects Completed</h4>
            <p className="text-gray-600 mt-2">
              Implemented large-scale education initiatives with 200+ activities, transforming the education sector.
            </p>
          </div>

          {/* Metric 2 */}
          <div className="flex flex-col items-center text-center">
            <h3 className="text-5xl font-bold text-purple-900">
              {isCounting ? <CountUp end={10} duration={5} /> : '10'}+
            </h3>
            <h4 className="text-xl font-semibold text-gray-800 mt-2">International Collaboration</h4>
            <p className="text-gray-600 mt-2">
              Collaborating with international organizations to strengthen education globally.
            </p>
          </div>

          {/* Metric 3 */}
          <div className="flex flex-col items-center text-center">
            <h3 className="text-5xl font-bold text-purple-900">
              {isCounting ? <CountUp end={50} duration={5} /> : '50'}+
            </h3>
            <h4 className="text-xl font-semibold text-gray-800 mt-2">Center of Excellence & Skill Acquisition Developed</h4>
            <p className="text-gray-600 mt-2">
              Transformation of technical colleges and skill acquisition centers into TVET centers of excellence.
            </p>
          </div>

          {/* Metric 4 */}
          <div className="flex flex-col items-center text-center">
            <h3 className="text-5xl font-bold text-purple-900">
              {isCounting ? <CountUp end={5000} duration={5} /> : '5000'}+
            </h3>
            <h4 className="text-xl font-semibold text-gray-800 mt-2">Students Reached</h4>
            <p className="text-gray-600 mt-2">
              Positively impacted students, giving them access to improved learning environments and opportunities.
            </p>
          </div>

          {/* Metric 5 */}
          <div className="flex flex-col items-center text-center">
            <h3 className="text-5xl font-bold text-purple-900">
              {isCounting ? <CountUp end={20} duration={5} /> : '20'}%
            </h3>
            <h4 className="text-xl font-semibold text-gray-800 mt-2">Increase in Digital Literacy</h4>
            <p className="text-gray-600 mt-2">
              Increased digital literacy among students in underserved areas through technology integration.
            </p>
          </div>

          {/* Metric 6 */}
          <div className="flex flex-col items-center text-center">
            <h3 className="text-5xl font-bold text-purple-900">
              {isCounting ? <CountUp end={100} duration={5} /> : '100'}+
            </h3>
            <h4 className="text-xl font-semibold text-gray-800 mt-2">Schools Upgraded with Digital Infrastructure</h4>
            <p className="text-gray-600 mt-2">
              Supported the implementation of modern technology and digital learning tools to enhance education access.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerformanceMetricsSection;

import React from "react";

function ProcessAutomation() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Process Automation and Operational Efficiency
          </h1>
        </div>
      </section>

      {/* Introduction */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            At Seismic Consulting Group, enhancing operational efficiency through intelligent process
            automation is a cornerstone of our digital transformation services. We leverage advanced
            technologies to streamline workflows, reduce operational costs, and empower employees to focus
            on strategic initiatives that drive innovation and growth.
          </p>
        </div>
      </section>

      {/* Robotic Process Automation (RPA) */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            Robotic Process Automation (RPA)
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            We specialize in automating repetitive, rule-based tasks such as data entry, report generation, 
            and invoice processing. By deploying RPA solutions, we help organizations minimize errors, 
            increase processing speed, and improve accuracy. This not only reduces costs but also frees up 
            valuable human resources to engage in higher-value activities that require critical thinking and creativity.
          </p>
        </div>
      </section>

      {/* Workflow and Process Digitization */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            Workflow and Process Digitization
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Seismic transforms manual, paper-based processes into efficient, digital workflows. Our expertise 
            in process digitization enhances transparency, improves compliance, and accelerates operations. By automating 
            approval processes, document management, and other essential workflows, we enable organizations to operate 
            more responsively and effectively in a digital landscape.
          </p>
        </div>
      </section>

      {/* AI-Driven Operations */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            AI-Driven Operations
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Leveraging artificial intelligence, we optimize operations through predictive maintenance, 
            resource optimization, and dynamic task allocation. Our AI solutions analyze data patterns to 
            anticipate operational needs, reduce downtime, and enhance productivity. For example, in industries like 
            manufacturing and logistics, our AI-driven systems can predict equipment failures before they occur, allowing 
            for proactive maintenance and uninterrupted operations.
          </p>
        </div>
      </section>

      {/* Delivering Value Through Intelligent Process Automation */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            Delivering Value Through Intelligent Process Automation
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            One of the most impactful ways Seismic Consulting Group adds value is by implementing intelligent 
            process automation tailored to our clients' unique needs. By automating routine and time-consuming 
            tasks, we help organizations cut operational costs, reduce the potential for human error, and reallocate 
            resources to strategic, high-impact projects.
          </p>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            For instance, we partnered with a manufacturing client to implement an automated quality control system 
            utilizing AI and machine learning. This innovation reduced inspection times by 40%, significantly enhancing 
            production efficiency and accuracy. As a result, the client was able to redirect resources toward research and 
            development initiatives, fostering innovation and gaining a competitive edge in the market.
          </p>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Our comprehensive approach ensures that process automation initiatives are not only technologically advanced 
            but also aligned with organizational goals and culture. From initial assessment and strategy development to 
            implementation and ongoing support, Seismic provides end-to-end services that transform operations and deliver 
            measurable, sustainable results.
          </p>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            By choosing Seismic Consulting Group for process automation, organizations position themselves at the forefront 
            of efficiency and innovation, ready to meet the challenges of today's fast-paced digital environment.
          </p>
        </div>
      </section>
    </div>
  );
}

export default ProcessAutomation;

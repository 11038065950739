import React from 'react';
import MethodologySectionCT from './MethodologySectionCT';
import { paths } from '../../constants/paths';

const CulturalTransformation = () => {
  return (
    <div className="py-16">
      {/* Hero Section */}
      <section 
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736932993/close-up-interracial-people-holding-hands_1_iw7zr7.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
          <h1 className="text-3xl font-bold text-gray-800">
            Cultural Transformation
          </h1>
        </div>
      </section>

      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Cultural Transformation
        </h1>
      </div>

      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-28">
        {/* Context Section */}
        <div className="mb-16">
          <p className="text-gray-600 text-xl leading-relaxed">
            Organizational culture—the collective values, beliefs, and behaviors of its people—is a critical determinant of success. Through Cultural Transformation, Seismic helps organizations realign their culture to better support their strategic objectives, fostering an environment of trust, collaboration, and shared purpose.
          </p>
        </div>

        {/* What We Do Section */}
        <div className="mb-12 pt-8">
          <h2 className="text-2xl font-bold text-center mb-8">What We Do</h2>
          <p className="text-gray-600 text-xl leading-relaxed mb-8">
            We work closely with leadership teams to reshape organizational culture, fostering values and behaviors that align with strategic goals. Seismic partners with leadership teams to redefine and cultivate an organizational culture that reflects and reinforces the company’s mission and long-term goals. This involves:
          </p>
          <ul className="list-none space-y-8 text-lg leading-relaxed">
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Assessing Current Culture:</strong> Analyzing existing cultural dynamics, strengths, and areas of misalignment with strategic priorities.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Defining Desired Culture:</strong> Collaboratively crafting a vision of the ideal organizational culture—one that supports innovation, adaptability, and high performance.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Transforming Values into Actions:</strong> Ensuring that organizational values are consistently reflected in behaviors, decision-making, and daily operations.
              </div>
            </li>
          </ul>
        </div>

        {/* Our Methodology Section */}
<MethodologySectionCT />

        {/* Value to Clients Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6 text-gray-900 text-center">Value to Clients</h2>
          <ul className="list-none space-y-8 text-lg leading-relaxed">
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Enhanced Employee Engagement:</strong> Employees feel more connected to the organization’s mission and values, leading to increased job satisfaction and loyalty.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Improved Productivity:</strong> A positive, values-driven culture fosters motivation, collaboration, and efficiency.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Stronger Alignment with Strategic Objectives:</strong> Cultural alignment ensures that day-to-day behaviors and decisions directly support organizational goals.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Trust and Collaboration:</strong> Open communication and inclusive practices build trust, creating a cohesive workforce united by shared purpose.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Resilience and Adaptability:</strong> A transformed culture fosters agility, enabling organizations to navigate changes and challenges with confidence.
              </div>
            </li>
          </ul>
        </div>


      </div>

            {/* Seismic’s expertise in Cultural Transformation */}
<section className="bg-white py-16">
  <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-wrap lg:flex-nowrap gap-12 items-center">
    {/* Image Section */}
    <div className="flex-shrink-0 w-full lg:w-1/2">
      <img
        src=""
        alt="Why Choose Seismic Consulting"
        className="w-full"
      />
    </div>

    {/* Text Section */}
    <div className="w-full lg:w-1/2">
      <h2 className="text-2xl font-medium mb-6">
        Seismic’s expertise in Cultural Transformation
      </h2>

      <p className="text-gray-600 text-xl leading-relaxed">
      Through its Cultural Transformation methodology, Seismic equips organizations with the cultural foundation needed for sustainable success. By fostering shared values, engaging employees at all levels, and aligning behaviors with strategic goals, Seismic helps clients unlock their full potential in today’s competitive landscape. This transformation becomes the cornerstone for achieving innovation, growth, and enduring relevance.
      </p>
    </div>
  </div>
</section>

{/* Related Services Section */}
<div className="max-w-8xl mx-auto px-6 lg:px-12 mt-8 lg:mt-12 ml-8">
  <h2 className="text-2xl font-bold mb-12 text-center">Seismic’s Approach to Change Management</h2>
  <div className="grid md:grid-cols-3 gap-8">
    {[
              {
                title: "Adaptive Strategy Development",
                description:
                  "In an era defined by constant evolution in market dynamics, regulatory landscapes, and technological innovation, Adaptive Strategy Development is central to Seismic's approach to Change Management & Strategy Consulting.",
                image:
                  "https://res.cloudinary.com/dxhx45ing/image/upload/v1736871131/4_pnllia.jpg",
                link: `/consulting-services/${paths.changeManagementPage}/${paths.adaptiveStrategyDevelopment}`,
              },
      {
        title: "Digital Transformation",
        description:
          "Empowering organizations to embrace digital innovation, streamline operations, and enhance customer experiences.",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736932864/5_1_ldv5s1.jpg",
        link: "/consulting-services/change-management-page/digital-transformation",
      },
      {
        title: "Capacity Building for Change Leaders",
        description:
          "Equipping leaders with the tools and skills needed to inspire, guide, and sustain change within their organizations.",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736933370/8_1_q1oige.jpg",
        link: "/consulting-services/change-management-page/capacity-building-for-change-leaders",
      },
    ].map((item, index) => (
      <a
        key={index}
        href={item.link}
        className="block text-left hover:shadow-lg transition-shadow duration-300"
      >
        <img
          src={item.image}
          alt={item.title}
          className="mb-4 w-full h-[300px] object-cover"
        />
        <h3 className="text-lg font-bold mb-2">{item.title}</h3>
        <p className="text-gray-600 text-sm mb-2">{item.description}</p>
        <span className="text-purple-900 font-bold text-sm">Read More</span>
      </a>
    ))}
  </div>
</div>
    </div>
  );
};

export default CulturalTransformation;

// src/pages/PublicSector.jsx
import React from "react";

const PublicSector = () => {
  const projects = [
    {
        title: "Sports Project: Talent Management System",
        overview: `The Sports Talent Management System is currently in the research and design phase, focusing on user workflow mapping and the conceptualization of a vocational training module. The system aims to identify, develop, and manage sports talent by providing a structured digital platform for athletes, coaches, and sports organizations.
    
    Stakeholder consultations are ongoing to gather input on platform requirements and features, ensuring that the system aligns with industry needs, athlete development programs, and vocational training for post-sports career planning.`,
        useCases: [
          "Athletes & Sports Talent – Provides a centralized system for tracking progress, managing performance metrics, and accessing training programs.",
          "Sports Academies & Training Centers – Offers structured talent development modules and career planning tools for young athletes.",
          "Sports Federations & Associations – Facilitates athlete scouting, data analytics, and performance evaluation.",
          "Coaches & Mentors – Enables data-driven coaching and personalized training plans based on athlete performance analytics.",
          "Vocational & Career Transition Training – Integrates education and alternative career paths for athletes transitioning post-competition.",
          "Sponsorship & Sports Marketing Agencies – Connects athletes with endorsement opportunities and provides exposure for talent scouting.",
        ],
      },
    {
      title: "Data Centre Project",
      overview: `The Data Centre Project is a strategic initiative aimed at establishing Nigeria’s first physical data centers and servers, providing businesses and institutions with secure, scalable, and high-performance infrastructure for data storage, processing, and management.
      
This project is designed to enhance digital transformation by ensuring data sovereignty, cybersecurity, and operational efficiency. It supports cloud computing, enterprise IT solutions, and digital economy growth, enabling businesses, government agencies, and financial institutions to securely host and manage their mission-critical data within Nigeria.`,
      useCases: [
        "Enterprise IT & Cloud Computing – Provides businesses with scalable server space for hosting applications, websites, and databases.",
        "Government & Public Sector – Ensures secure data storage and processing for government agencies, national security, and digital governance.",
        "Financial Institutions – Supports banks, fintech companies, and payment processors by ensuring high availability and data protection.",
        "Telecommunications & ISPs – Enhances network infrastructure for faster internet speeds, improved connectivity, and local data hosting.",
        "Health & Research Institutions – Facilitates big data analytics, medical records storage, and AI-driven healthcare innovations.",
        "E-commerce & Digital Services – Enables online businesses and startups to store and process data securely, ensuring reliable online transactions.",
      ],
    },
    {
      title: "Energy Project",
      overview: `The Energy Project is a smartphone and web-enabled prepaid meter management system designed to address challenges faced by Nigeria’s Electricity Distribution Companies (DisCos). The system enhances metering efficiency, revenue collection, and customer satisfaction by providing real-time inventory management, GPS-enabled geotagging, and automated energy unit top-ups.
      
By integrating smart technology and automation, the Energy Project streamlines power distribution, reduces energy theft, and ensures efficient meter tracking, ultimately improving the overall electricity management ecosystem in Nigeria.`,
      useCases: [
        "Electricity Distribution Companies (DisCos) – Enhances meter inventory management, tracking, and energy billing automation.",
        "Utility Customers (Residential & Commercial) – Provides real-time energy consumption monitoring and seamless prepaid meter top-ups.",
        "Regulatory Bodies & Government Agencies – Supports data-driven decision-making for energy policies, tariff regulation, and national grid improvements.",
        "Renewable Energy Providers – Facilitates integration of solar and alternative energy metering into prepaid systems.",
        "Field Technicians & Service Providers – Uses GPS-enabled geotagging to track and manage meter installations and repairs.",
        "Financial & Fintech Companies – Integrates with digital payment solutions for seamless energy unit purchases and automated transactions.",
      ],
    },
    {
      title: "Transafe: AI-Powered Mobility-as-a-Service (MaaS) Platform",
      overview: `Transafe is an AI-powered Mobility-as-a-Service (MaaS) platform designed to revolutionize urban transportation in Abuja, Nigeria. By leveraging artificial intelligence, real-time data analytics, and smart mobility solutions, Transafe optimizes commuter experience, safety, and efficiency.
      
The platform offers real-time route optimization, demand prediction, safety alerts, and personalized travel recommendations, enabling a smarter, safer, and more efficient urban transport system. It aims to reduce traffic congestion, improve public transit accessibility, and enhance overall commuter convenience.`,
      useCases: [
        "Commuters & Daily Travelers – Provides real-time route optimization and personalized travel recommendations for a faster, more efficient commute.",
        "Ride-Hailing & Transport Operators – Enhances fleet management and demand prediction for better resource allocation.",
        "Public Transport Authorities – Supports urban mobility planning, congestion management, and smart city initiatives.",
        "Emergency & Security Agencies – Issues real-time safety alerts and location tracking to enhance commuter security.",
        "Logistics & Delivery Services – Optimizes delivery routes and transport schedules using AI-powered analytics.",
        "Smart City Development – Integrates with IoT-based traffic monitoring systems to improve urban mobility infrastructure.",
      ],
    },
  ];

  return (
    <section className="bg-gray-50">
{/* Hero Section */}
<div
  className="relative w-full h-[500px] sm:h-[600px] bg-black bg-cover bg-center flex items-center justify-center"
  style={{
    backgroundImage:
      "url('https://via.placeholder.com/1500x600?text=Public+Sector+Advancement')",
  }}
>
  <div className="absolute inset-0 bg-gradient-to-r from-orange-900 to-red-900 opacity-80"></div>
  <div className="relative z-10 text-center px-4">
    <h1 className="text-4xl sm:text-5xl font-extrabold text-white tracking-wide drop-shadow-lg">
      Public Sector Advancement
    </h1>
    <p className="mt-4 text-xl sm:text-2xl text-gray-200 max-w-2xl mx-auto drop-shadow">
      Transforming public infrastructure with innovative digital solutions.
    </p>
  </div>
</div>


      {/* Introduction */}
      <div className="max-w-4xl mx-auto px-4 py-16 text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-6">
          Our Public Sector Projects
        </h2>
        <p className="text-gray-700 text-lg leading-relaxed">
          We drive digital transformation in the public sector with strategic initiatives that bolster national infrastructure, ensure data security, and optimize operational efficiency. Explore our flagship projects designed to empower government agencies, improve public services, and foster sustainable development.
        </p>
      </div>

      {/* Projects Section */}
      <div className="max-w-6xl mx-auto px-4 pb-16 space-y-16">
        {projects.map((project, idx) => (
          <div
            key={idx}
            className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105"
          >
            <h2 className="text-3xl font-bold text-red-800 mb-4">
              {project.title}
            </h2>
            <div className="mb-6">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                Overview
              </h3>
              {project.overview.split("\n\n").map((para, i) => (
                <p key={i} className="text-gray-700 text-lg leading-relaxed mb-4">
                  {para}
                </p>
              ))}
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                Use Cases
              </h3>
              <ul className="mt-2 list-disc list-inside text-gray-700 space-y-3 text-lg">
                {project.useCases.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PublicSector;

import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";

const DecentralizationFiscalManagement = () => {
  return (
    <div className="w-full bg-white">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
        style={{ backgroundImage: "url('/PagesImages/dfm.jpg')" }} // Replace with the correct hero image path
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>

        {/* Title Overlay for large screens*/}
        <div
    className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
  >
    <h1 className="text-3xl font-bold text-gray-800 mt-4">
    Decentralization and Fiscal Management
    </h1>
  </div>
      </section>

      {/* Foreword Section */}
      <section className="max-w-4xl mx-auto py-16 px-6 lg:px-0">
        {/* Title Below Image for Small Screens */}
        <div className="block lg:hidden mt-4 mb-4 text-left">
          <h1 className="text-2xl font-bold text-gray-800">
            Decentralization and Fiscal Management
          </h1>
        </div>
        <p className="text-lg text-gray-700 mb-8 mt-8">
          Seismic is a global leader in Public Financial Management (PFM) with a
          strong focus on decentralization and fiscal management. We leverage
          our extensive experience to help governments design and implement
          fiscal management strategies that empower local governments to manage
          financial resources effectively, promote regional development, and
          strengthen the capacity of local authorities.
        </p>
        <div className="flex flex-col lg:flex-row items-start gap-8 mt-20">
          <img
            src="/PagesImages/decentralization_small.jpg" // Replace with the correct small image path
            alt="Decentralization"
            className="w-full lg:w-5/12 object-cover"
          />
          <p className="text-lg text-gray-700 lg:w-7/12 mt-4">
            Seismic's tailored solutions promote accountable and transparent
            fiscal management, strengthening local revenue generation and
            supporting regional development.
          </p>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16">
        <div className="max-w-4xl mx-auto px-6 lg:px-0">
          <h2 className="text-3xl font-semibold mb-8">Our Approach</h2>
          <ul className="space-y-8">
            {[
              {
                title: "Strengthening Accountability and Transparency",
                description:
                  "Seismic helps design financial reporting and auditing systems that ensure responsible use of public funds at the local level, enhancing trust through transparent financial practices and preventing mismanagement.",
              },
              {
                title: "Enhancing Local Revenue Generation",
                description:
                  "We design revenue mobilization strategies for local governments, optimizing taxation systems, identifying alternative revenue sources, and improving collection mechanisms to support essential services and economic development.",
              },
              {
                title: "Supporting Fiscal Decentralization for Effective Governance",
                description:
                  "Seismic guides governments in implementing fiscal decentralization frameworks that empower local authorities to manage resources effectively and create responsive governance structures for regional development.",
              },
              {
                title: "Developing Robust Fiscal Management Strategies",
                description:
                  "Our fiscal management strategies provide local governments with the tools for responsible public finance management, covering budgeting, expenditure controls, and financial accountability.",
              },
              {
                title: "Building Capacity for Regional Development",
                description:
                  "Through targeted training, Seismic enhances the skills of local officials in financial management, enabling them to make informed decisions on budgeting, resource allocation, and public investment.",
              },
            ].map((approach, index) => (
              <li key={index} className="flex gap-4">
                <span className="text-purple-900 text-2xl">▶</span>
                <div className="text-lg font-semibold">
                  {approach.title}: <span className="font-normal text-gray-700">{approach.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* Other Services Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-gray-800 mb-8">Other Services</h2>
          <p className="text-xl text-gray-600 mb-8">
      Helping clients bridge the fundamental gaps in public financial management
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                title: "Audit and Financial Control Systems",
                description:
                  "Seismic provides audit and control solutions that reinforce transparency, accountability, and governance within public institutions.",
                link: `/${paths.consultingServices}/${paths.pfm}/${paths.afcs}`,
              },
              {
                title: "Capacity Building and Training",
                description:
                  "Our targeted training programs equip public sector staff with skills for effective PFM implementation, ensuring sustainable fiscal management.",
                link: `/${paths.consultingServices}/${paths.pfm}/${paths.cbt}`,
              },
              {
                title: "Capital Investment Planning",
                description:
                  "We help governments and institutions develop strategic capital investment plans aligned with national development goals, promoting economic growth and resource optimization.",
                link: `/${paths.consultingServices}/${paths.pfm}/${paths.cip}`,
              },
            ].map((service, index) => (
              <Link
                to={service.link}
                key={index}
                className="block bg-white p-6 shadow-md rounded-md text-left max-w-[450px] mx-auto"
              >
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  {service.title}
                </h3>
                <p className="text-lg text-gray-600 mb-4">
                  {service.description}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DecentralizationFiscalManagement;

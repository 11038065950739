import React from "react";

function CloudInfrastructurePage() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Cloud and IT Infrastructure Transformation
          </h1>
        </div>
      </section>

      {/* Intro Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            In the rapidly evolving digital landscape, a robust and adaptable IT
            infrastructure is essential for organizations aiming to innovate and
            stay ahead of the competition. At Seismic Consulting Group, we
            specialize in transforming your cloud and IT infrastructure to
            enhance scalability, efficiency, and security, aligning technology
            with your strategic business objectives.
          </p>
        </div>
      </section>

{/* Content Sections */}
<section className="bg-gray-50 py-12">
  <div className="max-w-6xl mx-auto px-4 space-y-12">
    {/* Cloud Migration and Management */}
    <div>
      <h2 className="text-2xl font-bold text-purple-900 mb-4">
        Cloud Migration and Management
      </h2>
      <p className="text-gray-700 mb-6 leading-relaxed text-lg">
        Seismic assists organizations in seamlessly migrating legacy
        systems to the cloud, significantly improving scalability,
        accessibility, and cost-effectiveness. Our experts evaluate your
        unique needs to select and configure the most suitable cloud
        solutions—whether public, private, or hybrid environments.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="font-bold text-gray-800">
            Seamless Cloud Integration
          </h3>
          <p className="text-gray-700">
            We manage the entire migration process, ensuring minimal
            disruption and maximum data integrity. Post-migration, we
            provide ongoing management services to optimize cloud
            performance and cost-efficiency.
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737458371/pexels-cookiecutter-1148820_vp7g7x.jpg"
          alt="Cloud Migration"
          className="rounded shadow object-cover w-full h-[150px]"
        />
      </div>
    </div>

    {/* Edge Computing Solutions */}
    <div>
      <h2 className="text-2xl font-bold text-purple-900 mb-4">
        Edge Computing Solutions
      </h2>
      <p className="text-gray-700 mb-6 leading-relaxed text-lg">
        To meet the demands of real-time data processing and IoT
        applications, we implement edge computing strategies that process
        data closer to its source. This approach enhances processing
        speed, reduces latency, and improves overall system
        responsiveness.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="font-bold text-gray-800">Faster Data Access</h3>
          <p className="text-gray-700">
            By deploying edge computing solutions, Seismic enables clients
            to deliver faster services, improve user experiences, and
            reduce bandwidth costs.
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1734693710/pexels-joshsorenson-1714208_jldkni.jpg"
          alt="Edge Computing"
          className="rounded shadow object-cover w-full h-[150px]"
        />
      </div>
    </div>

    {/* Cybersecurity and Compliance */}
    <div>
      <h2 className="text-2xl font-bold text-purple-900 mb-4">
        Cybersecurity and Compliance
      </h2>
      <p className="text-gray-700 mb-6 leading-relaxed text-lg">
        In an era where data breaches and cyber threats are prevalent,
        establishing robust cybersecurity measures is non-negotiable.
        Seismic designs and implements comprehensive security frameworks
        tailored to protect your organization's data and IT assets.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="font-bold text-gray-800">Regulatory Compliance</h3>
          <p className="text-gray-700">
            We ensure compliance with industry regulations such as GDPR,
            HIPAA, and CCPA, mitigating risks and safeguarding your
            reputation.
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737459057/istockphoto-1412282189-612x612_m0vaw4.jpg"
          alt="Cybersecurity"
          className="rounded shadow object-cover w-full h-[150px]"
        />
      </div>
    </div>
  </div>
</section>

      {/* Why Choose Seismic */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            Why Choose Seismic for Cloud and IT Infrastructure Transformation
          </h2>
          <p className="text-gray-700 max-w-3xl mx-auto mb-8">
            Seismic Consulting Group brings deep expertise and a holistic
            approach to transforming your IT infrastructure.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <div className="flex flex-col items-center text-center">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738664804/7498017.png_xjmusv.png"
                alt="Customized Solutions"
                className="mb-4 w-16 h-16"
              />
              <h3 className="font-bold text-gray-800">Customized Solutions</h3>
              <p className="text-gray-600">
                We tailor our strategies to fit your specific business needs,
                ensuring that technology investments deliver maximum value.
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546301/7275836.png_zeupcw.png"
                alt="Security"
                className="mb-4 w-16 h-16"
              />
              <h3 className="font-bold text-gray-800">Commitment to Security</h3>
              <p className="text-gray-600">
                We prioritize data protection and compliance, implementing
                stringent security measures that protect your assets and build
                stakeholder trust.
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737556289/7278881.png_pl8sa1.png"
                alt="Expert Team"
                className="mb-4 w-16 h-16"
              />
              <h3 className="font-bold text-gray-800">Expert Team</h3>
              <p className="text-gray-600">
                Our professionals are seasoned in the latest technologies and
                best practices, providing insights that drive innovation and
                efficiency.
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png"
                alt="End-to-End"
                className="mb-4 w-16 h-16"
              />
              <h3 className="font-bold text-gray-800">End-to-End Support</h3>
              <p className="text-gray-600">
                From initial assessment and planning to implementation and
                ongoing management, we offer comprehensive services that cover
                every aspect of IT transformation.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Transform Your IT Infrastructure with Seismic Section */}
      <section className="mx-auto px-4 lg:px-32 mt-8 lg:mt-16">
        <div className="flex flex-col lg:flex-row items-center gap-6 lg:gap-12">
          {/* Text Section */}
          <div className="lg:w-1/2 text-center lg:text-left">
            {/* Image Section for Small Screens */}
            <div className="lg:hidden mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738161776/pexels-photo-8847193_qpwfg4.jpg"
                alt="Why Sesimic"
                className="w-full rounded-lg"
              />
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Transform Your IT Infrastructure with Seismic
            </h2>
            <p className="text-gray-600 text-base lg:text-lg leading-relaxed">
            Embrace a future-ready IT infrastructure that propels your business forward. By partnering with Seismic Consulting Group for your cloud and IT infrastructure transformation, you position your organization to harness the full potential of digital technologies. Our solutions enhance operational agility, reduce costs, and create a scalable foundation that supports future growth and innovation.
            </p>
          </div>
          {/* Image Section for Larger Screens */}
          <div className="lg:w-1/2 hidden lg:block">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737459528/free-photo-of-business-presentation-in-modern-office-setting_m4a3we.jpg"
              alt="Transform Your IT"
              className="w-full rounded-lg"
            />
          </div>
        </div>
        <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-16 mt-8"></div>
      </section>




    </div>
  );
}

export default CloudInfrastructurePage;

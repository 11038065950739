import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const projects = [
  {
    id: "tvet",
    title: "TVET Sub-Sector Strategy",
    description: "This project focused on shaping a comprehensive education sector strategy for the TVET sub-sector in Nigeria, aiming to improve the quality, accessibility, and relevance of vocational education.",
    projectTitle: "Development of Education Sector Strategy for the TVET Sub-sector in Nigeria",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745705/giz_logo_vwn73e.png",
    clientName: "GIZ",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1738745706/Glasses_t2iqsk.png",
  },
  {
    id: "mla",
    title: "Monitoring Learning Achievement (MLA)",
    description: "Seismic develops custom KPIs and evaluation tools to track and assess student performance. Our MLA services enable schools and institutions to identify learning gaps and refine their educational strategies, ensuring that students reach their full potential.",
    projectTitle: "Monitoring Learning Achievements (MLA) survey for Anambra Secondary Schools.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733493166/mla_apo3mv.png",
  },
  {
    id: "egma",
    title: "Early Grade Mathematics Assessment (EGMA)",
    description: "We offer services in both Early Grade Reading Assessment (EGRA) and Early Grade Mathematics Assessment (EGMA) to enhance foundational literacy and numeracy skills among young learners.",
    projectTitle: "Early Grade Mathematics Assessment for Bauchi and Anambra SEPIP",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733493161/track-record-8_dfyxo7.jpg",
  },
  {
    id: "sdi-surveys",
    title: "Service Delivery Indicator Surveys (SDI)",
    description: "Seismic's SDI surveys gather critical feedback from students, parents, teachers, and administrators to measure the quality of educational services.",
    projectTitle: "Service Delivery Indicators (SDI) Survey for Anambra SEPIP World Bank/SEPIP.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733493160/edu_reform_x3eqb9.jpg",
  },
  {
    id: "teacher-capacity",
    title: "Capacity Building for Teachers",
    description: "Our training programs upskill educators in modern teaching methodologies and technological tools to ensure pedagogical advancements and student engagement.",
    projectTitle: "Develop capacity enhancement plan for teaching staff in technical colleges supported by the IDEAS project.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733493162/track-record-9_tpnpoi.jpg",
  },
];

const ProjectsSection = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  // Auto-cycle through projects every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const currentProject = projects[currentProjectIndex];

  return (
    <section className="py-16 bg-white">
      {/* PC Layout */}
      <div className="hidden md:flex items-center justify-between">
        {/* Left Image */}
        <div className="pr-8 pl-4">
          <img
            src={currentProject.image}
            alt={currentProject.title}
            style={{ height: '500px', width: '600px' }}
            className="h-auto object-cover shadow-lg"
          />
        </div>

        {/* Right Content */}
        <div className="w-2/3 pl-8 space-y-8">
          {/* Heading and Subheading */}
          <div>
            <h2 className="text-4xl font-semibold">
              Innovative Solutions, Tangible Results
            </h2>
            <p className="text-lg text-gray-600 mt-2">
              Discover how our partnerships have transformed communities and environments worldwide.
            </p>
          </div>

          {/* Sector and Assignment Information with a Vertical Line */}
          <div className="flex items-center">
            {/* Left Side: Sector */}
            <div className="text-center">
              <h3 className="text-sm font-semibold text-gray-500">Sector</h3>
              <p className="text-lg font-semibold">Education and Human Service</p>
            </div>

            {/* Vertical Divider */}
            <div
              className="border-l-4 mx-6"
              style={{ borderColor: '#D6C3D2', height: '230px' }}
            ></div>

            {/* Right Side: Project Text */}
            <div className="pl-6 text-center">
              <h4 className="text-sm font-semibold text-gray-500 mb-2 text-center">
                Project
              </h4>
              <p className="text-md font-semibold mt-2 max-w-xs mx-auto">
                {currentProject.projectTitle}
              </p>

              {/* Client Information */}
              <div className="flex items-center justify-center mt-2">
                <p className="text-sm text-gray-500">Client:</p>
                <img
                  src={currentProject.clientImage}
                  alt={currentProject.clientName}
                  className="h-10 ml-2"
                />
              </div>

              {/* View Achievement Link */}
              <div className="mt-4">
                <Link
                  to={`/consulting-services/education-reform/all-education-projects/${currentProject.id}`}
                  className="text-purple-900 font-semibold hover:text-purple-900 transition"
                >
                  View Achievement &rarr;
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Toggles and Button (Centered) */}
      <div className="hidden md:flex flex-col items-center justify-center mt-8 px-4">
        {/* Purple Toggle (Slide Navigation Dots) on Desktop */}
        <div className="flex items-center gap-2 mb-8">
          {projects.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentProjectIndex(index)}
              className={`transition-all duration-300 ${
                index === currentProjectIndex
                  ? 'bg-purple-800 w-8 h-3 rounded-lg'
                  : 'bg-purple-300 w-4 h-4 rounded-full'
              }`}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>

        {/* 'See All Projects' button on Desktop */}
        <div className="text-center">
          <Link
            to="/consulting-services/education-reform/all-education-projects"
            className="inline-flex items-center px-5 py-3 border border-purple-300 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
          >
            See all Projects
            <svg
              className="w-5 h-5 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Link>
        </div>
      </div>

      {/* Phone Layout */}
      <div className="md:hidden flex flex-col items-center">
        {/* Title and Subheading */}
        <div className="text-center mb-6 px-4">
          <h2 className="text-3xl font-semibold">
            Innovative Solutions, Tangible Results
          </h2>
          <p className="text-lg text-gray-600 mt-2">
            Discover how our partnerships have transformed communities and environments worldwide.
          </p>
        </div>

        {/* Image */}
        <div className="w-full mb-6 px-4">
          <img
            src={currentProject.image}
            alt={currentProject.title}
            className="w-full h-auto object-cover shadow-lg max-h-60"
          />
        </div>

        {/* Sector Information */}
        <div className="text-center mb-6 px-4">
          <h3 className="text-sm font-semibold text-gray-500">Sector</h3>
          <p className="text-lg font-semibold">Education and Human Service</p>
        </div>

        {/* Project Text */}
        <div className="w-full max-w-md mb-6">
          <h4 className="text-sm font-semibold text-gray-500 mb-2 text-center">
            Project
          </h4>
          <p className="text-md font-semibold mt-2 text-center">
            {currentProject.projectTitle}
          </p>

          {/* Client Information */}
          <div className="flex items-center justify-center mt-2">
            <p className="text-sm text-gray-500">Client:</p>
            <img
              src={currentProject.clientImage}
              alt={currentProject.clientName}
              className="h-10 ml-2"
            />
          </div>

          {/* View Achievement Link */}
          <div className="mt-4 text-center">
            <Link
              to={`/consulting-services/education-reform/all-education-projects/${currentProject.id}`}
              className="text-purple-900 font-semibold hover:text-purple-900 transition"
            >
              View Achievement &rarr;
            </Link>
          </div>
        </div>

        {/* Slide Navigation Dots on Mobile */}
        <div className="flex justify-center items-center gap-3 mt-6">
          {projects.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentProjectIndex(index)}
              className={`transition-all duration-300 ${
                index === currentProjectIndex ? 'bg-purple-800 w-8 h-3 rounded-lg' : 'bg-purple-300 w-4 h-4 rounded-full'
              }`}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>

        {/* 'See All Projects' button only on mobile */}
        <div className="text-center mt-6 md:hidden">
          <Link
            to="/consulting-services/education-reform/all-education-projects"
            className="inline-flex items-center px-5 py-3 border border-purple-300 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
          >
            View more Projects
            <svg
              className="w-5 h-5 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M9 5l7 7-7 7" 
              />
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;

import React from "react";
import MethodologySectionCM from "./MethodologySectionCM";
import { paths } from "../../constants/paths";

const ChangeManagementFrameworks = () => {
  return (
    <div className="py-16">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736933098/9_1_lgkpzt.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
          <h1 className="text-3xl font-bold text-gray-800">
            Change Management Frameworks
          </h1>
        </div>
      </section>

      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Change Management Frameworks
        </h1>
      </div>

      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-28">
        {/* Context Section */}
        <div className="mb-16">
          <p className="text-gray-600 text-xl leading-relaxed">
            Seismic Consulting recognizes that the success of any transformation
            initiative hinges on the organization’s ability to manage change
            effectively. Through robust Change Management Frameworks, we help
            businesses navigate transitions seamlessly, ensuring that people,
            processes, and systems adapt cohesively to new realities.
          </p>
        </div>

        {/* What We Do Section */}
        <div className="mb-12 pt-2">
          <h2 className="text-2xl font-bold text-center mb-1">What We Do</h2>
          <p className="text-gray-600 text-xl leading-relaxed mb-8">
            We establish frameworks that enable organizations to manage change
            effectively, ensuring a smooth transition during transformational
            initiatives. Seismic works with organizations to establish
            comprehensive frameworks that anticipate challenges, address
            resistance, and drive successful outcomes. Our approach ensures that
            change is not only implemented but is also embraced by stakeholders
            at every level. Key actions include:
          </p>
          <ul className="list-none space-y-6 text-lg leading-relaxed">
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Facilitating Buy-In:</strong>{" "}
                Building trust and commitment among stakeholders to support the
                change process.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Mitigating Resistance:</strong>{" "}
                Proactively identifying concerns and addressing them with
                tailored strategies.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">
                  Supporting Seamless Transitions:
                </strong>{" "}
                Providing clear guidance and tools to manage the operational and
                cultural shifts required for transformation.
              </div>
            </li>
          </ul>
        </div>

        {/* Methodology Section */}
        <MethodologySectionCM />

        {/* Value to Clients Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6 text-gray-900 text-center">
            Value to Clients
          </h2>
          <p className="text-gray-600 text-xl leading-relaxed mb-8 text-center">
            Minimized resistance to change, seamless transitions, and successful
            implementation of initiatives. Seismic’s Change Management
            Frameworks deliver tangible benefits that set organizations up for
            success in dynamic environments:
          </p>
          <ul className="list-none space-y-8 text-lg leading-relaxed">
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl text-gray-900">
                  Minimized Resistance to Change:
                </strong>
                <span className="text-gray-600 text-xl">
                  {" "}
                  By addressing concerns proactively and fostering stakeholder
                  involvement, we ensure smoother transitions and widespread
                  acceptance of new initiatives.
                </span>
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl text-gray-900">
                  Seamless Transitions:
                </strong>
                <span className="text-gray-600 text-xl">
                  {" "}
                  Detailed roadmaps and clear communication reduce ambiguity,
                  creating a structured path that teams can follow confidently.
                </span>
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl text-gray-900">
                  Successful Implementation of Initiatives:
                </strong>
                <span className="text-gray-600 text-xl">
                  {" "}
                  Continuous monitoring and adaptive strategies enable
                  organizations to overcome challenges and achieve desired
                  outcomes efficiently.
                </span>
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl text-gray-900">
                  Improved Team Cohesion and Morale:
                </strong>
                <span className="text-gray-600 text-xl">
                  {" "}
                  Engaging employees at all levels fosters a sense of ownership
                  and collective purpose, reducing anxiety and enhancing morale.
                </span>
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl text-gray-900">
                  Sustained Results
                </strong>
                <span className="text-gray-600 text-xl">
                  {" "}
                  Robust frameworks ensure that the benefits of change are not
                  only achieved but also maintained in the long term.
                </span>
              </div>
            </li>
          </ul>

          <div className="mt-12">
            <p className="text-gray-600 text-xl leading-relaxed mb-8 text-center">
              Seismic’s approach to change management is distinguished by its
              emphasis on inclusivity, precision, and adaptability:
            </p>
            <ul className="list-none space-y-8 text-lg leading-relaxed">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl text-gray-900">
                    Tailored Solutions:
                  </strong>
                  <span className="text-gray-600 text-xl">
                    {" "}
                    Frameworks are customized to reflect the unique culture,
                    goals, and challenges of each organization
                  </span>
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl text-gray-900">
                    People-Centric Approach:
                  </strong>
                  <span className="text-gray-600 text-xl">
                    {" "}
                    A focus on stakeholder engagement ensures that individuals
                    at all levels are aligned and empowered.
                  </span>
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl text-gray-900">
                    Outcome-Oriented Execution:
                  </strong>
                  <span className="text-gray-600 text-xl">
                    {" "}
                    With a strong emphasis on measurable results, we prioritize
                    solutions that deliver value..
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Seismic’s expertise in Change Management Frameworks */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-wrap lg:flex-nowrap gap-12 items-center">
          {/* Image Section */}
          <div className="flex-shrink-0 w-full lg:w-1/2">
            <img
              src=""
              alt="Why Choose Seismic Consulting"
              className="w-full"
            />
          </div>

          {/* Text Section */}
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl font-medium mb-6">
              Seismic’s expertise in Change Management Frameworks
            </h2>

            <p className="text-gray-600 text-xl leading-relaxed">
              Seismic’s Change Management Frameworks are designed to transform
              challenges into opportunities. By combining detailed planning with
              proactive engagement and adaptive learning, we enable
              organizations to navigate change with confidence. Our proven
              methodologies ensure not only the smooth implementation of
              transformational initiatives but also the long-term success and
              resilience of our clients in an ever-evolving business landscape.
            </p>
          </div>
        </div>
      </section>

            {/* Related Services Section */}
            <div className="max-w-8xl mx-auto px-6 lg:px-12 mt-8 lg:mt-12 ml-8">
              <h2 className="text-2xl font-bold mb-12 text-center">Seismic’s Approach to Change Management</h2>
              <div className="grid md:grid-cols-3 gap-8">
                {[
                          {
                            title: "Adaptive Strategy Development",
                            description:
                              "In an era defined by constant evolution in market dynamics, regulatory landscapes, and technological innovation, Adaptive Strategy Development is central to Seismic's approach to Change Management & Strategy Consulting.",
                            image:
                              "https://res.cloudinary.com/dxhx45ing/image/upload/v1736871131/4_pnllia.jpg",
                            link: `/consulting-services/${paths.changeManagementPage}/${paths.adaptiveStrategyDevelopment}`,
                          },
                          {
                            title: "Digital Transformation",
                            description:
                              "Empowering organizations to embrace digital innovation, streamline operations, and enhance customer experiences.",
                            image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736932864/5_1_ldv5s1.jpg",
                            link: "/consulting-services/change-management-page/digital-transformation",
                          },
                  {
                    title: "Capacity Building for Change Leaders",
                    description:
                      "Equipping leaders with the tools and skills needed to inspire, guide, and sustain change within their organizations.",
                    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736933370/8_1_q1oige.jpg",
                    link: "/consulting-services/change-management-page/capacity-building-for-change-leaders",
                  },
                ].map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    className="block text-left hover:shadow-lg transition-shadow duration-300"
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      className="mb-4 w-full h-[300px] object-cover"
                    />
                    <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                    <p className="text-gray-600 text-sm mb-2">{item.description}</p>
                    <span className="text-purple-900 font-bold text-sm">Read More</span>
                  </a>
                ))}
              </div>
            </div>
    </div>
  );
};

export default ChangeManagementFrameworks;

import React from 'react';
import { Link } from 'react-router-dom';

const InsightsSection = () => {
  const articles = [
    {
      category: 'Technology Consulting',
      title: 'Sustainable Urban Development through Smart City Solutions',
      date: 'January 28, 2025',
      description:
        'Imagine a city where morning commutes are seamless, the air is clean, and resources are never wasted. Meet Amara, a young entrepreneur in Lagos, Nigeria. Her day begins with a smartphone app powered by the city’s integrated smart grid, guiding her to optimize her energy use and save costs. Intelligent traffic systems direct her route, and electric buses provide an eco-friendly commute. Digital governance tools save her time, and citizen engagement apps keep her connected to community projects. Amara’s story exemplifies how smart cities enhance quality of life, foster economic growth, and promote sustainability.....',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1738745714/techroom_vodlyq.jpg',
      alt: 'Innovation in Consulting',
      link: '/insights/smart-city-solutions',
    },
    {
      category: 'Audit and Assurance',
      title: 'Independent Verification Agents (IVA): Bridging the Gap Between Goals and Outcomes',
      date: 'January 23, 2025',
      description:
        'In today’s dynamic business environment, where competition is fierce and stakeholders demand measurable outcomes, the principles of transparency, accountability, and efficiency have evolved from mere ideals to critical prerequisites for success. Businesses, consultancies, governments, and development organizations are under increasing pressure to demonstrate that resources are utilized effectively, objectives are met, and results are delivered with integrity. In this context, IVAs have emerged as a cornerstone of project oversight and evaluation....',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1738745774/insights3_xj4hxc.png',
      alt: 'Corporate Strategy',
      link: '/insights/iva-insight',
    },
  ];

  return (
    <section className="py-16 bg-white 2xl:max-w-[90%] 2xl:mx-auto">
      {/* Heading and Subheading */}
      <div className="text-center mb-12">
        <h2 className="text-3xl sm:text-4xl font-bold">
          Insights, Trends, and Expert Perspectives
        </h2>
        <p className="text-lg sm:text-xl text-gray-600 mt-2">
          Discover How Seismic Consulting Is Shaping The Future Across Industries.
        </p>
      </div>

      {/* Articles for Medium and Larger Screens */}
      <div className="hidden md:grid grid-cols-1 md:grid-cols-2 gap-8 mb--4">
        {/* First Article */}
        <div>
          <p className="text-purple-900 font-semibold mb-2 text-lg">
            {articles[0].category}
          </p>
          <h3 className="text-xl font-semibold mb-14 leading-tight">
            {articles[0].title}
          </h3>
          <p className="text-gray-500 text-base mb-2">{articles[0].date}</p>
          <p className="text-gray-700 mb-6">
            {articles[0].description}
            <Link
              to={articles[0].link}
              className="text-purple-900 font-semibold ml-1"
            >
              Read more
            </Link>
          </p>
        </div>
        <div>
          <img
            src={articles[0].image}
            alt={articles[0].alt}
            className="w-full h-auto object-cover mb-0"
            style={{ height: '350px', width: '650px' }}
          />
        </div>

        {/* Second Article */}
        <div>
          <img
            src={articles[1].image}
            alt={articles[1].alt}
            className="w-full h-auto object-cover mb-6"
            style={{ height: '350px', width: '700px' }}
          />
        </div>
        <div>
          <p className="text-purple-900 font-semibold mb-2 text-lg">
            {articles[1].category}
          </p>
          <h3 className="text-xl font-semibold mb-14 leading-tight">
            {articles[1].title}
          </h3>
          <p className="text-gray-500 text-base mb-2">{articles[1].date}</p>
          <p className="text-gray-700 mb-6">
            {articles[1].description}
            <Link
              to={articles[1].link}
              className="text-purple-900 font-semibold ml-1"
            >
              Read more
            </Link>
          </p>
        </div>
      </div>

      {/* Articles for Small Screens (Phones) */}
      <div
        className="md:hidden overflow-x-auto flex"
        style={{ scrollSnapType: 'x mandatory' }}
      >
        {articles.map((article, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-full text-center px-4"
            style={{ scrollSnapAlign: 'start' }}
          >
            <img
              src={article.image}
              alt={article.alt}
              className="w-full h-56 object-cover mb-4 rounded-md mx-auto"
            />
            <p className="text-purple-900 font-semibold mb-2 text-base">
              {article.category}
            </p>
            <h3 className="text-lg font-bold mb-4 leading-tight px-2">
              {article.title}
            </h3>
            <p className="text-gray-500 text-sm mb-2">{article.date}</p>
            <p className="text-gray-700 mb-6 px-4 text-sm">
              {article.description}
              <Link
                to={article.link}
                className="text-purple-900 font-semibold ml-1"
              >
                Read more
              </Link>
            </p>
          </div>
        ))}
      </div>

      <div className="mt-8 text-center">
        <Link
          to="/insights"
          className="inline-flex items-center px-5 py-3 border border-purple-500 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
        >
          Read More On Our Blog
          <svg
            className="w-4 h-4 lg:w-5 lg:h-5 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
      </div>
    </section>
  );
};

export default InsightsSection;

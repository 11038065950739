export const projectsData = {
  "Learning Assessment Studies": [
    {
      id: "mla",
      title: "Monitoring Learning Achievement (MLA)",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493166/mla_apo3mv.png",
      overview: `
        Seismic develops custom KPIs and evaluation tools to track and assess student performance. Our MLA services enable schools and institutions to identify learning gaps and refine their educational strategies, ensuring that students reach their full potential. The insights we provide serve as the backbone for continuous improvement in teaching quality and learner success.
      `,
      description: `
        Project: Monitoring Learning Achievements (MLA) survey for Anambra Secondary Schools. The main objective of the MLA study is to measure the impact of government policy actions and investments on learning outcomes in a specific location (e.g., State, Country, and Continent).
      `,
      year: "2019",
      role: "Sole Consultant",
      client: "World Bank/SEPIP",
      clientLogo: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png", 
      objectives: [
        "Equity: rural-urban allocation of resources, differences in rural-urban learning outcomes.",
        "Quality: Students’ scores in tested learning domains and their respective components, teacher and head-teachers' level of education and training.",
        "Endogenous factors affecting learning outcomes: - Students’ perception of education, school environment, class teachers, and core courses. - Class teachers’ education level, professional training, teaching style, work conditions, and preferred profession. - Head-teachers’ education and training, work conditions, preferred profession, adequacy, and quality of subject or class teachers.",
        "Exogenous factors affecting learning outcomes, including students’ socio-economic background, walking distance to school, parents’ attitude to education, parents’ education level, and after-school activities."
      ]
    },
    {
      id: "egra",
      title: "Early Grade Reading Assessment (EGRA)",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493161/track-record-8_dfyxo7.jpg",
      overview: [
        "At Seismic Consulting, we offer comprehensive services in both Early Grade Reading Assessment (EGRA) and Early Grade Mathematics Assessment (EGMA) to evaluate and enhance foundational literacy and numeracy skills among young learners. Our EGRA services focus on assessing and developing key literacy skills, ensuring that students acquire the reading abilities necessary to excel in higher education and beyond. We design strategies that foster literacy development from the earliest stages of learning, setting the foundation for academic success.",
        
        "In parallel, our EGMA services assess and improve foundational mathematics skills. We design, administer, and analyze EGMA to provide actionable insights that guide targeted interventions, helping education systems align with curriculum standards and promote measurable improvements in mathematics education. Seismic collaborates closely with schools, education ministries, and stakeholders to ensure both EGRA and EGMA assessments support long-term success, preparing students for future challenges in their academic and professional journeys."
      ],
      description: `
        Project: Early Grade Learning Assessment (EGRA) for the five NIPEP participating states - Kaduna, Kano, Katsina, Jigawa, and Sokoto for World Bank/NIPEP.
      `,
      year: "2017",
      role: "Sole Consultant",
      client: "World Bank/NIPEP",
      clientLogo: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png", 
      objectives: [
        "Undertake an EGRA survey in the listed States to ascertain if the students are learning what they ought to and to highlight whether they are achieving a satisfactory level of performance in critical reading domains.",
        "Carry out Early Grade Reading Assessment survey within the NIPEP focused States/LGAs and Selected Schools with a view to assessing the impact of the intervention on the learners/pupils/student.",
        "Availing the NIPEP/WORLD BANK team with data arising from the assignment.",
        "Familiarizing relevant project staff with the Learning Assessment report.",
        "Demonstrate effectiveness/otherwise of the NIPEP Grants in achieving the desired outcomes.",
        "Providing any other information/document as may be required by the client."
      ]
    },
    {
      id: "egma",
      title: "Early Grade Mathematics Assessment (EGMA)",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493159/ecd_ssu7nx.jpg",
      overview: `
        At Seismic Consulting, we specialize in Early Grade Mathematics Assessment (EGMA) services to evaluate and enhance foundational numeracy skills among young learners. Our EGMA services focus on assessing and developing key mathematical skills, ensuring that students acquire the abilities necessary for academic success. We design, administer, and analyze EGMA to provide actionable insights that guide targeted interventions, helping education systems align with curriculum standards.
      `,
      description: `
        Project: Early Grade Mathematics Assessment for Bauchi and Anambra SEPIP.
      `,
      year: "2017",
      role: "Sole Consultant",
      client: "World Bank/SEPIP",
      clientLogo: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png", 
      objectives: [
        "Diagnostic tool to determine how students are performing overall compared to stated curriculum in mathematics.",
        "Equity: rural urban allocation of resources, differences in rural-urban learning outcomes.",
        "Evaluate the education system with respect to its specific curricula and intervention programs."
      ]
    }
  ],
  "Learning Management Systems (LMS)": [
    {
      id: "curriculum-dev",
      title: "Curriculum Development & Learning Management Systems",
      description: "Conduct Effective Remote Outreach & Stakeholder Engagement Training for Independent Development Evaluation (IDEV) Staff at the African Development Bank (AfdB)",
      year: "2021",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493160/education_focus_avmxqk.jpg",
      objectives: [
        "Design a stakeholder engagement process for IDEV staff.",
        "Provide remote outreach and engagement training considering COVID-19 impacts.",
        "Develop evidence-based curriculum for engagement, mapping, and analysis."
      ]
    },
    {
      id: "learning-mgmt-systems",
      title: "Learning Management Systems",
      description: "Supported EMIS and LMS development for the World Bank Nigeria Partnership for Education Project (NIPEP)",
      year: "2017",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493166/higher_education_uzd3iq.jpg",
      objectives: [
        "Review the EMIS concept note and provide recommendations.",
        "Develop system design and integration plans for EMIS and LMS.",
        "Train education officers and teachers on data input, course management, and reporting."
      ]
    }
  ],
  "Other Projects": [
    {
      id: "blended-learning",
      title: "Blended Learning Programs",
      description: "Digital Skills Training Programs for informal Vocational Education and Training (TVET) sector.",
      year: "2023",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733494509/Glasses_djumkb.png",
      objectives: [
        "Design a blended training program combining online and face-to-face learning.",
        "Develop e-modules and interactive quizzes for the informal VET sector.",
        "Configure an LMS to host digital training content and track participant progress."
      ]
    },
    {
      id: "teacher-capacity",
      title: "Capacity Building for Teachers",
      description: "Capacity enhancement plan for technical college teaching staff under the IDEAS project.",
      year: "2022",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1734690580/medium-shot-woman-teaching-lesson_23-2149272233_zsnogw.avif",
     // image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493162/track-record-9_tpnpoi.jpg",
      objectives: [
        "Review studies to map out current teacher capacities and skills gaps.",
        "Propose a sustainable capacity enhancement plan for technical colleges.",
        "Provide strategies to sustain capacity enhancement beyond donor funding."
      ]
    },
    {
      id: "resource-mapping",
      title: "Education Resource Mapping",
      description: "Technical Assistance (M&E) support for the AGILE Project in Kano State.",
      year: "2022",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493170/secondary_education_ep9hud.jpg",
      objectives: [
        "Develop an M&E Result Framework for AGILE projects.",
        "Create a step-by-step guideline to implement the M&E Result Framework.",
        "Build staff capacity to operationalize the developed guideline."
      ]
    },
    {
      id: "digital-skilling",
      title: "Digital Skilling & Technology Integration",
      description: "Digital tracer studies methodology for skills development graduates in Nigeria.",
      year: "2023",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493166/higher_education_uzd3iq.jpg",
      objectives: [
        "Identify ICT applications suitable for tracer studies of TVET graduates.",
        "Design an ICT ecosystem for generating tracer study information.",
        "Deploy tracer studies application software on the NBTE website."
      ]
    },
    {
      id: "out-of-school-children",
      title: "Out-of-School Children",
      description: "Project Completion Report for the 17 State Universal Basic Education Board - BESDA Project",
      year: "2024",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493159/digital_skilling_h6ww2m.jpg",
      objectives: [
        "Assess project outcomes against established indicators.",
        "Develop plans to expand school infrastructure and teacher resources.",
        "Create literacy improvement plans at school levels."
      ]
    },
    {
      id: "sdi-surveys",
      title: "Service Delivery Indicator Surveys (SDI)",
      description: "SDI Survey for Anambra SEPIP to measure educational service quality.",
      year: "2019",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1734357864/pexels-katerina-holmes-5905554_rixbf3.jpg",
      objectives: [
        "Assess frontline education service providers' capabilities.",
        "Evaluate school infrastructure quality.",
        "Identify service delivery indicators that impact education performance."
      ]
    }
  ]
};

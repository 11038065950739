import React, { useState } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";
import AgricultureFocusAreas from "./AgricultureFocusAreas";

const AgricultureAgribusiness = () => {
  const [activeFocus, setActiveFocus] = useState("Precision Agriculture");
  const [openSections, setOpenSections] = useState({});

  const toggleCollapse = (title) => {
    setOpenSections((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  const focusAreas = [
    "Precision Agriculture",
    "Sustainable Farming Practices",
    "Agri-Fintech Solutions",
    "Supply Chain Optimization",
  ];

  return (
    <div className="pb-16">
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737463439/pexels-rarnie-mccudden-775207_egmanw.jpg')" }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Agriculture and Agribusiness
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            We empower farmers, agribusinesses, and organizations to embrace transformative practices that boost productivity, advance sustainability, and strengthen food security.
          </p>
        </div>
      </section>

      {/* Context Section */}
      <div className="max-w-6xl mx-auto px-4 lg:px-12 mt-16 text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Driving Innovation and Sustainability in Agriculture
        </h2>
        <p className="text-lg text-gray-600 leading-relaxed">
        Seismic Consulting Group is dedicated to redefining the agriculture and agribusiness landscape through innovative strategies designed to address the sector's most critical challenges. In a world where the demand for food continues to rise alongside mounting environmental pressures, we empower farmers, agribusinesses, and organizations to embrace transformative practices that boost productivity, advance sustainability, and strengthen food security. By integrating cutting-edge technology, sustainable methodologies, and strategic expertise, we deliver customized solutions that enhance efficiency, safeguard ecosystems, and create enduring value for all stakeholders.
        </p>
      </div>

<AgricultureFocusAreas />


{/* Digital Projects Introduction for Agriculture */}
<div className="max-w-7xl mx-auto px-6 lg:px-12 mt-20">
  <div className="flex flex-col md:flex-row items-center gap-8">
    {/* Left Column: Image */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739802685/pexels-photo-8939267_sadedy.jpg"
        alt="Digital Agriculture Projects"
        className="w-full rounded-lg shadow-md"
      />
    </div>
    {/* Right Column: Text Content */}
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        Empowering Agriculture with Digital Innovation
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        Our digital agriculture projects harness cutting-edge technology to boost market access, enhance trade efficiency, and improve supply chain transparency. By connecting African farmers, cooperatives, and agribusinesses with global markets, we empower stakeholders to thrive in a competitive digital era.
      </p>
      <Link
        to={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalAgriculture}`}
        className="inline-flex items-center px-6 py-3 border border-purple-900 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
      >
        View Digital Projects
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  </div>
</div>

    </div>
  );
};

export default AgricultureAgribusiness;

import React, { useState } from "react";

const UrbanMobility = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const expertiseData = [
    {
      title: "Enhanced Public Transportation Networks",
      description:
        "Reliable public transit is the backbone of a well-functioning city. We work with stakeholders to design and implement integrated public transportation systems that:",
      points: [
        "Improve Connectivity: Develop multimodal transit networks that connect buses, trains, subways, and other modes of transportation for seamless travel.",
        "Increase Accessibility: Ensure equitable access to transportation services by addressing the needs of underserved communities and individuals with disabilities.",
        "Enhance Reliability and Efficiency: Leverage real-time tracking and scheduling technologies to minimize delays, optimize routes, and increase passenger satisfaction.",
      ],
    },
    {
      title: "Traffic Congestion Solutions",
      description:
        "Urban congestion is a significant impediment to economic productivity and environmental sustainability. Our intelligent traffic management systems and alternative transit strategies include:",
      points: [
        "Smart Traffic Systems: Deploying AI-powered tools to monitor traffic flow, predict congestion patterns, and adjust traffic signals in real-time.",
        "Carpooling and Ride-Sharing Integration: Encouraging shared transportation options to reduce the number of vehicles on the road.",
        "Infrastructure Redesign: Reconfiguring roadways to prioritize high-capacity transit lanes, pedestrian pathways, and bike lanes.",
      ],
    },
    {
      title: "Micro-Mobility Innovations",
      description:
        "Last-mile connectivity remains a critical challenge in urban transportation. We support the adoption of shared mobility solutions that address this issue while promoting sustainability, including:",
      points: [
        "E-Scooters and E-Bikes: Integrating eco-friendly, shared micro-mobility options that provide convenient, short-distance travel solutions.",
        "Bike-Sharing Programs: Designing and implementing bike-sharing systems tailored to urban environments, reducing the need for car-based short trips.",
        "Integration with Public Transit: Ensuring micro-mobility services complement existing public transportation systems for a holistic urban mobility ecosystem.",
      ],
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739186675/pexels-photo-3229622_dtwgkh.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Urban Mobility Solutions
          </h1>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Redesigning Urban Transit for Resilient, Livable Cities
        </h2>
        <p className="text-gray-700 text-lg mb-4">
          As urban populations grow and the demand for seamless connectivity intensifies, cities face mounting challenges in ensuring efficient and sustainable mobility. At Seismic Consulting Group, we collaborate with governments, municipalities, and private enterprises to reimagine urban transportation systems that meet the needs of modern cities. Our tailored urban mobility solutions focus on enhancing accessibility, reducing congestion, minimizing environmental impact, and fostering economic growth.
        </p>
      </div>

      {/* Expertise Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Key Areas of Expertise
        </h2>
        {expertiseData.map((item, index) => (
          <div key={index} className="mb-12 border-b border-pink-100 pb-8">
            <button
              onClick={() => toggleSection(index)}
              className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
            >
              {item.title}
              <span className="text-purple-900 text-3xl">
                {openSection === index ? "-" : "+"}
              </span>
            </button>
            {openSection === index && (
              <div>
                <p className="text-gray-700 text-lg mb-4">{item.description}</p>
                {item.points && (
                  <ul className="list-none text-gray-700 text-lg space-y-4 mt-4 px-6 lg:px-32">
                    {item.points.map((point, idx) => (
                      <li key={idx} className="relative pl-6">
                        <span
                          className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 border-purple-900 rounded-full"
                        ></span>
                        {point}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Why Seismic Section */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
          {/* Text Section */}
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-semibold mb-4">Future-Ready Urban Mobility for Thriving Cities</h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
              Urban mobility is more than just moving people and goods—it’s about creating cities that are livable, sustainable, and economically vibrant. At Seismic Consulting Group, we combine cutting-edge technology, strategic planning, and stakeholder collaboration to develop transformative mobility systems tailored to each city’s unique needs.
            </p>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
            Whether enhancing public transportation, mitigating traffic congestion, or pioneering micro-mobility
innovations, our solutions empower cities to meet the challenges of today while preparing for the
demands of tomorrow. Together, we help cities build mobility systems that are not only efficient and
sustainable but also catalysts for long-term growth and resilience.
            </p>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737555875/pexels-photo-68427_syfk11.jpg"
              alt="Urban Mobility Solutions"
              className="w-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default UrbanMobility;

import React from "react";

const MonitoringEvaluation = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="bg-white pb-16 mt-20">
        <section
          className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733515176/pexels-fauxels-3184639_hjpkle.jpg')", // Replace with your actual image URL
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          {/* Title Overlay for Large Screens */}
          <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
            <h1 className="text-3xl font-bold text-gray-800">
              Monitoring, Evaluation and Adaptive Learning
            </h1>
          </div>
        </section>

        {/* Title Under Image for Small Screens */}
        <div className="block lg:hidden mt-4">
          <h1 className="text-2xl font-bold text-gray-800 text-center">
            Monitoring, Evaluation and Adaptive Learning
          </h1>
        </div>
      </section>

      {/* Context Section */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed mb-6">
          Continuous monitoring and evaluation are central to our approach.
          Seismic uses adaptive learning to refine and improve social protection
          programs over time, ensuring that each initiative remains responsive
          to evolving community needs and delivers measurable results.
        </p>
        <p className="text-xl text-gray-600 leading-relaxed">
          At Seismic Consulting Group, we believe that effective Monitoring,
          Evaluation, and Adaptive Learning (MEAL) are key to ensuring that
          social protection programs are impactful, sustainable, and responsive
          to the changing needs of vulnerable populations. Continuous assessment
          of programs allows us to track progress, identify challenges early,
          and make necessary adjustments to improve outcomes. Our approach
          integrates learning into the entire project lifecycle, ensuring that
          we not only evaluate results but also adapt and innovate to enhance
          program delivery.
        </p>
      </section>

      {/* How We Execute Section */}
      <section className="py-8 px-8 lg:px-64">
        <h2 className="text-2xl lg:text-3xl font-medium text-gray-800 mb-6">
          Here's how Seismic applies MEAL to drive social protection and poverty
          reduction:
        </h2>
        <div className="space-y-10">
          {/* Continuous Monitoring */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Continuous Monitoring
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Monitoring is an ongoing process that allows us to assess whether
              a program is being implemented as planned and is reaching its
              target populations. Seismic uses real-time data collection
              methods, including digital tools and mobile platforms, to track
              inputs, activities, and outputs. This allows us to measure how
              resources are being utilized, assess beneficiary engagement, and
              identify any deviations from the program design early in the
              implementation phase. Monitoring helps us stay informed about the
              operational aspects of the program and ensures that we can make
              timely interventions if necessary.
            </p>
          </div>

          {/* Outcome and Impact Evaluation */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Outcome and Impact Evaluation
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Evaluation is conducted at strategic points throughout the
              lifecycle of the program to measure its effectiveness in achieving
              the intended outcomes. Seismic uses both formative evaluations
              (during the program) and summative evaluations (at the end of the
              program) to assess the broader social impact. These evaluations
              assess not just whether the program achieved its immediate goals
              but also whether it contributed to long-term poverty reduction and
              improved social well-being for the target populations. Key
              indicators such as improvements in income levels, access to
              services (healthcare, education, housing), and employment
              opportunities are used to gauge success.
            </p>
          </div>

          {/* Adaptive Learning and Iteration */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Adaptive Learning and Iteration
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Adaptive learning is a critical component of Seismic's approach,
              focusing on using insights from monitoring and evaluation to
              refine and improve programs. We do not wait until the end of a
              program to make changes—adaptive learning encourages us to iterate
              continuously. For example, if a particular intervention is not
              delivering the expected results or if new challenges emerge,
              Seismic can modify the approach based on data, feedback from
              beneficiaries, or changes in the local context. This ensures that
              the program remains relevant and responsive to community needs,
              maximizing its potential for success.
            </p>
          </div>

          {/* Beneficiary Feedback */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Beneficiary Feedback and Participatory Approaches
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              To ensure that the voices of the communities we serve are heard,
              Seismic integrates beneficiary feedback into the monitoring and
              evaluation process. Through regular consultations, surveys, focus
              groups, and community meetings, we gather input directly from the
              people who are most affected by the program. This participatory
              approach ensures that our interventions are tailored to the lived
              experiences of the target populations, allowing for more accurate
              assessments of needs and program performance. By actively
              involving beneficiaries in the evaluation process, we foster a
              sense of ownership and increase the likelihood of program success.
            </p>
          </div>

          {/* Data-Driven Decision Making */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Data-Driven Decision Making
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Seismic uses data analytics to analyze monitoring and evaluation
              findings, identifying trends, successes, and challenges. This
              data-driven approach allows us to make informed decisions about
              program adjustments, resource allocation, and the scaling of
              successful interventions. Through advanced tools such as geospatial
              mapping, predictive analytics, and statistical models, we gain
              deep insights into program performance, helping to prioritize
              resources and make evidence-based decisions that enhance
              effectiveness.
            </p>
          </div>


          <div>
    <h3 className="text-2xl font-medium text-gray-800 mb-2">
      Learning Loops and Knowledge Sharing
    </h3>
    <p className="text-lg text-gray-600 leading-relaxed">
      An essential part of adaptive learning is creating learning loops, where
      insights from monitoring and evaluation are used to inform not only the
      current program but also future interventions. Seismic encourages
      internal reflection, cross-program knowledge sharing, and external
      learning through networks and partnerships. By continually learning from
      past successes and failures, we refine our strategies and improve the
      overall effectiveness of social protection programs.
    </p>
  </div>

  {/* Sustainability and Long-Term Impact */}
  <div className="mt-10">
    <h3 className="text-2xl font-medium text-gray-800 mb-2">
      Sustainability and Long-Term Impact
    </h3>
    <p className="text-lg text-gray-600 leading-relaxed">
      Effective monitoring, evaluation, and adaptive learning contribute to the
      long-term sustainability of social protection programs by ensuring they
      remain responsive and evolve with the needs of the community. As programs
      mature, they may require adjustments to reflect changing political,
      economic, or social conditions. By systematically evaluating outcomes and
      incorporating lessons learned, Seismic helps ensure that programs
      continue to drive positive, lasting change beyond their initial
      implementation phase.
    </p>
  </div>

  {/* Accountability and Transparency */}
  <div className="mt-10">
    <h3 className="text-2xl font-medium text-gray-800 mb-2">
      Accountability and Transparency
    </h3>
    <p className="text-lg text-gray-600 leading-relaxed">
      Seismic also integrates accountability into its MEAL approach, ensuring
      that stakeholders, including governments, donors, and communities, are
      kept informed of the program's progress and outcomes. Regular reporting,
      data sharing, and open communication channels foster trust and ensure
      that all parties are held accountable for the program's success.
      Transparency in evaluation findings also allows for course correction, if
      necessary, and reassures stakeholders that funds and resources are being
      used efficiently and effectively.
    </p>
  </div>

        </div>
      </section>


      {/* Conclusion */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed">
          By integrating Monitoring, Evaluation, and Adaptive Learning into the
          core of social protection programs, Seismic ensures that interventions
          are not only effective in the short term but also evolve to meet the
          changing needs of vulnerable populations. This approach maximizes
          impact, fosters innovation, and drives sustainable poverty reduction.
        </p>
      </section>


      {/* Seismic's Approach Section */}
<section className="py-16 px-4 lg:px-20">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Seismic's Approach to Reducing Poverty
    </h2>
    <p className="text-lg text-gray-600 text-center mb-10">
      Our approach combines rigorous analysis with creative problem-solving
      to address the root causes of poverty and inequality.
    </p>

    {/* Row 1 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 1 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851836/pexels-tima-miroshnichenko-6474494_nbhrkk.jpg"
          alt="Comprehensive Needs Assessment"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2">
              Comprehensive Needs Assessment and Analysis
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              By analyzing demographic data, economic trends, and social indicators,
              we tailor our social protection strategies to address root causes of
              poverty specific to each region.
            </p>
          </div>
          <a
            href="/consulting-services/social-protection/comprehensive-needs-assessment"
            className="text-purple-700 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>

      {/* Box 2 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Integrated Social Protection Frameworks
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            We create holistic social protection frameworks that integrate safety
            nets, job creation initiatives, and targeted social programs. Our
            frameworks combine interventions into a cohesive support system.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/integrated-social-protection"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>

    {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Innovative Financing Mechanisms
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Recognizing the financial challenges in funding large-scale social
            protection programs, Seismic leverages innovative financing models such
            as social impact bonds, micro-loans, and public-private partnerships.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/innovative-financing-mechanisms"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851169/pexels-weekendplayer-187041_voljsh.jpg"
          alt="Innovative Financing Mechanisms"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-1">
              Data-Driven Targeting and Predictive Analytics
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              In the modern landscape of social protection and poverty reduction,
              traditional methods identifying and reaching vulnerable populations
              can be inefficient, often leading to misallocation of resources.
            </p>
          </div>
          <a
            href="/consulting-services/social-protection/data-driven-targeting"
            className="text-purple-700 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>

  </div>
</section>
    </div>
  );
};

export default MonitoringEvaluation;

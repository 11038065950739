import React from "react";

function WorkforceEnablement() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Workforce Enablement and Cultivating a Digital Culture
          </h1>
        </div>
      </section>

      {/* Intro Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            At Seismic Consulting Group, we understand that the success of digital
            transformation hinges on an empowered workforce and a culture that embraces
            innovation. We specialize in enabling organizations to foster a digital mindset
            among their employees, ensuring seamless technology adoption and maximizing the
            benefits of new tools and methodologies.
          </p>
        </div>
      </section>

      {/* Digital Collaboration Tools Implementation */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">
              Digital Collaboration Tools Implementation
            </h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              In today's dynamic business environment, effective collaboration is crucial—
              especially with the rise of remote work and geographically dispersed teams. Seismic
              facilitates the integration of advanced digital collaboration platforms such as Microsoft
              Teams, Slack, and sophisticated project management software.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li>
                <strong>Strategic Assessment:</strong> Evaluating your organization's collaboration needs
                to recommend the most suitable tools.
              </li>
              <li>
                <strong>Seamless Integration:</strong> Ensuring that new platforms are smoothly incorporated
                into existing workflows and systems.
              </li>
              <li>
                <strong>User Training and Adoption:</strong> Providing comprehensive training programs to
                enhance user proficiency and promote widespread adoption.
              </li>
              <li>
                <strong>Ongoing Support:</strong> Offering continuous support to address challenges and
                optimize the use of collaboration tools.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* AI-Augmented Workforce Solutions */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">
              AI-Augmented Workforce Solutions
            </h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Seismic empowers your employees by integrating Artificial Intelligence tools that enhance
              productivity and efficiency across various functions.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li>
                <strong>Intelligent Automation:</strong> Implementing AI-driven chatbots and virtual
                assistants to streamline customer service, HR inquiries, and IT support, freeing up staff
                to focus on strategic tasks.
              </li>
              <li>
                <strong>Data-Driven Insights:</strong> Equipping sales and marketing teams with AI analytics
                tools that provide actionable insights, optimize strategies, and improve decision-making.
              </li>
              <li>
                <strong>Personalized Employee Experiences:</strong> Utilizing AI to customize learning and
                development paths, boosting engagement and accelerating skill acquisition.
              </li>
            </ul>
            <p className="text-gray-700 mt-6">
              Our approach ensures that AI technologies complement human capabilities, creating a
              synergistic environment where employees are empowered rather than replaced.
            </p>
          </div>
        </div>
      </section>

      {/* Digital Upskilling and Continuous Learning Programs */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">
              Digital Upskilling and Continuous Learning Programs
            </h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Recognizing that technology evolves rapidly, Seismic designs and implements continuous learning
              and development programs focused on enhancing digital skills and fostering an innovative mindset.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li>
                <strong>Skill Gap Analysis:</strong> Conducting comprehensive assessments to identify areas
                where digital competencies need strengthening.
              </li>
              <li>
                <strong>Customized Training Curricula:</strong> Developing tailored programs that address
                specific organizational needs, from basic digital literacy to advanced technical skills.
              </li>
              <li>
                <strong>Modern Learning Platforms:</strong> Implementing learning management systems (LMS)
                that provide flexible, on-demand access to training resources.
              </li>
              <li>
                <strong>Innovation Workshops:</strong> Facilitating workshops and seminars that encourage
                creative thinking, collaboration, and the adoption of best practices.
              </li>
            </ul>
            <p className="text-gray-700 mt-6">
              By investing in your workforce's development, we help cultivate a culture of continuous improvement
              and adaptability, essential for long-term success in the digital age.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WorkforceEnablement;

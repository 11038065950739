import React from "react";

const MonitoringAndEvaluation = () => {
  return (
    <section className="bg-white pb-16 mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage: "url('https://your-image-url.com')", // Replace with actual image URL
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div
          className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]"
        >
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Monitoring and Evaluation
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Regular Assessment of Program Effectiveness and Feedback Loops for Continuous Improvement
          </p>
        </div>
      </section>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 py-12">
        {/* Introduction */}
        <div className="mb-12">
          <p className="text-lg text-gray-600 leading-relaxed">
            In Seismic’s Capacity Building & Human Resource Development methodology, Monitoring and Evaluation (M&E) is a pivotal phase that ensures programs deliver tangible, measurable results and continuously evolve to meet emerging needs. By embedding M&E into every stage of its programs, Seismic guarantees accountability, responsiveness, and sustained impact.
          </p>
        </div>

        {/* Regular Assessment Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Regular Assessment of Program Effectiveness
          </h2>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Establishing Clear Metrics:</strong> At the outset, Seismic defines clear, measurable objectives and performance indicators for each program. These metrics may include skill acquisition rates, participant satisfaction levels, on-the-job application of new skills, and organizational performance improvements.
            </li>
            <li>
              <strong>Quantitative and Qualitative Methods:</strong> Seismic utilizes a mix of quantitative (e.g., test scores, participation rates) and qualitative (e.g., participant feedback, interviews, case studies) evaluation methods. This balanced approach provides a comprehensive understanding of the program’s impact and helps uncover insights that may not be evident through data alone.
            </li>
            <li>
              <strong>Pre- and Post-Training Assessments:</strong>  To measure the effectiveness of training interventions, Seismic conducts pre- and post-training assessments. These assessments gauge participants' skill levels, knowledge, and confidence before and after the program, highlighting areas of growth and demonstrating the program’s value.
            </li>
            <li>
              <strong>On-the-Job Performance Reviews:</strong>  Beyond the training environment, Seismic evaluates how participants apply their newfound skills in their roles. This includes monitoring changes in productivity, leadership effectiveness, communication skills, and problem-solving abilities in real-world settings.
            </li>
            <li>
              <strong>Benchmarking and Comparisons:</strong>  Seismic compares program outcomes against industry benchmarks and best practices to ensure that its interventions meet or exceed global standards. This comparative analysis helps validate program effectiveness and provides insights for enhancement.
            </li>
          </ul>
        </div>

        {/* Feedback Loops Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Feedback Loops for Continuous Improvement
          </h2>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Real-Time Feedback Mechanisms:</strong>
              ➢ Real-Time Feedback Mechanisms: During training sessions, participants are encouraged to share real-time feedback through surveys, polls, and discussions. Trainers use this input to make immediate adjustments to content delivery, pacing, or focus areas, ensuring that sessions remain relevant and engaging.
            </li>
            <li>
              <strong>Post-Training Evaluations:</strong> After the completion of training, participants are invited to provide detailed feedback on their learning experience, including the quality of materials, delivery methods, and applicability of the content. Seismic uses this input to refine future programs, enhancing their relevance and impact.
            </li>
            <li>Seismic engages with stakeholders—such as organizational leaders, HR teams, and supervisors—to gather insights on the training program's impact at the organizational level. This holistic feedback helps align future programs with strategic goals and operational needs. Insights from leaders, HR teams, and supervisors ensure alignment with strategic goals.
            </li>
            <li>
              <strong>Adaptive Learning Approaches:</strong>  Insights from M&E activities feed directly into Seismic’s adaptive learning frameworks, which allow for the continuous refinement of training modules, methodologies, and tools. For instance, if a particular module receives consistently low feedback scores, it may be redesigned or replaced with more effective content.
            </li>
            <li>
              <strong>Iterative Program Updates:</strong> Seismic views its capacity-building initiatives as dynamic projects that evolve over time. Feedback gathered through M&E is used to iteratively update programs, ensuring that they remain effective and responsive to changing organizational and workforce needs.
            </li>
          </ul>
        </div>

        {/* Technology-Driven M&E Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Technology-Driven M&E
          </h2>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Learning Management Systems (LMS):</strong>  Seismic uses LMS platforms to track participant progress, engagement, and performance. These systems generate detailed reports that help trainers and organizations monitor training outcomes in real-time.
            </li>
            <li>
              <strong>Data Analytics:</strong> Advanced analytics tools are used to process and interpret large volumes of data from M&E activities. These insights enable Seismic to identify trends, measure program effectiveness, and pinpoint areas for improvement.
            </li>
            <li>
              <strong>Dashboards and Visualizations:</strong>Interactive dashboards provide stakeholders with clear, visual representations of program outcomes. These dashboards highlight key metrics and insights, making it easier for decision-makers to evaluate program performance and make informed adjustments.
            </li>
            <li>
              <strong>Automated Feedback Collection:</strong> Seismic uses online surveys and feedback forms to collect participant input efficiently. Automated tools streamline the feedback process, ensuring timely responses and comprehensive data collection.

            </li>
          </ul>
        </div>

        {/* Accountability and Reporting Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Accountability and Reporting
          </h2>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Comprehensive Reporting:</strong> Seismic provides detailed reports on program outcomes, including successes, challenges, and recommendations for future initiatives. These reports are shared with key stakeholders to demonstrate the program’s value and impact.
            </li>
            <li>
              <strong>Impact Measurement:</strong>  Beyond immediate outcomes, Seismic evaluates the long-term impact of its programs on participants and organizations. This includes tracking sustained improvements in skills, productivity, and organizational performance over time.
            </li>
            <li>
              <strong>Collaborative Reviews:</strong> Seismic collaborates with clients to review M&E findings and co-create strategies for future capacity-building initiatives. This collaborative approach ensures alignment with organizational goals and fosters a sense of shared ownership.
            </li>
          </ul>
        </div>

        {/* Final Overview Section */}
        <div className="bg-white py-12">
          <hr className="border-gray-300" />
          <div className="max-w-8xl mx-auto flex flex-col lg:flex-row items-center gap-8 py-8 px-6 lg:px-12">
            <div className="lg:w-6/12 w-full">
              <img
                src="https://via.placeholder.com/500x300" // Replace with relevant image URL
                alt="Monitoring and Evaluation"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="lg:w-6/12 w-full">
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                Ensuring Excellence Through M&E
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Monitoring and Evaluation is a cornerstone of Seismic’s approach to Capacity Building & Human Resource Development. By combining regular assessments, robust feedback loops, and technology-driven tools, Seismic not only evaluates the effectiveness of its interventions but also enhances them for maximum impact. This commitment ensures lasting improvements in organizational performance and workforce development.
              </p>
            </div>
          </div>
          <hr className="border-gray-300" />
        </div>
      </div>
    </section>
  );
};

export default MonitoringAndEvaluation;

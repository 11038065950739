import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../constants/paths";

const Industries = () => {
  const industries = [
    {
      title: "Healthcare and Life Sciences",
      description:
        "Our innovative solutions have helped healthcare organizations reduce patient wait times, enhance care quality, and achieve cost efficiencies, ultimately leading to healthier communities and more sustainable healthcare systems.",
      link: `/${paths.healthcare}`,
    },
    {
      title: "Renewable Energy and Sustainability",
      description:
        "Through our renewable energy and sustainability initiatives, we have enabled organizations to significantly reduce their environmental impact, and position themselves as leaders in the global shift.",
      link: `/${paths.renewableEnergy}`,
    },
    {
      title: "Education",
      description:
        "In this dynamic environment, innovative approaches are crucial. Our education consulting experts work with clients to transform learning environments, ensuring that education systems remain resilient, equitable, and prepared for the challenges of the 21st century.",
      link: `/${paths.education}`,
    },
    {
      title: "Financial Services",
      description:
        "Our work in the financial services industry has helped institutions streamline operations, enhance customer engagement, and drive sustainable financial innovation, positioning them for long-term success in the digital economy.",
      link: `/${paths.financialServices}`,
    },
    {
      title: "Agriculture and Agribusiness",
      description:
        "Our innovations in agriculture have enabled farmers to increase productivity, reduce costs, and adopt environmentally sustainable practices, ensuring food security and economic growth for communities around the world.",
      link: `/${paths.agriculture}`,
    },
    {
      title: "Technology and Telecommunications",
      description:
        "Our work has enabled companies to innovate faster, deliver better services, and protect their customers, all while contributing to the development of smart, connected communities.",
      link: `/${paths.technology}`,
    },
    {
      title: "Audit and Assurance",
      description:
        "Seismic Consulting Group's Audit and Assurance services are tailored to help organizations maintain compliance with regulatory requirements, enhance financial reporting accuracy, and foster transparency. We assist businesses in managing complex financial challenges, ensuring stakeholder confidence, mitigating risks, and making strategic decisions backed by reliable data.",
      link: `/${paths.audit}`,
    },
    {
      title: "Manufacturing and Industrial Engineering",
      description:
        "Our innovative solutions in manufacturing have enabled companies to increase efficiency, reduce costs, and adopt sustainable practices, positioning them as leaders in a rapidly evolving industry.",
      link: `/${paths.manufacturing}`,
    },
    {
      title: "Transportation and Logistics",
      description:
        "Our transformative solutions in transportation and logistics have helped companies achieve greater efficiency, reduce operational costs, and embrace sustainable practices, driving growth and resilience in a dynamic industry.",
      link: `/${paths.transportation}`,
    },
    {
      title: "Mining",
      description:
        "Our contributions have led to the discovery of significant mineral resources, and sustainable mining operations, and the attraction of substantial investment into the mining sector.",
      link: `/${paths.miningIndustryPage}`,
    },
  ];

  return (
    <section className="relative w-full bg-white pt-32 py-16">
      {/* Large Screen Design */}
      <div className="hidden lg:block relative w-full max-w-full mx-auto z-5 mb-24">
        {/* Right-Side Image */}
        <div className="absolute top-0 right-0 w-full h-[450px] overflow-hidden">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733493167/industries-bg_j2zt1k.jpg" // Replace with the correct image path
            alt="Industries Background"
            className="w-full h-full object-cover"
            style={{ position: 'absolute', right: 0 }} // Ensures it stretches to the right
          />
        </div>

        {/* Overlapping Card */}
        <div
          className="absolute bg-white p-8 z-20"
          style={{
            width: '42%', // Card width
            top: '180px', // Adjust this as needed to ensure overlap with the image
            left: '0%',
            height: '270px',
          }}
        >
          <h2 className="text-3xl md:text-2xl font-bold text-gray-800 ml-24">
            Innovating Beyond Boundaries and Delivering Impact
          </h2>
          <p className="text-lg text-gray-600 mt-4 ml-24">
            Empowering organizations, governments, and leaders to drive meaningful change through innovative, evidence-based strategies.
          </p>
        </div>
      </div>

      {/* Small Screen Design */}
      <div className="block lg:hidden">
        {/* Card First */}
        <div className="bg-white p-8 z-20 mb-6 text-center"> {/* Removed shadow for small screens */}
          <h2 className="text-3xl font-semibold text-gray-800">
            Innovating Beyond Boundaries and Delivering Impact
          </h2>
        </div>

        {/* Image Second */}
        <div className="w-full mb-6">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745765/industries-bg_axkdlp.jpg"
            alt="Industries Background"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>

      {/* Industries Section */}
      <div className="relative z-10 max-w-7xl mx-auto mt-12 lg:mt-80 lg:pt-40 text-center">
        <h3 className="text-3xl font-semibold text-center text-gray-800 mb-2">
          Tap into our Expertise
        </h3>
        <p className="text-center text-lg text-gray-600 mb-8">
          We partner with businesses across diverse industries.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {industries.map((industry, index) => (
            <div
              key={index}
              className="border border-gray-200 p-6 rounded-lg hover:shadow-lg transition-shadow bg-white text-center lg:text-left"
            >
              <h4 className="text-xl font-semibold text-gray-800 mb-4">
                {industry.title}
              </h4>
              <p className="text-gray-600 text-base mb-4">
                {industry.description}
              </p>
              <Link
                to={industry.link}
                className="text-purple-900 font-semibold inline-flex items-center"
              >
                Learn More
                <svg
                  className="w-5 h-5 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Industries;

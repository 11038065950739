import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ExpertiseSection = () => {
  const industries = [
    {
      title: 'Healthcare & Life Sciences',
      description:
        'We help healthcare reduce wait times, enhance care and achieve cost efficiencies, leading to healthier communities and sustainable healthcare systems.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738745707/nurse_scrubs_kbhrx2.jpg',
      link: '/industries/healthcare-and-life-sciences'
    },
    {
      title: 'Renewable Energy',
      description:
        'Our involvement in the renewable energy sector fosters cleaner power generation and helps communities transition towards a more resilient and eco-friendly future.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1733513684/pexels-andreea-ch-371539-1166643_wvlv96.jpg',
      link: '/industries/renewable-energy'
    },
    {
      title: 'Mineral Exploration and Mining',
      description:
        'Our contributions have led to the discovery of significant mineral resources, sustainable mining operations, and the attraction of substantial investment into the mining sector.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738233254/WhatsApp_Image_2025-01-29_at_17.25.40_sdtawd.jpg',
      link: '/industries/mining'
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatic slide change every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + industries.length) % industries.length;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % industries.length;
    setCurrentIndex(newIndex);
  };

  return (
    <section className="py-16 bg-white">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold">Tap Into Our Expertise</h2>
        <p className="text-lg text-gray-600 mt-2">
          We partner with businesses across diverse industries.
        </p>
      </div>

      {/* Industries Carousel for Small Screens */}
      <div className="block md:hidden relative">
        {/* Single Industry Card (wrapped in Link) */}
        <Link to={industries[currentIndex].link} className="block w-full px-4">
          <div className="w-full h-64 mb-4">
            <img
              src={industries[currentIndex].image}
              alt={industries[currentIndex].title}
              className="w-full h-full object-cover rounded-md"
            />
          </div>
          <h3 className="text-xl font-semibold mb-2">
            {industries[currentIndex].title}
          </h3>
          <p className="text-sm text-gray-600 mb-4">
            {industries[currentIndex].description}
          </p>
        </Link>

        {/* Navigation Arrows */}
        <button
          onClick={handlePrev}
          className="absolute top-1/2 left-2 transform -translate-y-1/2 p-1"
        >
          <svg
            className="w-6 h-6 text-white pt-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          onClick={handleNext}
          className="absolute top-1/2 right-2 transform -translate-y-1/2 p-1"
        >
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      {/* Industries Grid for Larger Screens */}
      <div className="hidden md:flex justify-between space-x-8">
        {industries.map((industry) => (
          <Link
            key={industry.title}
            to={industry.link}
            className="block w-1/3 hover:opacity-90 transition"
          >
            <div className="w-full h-64 mb-4">
              <img
                src={industry.image}
                alt={industry.title}
                className="w-full h-full object-cover rounded-md"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2">{industry.title}</h3>
            <p className="text-sm text-gray-600 mb-4">
              {industry.description}
            </p>
          </Link>
        ))}
      </div>

      {/* Explore More Link */}
      <div className="text-center mt-8">
        <Link
          to="/industries" // Link to the industries page route
          className="text-[#600060] font-semibold text-sm flex items-center justify-center"
        >
          Explore More
          <svg
            className="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
      </div>
    </section>
  );
};

export default ExpertiseSection;

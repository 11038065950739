import React from "react";

export default function MiningIndustryPage() {
  return (
    <div className="mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739278370/pexels-photo-6788584_wkzanj.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Mining
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Driving strategic growth and sustainability within the global mining sector.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 text-center">
        <h1 className="text-2xl font-bold text-gray-800">
          Mining
        </h1>
      </div>

      {/* Introduction */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
          Seismic Consulting Group: A Trusted Partner in Mining
        </h2>
        <p className="text-lg text-gray-700 mb-4">
          The mining industry is a cornerstone of global economic development, 
          providing raw materials essential to numerous sectors. However, mining 
          operations today face increasingly complex challenges—from stringent 
          regulations and environmental demands to the need for operational efficiency 
          and stakeholder alignment. Seismic Consulting Group stands ready to help 
          you navigate these complexities, optimize your operations, and uncover 
          new growth opportunities.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Whether you need guidance on policy frameworks, environmental stewardship, 
          or investment facilitation, our services are tailored to address the entire 
          mining lifecycle. By leveraging advanced analytics, topographic mapping, 
          strategic marketing, and more, we position your organization to thrive in 
          an ever-evolving landscape—delivering long-term value while maintaining 
          social license to operate.
        </p>
      </div>

      {/* Key Areas of Focus */}
      <div className="py-16 px-4 lg:px-20">
        <div className="max-w-[1200px] mx-auto">
          <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
            Key Areas of Focus
          </h2>
          <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
            Our holistic approach addresses operational efficiency, sustainability, 
            and compliance—ensuring a well-rounded, future-proof strategy for your 
            mining operations.
          </p>

          {/* Focus Cards */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Regulatory Compliance & Policy */}
            <div className="border border-gray-200 p-6 shadow-md">
              <h3 className="text-lg font-bold mb-2 text-purple-900">
                Regulatory Compliance &amp; Policy
              </h3>
              <p className="text-gray-600 text-md leading-relaxed mb-4">
                We develop and refine policy frameworks that enable responsible 
                resource extraction. By conducting comprehensive assessments of 
                mineral deposits and crafting structured guidelines, we help you 
                meet complex regulations and maintain a strong social license 
                to operate.
              </p>
            </div>

            {/* Sustainable Operations */}
            <div className="border border-gray-200 p-6 shadow-md">
              <h3 className="text-lg font-bold mb-2 text-purple-900">
                Sustainable Operations
              </h3>
              <p className="text-gray-600 text-md leading-relaxed mb-4">
                Our environmental stewardship approach integrates geochemical 
                mapping and innovative mining techniques, minimizing ecological 
                disruption while safeguarding community interests. We help you 
                strike the right balance between profitability and responsibility.
              </p>
            </div>

            {/* Investment Facilitation */}
            <div className="border border-gray-200 p-6 shadow-md">
              <h3 className="text-lg font-bold mb-2 text-purple-900">
                Investment Facilitation
              </h3>
              <p className="text-gray-600 text-md leading-relaxed mb-4">
                We highlight mineral-rich zones using topographic mapping 
                and strategic marketing, attracting investors and streamlining 
                the project funding process. Our structured facilitation ensures 
                effective partnerships for long-term growth.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Sustainable Growth & Central Economic Corridors */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
          {/* Text Section */}
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-medium mb-4">
              Sustainable Growth &amp; Central Economic Corridors
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
              One of our core specialties involves designing and mapping 
              central economic corridors. These corridors streamline the 
              transportation of mined materials, connect industrial zones, 
              and facilitate export channels for maximum efficiency.
            </p>
            <p className="text-gray-600 text-lg leading-relaxed">
              By positioning critical infrastructure along high-volume routes 
              and leveraging data on mineral distribution, we help you 
              minimize logistical costs, optimize sales strategies, and 
              secure robust supply chains—driving profitability and resilience 
              in your mining operations.
            </p>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738233254/WhatsApp_Image_2025-01-29_at_17.25.40_sdtawd.jpg"
              alt="Mining Corridors"
              className="w-full"
            />
          </div>
        </div>
      </section>

      {/* Why Seismic for Mining */}
      <div className="max-w-7xl mx-auto px-6 lg:px-12 mt-12 mb-12">
        <h3 className="text-2xl font-bold text-gray-900 mb-4">
          Why Seismic Consulting Group?
        </h3>
        <ul className="list-disc list-inside text-gray-700 space-y-2">
          <li>
            <strong>Holistic Expertise:</strong> From exploration and operational 
            optimization to policy formation, our team covers every stage of 
            the mining lifecycle.
          </li>
          <li>
            <strong>Cutting-Edge Tools:</strong> We leverage advanced technologies 
            such as GIS, geochemical analysis, and data-driven marketing to inform 
            strategic decisions and enhance project outcomes.
          </li>
          <li>
            <strong>Global Perspective:</strong> Our consultants bring diverse, 
            international experience, offering unique insights and best practices 
            to your mining initiatives.
          </li>
          <li>
            <strong>Stakeholder Alignment:</strong> We help you navigate government 
            regulations, community relations, and investor interests—ensuring a 
            balanced approach that benefits all parties.
          </li>
        </ul>
      </div>
    </div>
  );
}

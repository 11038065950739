import React, { useState } from "react";

const RegulatoryCompliance = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const approachData = [
    {
      title: "Comprehensive Compliance Audits",
      description:
        "We conduct in-depth reviews of organizational practices to identify compliance gaps, including:",
      services: [
        "Internal policies, procedures, and workflows.",
        "Licensing and certification requirements.",
        "Adherence to region-specific regulations, such as HIPAA, FDA, EMA, and more.",
      ],
      summary:
        "Our audits culminate in actionable recommendations to bridge gaps, streamline processes, and ensure full compliance.",
    },
    {
      title: "Regulatory Advisory Services",
      description:
        "Our experts keep you informed of the latest regulatory developments and provide guidance on:",
      services: [
        "Adapting to changing standards without disrupting operations.",
        "Navigating the approval process for drugs, medical devices, and clinical trials.",
        "Aligning with international compliance frameworks to enable global market entry.",
      ],
    },
    {
      title: "Risk Assessment and Mitigation",
      description:
        "We employ advanced risk assessment methodologies to identify potential threats, including:",
      services: [
        "Operational risks, such as breaches in patient data privacy or deviations from clinical trial protocols.",
        "Strategic risks, including non-compliance with emerging regulations or ethical standards.",
        "Financial risks stemming from penalties, delays, or legal disputes.",
      ],
      summary:
        "Our team works with you to create proactive risk mitigation strategies, such as enhancing internal controls, adopting advanced monitoring tools, and ensuring organizational readiness for audits.",
    },
    {
      title: "Compliance Training and Capacity Building",
      description:
        "Sustainable compliance starts with informed and empowered teams. We offer:",
      services: [
        "Customized training programs for staff on regulatory standards and ethical practices.",
        "Capacity-building workshops to instill a culture of compliance across all levels of the organization.",
      ],
    },
    {
      title: "Technology-Driven Solutions",
      description:
        "We leverage technology to enhance compliance and risk management, including:",
      services: [
        "AI-powered compliance monitoring tools that flag deviations in real time.",
        "Blockchain-based solutions for maintaining secure and tamper-proof audit trails.",
        "Automated reporting systems to ensure timely and accurate regulatory submissions.",
      ],
    },
  ];
  

  const regulatoryFocusData = [
    {
      title: "Patient Data Privacy and Security",
      points: [
        "Ensuring compliance with data protection regulations like GDPR and HIPAA.",
        "Implementing robust cybersecurity measures to safeguard sensitive information.",
      ],
    },
    {
      title: "Clinical Trials Compliance",
      points: [
        "Guiding organizations through regulatory requirements for clinical trial approvals and oversight.",
        "Ensuring ethical standards in patient recruitment, data collection, and reporting.",
      ],
    },
    {
      title: "Post-Market Surveillance",
      points: [
        "Monitoring product performance post-launch to ensure ongoing compliance and safety.",
        "Managing recalls or corrective actions to maintain public trust and regulatory approval.",
      ],
    },
    {
      title: "Product Approvals and Certifications",
      points: [
        "Supporting compliance with FDA, EMA, and other global health authorities for new drugs, devices, and therapies.",
        "Managing documentation and communication to expedite approval processes.",
      ],
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739183282/pexels-photo-5668473_frnkqj.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Regulatory Compliance and Risk Management
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Ensuring Healthcare and Life Sciences Thrive Amid Complex Regulations
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Regulatory Compliance and Risk Management
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Ensuring Healthcare and Life Sciences Thrive Amid Complex Regulations
        </h2>
        <p className="text-gray-700 text-lg mb-4">
          In the highly regulated healthcare and life sciences sectors, compliance is non-negotiable. Regulatory frameworks are designed to safeguard patient safety, ensure ethical practices, and maintain public trust. However, navigating these intricate regulations can be overwhelming, especially as global standards evolve and new risks emerge.
        </p>
        <p className="text-gray-700 text-lg mb-4">
          At Seismic Consulting Group, we offer comprehensive regulatory compliance and risk management solutions that help organizations confidently operate in this dynamic environment. By blending deep industry expertise with cutting-edge tools, we ensure you meet regulatory requirements, mitigate risks, and uphold the highest standards of quality and integrity.
        </p>
      </div>

{/* Benefits Section */}
<div className="py-16 max-w-8xl mx-auto px-6 lg:px-12">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Benefits of Our Services
  </h2>
  <div className="grid grid-cols-1 lg:grid-cols-4 gap-0 text-center">
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center bg-purple-100">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546264/7498046.png_ejk71i.png"
          alt="Operational Efficiency Icon"
          className="w-16 h-16"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Enhanced Operational Efficiency</h3>
      <p className="text-gray-700 mt-2">
        Streamlined workflows reduce the burden of compliance tasks, freeing up resources for innovation and patient care.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center bg-purple-100">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546301/7275836.png_zeupcw.png"
          alt="Risk Reduction Icon"
          className="w-16 h-16"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Risk Reduction</h3>
      <p className="text-gray-700 mt-2">
        Proactive strategies and real-time monitoring minimize legal, financial, and reputational risks.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center bg-purple-100">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546702/7610698.png_lf7pso.png"
          alt="Global Market Readiness Icon"
          className="w-16 h-16"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Global Market Readiness</h3>
      <p className="text-gray-700 mt-2">
        Alignment with international regulations enables seamless market entry and expansion.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center bg-purple-100">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546776/7497716.png_lmqppk.png"
          alt="Trust and Integrity Icon"
          className="w-16 h-16"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Sustained Trust and Integrity</h3>
      <p className="text-gray-700 mt-2">
        Adherence to ethical and regulatory standards strengthens stakeholder confidence, from patients to investors.
      </p>
    </div>
  </div>
</div>


      {/* Approach Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Our Approach to Compliance
        </h2>
        {approachData.map((item, index) => (
          <div key={index} className="mb-12 border-b border-pink-100 pb-8">
            <button
              onClick={() => toggleSection(index)}
              className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
            >
              {item.title}
              <span className="text-purple-900 text-3xl">
                {openSection === index ? "-" : "+"}
              </span>
            </button>
            {openSection === index && (
              <div>
                <p className="text-gray-700 text-lg mb-4">{item.description}</p>
                {item.services && (
                  <ul className="list-none text-gray-700 text-lg space-y-4 mt-4 px-6 lg:px-32">
                    {item.services.map((service, idx) => (
                      <li key={idx} className="relative pl-6">
                        <span
                          className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 border-purple-900 rounded-full"
                        ></span>
                        {service}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Regulatory Focus Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Key Areas of Regulatory Focus
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {regulatoryFocusData.map((item, index) => (
            <div key={index}>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{item.title}</h3>
              <ul className="list-none text-gray-700 text-lg space-y-2">
                {item.points.map((point, idx) => (
                  <li key={idx} className="relative pl-6">
                    <span
                      className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 border-purple-900 rounded-full"
                    ></span>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>


{/* Proven Impact Section */}
<div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Proven Impact
  </h2>
  <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
    Through our regulatory compliance and risk management solutions, Seismic Consulting has helped healthcare and life sciences organizations achieve measurable results that drive operational efficiency, enhance trust, and safeguard critical data.
  </p>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-12 text-center">
    <div>
      <h3 className="text-4xl font-bold text-purple-900">50%</h3>
      <p className="text-gray-700 mt-2">
        Reduced regulatory penalties by over 50% through proactive compliance measures, ensuring organizations avoid costly fines and maintain compliance with stringent standards.
      </p>
    </div>
    <div>
      <h3 className="text-4xl font-bold text-purple-900">100%</h3>
      <p className="text-gray-700 mt-2">
        Safeguarded patient data with 100% compliance, reinforcing trust among stakeholders, improving public confidence, and meeting the highest industry benchmarks for security.
      </p>
    </div>
  </div>
  <p className="text-lg text-gray-700 text-center mt-12">
    At Seismic Consulting, we deliver results that go beyond compliance. Our tailored strategies help organizations thrive in a complex regulatory environment while building a culture of accountability and excellence.
  </p>
</div>


      {/* Call to Action Section */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739184926/pexels-photo-6077861_rlffnq.jpg"
              alt="Call to Action"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
          <div>
            <h2 className="text-3xl font-bold mb-4">
              Let’s build a compliant, resilient, and thriving future for your organization.
            </h2>
            <p className="text-gray-600 text-lg mb-4">
              With Seismic Consulting Group, regulatory compliance becomes a strategic advantage rather than a burden. By navigating the complex regulatory landscape with confidence and precision, you can focus on delivering innovative healthcare solutions that transform lives.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegulatoryCompliance;

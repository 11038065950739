import React from "react";

const ProgramDesignAndCustomization = () => {
  return (
    <div className="bg-white">
      {/* Header Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1440x516')", // Replace this placeholder with the actual image URL
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
          <h1 className="text-3xl font-bold text-gray-800">
            Program Design and Customization
          </h1>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Program Design and Customization
        </h1>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 py-12">
        {/* Introduction */}
        <div className="mb-12">
          <p className="text-lg text-gray-600 leading-relaxed mt-12">
            In Seismic’s approach to Capacity Building & Human Resource
            Development, Program Design and Customization is a cornerstone of
            ensuring that training programs are not only relevant but also
            aligned with the unique goals and needs of an organization. The
            process focuses on collaborative co-creation of tailored learning
            experiences that directly support the organization’s strategic
            vision, while also integrating the insights and input of key
            stakeholders to ensure the program’s effectiveness and adaptability.
            Here’s how Seismic applies this methodology:
          </p>
        </div>

        {/* Co-Creation Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Co-Creation of Training Programs Aligned with Strategic Goals
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed mb-6">
            The first step in designing and customizing a program is ensuring
            that it is fully aligned with the organization’s long-term
            objectives, vision, and goals. Seismic believes in co-creation—a
            collaborative approach that involves key stakeholders from the
            outset of the program design process, ensuring the training programs
            are purpose-driven and strategically focused. This approach has the
            following key elements:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Alignment with Organizational Vision:</strong> Seismic
              works closely with leadership teams to understand the
              organization’s broader strategic objectives and priorities. By
              aligning training programs with these goals, we ensure that the
              outcomes of the capacity-building initiatives support the
              organization's mission, whether it’s improving efficiency,
              fostering innovation, enhancing employee engagement, or driving
              transformation.
            </li>
            <li>
              <strong>Identification of Key Success Areas:</strong> Seismic
              helps organizations identify the key areas where enhanced skills
              or knowledge are most critical to achieving strategic success. For
              example, if an organization is focused on digital transformation,
              the training program may center on developing technical expertise,
              change management capabilities, and leadership skills in
              navigating technological advancements.
            </li>
            <li>
              <strong>Tailored Content and Approach:</strong> Based on the
              organization's goals, Seismic customizes training content that
              addresses specific needs, such as leadership development,
              technical proficiency, or team collaboration. Whether it involves
              soft skills, technical training, or advanced management
              competencies, the program content is purposefully designed to
              ensure maximum impact on the organization’s ability to execute its
              strategic goals.
            </li>
            <li>
              <strong>Scalability and Flexibility:</strong> Seismic designs
              training programs with scalability in mind, ensuring that they can
              be easily expanded or adapted as the organization grows or as its
              strategic priorities shift. This approach allows organizations to
              respond to emerging needs without having to start from scratch
              each time.
            </li>
          </ul>
        </div>

        {/* Stakeholder Input Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Inclusion of Stakeholder Input for Relevance and Adaptability
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed mb-6">
            To ensure that the training program is practical, actionable, and
            relevant, Seismic integrates stakeholder input throughout the design
            process. This input ensures that the program reflects the needs,
            expectations, and experiences of all key stakeholders, including
            leadership, managers, employees, and external partners. The process
            of gathering and incorporating feedback ensures that the program is
            not only relevant but also adaptable to the specific culture and
            context of the organization. Key aspects of this approach include:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Stakeholder Engagement:</strong> Seismic involves a broad
              range of stakeholders—from executive leadership to frontline
              employees—to ensure a comprehensive understanding of the needs and
              expectations across different levels of the organization. This
              could include surveys, focus groups, interviews, and workshops
              with managers, HR teams, and even potential trainees to capture a
              diverse set of perspectives. Engaging stakeholders early in the
              design process ensures the program is holistic and well-rounded.
            </li>
            <li>
              <strong>Feedback Loops and Iterative Design:</strong>The program
              design is iterative, with feedback loops integrated at multiple
              stages. After initial design concepts, Seismic presents early
              drafts of the program to key stakeholders for their feedback. This
              allows for continuous refinement, ensuring that the final product
              is finely tuned to meet the specific demands and challenges of the
              organization. Regular feedback helps us remain responsive to
              changing needs, ensuring adaptability as circumstances evolve.
            </li>
            <li>
              <strong>Adapting to Organizational Culture:</strong> The program
              is designed with organizational culture in mind, ensuring that it
              resonates with employees and is adopted across the organization.
              For example, if the organization has a collaborative culture, the
              training might emphasize team-based learning and peer-to-peer
              engagement. If innovation is a core value, the program might focus
              on fostering creativity and problem-solving skills. By tailoring
              the program to reflect the organization’s culture, Seismic ensures
              higher engagement and greater effectiveness.
            </li>
            <li>
              <strong>Contextualization of Training Content:</strong>{" "}
              Stakeholder input helps Seismic tailor the content to address
              real-world challenges and specific regional or industry contexts.
              For example, in a multinational organization, training content may
              be customized to reflect the nuances of different cultural
              contexts or local market challenges. Similarly, if the
              organization operates in a highly regulated industry, the training
              can be adjusted to include compliance or industry-specific
              practices.
            </li>
            <li>
              <strong>Ongoing Collaboration:</strong> The process of co-creation
              doesn’t stop once the program is launched. Seismic fosters ongoing
              collaboration with stakeholders throughout the implementation
              phase. This may include post-training evaluations, continuous
              feedback sessions, or follow-up discussions with managers to
              assess how well the training program is being applied and whether
              any adjustments are necessary. This ongoing engagement ensures
              that the training program stays relevant over time.
            </li>
          </ul>
        </div>

        {/* Relevance and Adaptability Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Ensuring Relevance and Adaptability
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed mb-6">
            As the external and internal environments change, Seismic ensures
            that the training program is adaptable to these shifts. Whether it’s
            through continuous professional development opportunities, modular
            training options, or the ability to reframe content as new business
            challenges emerge, Seismic builds flexibility into all programs.
            This adaptability is crucial to sustaining the effectiveness of
            training over time.
          </p>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Modular Learning Design:</strong> Training is often
              structured in modular formats, allowing organizations to adjust
              content as needed. If an organization undergoes a transformation,
              shifts its focus, or faces new challenges, certain modules can be
              updated or replaced to keep the program in line with strategic
              priorities.
            </li>
            <li>
              <strong>Monitoring Effectiveness:</strong>Seismic tracks the
              progress of participants and evaluates the effectiveness of the
              training in real-time. By gathering feedback from stakeholders and
              assessing performance outcomes, Seismic ensures that the program
              adapts to meet both the evolving needs of the organization and the
              learners’ requirements.
            </li>
          </ul>
        </div>

        {/* Final Overview Section */}
        <div className="bg-white py-12">
          {/* Top Border */}
          <hr className="border-gray-300" />
          <div className="max-w-8xl mx-auto flex flex-col lg:flex-row items-center gap-8 py-8 px-6 lg:px-12">
            {/* Image */}
            <div className="lg:w-6/12 w-full">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738139818/WhatsApp_Image_2025-01-28_at_13.47.20_t7hjam.jpg" // Replace with relevant image URL
                alt="Program Design"
                className="rounded-lg shadow-lg h-[400px]"
              />
            </div>

            {/* Text Content */}
            <div className="lg:w-6/12 w-full">
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                Seismic’s Program Design and Customization
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Seismic’s approach to Program Design and Customization is built
                on a foundation of collaborative co-creation and stakeholder
                engagement, ensuring that the training programs are aligned with
                strategic goals and are relevant to the needs of the
                organization. This method not only enhances the effectiveness of
                the programs but also ensures that the outcomes support the
                organization's long-term success. By customizing training based
                on comprehensive stakeholder input and keeping the content
                adaptable to changing needs, Seismic delivers capacity-building
                solutions that drive sustained organizational growth and
                performance improvement.
              </p>
            </div>
          </div>
          {/* Bottom Border */}
          <hr className="border-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default ProgramDesignAndCustomization;

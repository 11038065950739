import React from "react";

function EnhancedCustomerEngagementPage() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Enhanced Customer Engagement
          </h1>
        </div>
      </section>

      {/* Intro Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            At Seismic Consulting Group, we recognize that digital transformation fundamentally redefines how
            organizations connect with their customers. Our expertise ensures this evolution is seamless, impactful, and aligned with your business objectives.
            By implementing advanced Customer Relationship Management (CRM) systems integrated with Artificial Intelligence (AI), we anticipate customer needs and preferences, enhancing engagement, satisfaction, and loyalty.
          </p>
        </div>
      </section>

      {/* Customer Relationship Management (CRM) Optimization */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Customer Relationship Management (CRM) Optimization</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              We specialize in implementing and refining CRM tools enhanced with AI and analytics. This provides a deeper understanding of customer behavior, enabling personalized marketing strategies and strengthening customer relationships.
              Our tailored CRM solutions have helped clients effectively segment their customer base, leading to increased targeted engagement and improved customer retention rates.
            </p>
          </div>
        </div>
      </section>

      {/* Omnichannel Integration */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Omnichannel Integration</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Seismic creates cohesive customer experiences across all channels—online platforms, mobile applications, and physical locations—ensuring consistent engagement and superior service delivery. By unifying these touchpoints, we help organizations meet customers wherever they are, enhancing satisfaction and fostering brand loyalty.
            </p>
          </div>
        </div>
      </section>

      {/* Customer Journey Mapping */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Customer Journey Mapping</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              We meticulously analyze customer interactions at every touchpoint to streamline and elevate the user experience. By mapping the customer journey, we identify opportunities to improve conversion rates and enhance overall satisfaction. This holistic approach allows our clients to deliver tailored experiences that resonate deeply with their target audience.
            </p>
          </div>
        </div>
      </section>

      {/* Conclusion Section */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">Driving Growth and Loyalty Through Engagement</h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Through our commitment to enhancing customer engagement, Seismic Consulting Group empowers organizations to build stronger, more meaningful relationships with their customers. By leveraging cutting-edge technologies and strategic insights, we drive growth and competitive advantage in the digital era.
          </p>
        </div>
      </section>
    </div>
  );
}

export default EnhancedCustomerEngagementPage;

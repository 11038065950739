import React from "react";

const ManufacturingIndustry = () => {
  return (
    <div className="w-full bg-white mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739539465/pexels-photo-1216544_gkcbbx.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-20">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white">
            Manufacturing & Industrial Engineering
          </h1>
          <p className="text-base lg:text-lg text-white mt-4">
            Empowering industries through cutting-edge innovation, operational
            excellence, and sustainable growth.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Manufacturing & Industrial Engineering
        </h1>
      </div>

      {/* Introduction */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
          Driving Innovation in Manufacturing & Industrial Engineering
        </h2>
        <p className="text-lg text-gray-700 mb-4">
          In an era marked by rapid technological advancements and evolving market
          demands, manufacturers are challenged to innovate while maximizing
          efficiency. Whether it's integrating automation, optimizing supply chains,
          or transitioning to greener production methods, our tailored solutions
          empower your business to stay ahead of the curve.
        </p>
        <p className="text-lg text-gray-700">
          Our expert team delivers a full spectrum of services, ensuring that every
          facet of your operations is enhanced—from initial concept and design to
          final implementation and ongoing support.
        </p>
      </div>

      {/* Comprehensive Services */}
      <div className="max-w-7xl mx-auto px-6 lg:px-12 mt-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
          Comprehensive Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              title: "Process Optimization",
              description:
                "Streamline operations, reduce waste, and boost productivity with our tailor-made process improvement strategies.",
            },
            {
              title: "Automation & Robotics",
              description:
                "Integrate advanced robotics and automation systems to enhance precision, reduce human error, and cut operational costs.",
            },
            {
              title: "Digital Transformation",
              description:
                "Leverage IoT, AI, and data analytics to modernize manufacturing processes and drive smart decision-making.",
            },
            {
              title: "Quality Assurance & Compliance",
              description:
                "Implement rigorous quality control measures to ensure compliance with industry standards and regulatory requirements.",
            },
            {
              title: "Supply Chain Management",
              description:
                "Optimize logistics, manage inventory efficiently, and ensure seamless coordination throughout the production cycle.",
            },
            {
              title: "Sustainability & Green Manufacturing",
              description:
                "Adopt eco-friendly practices, reduce your carbon footprint, and integrate energy-efficient solutions.",
            },
          ].map((service, index) => (
            <div
              key={index}
              className="p-6 border border-gray-200 shadow-md rounded-lg"
            >
              <h3 className="text-xl font-semibold text-purple-900 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>


{/* Our Core Expertise */}
<div className="py-16 px-4 lg:px-20 bg-gray-50">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-12">
      Our Core Expertise
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Card 1: Infrastructure & Contracting */}
      <div className="border border-gray-200 shadow-md rounded-lg overflow-hidden">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739539504/pexels-photo-416405_mrpzh5.jpg"
          alt="Infrastructure & Contracting"
          className="w-full h-48 object-cover"
        />
        <div className="p-6">
          <h3 className="text-lg font-bold mb-2">
            Infrastructure & Contracting
          </h3>
          <p className="text-gray-600">
            Our expertise in large-scale infrastructure projects ensures your
            facility is designed, planned, and executed to the highest standards.
            From feasibility studies to final commissioning, we deliver sustainable
            and cost-effective solutions.
          </p>
        </div>
      </div>

      {/* Card 2: Machinery & Equipment */}
      <div className="border border-gray-200 shadow-md rounded-lg overflow-hidden">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739539566/pexels-photo-256297_f0a7jh.jpg"
          alt="Machinery & Equipment"
          className="w-full h-48 object-cover"
        />
        <div className="p-6">
          <h3 className="text-lg font-bold mb-2">Machinery & Equipment</h3>
          <p className="text-gray-600">
            We offer end-to-end solutions for machinery and equipment—from
            procurement and installation to ongoing maintenance and upgrades.
            Our goal is to ensure optimal performance and minimal downtime in your
            production lines.
          </p>
        </div>
      </div>

      {/* Card 3: Process Optimization & Automation */}
      <div className="border border-gray-200 shadow-md rounded-lg overflow-hidden">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739539603/pexels-photo-1595104_ynrrdy.jpg"
          alt="Process Optimization & Automation"
          className="w-full h-48 object-cover"
        />
        <div className="p-6">
          <h3 className="text-lg font-bold mb-2">
            Process Optimization & Automation
          </h3>
          <p className="text-gray-600">
            Enhance operational efficiency with customized strategies that
            integrate advanced automation systems. We help streamline workflows,
            minimize waste, and boost overall productivity.
          </p>
        </div>
      </div>

      {/* Card 4: Digital Transformation & IoT Integration */}
      <div className="border border-gray-200 shadow-md rounded-lg overflow-hidden">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739539739/pexels-photo-3862624_hjqswo.jpg"
          alt="Digital Transformation & IoT Integration"
          className="w-full h-48 object-cover"
        />
        <div className="p-6">
          <h3 className="text-lg font-bold mb-2">
            Digital Transformation & IoT Integration
          </h3>
          <p className="text-gray-600">
            Embrace the digital future with our comprehensive transformation
            services. From implementing IoT solutions to harnessing big data analytics,
            we enable smart, connected manufacturing environments.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


            {/* Why Choose Us */}
            <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        <h3 className="text-2xl font-bold text-gray-900 text-center mb-8">
          Why Partner with Us?
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          {[
            {
              title: "Industry Expertise",
              description:
                "Decades of experience in industrial engineering, supply chain management, and automation solutions.",
            },
            {
              title: "Innovative Solutions",
              description:
                "We leverage cutting-edge technologies like AI, IoT, and digital twins to drive manufacturing excellence.",
            },
            {
              title: "Sustainable Practices",
              description:
                "Our commitment to green initiatives ensures energy efficiency and eco-friendly operations.",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="p-6 border border-gray-200 shadow-md rounded-lg"
            >
              <h4 className="text-xl font-semibold text-purple-900 mb-2">
                {item.title}
              </h4>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Impactful Results */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-medium mb-4">
              Delivering Impactful Results
            </h2>
            <p className="text-gray-600 text-xl leading-relaxed mb-4">
              Our consulting services have enabled industrial firms to reduce
              production costs by up to 30%, enhance supply chain resilience, and
              improve sustainability.
            </p>
            <p className="text-gray-600 text-xl leading-relaxed">
              By working closely with our clients, we craft tailored strategies that
              drive efficiency, profitability, and long-term growth.
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739539793/weld-hot-soldering-radio-welder-73833_xxktzj.jpg"
              alt="Impactful Results"
              className="w-full h-[450px] rounded-lg object-cover shadow-lg"
            />
          </div>
        </div>
      </section>

      {/* Additional Insights */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16 mb-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
          Additional Insights & Industry Trends
        </h2>
        <p className="text-lg text-gray-700 mb-4">
          Staying ahead in today’s competitive landscape requires a deep
          understanding of emerging technologies and market dynamics. Our team
          continuously monitors the latest trends, offering insights that help you
          adapt and thrive.
        </p>
        <ul className="list-disc list-inside text-gray-700 text-lg space-y-2">
          <li>
            <strong>Emerging Technologies:</strong> From advanced robotics and AI-driven
            analytics to blockchain applications in supply chain management.
          </li>
          <li>
            <strong>Regulatory Compliance:</strong> Expert guidance on navigating both
            local and international standards to ensure your operations remain
            compliant.
          </li>
          <li>
            <strong>Market Dynamics:</strong> In-depth analyses that help you anticipate
            industry shifts and adapt your strategies for sustained success.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ManufacturingIndustry;

import React from "react";
import KeyApproach from "./KeyApproach";

const GovernanceReform = () => {
  return (
    <section className="bg-white">
      {/* Hero Section */}
      <div
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739188364/wooden-hammer-on-a-desk-with-blurred-bookshelf-background-blue-toned-image_tkczjn.jpg')", // Replace with the actual image URL
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 bg-black bg-opacity-20 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Governance Reform
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Transforming policies and systems to build transparency,
            accountability, and sustainable progress.
          </p>
        </div>
      </div>


         {/* Title Under Image for Small Screens */}
         <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Governance Reform
        </h1>
      </div>

      {/* Driving Change Through Governance Reform Section */}
      <div className="max-w-4xl mx-auto py-16 px-6 lg:px-12 text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">
          Driving Change Through Governance Reform
        </h2>
        <p className="text-gray-700 text-lg leading-relaxed mb-6">
          Unlocking the future of governance begins with bold, transformative
          action. At Seismic Consulting Group, we don’t just envision better
          governance; we build it. Our governance reforms break down barriers,
          drive transparency, and ignite accountability across every level of
          the public sector. By reengineering outdated systems, empowering
          decision-makers, and amplifying citizen voices, we create a
          governance framework that’s responsive, resilient, and ready for
          tomorrow’s challenges. With Seismic, every reform is a step forward—a
          leap toward a government that truly serves, inspires, and leads.
        </p>
        <p className="text-gray-700 text-lg leading-relaxed mb-6">
          Seismic Consulting Group is redefining governance for sustainable
          development, empowering governments to deliver transparent,
          accountable, and efficient services. Our governance reforms go beyond
          traditional models, combining innovation with a deep understanding of
          the public sector to create transformative solutions that address
          today’s most pressing challenges. At Seismic, we don’t just support
          government systems—we revolutionize them, setting a global standard
          for good governance.
        </p>
      </div>

      {/* Collaborative Governance and Public-Private Partnerships Section */}
      <div className="py-4">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Collaborative Governance and Public-Private Partnerships
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed mb-8 max-w-3xl mx-auto">
            Seismic fosters collaborative governance models to bring public and
            private sector entities together in solving complex challenges.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Public-Private Collaboration Models */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold text-gray-800 mb-4">
                Public-Private Collaboration Models
              </h3>
              <p className="text-gray-700 text-sm">
                Designing frameworks that promote innovation and resource-sharing between sectors.
              </p>
            </div>
            {/* Stakeholder Engagement */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold text-gray-800 mb-4">
                Stakeholder Engagement
              </h3>
              <p className="text-gray-700 text-sm">
                Facilitating collaboration among government agencies, private companies, and civil society organizations to enhance service delivery and governance outcomes.
              </p>
            </div>
            {/* Sustainable Development Partnerships */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold text-gray-800 mb-4">
                Sustainable Development Partnerships
              </h3>
              <p className="text-gray-700 text-sm">
                Partnering with international organizations and NGOs to
                implement governance solutions aligned with global sustainable
                development goals.
              </p>
            </div>
          </div>
        </div>
      </div>
       {/* Key Approach Section */}
       <KeyApproach />

                   {/* Governance Reform Experts Section */}
                   <div className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
  <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
    Our  Governance Reform Experts
  </h2>

  <div className="flex justify-center gap-16 flex-wrap">
    {[
    {
      name: "Dr. Omotunde Oni",
      role: "Board Member, Expert Governance Reforms",
      imageUrl: "https://via.placeholder.com/300x300?text=Misha+Pratap",
    },
    {
      name: "Promise Ikpuri",
      role: "Head of Operations",
      imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739447842/WhatsApp_Image_2025-02-13_at_12.55.28_p35ctp.jpg",
    },
    ].map((expert, index) => (
      <div key={index} className="shadow-lg rounded-lg overflow-hidden text-left bg-white w-[280px]">
        <img src={expert.imageUrl} alt={expert.name} className="w-full h-[300px] object-cover" />
        <div className="p-4">
          <h3 className="text-xl font-bold text-gray-900">{expert.name}</h3>
          <p className="text-gray-600 mt-1">{expert.role}</p>
        </div>
      </div>
    ))}
  </div>
</div>
    </section>
  );
};

export default GovernanceReform;

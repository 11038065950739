import React, { useState } from "react";

const SustainableTransportation = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const expertiseData = [
    {
      title: "Adoption of Electric and Autonomous Vehicles (EVs and AVs)",
      description:
        "The shift to electric and autonomous fleets represents a transformative opportunity for businesses aiming to reduce their environmental impact while improving operational efficiency. We guide our clients through this transition by:",
      points: [
        "Evaluating Feasibility: Assessing the compatibility of EVs and AVs with existing infrastructure and operational needs.",
        "Facilitating Integration: Assisting with the adoption of EVs and AVs, from selecting appropriate models to implementing charging stations and maintenance protocols.",
        "Maximizing ROI: Analyzing cost savings from reduced fuel consumption, tax incentives, and lower maintenance requirements while calculating environmental benefits such as carbon reduction.",
      ],
    },
    {
      title: "Fleet Optimization with AI-Powered Tools",
      description:
        "Efficiency in transportation is key to sustainability. Our fleet optimization services leverage cutting-edge AI-driven tools to ensure the smartest use of resources, including:",
      points: [
        "Route Optimization: Using algorithms to calculate the most efficient delivery routes, reducing travel time and fuel consumption.",
        "Predictive Maintenance: Monitoring vehicle health in real-time to predict and prevent breakdowns, minimizing costly repairs and downtime.",
        "Load Optimization: Balancing payload distribution to improve fuel efficiency and reduce wear and tear on vehicles.",
      ],
    },
    {
      title: "Transition to Sustainable Fuel Alternatives",
      description:
        "Fuels play a pivotal role in transforming transportation sustainability. We help businesses transition to low-emission fuel sources, including:",
      points: [
        "Hydrogen Fuel: Supporting the adoption of hydrogen-powered vehicles, offering a clean alternative to traditional fuels with water vapor as the only emission.",
        "Biodiesel and Renewable Diesel: Guiding the integration of biofuels derived from renewable sources to reduce reliance on fossil fuels without significant changes to existing infrastructure.",
        "Electric Charging Networks: Developing comprehensive strategies for building and managing charging networks to support EV fleets.",
      ],
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737555949/pexels-photo-912050_ehvnyv.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Sustainable Transportation
          </h1>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Pioneering the Shift to Greener Mobility
        </h2>
        <p className="text-gray-700 text-lg mb-4">
          As the global community increasingly prioritizes environmental sustainability, businesses must adapt transportation strategies to meet these expectations while remaining cost-effective. At Seismic Consulting Group, we enable organizations to transition to sustainable transportation models that deliver long-term environmental and economic benefits. Our solutions focus on reducing carbon footprints, optimizing operations, and fostering innovation to align with the demands of a greener, more responsible future.
        </p>
      </div>

{/* Benefits Section */}
<div className="py-16 max-w-8xl mx-auto px-6 lg:px-20">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-20">
    The Business Benefits of Sustainable Transportation
  </h2>
  <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 text-center">
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
          alt="Environmental Impact"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Environmental Impact</h3>
      <p className="text-gray-700 mt-2">
        Significant reductions in carbon emissions and other pollutants contribute to global climate goals and local environmental health.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546264/7498046.png_ejk71i.png"
          alt="Cost Efficiency"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Cost Efficiency</h3>
      <p className="text-gray-700 mt-2">
        Reduced fuel consumption, tax incentives for green initiatives, and lower maintenance costs ensure financial sustainability.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png"
          alt="Regulatory Compliance"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Regulatory Compliance</h3>
      <p className="text-gray-700 mt-2">
        Alignment with evolving environmental regulations and standards helps mitigate legal risks and penalties.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737556289/7278881.png_pl8sa1.png"
          alt="Brand Reputation"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Brand Reputation</h3>
      <p className="text-gray-700 mt-2">
        Demonstrating commitment to sustainability enhances brand image, fostering trust and loyalty among consumers, investors, and stakeholders.
      </p>
    </div>
  </div>
</div>


      {/* Expertise Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Key Areas of Expertise
        </h2>
        {expertiseData.map((item, index) => (
          <div key={index} className="mb-12 border-b border-pink-100 pb-8">
            <button
              onClick={() => toggleSection(index)}
              className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
            >
              {item.title}
              <span className="text-purple-900 text-3xl">
                {openSection === index ? "-" : "+"}
              </span>
            </button>
            {openSection === index && (
              <div>
                <p className="text-gray-700 text-lg mb-4">{item.description}</p>
                {item.points && (
                  <ul className="list-none text-gray-700 text-lg space-y-4 mt-4 px-6 lg:px-32">
                    {item.points.map((point, idx) => (
                      <li key={idx} className="relative pl-6">
                        <span
                          className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 border-purple-900 rounded-full"
                        ></span>
                        {point}
                      </li>
                    ))}
                  </ul>
                )}
                {item.conclusion && (
                  <p className="text-gray-700 text-lg mt-4">{item.conclusion}</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Call to Action Section */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737556331/pexels-photo-799443_x4wifn.jpg"
              alt="Call to Action"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
          <div>
            <h2 className="text-3xl font-bold mb-4">
              Driving the Future of Transportation
            </h2>
            <p className="text-gray-600 text-lg mb-4">
              The transition to sustainable transportation is no longer a matter of choice but a strategic necessity for businesses seeking resilience and relevance in an evolving market.
            </p>
            <p className="text-gray-600 text-lg">
              By adopting electric and autonomous vehicles, optimizing fleet operations, and transitioning to alternative fuels, our clients achieve transformative results that extend beyond compliance, positioning them as leaders in the global drive toward sustainability.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SustainableTransportation;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const areasOfFocus = [
  {
    title: "Early Childhood Development (ECD)",
    description:
      "Seismic Consulting Group provides specialized consulting services aimed at enhancing early childhood education by supporting the holistic development of children.",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493159/ecd_ssu7nx.jpg",
    link: "/consulting-services/education-reform/early-childhood-development",
  },
  {
    title: "Primary Education",
    description:
      "Seismic’s consulting services in primary education are designed to improve foundational learning, literacy, and numeracy outcomes.",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738141590/WhatsApp_Image_2025-01-28_at_09.57.14_sfozpc.jpg",
    link: "/consulting-services/education-reform/primary-education",
  },
  {
    title: "Secondary Education",
    description:
      "Seismic’s secondary education consulting services focus on preparing students for success in higher education and the labor market.",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738141591/WhatsApp_Image_2025-01-28_at_10.11.53_p2vz18.jpg",
    link: "/consulting-services/education-reform/secondary-education",
  },
  {
    title: "Technical and Vocational Education and Training (TVET)",
    description:
      "Our approach to TVET is firmly rooted in the internationally recognized German dual apprenticeship system.",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745250/WhatsApp_Image_2025-02-05_at_09.45.49_vsk3mb.jpg",
    link: "/consulting-services/education-reform/tvet",
  },
  {
    title: "Higher Education",
    description:
      "Seismic Consulting provides tailored solutions to ensure higher education institutions are equipped to meet workforce demands.",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1734689220/istockphoto-858465172-612x612_gwv5de.jpg",
    link: "/consulting-services/education-reform/higher-education",
  },
  {
    title: "Non-Formal Education and Lifelong Learning",
    description:
      "Seismic emphasizes lifelong learning as a means to support individuals in staying relevant in evolving workforce environments.",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738070679/WhatsApp_Image_2025-01-28_at_09.52.28_gmdxag.jpg",
    link: "/consulting-services/education-reform/non-formal-education",
  },
];

const AreaOfFocusSection = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    // Preload images
    areasOfFocus.forEach((area) => {
      const img = new Image();
      img.src = area.image;
    });
  }, []);

  const toggleExpansion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
<section className="py-16 px-6 lg:px-2 bg-white">
  <div className="max-w-7xl mx-auto text-center mb-8">
    <h2 className="text-4xl font-bold text-gray-800">Our Area of Focus</h2>
    <p className="text-xl text-gray-600 mt-2">Shaping Education for Global Impact</p>
  </div>

  <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-8">
    {/* Adjusted Parent Div */}
    <div className="lg:w-5/12 w-full flex justify-center lg:justify-start pl-0 lg:pl-4">
      <img
        src={
          expandedIndex !== null
            ? areasOfFocus[expandedIndex].image
            : "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745250/WhatsApp_Image_2025-02-05_at_09.45.49_vsk3mb.jpg"
        }
        alt="Area of Focus"
        className="w-full h-auto object-cover shadow-lg lg:w-[650px] lg:h-[500px]"
        style={{
          maxWidth: "100%", // Ensure the image does not exceed its container width
          height: "auto",   // Maintain aspect ratio
          transition: "none",
        }}
      />
    </div>

    <div className="lg:w-7/12 w-full flex justify-center">
      <div className="w-full max-w-[550px]">
        {areasOfFocus.map((area, index) => (
          <div key={index} className="border-b border-[#F2E6F2] py-4">
            <h3
              onClick={() => toggleExpansion(index)}
              className="text-xl font-medium text-gray-800 flex items-center justify-between cursor-pointer"
            >
              {area.title}
              <span className="text-purple-800 text-2xl">
                {expandedIndex === index ? "−" : "+"}
              </span>
            </h3>
            {expandedIndex === index && (
              <>
                <p className="text-gray-700 mt-2 text-sm leading-relaxed">
                  {area.description}
                </p>
                <div className="mt-4">
                  <Link
                    to={area.link}
                    className="text-purple-800 font-semibold hover:underline text-sm"
                  >
                    Read More →
                  </Link>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
</section>
  );
};

export default AreaOfFocusSection;

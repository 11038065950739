import React, { useState } from "react";
import KeyAreasOfServices from "./KeyAreasOfServices";
import VerificationProjectsExecuted from "./VerificationProjectsExecuted";

const IndependentVerification = () => {
  const [activeOffering, setActiveOffering] = useState(
    "Outcome-Based Verification"
  );

  // For mobile, each offering will be collapsible independently.
  const [mobileOfferingsAccordion, setMobileOfferingsAccordion] = useState(
    // Initialize an array of booleans (one per offering)
    ["Outcome-Based Verification", "Real-Time Data Collection and Analysis", "Comprehensive Risk Management"].map(() => false)
  );

  const toggleMobileOfferingsAccordion = (index) => {
    setMobileOfferingsAccordion((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const offerings = [
    "Outcome-Based Verification",
    "Real-Time Data Collection and Analysis",
    "Comprehensive Risk Management",
  ];

  const offeringDetails = {
    "Outcome-Based Verification": {
      title: "Ensuring Tangible Results",
      description:
        "In today’s impact-driven world, traditional verification methods are often insufficient. Seismic’s outcome-based verification focuses on verifying the actual results and measurable impact of projects, not just the process. This approach ensures that funds are being used as intended and that objectives are being met in a way that delivers true, lasting value. By examining each stage of the project with a results-oriented lens, we help our clients assess the effectiveness of their investments, ensuring that every dollar spent translates into real, positive outcomes for communities and stakeholders.",
    },
    "Real-Time Data Collection and Analysis": {
      title: "Real-Time Insights",
      description:
        "At Seismic, we use advanced technology to provide real-time data collection and analysis for our verification processes. This capability gives stakeholders access to live, actionable insights, allowing for timely adjustments and course corrections to keep projects on track. Through real-time monitoring, we identify emerging trends, pinpoint potential challenges, and highlight areas of opportunity—all while the project is underway. This proactive approach helps clients mitigate risks early, optimize resource allocation, and ensure that project execution is always aligned with strategic goals.",
    },
    "Comprehensive Risk Management": {
      title: "Proactive Risk Mitigation",
      description:
        "Seismic’s verification processes include a comprehensive risk management framework that goes beyond simply identifying risks. Our team conducts thorough, sector-specific risk assessments to anticipate potential issues before they arise. With this proactive approach, we develop and implement tailored mitigation strategies to protect project objectives and facilitate smooth execution. By managing risks upfront, we help clients avoid costly delays and disruptions, ensuring a strong foundation for project success.",
    },
  };

  const benefits = [
    {
      title: "Credibility and Expertise",
      description:
        "Our seasoned professionals ensure precise, evidence-based assessments, building trust and confidence among stakeholders.",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737556289/7278881.png_pl8sa1.png",
    },
    {
      title: "Tailored Solutions",
      description:
        "Seismic provides customized verification and assurance services designed to meet the unique requirements of each audience segment.",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738664804/7498017.png_xjmusv.png",
    },
    {
      title: "Enhanced Transparency",
      description:
        "By fostering transparency and accountability, we empower our clients to achieve their goals and maintain stakeholder confidence.",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738664921/7278131.png_x7hlmm.png",
    },
    {
      title: "Sustainability Focus",
      description:
        "We help clients align with global standards, ensuring their projects deliver sustainable and impactful results.",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738664839/7272713.png_bdaaqo.png",
    },
  ];

  return (
    <div className="pt-16">
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-12"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737467754/pexels-wildlittlethingsphoto-705792_qktjrd.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-20">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white">
            Monitoring and Evaluation, Independent Verification and Assurance
          </h1>
          <p className="text-base lg:text-lg text-white mt-4">
            We redefine verification and assurance to bring unparalleled
            transparency and accountability to our clients.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-12 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Monitoring and Evaluation, Independent Verification and Assurance
        </h1>
      </div>

      {/* Context Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-16">
        <p className="text-gray-700 text-lg leading-relaxed mb-8">
          In a world where transparency and accountability have become
          indispensable, transforming complex projects into pathways for
          sustainable change is our cue— we stand at the forefront. At Seismic
          Consulting, we redefine what it means to deliver results. Our
          Independent Verification and Assurance Services are not merely
          oversight; they are catalysts for excellence. By combining
          cutting-edge technology, rigorous data analysis, and an outcome driven
          approach, we ensure that every initiative, from public financial
          management to education reform, fulfills its promises and drives
          tangible impact. With Seismic, you’re not just achieving goals; you’re
          creating a legacy of integrity, efficiency, and measurable progress.
          Our team partners with you to hold your projects to the highest
          standards, empowering you to realize a future defined by meaningful,
          lasting outcomes.
        </p>
        <p className="text-gray-700 text-lg leading-relaxed mb-8">
          At Seismic Consulting, we believe that transparency and accountability
          are essential for driving meaningful and sustainable change in any
          development project. As a global consulting firm, we specialize in
          Independent Verification and Assurance Services that provide a
          rigorous, data-driven approach to ensure projects not only meet their
          objectives but also deliver maximum value and impact. Our team of
          seasoned experts, coupled with cutting-edge technology, enables us to
          offer innovative solutions that extend far beyond conventional
          verification practices. By partnering with Seismic, stakeholders gain
          peace of mind that their projects are progressing effectively,
          responsibly, and with lasting outcomes.
        </p>
      </div>

      {/* Why Seismic Section */}
      <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-16 mt-8"></div>
      <div className="mx-auto px-4 lg:px-32 mt-8 lg:mt-16">
        <div className="flex flex-col lg:flex-row items-center gap-6 lg:gap-12">
          {/* Text Section */}
          <div className="lg:w-1/2 text-center lg:text-left">
            {/* Image Section for Small Screens */}
            <div className="lg:hidden mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737624157/pexels-photo-6929004_tkdoqy.jpg"
                alt="Why Seismic"
                className="w-full rounded-lg"
              />
            </div>
            <h2 className="text-3xl font-bold text-gray-800 text-center mb-4">
              Why Seismic
            </h2>
            <p className="text-gray-600 text-base lg:text-lg leading-relaxed">
              Why Seismic’s Verification and Assurance Services Stand Out - At
              Seismic, we don’t just verify compliance; we actively work to
              optimize project impact. Our independent verification services
              provide objective insights into the performance, resource
              utilization, and results of development projects, empowering
              stakeholders with the information they need to make decisions that
              will maximize benefits. Seismic’s approach emphasizes
              collaboration, innovation, and a deep understanding of each
              project’s unique challenges and goals.
            </p>
          </div>
          {/* Image Section for Larger Screens */}
          <div className="lg:w-1/2 hidden lg:block">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737624157/pexels-photo-6929004_tkdoqy.jpg"
              alt="Why Seismic"
              className="w-full rounded-lg"
            />
          </div>
        </div>
        <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-16 mt-8"></div>
      </div>

      {/* Key Offerings Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-16">
        {/* Title and Subheading */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Key Offerings in Independent Verification
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            Seismic Consulting employs a comprehensive, structured approach to
            Independent Verification and Assurance.
          </p>
        </div>

        {/* Desktop Version */}
        <div className="hidden lg:flex">
          <div className="lg:w-1/3 w-full lg:pr-8">
            <div className="bg-[#F9F5FB] p-8 space-y-4">
              {offerings.map((offering, index) => (
                <div key={offering}>
                  <button
                    onClick={() => setActiveOffering(offering)}
                    className={`text-left py-6 px-4 font-semibold text-xl w-full ${
                      activeOffering === offering
                        ? "bg-gradient-to-b from-[#2D002D] to-[#600060] text-white"
                        : "text-gray-800 hover:bg-purple-100"
                    }`}
                  >
                    {offering}
                  </button>
                  {index < offerings.length - 1 && (
                    <div className="border-t border-purple-200 w-full"></div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="lg:w-2/3 w-full">
            <div className="p-8 bg-white shadow-md rounded-md">
              <h2 className="text-3xl font-bold mb-4">
                {offeringDetails[activeOffering].title}
              </h2>
              <p className="text-gray-700 text-lg leading-relaxed">
                {offeringDetails[activeOffering].description}
              </p>
            </div>
          </div>
        </div>

        {/* Mobile Version */}
        <div className="block lg:hidden">
          <div className="bg-[#F9F5FB] p-8 space-y-4">
            {offerings.map((offering, index) => (
              <div key={offering} className="border-t border-purple-200">
                <button
                  onClick={() => toggleMobileOfferingsAccordion(index)}
                  className={`flex justify-between items-center w-full py-6 px-4 font-semibold text-xl transition ${
                    mobileOfferingsAccordion[index]
                      ? "bg-gradient-to-b from-[#2D002D] to-[#600060] text-white"
                      : "text-gray-800 hover:bg-purple-100"
                  }`}
                >
                  <span>{offering}</span>
                  <span className="text-2xl">
                    {mobileOfferingsAccordion[index] ? "-" : "+"}
                  </span>
                </button>
                {mobileOfferingsAccordion[index] && (
                  <div className="px-4 pb-6 bg-white">
                    <h2 className="text-3xl font-bold mb-4">
                      {offeringDetails[offering].title}
                    </h2>
                    <p className="text-gray-700 text-lg leading-relaxed">
                      {offeringDetails[offering].description}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="max-w-8xl mx-auto px-6 lg:px-12 mt-16 lg:mt-24">
        <h3 className="text-2xl font-bold text-gray-900 mb-8 text-center">
          How Seismic Addresses the Needs of Its Audience
        </h3>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto text-center mb-12">
          Seismic Consulting’s Independent Verification and Assurance services
          are ideal for stakeholders seeking reliable, professional, and
          impactful solutions to enhance accountability and success in their
          initiatives.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <div key={index} className="text-center p-6">
              <div className="flex justify-center mb-4">
                <img
                  src={benefit.icon}
                  alt={`${benefit.title} Icon`}
                  className="w-12 h-12"
                />
              </div>
              <h4 className="text-xl font-semibold text-gray-900 mb-2">
                {benefit.title}
              </h4>
              <p className="text-gray-600 text-base max-w-sm mx-auto leading-relaxed">
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      <KeyAreasOfServices />

      {/* Target Audience Section */}
      <div className="bg-gradient-to-b from-[#2D002D] to-[#600060] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 lg:px-8">
          <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
            Target Audience
          </h2>
          <p className="text-lg text-center mb-10">
            Our Change Management & Strategy Consulting services cater to a wide
            range of organizations and stakeholders.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Audience Item 1 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png"
                  alt="Government Agencies"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                Government Agencies and Ministries
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Federal, state, and local governments involved in project
                  implementation and monitoring.
                </li>
                <li>
                  Ministries such as Finance, Education, Health, and Agriculture
                  overseeing development projects.
                </li>
              </ul>
            </div>

            {/* Audience Item 2 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
                  alt="Development Partners"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                Development Partners and Donor Organizations
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  International agencies like the World Bank, USAID, UNDP, and
                  GIZ.
                </li>
                <li>
                  Organizations funding health, education, infrastructure, and
                  social development projects.
                </li>
              </ul>
            </div>

            {/* Audience Item 3 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png"
                  alt="NGOs and CSOs"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                NGOs and Civil Society Organizations
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  NGOs implementing projects requiring third-party validation.
                </li>
                <li>
                  Advocacy groups ensuring accountability in publicly funded
                  projects.
                </li>
              </ul>
            </div>

            {/* Audience Item 4 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765499/search_7501627_llk7w8.png"
                  alt="Private Sector Companies"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                Private Sector Companies
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Organizations engaged in PPPs or corporate social
                  responsibility initiatives.
                </li>
                <li>
                  Private companies seeking assurance for compliance with
                  regulations.
                </li>
              </ul>
            </div>

            {/* Audience Item 5 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765626/person_7498074_e8j9wb.png"
                  alt="Multilateral Agencies"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                Multilateral and Bilateral Agencies
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Entities like AfDB, EU, and DFID involved in funding and
                  managing large-scale programs.
                </li>
              </ul>
            </div>

            {/* Audience Item 6 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png"
                  alt="Research Institutions"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                Research and Academic Institutions
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Universities conducting impact evaluations or seeking
                  validation for grant-funded initiatives.
                </li>
              </ul>
            </div>

            {/* Audience Item 7 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
                  alt="Grassroots Organizations"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                Communities and Grassroots Organizations
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Local organizations engaged in social development projects
                  seeking external validation.
                </li>
              </ul>
            </div>

            {/* Audience Item 8 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png"
                  alt="Independent Auditors"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                Independent Auditors and Regulators
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Regulatory bodies overseeing compliance and performance of
                  policies and programs.
                </li>
              </ul>
            </div>

            {/* Audience Item 9 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765499/search_7501627_llk7w8.png"
                  alt="Corporate and Institutional Investors"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-2">
                Corporate and Institutional Investors
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Investors requiring assurance of project viability,
                  sustainability, and impact.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <VerificationProjectsExecuted />

      {/* Commitment to Excellence Section */}
      <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-16 mt-8"></div>
      <div className="mx-auto px-4 lg:px-32 mt-8 lg:mt-16">
        <div className="flex flex-col lg:flex-row items-center gap-6 lg:gap-12">
          {/* Text Section */}
          <div className="lg:w-1/2 text-center lg:text-left">
            {/* Image Section for Small Screens */}
            <div className="lg:hidden mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738161776/pexels-photo-8847193_qpwfg4.jpg"
                alt="Our Commitment"
                className="w-full rounded-lg"
              />
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our Commitment to Excellence
            </h2>
            <p className="text-gray-600 text-base lg:text-lg leading-relaxed">
              At Seismic Consulting, we pride ourselves on our ability to
              deliver Independent Verification and Assurance services with
              integrity, precision, and innovation. By partnering with us,
              clients benefit from our commitment to excellence and our proven
              track record of enabling transformative change through
              evidence-based strategies.
            </p>
          </div>
          {/* Image Section for Larger Screens */}
          <div className="lg:w-1/2 hidden lg:block">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738161776/pexels-photo-8847193_qpwfg4.jpg"
              alt="Our Commitment"
              className="w-full rounded-lg"
            />
          </div>
        </div>
        <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-16 mt-8"></div>
      </div>

      {/* Independent Verification Experts Section */}
      <section className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
          Our Independent Verification Experts
        </h2>
        <div className="flex justify-center gap-16 flex-wrap">
          {[
            {
              name: "Prof. Okunlola Olaniyi James",
              role: "Board Member, Expert Monitoring and Evaluation",
              imageUrl:
                "https://res.cloudinary.com/dxhx45ing/image/upload/v1739435925/bmxruumap7ykui61g8st.jpg",
            },
            {
              name: "Alfred Kobo",
              role: "Associate, Monitoring & Evaluation",
              imageUrl:
                "https://res.cloudinary.com/dxhx45ing/image/upload/v1739459266/WhatsApp_Image_2025-02-13_at_16.06.42_ibulhk.jpg",
            },
          ].map((expert, index) => (
            <div
              key={index}
              className="shadow-lg rounded-lg overflow-hidden text-left bg-white w-[280px]"
            >
              <img
                src={expert.imageUrl}
                alt={expert.name}
                className="w-full h-[300px] object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-gray-900">
                  {expert.name}
                </h3>
                <p className="text-gray-600 mt-1">{expert.role}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default IndependentVerification;

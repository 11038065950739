import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";

const HealthcarePage = () => {


  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1737456819/pexels-chokniti-khongchum-1197604-3938023_libt9g.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-10">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Healthcare and Life Sciences
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
          Partnering with organizations to navigate the complexities of healthcare delivery,
          regulatory frameworks, and patient engagement.
          </p>
        </div>
      </section>

            {/* Title Under Image for Small Screens */}
            <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Healthcare and Life Sciences
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
          Transforming Healthcare Delivery with Innovative Solutions
        </h2>
        <p className="text-lg text-gray-700 mb-4">
          At Seismic Consulting Group, we redefine what’s possible in the healthcare and life
          sciences industry by driving transformative solutions that address the most pressing
          challenges of modern healthcare systems. We partner with organizations to navigate the
          complexities of healthcare delivery, regulatory frameworks, and patient engagement,
          creating a seamless ecosystem that prioritizes innovation, efficiency, and impact.
        </p>
        <p className="text-lg text-gray-700">
          Our approach combines cutting-edge technologies, advanced data analytics, and process
          optimization strategies to empower healthcare providers, life sciences companies, and
          policymakers. We are committed to enhancing patient outcomes, streamlining operations,
          and fostering sustainable healthcare systems that adapt to ever-evolving demands.
        </p>
      </div>

{/* Why Seismic Section */}
<div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
  <h3 className="text-2xl font-bold text-gray-900 text-center mb-8">
    Why Seismic Consulting Group?
  </h3>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
    <div>
      <div className="flex justify-center mb-4">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736942834/learning_7243265_exhpfw.png"
          alt="Visionary Solutions Icon"
          className="w-14 h-14"
        />
      </div>
      <h4 className="text-xl font-semibold text-purple-900 mb-2">Visionary Solutions</h4>
      <p className="text-gray-600">
        We embrace forward-thinking strategies that anticipate industry trends and position
        our clients for long-term success.
      </p>
    </div>
    <div>
      <div className="flex justify-center mb-4">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765626/person_7498074_e8j9wb.png"
          alt="Cross-Sector Expertise Icon"
          className="w-14 h-14"
        />
      </div>
      <h4 className="text-xl font-semibold text-purple-900 mb-2">Cross-Sector Expertise</h4>
      <p className="text-gray-600">
        Our consultants bring diverse, global experience, ensuring every solution is tailored
        to unique organizational needs.
      </p>
    </div>
    <div>
      <div className="flex justify-center mb-4">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png" 
          alt="Sustainable Innovation Icon"
          className="w-14 h-14"
        />
      </div>
      <h4 className="text-xl font-semibold text-purple-900 mb-2">Sustainable Innovation</h4>
      <p className="text-gray-600">
        Our focus is on creating enduring value by aligning healthcare innovation with
        operational sustainability.
      </p>
    </div>
  </div>
</div>


      {/* Key Areas of Expertise Section */}
      <div className="py-16 px-4 lg:px-20">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Key Areas of Expertise
    </h2>
    <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
      Seismic Consulting's Change Management & Strategy Consulting services
      deliver tangible, sustainable outcomes that drive long-term success.
    </p>

    {/* Row 1 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 1 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738668859/young-woman-having-a-doctors-appointment-online-at-home_t9uddk.jpg"
          alt="Telemedicine and Digital Health"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2">
              Telemedicine and Digital Health
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              By enabling healthcare providers to implement advanced telemedicine platforms, we bridge the gap between patients and care providers, ensuring that quality healthcare is accessible to everyone, regardless of location.
</p>
          </div>
          <a
            href="/industries/healthcare/telemedicine-digital-health"
            className="text-purple-900 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>

      {/* Box 2 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">Personalized Medicine</h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Leveraging the latest advancements in genomics, artificial intelligence (AI), and data analytics, we help life sciences companies and healthcare providers create tailored treatment plans designed to meet the unique needs of individual patients.
          </p>
        </div>
        <a
          href="/industries/healthcare/personalized-medicine"
          className="text-purple-900 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>

    {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Healthcare Systems Transformation
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            We specialize in driving comprehensive healthcare systems transformations, helping organizations adapt to the rapidly evolving landscape of modern medicine.
          </p>
        </div>
        <a
          href="/industries/healthcare/healthcare-systems-transformation"
          className="text-purple-900 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737457273/istockphoto-1007900266-612x612_owsis5.jpg"
          alt="Regulatory Compliance and Risk Management"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2">
              Regulatory Compliance and Risk Management
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              We offer comprehensive regulatory compliance and risk management solutions that help organizations confidently operate in this dynamic environment.
            </p>
          </div>
          <a
            href="/industries/healthcare/regulatory-compliance"
            className="text-purple-900 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

{/* Digital Projects Introduction */}
<div className="max-w-6xl mx-auto px-6 lg:px-12 mt-20">
  <div className="flex flex-col md:flex-row items-center gap-8">
    {/* Left Column: Image */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739800247/pexels-photo-5452300_klxp7s.jpg"
        alt="Digital Projects"
        className="w-full rounded-lg shadow-md"
      />
    </div>
    {/* Right Column: Text Content */}
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        Revolutionizing Healthcare with Technology
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        Our suite of digital projects is at the forefront of transforming healthcare delivery. From state-of-the-art Hospital ERP systems to AI-powered mental health diagnostics, our innovative solutions empower organizations to optimize operations, enhance patient care, and drive sustainable growth.
      </p>
      <Link
    to={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalHealthcare}`} 
        className="inline-flex items-center px-6 py-3 border border-purple-900 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
      >
        View Digital Projects
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  </div>
</div>


{/* Patient Experience Section */}
<div className="bg-gray-50 py-12 mt-8">
  <div className=" mx-auto px-6 lg:px-12">
    <h3 className="text-2xl lg:text-3xl font-bold text-center mb-8">
      Creating a Connected Patient Experience
    </h3>
    <p className="text-lg text-gray-600 text-center mb-12 max-w-2xl mx-auto">
      Healthcare is about more than treatment; it's about relationships and trust.
      Our telemedicine platforms are designed to create a patient-centric experience that emphasizes:
    </p>

    {/* Grid Section */}
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
      {[
  
        {
          title: "Accessibility and Convenience",
          description: `Patients can consult with healthcare professionals from the comfort of their homes, reducing stress and increasing satisfaction.`,
          image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737457522/Walmart-Healthcare-Pharmacy_ggwgxs.jpg",
          link: "/connected-care/accessibility-and-convenience",
        },
        {
          title: "Engagement and Empowerment",
          description: `Tools such as health tracking apps and educational resources empower patients to take an active role in managing their health.`,
          image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737457583/24e365509869cbc6eb035ce17983a4b9-md_ki60iy.jpg",
          link: "/connected-care/engagement-and-empowerment",
        },
        {
            title: "Continuity of Care",
            description: `Seamless integration with electronic health records (EHR) ensures that patient history is accessible, allowing providers to deliver personalized care.`,
            image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737457402/istockphoto-1437830105-612x612_pewsov.jpg",
            link: "/connected-care/continuity-of-care",
          },
      ].map((item, index) => (
        <div
          key={index}
          className="bg-white rounded-lg shadow-md overflow-hidden lg:w-[95%] mx-auto"
        >
          <img
            src={item.image}
            alt={item.title}
            className="w-full h-56 object-cover"
          />
          <div className="p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              {item.title}
            </h3>
            <p className="text-gray-600 text-sm mb-4">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>

      {/* Impactful Results Section */}
      <section className="bg-white py-16">
  <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
    {/* Text Section */}
    <div className="w-full md:w-1/2">
      <h2 className="text-2xl font-medium mb-4">Impactful Results</h2>
      <p className="text-gray-600 text-xl leading-relaxed mb-4">
        Our work transcends immediate business outcomes to deliver lasting societal benefits. By reducing patient wait times, improving care quality, and lowering operational costs, we help healthcare organizations achieve measurable results that benefit communities. Our solutions are not just about solving today's challenges—they are about building resilient systems for the future.
      </p>
      <p className="text-gray-600 text-xl leading-relaxed">
        From reimagining healthcare delivery models to empowering breakthroughs in life sciences, Seismic Consulting Group is your trusted partner in shaping a healthier, more equitable world. Together, we’ll create transformative impacts that extend far beyond the boundaries of the healthcare sector.
      </p>
    </div>

    {/* Image Section */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737457648/istockphoto-1341490004-612x612_ibht0y.jpg"
        alt="Why Choose Seismic Consulting"
        className="w-full"
      />
    </div>
  </div>
</section>

 </div>
  );
};

export default HealthcarePage;

import React from "react";

function AgileEnterpriseDevelopmentPage() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Agile Enterprise Development
          </h1>
        </div>
      </section>

      {/* Intro Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Modern enterprises operate in a fast-paced environment where flexibility and responsiveness are crucial
            for success. At Seismic Consulting Group, we specialize in crafting agile technological infrastructures that
            empower organizations to adapt quickly to evolving market demands and technological advancements.
          </p>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Our team of experts designs and implements scalable solutions that enhance operational agility and efficiency.
          </p>
        </div>
      </section>

      {/* Cloud Computing Services */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Cloud Computing Services</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              We provide comprehensive cloud strategies that enable clients to scale their operations seamlessly. By leveraging
              cloud technologies, we help organizations optimize resources, reduce costs, and improve collaboration.
              Our services include cloud migration, integration, and management, ensuring a smooth transition and minimal disruption to business processes.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Cloud Migration:</strong> Seamlessly transition to the cloud while minimizing disruption to business operations.</li>
              <li><strong>Cloud Integration:</strong> Integrate cloud solutions into your existing infrastructure to enhance functionality and flexibility.</li>
              <li><strong>Cloud Management:</strong> Manage and optimize your cloud environment to ensure scalability, efficiency, and cost-effectiveness.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Cybersecurity Frameworks */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Cybersecurity Frameworks</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Data integrity and security are paramount in today's digital landscape. Seismic implements robust cybersecurity
              measures to protect your critical assets. Our frameworks are designed to safeguard against threats, ensure
              compliance with industry regulations, and maintain the confidentiality and availability of your data.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Risk Assessments:</strong> Identify vulnerabilities and potential risks in your infrastructure.</li>
              <li><strong>Security Architecture Design:</strong> Build a resilient cybersecurity framework that protects against evolving threats.</li>
              <li><strong>Ongoing Monitoring:</strong> Continuously monitor and update your security protocols to safeguard against emerging threats.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Conclusion Section */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">Empowering Your Organization for the Future</h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            By integrating agile methodologies with cutting-edge technologies, Seismic Consulting enhances your organization’s
            ability to respond swiftly to changes, innovate continuously, and maintain a competitive edge. Our commitment to excellence ensures
            that the solutions we provide are not only effective but also aligned with your strategic objectives.
          </p>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Through our Agile Enterprise Development services, we position your organization to achieve sustainable growth, improve
            operational efficiency, and navigate the complexities of the modern business environment with confidence.
          </p>
        </div>
      </section>
    </div>
  );
}

export default AgileEnterpriseDevelopmentPage;

export const paths = {
  homeIndex: '',
  consultingServices: 'consulting-services',
  digital: 'digital',
  careers: 'careers',
  contact: 'contact', // Contact path

  // Education paths
  educationReform: 'education-reform',
  allEducationProjects: 'all-education-projects',
  earlyChildhoodDevelopment: 'early-childhood-development',
  primaryEducation: 'primary-education',
  secondaryEducation: 'secondary-education',
  tvet: 'tvet',
  higherEducation: 'higher-education',
  lifelongLearning: 'non-formal-education',
  projectDetail: ':projectId',
  
  // PFM paths
  pfm: 'public-financial-management',
  fmis: 'financial-management-information-systems',
  pfpa: 'public-finance-policy-assessment',
  pbb: 'performance-based-budgeting',
  afcs: 'audit-and-financial-control-systems',
  cbt: 'capacity-building-and-training',
  cip: 'capital-investment-planning',
  dfm: 'decentralization-fiscal-management',

  
  // Governance Reform paths
  governanceReform: 'governance-reform',
  changeManagement: 'change-management',

  // Capacity Building paths
  capacityBuilding: 'capacity-building',
  needsAssessment: 'needs-assessment',
  programDesignAndCustomization: 'program-design-and-customization',
  implementation: 'implementation',
  monitoringAndEvaluation: 'monitoring-and-evaluation',
  sustainabilityPlanning: 'sustainability-planning',
  accomplishedProjects: 'accomplished-projects',

  // Social Protection Poverty Reduction paths
  socialProtectionPovertyReduction: 'social-protection',
  comprehensiveNeedsAssessment: 'comprehensive-needs-assessment',
  integratedSocialProtection: 'integrated-social-protection',
  innovativeFinancingMechanisms: 'innovative-financing-mechanisms',
  dataDrivenTargeting: 'data-driven-targeting',
  communityEmpowerment: 'community-empowerment',
  monitoringEvaluation: 'monitoring-evaluation',

  // Change Management paths
  changeManagementPage: 'change-management-page',
  adaptiveStrategyDevelopment: 'adaptive-strategy-development',
  culturalTransformation: 'cultural-transformation',
  digitalTransformationCM: 'digital-transformation-cm',
  changeManagementFrameworks: 'change-management-frameworks',
  capacityBuildingForChangeLeaders: 'capacity-building-for-change-leaders',

  // Independent Verification paths
  independentVerification: 'independent-verification',

 // industries paths
     industries: 'industries',
    education: "industries/education",
    financialServices: "industries/financial-services",
    agriculture: "industries/agriculture-and-agribusiness",
    technology: "industries/technology-and-telecommunications",
    manufacturing: "industries/manufacturing-and-industrial-engineering",
    audit: "industries/audit-and-assurance",
    miningIndustryPage: "industries/mining",
    manufacturingIndustry: "industries/manufacturing-industry",

    // Healthcare paths
    healthcare: "industries/healthcare-and-life-sciences",
    telemedicineDigitalHealth: "industries/healthcare/telemedicine-digital-health",
    personalizedMedicine: "industries/healthcare/personalized-medicine",
    healthcareSystemsTransformation: "industries/healthcare/healthcare-systems-transformation",
    regulatoryCompliance: "industries/healthcare/regulatory-compliance",
  
    // Transportation paths
    transportation: "industries/transportation-and-logistics",
    smartLogistics: "industries/transportation/smart-logistics",
    sustainableTransportation: "industries/transportation/sustainable-transportation",
    urbanMobility: "industries/transportation/urban-mobility",
    digitalPlatforms: "industries/transportation/digital-platforms",

    // Renewable Energy paths
    renewableEnergy: "industries/renewable-energy",
    renewableEnergyIntegration: "industries/renewable-energy/renewable-energy-integration",
    energyEfficiency: "industries/renewable-energy/energy-efficiency",
    sustainableSupplyChainManagement: "industries/renewable-energy/sustainable-supply-chain",
    climateRiskAndResiliencePlanning: "industries/renewable-energy/climate-resilience",

    //digital-transformation
    digitalTransformationPage: "digital-transformation",
    digitalServicesPage: "digital-services-page",
    digitalDriven: "digital-driven",
    digitalBusinessModel: "digital-business-model",
    blockchainTechnologyPage: "blockchain-technology-page",
    cloudInfrastructurePage: "cloud-infrastructure-page",
    intelligentOperations: "intelligent-operations",
    aIAndML: "ai-and-ml",
    workforceEnablement: "workforce-enablement",
    digitalMarketing: "digital-marketing",
    sustainableDigitalSolutionsPage: "sustainable-digital-solutions-page",
    gRCTransformationPage: "grc-transformation-page",
    agileEnterpriseDevelopmentPage: "agile-enterprise-development-page",
    enhancedCustomerEngagementPage: "enhanced-customer-engagement-page",
    processAutomation: "process-automation",
    digitalEducation: "education",
    digitalFinancialServices: "financial-services",
    publicSector: "public-sector",
    digitalHealthcare: "healthcare",
    digitalAgriculture: "agriculture",
    manufacturingEvolution: "manufacturing-evolution",


    // insights path
    insights: 'insights',
    ivaInsight: 'insights/iva-insight',
    crisisCommunication: 'insights/crisis-communication',
    circularEconomy: 'insights/circular-economy',
    blockchainGovernance: 'insights/blockchain-governance',
    smartCitySolutions: 'insights/smart-city-solutions',
    digitalFinancialInclusion: 'insights/digital-financial-inclusion',
    aRWorkforceDevelopmentInsight: 'insights/arworkforce-development',
    insightDrivingChange: 'insights/driving-change',

    // about us paths
    about: 'about',
    rfp: 'about/request-for-proposal',
    whatWeBelievePage: 'about/what-we-believe',
    whatWeDoPage: 'about/what-we-do',
    teamPage: 'about/the-team',
    jibrinHarunaProfile: 'about/jibrin-haruna',
    promiseIkpuriProfile: 'about/promise-ikpuri',
    olusojiAdeniyiProfile: 'about/dr-olusoji-adeniyi',

    //HealthcareConsultingPage path
    healthcareConsultingPage: 'healthcare-consulting',


    //MiningConsultingServices paths
    miningConsultingServicesPage: "mineral-exploration-and-mining-consulting-service",


    cookiePolicy: 'cookie-policy',
    privacyPolicy: 'privacy-policy',
    termsAndConditions: 'terms-of-service',

}

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const LifelongLearning = () => {
  return (
    <section className="bg-white pb-16 mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1733493165/lifelong_learning_rr7q30.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div
          className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
        >
          <h1 className="text-3xl font-bold text-gray-800">
            Non-Formal Education & Lifelong Learning
          </h1>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Non-Formal Education & Lifelong Learning
        </h1>
      </div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto px-6 mt-8 lg:mt-24 text-center lg:text-left">
        <p className="text-gray-700 mb-8">
          Seismic supports lifelong learning by helping individuals stay relevant in evolving work environments, offering services across formal and informal sectors.
        </p>

        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Key Services</h3>
        <ul className="space-y-6 text-gray-700">
          <li><p><strong>TVET Programs:</strong> Integrating competency-based training for both formal and informal sectors.</p></li>
          <li><p><strong>Skills Development Programs:</strong> Offering skill acquisition programs for immediate workforce needs and long-term development goals.</p></li>
          <li><p><strong>Digital Skilling & Blended Learning:</strong> Combining face-to-face instruction with digital platforms for accessible learning experiences.</p></li>
        </ul>

        <div className="mt-8">
          <Link to="/education-reform/non-formal-education/projects" className="text-purple-800 font-semibold hover:underline">
            View Non-Formal Education Projects Executed →
          </Link>
        </div>
      </div>
      {/* Related Area of Focus Section */}
      <div className="bg-gray-50 py-12 mt-16">
        <div className="max-w-7xl mx-auto px-6">
          <h3 className="text-3xl font-semibold text-gray-800 text-center">Related Area of Focus</h3>
          <p className="text-center text-gray-600 mb-8">Shaping Education for Global Impact</p>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      <Link to={`/${paths.consultingServices}/${paths.educationReform}/${paths.primaryEducation}`} className="bg-white shadow p-6 text-center">
              <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745785/track-record-1_emzjef.jpg" alt="Primary Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Primary Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                We work closely with governments, schools, and NGOs to enhance curricula, teacher quality, and educational resources, ensuring all students have the opportunity to excel in the classroom and beyond.
              </p>
            </Link>

      <Link to={`/${paths.consultingServices}/${paths.educationReform}/${paths.secondaryEducation}`} className="bg-white shadow p-6 text-center">
              <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745703/consult3_tdo25f.png" alt="Secondary Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Secondary Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                Our expertise in curriculum design, teacher development, and personalized learning ensures that students acquire both the academic and practical skills necessary for future employment and lifelong learning.
              </p>
            </Link>

      <Link to={`/${paths.consultingServices}/${paths.educationReform}/${paths.tvet}`} className="bg-white shadow p-6 text-center">
              <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745250/WhatsApp_Image_2025-02-05_at_09.45.49_vsk3mb.jpg" alt="Technical and Vocational Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Technical and Vocational Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                Developing skills through vocational training that align with labor market needs.
              </p>
            </Link>

      <Link to={`/${paths.consultingServices}/${paths.educationReform}/${paths.higherEducation}`} className="bg-white shadow p-6 text-center">
              <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1734689220/istockphoto-858465172-612x612_gwv5de.jpg" alt="Higher Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Higher Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                Seismic Consulting provides tailored solutions to ensure higher education institutions are equipped to meet the demands of the 21st-century workforce.
              </p>
            </Link>
          </div>
        </div>
      </div>

    </section>
  );
};

export default LifelongLearning;

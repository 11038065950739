import React from "react";

function BlockchainTechnologyPage() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
          Blockchain and Distributed Ledger Technology
          </h1>
        </div>
      </section>

      {/* Intro Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            At Seismic Consulting Group, we harness the transformative potential
            of blockchain and distributed ledger technologies to revolutionize
            business operations across various industries. Our expertise
            enables organizations to enhance transparency, security, and
            efficiency, positioning them at the forefront of digital
            innovation.
          </p>
        </div>
      </section>

      {/* Content Sections */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-12">
          {/* Supply Chain Transparency */}
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">
              Supply Chain Transparency
            </h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Seismic specializes in implementing blockchain solutions that
              provide end-to-end visibility in supply chain management. By
              integrating blockchain technology, we help clients track and
              verify each stage of their supply chain, ensuring data immutability
              and fostering trust among stakeholders.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="font-bold text-gray-800">
                  Case Study: Global Manufacturing
                </h3>
                <p className="text-gray-700">
                  For a global manufacturing client, Seismic developed a
                  blockchain-based supply chain system that enabled real-time
                  tracking of goods from origin to delivery. This solution
                  reduced discrepancies by 60% and enhanced compliance with
                  regulatory standards.
                </p>
              </div>
              <img
                src="https://via.placeholder.com/400x250"
                alt="Supply Chain Transparency"
                className="rounded shadow"
              />
            </div>
          </div>

          {/* Smart Contracts */}
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">
              Smart Contracts
            </h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Our team leverages blockchain to automate contract execution
              through self-executing smart contracts with the terms directly
              written into code. We design and deploy smart contract solutions
              that are secure, transparent, and tamper-proof.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="font-bold text-gray-800">Financial Sector</h3>
                <p className="text-gray-700">
                  We assist financial institutions in automating complex
                  transactions, settlement processes, and compliance checks,
                  enhancing speed and reducing operational costs.
                </p>
              </div>
              <div>
                <h3 className="font-bold text-gray-800">
                  Legal and Real Estate
                </h3>
                <p className="text-gray-700">
                  Seismic implements smart contracts to streamline property
                  transactions, lease agreements, and legal contracts, ensuring
                  accuracy and accelerating deal closures.
                </p>
              </div>
              <img
                src="https://via.placeholder.com/400x250"
                alt="Smart Contracts"
                className="rounded shadow"
              />
            </div>
          </div>

          {/* Digital Identity Solutions */}
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">
              Digital Identity Solutions
            </h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Seismic Consulting Group offers robust digital identity solutions
              utilizing blockchain technology to establish secure, verifiable
              identities for individuals and assets.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div>
                <h3 className="font-bold text-gray-800">
                  Identity Verification Systems
                </h3>
                <p className="text-gray-700">
                  We develop decentralized identity platforms that protect
                  personal information while enabling secure authentication
                  processes.
                </p>
              </div>
              <div>
                <h3 className="font-bold text-gray-800">Asset Digitization</h3>
                <p className="text-gray-700">
                  By tokenizing assets on the blockchain, we enable secure
                  tracking and ownership verification of physical and digital
                  assets.
                </p>
              </div>
              <div>
                <h3 className="font-bold text-gray-800">Fraud Reduction</h3>
                <p className="text-gray-700">
                  Our blockchain solutions enhance trust by ensuring data
                  integrity and transparency, significantly mitigating risks
                  associated with fraud.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

{/* Why Choose Seismic */}
<section className="py-12 bg-gray-100">
  <div className="max-w-6xl mx-auto px-4 text-center">
    <h2 className="text-2xl font-bold text-purple-900 mb-6">
      Why Choose Seismic for Blockchain Solutions
    </h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
      <div className="flex flex-col items-center text-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png"
          alt="Innovation"
          className="mb-4 w-16 h-16" // Adjusted icon size
        />
        <h3 className="font-bold text-gray-800">Expertise and Innovation</h3>
        <p className="text-gray-600">
          Our team stays abreast of the latest developments to provide
          cutting-edge blockchain solutions.
        </p>
      </div>
      <div className="flex flex-col items-center text-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738664804/7498017.png_xjmusv.png"
          alt="Strategy"
          className="mb-4 w-16 h-16" // Adjusted icon size
        />
        <h3 className="font-bold text-gray-800">Customized Strategies</h3>
        <p className="text-gray-600">
          We tailor blockchain implementations to meet business goals and
          regulatory requirements.
        </p>
      </div>
      <div className="flex flex-col items-center text-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738664839/7272713.png_bdaaqo.png"
          alt="End-to-End"
          className="mb-4 w-16 h-16" // Adjusted icon size
        />
        <h3 className="font-bold text-gray-800">End-to-End Services</h3>
        <p className="text-gray-600">
          From feasibility studies to development, Seismic offers
          comprehensive blockchain adoption services.
        </p>
      </div>
      <div className="flex flex-col items-center text-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546301/7275836.png_zeupcw.png"
          alt="Security"
          className="mb-4 w-16 h-16" // Adjusted icon size
        />
        <h3 className="font-bold text-gray-800">Security and Compliance</h3>
        <p className="text-gray-600">
          We design solutions that ensure regulatory compliance and data
          integrity.
        </p>
      </div>
    </div>
  </div>
</section>

    </div>
  );
}

export default BlockchainTechnologyPage;

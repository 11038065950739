import React from 'react';
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";
import AreaOfFocusSection from './AreaOfFocusSection';
import EducationProjectsExecuted from './EducationProjectsExecuted';
import PerformanceMetrics from './PerformanceMetrics';

const EducationReform = () => {
  return (
    <div className="w-full bg-white mt-28">
{/* Hero Section */}
<section
className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center"
  style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739187125/pexels-photo-355952_ffdr1h.jpg')" }}
>
  <div
    className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]"
  >
    <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
      Education Reform and Advisory
    </h1>
    <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
      Education is an admirable thing, but it is well to remember from time to time that nothing that is worth knowing can be taught
    </p>
  </div>
</section>
         {/* Title Under Image for Small Screens */}
         <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Education Reform and Advisory
        </h1>
      </div>

   {/* Overview Section */}
      <section className="py-16 px-6 lg:px-2 max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-2">Overview</h2>
        <p className="text-lg font-semibold text-gray-600 mb-6">
          Transforming Education to Drive Global Development
        </p>
        <p className="text-lg text-gray-600 mb-4 text-left">
          Education is the cornerstone of economic and social progress. It empowers individuals, fosters innovation, and builds the foundation for a skilled and adaptable workforce, capable of driving global development. As the world faces unprecedented challenges—from widening socio-economic inequalities to the disruptive impacts of technological advancements—education systems must evolve rapidly to remain relevant. High-quality education not only enhances personal and professional growth but also fosters the kind of inclusive, sustainable economic development that benefits entire communities and nations.
        </p>
        <p className="text-lg text-gray-600 mb-4 text-left">
          However, educational institutions across the globe face a complex web of challenges. These include budget constraints, outdated policies, uneven access to resources, and a widening gap between the skills taught in classrooms and those demanded by the modern workforce. The COVID-19 pandemic further exacerbated these issues, disrupting the learning of billions of students worldwide, and deepening existing inequalities. In this context, there is an urgent need for innovative approaches that reimagine education for the 21st century.
        </p>
      </section>

{/* Highlight Section */}
<section className="py-6 px-6 lg:px-2">
  
  {/* Short Top Border */}
  <div className="flex justify-center">
    <div className="w-3/4 border-t border-gray-300 mb-6"></div>
  </div>

  <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center gap-4">
    
    {/* Text Section on the Left */}
    <div className="lg:w-7/12 w-full text-center lg:text-left">
      <p className="text-gray-700 text-lg font-medium max-w-2xl mx-auto lg:mx-0 italic">
        Disruptions in education are creating opportunities for educational systems—from ECD through higher education—to evolve and innovate. Seismic’s education consulting experts work with clients to transform how people learn, and educational systems thrive.
      </p>
    </div>
    
    {/* Image Section on the Right */}
    <div className="lg:w-6/12 w-full flex justify-center lg:justify-end">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733510735/pexels-artempodrez-6941084_ngddxm.jpg" 
        alt="Education Transformation"
        className="shadow-lg w-full h-[180px] object-cover max-w-lg"
      />
    </div>
    
  </div>
  
  {/* Short Bottom Border */}
  <div className="flex justify-center mt-6">
    <div className="w-3/4 border-t border-gray-300"></div>
  </div>
  
</section>

{/* Performance Metrics Section */}
<PerformanceMetrics />

{/* Our Area of Focus Section */}
<AreaOfFocusSection />





{/* Our Track Record Section */}
<section className="py-16 px-6 lg:px-2">
  <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-8">
    
    {/* Track Record Text Section */}
    <div className="lg:w-6/12 w-full">
      <h2 className="text-3xl font-bold text-gray-800">Our Track Record</h2>
      <p className="text-lg text-gray-700 mt-4">
      Comprehensive Education Project Executed at Seismic Consulting, our executed Comprehensive Education Project demonstrates our commitment to delivering transformative outcomes across the entire educational ecosystem. We aligned education, employment, and welfare objectives to create a sustainable foundation for long-term success. Collaborating with key stakeholders, we established measurable goals, developed actionable strategies, and integrated cutting-edge technology to optimize educational processes. As a result, we enhanced institutional capacities, expanded access to quality resources, and empowered educators and learners alike—ensuring that educational systems remain resilient, equitable, and prepared to meet the evolving demands of the future.
      </p>
    </div>

    {/* Track Record Image */}
    <div className="lg:w-6/12 w-full">
  <img
    //src="https://res.cloudinary.com/dxhx45ing/image/upload/v1734687773/pexels-amos-getanda-244415932-12497061_r99ylt.jpg"
    src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738234646/WhatsApp_Image_2025-01-30_at_11.55.36_1_opalmg.jpg"
    alt="Our Track Record"
    className="w-full h-auto max-h-[400px] object-cover shadow-lg"
  />
</div>

    
  </div>
</section>

<EducationProjectsExecuted />

      {/* Digital Projects Introduction for Education */}
      <div className="max-w-7xl mx-auto px-6 lg:px-8 mt-8 mb-8">
  <div className="flex flex-col md:flex-row items-center gap-8">
    {/* Left Column: Image */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739801131/group-of-unrecognisable-international-students-having-online-meeting_cilkws.jpg"
        alt="Digital Education Projects"
        className="w-full h-[350px] rounded-lg shadow-md"
      />
    </div>
    {/* Right Column: Text Content */}
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        Empowering Education with Technology
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        Our suite of digital education projects is revolutionizing the way learning is delivered. From AI-powered curriculum builders to interactive digital classrooms, our innovative solutions empower educators and students alike to thrive in a modern, connected world.
      </p>
      <Link
        to={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalEducation}`}
        className="inline-flex items-center px-6 py-3 border border-purple-900 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
      >
        View Digital Projects
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  </div>
</div>

{/* Our Education Consulting Experts Section */}
<section className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
  <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
    Our Education Consulting Experts
  </h2>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
    {[
          {
            name: "Prof. Shuaibu Babawuro",
            role: "Senior Partner, Expert Technical and Vocational Education and Training",
            image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739522193/WhatsApp_Image_2025-02-14_at_09.35.12_ky5grs.jpg",
          },
     {
      name: "Tara McCourt",
      role: "Board Member, Expert Education Reform",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739521746/WhatsApp_Image_2025-02-14_at_09.28.55_ghid6p.jpg",
    },
    {
      name: "Dr. Olusoji Adeniyi",
      role: "Senior Partner, Expert Education & Capacity Building ",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739522280/WhatsApp_Image_2025-02-14_at_09.37.47_y6vhwu.jpg",
    },
    ].map((expert, index) => (
      <div key={index} className="shadow-lg rounded-lg overflow-hidden text-left bg-white">
        <img
          src={expert.image}
          alt={expert.name}
          className="w-full h-[300px] object-cover"
        />
        <div className="p-4">
          <h3 className="text-xl font-bold text-gray-900">{expert.name}</h3>
          <p className="text-gray-600 mt-1">{expert.role}</p>
        </div>
      </div>
    ))}
  </div>
</section>


    </div>
  );
};

export default EducationReform;

import React from "react";
import { useParams } from "react-router-dom";

const projects = [
  {
    "id": "capacity-building-mindiver",
    "title": "5-Day Capacity Building Workshop",
    "description": "World Bank – MINDIVER presents a 5-day capacity-building workshop on geochemical mapping of Proof of Concept areas in Nigeria. Organized by Seismic Consulting Group in collaboration with 3D Consulting-GEO GMBH. Location: Nassarawa (New Karu).",
    "details": `• Assignment Name: 5-Day Capacity Building Workshop on Geochemical Mapping of Proof of Concept Areas in Nigeria
    • Objectives:
      ➢ Provide comprehensive training on geochemical mapping techniques and their applications to Proof of Concept areas in Nigeria.
      ➢ Enhance the technical skills of participants in geochemical analysis, data interpretation, and field mapping.
      ➢ Foster collaboration among stakeholders in the geoscience and mineral exploration sectors.
      ➢ Strengthen the capacity of Nigerian professionals in the areas of environmental and resource management.
      ➢ Build awareness about the significance of geochemical mapping in sustainable development and resource utilization.
    • Name Of Client: WORLD BANK – MINDIVER
    • Role On the Assignment: Seismic Consulting Group / 3D Consulting-GEO GMBH
    • YEAR: 2023`,
    "clientImage": "https://res.cloudinary.com/dxhx45ing/image/upload/v1733494509/world_bank_v3jcqh.png",
    "clientName": "WORLD BANK – MINDIVER",
    "image": "https://res.cloudinary.com/dxhx45ing/image/upload/v1738233210/WhatsApp_Image_2025-01-29_at_17.25.46_aprpme.jpg"
  },  
  {
    id: "impact-evaluation-adamawa",
    title: "Impact Evaluation on Teachers",
    description: "Impact Evaluation on Teachers on Psycho-Social/Pedagogy Support and Salary Top-Up to Train Teachers on The Quality of Education Delivery in Adamawa State 2020",
    details: `• Assignment Description: Impact Evaluation on Teachers on Psycho-Social/Pedagogy Support and Salary Top-Up to Train Teachers on The Quality of Education Delivery in Adamawa State 2020
    • Name of Client: World Bank - State Education Program Investment, Additional Finance (SEPIP-AF)
    • Scope of the Assignment:
      ➢ Validate project outcomes by assessing successes achieved and challenges encountered during implementation.
      ➢ Analyze the impact of various project components on the lives of children within the affected focus states.
      ➢ Evaluate the relevance, effectiveness, efficiency, sustainability, and participatory nature of the project interventions.
      ➢ Identify and document lessons learned from the programs to inform further development and improvement of the project.
      ➢ Conduct an impact evaluation by analyzing the progress made within various project components against the stated objectives and intended outcomes.
    • Role on the assignment: Sole Consultant`,
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738075378/WhatsApp_Image_2025-01-28_at_09.48.10_uyxb50.jpg",
  },
  {
    id: "impact-study-bauchi",
    title: "Impact Study on Teachers",
    description: "Analyzing the effectiveness and sustainability of interventions to improve education quality and teacher support in Bauchi State.",
    details: `• Assignment Name: Impact Study on Teachers on Psycho-Social/Pedagogy Support and Salary Top-Up to Train Teachers on The Quality of Education Delivery in Bauchi State.
    • Objectives:
      ➢ Verify the impact the various project components made in the lives of the children within affected focus states?
      ➢ How relevant, effective, efficient, sustainable and participatory are the project interventions?
      ➢ What lessons can be drawn from the programs that can be taken to further develop the project?
      ➢ The impact evaluation should analyse the progress made within the various project components against the stated objectives and intended outcomes.
    • Name Of Client: World Bank - State Education Program Investment
    • Role On the Assignment: Sole Consultant
    • Year: 2020`,
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738070568/WhatsApp_Image_2025-01-28_at_09.48.12_1_i5hczp.jpg",
  },
  {
    id: "skills-training-yobe",
    title: "Skills Acquisition and Entrepreneurship Training",
    description: "Strengthening the employability and entrepreneurial capacity of unskilled women and youths in Yobe State.",
    details: `• Assignment Name: Training on skills acquisition and entrepreneurship for unskilled women & youths
    • Objectives:
      ➢ Provide training, knowledge, and skills on the market environment, including marketing principles for wealth creation, the market value chain, market demand, buyer mapping, negotiation skills, and how to build a connection to the private sector in and out of the state.
      ➢ Enhancing the employability of unskilled women & youths
      ➢ Strengthening the capacity of women and youths in Entrepreneurship and introduction to the central concept of social enterprise and its operations
      ➢ Cultivate a spirit of enterprise, self-dependence, and confidence of unskilled women and youths in affected communities of 17 LGAs of Yobe State
    • Name Of Client: Multi-Sectoral Crisis Recovery Project Yobe State
    • Role On the Assignment: Seismic Consulting Limited/Insightplus Consulting Limited
    • YEAR: 2023`,
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "MULTI-SECTORAL CRISIS RECOVERY PROJECT YOBE STATE",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738070537/WhatsApp_Image_2025-01-28_at_09.48.12_eylgob.jpg",
  },
];

const AccomplishedProjectDetail = () => {
  const { projectId } = useParams();
  const project = projects.find((p) => p.id === projectId);

  if (!project) {
    return (
      <div className="text-center text-xl text-gray-800 mt-12">
        Project not found.
      </div>
    );
  }

  return (
<div>

  {/* Project Details Section */}
  <div className="max-w-6xl mx-auto py-12 px-6 lg:px-12 mt-8">
    {/* Featured Image */}
    <img
      src={project.image}
      alt={project.title}
      className="w-full h-[450px] object-cover rounded-lg mb-8 shadow-lg"
    />

  {/* Top Header Section */}
  <section className="relative w-full bg-white text-gray-800 py-8">
    <div className="flex flex-col justify-center items-center text-center">
      <h1 className="text-4xl font-bold mt-12">{project.title}</h1>
    </div>
  </section>

    {/* Details */}
    <h2 className="text-2xl font-bold text-gray-800 mb-4">Project Details</h2>
    <p className="text-lg text-gray-600 whitespace-pre-line">
      {project.details}
    </p>

    {/* Client Info */}
    <div className="flex items-center gap-4 mt-6">
      <span className="text-gray-600 font-semibold">Client:</span>
      <img
        src={project.clientImage}
        alt={project.clientName}
        className="h-8"
      />
    </div>
  </div>
</div>

  );
};

export default AccomplishedProjectDetail;

import React from "react";

const RenewableEnergyIntegration = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737622978/pexels-photo-1108572_lwqihj.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Renewable Energy Integration
          </h1>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-5xl mx-auto px-6 lg:px-20">

        <p className="text-gray-700 text-xl mb-4">
          At Seismic Consulting Group, we specialize in helping energy providers seamlessly
          integrate renewable energy sources such as solar, wind, hydroelectric, and other
          emerging technologies into their existing infrastructure. This integration is essential
          for reducing reliance on fossil fuels and minimizing carbon footprints, all while
          ensuring the stability and efficiency of energy grids. Our approach goes beyond mere
          installation, addressing the complex technical, operational, and regulatory challenges
          that accompany this transition.
        </p>
      </div>

{/* Key Areas Section */}
<div className="py-16 max-w-6xl mx-auto px-6 lg:px-20">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Customized Integration Solutions
  </h2>
  <p className="text-gray-700 text-xl text-center mb-6">
    Each energy provider faces a unique set of circumstances, including geographic, operational, and market-specific factors. 
  </p>
  <p className="text-gray-700 text-lg leading-relaxed mb-6">
    We begin by conducting a thorough feasibility study that assesses the technical, economic, and environmental potential of renewable energy integration within the context of your existing energy infrastructure. By combining this data with an understanding of the local energy demand and grid dynamics, we craft tailored solutions that optimize energy generation, storage, and distribution capabilities.
  </p>

  {/* Leveraging Advanced Technologies */}
  <div className="mb-8">
    <h3 className="text-2xl font-semibold text-gray-900 mb-4">
      Leveraging Advanced Technologies
    </h3>
    <p className="text-gray-700 text-lg leading-relaxed">
      We utilize cutting-edge technologies such as smart grids, energy storage systems, and advanced forecasting tools to enhance the integration of renewable sources. These technologies allow us to address intermittency challenges (such as the variable nature of solar and wind energy) and maximize the efficiency of renewable energy generation. For example, energy storage systems like batteries and pumped hydro storage enable excess energy to be stored and dispatched during periods of high demand, ensuring grid stability even when renewable generation fluctuates.
    </p>
  </div>

  {/* Minimizing Disruption */}
  <div className="mb-8">
    <h3 className="text-2xl font-semibold text-gray-900 mb-4">
      Minimizing Disruption
    </h3>
    <p className="text-gray-700 text-lg leading-relaxed">
      Integrating renewable energy into an existing infrastructure can often be a complex process. At Seismic Consulting Group, we prioritize minimizing these disruptions. We ensure that the integration process is as non-invasive and efficient as possible by leveraging modular and scalable solutions that can be phased in over time. Our team works closely with your engineers and stakeholders to ensure that transitions are smooth, with minimal downtime and cost overruns.
    </p>
  </div>

  {/* Ensuring Grid Reliability */}
  <div className="mb-8">
    <h3 className="text-2xl font-semibold text-gray-900 mb-4">
      Ensuring Grid Reliability
    </h3>
    <p className="text-gray-700 text-lg leading-relaxed">
      Grid reliability is a cornerstone of energy security. While renewable energy sources offer a cleaner alternative, they can present challenges to grid stability due to their intermittent nature. Through advanced modeling and simulation tools, we assess potential impacts on grid reliability and implement grid management strategies that balance renewable input with traditional power generation. Additionally, we incorporate demand-side management techniques, enabling consumers to adjust energy consumption based on availability, further ensuring grid stability.
    </p>
  </div>

  {/* Cost-Effective Solutions for the Long Term */}
  <div className="mb-8">
    <h3 className="text-2xl font-semibold text-gray-900 mb-4">
      Cost-Effective Solutions for the Long Term
    </h3>
    <p className="text-gray-700 text-lg leading-relaxed">
      We understand that energy providers need to ensure cost-effectiveness when transitioning to renewable energy. By integrating renewable sources, companies not only reduce operational costs over time but also hedge against the volatility of fossil fuel prices. Our solutions are designed to optimize operational efficiency, reduce levelized cost of energy (LCOE), and help organizations achieve return on investment (ROI) while supporting long-term sustainability goals.
    </p>
  </div>

  {/* Compliance with Regulatory Standards */}
  <div>
    <h3 className="text-2xl font-semibold text-gray-900 mb-4">
      Compliance with Regulatory Standards
    </h3>
    <p className="text-gray-700 text-lg leading-relaxed">
      The regulatory landscape surrounding renewable energy is evolving rapidly. Seismic Consulting Group keeps you ahead of the curve by ensuring that all integration efforts comply with local, national, and international regulations. We work alongside policymakers to navigate incentives, subsidies, and compliance requirements, ensuring that your transition is not only effective but also maximizes available benefits.
    </p>
  </div>
</div>

      {/* Why Choose Seismic Consulting Group */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-4">Why Choose Seismic Consulting Group?</h2>
            <p className="text-gray-600 text-lg mb-4">
              Through our expert-led integration process, energy providers can unlock the full
              potential of renewable energy, reducing carbon emissions, bolstering grid
              resilience, and driving sustainable growth in an increasingly green economy.
            </p>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737623091/pexels-photo-207489_dmteha.jpg"
              alt="Why Choose Seismic Consulting Group"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenewableEnergyIntegration;

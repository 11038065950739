import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { paths } from "./constants/paths";
import "animate.css";
import Layout from "./Layout";
import ScrollToTop from "./ScrollToTop";
import LandingPage from "./pages/LandingPage";
import About from "./pages/AboutUs/About";
import Industries from "./pages/Industries";
import ConsultingServices from "./pages/ConsultingServices";
import Digital from "./pages/Digital";
import Insights from "./pages/Insights/Insights";
import Careers from "./pages/Careers";
import PFM from "./pages/PFM/PFM";
import EducationReform from "./pages/EducationReform/EducationReform";
import AllEducationProjects from "./pages/EducationReform/AllEducationProjects";
import EarlyChildhoodDevelopment from "./pages/EducationReform/EarlyChildhoodDevelopment";
import ProjectDetail from "./pages/EducationReform/Projectdetail";
import PrimaryEducation from "./pages/EducationReform/PrimaryEducation";
import SecondaryEducation from "./pages/EducationReform/SecondaryEducation";
import TVET from "./pages/EducationReform/TVET";
import HigherEducation from "./pages/EducationReform/HigherEducation";
import LifelongLearning from "./pages/EducationReform/LifelongLearning";
import FMIS from "./pages/PFM/FMIS";
import PublicFinancePolicyAssessment from "./pages/PFM/PublicFinancePolicyAssessment";
import PerformanceBasedBudgeting from "./pages/PFM/PerformanceBasedBudgeting";
import AuditAndFinancialControlSystems from "./pages/PFM/AuditAndFinancialControlSystems";
import CapacityBuildingAndTraining from "./pages/PFM/CapacityBuildingAndTraining";
import CapitalInvestmentPlanning from "./pages/PFM/CapitalInvestmentPlanning";
import DecentralizationFiscalManagement from "./pages/PFM/DecentralizationFiscalManagement";
import Contact from "./pages/Contact";
import RequestForProposal from "./pages/RequestForProposal";
import GovernanceReform from "./pages/GovernanceReform/GovernanceReform";
import ChangeManagement from "./pages/GovernanceReform/ChangeManagement";
import CapacityBuilding from "./pages/CapacityBuilding/CapacityBuilding";
import NeedsAssessment from "./pages/CapacityBuilding/NeedsAssessment";
import ProgramDesignAndCustomization from "./pages/CapacityBuilding/ProgramDesignAndCustomization";
import Implementation from "./pages/CapacityBuilding/Implementation";
import MonitoringAndEvaluation from "./pages/CapacityBuilding/MonitoringAndEvaluation";
import SustainabilityPlanning from "./pages/CapacityBuilding/SustainabilityPlanning";
import SocialProtectionPovertyReduction from "./pages/SocialProtectionPovertyReduction/SocialProtectionPovertyReduction";
import InnovativeFinancingMechanisms from "./pages/SocialProtectionPovertyReduction/InnovativeFinancingMechanisms";
import DataDrivenTargeting from "./pages/SocialProtectionPovertyReduction/DataDrivenTargeting";
import CommunityEmpowerment from "./pages/SocialProtectionPovertyReduction/CommunityEmpowerment";
import MonitoringEvaluation from "./pages/SocialProtectionPovertyReduction/MonitoringEvaluation";
import ComprehensiveNeedsAssessment from "./pages/SocialProtectionPovertyReduction/ComprehensiveNeedsAssessment";
import IntegratedSocialProtection from "./pages/SocialProtectionPovertyReduction/IntegratedSocialProtection";
import ChangeManagementPage from "./pages/ChangeManagement/ChangeManagementPage";
import AdaptiveStrategyDevelopment from "./pages/ChangeManagement/AdaptiveStrategyDevelopment";
import CulturalTransformation from "./pages/ChangeManagement/CulturalTransformation";
import DigitalTransformationCM from "./pages/ChangeManagement/DigitalTransformationCM";
import ChangeManagementFrameworks from "./pages/ChangeManagement/ChangeManagementFrameworks";
import CapacityBuildingForChangeLeaders from "./pages/ChangeManagement/CapacityBuildingForChangeLeaders";
import FinancialServices from "./pages/FinancialServices/FinancialServices";
import IndependentVerification from "./pages/IndependentVerification/IndependentVerification";
import AccomplishedProjectDetail from "./pages/CapacityBuilding/AccomplishedProjectDetail";
import AccomplishedProjects from "./pages/CapacityBuilding/AccomplishedProjects";
import AgricultureAgribusiness from "./pages/AgricultureAgribusiness/AgricultureAgribusiness";
import TechnologyTelecomPage from "./pages/TechnologyTelecomPage/TechnologyTelecomPage";
import HealthcarePage from "./pages/Healthcare/HealthcarePage";
import TelemedicineDigitalHealth from "./pages/Healthcare/TelemedicineDigitalHealth";
import PersonalizedMedicine from "./pages/Healthcare/PersonalizedMedicine";
import HealthcareSystemsTransformation from "./pages/Healthcare/HealthcareSystemsTransformation";
import RegulatoryCompliance from "./pages/Healthcare/RegulatoryCompliance";
import TransportationAndLogistics from "./pages/TransportationAndLogistics/TransportationAndLogistics";
import SmartLogistics from "./pages/TransportationAndLogistics/SmartLogistics";
import SustainableTransportation from "./pages/TransportationAndLogistics/SustainableTransportation";
import UrbanMobility from "./pages/TransportationAndLogistics/UrbanMobility";
import DigitalPlatforms from "./pages/TransportationAndLogistics/DigitalPlatforms";
import RenewableEnergy from "./pages/RenewableEnergy/RenewableEnergy";
import AuditAssurance from "./pages/AuditAssurance/AuditAssurance";
import RenewableEnergyIntegration from "./pages/RenewableEnergy/RenewableEnergyIntegration";
import EnergyEfficiency from "./pages/RenewableEnergy/EnergyEfficiency";
import SustainableSupplyChainManagement from "./pages/RenewableEnergy/SustainableSupplyChainManagement";
import ClimateRiskAndResiliencePlanning from "./pages/RenewableEnergy/ClimateRiskAndResiliencePlanning";
import IVAInsight from "./pages/Insights/IVAInsight";
import CrisisCommunication from "./pages/Insights/CrisisCommunication";
import CircularEconomy from "./pages/Insights/CircularEconomy";
import BlockchainGovernance from "./pages/Insights/BlockchainGovernance";
import SmartCitySolutions from "./pages/Insights/SmartCitySolutions";
import DigitalFinancialInclusion from "./pages/Insights/DigitalFinancialInclusion";
import WhatWeBelievePage from "./pages/AboutUs/WhatWeBelievePage";
import WhatWeDoPage from "./pages/AboutUs/WhatWeDoPage";
import JibrinHarunaProfile from "./pages/AboutUs/JibrinHarunaProfile";
import PromiseIkpuriProfile from "./pages/AboutUs/PromiseIkpuriProfile";
import DigitalTransformationPage from "./pages/DigitalTransformation/DigitalTransformationPage";
import DigitalServicesPage from "./pages/DigitalTransformation/DigitalServicesPage";
import DigitalDriven from "./pages/DigitalTransformation/DigitalDriven";
import DigitalBusinessModel from "./pages/DigitalTransformation/DigitalBusinessModel";
import BlockchainTechnologyPage from "./pages/DigitalTransformation/BlockchainTechnologyPage";
import CloudInfrastructurePage from "./pages/DigitalTransformation/CloudInfrastructurePage";
import IntelligentOperations from "./pages/DigitalTransformation/IntelligentOperations";
import AIAndML from "./pages/DigitalTransformation/AIAndML";
import WorkforceEnablement from "./pages/DigitalTransformation/WorkforceEnablement";
import DigitalMarketing from "./pages/DigitalTransformation/DigitalMarketing";
import SustainableDigitalSolutionsPage from "./pages/DigitalTransformation/SustainableDigitalSolutionsPage";
import GRCTransformationPage from "./pages/DigitalTransformation/GRCTransformationPage";
import AgileEnterpriseDevelopmentPage from "./pages/DigitalTransformation/AgileEnterpriseDevelopmentPage";
import EnhancedCustomerEngagementPage from "./pages/DigitalTransformation/EnhancedCustomerEngagementPage";
import ProcessAutomation from "./pages/DigitalTransformation/ProcessAutomation";
import OlusojiAdeniyiProfile from "./pages/AboutUs/OlusojiAdeniyiProfile";
import HealthcareConsultingPage from "./pages/HealthcareConsultingPage/HealthcareConsultingPage";
import MiningConsultingServicesPage from "./pages/MiningConsultingServices/MiningConsultingServicesPage";
import MiningIndustryPage from "./pages/MiningIndustry/MiningIndustryPage";
import TeamPage from "./pages/AboutUs/TeamPage";
import ARWorkforceDevelopmentInsight from "./pages/Insights/ARWorkforceDevelopmentInsight";
import InsightDrivingChange from "./pages/Insights/InsightDrivingChange";
import ManufacturingIndustry from "./pages/ManufacturingIndustry/ManufacturingIndustry";
import EducationIndustry from "./pages/EducationIndustry/EducationIndustry";
import DigitalEducation from "./pages/DigitalTransformation/DigitalEducation";
import DigitalFinancialServices from "./pages/DigitalTransformation/DigitalFinancialServices";
import PublicSector from "./pages/DigitalTransformation/PublicSector";
import DigitalHealthcare from "./pages/DigitalTransformation/DigitalHealthcare";
import DigitalAgriculture from "./pages/DigitalTransformation/DigitalAgriculture";
import ManufacturingEvolution from "./pages/DigitalTransformation/ManufacturingEvolution";
import CookiePolicy from "./pages/CookiePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Landing Page Route */}
        <Route
          path={`/${paths.homeIndex}`}
          element={
            <Layout>
              <LandingPage />
            </Layout>
          }
        />

        {/* About Page Route */}
        <Route
          path={`/${paths.about}`}
          element={
            <Layout>
              <About />
            </Layout>
          }
        />

        {/* Request for Proposal */}
        <Route
          path={`/${paths.rfp}`}
          element={
            <Layout>
              <RequestForProposal />
            </Layout>
          }
        />

        {/* Industries Page Route */}
        <Route
          path={`/${paths.industries}`}
          element={
            <Layout>
              <Industries />
            </Layout>
          }
        />

        {/* Consulting Services Route */}
        <Route
          path={`/${paths.consultingServices}`}
          element={
            <Layout>
              <ConsultingServices />
            </Layout>
          }
        />

        {/* Digital Route */}
        <Route
          path={`/${paths.digital}`}
          element={
            <Layout>
              <Digital />
            </Layout>
          }
        />

        {/* Insights Route */}
        <Route
          path={`/${paths.insights}`}
          element={
            <Layout>
              <Insights />
            </Layout>
          }
        />

        {/* Careers Route */}
        <Route
          path={`/${paths.careers}`}
          element={
            <Layout>
              <Careers />
            </Layout>
          }
        />

        {/* Contact Page Route */}
        <Route
          path={`/${paths.contact}`}
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />

        {/* PFM Services Routes */}
        <Route
          path={`/${paths.consultingServices}/${paths.pfm}`}
          element={
            <Layout>
              <PFM />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.pfm}/${paths.fmis}`}
          element={
            <Layout>
              <FMIS />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.pfm}/${paths.pfpa}`}
          element={
            <Layout>
              <PublicFinancePolicyAssessment />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.pfm}/${paths.pbb}`}
          element={
            <Layout>
              <PerformanceBasedBudgeting />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.pfm}/${paths.afcs}`}
          element={
            <Layout>
              <AuditAndFinancialControlSystems />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.pfm}/${paths.cbt}`}
          element={
            <Layout>
              <CapacityBuildingAndTraining />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.pfm}/${paths.cip}`}
          element={
            <Layout>
              <CapitalInvestmentPlanning />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.pfm}/${paths.dfm}`}
          element={
            <Layout>
              <DecentralizationFiscalManagement />
            </Layout>
          }
        />
        {/* Education Reform Routes */}
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}`}
          element={
            <Layout>
              <EducationReform />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}/${paths.allEducationProjects}`}
          element={
            <Layout>
              <AllEducationProjects />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}/${paths.allEducationProjects}/:projectId`}
          element={
            <Layout>
              <ProjectDetail />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}/${paths.earlyChildhoodDevelopment}`}
          element={
            <Layout>
              <EarlyChildhoodDevelopment />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}/${paths.primaryEducation}`}
          element={
            <Layout>
              <PrimaryEducation />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}/${paths.secondaryEducation}`}
          element={
            <Layout>
              <SecondaryEducation />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}/${paths.tvet}`}
          element={
            <Layout>
              <TVET />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}/${paths.higherEducation}`}
          element={
            <Layout>
              <HigherEducation />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.educationReform}/${paths.lifelongLearning}`}
          element={
            <Layout>
              <LifelongLearning />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.governanceReform}`}
          element={
            <Layout>
              <GovernanceReform />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.governanceReform}/${paths.changeManagement}`}
          element={
            <Layout>
              <ChangeManagement />
            </Layout>
          }
        />

        <Route
          path={`/${paths.consultingServices}/${paths.capacityBuilding}`}
          element={
            <Layout>
              <CapacityBuilding />
            </Layout>
          }
        />

        <Route
          path={`/${paths.consultingServices}/${paths.capacityBuilding}/${paths.needsAssessment}`}
          element={
            <Layout>
              <NeedsAssessment />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.capacityBuilding}/${paths.programDesignAndCustomization}`}
          element={
            <Layout>
              <ProgramDesignAndCustomization />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.capacityBuilding}/${paths.implementation}`}
          element={
            <Layout>
              <Implementation />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.capacityBuilding}/${paths.monitoringAndEvaluation}`}
          element={
            <Layout>
              <MonitoringAndEvaluation />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.capacityBuilding}/${paths.sustainabilityPlanning}`}
          element={
            <Layout>
              <SustainabilityPlanning />
            </Layout>
          }
        />

        <Route
          path="/consulting-services/accomplished-projects"
          element={<AccomplishedProjects />}
        />
        <Route
          path="/consulting-services/accomplished-projects/:projectId"
          element={<AccomplishedProjectDetail />}
        />
        <Route
          path={`/${paths.consultingServices}/${paths.socialProtectionPovertyReduction}`}
          element={
            <Layout>
              <SocialProtectionPovertyReduction />
            </Layout>
          }
        />

        <Route
          path={`/${paths.consultingServices}/${paths.socialProtectionPovertyReduction}/${paths.comprehensiveNeedsAssessment}`}
          element={
            <Layout>
              <ComprehensiveNeedsAssessment />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.socialProtectionPovertyReduction}/${paths.integratedSocialProtection}`}
          element={
            <Layout>
              <IntegratedSocialProtection />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.socialProtectionPovertyReduction}/${paths.innovativeFinancingMechanisms}`}
          element={
            <Layout>
              <InnovativeFinancingMechanisms />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.socialProtectionPovertyReduction}/${paths.dataDrivenTargeting}`}
          element={
            <Layout>
              <DataDrivenTargeting />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.socialProtectionPovertyReduction}/${paths.communityEmpowerment}`}
          element={
            <Layout>
              <CommunityEmpowerment />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.socialProtectionPovertyReduction}/${paths.monitoringEvaluation}`}
          element={
            <Layout>
              <MonitoringEvaluation />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.changeManagementPage}`}
          element={
            <Layout>
              <ChangeManagementPage />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.changeManagementPage}/${paths.adaptiveStrategyDevelopment}`}
          element={
            <Layout>
              <AdaptiveStrategyDevelopment />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.changeManagementPage}/${paths.culturalTransformation}`}
          element={
            <Layout>
              <CulturalTransformation />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.changeManagementPage}/${paths.digitalTransformationCM}`}
          element={
            <Layout>
              <DigitalTransformationCM />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.changeManagementPage}/${paths.changeManagementFrameworks}`}
          element={
            <Layout>
              <ChangeManagementFrameworks />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.changeManagementPage}/${paths.capacityBuildingForChangeLeaders}`}
          element={
            <Layout>
              <CapacityBuildingForChangeLeaders />
            </Layout>
          }
        />
        <Route
          path={`/${paths.consultingServices}/${paths.independentVerification}`}
          element={
            <Layout>
              <IndependentVerification />
            </Layout>
          }
        />
        <Route
          path={`/${paths.financialServices}`}
          element={
            <Layout>
              <FinancialServices />
            </Layout>
          }
        />
        <Route
          path={`/${paths.agriculture}`}
          element={
            <Layout>
              <AgricultureAgribusiness />
            </Layout>
          }
        />
        <Route
          path={`/${paths.technology}`}
          element={
            <Layout>
              <TechnologyTelecomPage />
            </Layout>
          }
        />
        <Route
          path={`/${paths.healthcare}`}
          element={
            <Layout>
              <HealthcarePage />
            </Layout>
          }
        />
        <Route
          path={`/${paths.manufacturingIndustry}`}
          element={
            <Layout>
              <ManufacturingIndustry />
            </Layout>
          }
        />
        <Route
          path={`/${paths.education}`}
          element={
            <Layout>
              <EducationIndustry />
            </Layout>
          }
        />
          <Route
          path={`/${paths.telemedicineDigitalHealth}`}
          element={
            <Layout>
          <TelemedicineDigitalHealth />
          </Layout>
        }
        />
          <Route
          path={`/${paths.personalizedMedicine}`}
          element={
            <Layout>
          <PersonalizedMedicine />
          </Layout>
        }
        />
          <Route
          path={`/${paths.healthcareSystemsTransformation}`}
          element={
            <Layout>
          <HealthcareSystemsTransformation />
          </Layout>
        }
        />
          <Route
          path={`/${paths.regulatoryCompliance}`}
          element={
            <Layout>
          <RegulatoryCompliance />
          </Layout>
        }
        />

          <Route
          path={`/${paths.transportation}`}
          element={
            <Layout>
              <TransportationAndLogistics />
            </Layout>
          }
        />
          <Route
          path={`/${paths.smartLogistics}`}
          element={
            <Layout>
              <SmartLogistics />
            </Layout>
          }
        />
          <Route
          path={`/${paths.sustainableTransportation}`}
          element={
            <Layout>
              <SustainableTransportation />
            </Layout>
          }
        />
          <Route
          path={`/${paths.urbanMobility}`}
          element={
            <Layout>
              <UrbanMobility />
            </Layout>
          }
        />
          <Route
          path={`/${paths.digitalPlatforms}`}
          element={
            <Layout>
              <DigitalPlatforms />
            </Layout>
          }
        />
          <Route
          path={`/${paths.audit}`}
          element={
            <Layout>
              <AuditAssurance />
            </Layout>
          }
        />
          <Route
          path={`/${paths.renewableEnergy}`}
          element={
            <Layout>
              <RenewableEnergy />
            </Layout>
          }
        />
          <Route
          path={`/${paths.renewableEnergyIntegration}`}
          element={
            <Layout>
              <RenewableEnergyIntegration />
            </Layout>
          }
        />
          <Route
          path={`/${paths.energyEfficiency}`}
          element={
            <Layout>
              <EnergyEfficiency />
            </Layout>
          }
        />

          <Route
          path={`/${paths.sustainableSupplyChainManagement}`}
          element={
            <Layout>
              <SustainableSupplyChainManagement />
            </Layout>
          }
        />
          <Route
          path={`/${paths.climateRiskAndResiliencePlanning}`}
          element={
            <Layout>
              <ClimateRiskAndResiliencePlanning />
            </Layout>
          }
        />
          <Route
          path={`/${paths.ivaInsight}`}
          element={
            <Layout>
              <IVAInsight />
            </Layout>
          }
        />
          <Route
          path={`/${paths.crisisCommunication}`}
          element={
            <Layout>
              <CrisisCommunication />
            </Layout>
          }
        />
          <Route
          path={`/${paths.circularEconomy}`}
          element={
            <Layout>
              <CircularEconomy />
            </Layout>
          }
        />
          <Route
          path={`/${paths.blockchainGovernance}`}
          element={
            <Layout>
              <BlockchainGovernance />
            </Layout>
          }
        />
          <Route
          path={`/${paths.smartCitySolutions}`}
          element={
            <Layout>
              <SmartCitySolutions />
            </Layout>
          }
        />
          <Route
          path={`/${paths.aRWorkforceDevelopmentInsight}`}
          element={
            <Layout>
              <ARWorkforceDevelopmentInsight />
            </Layout>
          }
        />
          <Route
          path={`/${paths.insightDrivingChange}`}
          element={
            <Layout>
              <InsightDrivingChange />
            </Layout>
          }
        />
          <Route
          path={`/${paths.digitalFinancialInclusion}`}
          element={
            <Layout>
              <DigitalFinancialInclusion />
            </Layout>
          }
        />
          <Route
          path={`/${paths.whatWeBelievePage}`}
          element={
            <Layout>
              <WhatWeBelievePage />
            </Layout>
          }
        />
          <Route
          path={`/${paths.whatWeDoPage}`}
          element={
            <Layout>
              <WhatWeDoPage />
            </Layout>
          }
        />
          <Route
          path={`/${paths.teamPage}`}
          element={
            <Layout>
              <TeamPage />
            </Layout>
          }
        />
          <Route
          path={`/${paths.jibrinHarunaProfile}`}
          element={
            <Layout>
              <JibrinHarunaProfile />
            </Layout>
          }
        />
          <Route
          path={`/${paths.promiseIkpuriProfile}`}
          element={
            <Layout>
              <PromiseIkpuriProfile />
            </Layout>
          }
        />
          <Route
          path={`/${paths.olusojiAdeniyiProfile}`}
          element={
            <Layout>
              <OlusojiAdeniyiProfile />
            </Layout>
          }
        />
<Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}`}
          element={
            <Layout>
              <DigitalTransformationPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}`}
          element={
            <Layout>
              <DigitalServicesPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.digitalDriven}`}
          element={
            <Layout>
              <DigitalDriven />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.digitalBusinessModel}`}
          element={
            <Layout>
              <DigitalBusinessModel />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.blockchainTechnologyPage}`}
          element={
            <Layout>
              <BlockchainTechnologyPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.cloudInfrastructurePage}`}
          element={
            <Layout>
              <CloudInfrastructurePage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.intelligentOperations}`}
          element={
            <Layout>
              <IntelligentOperations />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.aIAndML}`}
          element={
            <Layout>
              <AIAndML />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.workforceEnablement}`}
          element={
            <Layout>
              <WorkforceEnablement />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.digitalMarketing}`}
          element={
            <Layout>
              <DigitalMarketing />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.sustainableDigitalSolutionsPage}`}
          element={
            <Layout>
              <SustainableDigitalSolutionsPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.gRCTransformationPage}`}
          element={
            <Layout>
              <GRCTransformationPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.agileEnterpriseDevelopmentPage}`}
          element={
            <Layout>
              <AgileEnterpriseDevelopmentPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.enhancedCustomerEngagementPage}`}
          element={
            <Layout>
              <EnhancedCustomerEngagementPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.processAutomation}`}
          element={
            <Layout>
              <ProcessAutomation />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalEducation}`}
          element={
            <Layout>
              <DigitalEducation />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalFinancialServices}`}
          element={
            <Layout>
              <DigitalFinancialServices />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.publicSector}`}
          element={
            <Layout>
              <PublicSector />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalHealthcare}`}
          element={
            <Layout>
              <DigitalHealthcare />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalAgriculture}`}
          element={
            <Layout>
              <DigitalAgriculture />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.manufacturingEvolution}`}
          element={
            <Layout>
              <ManufacturingEvolution />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.healthcareConsultingPage}`}
          element={
            <Layout>
              <HealthcareConsultingPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.MiningConsultingServicesPage}`}
          element={
            <Layout>
              <MiningConsultingServicesPage />
            </Layout>
          }
        />
                <Route
          path={`/${paths.consultingServices}/${paths.miningConsultingServicesPage}`}
          element={
            <Layout>
              <MiningConsultingServicesPage />
            </Layout>
          }
        />

<Route
          path={`/${paths.miningIndustryPage}`}
          element={
            <Layout>
              <MiningIndustryPage />
            </Layout>
          }
        />
<Route
          path={`/${paths.cookiePolicy}`}
          element={
            <Layout>
              <CookiePolicy />
            </Layout>
          }
        />
<Route
          path={`/${paths.privacyPolicy}`}
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
<Route
          path={`/${paths.termsAndConditions}`}
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        />

        {/* Add additional routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;

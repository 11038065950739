import React, { useState } from "react";

const KeyAreasOfService = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleSection = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const sections = [
    {
      title: "Social Safety Nets",
      content: `
        Seismic designs and implements safety net programs that offer immediate support
        to vulnerable populations, such as cash transfers, food assistance, and healthcare
        subsidies. Our safety nets are tailored to address immediate needs while supporting
        long-term resilience.
      `,
    },
    {
      title: "Employment and Livelihood Programs",
      content: `
        We develop job creation and skills training programs that open up employment
        opportunities and equip individuals with marketable skills. These programs are
        designed to promote economic independence and foster sustainable growth in communities.
      `,
    },
    {
      title: "Child and Family Welfare Programs",
      content: `
        Recognizing that poverty impacts families and children uniquely, Seismic offers targeted
        welfare programs that address childhood nutrition, educational support, and healthcare access.
        These programs ensure that every family has the resources needed to break the cycle of poverty.
      `,
    },
    {
      title: "Healthcare Access and Insurance Initiatives",
      content: `
        Seismic promotes accessible healthcare through social health insurance models and community-based
        health programs. By improving healthcare affordability and access, we contribute to healthier,
        more resilient communities.
      `,
    },
    {
      title: "Housing and Basic Infrastructure Development",
      content: `
        We work on social housing projects and infrastructure development to improve living standards
        in impoverished communities. By providing access to quality housing, clean water, and sanitation,
        Seismic addresses foundational elements necessary for poverty reduction.
      `,
    },
    {
      title: "Inclusive Social Protection for Marginalized Groups",
      content: `
        Seismic is committed to designing inclusive social protection programs that specifically address
        the needs of marginalized populations, including women, the elderly, and people with disabilities.
        We develop strategies to ensure that these groups are represented and supported within the larger
        social protection framework.
      `,
    },
    {
      title: "Financial Literacy and Economic Empowerment",
      content: `
        Seismic supports economic resilience through financial literacy programs and micro-financing initiatives.
        By teaching communities essential financial skills and providing access to small loans, we enable individuals
        to build sustainable businesses and achieve financial stability.
      `,
    },
  ];

  return (
    <section className="flex flex-wrap lg:flex-nowrap">
      {/* Title Section with Purple Background */}
      <div className="relative w-full lg:w-1/2">
        <div className="absolute top-0 left-0 w-full bg-gradient-to-r from-[#2D002D] to-[#600060] text-white py-8 text-center z-10">
          <h2 className="text-3xl lg:text-4xl font-bold">
            Key Areas of Service in Social Protection and Poverty Reduction
          </h2>
        </div>
        {/* Image */}
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736425834/Image_zvn5a5.png"
          alt="Empowered Communities"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Collapsible Options */}
      <div className="w-full lg:w-1/2 bg-white text-black p-6 lg:p-12">
        <ul className="space-y-6">
          {sections.map((section, index) => (
            <li key={index} className="border-b border-gray-300 pb-4">
              <button
                className="w-full flex justify-between items-center py-4 text-left "
                onClick={() => toggleSection(index)}
              >
                <span className="text-lg font-bold">{section.title}</span>
                <span className="text-purple-900 text-3xl font-bold">
                  {expandedIndex === index ? "-" : "+"}
                </span>
              </button>
              {expandedIndex === index && (
                <p className="mt-4 text-sm">{section.content}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default KeyAreasOfService;

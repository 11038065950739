import React, { useState } from "react";

const approachOptions = [
  "Tailored Methodology",
  "Technology-Driven Insights",
  "Global Standards, Local Expertise",
];

const approachDetails = {
  "Tailored Methodology": {
    title: "Tailored Methodology",
    description:
      "Every organization is unique, and so are its challenges. We customize our audit processes to align with your industry, scale, and strategic priorities. Our team works closely with you to gain a deep understanding of your operations, enabling us to design audits that address your specific needs and provide actionable insights.",
  },
  "Technology-Driven Insights": {
    title: "Technology-Driven Insights",
    description:
      "In an era where data is king, we leverage advanced analytics, AI-powered tools, and automated processes to enhance the accuracy and efficiency of our audits. These technologies enable us to identify patterns, uncover risks, and provide forward-looking recommendations.",
  },
  "Global Standards, Local Expertise": {
    title: "Global Standards, Local Expertise",
    description:
      "Navigating complex regulatory environments requires a blend of global perspective and local know-how. Our experts bring a deep understanding of international audit standards while tailoring their approach to meet the specific regulatory requirements of your region. This ensures compliance while uncovering market-specific opportunities.",
  },
};

const AuditAssurance = () => {
  const [activeApproach, setActiveApproach] = useState(approachOptions[0]);
  // State for mobile view collapsibles (each index tracks open/closed state)
  const [mobileApproachOpen, setMobileApproachOpen] = useState({});

  const toggleMobileApproach = (index) => {
    setMobileApproachOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-12"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739186797/pexels-photo-3780104_qkebzb.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-20">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white">
            Audit and Assurance
          </h1>
          <p className="text-base lg:text-lg text-white mt-4">
            Helping different clients, sort through numbers, handle taxes, and more.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-12 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Audit and Assurance
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="mt-12 max-w-6xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Empowering Organizations with Reliable Insights
        </h2>
        <p className="text-gray-700 text-lg mb-4">
          Working with numbers is our specialty—even when, for some, it isn’t as easy as it might seem. At Seismic Consulting Group, it is exciting for us to help different clients sort through numbers, handle taxes, and more.
        </p>
        <p className="text-gray-700 text-lg">
          We understand that in today’s dynamic and increasingly regulated global marketplace, businesses face ever-growing expectations to maintain transparency, integrity, and accountability. Our Audit and Assurance services empower organizations with insights that go beyond compliance, helping them build stakeholder confidence, enhance operational efficiency, and make informed strategic decisions.
        </p>
      </div>

      {/* Our Expertise Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-4">Our Expertise</h2>
            <p className="text-gray-600 text-lg mb-4">
              With over 15 years of experience in empowering organizations across diverse sectors, Seismic Consulting Group brings unparalleled expertise to every engagement. Our team of seasoned professionals combines deep industry knowledge with cutting-edge audit technologies to deliver insights that drive meaningful outcomes. From supporting multinational corporations in their global operations to enhancing public sector transparency and accountability, we’ve earned a reputation for delivering excellence and trust.
            </p>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737624157/pexels-photo-6929004_tkdoqy.jpg"
              alt="Why Choose Seismic Consulting Group"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
        </div>
      </div>

      {/* Our Approach Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-16">
        {/* Title and Subheading */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Approach</h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            At Seismic Consulting Group, we don’t view audit and assurance as mere obligations—we see them as opportunities to strengthen trust and uncover value.
          </p>
        </div>

        {/* Desktop View */}
        <div className="hidden lg:flex">
          {/* Left Navigation */}
          <div className="lg:w-1/3 w-full lg:pr-8">
            <div className="bg-[#F9F5FB] p-8 space-y-4">
              {approachOptions.map((option, index) => (
                <div key={option}>
                  <button
                    onClick={() => setActiveApproach(option)}
                    className={`text-left py-6 px-4 font-semibold text-xl w-full ${
                      activeApproach === option
                        ? "bg-gradient-to-b from-[#2D002D] to-[#600060] text-white"
                        : "text-gray-800 hover:bg-purple-100"
                    }`}
                  >
                    {option}
                  </button>
                  {index < approachOptions.length - 1 && (
                    <div className="border-t border-purple-200 w-full"></div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Right Content Display */}
          <div className="lg:w-2/3 w-full mt-12">
            <div className="p-8 bg-white shadow-md rounded-md">
              <h2 className="text-3xl font-bold mb-4">
                {approachDetails[activeApproach].title}
              </h2>
              <p className="text-gray-700 text-lg leading-relaxed">
                {approachDetails[activeApproach].description}
              </p>
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className="block lg:hidden max-w-md mx-auto px-4 mt-8">
          {approachOptions.map((option, index) => (
            <div key={option} className="mb-4">
              <button
                onClick={() => toggleMobileApproach(index)}
                className="w-full flex justify-between items-center py-4 px-4 bg-[#F9F5FB] border-b border-purple-200 text-xl font-semibold text-purple-900"
              >
                <span>{option}</span>
                <span className="text-3xl">
                  {mobileApproachOpen[index] ? "-" : "+"}
                </span>
              </button>
              {mobileApproachOpen[index] && (
                <div className="p-4 bg-white shadow-md rounded-b">
                  <h2 className="text-2xl font-bold mb-2">
                    {approachDetails[option].title}
                  </h2>
                  <p className="text-gray-700 text-base">
                    {approachDetails[option].description}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Key Areas of Expertise Section */}
      <section className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-12">
          <h2 className="text-3xl font-bold text-center mb-6">
            Key Areas of Expertise
          </h2>
          <p className="text-center text-gray-600 mb-12 leading-relaxed max-w-4xl mx-auto">
            Our comprehensive Audit and Assurance services include:
          </p>

          {/* First Row: 3 Boxes */}
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Financial Statement Audits",
                description:
                  "We ensure the accuracy and reliability of your financial reports, enhancing transparency and building confidence among investors and stakeholders. By identifying potential discrepancies, we help you uphold the integrity of your financial operations.",
              },
              {
                title: "Internal Audits",
                description:
                  "Our internal audit services focus on evaluating and improving your governance, risk management, and internal control processes. We provide actionable recommendations to optimize your operations and mitigate potential risks.",
              },
              {
                title: "Risk-Based Auditing",
                description:
                  "By focusing on areas of highest risk, our risk-based auditing approach ensures your resources are effectively allocated. We identify and address risks that could impact your financial performance and operational integrity, for long-term success.",
              },
            ].map((item, index) => (
              <div key={index} className="border border-gray-200 p-6 text-center">
                <h3 className="text-lg font-semibold mb-4">{item.title}</h3>
                <p className="text-gray-600 text-sm leading-relaxed">
                  {item.description}
                </p>
              </div>
            ))}
          </div>

          {/* Second Row: 2 Boxes Centered */}
          <div className="mt-12 flex flex-col md:flex-row justify-center items-center md:space-x-8 space-y-8 md:space-y-0">
            {[
              {
                title: "Sustainability and ESG Assurance",
                description:
                  "As the importance of environmental, social, and governance (ESG) factors grows, we provide assurance over your sustainability reporting. This helps you meet stakeholder expectations and demonstrate your commitment to responsible business practices.",
              },
              {
                title: "Regulatory Compliance Audits",
                description:
                  "We assist organizations in navigating the complexities of legal and regulatory frameworks. Our audits ensure adherence to industry-specific requirements, minimizing the risk of penalties and safeguarding your reputation.",
              },
            ].map((item, index) => (
              <div key={index} className="border border-gray-200 p-6 text-center flex-none w-96">
                <h3 className="text-lg font-semibold mb-4">{item.title}</h3>
                <p className="text-gray-600 text-sm leading-relaxed">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Impactful Results Section */}
      <section className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-12">
          <h2 className="text-3xl font-bold text-center mb-6">
            Impactful Results
          </h2>
          <p className="text-center text-gray-600 mb-12 leading-relaxed max-w-4xl mx-auto">
            Seismic Consulting Group is proud to have delivered tangible, high-impact results for our clients, including:
          </p>

          {/* First Row: 3 Columns */}
          <div className="grid md:grid-cols-3 gap-12">
            {[
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png",
                title: "Strengthened Financial Resilience",
                description:
                  "Through meticulous audits, we’ve helped clients uncover financial irregularities, recover lost revenues, and improve resource allocation, resulting in strengthened bottom lines.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736765626/person_7498074_e8j9wb.png",
                title: "Enhanced Governance Frameworks",
                description:
                  "By identifying weaknesses in internal controls and governance structures, our clients have successfully implemented improvements that reduce risk and enhance organizational integrity.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737554837/7993074.png_d9i4tw.png",
                title: "Regulatory Excellence",
                description:
                  "Our compliance audits have enabled businesses to avoid costly penalties and build strong reputations for adhering to stringent regulatory standards.",
              },
            ].map((item, index) => (
              <div key={index} className="flex flex-col items-start space-y-3">
                <img src={item.icon} alt={item.title} className="w-12 h-12" />
                <div>
                  <h3 className="text-lg font-semibold">{item.title}</h3>
                  <p className="text-gray-600 text-sm leading-relaxed">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Second Row: 3 Columns */}
          <div className="grid md:grid-cols-3 gap-12 mt-12">
            {[
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png",
                title: "Sustainability Leadership",
                description:
                  "Clients leveraging our ESG assurance services have gained recognition for their commitment to sustainability, attracting investors and improving stakeholder trust.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png",
                title: "Operational Efficiencies",
                description:
                  "Our insights into process inefficiencies have driven significant cost savings and productivity gains across diverse industries.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png",
                title: "Informed Strategic Decisions",
                description:
                  "Through risk-based auditing and data-driven insights, we’ve empowered clients to make confident, future-focused decisions that drive growth and innovation.",
              },
            ].map((item, index) => (
              <div key={index} className="flex flex-col items-start space-y-3">
                <img src={item.icon} alt={item.title} className="w-12 h-12" />
                <div>
                  <h3 className="text-lg font-semibold">{item.title}</h3>
                  <p className="text-gray-600 text-sm leading-relaxed">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AuditAssurance;

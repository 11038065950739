import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";

const EducationIndustry = () => {
  return (
    <div className="w-full bg-white mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739540494/hall-congress-architecture-building-159213_aslif6.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-20">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white">
            Education Consulting & Transformation
          </h1>
          <p className="text-base lg:text-lg text-white mt-4">
            Shaping the future of education for a more inclusive and innovative world.
          </p>
        </div>
      </section>

      {/* Introduction */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
          Empowering Education for Global Impact
        </h2>
        <p className="text-lg text-gray-700 mb-4">
          Education is the cornerstone of economic and social progress. It empowers individuals, fosters innovation, and builds the foundation for a skilled and adaptable workforce capable of driving global development.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          However, educational institutions worldwide face a complex web of challenges—from budget constraints and outdated policies to uneven access to resources and a widening gap between classroom skills and modern workforce demands. The COVID-19 pandemic further disrupted learning and deepened existing inequalities.
        </p>
        <p className="text-lg text-gray-700">
          In this dynamic environment, innovative approaches are crucial. Our education consulting experts work with clients to transform learning environments, ensuring that education systems remain resilient, equitable, and prepared for the challenges of the 21st century.
        </p>
      </div>

      {/* Digital Projects Introduction for Education */}
<div className="max-w-6xl mx-auto px-6 lg:px-12 mt-20 mb-8">
  <div className="flex flex-col md:flex-row items-center gap-8">
    {/* Left Column: Image */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739801131/group-of-unrecognisable-international-students-having-online-meeting_cilkws.jpg"
        alt="Digital Education Projects"
        className="w-full rounded-lg shadow-md"
      />
    </div>
    {/* Right Column: Text Content */}
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        Empowering Education with Technology
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        Our suite of digital education projects is revolutionizing the way learning is delivered. From AI-powered curriculum builders to interactive digital classrooms, our innovative solutions empower educators and students alike to thrive in a modern, connected world.
      </p>
      <Link
        to={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalEducation}`}
        className="inline-flex items-center px-6 py-3 border border-purple-900 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
      >
        View Digital Projects
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  </div>
</div>


      {/* Performance Metrics */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
            Performance Metrics
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Metric 1 */}
            <div className="p-6 border border-gray-200 shadow-md rounded-lg text-center">
              <h3 className="text-4xl font-bold text-purple-900 mb-2">50+</h3>
              <p className="text-lg text-gray-600">Education Projects Completed</p>
              <p className="text-gray-600 mt-2">
                Implemented large-scale education initiatives with 200+ activities, transforming the education sector.
              </p>
            </div>
            {/* Metric 2 */}
            <div className="p-6 border border-gray-200 shadow-md rounded-lg text-center">
              <h3 className="text-4xl font-bold text-purple-900 mb-2">10+</h3>
              <p className="text-lg text-gray-600">International Collaboration</p>
              <p className="text-gray-600 mt-2">
                Collaborating with international organizations to strengthen education globally.
              </p>
            </div>
            {/* Metric 3 */}
            <div className="p-6 border border-gray-200 shadow-md rounded-lg text-center">
              <h3 className="text-4xl font-bold text-purple-900 mb-2">50+</h3>
              <p className="text-lg text-gray-600">Centers of Excellence</p>
              <p className="text-gray-600 mt-2">
                Transformation of technical colleges and skill acquisition centers into TVET centers of excellence.
              </p>
            </div>
            {/* Metric 4 */}
            <div className="p-6 border border-gray-200 shadow-md rounded-lg text-center">
              <h3 className="text-4xl font-bold text-purple-900 mb-2">5,000+</h3>
              <p className="text-lg text-gray-600">Students Reached</p>
              <p className="text-gray-600 mt-2">
                Positively impacted students by providing access to improved learning environments and opportunities.
              </p>
            </div>
            {/* Metric 5 */}
            <div className="p-6 border border-gray-200 shadow-md rounded-lg text-center">
              <h3 className="text-4xl font-bold text-purple-900 mb-2">20%</h3>
              <p className="text-lg text-gray-600">Increase in Digital Literacy</p>
              <p className="text-gray-600 mt-2">
                Boosted digital literacy among students in underserved areas through technology integration.
              </p>
            </div>
            {/* Metric 6 */}
            <div className="p-6 border border-gray-200 shadow-md rounded-lg text-center">
              <h3 className="text-4xl font-bold text-purple-900 mb-2">100+</h3>
              <p className="text-lg text-gray-600">Schools Upgraded</p>
              <p className="text-gray-600 mt-2">
                Supported the implementation of modern technology and digital learning tools to enhance education access.
              </p>
            </div>
          </div>
        </div>
      </div>
{/* Our Area of Focus */}
<div className="py-16 px-4 lg:px-20 bg-gradient-to-r from-purple-50 to-blue-50">
  <div className="max-w-6xl mx-auto">
    <h2 className="text-4xl font-extrabold text-center mb-10 text-purple-900 tracking-wide">
      Our Area of Focus
    </h2>
    <p className="text-xl text-gray-800 mb-6 leading-relaxed">
      At the <span className="font-bold text-purple-700">heart of educational transformation</span> lies our relentless drive to reinvent learning environments. We spearhead groundbreaking initiatives—from executing comprehensive{" "}
      <span className="font-bold text-purple-700">Annual School Censuses</span> that reveal critical insights into infrastructure and resource allocation, to launching dynamic, agile projects designed to reintegrate <span className="font-bold text-purple-700">girls out of school</span> into thriving learning communities. Our targeted <span className="font-bold text-purple-700">TVET programs</span> empower underserved populations with practical, job-ready skills, forging vibrant pathways for both personal and professional growth.
    </p>
    <p className="text-xl text-gray-800 leading-relaxed">
      Beyond strategic programming, we are passionately committed to the revitalization of educational spaces. By renovating outdated schools and infusing them with cutting-edge digital technologies, we create modern, inspiring environments that spark creativity, resilience, and academic excellence. Our holistic approach ensures that every institution—be it early childhood centers or technical colleges—is transformed into a beacon of progress, ready to meet the demands of the 21st century and drive sustainable development.
    </p>
  </div>
</div>

           {/*  Our Track Record */}
           <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-medium mb-4">
            Our Track Record
            </h2>
            <p className="text-lg text-gray-700 mb-4">
            Our Comprehensive Education Project executed at Seismic Consulting demonstrates our commitment to delivering transformative outcomes across the entire educational ecosystem. We aligned education, employment, and welfare objectives to create a sustainable foundation for long-term success.
          </p>
          <p className="text-lg text-gray-700">
            Collaborating with key stakeholders, we established measurable goals, developed actionable strategies, and integrated cutting-edge technology to optimize educational processes. As a result, we enhanced institutional capacities, expanded access to quality resources, and empowered educators and learners alike—ensuring that educational systems remain resilient, equitable, and prepared to meet the evolving demands of the future.
          </p>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739541182/pexels-photo-256455_rvsull.jpg"
              alt="Impactful Results"
              className="w-full h-[450px] rounded-lg object-cover shadow-lg"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default EducationIndustry;

import React from "react";

const TransportationAndLogistics = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1737554496/pexels-tomfisk-3057960_kngjou.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-3xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Transportation and Logistics
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Revolutionizing the way goods and people move, crafting innovative solutions that transform operational efficiency, cut costs, and champion environmental sustainability.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Transportation and Logistics
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Revolutionizing the Future of Movement and Delivery
        </h2>
        <p className="text-gray-700 text-lg mb-4 italic">
          “Every revolution begins as a spark—a single thought in one mind, destined to ignite a movement. When that thought resonates across minds, it unlocks the future of an era.”
        </p>
        <p className="text-gray-700 text-lg mb-4">
        In transportation and logistics, we are that spark. We are revolutionizing the way goods and people move, crafting innovative solutions that transform operational efficiency, cut costs, and champion environmental sustainability. This is not just evolution; it’s a reimagining of the sector’s potential. 
        </p>
        <p className="text-gray-700 text-lg mb-4">
        Seismic comprehensive approach encompasses the entire logistics value chain, equipping businesses to overcome the challenges of a rapidly evolving industry while capitalizing on opportunities for growth, innovation, and resilience.
        </p>
        <p className="text-gray-700 text-lg mb-4">
        From optimizing supply chain operations and implementing sustainable transportation models to designing forward-thinking urban mobility systems, we deliver tailored strategies that address the demands of today’s dynamic global market.
        </p>
      </div>

{/* Key Areas of Expertise Section */}
<div className="py-16 px-4 lg:px-20">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Key Areas of Expertise
    </h2>
    <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
      Seismic Consulting’s expertise in transportation and logistics is reshaping the industry with innovative solutions that drive efficiency, sustainability, and growth.
    </p>

    {/* Row 1 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 1 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737550596/pexels-photo-4484155_kx7ln0.jpg"
          alt="Smart Logistics and Supply Chain Management"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2 mt-4">
              Smart Logistics and Supply Chain Management
            </h3>
            <p className="text-gray-600 text-lg leading-relaxed mt-4">
              Empowering businesses to modernize their supply chain operations with advanced, data-driven technologies.
            </p>
          </div>
          <a
            href="/industries/transportation/smart-logistics"
            className="text-purple-900 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>

      {/* Box 2 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2 mt-4">
            Sustainable Transportation
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed mt-4">
            Reducing carbon footprints and optimizing operations to align with the demands of a greener, more responsible future.
          </p>
        </div>
        <a
          href="/industries/transportation/sustainable-transportation"
          className="text-purple-900 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>

    {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2 mt-4">
            Urban Mobility Solutions
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed mt-4">
            Addressing urban population growth and the need for seamless connectivity with efficient and sustainable mobility systems.
          </p>
        </div>
        <a
          href="/industries/transportation/urban-mobility"
          className="text-purple-900 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737549897/pexels-photo-6169870_gw6aai.jpg"
          alt="Digital Platforms for Logistics"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2 mt-4">
              Digital Platforms for Logistics
            </h3>
            <p className="text-gray-600 text-lg leading-relaxed">
              Enabling real-time tracking and route optimization for greater efficiency, transparency, and agility in logistics operations.
            </p>
          </div>
          <a
            href="/industries/transportation/digital-platforms"
            className="text-purple-900 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


{/* Benefits Section */}
<div className="py-16 max-w-8xl mx-auto px-6 lg:px-12">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Creating a Connected Logistics Experience
  </h2>
  <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
  Our work in transportation and logistics drives measurable results that extend far beyond operational improvements. By partnering with Seismic Consulting Group, organizations achieve:
  </p>
  <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 text-center">
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546776/7497716.png_lmqppk.png"
          alt="Operational Excellence"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Operational Excellence</h3>
      <p className="text-gray-700 mt-2">
        Streamlined processes, reduced inefficiencies, and enhanced reliability in logistics operations.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554837/7993074.png_d9i4tw.png"
          alt="Environmental Leadership"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Environmental Leadership</h3>
      <p className="text-gray-700 mt-2">
        Reduced carbon footprints and alignment with global sustainability goals, positioning businesses as leaders in green innovation.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554890/7448955.png_gksmrt.png"
          alt="Cost Savings"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Cost Savings</h3>
      <p className="text-gray-700 mt-2">
        Lower transportation, storage, and delivery costs through optimized systems and sustainable practices.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png"
          alt="Resilience in a Dynamic Market"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Resilience in a Dynamic Market</h3>
      <p className="text-gray-700 mt-2">
        The agility to adapt to shifting consumer demands, regulatory changes, and technological advancements.
      </p>
    </div>
  </div>
</div>


      {/* Why Seismic Section */}
      <section className="bg-white py-16">
  <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
    {/* Text Section */}
    <div className="w-full md:w-1/2">
      <h2 className="text-2xl font-medium mb-4">Why Seismic Consulting Group?</h2>
      <p className="text-gray-600 text-xl leading-relaxed mb-4">
      Our holistic approach to transportation and logistics combines technical expertise, strategic insight, and a commitment to sustainability. With a proven track record of delivering transformative solutions, we empower our clients to navigate industry challenges with confidence and success
      </p>
      <p className="text-gray-600 text-xl leading-relaxed">
      By reimagining supply chains, embracing digital innovation, and championing sustainable practices, Seismic Consulting Group is leading the way toward a smarter, greener, and more resilient future in transportation and logistics.
      </p>
    </div>

    {/* Image Section */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737555064/free-photo-of-bus-on-street-in-boston-in-the-usa_yjplra.jpg"
        alt="Why Choose Seismic Consulting"
        className="h-[500px] w-[500px]"
      />
    </div>
  </div>
</section>
    </div>
  );
};

export default TransportationAndLogistics;

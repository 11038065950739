import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";

const projects = [
  {
    id: "capacity-building-mindiver",
    title: "5-Day Capacity Building Workshop",
    description:
      "World Bank – MINDIVER presents a 5-day capacity-building workshop on geochemical mapping of Proof of Concent areas in Nigeria. Organized by Seismic Consulting Group in collaboration with 3D Consulting-GEO GMBH. Location: Nassarawa (New Karu).",
    projectTitle:
      "5-Day Capacity Building Workshop Programme on Geochemical Mapping of Proof of Concent Areas in Nigeria",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733494509/world_bank_v3jcqh.png",
    clientName: "WORLD BANK – MINDIVER",
    image:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1738233210/WhatsApp_Image_2025-01-29_at_17.25.46_aprpme.jpg",
  },
  {
    id: "impact-evaluation-adamawa",
    title: "Impact Evaluation on Teachers",
    description:
      "With expertise in data-driven strategy, innovative financing, and community empowerment, our work with government and international partners tackles immediate needs while fostering resilience for the future.",
    projectTitle:
      "Impact Evaluation on Teachers on Psycho-Social/Pedagogy Support and Salary Top-Up to Train Teachers on The Quality of Education Delivery in Adamawa State 2020",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733494509/world_bank_v3jcqh.png",
    clientName: "THE WORLD BANK",
    image:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745793/insights4_rnvvxi.png",
  },
  {
    id: "impact-study-bauchi",
    title: "Impact Study on Teachers",
    description:
      "Analyzing the effectiveness and sustainability of interventions to improve education quality and teacher support in Bauchi State.",
    projectTitle:
      "Impact Study on Teachers on Psycho-Social/Pedagogy Support and Salary Top-Up to Train Teachers on The Quality of Education Delivery in Bauchi State.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733494509/world_bank_v3jcqh.png",
    clientName: "THE WORLD BANK",
    image:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1738075039/WhatsApp_Image_2025-01-28_at_13.47.15_wuar8k.jpg",
  },
  {
    id: "skills-training-yobe",
    title: "Skills Acquisition and Entrepreneurship Training",
    description:
      "Strengthening the employability and entrepreneurial capacity of unskilled women and youths in Yobe State.",
    projectTitle:
      "Training on skills acquisition and entrepreneurship for unskilled women & youths in 17 LGAs of Yobe State.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "MULTI-SECTORAL CRISIS RECOVERY PROJECT YOBE STATE",
    image:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1738075196/WhatsApp_Image_2025-01-28_at_13.00.33_ebjbm9.jpg",
  },
];

const AccomplishedProjects = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  // Auto-cycle through slides
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 5000); // Adjust duration for slide change

    return () => clearInterval(interval);
  }, []);

  const currentProject = projects[currentProjectIndex];

  return (
    <section className="py-16 px-4 lg:px-8">
      {/* Section Title */}
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-800">Accomplished Projects</h2>
      </div>

      {/* Project Display */}
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-start gap-8">
        {/* Image */}
        <div className="lg:w-6/12 w-full transition-opacity duration-500">
          <img
            src={currentProject.image}
            alt="Project Image"
            className="w-full h-full object-cover shadow-lg"
            style={{ height: "450px" }}
          />
        </div>

        {/* Project Details */}
        <div className="lg:w-6/12 w-full flex flex-col">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">
            {currentProject.title}
          </h3>
          <p className="text-lg text-gray-600 mb-6">
            {currentProject.description}
          </p>

          {/* Project Info */}
          <div className="text-left mb-2">
            <span className="text-gray-600 font-medium text-lg">Project</span>
          </div>
          <div
            className="border border-[#F2E6F2] p-6"
            style={{ borderTopWidth: "8px", height: "200px" }}
          >
            <p className="text-lg font-semibold text-gray-800 mb-2">
              {currentProject.projectTitle}
            </p>
            <div className="flex items-center mb-4">
              <span className="text-gray-600 font-semibold mr-2">Client:</span>
              <img
                src={currentProject.clientImage}
                alt={currentProject.clientName}
                className="h-8"
              />
            </div>
            <Link
  to={`/${paths.consultingServices}/${paths.accomplishedProjects}/${currentProject.id}`}
  className="text-purple-900 font-semibold inline-flex items-center"
>
  View Achievement
  <svg
    className="w-5 h-5 ml-2"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    />
  </svg>
</Link>

          </div>
        </div>
      </div>

      {/* Slide Navigation Dots */}
      <div className="flex justify-center items-center gap-3 mt-6">
        {projects.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentProjectIndex(index)}
            className={`transition-all duration-300 ${
              index === currentProjectIndex
                ? "bg-purple-800 w-8 h-3 rounded-lg"
                : "bg-purple-300 w-4 h-4 rounded-full"
            }`}
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>
    </section>
  );
};

export default AccomplishedProjects;

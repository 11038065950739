import React from "react";

function SustainableDigitalSolutionsPage() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Sustainable Digital Solutions
          </h1>
        </div>
      </section>

      {/* Intro Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            At Seismic Consulting Group, we are committed to integrating sustainability into digital transformation.
            We provide innovative solutions that not only drive business success but also promote environmental responsibility and long-term sustainability.
          </p>
        </div>
      </section>

      {/* Green IT and Carbon Reduction */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Green IT and Carbon Reduction</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              We advise organizations on adopting energy-efficient IT practices to minimize environmental impact and reduce operational costs.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Data Center Optimization:</strong> Implementing strategies to improve energy efficiency in data centers, such as virtualization, cooling optimization, and efficient hardware utilization.</li>
              <li><strong>Green Cloud Solutions:</strong> Transitioning to cloud services that prioritize renewable energy sources and sustainable operations.</li>
              <li><strong>Sustainable IT Policies:</strong> Developing guidelines for responsible IT usage, including hardware lifecycle management and electronic waste reduction.</li>
            </ul>
            <p className="text-gray-700 mt-6">
              Example: Seismic assisted a financial services company in reducing their data center energy consumption by 30% through optimization and migration to a green cloud provider, significantly lowering their carbon footprint.
            </p>
          </div>
        </div>
      </section>

      {/* Sustainable Supply Chains */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Sustainable Supply Chains</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              Utilizing digital technologies, we help organizations build transparent and sustainable supply chains.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Supply Chain Visibility:</strong> Implementing tracking systems that provide real-time data on sourcing, production, and logistics, ensuring compliance with sustainability standards.</li>
              <li><strong>Sustainability Metrics Reporting:</strong> Developing dashboards and reporting tools that monitor environmental impact, enabling informed decision-making and continuous improvement.</li>
              <li><strong>Stakeholder Engagement:</strong> Enhancing collaboration with suppliers and partners to promote sustainable practices throughout the supply chain.</li>
            </ul>
            <p className="text-gray-700 mt-6">
              Example: For a manufacturing client, Seismic deployed a digital supply chain management system that improved sustainability reporting accuracy by 45% and identified key areas for reducing environmental impact.
            </p>
          </div>
        </div>
      </section>

      {/* Circular Economy Models */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold text-purple-900 mb-4">Circular Economy Models</h2>
            <p className="text-gray-700 mb-6 leading-relaxed text-lg">
              We support clients in embracing circular economy principles, promoting resource efficiency and waste reduction.
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li><strong>Product Lifecycle Management:</strong> Designing strategies for products that consider end-of-life reuse, recycling, or refurbishment.</li>
              <li><strong>Resource Optimization:</strong> Implementing systems to track and manage resource use, reducing waste and improving efficiency.</li>
              <li><strong>Innovation in Business Models:</strong> Exploring new models such as product-as-a-service or take-back programs that align with circular economy practices.</li>
            </ul>
            <p className="text-gray-700 mt-6">
              Example: Seismic guided a consumer electronics company in launching a device recycling program, resulting in the recovery of valuable materials and strengthening their brand's sustainability image.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SustainableDigitalSolutionsPage;

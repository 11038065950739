// src/pages/DigitalAgriculture.jsx
import React from "react";

const DigitalAgriculture = () => {
  const project = {
    title: "EcoExport: B2B Marketplace for African Agricultural Trade",
    overview: `EcoExport is a B2B digital marketplace designed to connect African farmers with global buyers, facilitating secure transactions, quality verification, and seamless trade processes. By leveraging technology and smart logistics, EcoExport enhances market access, transparency, and efficiency in the agricultural export industry.

The platform ensures that African agricultural products meet international standards, enabling farmers, cooperatives, and agribusinesses to access global markets with ease and confidence.`,
    useCases: [
      "Farmers & Agribusinesses – Provides a direct marketplace to sell agricultural products globally, ensuring fair pricing and reduced dependency on middlemen.",
      "Exporters & Traders – Streamlines international trade documentation, compliance, and payment processing for seamless exports.",
      "Global Buyers & Importers – Enables secure bulk purchasing of verified, high-quality agricultural products from Africa.",
      "Quality Control & Certification Bodies – Integrates product quality verification mechanisms to meet global trade standards.",
      "Government & Trade Organizations – Supports agriculture sector development, boosting economic growth through international trade.",
      "Financial & Payment Service Providers – Facilitates secure cross-border transactions and provides financing options for exporters.",
    ],
  };

  return (
    <section className="bg-gray-50">
{/* Hero Section */}
<div
  className="relative w-full h-[500px] sm:h-[600px] bg-black bg-cover bg-center flex items-center justify-center"
  style={{
    backgroundImage:
      "url('https://via.placeholder.com/1500x600?text=Digital+Agriculture')",
  }}
>
  <div className="absolute inset-0 bg-gradient-to-r from-green-800 to-yellow-600 opacity-80"></div>
  <div className="relative z-10 text-center px-4">
    <h1 className="text-4xl sm:text-5xl font-extrabold text-white tracking-wide drop-shadow-lg">
      Digital Agriculture
    </h1>
    <p className="mt-4 text-xl sm:text-2xl text-gray-200 max-w-2xl mx-auto drop-shadow">
      Transforming agricultural trade and market access through innovative digital solutions.
    </p>
  </div>
</div>


      {/* Introduction */}
      <div className="max-w-4xl mx-auto px-4 py-16 text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-6">
          Our Digital Agriculture Initiative
        </h2>
        <p className="text-gray-700 text-lg leading-relaxed">
          We are committed to bridging the gap between African agriculture and global markets. Explore our flagship project that revolutionizes agricultural trade through secure, efficient, and transparent digital solutions.
        </p>
      </div>

      {/* Project Section */}
      <div className="max-w-6xl mx-auto px-4 pb-16">
        <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-green-800 mb-4">
            {project.title}
          </h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
              Overview
            </h3>
            {project.overview.split("\n\n").map((para, idx) => (
              <p key={idx} className="text-gray-700 text-lg leading-relaxed mb-4">
                {para}
              </p>
            ))}
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
              Use Cases
            </h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-3 text-lg">
              {project.useCases.map((useCase, idx) => (
                <li key={idx}>{useCase}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DigitalAgriculture;

// src/pages/ManufacturingEvolution.jsx
import React from "react";

const ManufacturingEvolution = () => {
  const project = {
    title: "Artwork Digital Marketplace",
    overview: `The Artwork Digital Marketplace is an e-commerce platform designed to enable artists, sculptors, and creatives to showcase and sell their exceptional works. Inspired by platforms like Alibaba, it provides a global marketplace where artists can independently set prices, manage their portfolios, and connect with buyers.

The platform integrates the Seismic Payment Gateway, ensuring secure, seamless, and efficient transactions, allowing artists to monetize their creativity without financial bottlenecks.`,
    useCases: [
      "Artists & Creatives – Provides a dedicated digital storefront to sell paintings, sculptures, and artistic creations globally.",
      "Collectors & Buyers – Enables art lovers, investors, and collectors to browse and purchase authentic, high-quality artwork.",
      "Art Galleries & Exhibitions – Offers virtual showcases and digital auction spaces for exhibitions and curated collections.",
      "E-commerce & Retailers – Expands market reach by allowing boutiques, interior designers, and decor specialists to source unique art pieces.",
      "Payment & Financial Services – Uses the Seismic Payment Gateway to facilitate secure online payments, escrow services, and instant artist payouts.",
      "NFT & Digital Art Integration (Future Expansion) – Supports blockchain-backed verification for digital artwork sales and ownership tracking.",
    ],
  };

  return (
    <section className="bg-gray-50">
{/* Hero Section */}
<div
  className="relative w-full h-[500px] sm:h-[600px] bg-black flex items-center justify-center"
>
  <div className="relative z-10 text-center px-4">
    <h1 className="text-4xl sm:text-5xl font-extrabold text-white tracking-wide drop-shadow-lg">
      Manufacturing Evolution
    </h1>
    <p className="mt-4 text-xl sm:text-2xl text-gray-200 max-w-2xl mx-auto drop-shadow">
      Showcasing innovative digital solutions that transform the manufacturing landscape.
    </p>
  </div>
</div>


      {/* Introduction Section */}
      <div className="max-w-4xl mx-auto px-4 py-16 text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-6">
          {project.title}
        </h2>
        <p className="text-gray-700 text-lg leading-relaxed">
          The Artwork Digital Marketplace is a revolutionary e-commerce platform designed for the creative industry. Explore how artists and collectors connect globally with a secure and seamless digital marketplace.
        </p>
      </div>

      {/* Project Details Section */}
      <div className="max-w-6xl mx-auto px-4 pb-16">
        <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          {/* Overview */}
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
              Overview
            </h3>
            {project.overview.split("\n\n").map((para, idx) => (
              <p key={idx} className="text-gray-700 text-lg leading-relaxed mb-4">
                {para}
              </p>
            ))}
          </div>
          {/* Use Cases */}
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
              Use Cases
            </h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-3 text-lg">
              {project.useCases.map((useCase, idx) => (
                <li key={idx}>{useCase}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManufacturingEvolution;

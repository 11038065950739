import React, { useState } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";

const KeyApproach = () => {
  const [active, setActive] = useState(null);

  const toggleActive = (index) => {
    setActive(active === index ? null : index);
  };

  const approaches = [
    {
      title: "E-Governance Solutions",
      content: (
        <div className="flex flex-col md:grid md:grid-cols-2 gap-8 items-center md:items-start">
          {/* Description */}
          <div className="order-2 md:order-1 flex items-center">
            <p className="text-md lg:text-lg px-6 lg:px-12">
              Seismic Consulting harnesses the power of digital technologies to
              modernize government operations. Our E-Governance solutions
              streamline workflows, reduce bureaucracy, and enhance citizen
              engagement through secure, accessible digital platforms. We design
              systems that simplify complex processes, improve data
              transparency, and enable seamless interactions between governments
              and the communities they serve.
            </p>
          </div>
          {/* Image */}
          <div className="order-1 md:order-2 flex justify-center items-center">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737468616/images_pqum9x.jpg" // Replace with actual image URL
              alt="E-Governance Solutions"
              className="rounded-lg shadow-md object-cover w-full md:w-auto"
              style={{ maxHeight: "300px", minHeight: "250px", height: "auto" }}
            />
          </div>
          <div className="col-span-2 mt-6 px-4 lg:px-6 order-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="font-bold text-xl">
                  Digital Transformation of Public Services
                </h4>
                <p className="text-lg">
                  By digitizing essential government processes, we improve
                  accessibility, efficiency, and user satisfaction, fostering a
                  more responsive government.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">
                  Data Transparency Initiatives
                </h4>
                <p className="text-lg">
                  Seismic supports governments in developing open data platforms
                  that promote transparency, enabling informed decision-making
                  and trust between citizens and their government.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Public Sector Reform and Structural Optimization",
      content: (
        <div className="flex flex-col md:grid md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <p className="text-md lg:text-lg px-6 lg:px-12">
              We lead comprehensive reform initiatives that tackle structural
              inefficiencies, optimize resource allocation, and build a
              foundation of integrity and accountability. Our approach fosters a
              results-driven culture in government institutions, ensuring that
              policies are effectively implemented and public resources are used
              to maximize impact.
            </p>
          </div>
          <div className="order-1 md:order-2 flex justify-center items-center">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733517167/pexels-essow-k-251295-936722_sb0c7a.jpg" // Replace with actual image URL
              alt="Public Sector Reform"
              className="rounded-lg shadow-md object-cover w-full md:w-auto"
              style={{ maxHeight: "250px", minHeight: "200px", height: "auto" }}
            />
          </div>
          <div className="col-span-2 mt-6 px-4 lg:px-6 order-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="font-bold text-xl">Operational Restructuring</h4>
                <p className="text-lg">
                  We help governments optimize their structures for improved
                  service delivery and responsiveness.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">
                  Resource Allocation Optimization
                </h4>
                <p className="text-lg">
                  Our experts design systems that ensure resources are allocated
                  effectively, maximizing impact and minimizing waste.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">
                  Accountability & Compliance Systems
                </h4>
                <p className="text-lg">
                  Seismic develops frameworks that enforce accountability and
                  ethical governance, promoting integrity within public
                  institutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Inclusive Governance and Social Equity",
      content: (
        <div className="flex flex-col md:grid md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <p className="text-md lg:text-lg">
            At Seismic, we firmly believe that effective governance is rooted in inclusivity. Our strategies are designed to ensure that underrepresented and marginalized groups are not only considered but actively included in the decision-making process. By fostering equity, representation, and social justice, we help create governance frameworks that reflect the diverse voices of the community, promoting policies and initiatives that address the needs of all stakeholders and drive sustainable, inclusive development.
            </p>
          </div>
          <div className="order-1 md:order-2 flex justify-center items-center">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733494509/image_18_pp7pnw.png" // Replace with actual image URL
              alt="Inclusive Governance"
              className="rounded-lg shadow-md object-cover w-full md:w-auto"
              style={{ maxHeight: "300px", minHeight: "200px", height: "auto" }}
            />
          </div>
          <div className="col-span-2 mt-6 px-4 lg:px-6 order-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="font-bold text-xl">
                  Equitable Policy Development
                </h4>
                <p className="text-lg">
                  We guide governments in crafting policies that address the
                  needs of marginalized and vulnerable populations.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">
                  Capacity Building for Inclusion
                </h4>
                <p className="text-lg">
                  Through training programs and strategic guidance, we build the
                  skills and structures necessary for inclusive decision-making
                  processes.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">Social Equity Monitoring</h4>
                <p className="text-lg">
                  Seismic provides tools and frameworks to assess and monitor
                  the impact of governance reforms on diverse demographic
                  groups.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Performance and Impact Evaluation",
      content: (
        <div className="flex flex-col md:grid md:grid-cols-2 gap-8">
          {/* Description */}
          <div className="order-2 md:order-1">
            <p className="text-md lg:text-lg">
              In governance, accountability is ultimately measured by the
              outcomes achieved and their impact on communities. At Seismic, we
              empower governments and public institutions by designing and
              implementing robust performance evaluation frameworks. These
              frameworks enable comprehensive assessment and measurement of the
              effectiveness, efficiency, and sustainability of their reforms. By
              focusing on tangible results, we help institutions identify areas
              of improvement, ensure alignment with strategic goals, and build
              public trust through transparency and evidence-based
              decision-making.
            </p>
          </div>
          {/* Image */}
          <div className="order-1 md:order-2 flex justify-center items-center">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733494502/financial_planning_xl1tlg.jpg" // Replace with actual image URL
              alt="Performance Evaluation"
              className="rounded-lg shadow-md object-cover w-full md:w-auto"
              style={{ maxHeight: "200px", minHeight: "300px", height: "auto" }}
            />
          </div>
          {/* Additional Details */}
          <div className="col-span-2 mt-6 px-4 lg:px-6 order-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="font-bold text-xl">Impact Analysis</h4>
                <p className="text-lg">
                  We provide detailed evaluations to measure the social and
                  economic impact of reforms, ensuring that government programs
                  achieve intended objectives.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">
                  Key Performance Indicators (KPIs)
                </h4>
                <p className="text-lg">
                  We develop KPIs tailored to each client’s goals, offering a
                  clear roadmap for measuring progress and success.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">Adaptive Learning Systems</h4>
                <p className="text-lg">
                  Seismic integrates learning systems within organizations to
                  continuously improve reform initiatives and adapt to emerging
                  challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Capacity Building and Knowledge Transfer",
      content: (
        <div className="flex flex-col md:grid md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <p className="text-md lg:text-lg">
              Seismic is deeply committed to leaving a lasting legacy by
              prioritizing knowledge transfer and capacity building. Our
              approach focuses on equipping public sector officials with the
              skills, expertise, and practical tools needed to sustain and
              advance reforms independently. By fostering a culture of
              continuous learning and innovation, we ensure that the impact of
              our interventions extends far beyond our involvement, enabling
              governments and institutions to achieve long-term success and
              self-reliance in driving meaningful change.
            </p>
          </div>
          <div className="order-1 md:order-2 flex justify-center items-center">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733493156/cbt_sazqvr.jpg" // Replace with actual image URL
              alt="Knowledge Transfer"
              className="rounded-lg shadow-md object-cover w-full md:w-auto"
              style={{ maxHeight: "300px", minHeight: "200px", height: "auto" }}
            />
          </div>
          <div className="col-span-2 mt-6 px-4 lg:px-6 order-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="font-bold text-xl">Leadership Training</h4>
                <p className="text-lg">
                  We train government leaders in best practices, ethical
                  governance, and performance-driven management.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">
                  Technical Skills Development
                </h4>
                <p className="text-lg">
                  Our workshops and seminars equip officials with essential
                  skills in data management, strategic planning, and digital
                  governance.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">
                  Knowledge Transfer Programs
                </h4>
                <p className="text-lg">
                  Seismic provides ongoing mentorship and resources to embed
                  expertise within institutions, fostering long-term
                  sustainability.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Change Management as E-Governance Solutions",
      content: (
        <div className="flex flex-col md:grid md:grid-cols-2 gap-8">
          {/* Description */}
          <div className="order-1 md:order-1">
            <p className="text-md lg:text-lg">
              We recognize the implementation of e-governance solutions often
              involves significant organizational transformation. Our change
              management framework ensures seamless integration, adoption, and
              sustainability of e-governance systems.
            </p>
          </div>
          {/* Image */}
          <div className="order-2 md:order-2 flex justify-center items-center">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733493162/fmis_c8prqp.jpg" // Replace with actual image URL
              alt="Change Management"
              className="rounded-lg shadow-md object-cover w-full md:w-auto"
              style={{ maxHeight: "300px", minHeight: "200px", height: "auto" }}
            />
          </div>
          {/* Additional Details */}
          <div className="col-span-2 mt-6 px-4 lg:px-6 order-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="font-bold text-xl">Effective E-Governance</h4>
                <p className="text-lg">
                  Effective e-governance implementation begins with building
                  trust and understanding among stakeholders.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">
                  Risk Management and Resistance
                </h4>
                <p className="text-lg">
                  Mitigation strategies are employed to address resistance,
                  ensuring seamless transitions.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-xl">Monitoring and Evaluation</h4>
                <p className="text-lg">
                  Continuous evaluation measures adoption rates, user
                  satisfaction, and operational efficiency.
                </p>
              </div>
            </div>
          </div>
          {/* Read More Link */}
          <div className="col-span-2 mt-6 text-center order-4">
            <Link
              to={`/${paths.consultingServices}/${paths.governanceReform}/${paths.changeManagement}`}
              className="inline-block px-6 py-2 bg-purple-900 text-white font-semibold rounded-lg hover:bg-purple-800"
            >
              Read More →
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <section className="bg-white py-12">
      <div className="max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-4xl font-bold text-gray-800 text-center mb-6">
          Key Approach
        </h2>
        <p className="text-center text-lg lg:text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
          Smart strategies, custom solutions, and seamless collaboration to
          drive meaningful results and lasting impact.
        </p>
        <div className="border-t border-gray-300">
          {approaches.map((approach, index) => (
            <div
              key={index}
              className={`border-b border-gray-300 ${
                active === index ? "mt-8 mb-8" : "mt-8 mb-8"
              }`}
            >
              <button
                className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
                onClick={() => toggleActive(index)}
              >
                <span className="text-lg lg:text-xl font-semibold text-gray-800">
                  {approach.title}
                </span>
                <span className={`text-purple-800 text-2xl lg:text-3xl`}>
                  {active === index ? "−" : "+"}
                </span>
              </button>
              {active === index && (
                <div className="p-4 lg:p-6 text-gray-600">
                  {approach.content}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyApproach;

import React, { useState } from "react";

const MethodologySectionDT = () => {
  const [open, setOpen] = useState({
    technologyAssessment: false,
    solutionImplementation: false,
    strategyDevelopment: false,
  });

  const toggleCollapse = (key) => {
    setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div className="mb-16">
      <h2 className="text-2xl font-bold mb-4 text-center pt-12">
        Our Methodology
      </h2>
      <h3 className="text-xl text-center mb-4 text-gray-600">
      Assess existing technology infrastructure and identify areas for improvement. Implement cutting-edge solutions tailored to organizational needs, such as AI, cloud computing, and data analytics Seismic’s Digital Transformation methodology is rooted in understanding each organization’s unique challenges and crafting solutions that ensure maximum impact
      </h3>
      {/* Divider Line */}
      <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-8 mt-8"></div>

      <div className="max-w-4xl mx-auto space-y-16 mt-12">
        {/* Technology Assessment */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("technologyAssessment")}
          >
            Technology Assessment Infrastructure Review
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.technologyAssessment ? "rotate-180" : ""
              }`}
            >
              {open.technologyAssessment ? "-" : "+"}
            </span>
          </button>
          {open.technologyAssessment && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Infrastructure Review:</strong>{" "}
                  Comprehensive evaluations of existing IT systems, identifying
                  strengths, gaps, and opportunities for improvement.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Capability Mapping:</strong>{" "}
                  Analyze current technological capabilities and their alignment
                  with strategic goals.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Industry Benchmarking:</strong>{" "}
                  Compare technological readiness and adoption levels with
                  industry standards to identify competitive gaps.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Solution Implementation */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("solutionImplementation")}
          >
            Solution Implementation
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.solutionImplementation ? "rotate-180" : ""
              }`}
            >
              {open.solutionImplementation ? "-" : "+"}
            </span>
          </button>
          {open.solutionImplementation && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">
                    Cutting-Edge Technologies:
                  </strong>{" "}
                  Deploy tools and platforms like AI, cloud computing, and data
                  analytics.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Change Enablement:</strong>{" "}
                  Provide hands-on training and support to ensure smooth
                  adoption of new technologies by teams.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Cybersecurity:</strong> Fortify
                  digital infrastructures to protect sensitive data and ensure
                  compliance with regulatory standards.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Strategy Development */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("strategyDevelopment")}
          >
            Strategy Development
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.strategyDevelopment ? "rotate-180" : ""
              }`}
            >
              {open.strategyDevelopment ? "-" : "+"}
            </span>
          </button>
          {open.strategyDevelopment && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Customization:</strong> Develop
                  digital strategies tailored to the organization’s specific
                  needs, ensuring alignment with broader business objectives.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Integration Roadmaps:</strong>{" "}
                  Outline phased implementations that prioritize high-impact
                  solutions, minimizing disruptions while maximizing ROI.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Future-Proofing:</strong>{" "}
                  Incorporate scalable solutions that adapt to emerging trends
                  and evolving needs.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>
        {/*  Continuous Monitoring and Support */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("strategyDevelopment")}
          >
            Continuous Monitoring and Support
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.strategyDevelopment ? "rotate-180" : ""
              }`}
            >
              {open.strategyDevelopment ? "-" : "+"}
            </span>
          </button>
          {open.strategyDevelopment && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl"> Establish robust feedback loops and monitoring systems to evaluate the performance of implemented solutions, refining them as necessary.</strong> 
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>
      </div>
    </div>
  );
};

export default MethodologySectionDT;

import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <section className="bg-white py-16">
      {/* Hero Section */}
      <div
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1734612957/pexels-fauxels-3184316_g690xs.jpg')" }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 bg-black bg-opacity-20 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] max-w-full lg:max-w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">About Us</h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            We are driven by the conviction that impactful change is achieved through strategic thinking, 
            collaborative innovation, and unwavering commitment to delivering results.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto py-16 px-4 lg:px-16">
        {/* About Seismic Consulting */}
        <div className="text-center mb-16">
          <p className="text-xl text-purple-900 italic max-w-4xl mx-auto">
            At <strong>Seismic Consulting</strong>, we distinguish ourselves through our collaborative partnership model, 
            which integrates seamlessly across our organization and with our clients. We view every engagement as an 
            opportunity to co-create solutions innovatively that address the unique challenges and goals of our clients.
          </p>
        </div>


        {/* Strategic Approach */}
        <div className="text-gray-800 mb-16">
        <p className="text-xl text-gray-800 mb-6">
            <strong className="text-purple-900">SEISMIC</strong> is a leading consultancy that provides superior, 
            innovative, evidence-based, effective solutions and customized services to organizations, government, 
            and leaders working to create large-scale, enduring positive change in society.
          </p>
          <p className="text-xl">
            Through collaborative partnerships with highly diverse clientele across a wide range of industries and sectors, 
            we undertake leading-edge research and analytics to generate data, intelligence, insights, and strategic thinking 
            that enable our clients and partners to solve problems, make informed decisions, maximize resources, transform 
            their organizations, and implement cost-effective solutions, strategies, and policies that deliver value to people.
          </p>
          <p className="text-xl mt-6">
            We leverage the diverse capabilities, experiences, and thinking of our people, combined with technology, digital, 
            and other relevant tools to provide solutions that enable our clients and partners to achieve bottom-line results 
            and other positive outcomes.
          </p>
        </div>

{/* Mission & Vision */}
<div className="flex flex-col gap-8 mb-16">
  {/* Mission Section */}
  <div className="flex items-start w-full">
    <h3 className="text-2xl font-semibold text-gray-800 w-1/4 min-w-[150px]">Our Mission</h3>
    <p className="text-xl text-purple-900 italic w-3/4 text-center">
      "Our mission is to support our clients and partners to deliver value, 
      achieve positive outcomes, and make large-scale enduring impact, by providing 
      innovative and effective solutions that guarantee desired results."
    </p>
  </div>

  {/* Vision Section */}
  <div className="flex items-start w-full">
    <h3 className="text-2xl font-semibold text-gray-800 w-1/4 min-w-[150px]">Seismic Vision</h3>
    <p className="text-xl text-purple-900 italic w-3/4 text-center">
      "To be the most prestigious, research-oriented, results-driven, and 
      innovative consulting firm in the world."
    </p>
  </div>
</div>

{/* Our Firm Section */}
<div className="text-center mb-16">
  <h2 className="text-3xl font-semibold text-gray-800 mb-4">Our Firm</h2>
  <p className="text-lg text-gray-600 max-w-3xl mx-auto">
    Seismic Consulting Group brings deep expertise and a holistic approach to transforming your IT infrastructure.
  </p>

  {/* 1-column on small screens, 2-columns on medium (md) and above */}
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8 max-w-4xl mx-auto">
    {/* What We Believe */}
    <a href="/about/what-we-believe" className="block">
      <div className="p-6 border border-gray-200 rounded-lg text-left shadow-sm hover:shadow-lg transition-shadow cursor-pointer h-full">
        <h4 className="text-xl font-semibold text-gray-800 mb-2">
          What We Believe
        </h4>
        <p className="text-gray-600">
          We are driven by the conviction that impactful change is achieved through
          strategic thinking, collaborative innovation, and unwavering commitment 
          to delivering results.
        </p>
        <div className="text-right mt-4">
          <span className="text-purple-800 text-xl">→</span>
        </div>
      </div>
    </a>

    {/* What We Do */}
    <a href="/about/what-we-do" className="block">
      <div className="p-6 border border-gray-200 rounded-lg text-left shadow-sm hover:shadow-lg transition-shadow cursor-pointer h-full">
        <h4 className="text-xl font-semibold text-gray-800 mb-2">What We Do</h4>
        <p className="text-gray-600">
          We are more than just a consultancy. Whether a government agency 
          or a private organization, we help improve public service delivery 
          and operational efficiency.
        </p>
        <div className="text-right mt-4">
          <span className="text-purple-800 text-xl">→</span>
        </div>
      </div>
    </a>
  </div>
</div>



</div>

{/* Our Leadership Section */}
<div className="text-center mb-16 lg:px-24">
  <h2 className="text-3xl font-semibold text-gray-800 mb-4">Our Leadership</h2>
  <p className="text-lg text-gray-600 max-w-3xl mx-auto">
    We are a diverse team of innovators, thinkers, and problem-solvers, united by a shared passion
    for technology and a commitment to making an impact.
  </p>

  {/* People Grid */}
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 mt-8 max-w-4xl mx-auto">

    {/* Person 1 */}
    <a
      href="/about/dr-daniel-oghojafor"
      className="flex items-center space-x-6 hover:bg-gray-50 p-4 rounded transition"
    >
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738146401/WhatsApp_Image_2025-01-29_at_11.26.07_fak7xr.jpg"
        alt="Dr Daniel Ejiri Oghojafor"
        className="w-24 h-24 rounded-full object-cover"
      />
      <div className="text-left">
        <h4 className="text-xl font-semibold text-gray-800">Dr Daniel Ejiri Oghojafor</h4>
        <p className="text-base text-gray-600">CEO &amp; Founder Seismic Consulting Group</p>
      </div>
    </a>

    {/* Person 2 */}
    <a
      href="/about/dr-olusoji-adeniyi"
      className="flex items-center space-x-6 hover:bg-gray-50 p-4 rounded transition"
    >
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738146402/WhatsApp_Image_2025-01-29_at_11.26.03_rw1zvz.jpg"
        alt="Dr Olusoji Adeniyi"
        className="w-24 h-24 rounded-full object-cover"
      />
      <div className="text-left">
        <h4 className="text-xl font-semibold text-gray-800">Dr Olusoji Adeniyi</h4>
        <p className="text-base text-gray-600">Senior Partner, Risk &amp; Quality Assurance</p>
      </div>
    </a>

    {/* Person 3 */}
    <a
      href="/about/promise-ikpuri"
      className="flex items-center space-x-6 hover:bg-gray-50 p-4 rounded transition"
    >
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739447842/WhatsApp_Image_2025-02-13_at_12.55.28_p35ctp.jpg"
        alt="Promise Ikpuri"
        className="w-24 h-24 rounded-full bg-gray-300 object-cover"
      />
      <div className="text-left">
        <h4 className="text-xl font-semibold text-gray-800">Promise Ikpuri</h4>
        <p className="text-base text-gray-600">Head of Operations</p>
      </div>
    </a>

    {/* Person 4 */}
    <a
      href="/about/jibrin-haruna"
      className="flex items-center space-x-6 hover:bg-gray-50 p-4 rounded transition"
    >
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739452414/WhatsApp_Image_2025-02-13_at_14.10.59_boxmoc.jpg"
        alt="Jibrin Haruna"
        className="w-24 h-24 rounded-full bg-gray-300 object-cover"
      />
      <div className="text-left">
        <h4 className="text-xl font-semibold text-gray-800">Jibrin Haruna</h4>
        <p className="text-base text-gray-600">Team Lead, Digital Transformation &amp; Innovation</p>
      </div>
    </a>
  </div>

  {/* Button */}
  <div className="mt-8">
    <a
      href="/about/the-team"
      className="inline-block bg-purple-900 hover:bg-purple-900 text-white font-semibold text-lg px-6 py-3 rounded transition"
    >
      Meet Our Team &rarr;
    </a>
  </div>
</div>


{/* Departments Section */}
<div className="text-center mb-16 max-w-7xl mx-auto py-16 px-4 lg:px-16">
  <h2 className="text-3xl font-semibold text-gray-800 mb-4">Our Departments</h2>
  <p className="text-lg text-gray-600 max-w-3xl mx-auto">
    We are a diverse team of innovators, thinkers, and problem-solvers, united by a shared passion for technology and a commitment to making an impact.
  </p>

  {/* Department Cards */}
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8 max-w-6xl mx-auto">
    {/* Digital Transformation & Innovation */}
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736932864/5_1_ldv5s1.jpg"
        alt="Digital Transformation & Innovation"
        className="w-full h-72 object-cover"
      />
      <div className="p-6 text-left">
        <h4 className="text-xl font-semibold text-gray-800">
          Digital Transformation &amp; Innovation
        </h4>
        <p className="text-gray-600 mt-2">
          Our expertise lies in harnessing this invaluable resource to empower
          our clients with actionable insights and strategic foresight. Through
          advanced big data analytics, predictive modeling, and machine learning,
          we enable organizations to make informed decisions that drive growth
          and innovation.
        </p>
      </div>
    </div>

    {/* Research and Innovation */}
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736937596/pexels-divinetechygirl-1181355_sdhaw9.jpg"
        alt="Research and Innovation"
        className="w-full h-72 object-cover"
      />
      <div className="p-6 text-left">
        <h4 className="text-xl font-semibold text-gray-800">
          Research and Innovation
        </h4>
        <p className="text-gray-600 mt-2">
          Our expertise lies in conducting in-depth research and fostering
          innovation to drive evidence-based decision-making. We utilize
          cutting-edge methodologies to analyze trends, evaluate programs, and
          develop innovative solutions tailored to industry needs.
        </p>
      </div>
    </div>

    {/* Monitoring & Evaluation */}
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739182750/pexels-photo-9301831_nh9lgb.jpg"
        alt="Monitoring & Evaluation"
        className="w-full h-72 object-cover"
      />
      <div className="p-6 text-left">
        <h4 className="text-xl font-semibold text-gray-800">
          Monitoring &amp; Evaluation
        </h4>
        <p className="text-gray-600 mt-2">
          We specialize in designing and implementing robust monitoring and
          evaluation frameworks that track performance, assess impact, and ensure
          accountability. Our expertise enables organizations to measure success
          and optimize strategies for sustainable growth.
        </p>
      </div>
    </div>

    {/* Procurement */}
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739182650/pexels-photo-6170101_iovgqv.jpg"
        alt="Procurement"
        className="w-full h-72 object-cover"
      />
      <div className="p-6 text-left">
        <h4 className="text-xl font-semibold text-gray-800">Procurement</h4>
        <p className="text-gray-600 mt-2">
          Our procurement department ensures seamless sourcing, contract management,
          and supply chain optimization. We streamline procurement processes to
          enhance efficiency, minimize risks, and drive cost-effective solutions
          for organizations.
        </p>
      </div>
    </div>

    {/* Capacity Building & Training */}
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739189708/WhatsApp_Image_2025-02-05_at_14.48.29_1_ddsdtf.jpg"
        alt="Capacity Building & Training"
        className="w-full h-72 object-cover"
      />
      <div className="p-6 text-left">
        <h4 className="text-xl font-semibold text-gray-800">
          Capacity Building &amp; Training
        </h4>
        <p className="text-gray-600 mt-2">
        We focus on equipping individuals and organizations with the knowledge and 
      skills necessary to excel. Through interactive workshops, specialized training 
      programs, and skill-building initiatives, we empower communities, employees, 
      and leaders to adapt to an ever-evolving landscape. This approach fosters 
      personal growth, organizational resilience, and broader socio-economic 
      development.
        </p>
      </div>
    </div>

    {/* Business Development */}
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739354444/businesswoman-analyze-the-profitability-of-companies-using-digital-graphs-positive-trends-for_fbz3kk.jpg"
        alt="Business Development"
        className="w-full h-72 object-cover"
      />
      <div className="p-6 text-left">
        <h4 className="text-xl font-semibold text-gray-800">
          Business Development
        </h4>
        <p className="text-gray-600 mt-2">
          Our business development team offers strategic market insights,
          partnership facilitation, and growth strategies. Through data-driven
          approaches, we help organizations expand market reach, optimize revenue
          streams, and capture new opportunities in a dynamic business environment.
        </p>
      </div>
    </div>
  </div>
</div>




{/* Request for Proposal Section */}
<div className="bg-gray-50 py-12">
  <div className="max-w-5xl mx-auto px-6">
    <div className="flex flex-col md:flex-row items-center gap-8">
      {/* Image */}
      <div className="w-full md:w-1/2">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738159276/pexels-rdne-7821490_it9tlm.jpg"
          alt="Request for Proposal"
          className="rounded-lg shadow-md w-full h-auto"
        />
      </div>

      {/* Content */}
      <div className="w-full md:w-1/2 text-left">
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Request for Proposal</h3>
        <p className="text-lg text-gray-600 mb-6">
          Are you looking for a trusted partner to deliver innovative, results-driven solutions for your organization?
          We collaborate with clients across diverse industries, providing specialized insights and strategies to address
          unique challenges and unlock opportunities. By submitting an RFP, you take the first step toward building a
          meaningful partnership with us.
        </p>
        <Link to="/about/request-for-proposal" className="text-purple-900 font-semibold inline-flex items-center">
            Submit RFP Request
            <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </Link>
      </div>
    </div>
  </div>
</div>


  
    </section>
  );
};

export default About;

import React, { useState } from "react";
import Methodology from "./Methodology";
import AccomplishedProjects from "./AccomplishedProjects";

const CapacityBuilding = () => {
  const [active, setActive] = useState(null);

  const toggleAccordion = (index) => {
    setActive(active === index ? null : index);
  };

  return (
    <section className="bg-white pb-16 mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1733493156/cbt_sazqvr.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Capacity Building & Human Resource Development
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Envision a world where social equity is both a goal and a reality.
          </p>
        </div>
      </section>



         {/* Title Under Image for Small Screens */}
         <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Capacity Building & Human Resource Development
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        <p className="text-lg lg:text-xl text-gray-600 leading-relaxed">
          With decades of experience and a team of experts spanning various
          fields, Seismic Consulting is uniquely positioned to deliver impactful
          Capacity Building & Human Resource Development services. We are
          committed to excellence, innovation, and sustainable results, helping
          our clients build capabilities that endure and transform challenges
          into opportunities.
        </p>
        <p className="text-lg lg:text-xl text-gray-600 leading-relaxed mt-4">
          At Seismic Consulting, we understand that an organization's most
          valuable asset is its people. Building strong, resilient, and capable
          human capital is essential for organizations to achieve their
          strategic goals, drive innovation, and remain competitive in today's
          dynamic global landscape. Our Capacity Building & Human Resource
          Development services go beyond traditional training by creating
          comprehensive, sustainable frameworks that foster a culture of
          continuous learning, development, and empowerment.
        </p>
        <p className="text-lg lg:text-xl text-gray-600 leading-relaxed mt-4">
          We partner with our clients to unlock the full potential of their
          workforce, ensuring they have the knowledge, skills, and tools
          required to address challenges and seize opportunities in an
          ever-changing environment.
        </p>
      </div>

{/* Key Pillars Section */}
<div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16 mb-12">
  <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 text-center mb-4">
    Key Pillars of Seismic's Capacity Building Services
  </h2>
  <p className="text-lg text-gray-600 text-center mb-12 max-w-3xl mx-auto">
    By combining innovative methodologies, practical solutions, and a commitment to sustainability, we enable our clients to cultivate a culture of excellence, resilience, and continuous improvement.
  </p>
  <div className="grid md:grid-cols-2 gap-12">
    {/* Image */}
    <div className="flex justify-center items-center">
      <div className="w-full h-[500px] rounded-lg shadow-lg overflow-hidden">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738074206/WhatsApp_Image_2025-01-28_at_13.17.35_xfkaiq.jpg" // Replace with the single image URL you want to use
          alt="Key Pillars"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
    {/* Accordion */}
    <div>
      <div className="border-b border-gray-300">
        <button
          className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
          onClick={() => toggleAccordion(1)}
        >
          <span className="text-lg font-bold text-gray-800 mt-4 mb-2">
            Customized Training Solutions
          </span>
          <span className="text-purple-800 text-2xl">
            {active === 1 ? "−" : "+"}
          </span>
        </button>
        {active === 1 && (
          <div className="py-4 text-gray-600">
            <p>
              <strong>What we do:</strong> We design and implement bespoke training programs tailored to the specific needs, goals, and operational realities of each organization.
            </p>
            <p className="mt-2">
              <strong>Approach:</strong> Our programs blend interactive workshops, real-world simulations, e-learning platforms, and case studies to ensure comprehensive and impactful learning.
            </p>
          </div>
        )}
      </div>
      <div className="border-b border-gray-300">
        <button
          className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
          onClick={() => toggleAccordion(2)}
        >
          <span className="text-lg font-bold text-gray-800 mt-4 mb-2">
            Leadership Development Programs
          </span>
          <span className="text-purple-800 text-2xl">
            {active === 2 ? "−" : "+"}
          </span>
        </button>
        {active === 2 && (
          <div className="py-4 text-gray-600">
            <p>
              <strong>What we do:</strong> Equip current and emerging leaders with the insights and skills needed to guide their organizations through periods of transformation, growth, and uncertainty.
            </p>
            <p className="mt-2">
              <strong>Focus Areas:</strong> Strategic decision-making, Change management, Conflict resolution, Emotional intelligence.
            </p>
          </div>
        )}
      </div>
      <div className="border-b border-gray-300">
        <button
          className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
          onClick={() => toggleAccordion(3)}
        >
          <span className="text-lg font-bold text-gray-800 mt-4 mb-2">
            Organizational Development and Culture Transformation
          </span>
          <span className="text-purple-800 text-2xl">
            {active === 3 ? "−" : "+"}
          </span>
        </button>
        {active === 3 && (
          <div className="py-4 text-gray-600">
            <p>
              <strong>What we do:</strong> Conduct in-depth assessments to identify organizational capability gaps and implement strategies to close them.
            </p>
            <p className="mt-2">
              <strong>Focus Areas:</strong> Fostering a culture of continuous learning and collaboration, Building resilient systems that adapt to changes effectively, Optimizing processes to maximize efficiency.
            </p>
          </div>
        )}
      </div>
      <div className="border-b border-gray-300">
        <button
          className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
          onClick={() => toggleAccordion(4)}
        >
          <span className="text-lg font-bold text-gray-800 mt-4 mb-2">
            Innovative Learning Approaches
          </span>
          <span className="text-purple-800 text-2xl">
            {active === 4 ? "−" : "+"}
          </span>
        </button>
        {active === 4 && (
          <div className="py-4 text-gray-600">
            <p>
              <strong>What we do:</strong> Integrate modern technologies, methodologies, and tools to enhance the learning experience.
            </p>
            <ul className="mt-2 list-disc pl-5">
              <li>Use of gamification and digital platforms for skill-building.</li>
              <li>Deployment of blended learning models combining in-person and online learning.</li>
            </ul>
          </div>
        )}
      </div>
      <div className="border-b border-gray-300 mt-4 mb-2">
        <button
          className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
          onClick={() => toggleAccordion(5)}
        >
          <span className="text-lg font-bold text-gray-800">
            Long-Term Knowledge Transfer
          </span>
          <span className="text-purple-800 text-2xl">
            {active === 5 ? "−" : "+"}
          </span>
        </button>
        {active === 5 && (
          <div className="py-4 text-gray-600">
            <p>
              <strong>What we do:</strong> Embed sustainable practices within organizations to ensure the knowledge and skills gained during capacity-building initiatives remain effective over time.
            </p>
            <ul className="mt-2 list-disc pl-5">
              <li>Develop internal mentorship and coaching frameworks.</li>
              <li>Create reusable training materials and resources.</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  </div>
</div>


      {/* Target Audience Section */}
      <div className="bg-gradient-to-b from-[#2D002D] to-[#600060] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 lg:px-8">
          <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
            Target Audience
          </h2>
          <p className="text-lg text-center mb-10">
            Building Human Resource Development services catering to a diverse
            audience
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Audience Item 1 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png"
                  alt="Government Institutions"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-8">
                Government Institutions
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Federal, state, and local government agencies seeking to build
                  skilled personnel for public service efficiency.
                </li>
                <li>
                  Ministries of Education, Health, Agriculture, Finance, and
                  more.
                </li>
              </ul>
            </div>

            {/* Audience Item 2 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
                  alt="Development Organizations and NGOs"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-8">
                Development Organizations and NGOs
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  International development agencies and nonprofits aiming to
                  empower their partners and beneficiaries through
                  skill-building initiatives.
                </li>
              </ul>
            </div>

            {/* Audience Item 3 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png"
                  alt="Corporate Entities"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-8">Corporate Entities</h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Companies looking to invest in their workforce to enhance
                  productivity.
                </li>
                <li>
                  Improve employee engagement and future-proof their operations.
                </li>
              </ul>
            </div>

            {/* Audience Item 4 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765499/search_7501627_llk7w8.png"
                  alt="Academic and Research Institutions"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-8">
                Academic and Research Institutions
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Universities and training institutions in need of innovative
                  capacity-building solutions for faculty and administrators.
                </li>
              </ul>
            </div>

            {/* Audience Item 5 */}
            <div className="text-left flex flex-col items-start">
              <div className="w-12 h-12 mb-4">
                <img
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765626/person_7498074_e8j9wb.png"
                  alt="Community-Based Organizations"
                  className="w-full h-full object-contain"
                />
              </div>
              <h3 className="text-md font-bold mb-8">
                Community-Based Organizations
              </h3>
              <ul className="list-disc pl-5 text-sm lg:text-base space-y-2">
                <li>
                  Grassroots organizations requiring skills to address local
                  challenges effectively and sustainably.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Methodology />

      {/* Results and Impact Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12">
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 text-center mb-6">
            Results and Impact
          </h2>
          <p className="text-lg text-gray-600 text-center mb-12 max-w-2xl mx-auto">
            Our capacity-building initiatives are designed to create measurable,
            transformational outcomes:
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Card 1 */}
            <div className="bg-white shadow-md text-center p-6">
              <h3 className="text-lg font-bold text-gray-800 mb-2">
                Increased Workforce Competence
              </h3>
              <p className="text-gray-600 text-sm">
                Employees gain practical, relevant skills to excel in their
                roles.
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-white shadow-md text-center p-6">
              <h3 className="text-lg font-bold text-gray-800 mb-2">
                Stronger Leadership
              </h3>
              <p className="text-gray-600 text-sm">
                Leaders are equipped to inspire teams, manage change, and
                achieve organizational goals.
              </p>
            </div>

            {/* Card 3 */}
            <div className="bg-white shadow-md text-center p-6">
              <h3 className="text-lg font-bold text-gray-800 mb-2">
                Enhanced Productivity
              </h3>
              <p className="text-gray-600 text-sm">
                Improved operational processes and employee engagement drive
                overall efficiency.
              </p>
            </div>

            {/* Card 4 */}
            <div className="bg-white shadow-md text-center p-6">
              <h3 className="text-lg font-bold text-gray-800 mb-2">
                Cultural Transformation
              </h3>
              <p className="text-gray-600 text-sm">
                Organizations adopt a growth mindset, fostering innovation and
                adaptability.
              </p>
            </div>

            {/* Card 5 */}
            <div className="bg-white shadow-md text-center p-6">
              <h3 className="text-lg font-bold text-gray-800 mb-2">
                Long-Term Impact
              </h3>
              <p className="text-gray-600 text-sm">
                Knowledge and practices are embedded within the organization,
                ensuring sustainable success.
              </p>
            </div>
          </div>
        </div>
      </div>

      <AccomplishedProjects />

            {/*Capacity Building Experts Section */}
<section className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
  <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
    Our Capacity Building Experts
  </h2>

  <div className="flex justify-center gap-16 flex-wrap">
    {[
   {
    name: "Dr. Olusoji Adeniyi",
    role: "Senior Partner, Expert Education & Capacity Building ",
    imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739522280/WhatsApp_Image_2025-02-14_at_09.37.47_y6vhwu.jpg",
  },
  {
    name: "Joseph I. Emmanuel",
    role: "Associate, Research, Capacity building and Training",
    imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739442693/WhatsApp_Image_2025-02-13_at_11.31.13_ourq3z.jpg",
  },
  {
    name: "Onyey-Ezechukwu Wendy Nkechi",
    role: "Legal & HR Manager",
    imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739447851/WhatsApp_Image_2025-02-13_at_12.57.10_ihm5tr.jpg",
  },
    ].map((expert, index) => (
      <div key={index} className="shadow-lg rounded-lg overflow-hidden text-left bg-white w-[280px]">
        <img src={expert.imageUrl} alt={expert.name} className="w-full h-[300px] object-cover" />
        <div className="p-4">
          <h3 className="text-xl font-bold text-gray-900">{expert.name}</h3>
          <p className="text-gray-600 mt-1">{expert.role}</p>
        </div>
      </div>
    ))}
  </div>
</section>
    </section>
  );
};

export default CapacityBuilding;

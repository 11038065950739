import React from "react";

function AIAndML() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Artificial Intelligence and Machine Learning Applications
          </h1>
        </div>
      </section>

      {/* Introduction */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            At Seismic Consulting Group, we drive AI and ML innovation, enabling businesses to enhance **efficiency, decision-making, and competitiveness** through cutting-edge technologies.
          </p>
        </div>
      </section>

      {/* Natural Language Processing (NLP) */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            Natural Language Processing (NLP)
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Seismic applies NLP to **automate customer interactions, analyze sentiment, and streamline document processing**—transforming how businesses process language-based data.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="font-bold text-gray-800">
                Customer Service Automation
              </h3>
              <p className="text-gray-700">
                AI-powered **chatbots and virtual assistants** deliver 24/7 customer support, enhancing responsiveness and reducing costs.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">Sentiment Analysis</h3>
              <p className="text-gray-700">
                NLP **analyzes customer feedback** to understand preferences, enabling personalized marketing strategies.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">Document Processing</h3>
              <p className="text-gray-700">
                AI automates **data extraction from reports, emails, and contracts**, accelerating decision-making and reducing human errors.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Computer Vision */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            Computer Vision
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Seismic harnesses Computer Vision to **enhance manufacturing quality, security monitoring, and augmented reality experiences**.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="font-bold text-gray-800">
                Quality Assurance in Manufacturing
              </h3>
              <p className="text-gray-700">
                AI-powered **visual inspection** detects defects faster than manual processes, improving product quality.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">Security & Surveillance</h3>
              <p className="text-gray-700">
                Real-time **AI-driven surveillance** detects anomalies, enhancing workplace safety and security measures.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">Augmented Reality (AR)</h3>
              <p className="text-gray-700">
                AR overlays digital insights onto real-world environments, transforming **training, maintenance, and retail experiences**.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* AI-Driven Business Intelligence */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            AI-Driven Business Intelligence
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            AI transforms raw data into strategic insights—**enhancing decision-making, predictive analytics, and operational intelligence**.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="font-bold text-gray-800">
                Predictive Analytics
              </h3>
              <p className="text-gray-700">
                AI extracts **hidden patterns from data**, providing forecasts for **sales, market trends, and risk management**.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">
                Strategic Decision Support
              </h3>
              <p className="text-gray-700">
                AI-powered dashboards deliver **real-time insights** for **finance, operations, and customer engagement**.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">
                Custom AI Tools
              </h3>
              <p className="text-gray-700">
                We develop **tailored AI solutions**, ensuring data is leveraged to maximize business performance.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Seismic for AI & ML Solutions */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            Why Choose Seismic for AI & ML Solutions
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <div className="flex flex-col items-center text-center">
              <img
                src="https://via.placeholder.com/50"
                alt="Expertise"
                className="mb-2 w-12 h-12"
              />
              <h3 className="font-bold text-gray-800">AI Expertise</h3>
              <p className="text-gray-600">
                Our AI specialists **stay ahead of industry advancements** to deliver cutting-edge solutions.
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <img
                src="https://via.placeholder.com/50"
                alt="Custom Solutions"
                className="mb-2 w-12 h-12"
              />
              <h3 className="font-bold text-gray-800">Custom AI Solutions</h3>
              <p className="text-gray-600">
                We tailor AI applications **to align with your business goals**, ensuring optimal impact.
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <img
                src="https://via.placeholder.com/50"
                alt="Innovation"
                className="mb-2 w-12 h-12"
              />
              <h3 className="font-bold text-gray-800">Data-Driven Innovation</h3>
              <p className="text-gray-600">
                Our AI **transforms data into actionable insights**, driving smarter business decisions.
              </p>
            </div>
            <div className="flex flex-col items-center text-center">
              <img
                src="https://via.placeholder.com/50"
                alt="Security"
                className="mb-2 w-12 h-12"
              />
              <h3 className="font-bold text-gray-800">Scalable AI</h3>
              <p className="text-gray-600">
                We design AI solutions **that grow with your organization**, ensuring long-term scalability.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AIAndML;

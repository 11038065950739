import React, { useState } from "react";
import { Link } from "react-router-dom";
import PFMTrackRecord from "./PFMTrackRecord";
import { paths } from "../../constants/paths";

const PFMServices = [
  {
    title: "Audit and Financial Control Systems",
    description:
      "As a global leader in Public Financial Management, Seismic offers a comprehensive suite of audit and financial control solutions that strengthen transparency, accountability, and governance within public institutions.",
    image: "/PagesImages/afcs.png",
    link: `/${paths.consultingServices}/${paths.pfm}/${paths.afcs}`,
  },
  {
    title: "Capacity Building and Training",
    description:
      "Our training initiatives emphasize collaboration and peer learning, encouraging participants to share experiences, insights, and solutions, fostering continuous learning and improvement within public institutions.",
    image: "/PagesImages/cbt.jpg",
    link: `/${paths.consultingServices}/${paths.pfm}/${paths.cbt}`,
  },
  {
    title: "Capital Investment Planning",
    description:
      "We help governments and public institutions develop strategic capital investment plans that drive sustainable economic growth and align with long-term national development goals.",
    image: "/PagesImages/cip.jpg",
    link: `/${paths.consultingServices}/${paths.pfm}/${paths.cip}`,
  },
  {
    title: "Decentralization and Fiscal Management",
    description:
      "We leverage extensive experience to help governments design and implement fiscal management strategies that empower local governments to manage resources effectively.",
    image: "/PagesImages/dfm.jpg",
    link: `/${paths.consultingServices}/${paths.pfm}/${paths.dfm}`,
  },
  {
    title: "Financial Management Information Systems (FMIS)",
    description:
      "Seismic is at the forefront of transforming public financial management through cutting-edge FMIS...",
    image: "/PagesImages/fmis.jpg",
    link: `/${paths.consultingServices}/${paths.pfm}/${paths.fmis}`,
  },
  {
    title: "Performance-Based Budgeting (PBB)",
    description:
      "Our PBB services help public institutions align fiscal strategies with performance goals, improving the transparency and efficiency of public spending.",
    image: "/PagesImages/pbb.jpg",
    link: `/${paths.consultingServices}/${paths.pfm}/${paths.pbb}`,
  },
  {
    title: "Public Finance Policy Assessment and Formulation",
    description:
      "We assist governments and institutions in achieving fiscal stability and promoting growth through comprehensive finance policy assessment and formulation services.",
    image: "/PagesImages/pfpa.jpg",
    link: `/${paths.consultingServices}/${paths.pfm}/${paths.pfpa}`,
  },
];

const PFM = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpansion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="w-full bg-white">
{/* Hero Section */}
<section
  className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center"
  style={{ backgroundImage: "url('/PagesImages/pfm_hero.png')" }}
>
  <div
    className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 bg-black bg-opacity-10 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]"
  >
    <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
      Public Financial Management
    </h1>
    <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
      Public Financial Management
    </p>
  </div>
</section>

         {/* Title Under Image for Small Screens */}
         <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Public Financial Management
        </h1>
      </div>

      {/* Overview Section */}
      <section className="py-16 px-6 lg:px-2 max-w-5xl mx-auto text-left">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Overview</h2>
        <p className="text-xl text-gray-600 mb-6">Cornerstone of Economic Stability</p>
        <p className="text-lg text-gray-600 mb-6">
          Public financial management is at the heart of sound economic
          governance. It encompasses the processes, systems, and policies
          through which governments manage public resources. Strong PFM systems
          help ensure that public money is allocated effectively, spent
          efficiently, and managed with the highest level of accountability.
        </p>
        <p className="text-lg text-gray-600 mb-6">
          At Seismic, we understand that effective PFM is not just about
          managing finances but about driving national development, improving
          the quality of public services, and instilling public trust in
          government institutions. We are a global leader in Public Financial
          Management (PFM), dedicated to empowering governments and
          organizations to manage financial resources with transparency,
          accountability, and efficiency.
        </p>
        <p className="text-lg text-gray-600">
          With a strategic focus on aligning financial management practices with
          national economic goals and sustainable development objectives,
          Seismic Consulting Group has become a trusted partner for public and
          private institutions around the world. Our solutions are grounded in
          global best practices, enabling governments to achieve fiscal
          discipline, promote good governance, and drive economic prosperity.
        </p>
      </section>

      {/* Why Seismic Section */}
      <section className="py-16 px-4">
        <div className="w-full lg:max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center gap-8">
          <div className="lg:w-7/12 text-left">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              Why Seismic
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Seismic Consulting Group offers a comprehensive approach to PFM,
              focusing on sustainable, long-term solutions that go beyond
              technical implementation. We partner with governments to foster a
              culture of accountability, improve public service delivery, and
              build fiscal discipline.
            </p>
            <p className="text-lg text-gray-600">
              Our global competitiveness, innovative strategies, and deep
              expertise make Seismic an attractive choice for governments
              seeking to transform their financial systems and achieve
              sustainable economic growth.
            </p>
          </div>
          <img
            src="/PagesImages/why-seismic.jpg"
            alt="Why Seismic"
            className="shadow-lg"
            style={{ width: "600px", height: "410px", objectFit: "cover" }}
          />
        </div>
      </section>

{/* PFM Services Section */}
<section className="py-16 px-4 lg:px-2">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-gray-800 mb-8">PFM Services</h2>
    <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-8">
      <div className="lg:w-5/12 w-full flex justify-center lg:justify-start">
        <img
          src={
            expandedIndex !== null
              ? PFMServices[expandedIndex].image
              : "/PagesImages/pfm3.jpg"
          }
          alt="Service Focus"
          className="w-full max-w-[650px] h-[300px] sm:h-[500px] object-cover shadow-lg"
          style={{ transition: "none" }}
        />
      </div>

      <div className="lg:w-7/12 w-full flex justify-center">
        <div className="w-full max-w-[550px] text-left">
          {PFMServices.map((service, index) => (
            <div key={index} className="border-b border-[#F2E6F2] py-4">
              <h3
                onClick={() => toggleExpansion(index)}
                className="text-xl font-medium text-gray-800 flex items-center justify-between cursor-pointer"
              >
                {service.title}
                <span className="text-purple-800 text-2xl">
                  {expandedIndex === index ? "−" : "+"}
                </span>
              </h3>
              {expandedIndex === index && (
                <>
                  <p className="text-gray-700 mt-2 text-sm leading-relaxed">
                    {service.description}
                  </p>
                  <div className="mt-4">
                    <Link
                      to={service.link}
                      className="text-purple-800 font-semibold hover:underline text-sm"
                    >
                      Read More →
                    </Link>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</section>


      <PFMTrackRecord />

      {/* Digital Projects Introduction for Digital Finances */}
<div className="max-w-6xl mx-auto px-6 lg:px-12 mt-4">
  <div className="flex flex-col md:flex-row items-center gap-8">
    {/* Left Column: Image */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739801858/pexels-photo-14751274_tbq8al.jpg"
        alt="Digital Financial Projects"
        className="w-full rounded-lg shadow-md"
      />
    </div>
    {/* Right Column: Text Content */}
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        Empowering Financial Excellence with Technology
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        Our innovative digital financial solutions transform financial planning, enhance secure transactions, and drive efficiency across institutions. From data-driven financial tracking to seamless payment systems, our projects deliver modern solutions for a competitive future.
      </p>
      <Link
        to={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalFinancialServices}`}
        className="inline-flex items-center px-6 py-3 border border-purple-900 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
      >
        View Digital Projects
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  </div>
</div>

            {/*Public Finance Experts Section */}
            <section className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
  <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
    Our Public Finance Experts
  </h2>

  <div className="flex justify-center gap-16 flex-wrap">
    {[
    {
      name: "Prof. Sani Badayi",
      role: "Board Member,Expert Public Finance",
      imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739435925/bmxruumap7ykui61g8st.jpg",
    },
    {
      name: "Cletus Ogar",
      role: "Associate, Analytics, Data and Research",
      imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739437486/WhatsApp_Image_2025-02-13_at_10.04.21_vpnqph.jpg",
    },
    ].map((expert, index) => (
      <div key={index} className="shadow-lg rounded-lg overflow-hidden text-left bg-white w-[280px]">
        <img src={expert.imageUrl} alt={expert.name} className="w-full h-[300px] object-cover" />
        <div className="p-4">
          <h3 className="text-xl font-bold text-gray-900">{expert.name}</h3>
          <p className="text-gray-600 mt-1">{expert.role}</p>
        </div>
      </div>
    ))}
  </div>
</section>
    </div>
  );
};

export default PFM;

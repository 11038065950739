import React, { useState, useEffect } from "react";

const HeroSection = () => {
  const [currentTab, setCurrentTab] = useState("Digital Transformation");

  const tabContent = {
    "Digital Transformation": {
      heading: "Empowering Growth Through Digital Transformation",
      description:
        "Leveraging technology to transform industries, streamline processes, and foster continuous innovation for success in the digital age.",
      image:
        'url("https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733512898/pexels-bradleyhook-123335_ifo9vy.jpg")',
    },

    Healthcare: {
      heading: "Developing Advanced ERP Solutions for Healthcare",
      description:
        "Implementing robust ERP systems tailored for healthcare to enhance operational efficiency, ensure data accuracy, and streamline patient management.",
      image:
        'url("https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733844685/pexels-cottonbro-7580244_i5d760.jpg")',
    },

    "Education Reform": {
      heading: "Shaping the Future: Education Reform & Advisory",
      description:
        "Empowering educators and institutions with innovative strategies to transform education for a brighter, inclusive future.",
      image:
        'url("https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1738228348/iStock-803141126_f8oso9.jpg")',
    },

    "Monitoring and Evaluation": {
      heading: "Enhancing Monitoring and Evaluation for Effective Learning",
      description:
        "Monitoring and Evaluation (M&E) are critical components for assessing the effectiveness of programs and interventions.",
      image:
        'url("https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733515176/pexels-fauxels-3184639_hjpkle.jpg")',
    },
  };

  const { heading, description, image } = tabContent[currentTab];

  // Automatically transition between tabs every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTab((prevTab) => {
        const keys = Object.keys(tabContent);
        const currentIndex = keys.indexOf(prevTab);
        const nextIndex = (currentIndex + 1) % keys.length;
        return keys[nextIndex];
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-screen">
      <div
        className={`relative w-full h-full bg-cover bg-no-repeat flex items-center ${
          currentTab === "Digital Transformation" ? "bg-right sm:bg-center" : "bg-center"
        }`}
        style={{
          backgroundImage: image,
        }}
      >
        {/* Gradient Overlay with Darker Start on Mobile */}
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent xs:from-black/60 xs:via-black/30 xs:to-transparent"></div>

        {/* Content aligned to the left */}
        <div className="relative z-10 max-w-2xl text-white pl-6 md:pl-20 lg:pl-32">
          <h2 className="text-lg sm:text-xl uppercase font-bold mb-4 animate-fadeIn">
            {currentTab}
          </h2>
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6 animate-fadeIn">
            {heading}
          </h1>
          <p className="text-md sm:text-lg md:text-xl mb-8 animate-fadeIn">
            {description}
          </p>
        </div>
      </div>

      {/* Tab Section Positioned Slightly Above Bottom */}
      <div className="absolute bottom-10 left-0 w-full">
        <div className="tab-section flex justify-center text-white font-bold gap-8 lg:gap-32 text-sm lg:text-lg sm:text-center">
          {Object.keys(tabContent).map((tab, index) => (
            <span
              key={tab}
              onClick={() => setCurrentTab(tab)}
              className={`cursor-pointer pb-2 relative ${
                currentTab === tab ? "active-tab" : ""
              } ${
                currentTab === tab ||
                index ===
                  (Object.keys(tabContent).indexOf(currentTab) + 1) %
                    Object.keys(tabContent).length
                  ? "block"
                  : "hidden sm:inline"
              }`}
            >
              {tab}
              {currentTab === tab && (
                <span className="absolute bottom-[-3px] left-0 h-1 bg-purple-800 w-full sm:block transition-all duration-300"></span>
              )}
            </span>
          ))}
        </div>
      </div>

      {/* Additional Styles for fine-tuning */}
      <style>{`
        @media (max-width: 768px) {
          .bg-gradient-to-r {
            background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
          }

          .text-content {
            position: relative;
            z-index: 10;
            color: white;
            text-align: left;
            padding: 5% 8%;
          }
        }
      `}</style>
    </div>
  );
};

export default HeroSection;
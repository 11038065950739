import React from 'react';
import { Link } from 'react-router-dom';

const DigitalDriven = () => {
  return (
    <div className="flex flex-col w-full">

            {/* Hero Section */}
            <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div
          className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
        >
          <h1 className="text-3xl font-bold text-gray-800">
          Data-Driven Decision Making
          </h1>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Data-Driven Decision Making
        </h1>
      </div>

      {/* Service Description */}
      <section className="py-12 px-6 max-w-5xl mx-auto mt-12">
        <p className="text-gray-700 text-lg leading-relaxed mb-6">
          At Seismic Consulting Group, we understand that data is the lifeblood
          of effective digital transformation. Our expertise lies in harnessing
          this invaluable resource to empower our clients with actionable
          insights and strategic foresight. Through advanced big data analytics,
          predictive modeling, and machine learning, we enable organizations to
          make informed decisions that drive growth and innovation.
        </p>
        <p className="text-gray-700 text-lg leading-relaxed">
        For instance, in the retail industry, our clients utilize our predictive analytics solutions to personalize customer experiences, boost engagement, and optimize inventory management. By analyzing customer behavior and purchasing patterns, they tailor offerings to meet specific needs, resulting in increased customer satisfaction and loyalty. At Seismic Consulting Group, our commitment to data-driven decision- making transforms organizational data into strategic assets. We provide the tools and expertise necessary to unlock new opportunities, streamline operations, and propel businesses forward in the digital age.
        </p>
      </section>

      {/* Data-Driven Insights */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-2xl font-bold text-center mb-8">
            Data-Driven Insights
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Insight 1 */}
            <div className="bg-white p-6 rounded shadow">
              <h3 className="text-xl font-bold mb-2">
                Big Data and Predictive Analytics
              </h3>
              <p className="text-gray-600">
                We assist clients in leveraging vast datasets to identify
                patterns and forecast trends. By establishing robust data
                warehouses and implementing sophisticated machine learning
                models, we help organizations derive meaningful insights that
                inform strategy and enhance operational efficiency.
              </p>
            </div>

            {/* Insight 2 */}
            <div className="bg-white p-6 rounded shadow">
              <h3 className="text-xl font-bold mb-2">
                Real-Time Decision Making
              </h3>
              <p className="text-gray-600">
              In today's fast-paced market, timely decisions are crucial. Seismic develops systems capable of processing data in real-time, allowing clients—especially in sectors like finance, healthcare, and e-commerce—to respond swiftly to emerging opportunities and challenges. This agility enhances performance and maintains a competitive edge.
              </p>
            </div>

            {/* Insight 3 */}
            <div className="bg-white p-6 rounded shadow">
              <h3 className="text-xl font-bold mb-2">
                Data Governance and Quality Management
              </h3>
              <p className="text-gray-600">
              Ensuring data accuracy, compliance, and security is paramount. We implement structured data management practices and policies that safeguard data integrity. Our comprehensive approach to data governance minimizes risks and enhances the reliability of business intelligence, fostering trust in decision-making processes.
              </p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default DigitalDriven;

import React from "react";

export default function TeamPage() {
  // Sample team data (replace with your actual content)
  const teamMembers = [
    {
      name: "Dr. Daniel Ediri Oghojafor",
      role: "Managing Partner & Director",
    },
    {
      name: "Dr. Olusoji Adeniyi",
      role: "Senior Partner, Expert Education & Capacity Building",
    },
    {
      name: "Prof. Shuaibu Babawuro",
      role: "Senior Partner, Expert TVET",
    },
    {
      name: "Prof. Samuel Ibeheme",
      role: "Senior Partner, Expert Healthcare & Life Sciences",
    },
    {
      name: "Dr. Omotunde Oni",
      role: "Board Member, Expert Governance Reforms",
    },
    {
      name: "Tara McCourt",
      role: "Board Member, Expert Education Reform",
    },
    {
      name: "Prof. Sani Badayi",
      role: "Board Member, Expert Public Finance",
    },
    {
      name: "Prof. Okunlola Olaniyi James",
      role: "Board Member, Expert Monitoring & Evaluation",
    },
    // Additional members for "Load More" functionality
    {
      name: "Prof. Edirin Jeroh",
      role: "Board Member, Expert Independent Verification and Assurance",
    },
    {
      name: "Prof. Suleiman Alikans",
      role: "Board Member, Expert Independent Verification and Assurance",
    },
    {
      name: "Prof. Samuel Olobaniyi",
      role: "Board Member, Expert Mineral Exploration and Mining",
    },
    {
      name: "Nosa Odiase",
      role: "Associate Partner, Expert Change Management",
    },
    {
      name: "Promise Ikpuri",
      role: "Head of Operations",
    },
    {
      name: "Jibrin Haruna",
      role: "Team Lead, Digital Transformation & Innovation",
    },
    {
      name: "Oscar Ikeogu Prince",
      role: "Head of Procurement",
    },
    {
      name: "Onyey-Ezechukwu Wendy Nkechi",
      role: "Legal & HR Manager",
    },
    {
      name: "Samuels John",
      role: "Associate, Business Development",
    },
    {
      name: "Alfred Kobo",
      role: "Associate, Monitoring & Evaluation",
    },
    {
      name: "Joseph I. Emmanuel",
      role: "Associate, Research, Capacity Building and Training",
    },
    {
      name: "Ariyo Gabriel Olushola",
      role: "Design Specialist",
    },
    {
      name: "Osagioduwa Lucky Ogbomo",
      role: "Head of Accounting",
    },
    {
      name: "Chibuogwo Jessica",
      role: "Admin Officer",
    },
  ];

  // First 4 for custom layout (Managing and Senior Partners)
  const managingAndSeniorPartners = teamMembers.slice(0, 4);

  // Next 8 for custom layout (Department Heads / Board Members)
  const departmentHeads = teamMembers.slice(4, 12);

  // The remainder after the first 12 (remaining team members)
  const remainingMembers = teamMembers.slice(12);

  return (
    <div className="mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage: "url('')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-10">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Our Leadership &amp; People
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            At SEISMIC, our team is driven by a passion for excellence and a deep commitment
            to transforming challenges into opportunities. Our leadership and dedicated
            professionals are the pillars that drive innovative solutions for lasting change.
          </p>
        </div>
      </section>

      {/* Introductory Text */}
      <div className="max-w-5xl mx-auto px-6 lg:px-12 mt-12">
        <p className="text-gray-700 text-lg mb-4 leading-relaxed">
          Everyone at SEISMIC has experienced the challenges of the developing world firsthand.
          Our leadership, empowered by their personal experiences and professional expertise,
          drives our mission to create sustainable solutions that bring about lasting change.
        </p>
        <p className="text-gray-700 text-lg mb-12 leading-relaxed">
          United by a vision to transform adversity into opportunity, our team works relentlessly
          to empower communities through innovative, evidence-based strategies.
        </p>
      </div>

      {/* Leadership Section Layout */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12">
        {/* Managing and Senior Partners */}
        <div className="mb-8">
          <h2 className="text-3xl font-bold text-purple-900 mb-4">Managing & Senior Partners</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-8 justify-items-center">
            {managingAndSeniorPartners.map((member, index) => (
              <div
                key={index}
                className="bg-white overflow-hidden p-4"
                style={{
                  height: "auto",
                  maxWidth: "400px",
                  justifySelf: index === 0 || index === 2 ? "center" : "end", // Move first two closer together
                }}
              >
                <h2 className="text-xl font-bold text-gray-800 mb-1">{member.name}</h2>
                <p className="text-md font-semibold text-gray-600">{member.role}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Department Heads / Board Members */}
        <div className="mb-8">
          <h2 className="text-3xl font-bold text-purple-900 mb-4">Board Members</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {departmentHeads.map((member, index) => (
              <div key={index} className="bg-white overflow-hidden p-4">
                <h2 className="text-xl font-bold text-gray-800 mb-1">{member.name}</h2>
                <p className="text-md font-semibold text-gray-600">{member.role}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Remaining Members */}
        <div className="mb-8">
          <h2 className="text-3xl font-bold text-purple-900 mb-4">Department Heads</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {remainingMembers.map((member, index) => (
              <div key={index} className="bg-white overflow-hidden p-4">
                <h2 className="text-xl font-bold text-gray-800 mb-1">{member.name}</h2>
                <p className="text-md font-semibold text-gray-600">{member.role}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';

const projects = [
  {
    id: "project-one",
    title: "Independent Verification of Disbursement Link Indicators (DLI) of the ACE Project",
    description: "Seismic Consults and WAIFEM collaborated to independently verify performance and develop monitoring plans for the ACE Project across Ghana, Sierra Leone, and The Gambia.",
    projectTitle: "Independent Verification of Disbursement Link Indicators (DLI) of the ACE Project in Ghana, Sierra Leone, and The Gambia",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "Government of Ghana and the World Bank",
    year: 2015,
    objectives: [
      "Compiled a GIS database of beneficiaries and assets created.",
      "Conducted desk reviews of activities of beneficiaries.",
      "Performed data collection through field visits and reviewed performance assessment activities.",
      "Initiated stakeholder meetings to review performance assessment reports.",
      "Developed evaluation protocols and guidance.",
      "Created a Monitoring Plan with specific parameters for site visits, asset verification, and data collection.",
      "Drafted and submitted performance assessment reports.",
      "Submitted monthly progress reports.",
      "Implemented the Monitoring Plan through site visits, capturing and analyzing data accurately."
    ],
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738074899/WhatsApp_Image_2025-01-28_at_13.45.50_1_jdvaja.jpg",
  },
  {
    id: "project-two",
    title: "Independent Expenditure Verification of Grant Contract",
    description: "Seismic Consults, WAIFEM, and Ahmadu Bello University performed expenditure verification and reconciled accounting systems for grants in Ghana, Sierra Leone, and Gambia.",
    projectTitle: "Independent Expenditure Verification of Grant Contract for Ghana, Sierra Leone, and Gambia",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738750591/x0nyel91ky5lejjwzlxp.png",
    clientName: "United Nations Office for Project Services (UNOPS)",
    year: 2016,
    objectives: [
      "Performed expenditure verification and conducted reports on financed grants.",
      "Reconciled internal accounting and book-keeping systems per standard operating procedures."
    ],
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1740655879/medical-appointment-doctor-healthcare-40568_nfzsnr.jpg",
  },
  {
    id: "project-three",
    title: "Verification and Validation of Livelihoods and Resilience Activities",
    description: "Seismic Consults verified databases and validated agricultural inputs distributed to communities affected by insurgency in Yobe State.",
    projectTitle: "Independent Verification and Validation of Livelihoods and Resilience Activities across Insurgent-Affected Communities in Yobe State",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "World Bank- Yobe State Multi Sectoral Crisis Recovery Project",
    year: 2021,
    objectives: [
      "Verified the database obtained from Youth Employment and Social Support Operation (YESSO).",
      "Validated agricultural inputs and trading start-up kits distributed across 17 Local Governments.",
      "Conducted validation of overall project results against the PDO.",
      "Performed Value for Money Audits of disbursements and fund utilization."
    ],
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1740655517/pexels-photo-2406271_v6rczw.jpg",
  },
  {
    id: "project-four",
    title: "Verification and Validation of Basic Minimum Package of Health Services",
    description: "Seismic Consults assessed health facilities, service providers, and state health insurance operations in Namibia to identify implementation bottlenecks.",
    projectTitle: "Independent Verification and Validation of Basic Minimum Package of Health Services (BMPHS) Program in Namibia",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738750662/ochhmihi47wjrusjokwh.png",
    clientName: "USAID / Government of Namibia",
    year: 2019,
    objectives: [
      "Assessed selected health facilities and knowledge of trained service providers.",
      "Evaluated perceptions of beneficiaries and operations of the state health insurance agency.",
      "Proffered recommendations to the Government of Namibia.",
      "Supported qualitative and quantitative data collection, quality assurance, data analysis, and report writing."
    ],
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1740655738/pexels-photo-4546132_t2ibhi.jpg",
  },
];

const VerificationProjectsExecuted = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);

  // Auto-cycle through slides
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 5000); // Adjust duration for slide change

    return () => clearInterval(interval);
  }, []);

  const currentProject = projects[currentProjectIndex];

  return (
    <section className="py-16 px-4 lg:px-2 bg-[#F9F5FB]">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-800">Independent Verification Projects Executed</h2>
      </div>

      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-start gap-8">
        {/* Image on the Left */}
        <div className="lg:w-6/12 w-full transition-opacity duration-500 opacity-100">
          <img
            src={currentProject.image}
            alt="Project Image"
            className="w-full h-full object-cover shadow-lg"
            style={{ height: '450px' }}
          />
        </div>

        {/* Project Details on the Right */}
        <div className="lg:w-6/12 w-full flex flex-col">
          <div className="mb-4 pt-4 mt-4">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">{currentProject.title}</h3>
            <p className="text-lg text-gray-600 mb-3">{currentProject.description}</p>
          </div>

          {/* Project Card */}
          <div className="border border-[#F2E6F2] p-6 relative">
            <p className="text-lg font-semibold text-gray-800 mb-2">{currentProject.projectTitle}</p>
            <div className="flex items-center mb-4">
              <span className="text-gray-600 font-semibold mr-2">Client:</span>
              <img src={currentProject.clientImage} alt={currentProject.clientName} className="h-8" />
            </div>
            <p className="text-gray-600">
              <strong>Year:</strong> {currentProject.year}
            </p>
          </div>

          {/* Objectives Section */}
          <div className="mt-6">
            <button
              className="text-purple-900 font-bold text-lg mb-4 focus:outline-none"
              onClick={() => setExpanded(!expanded)}
            >
              Objectives
            </button>
            {expanded && (
              <ul className="list-disc list-inside text-gray-700 text-lg space-y-2">
                {currentProject.objectives.map((objective, idx) => (
                  <li key={idx}>{objective}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      {/* Slide Navigation Dots */}
      <div className="flex justify-center items-center gap-3 mt-6">
        {projects.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentProjectIndex(index)}
            className={`transition-all duration-300 ${
              index === currentProjectIndex ? 'bg-purple-800 w-8 h-3 rounded-lg' : 'bg-purple-300 w-4 h-4 rounded-full'
            }`}
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>
    </section>
  );
};

export default VerificationProjectsExecuted;

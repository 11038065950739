import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";

const ChangeManagementPage = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1736934916/pexels-lilartsy-1314410_km1db6.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Change Management and Strategy Consulting
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            We go beyond traditional change management techniques, offering
            innovative solutions that address challenges and opportunities faced
            by each client
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center px-2">
          Change Management and Strategy Consulting
        </h1>
      </div>

      {/* Introduction Section */}
      <section className="max-w-4xl mx-auto py-16 px-6 lg:px-12 text-center">
        <p className="text-lg text-gray-600 leading-relaxed">
          Change Management & Strategy Consulting guiding transformational
          change for lasting success at Seismic Consulting, we understand that
          trans formation is essential for organizations to remain competitive
          and relevant in today's rapidly evolving landscape. Our Change
          Management & Strategy Consulting services are designed to guide
          organizations through the complexities of change, helping them align
          their strategic goals with actionable plans that deliver measurable
          results.
        </p>
        <p className="text-lg text-gray-600 leading-relaxed mt-4">
          We go beyond traditional change management techniques, offering
          innovative, tailored solutions that address the unique challenges and
          opportunities faced by each client. From fostering cultural
          transformation to driving digital innovation, we empower organizations
          to embrace change with confidence, ensuring that growth is sustainable
          and impactful.
        </p>
      </section>

      {/* Approach Section */}
      <section className="py-16">
        <div className="max-w-6xl mx-auto px-6 lg:px-0">
          <h2 className="text-3xl font-bold text-center mb-4">
            Seismic's Approach to Reducing Poverty
          </h2>
          <p className="text-center text-gray-600 mb-8 max-w-3xl mx-auto text-lg">
            Seismic Consulting employs a comprehensive, structured approach to
            change management and strategy consulting, ensuring alignment with
            global best practices and client-specific objectives:
          </p>

          <div className="grid md:grid-cols-3 gap-6 mb-8">
            {[
              {
                title: "Adaptive Strategy Development",
                description:
                  "In an era defined by constant evolution in market dynamics, regulatory landscapes, and technological innovation, Adaptive Strategy Development is central to Seismic's approach to Change Management & Strategy Consulting.",
                image:
                  "https://res.cloudinary.com/dxhx45ing/image/upload/v1736871131/4_pnllia.jpg",
                link: `/consulting-services/${paths.changeManagementPage}/${paths.adaptiveStrategyDevelopment}`,
              },
              {
                title: "Cultural Transformation",
                description:
                  "Through Cultural Transformation, Seismic helps organizations realign their culture to better support their strategic objectives, fostering an environment of trust, collaboration, and shared purpose.",
                image:
                  "https://res.cloudinary.com/dxhx45ing/image/upload/v1736932993/close-up-interracial-people-holding-hands_1_iw7zr7.jpg",
                link: `/consulting-services/${paths.changeManagementPage}/${paths.culturalTransformation}`,
              },
              {
                title: "Digital Transformation",
                description:
                  "We empower organizations to embrace digital innovation, streamline operations, and enhance customer experiences. This transformative process aligns technology with business objectives, fostering agility and sustained growth.",
                image:
                  "https://res.cloudinary.com/dxhx45ing/image/upload/v1736932864/5_1_ldv5s1.jpg",
                link: `/consulting-services/${paths.changeManagementPage}/${paths.digitalTransformationCM}`,
              },
            ].map((item, index) => (
              <Link key={index} to={item.link} className="block text-left">
                <img
                  src={item.image}
                  alt={item.title}
                  className="mb-4"
                  style={{
                    width: "397px",
                    height: "300px",
                    objectFit: "cover",
                  }}
                />
                <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                <p className="text-gray-600 text-sm mb-2">{item.description}</p>
                <span className="text-purple-900 font-bold text-sm">
                  Read More
                </span>
              </Link>
            ))}
          </div>

          <div className="grid md:grid-cols-2 gap-6">
            {[
              {
                title: "Change Management Frameworks",
                description:
                  "Through robust Change Management Frameworks, we help businesses navigate transitions seamlessly, ensuring that people, processes, and systems adapt cohesively to new realities.",
                image:
                  "https://res.cloudinary.com/dxhx45ing/image/upload/v1736933098/9_1_lgkpzt.jpg",
                link: `/consulting-services/${paths.changeManagementPage}/${paths.changeManagementFrameworks}`,
              },
              {
                title: "Capacity Building for Change Leaders",
                description:
                  "Through capacity building for change leaders, Seismic equips leaders with the tools, knowledge, and confidence needed to inspire, guide, and sustain change within their organizations.",
                image:
                  "https://res.cloudinary.com/dxhx45ing/image/upload/v1736933370/8_1_q1oige.jpg",
                link: `/consulting-services/${paths.changeManagementPage}/${paths.capacityBuildingForChangeLeaders}`,
              },
            ].map((item, index) => (
              <Link key={index} to={item.link} className="block text-left">
                <img
                  src={item.image}
                  alt={item.title}
                  className="mb-4"
                  style={{
                    width: "608px",
                    height: "300px",
                    objectFit: "cover",
                  }}
                />
                <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                <p className="text-gray-600 text-sm mb-2">{item.description}</p>
                <span className="text-purple-900 font-bold text-sm">
                  Read More
                </span>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Target Audience Section */}
      <section className="bg-gradient-to-b from-[#2D002D] to-[#600060] text-white py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-12">
          <h2 className="text-3xl font-bold text-center mb-8">
            Target Audience
          </h2>
          <p className="text-center text-gray-300 mb-16 leading-relaxed max-w-3xl mx-auto">
            Our Change Management & Strategy Consulting services cater to a wide
            range of organizations and stakeholders.
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
                description:
                  "Government Institutions Ministries and agencies undergoing policy reforms, digital transformations, or operational restructuring.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736765626/person_7498074_e8j9wb.png",
                description:
                  "Corporate Entities Companies seeking to align their operations with strategic goals, enhance efficiency, or expand into new markets.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png",
                description:
                  "Development Organizations and NGOs International agencies implementing large-scale projects requiring robust change management and strategy execution.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736942834/learning_7243265_exhpfw.png",
                description:
                  "Educational Institutions and Research Bodies Universities and research centers aiming to modernize their systems or introduce innovative programs.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png",
                description:
                  "Community-Based Organizations Local organizations navigating shifts in funding, operations, or stakeholder engagement.",
              },
              {
                icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736943271/team_7498209_fmksry.png",
                description:
                  "Multinational Organizations Businesses operating in diverse markets and facing the complexities of cross-cultural transformations.",
              },
            ].map((item, index) => (
              <div key={index} className="text-left">
                <img src={item.icon} alt="" className="w-12 h-12 mb-4" />
                <p className="text-base text-gray-200 leading-relaxed max-w-xl">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>


  {/* Result and impact */}
      <section className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-12">
          <h2 className="text-3xl font-bold text-center mb-6">
            Results and Impact
          </h2>
          <p className="text-center text-gray-600 mb-12 leading-relaxed max-w-4xl mx-auto">
            Seismic Consulting’s Change Management & Strategy Consulting
            services deliver tangible, sustainable outcomes that drive long-term
            success.
          </p>

          {/* First Grid with 3 Columns */}
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title:
                  "Successful Implementation of Transformational Initiatives",
                description:
                  "Organizations achieve their strategic goals with minimized disruption. Projects are delivered on time and within budget.",
              },
              {
                title: "Enhanced Organizational Agility",
                description:
                  "Flexible strategies enable organizations to adapt quickly to changing environments. Improved decision-making supported by robust frameworks and data-driven insights.",
              },
              {
                title: "Improved Employee Engagement and Productivity",
                description:
                  "Cultural alignment fosters a motivated workforce invested in organizational success. Leaders and employees are better equipped to navigate and embrace change.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="border border-gray-200 p-6 text-center"
              >
                <h3 className="text-lg font-medium mb-4">{item.title}</h3>
                <p className="text-gray-600 text-sm leading-relaxed">
                  {item.description}
                </p>
              </div>
            ))}
          </div>

          {/* Second Row: Two Boxes Centered Beside Each Other on Medium Screens and Above, Stacked on Smaller Screens */}
          <div className="mt-12 flex flex-col md:flex-row justify-center items-center md:space-x-8 space-y-8 md:space-y-0">
            <div className="border border-gray-200 p-6 text-center flex-none w-80">
              <h3 className="text-lg font-medium mb-4">
                Increased Innovation and Competitiveness
              </h3>
              <p className="text-gray-600 text-sm leading-relaxed">
                Digital transformation initiatives enable organizations to lead
                in their industries. Streamlined operations enhance efficiency
                and customer satisfaction.
              </p>
            </div>
            <div className="border border-gray-200 p-6 text-center flex-none w-80">
              <h3 className="text-lg font-medium mb-4">
                Strengthened Leadership and Governance
              </h3>
              <p className="text-gray-600 text-sm leading-relaxed">
                Leaders gain the skills to drive change, inspire teams, and
                sustain growth. Governance frameworks ensure accountability and
                transparency.
              </p>
            </div>
          </div>

          
        </div>
      </section>
      {/* Why Choose Seismic Consulting Section */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736941030/pexels-fauxels-3184416_xyek5p.jpg"
              alt="Why Choose Seismic Consulting"
              className="w-full"
            />
          </div>
          <div>
            <h2 className="text-2xl font-medium mb-4">
              Why Choose Seismic Consulting?
            </h2>
            <p className="text-gray-600 text-xl leading-relaxed mb-4">
              At Seismic Consulting, we bring a unique combination of expertise,
              innovation, and commitment to our Change Management & Strategy
              Consulting services. With a proven track record of guiding
              organizations through complex transformations, we are dedicated to
              delivering impactful and enduring results.
            </p>
            <p className="text-gray-600 text-xl leading-relaxed">
              Our approach ensures that every initiative is not just a momentary
              success but a foundation for sustained growth, innovation, and
              resilience. When you partner with Seismic, you’re choosing a team
              that doesn’t just manage change—we empower you to thrive in it.
            </p>
          </div>
        </div>
      </section>

{/* Change Management Experts Section */}
<section className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
  <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
    Our Change Management Experts
  </h2>

  <div className="flex justify-center gap-16 flex-wrap">
    {[
      {
        name: "Nosa Odiase",
        role: "Associate Partner, Change Management",
        imageUrl:
          "https://res.cloudinary.com/dxhx45ing/image/upload/v1739452663/WhatsApp_Image_2025-02-13_at_14.17.23_tmp655.jpg",
      },
      {
        name: "Oscar Ikeogu Prince",
        role: "Head of Procurement",
        imageUrl: "https://res.cloudinary.com/dxhx45ing/image/upload/v1739447845/WhatsApp_Image_2025-02-13_at_12.54.58_ued3we.jpg",
      },
    ].map((expert, index) => (
      <div key={index} className="shadow-lg rounded-lg overflow-hidden text-left bg-white w-[280px]">
        <img src={expert.imageUrl} alt={expert.name} className="w-full h-[300px] object-cover" />
        <div className="p-4">
          <h3 className="text-xl font-bold text-gray-900">{expert.name}</h3>
          <p className="text-gray-600 mt-1">{expert.role}</p>
        </div>
      </div>
    ))}
  </div>
</section>



    </div>
  );
};

export default ChangeManagementPage;

import React, { useState, useEffect } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import {
  FaIndustry,
  FaBriefcase,
  FaLightbulb,
  FaInfoCircle,
  FaUserFriends,
  FaCaretDown,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const location = useLocation();

  const logoWhiteBackground = "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745709/scg_logo_dtunha.png";
  const logoTransparentBackground = "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/scg_logo_white_rh2lwt.png";

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  const isLandingPage = location.pathname === "/";

  // **Menu Items**
  const consultingServices = [
    { name: "Education Reform and Advisory", path: "/consulting-services/education-reform" },
    { name: "Digital Transformation", path: "/consulting-services/digital-transformation" },
    { name: "Social Protection", path: "/consulting-services/social-protection" },
    { name: "Public Financial Management", path: "/consulting-services/public-financial-management" },
    { name: "Governance Reforms", path: "/consulting-services/governance-reform" },
    { name: "Change Management", path: "/consulting-services/change-management-page" },
    { name: "Monitoring and Evaluation, Independent Verification and Assurance", path: "/consulting-services/independent-verification" },
    { name: "Mineral Exploration & Mining Investment Facilitation", path: "/consulting-services/mineral-exploration-and-mining-consulting-service" },
    { name: "Capacity Building", path: "/consulting-services/capacity-building" },
    { name: "Healthcare Consulting", path: "/consulting-services/healthcare-consulting" },
  ];

  const industries = [
    { name: "Healthcare and Life Sciences", path: "/industries/healthcare-and-life-sciences" },
    { name: "Renewable Energy", path: "/industries/renewable-energy" },
    { name: "Education", path: "/industries/education" },
    { name: "Mining", path: "/industries/mining" },
    { name: "Financial Services", path: "/industries/financial-services" },
    { name: "Agriculture and Agribusiness", path: "/industries/agriculture-and-agribusiness" },
    { name: "Technology and Telecommunications", path: "/industries/technology-and-telecommunications" },
    { name: "Manufacturing and Industrial Engineering", path: "/industries/manufacturing-industry" },
    { name: "Transportation and Logistics", path: "/industries/transportation-and-logistics" },
    { name: "Audit and Assurance", path: "/industries/audit-and-assurance" },
  ];

  const insights = [
    {
      name: "Independent Verification Agents (IVA): Bridging the Gap Between Goals",
      path: "/insights/iva-insight",
    },
    {
      name: "Crisis Communication in Consulting: Turning Challenges into Opportunities",
      path: "/insights/crisis-communication",
    },
    {
      name: "Driving Sustainable Development Through Circular Economy Models",
      path: "/insights/circular-economy",
    },
    {
      name: "Enhancing Governance Through Blockchain Technology (Jan 28, 2025)",
      path: "/insights/blockchain-governance",
    },
    {
      name: "Sustainable Urban Development Through Smart City Solutions",
      path: "/insights/smart-city-solutions",
    },
    {
      name: "Empowering Communities Through Digital Financial Inclusion (Jan 30, 2025)",
      path: "/insights/digital-financial-inclusion",
    },
  ];

  // New About Us Dropdown Items
  const aboutItems = [
    { name: "What We Believe", path: "/about/what-we-believe" },
    { name: "What We Do", path: "/about/what-we-do" },
    { name: "Our Leadership and People", path: "/about/the-team" },
    { name: "Proposal Requests", path: "/about/request-for-proposal" },
  ];

  const handleMouseEnter = (menu) => setHoveredMenu(menu);
  const handleMouseLeave = () => setHoveredMenu(null);

  return (
    <div className="seismic_container">
      {/* Navbar */}
      <nav
        className={`fixed top-0 left-0 w-full z-20 p-8 transition-all duration-300 flex justify-between items-center ${
          isLandingPage && !isScrolled && !isHovered
            ? "bg-transparent text-white"
            : "bg-white text-black"
        }`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex items-center">
          <MenuIcon
            onClick={toggleSidebar}
            className={`h-8 w-8 cursor-pointer ${
              isLandingPage && !isScrolled && !isHovered
                ? "text-red"
                : "text-black"
            }`}
          />

          {/* Always use Link for logo */}
          <Link to="/">
            <img
              src={
                isLandingPage && !isScrolled && !isHovered
                  ? logoTransparentBackground
                  : logoWhiteBackground
              }
              alt="SCG Logo"
              className="ml-4 h-12"
            />
          </Link>
        </div>

        {/* Show navbar links only on large screens */}
        <div className="hidden lg:flex gap-12 pr-20 animate__animated animate__fadeIn">
          {/* Industries Link with Dropdown */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter("industries")}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex items-center cursor-pointer">
              <Link
                to="/industries"
                className="font-bold nav-link"
                onClick={() => setHoveredMenu(null)}
              >
                Industries
              </Link>
              <FaCaretDown
                className="ml-1 text-purple-800"
                style={{ marginTop: "-3px", marginLeft: "3px" }}
              />
            </div>
            {hoveredMenu === "industries" && (
              <div
                className="absolute top-full bg-white shadow-lg py-8 px-8 z-50"
                style={{
                  left: "-30px",
                  width: "900px",
                  minHeight: "380px",
                  borderBottom: "5px solid #6A0DAD",
                }}
              >
                <h3 className="text-lg font-bold mb-4 text-gray-900">
                  INDUSTRIES
                </h3>
                <div className="grid grid-cols-2 gap-8">
                  {industries.map((item, index) => (
                    <Link
                      key={index}
                      to={item.path}
                      className="block text-gray-700 font-semibold hover:text-purple-800 transition-colors"
                      onClick={() => setHoveredMenu(null)}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Consulting Services Link with Dropdown */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter("consulting-services")}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex items-center cursor-pointer">
              <Link
                to="/consulting-services"
                className="font-bold nav-link"
                onClick={() => setHoveredMenu(null)}
              >
                Consulting Services
              </Link>
              <FaCaretDown
                className="ml-1 text-purple-800"
                style={{ marginTop: "-3px", marginLeft: "3px" }}
              />
            </div>
            {hoveredMenu === "consulting-services" && (
              <div
                className="absolute bg-white shadow-lg py-8 px-8 z-50"
                style={{
                  top: "calc(100% - 5px)",
                  left: "-150px",
                  width: "900px",
                  minHeight: "350px",
                  borderBottom: "5px solid #6A0DAD",
                }}
              >
                <h3 className="text-lg font-bold mb-4 text-gray-900">
                  CONSULTING SERVICES
                </h3>
                <div className="grid grid-cols-2 gap-8">
                  {consultingServices.map((item, index) => (
                    <Link
                      key={index}
                      to={item.path}
                      className="block text-gray-700 font-semibold hover:text-purple-800 transition-colors"
                      onClick={() => setHoveredMenu(null)}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Insights Link with Dropdown */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter("insights")}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex items-center cursor-pointer">
              <Link
                to="/insights"
                className="font-bold nav-link"
                onClick={() => setHoveredMenu(null)}
              >
                Insights
              </Link>
              <FaCaretDown
                className="ml-1 text-purple-800"
                style={{ marginTop: "-3px", marginLeft: "3px" }}
              />
            </div>
            {hoveredMenu === "insights" && (
              <div
                className="absolute bg-white shadow-lg py-8 px-8 z-50"
                style={{
                  top: "calc(100% - 5px)",
                  left: "-280px",
                  width: "700px",
                  minHeight: "270px",
                  borderBottom: "5px solid #6A0DAD",
                }}
              >
                <h3 className="text-lg font-bold mb-4 text-gray-900">
                  INSIGHTS
                </h3>
                <div className="grid grid-cols-2 gap-8">
                  {insights.map((item, index) => (
                    <Link
                      key={index}
                      to={item.path}
                      className="block text-gray-700 font-semibold hover:text-purple-800 transition-colors"
                      onClick={() => setHoveredMenu(null)}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* About Us Link with Dropdown */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter("about")}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex items-center cursor-pointer">
              <Link
                to="/about"
                className="font-bold nav-link"
                onClick={() => setHoveredMenu(null)}
              >
                About Us
              </Link>
              <FaCaretDown
                className="ml-1 text-purple-800"
                style={{ marginTop: "-3px", marginLeft: "3px" }}
              />
            </div>
            {hoveredMenu === "about" && (
              <div
                className="absolute bg-white shadow-lg py-8 px-8 z-50"
                style={{
                  top: "calc(100% - 5px)",
                  left: "-150px",
                  width: "500px",
                  minHeight: "150px",
                  borderBottom: "5px solid #6A0DAD",
                }}
              >
                <h3 className="text-lg font-bold mb-4 text-gray-900">
                  ABOUT US
                </h3>
                <div className="grid grid-cols-2 gap-8">
                  {aboutItems.map((item, index) => (
                    <Link
                      key={index}
                      to={item.path}
                      className="block text-gray-700 font-semibold hover:text-purple-800 transition-colors"
                      onClick={() => setHoveredMenu(null)}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Careers */}
          <Link to="/careers" className="font-bold nav-link">
            Careers
          </Link>
        </div>
      </nav>

      {/* Divider Line */}
      <div
        style={{
          position: "fixed",
          top: "100px",
          left: 0,
          width: "100%",
          borderBottom:
            isHovered || isScrolled
              ? "1px solid rgba(0, 0, 0, 0.2)"
              : "1px solid rgba(224, 224, 224, 0.2)",
          zIndex: 19,
          transition: "border-color 0.3s ease",
        }}
      ></div>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-80 bg-white transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 z-30 shadow-xl`}
      >
        {/* Sidebar Header with Close Icon */}
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <img src={logoWhiteBackground} alt="SCG Logo" className="h-10 w-auto" />
          <XIcon className="h-6 w-6 cursor-pointer text-gray-900" onClick={toggleSidebar} />
        </div>

        {/* Sidebar Menu */}
        <ul className="space-y-4 px-6 text-base font-medium">
          <li className="flex items-center py-2">
            <FaIndustry className="h-5 w-5 mr-2 text-gray-900" />
            <Link
              to="/industries"
              className="text-gray-700 hover:text-purple-900 transition duration-200 font-bold"
              onClick={toggleSidebar}
            >
              Industries
            </Link>
          </li>
          <li className="flex items-center py-2">
            <FaBriefcase className="h-5 w-5 mr-2 text-gray-900" />
            <Link
              to="/consulting-services"
              className="text-gray-700 hover:text-purple-900 transition duration-200 font-bold"
              onClick={toggleSidebar}
            >
              Consulting Services
            </Link>
          </li>
          <li className="flex items-center py-2">
            <FaLightbulb className="h-5 w-5 mr-2 text-gray-900" />
            <Link
              to="/insights"
              className="text-gray-700 hover:text-purple-900 transition duration-200 font-bold"
              onClick={toggleSidebar}
            >
              Insights
            </Link>
          </li>
          <li className="flex items-center py-2">
            <FaInfoCircle className="h-5 w-5 mr-2 text-gray-900" />
            <Link
              to="/about"
              className="text-gray-700 hover:text-purple-900 transition duration-200 font-bold"
              onClick={toggleSidebar}
            >
              About Us
            </Link>
          </li>
          <li className="flex items-center py-2">
            <FaUserFriends className="h-5 w-5 mr-2 text-gray-900" />
            <Link
              to="/careers"
              className="text-gray-700 hover:text-purple-900 transition duration-200 font-bold"
              onClick={toggleSidebar}
            >
              Careers
            </Link>
          </li>
        </ul>

        <hr className="my-6 border-t border-gray-300" />
        <ul className="space-y-4 px-6 text-gray-700 text-sm font-medium mb-6">
          <li>
            <Link to="/contact" onClick={toggleSidebar}>Contact Us</Link>
          </li>
        </ul>
      </div>

      {/* Overlay on sidebar open */}
      {isSidebarOpen && (
        <div onClick={toggleSidebar} className="fixed inset-0 bg-black opacity-50 z-20"></div>
      )}

      {/* Hover underline effect */}
      <style>{`
        .nav-link {
          position: relative;
          display: inline-block;
          padding-bottom: 5px;
          text-decoration: none;
          color: inherit;
        }

        .nav-link::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: black;
          bottom: 0;
          left: 0;
          transform: scaleX(0);
          transition: transform 0.3s ease-out;
        }

        .nav-link:hover::after {
          transform: scaleX(1);
        }

        @media (max-width: 768px) {
          nav div.animate__fadeIn {
            display: none;
          }
          nav img {
            height: 40px;
          }
          div[style*="position: fixed"] {
            top: 88px;
            border-bottom: 1px solid rgba(224, 224, 224, 0.2);
          }
        }
      `}</style>
    </div>
  );
};

export default Navbar;

// src/pages/Education.jsx
import React from "react";

const DigitalEducation = () => {
  return (
    <section className="bg-gray-50">
{/* Hero Section */}
<div
  className="relative w-full h-[500px] sm:h-[600px] bg-black bg-cover bg-center flex items-center justify-center"
  style={{
    backgroundImage: "url('')", // No image provided, fallback to bg-black
  }}
>
  <div className="absolute inset-0 bg-gradient-to-r from-purple-900 to-indigo-900 opacity-75"></div>
  <div className="relative z-10 text-center px-4">
    <h1 className="text-4xl sm:text-5xl font-extrabold text-white tracking-wide drop-shadow-lg">
      Education
    </h1>
    <p className="mt-4 text-xl sm:text-2xl text-gray-200 max-w-2xl mx-auto drop-shadow">
      Transforming educational landscapes with data-driven insights, innovative solutions, and a commitment to inclusive growth.
    </p>
  </div>
</div>


      {/* Introduction */}
      <div className="max-w-6xl mx-auto px-4 py-16 text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-6">
          Our Projects in Education
        </h2>
        <p className="text-gray-700 text-lg max-w-3xl mx-auto leading-relaxed">
          We tackle the most pressing challenges in the education sector—from bridging the gender gap to modernizing outdated infrastructure. Explore our comprehensive initiatives designed to uplift learning environments and foster sustainable, long-term impact for students and educators alike.
        </p>
      </div>

      {/* Initiatives */}
      <div className="max-w-6xl mx-auto px-4 pb-16 space-y-16">

        {/* AGILE Project */}
        <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-indigo-800 mb-4">
            Adolescent Girls Initiative for Learning and Empowerment (AGILE) Project
          </h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Overview</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
              The AGILE Project is a World Bank-supported initiative implemented by the Federal Ministry of Education to improve secondary education opportunities for Nigerian girls aged 10 to 20. By addressing socio-cultural, financial, and infrastructural barriers, AGILE boosts enrollment, retention, and empowerment through upgraded facilities, financial incentives, and gender-inclusive policies.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Use Cases</h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-2">
              <li><strong>Educational Access & Equity</strong> – Expands secondary school opportunities for girls in underserved areas.</li>
              <li><strong>Financial Assistance & Scholarships</strong> – Provides grants and stipends to reduce financial barriers.</li>
              <li><strong>Infrastructure Development</strong> – Renovates and builds safe, girl-friendly schools with modern facilities.</li>
              <li><strong>Community & Parental Engagement</strong> – Partners with families and leaders to promote girls’ education.</li>
              <li><strong>Empowerment & Skills Training</strong> – Integrates digital literacy and vocational training for future opportunities.</li>
              <li><strong>Policy & Advocacy</strong> – Supports government initiatives that strengthen gender-inclusive education systems.</li>
            </ul>
          </div>
        </div>
        {/* IDEAS Project */}
        <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-indigo-800 mb-4">
            Innovation Development and Effectiveness in the Acquisition of Skills (IDEAS) Project
          </h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Overview</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
              The IDEAS Project is a World Bank-supported initiative implemented by the Federal Government of Nigeria through the Federal Ministry of Education. It aims to enhance skills acquisition and strengthen Technical and Vocational Education and Training (TVET) nationwide. By aligning vocational education with labor market demands, the project fosters innovation, bridges skills gaps, and improves training facilities. Through industry partnerships and private sector investment, IDEAS boosts workforce employability and equips individuals with in-demand skills.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Use Cases</h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-2">
              <li><strong>TVET Institutions & Vocational Schools</strong> – Build capacity to offer industry-relevant skills training.</li>
              <li><strong>Government Agencies & Policymakers</strong> – Enhance policy frameworks and curricula for vocational education.</li>
              <li><strong>Private Sector & Industries</strong> – Encourage partnerships and investments in skill-development programs.</li>
              <li><strong>Youth & Job Seekers</strong> – Acquire in-demand technical skills for improved employment opportunities.</li>
              <li><strong>Educational & Training Bodies</strong> – Adopt modern, competency-based training aligned with global standards.</li>
            </ul>
          </div>
        </div>

        {/* Annual School Census */}
        <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-indigo-800 mb-4">
            Annual School Census (ASC) Data Collection Tool
          </h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Overview</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
              The ASC Data Collection Tool is a digital solution designed to streamline educational data gathering in Nigeria. It serves as a centralized system for collecting, analyzing, and managing key school statistics, enabling stakeholders to make data-driven decisions for improving the education sector. Developed to support the Federal Ministry of Education, state governments, and local authorities, ASC reduces manual errors, expedites reporting, and provides real-time insights.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Use Cases</h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-2">
              <li><strong>Federal & State Ministries</strong> – Enables efficient data collection and policy planning.</li>
              <li><strong>Schools & Institutions</strong> – Facilitates seamless reporting of enrollment, facilities, and performance metrics.</li>
              <li><strong>Researchers & Analysts</strong> – Provides reliable data for research, funding, and planning.</li>
              <li><strong>Development Partners</strong> – Monitors education trends and assesses intervention impacts.</li>
              <li><strong>Government & Policymakers</strong> – Informs resource allocation and policy reforms.</li>
            </ul>
          </div>
        </div>

        {/* National Board for Technical Education */}
        <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-indigo-800 mb-4">
            National Board for Technical Education (NBTE)
          </h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Overview</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
              NBTE is a regulatory agency under the Federal Ministry of Education tasked with overseeing TVET institutions in Nigeria. Established in 1977, NBTE works to develop a skilled workforce that drives national development by ensuring technical education aligns with industry needs, strengthening vocational training, and promoting innovation and entrepreneurship.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Use Cases</h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-2">
              <li><strong>Accreditation & Quality Assurance</strong> – Regulates and accredits institutions to maintain high standards.</li>
              <li><strong>Curriculum Development</strong> – Designs and updates TVET curricula for industry relevance.</li>
              <li><strong>Manpower Development</strong> – Trains professionals in key sectors like engineering, ICT, and agriculture.</li>
              <li><strong>Policy Implementation</strong> – Advises on TVET policies, infrastructure, and funding.</li>
              <li><strong>Industry Collaboration</strong> – Aligns technical education with labor market demands and entrepreneurial skills.</li>
              <li><strong>Skills Acquisition Programs</strong> – Supports vocational training for youth employment and economic sustainability.</li>
            </ul>
          </div>
        </div>




                {/* AI-News */}
                <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-indigo-800 mb-4">AI-News</h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Overview</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
              AI-News is an AI-powered news aggregation and summarization platform that reimagines how users consume information. Leveraging natural language processing and machine learning, it scans multiple trusted sources, extracts key insights, and generates concise, easy-to-read summaries. Personalized news feeds, fact-checking, sentiment analysis, and multi-language support make news consumption more efficient, reliable, and engaging.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Use Cases</h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-2">
              <li><strong>Busy Professionals</strong> – Stay informed with quick, AI-generated news digests.</li>
              <li><strong>Journalists & Researchers</strong> – Analyze news trends and cross-check information from multiple sources.</li>
              <li><strong>Students & Educators</strong> – Access concise summaries for academic research and discussions.</li>
              <li><strong>Government & Policymakers</strong> – Receive fact-checked news insights to inform policy decisions.</li>
              <li><strong>General News Consumers</strong> – Enjoy personalized, bias-detected news for a well-rounded perspective.</li>
            </ul>
          </div>
        </div>

                {/* AI Curriculum Builder */}
                <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-indigo-800 mb-4">AI Curriculum Builder</h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Overview</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
              AI Curriculum Builder is an AI-driven educational platform designed to help teachers, schools, and education ministries generate schemes of work, lesson plans, and notes aligned with Nigerian academic standards. It automates curriculum development, ensuring quality, consistency, and cultural relevance while reducing educator workload. The platform integrates with learning management systems and supports personalized teaching strategies.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Use Cases</h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-2">
              <li><strong>Teachers & Tutors</strong> – Quickly generate standardized yet customized lesson plans.</li>
              <li><strong>Schools & Administrators</strong> – Ensure curriculum compliance with national education policies.</li>
              <li><strong>Education Ministries & Agencies</strong> – Streamline curriculum updates and implementation.</li>
              <li><strong>EdTech Platforms</strong> – Integrate AI-driven content planning into e-learning ecosystems.</li>
              <li><strong>Students & Parents</strong> – Access structured learning materials for improved education quality.</li>
            </ul>
          </div>
        </div>


      </div>
    </section>
  );
};

export default DigitalEducation;

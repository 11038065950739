import React from 'react';
import { paths } from '../constants/paths'; // Import the paths object

const ContactSection = () => {
  return (
    <section
      className="relative h-[5in] flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: 'url(https://res.cloudinary.com/dxhx45ing/image/upload/v1738160881/WhatsApp_Image_2025-01-28_at_13.13.45_2_mesbvb.jpg)' }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dark overlay */}

      <div className="relative z-10 text-center text-white">
        <div className="max-w-3xl mx-auto"> {/* Container for centering the text */}
          {/* Heading */}
          <h2 className="text-4xl md:text-5xl font-bold mb-4 text-center">
            Ready to make a difference?
          </h2>

          {/* Subheading */}
          <p className="text-lg md:text-xl mb-6 text-center">
            Whether you're looking to partner, innovate, or stay connected, there are many ways to engage with Seismic and be part of our journey toward transformative success.
          </p>

          {/* CTA Button Wrapper */}
          <div className="flex justify-center">
            {/* CTA Button */}
            <a
              href={`/${paths.contact}`} // Update the href to navigate to the Contact page
              className="inline-flex items-center px-6 py-3 border border-white text-white font-semibold rounded-lg hover:bg-white hover:text-black transition"
            >
              Contact Us
              <svg
                className="w-5 h-5 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

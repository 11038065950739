import React, { useState } from "react";

const SmartLogistics = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const expertiseData = [
    {
      title: "Optimizing Resource Utilization",
      description:
        "Effective resource management is foundational to a well-functioning supply chain. With precise and continuous tracking, companies can maximize their resources, minimize delays, and maintain seamless supply chain continuity. Our solutions leverage IoT-enabled tracking systems to:",
      points: [
        "Provide Real-Time Visibility: Track inventory levels, shipment locations, and warehouse operations in real-time, enabling swift responses to potential bottlenecks or disruptions.",
        "Reduce Waste and Idle Inventory: Monitor stock movement to optimize inventory turnover and minimize excess or underutilized resources.",
        "Enhance Asset Efficiency: Implement sensors and connected devices to monitor the condition and performance of vehicles, equipment, and storage facilities, ensuring optimal operation and reducing downtime.",
      ],
    },
    {
      title: "Enhancing Decision-Making with Predictive Analytics",
      description:
        "Supply chain management requires not only managing current operations but also anticipating future challenges and opportunities. Predictive analytics powered by artificial intelligence (AI) and big data enable companies to:",
      points: [
        "Forecast Demand Accurately: Analyze historical data, market trends, and consumer behavior to predict future demand and adjust production and distribution accordingly.",
        "Mitigate Risks Proactively: Identify potential disruptions, such as supplier delays, weather events, or geopolitical issues, and develop contingency plans to maintain stability.",
        "Streamline End-to-End Operations: Use data insights to optimize procurement, production schedules, and transportation routes, ensuring smooth coordination across all supply chain elements.",
      ],
      conclusion:
        "By turning raw data into actionable insights, businesses can make informed, strategic decisions that improve efficiency and resilience.",
    },
    {
      title: "Automating Processes for Efficiency and Accuracy",
      description:
        "Automation is transforming supply chains by reducing reliance on manual processes, enhancing accuracy, and lowering operational costs. Seismic Consulting facilitates the integration of automation technologies, including:",
      points: [
        "Automated Warehouse Systems: Implement robotics, conveyor systems, and AI-driven inventory management tools to streamline order picking, packing, and storage operations.",
        "Robotic Process Automation (RPA): Automate repetitive administrative tasks, such as order processing and invoice management, freeing up human resources for higher-value activities.",
        "Smart Sorting and Distribution: Utilize AI-powered sorting technologies to organize goods efficiently, minimize errors, and accelerate delivery times.",
      ],
      conclusion:
        "Automation not only increases speed and accuracy but also enables businesses to scale their operations seamlessly without a corresponding rise in costs.",
    },
  ];
  

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737555308/pexels-photo-8566527_ifx5ck.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Smart Logistics and Supply Chain Management
          </h1>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Transforming Logistics Operations with Intelligent Systems
        </h2>
        <p className="text-gray-700 text-lg mb-4">
          In today's fast-paced and interconnected global market, the efficiency of logistics
          operations is a critical determinant of a company's success. At Seismic Consulting Group,
          we specialize in empowering businesses to modernize their supply chain operations using
          advanced, data-driven technologies. These smart logistics solutions are designed to
          enhance resource utilization, decision-making, and automation, ensuring seamless
          operations that meet customer expectations and deliver a competitive edge.
        </p>
      </div>

  {/* Benefits Section */}
<div className="py-16 max-w-8xl mx-auto px-6 lg:px-20">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Benefits of Smart Logistics Solutions
  </h2>
  <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 text-center">
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546776/7497716.png_lmqppk.png"
          alt="Improved Customer Satisfaction"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Improved Customer Satisfaction</h3>
      <p className="text-gray-700 mt-2">
        Faster, more reliable delivery services ensure that customer expectations are consistently met or exceeded.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554890/7448955.png_gksmrt.png"
          alt="Cost Savings"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Cost Savings</h3>
      <p className="text-gray-700 mt-2">
        Automation, predictive analytics, and optimized resource utilization significantly reduce operational costs.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546264/7498046.png_ejk71i.png"
          alt="Enhanced Agility"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Enhanced Agility</h3>
      <p className="text-gray-700 mt-2">
        Real-time visibility and data-driven decision-making allow companies to adapt quickly to changes in demand or disruptions.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png"
          alt="Sustainability"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Sustainability</h3>
      <p className="text-gray-700 mt-2">
        Smarter logistics enable more efficient use of resources and reduction of waste, aligning operations with environmental and regulatory standards.
      </p>
    </div>
  </div>
</div>


      {/* Expertise Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Key Areas of Expertise
        </h2>
        {expertiseData.map((item, index) => (
          <div key={index} className="mb-12 border-b border-pink-100 pb-8">
            <button
              onClick={() => toggleSection(index)}
              className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
            >
              {item.title}
              <span className="text-purple-900 text-3xl">
                {openSection === index ? "-" : "+"}
              </span>
            </button>
            {openSection === index && (
              <div>
                <p className="text-gray-700 text-lg mb-4">{item.description}</p>
                {item.points && (
                  <ul className="list-none text-gray-700 text-lg space-y-4 mt-4 px-6 lg:px-32">
                    {item.points.map((point, idx) => (
                      <li key={idx} className="relative pl-6">
                        <span
                          className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 border-purple-900 rounded-full"
                        ></span>
                        {point}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}
      </div>


      {/* Why Seismic Section */}
      <section className="bg-white py-16">
  <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
    {/* Text Section */}
    <div className="w-full md:w-1/2">
      <h2 className="text-2xl font-semibold mb-4">Future-Proofing Logistics Operations</h2>
      <p className="text-gray-600 text-xl leading-relaxed mb-4">
      The logistics landscape is rapidly evolving, with technological advancements setting new benchmarks for efficiency, accuracy, and sustainability. Seismic Consulting Group ensures that our clients are not only prepared to meet today’s challenges but are also equipped to thrive in the logistics ecosystems of the
future.
      </p>
    </div>

    {/* Image Section */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737553922/pexels-photo-906982_n18vok.jpg"
        alt="Why Choose Seismic Consulting"
        className="w-full"
      />
    </div>
  </div>
</section>
    </div>
  );
};

export default SmartLogistics;

import React from 'react';

export default function PromiseIkpuriProfile() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero / Top Section */}
      <div className="relative w-full bg-gradient-to-r from-gray-900 to-gray-400 h-56 md:h-64 mt-20">
        <div className="max-w-7xl mx-auto px-4 md:px-8 h-full flex items-center">
          {/* Left Text */}
          <div className="text-white w-full md:w-1/2">
            <h1 className="text-xl md:text-2xl font-bold mb-2">Promise Ikpuri</h1>
            <p className="text-sm md:text-base leading-relaxed">
              Monitoring &amp; Evaluation Specialist | Conflict Analyst | Development Economist
            </p>
          </div>

          {/* Right Image (Replace the src with Promise's actual photo URL) */}
          {/* <div className="hidden md:flex w-1/2 justify-end">
            <img
              src="https://via.placeholder.com/200x240?text=Promise+Ikpuri"
              alt="Promise Ikpuri"
              className="h-48 w-auto object-cover rounded shadow-lg"
            />
          </div> */}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto py-12 px-6 md:px-12">
        {/* Introduction */}
        <section className="mb-10">
         
          <p className="text-lg leading-relaxed">
            Promise Ikpuri is a seasoned Monitoring &amp; Evaluation (M&amp;E) expert, conflict analyst, and development 
            economist with a proven track record in research-driven consulting. As the Research Team Lead at Seismic 
            Consulting Limited, he ensures that projects are driven by data, strategic insights, and well-founded research 
            methodologies. With extensive experience collaborating with global organizations such as the World Bank, 
            African Development Bank (AfDB), Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ), and Oxfam, 
            Promise plays a critical role in advancing Seismic’s mission through policy research, impact assessment, and 
            project management.
          </p>
          <p className="text-lg leading-relaxed mt-4">
            He holds a Master’s degree in Development Economics and a Bachelor’s degree in Economics from Delta State 
            University, Abraka, Nigeria, graduating with distinction in his postgraduate studies. His academic background, 
            enhanced by certifications in Peacebuilding &amp; Conflict Transformation, Conflict Analysis, and Economic Policy 
            Research, strengthens his ability to support Seismic’s commitment to evidence-based policymaking and rigorous 
            program evaluation.
          </p>
        </section>

        {/* Q&A Introduction Section */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4"> A Closer Look</h2>
          <p className="text-lg leading-relaxed">
          We sat down with Promise Ikpuri to gain deeper insights into the inner workings of his team and the driving principles behind his work at Seismic Consulting Limited. Through a series of thought-provoking questions and his detailed responses, he sheds light on the intricacies of leading a research team, the complexities of proposal development, and the strategic methodologies that guide the success of impactful projects. This conversation offers a comprehensive view of the challenges, achievements, and future directions of research-driven consulting.
          </p>
        </section>

        {/* Role & Responsibilities */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Role &amp; Responsibilities</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Could you start by describing your role as the Research Team Lead and how it fits within the larger consulting organization?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                As the Research Team Lead, I ensure that our consulting projects are driven by data, strategic insights, and well-founded research. My role involves leading a team that gathers and analyzes information to support decision-making, project development, and impact assessments. Within the organization, I act as a bridge between research, project implementation, and proposal development, ensuring that every initiative we take on is based on solid evidence and meets both donor and client expectations.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: What are some of your main responsibilities in identifying, drafting, and securing project proposals?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Developing project proposals is both an art and a science. It starts with identifying the right opportunities—ones that align with our expertise and have a strong potential for real impact. From there, I conduct extensive research, consult stakeholders, and collaborate with different teams to develop compelling proposals. I ensure that every submission is clear, well-structured, and supported by accurate data. Beyond writing, I also play a role in strategy, determining which opportunities are worth pursuing and ensuring we allocate resources effectively to maximize our chances of success.
              </p>
            </div>
          </div>
        </section>

        {/* Project Insights & Knowledge */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Project Insights &amp; Knowledge</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: You’ve worked on numerous projects—could you highlight a few that you feel particularly proud of or that had a significant impact?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Several projects stand out, but the Better Education Service Delivery for All (BESDA) project was particularly meaningful. It involved assessing education interventions across 17 states, giving me a front-row seat to how policy translates into real-world outcomes. Another impactful initiative was the Nigeria Digital Identification for Development (ID4D) project, where we helped design a monitoring framework to support the national identity system. These projects weren’t just about research—they were about shaping policies and programs that directly improve people’s lives.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: What was unique about the research or proposal process for those standout initiatives?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Each project came with its own challenges and learning moments. For BESDA, it was about navigating diverse regional contexts and ensuring that our evaluations reflected on-the-ground realities. With ID4D, the complexity lay in aligning our framework with government policies while incorporating best practices in digital identity management. In both cases, stakeholder engagement and adaptability were key. No two projects are the same, and that’s what makes the work so exciting.
              </p>
            </div>
          </div>
        </section>

        {/* Proposal Development & Strategy */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Proposal Development &amp; Strategy</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Could you walk us through the typical steps your team takes when drafting a project proposal—from initial idea to final submission?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Drafting a proposal is a structured yet dynamic process. It begins with identifying an opportunity and conducting thorough research to understand the client’s needs and objectives. From there, we develop a clear project concept, outlining objectives, methodologies, and expected outcomes. Stakeholder consultations play a big role in refining our approach. Once we have a draft, we go through multiple rounds of review to ensure clarity, feasibility, and alignment with donor requirements. Finally, after submission, we stay engaged, responding to queries and refining details if necessary.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: How do you decide which opportunities are worth pursuing, especially when juggling multiple potential projects at once?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Decision-making comes down to strategic fit, feasibility, and potential impact. We evaluate whether a project aligns with our expertise, if we have the necessary resources to execute it effectively, and whether the expected outcomes justify the effort. Juggling multiple projects requires prioritization, and we rely on internal discussions, market trends, and funding landscapes to determine which opportunities will bring the most value.
              </p>
            </div>
          </div>
        </section>

        {/* Collaboration & Team Dynamics */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Collaboration &amp; Team Dynamics</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: How does the research team collaborate with other departments—like the consultancy, tech, or field operations—to gather data and insights for proposals?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Research doesn’t happen in isolation. We work closely with consultancy teams to understand project objectives, coordinate with tech specialists for data analytics and digital tools, and engage field teams to gather real-world insights. The collaboration ensures that our proposals and reports are well-rounded, combining both theoretical analysis and practical realities. The more integrated our approach, the stronger our project outcomes.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: What strategies do you use to ensure clear communication and alignment between the research unit and the rest of the firm?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Effective communication is about structure and consistency. We use standardized reporting formats, hold regular meetings to share insights, and create collaborative workspaces where different teams can exchange information easily. I also prioritize training sessions and knowledge-sharing forums to keep everyone aligned. The goal is to make sure research is not just a background function but a driving force behind our firm’s strategy and execution.
              </p>
            </div>
          </div>
        </section>

        {/* Overcoming Challenges */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Overcoming Challenges</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: What are some of the biggest hurdles you face when compiling research or drafting proposals, and how do you address them?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                One major challenge is dealing with data gaps, especially in areas where information is scarce or inconsistent. In such cases, we rely on field research, stakeholder consultations, and comparative analysis to fill in the blanks. Another challenge is working within tight deadlines, which we manage by optimizing workflows and dividing tasks efficiently. The most critical hurdle, however, is adapting to shifting donor priorities, which requires us to remain flexible and continuously update our methodologies.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: Could you share an example of a particularly challenging project bid and what lessons you learned from it?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                The Nigeria Digital Identification for Development (ID4D) project was one of the most challenging due to its complexity and the need for high levels of coordination across government agencies. Aligning our monitoring framework with national policies while ensuring compliance with international best practices required extensive negotiations and adjustments. The biggest lesson was the importance of early stakeholder engagement and iterative feedback—waiting until the final stage to address concerns can be costly.
              </p>
            </div>
          </div>
        </section>

        {/* Measuring Success & Impact */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Measuring Success &amp; Impact</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Once a proposal is secured and the project is launched, how do you measure the success of your research efforts in shaping project outcomes?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Success is measured through both qualitative and quantitative indicators. We compare baseline data with post-implementation results, assess how well the project adheres to the proposed framework, and gather feedback from stakeholders. The goal is to ensure that our research translates into real, measurable improvements.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: Do you track metrics—like cost-effectiveness, social impact, or client satisfaction—to refine future proposal strategies?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Absolutely. Cost-effectiveness helps determine if resources are being utilized efficiently, social impact metrics assess the tangible benefits to communities, and client satisfaction provides insights into how well we are meeting expectations. These factors help refine our future approaches, ensuring continuous improvement.
              </p>
            </div>
          </div>
        </section>

        {/* Future Vision & Innovation */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Future Vision &amp; Innovation</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Looking ahead, are there any new research tools, data analytics approaches, or methodologies you plan to adopt for more competitive proposals?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                The future of research in consulting is all about smarter data use. We are exploring AI-powered analytics to predict project success rates, geospatial mapping for better resource allocation, and blockchain for enhanced transparency in project reporting. These innovations will not only improve accuracy but also give us a competitive edge.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: How do you see the research team’s role evolving as our consulting services expand into new areas or markets?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                As we grow, research will become even more integral, helping us navigate new markets, understand evolving client needs, and stay ahead of industry trends. The research team will shift from being purely analytical to becoming a key driver of strategic decision-making.
              </p>
            </div>
          </div>
        </section>

        {/* Regions Needing the Most Attention */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Regions Needing the Most Attention</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: From your perspective, which regions or areas currently require the most urgent focus and why?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Conflict-affected regions like North-East Nigeria need urgent attention due to security challenges and the need for humanitarian support. Additionally, states with low education enrollment and weak digital infrastructure require targeted interventions to bridge development gaps.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: In what ways can partners and stakeholders work through our firm to foster meaningful development and growth in those locales?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Partners can collaborate with us by investing in data-driven policy formulation, supporting capacity-building initiatives, and fostering public-private partnerships. Meaningful development happens when insights are backed by action.
              </p>
            </div>
          </div>
        </section>

        {/* Final Reflections */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Final Reflections</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Is there any advice you’d give to someone aspiring to excel in research-driven proposal development?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Stay curious, be adaptable, and always back your work with solid evidence. Understanding donor priorities and being able to translate research into compelling narratives will set you apart.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">
                Q: Any success stories that epitomize the value the research team brings to the firm?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                The Yobe Project Coordinating Unit review stands out. Our research directly influenced policy changes and capacity-building efforts, proving that well-conducted research doesn’t just inform—it transforms.
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Contact Section */}
      <div className="bg-gray-100 text-center py-6 px-6 md:px-12">
        <h4 className="text-xl font-semibold mb-4">Contact Promise</h4>
        <div className="flex justify-center space-x-8 mt-6">
          <a
            href="https://www.linkedin.com/in/promiseikpuri"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80"
          >
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738335197/10091797_kdndmj.png"
              alt="LinkedIn Icon"
              className="w-12 h-12"
            />
          </a>
          <a
            href="mailto:promise.ikpuri@seismicconsulting.com"
            className="hover:opacity-80"
          >
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738335233/10506089.png_lggulu.png"
              alt="Email Icon"
              className="w-12 h-12"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const PerformanceBasedBudgeting = () => {
  return (
    <div className="w-full bg-white">
{/* Hero Section */}
<section
  className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
  style={{ backgroundImage: "url('/PagesImages/pbb.jpg')" }} // Replace with the correct PBB hero image path
>
  <div className="absolute inset-0 bg-black bg-opacity-30"></div>

  {/* Title Overlay for large screens*/}
  <div
    className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
  >
    <h1 className="text-3xl font-bold text-gray-800 mt-4">
    Performance-Based Budgeting
    </h1>
  </div>
</section>


      {/* Foreword Section */}
      <section className="max-w-4xl mx-auto py-16 px-6 lg:px-0">
                                  {/* Title Below Image for Small Screens */}
<div className="block lg:hidden mt-4 mb-4 text-left">
  <h1 className="text-2xl font-bold text-gray-800">
  Performance-Based Budgeting
  </h1>
</div>
        <p className="text-lg text-gray-700 mb-8 mt-8">
          At Seismic, we are committed to enhancing the efficiency and effectiveness of public financial management through our Performance-Based Budgeting (PBB) approach. As a global leader in Public Financial Management (PFM), we offer innovative budgeting solutions that directly link public funding to measurable outcomes, ensuring that governments allocate resources where they will have the greatest impact.
        </p>
        <div className="flex flex-col lg:flex-row items-start gap-8 mt-20">
          <img
            src="/PagesImages/pbb_small.jpg" // Replace with the correct small image path
            alt="PBB Innovation"
            className="w-full lg:w-5/12 object-cover"
          />
          <p className="text-lg text-gray-700 lg:w-7/12 mt-4">
            Seismic’s PBB services align fiscal strategies with performance goals, improving the transparency, accountability, and efficiency of public spending.
          </p>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16">
        <div className="max-w-4xl mx-auto px-6 lg:px-0">
          <h2 className="text-3xl font-semibold mb-8">Our Approach</h2>
          <ul className="space-y-8">
            {[
              {
                title: "Tying Funding to Measurable Outcomes",
                description:
                  "Seismic's PBB approach shifts the focus from traditional input-based budgeting to an outcome-oriented model. We guide governments in linking their budget allocations to specific, measurable results that reflect policy priorities, ensuring funds deliver tangible benefits for citizens.",
              },
              {
                title: "Enhancing the Efficiency of Public Investments",
                description:
                  "Our PBB solutions maximize the impact of government spending by prioritizing high-performance programs. We help governments evaluate program effectiveness, encouraging a culture of accountability and allowing resources to be redirected to the most impactful initiatives.",
              },
              {
                title: "Improving Transparency and Accountability",
                description:
                  "Our PBB model promotes transparency by linking budget decisions to measurable outcomes. This approach fosters public trust, as citizens and stakeholders can see the impact of spending, and incentivizes departments to meet performance targets for future funding.",
              },
              {
                title: "Tailored Solutions for Diverse Public Finance Needs",
                description:
                  "Seismic delivers tailored PBB solutions that address the specific needs of diverse public finance systems. We design frameworks that are aligned with local governance structures, economic conditions, and policy priorities, integrating global best practices with local realities.",
              },
              {
                title: "Driving Impact through Performance-Based Budgeting",
                description:
                  "Seismic's PBB solutions support governments in achieving strategic goals by aligning fiscal policies with performance outcomes, fostering economic growth, social development, and improved public service delivery. We empower governments to make informed, data-driven decisions.",
              },
            ].map((approach, index) => (
              <li key={index} className="flex gap-4">
                <span className="text-purple-900 text-2xl">▶</span>
                <div className="text-lg font-semibold">
                  {approach.title}: <span className="font-normal text-gray-700">{approach.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
{/* Other Services Section */}
<section className="py-16">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-gray-800 mb-8">Other Services</h2>
    <p className="text-xl text-gray-600 mb-8">
      Helping clients bridge the fundamental gaps in the education system
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[
        {
          title: "Audit and Financial Control Systems",
          description:
            "Seismic offers a comprehensive suite of audit and financial control solutions that strengthen transparency, accountability, and governance within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.afcs}`,
        },
        {
          title: "Capacity Building and Training",
          description:
            "Our training initiatives emphasize collaboration and peer learning, fostering continuous improvement within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cbt}`,
        },
        {
          title: "Capital Investment Planning",
          description:
            "We help governments and institutions develop strategic capital investment plans aligned with national development goals, promoting economic growth and resource optimization.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cip}`,
        },
      ].map((service, index) => (
        <Link
          to={service.link}
          key={index}
          className="block bg-white p-6 shadow-md rounded-md text-left max-w-[450px] mx-auto"
        >
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            {service.title}
          </h3>
          <p className="text-lg text-gray-600 mb-4">
            {service.description}
          </p>
        </Link>
      ))}
    </div>
  </div>
</section>

    </div>
  );
};

export default PerformanceBasedBudgeting;

import React from "react";

export default function WhatWeBelievePage() {
  return (
    <div className="flex flex-col w-full mt-32">
      {/* Top Section with Background Image & Overlay */}
      <div className="relative h-[450px] w-full bg-cover bg-center" 
        style={{
          backgroundImage: `url("https://res.cloudinary.com/dxhx45ing/image/upload/v1740660837/pexels-photo-262367_vhhcbm.jpg")`
        }}
      >
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black/60" />
        
        {/* Text Overlay */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
          What We Believe
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
          We are driven by the conviction that impactful change is achieved through strategic thinking,
          collaborative innovation, and unwavering commitment to delivering results.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-4 md:px-8 py-10 text-gray-700 leading-relaxed">
        <p className="mb-4 text-xl">
          Everyone at SEISMIC has experienced first-hand, some of the challenges 
          of the developing world – widespread poverty, illiteracy, sub-optimal 
          service delivery, ineffective policies and programmes, disconnect 
          between policies and people, high unemployment, insecurity, etc.
        </p>
        <p className="mb-4 text-xl">
          Rather than become despondent, we have a strong conviction that within 
          this myriad of problems and challenges lies an opportunity to make a difference.
        </p>
        <p className="mb-4 text-xl">
          Our evidence-based solutions are centered on creating enduring social 
          and economic changes that would enable the world’s poorest people 
          create prosperity for themselves and live out their fullest potentials.
        </p>
        <p className="mb-4 text-xl">
          Ultimately, we believe that through our expertise and dedication, 
          we can create sustainable solutions that bring about positive change 
          for the future. We believe in the power of transformation and 
          the pursuit of excellence.
        </p>
      </div>

      {/* Mission Section */}
      <div className="flex items-start max-w-7xl mx-auto py-16 px-4 lg:px-16">
    <h3 className="text-2xl font-semibold text-gray-800 w-1/4 min-w-[150px]">Our Mission</h3>
    <p className="text-xl text-purple-900 italic w-3/4 text-center">
    "Together, we are innovating beyond boundaries to deliver impactful solutions that transform lives and inspire change."
    </p>
  </div>

{/* Our Truth Section */}
<div className="py-2 mb-20">
  <div className="max-w-7xl mx-auto px-4 md:px-8">
    <h2 className="text-2xl md:text-3xl font-bold text-center mb-4">Our Truth</h2>
    <p className="text-center text-gray-700 max-w-2xl mx-auto mb-8">
      We remain true to these values, as we continue to deliver exceptional results 
      for our clients, foster innovation, and leave a lasting positive impact 
      on the world around us.
    </p>

    {/* 6-Item Grid */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">

      {/* 1. Innovation for Growth */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546264/7498046.png_ejk71i.png"
            alt="Innovation Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Innovation for Growth</h3>
        <p className="text-gray-700">
          By leveraging cutting-edge technology, data-driven insights, and 
          forward-thinking strategies, we help organizations navigate complex 
          challenges and capitalize on new opportunities.
        </p>
      </div>

      {/* 2. Collaboration for Success */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736943271/team_7498209_fmksry.png"
            alt="Collaboration Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Collaboration for Success</h3>
        <p className="text-gray-700">
          We believe that the best solutions are built through collaboration.
          Our team thrives in a culture of openness, inclusivity, and mutual respect.
        </p>
      </div>

      {/* 3. Integrity and Trust */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546776/7497716.png_lmqppk.png"
            alt="Integrity Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Integrity and Trust</h3>
        <p className="text-gray-700">
          Integrity is the foundation of our work. We uphold the highest standards
          of transparency, accountability, and ethical conduct in all our interactions.
        </p>
      </div>

      {/* 4. Excellence in Execution */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737556289/7278881.png_pl8sa1.png"
            alt="Excellence Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Excellence in Execution</h3>
        <p className="text-gray-700">
          We believe that excellence is not a goal but a continuous journey. 
          From the strategic phase to delivery, we apply a rigorous approach 
          to ensure sustainable solutions and exceed expectations.
        </p>
      </div>

      {/* 5. Empowering People */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png"
            alt="Empowering Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Empowering People</h3>
        <p className="text-gray-700">
          We understand that the success of any organization is ultimately determined
          by the people behind it. Through capacity-building and a focus on human 
          potential, we help clients achieve their goals and transform for the better.
        </p>
      </div>

      {/* 6. Global Impact */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
            alt="Global Impact Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Global Impact</h3>
        <p className="text-gray-700">
          We believe in making a meaningful contribution to society at large.
          Whether through environmental sustainability, social development, 
          or economic empowerment, Seismic Consulting is committed 
          to driving positive change.
        </p>
      </div>

    </div>
  </div>
</div>

    </div>
  );
}

// src/pages/CookiePolicy.jsx
import React from "react";

const CookiePolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 mt-20">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Cookie Policy</h1>
      <p className="text-gray-700 text-lg mb-4">
        This Cookie Policy explains how our website ("we", "us", or "our")
        uses cookies and similar technologies to recognize you when you visit
        our website. It explains what these technologies are and why we use them,
        as well as your rights to control our use of them.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">What Are Cookies?</h2>
      <p className="text-gray-700 text-lg mb-4">
        Cookies are small data files placed on your device when you visit a website.
        They are widely used to make websites work, or work more efficiently, as well
        as to provide information to the website owners.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">How We Use Cookies</h2>
      <p className="text-gray-700 text-lg mb-4">
        We use cookies to:
      </p>
      <ul className="list-disc list-inside text-gray-700 text-lg mb-4">
        <li>Enable core website functionality and security.</li>
        <li>Analyze website usage to improve user experience.</li>
        <li>Provide personalized content and targeted advertising.</li>
      </ul>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Your Cookie Choices</h2>
      <p className="text-gray-700 text-lg mb-4">
        By using our website, you consent to our use of cookies as described in this
        policy. Most web browsers are set to accept cookies by default, but you can
        usually modify your browser settings to remove or reject cookies. Please note
        that disabling cookies may affect the functionality of our website.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Changes to This Policy</h2>
      <p className="text-gray-700 text-lg mb-4">
        We may update this Cookie Policy from time to time to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. Please check
        back periodically for updates.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Contact Us</h2>
      <p className="text-gray-700 text-lg">
        If you have any questions about our use of cookies or this policy, please
        contact us at <a href="mailto:email@example.com" className="text-purple-900">info@seismicconsults.com</a>.
      </p>
    </div>
  );
};

export default CookiePolicy;

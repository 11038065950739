import React from "react";

export default function OlusojiAdeniyiProfile() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero / Top Section */}
      <div className="relative w-full bg-gradient-to-r from-gray-900 to-gray-400 h-56 md:h-64 mt-20">
        <div className="max-w-7xl mx-auto px-4 md:px-8 h-full flex items-center">
          {/* Left Text */}
          <div className="text-white w-full md:w-1/2">
            <h1 className="text-xl md:text-2xl font-bold mb-2">
              Dr. Olusoji Adeniyi
            </h1>
            <p className="text-sm md:text-base leading-relaxed">
              Senior Partner, Risk &amp; Quality Assurance | Expert in
              Education, Capacity Building & Climate Advocacy
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto py-12 px-6 md:px-12">
        {/* Introduction */}
        <section className="mb-10">
          <p className="text-lg leading-relaxed">
            As Senior Partner at Seismic Consulting Limited, Dr. Olusoji Adeniyi
            plays a critical role in shaping risk and quality assurance
            strategies, overseeing large-scale education and workforce
            development initiatives, and driving impactful social development
            programs. With extensive experience in education systems, policy
            research, and program evaluation, Dr. Adeniyi leads efforts to
            improve technical education, vocational training, and human capital
            development. His work with major global institutions, has positioned
            Seismic Consulting as a leader in strategic consulting for
            educational transformation, workforce readiness, and
            climate-conscious development.
          </p>
          <p className="text-lg leading-relaxed mt-4">
            Bringing over two decades of global expertise, Dr. Adeniyi is a
            seasoned expert in capacity building, education reform, social
            development, and climate advocacy, with deep proficiency in policy
            formulation, program evaluation, and large-scale development
            initiatives. As the former Chairperson of the United Nations
            Pensions Fund, he spearheaded governance strategies that enhanced
            institutional sustainability. Today, he leverages this wealth of
            experience at Seismic Consulting, driving policies on social
            development, energy transition, and sustainability—ensuring that
            organizations adopt evidence-based, high-impact solutions to address
            emerging global challenges.
          </p>
        </section>

        {/* Q&A Introduction Section */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4">A Closer Look</h2>
          <p className="text-lg leading-relaxed">
            We had an in-depth conversation with Dr. Olusoji Adeniyi about his
            leadership at Seismic Consulting, his approach to education and
            social development, and the strategic priorities that define his
            work. In this conversation, he discusses the firm’s evolving role in
            education reform, the challenges of workforce transformation, and
            the importance of quality assurance in development projects.
          </p>
        </section>

        {/* Role & Contributions */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Role & Contributions</h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Can you describe your role at Seismic Consulting and how
                you've shaped the company’s strategic direction?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Joining the firm officially as a partner came years after I had
                been in contact with the Managing partner, providing the
                necessary "sounding board" and offering creative ideas for
                strategic positioning etc. I am proud of the evolution of the
                firm to what we are today, taking on giants in the industry and
                making a difference as a globally-focused institution. I believe
                we have the right team to continue to impact the development
                space and expand our horizon beyond the current coverage. We are
                positioned for bigger opportunities as we evolve.
              </p>
            </div>
          </div>
          <div className="space-y-6 ">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Which high-impact projects or initiatives have you led here,
                and what made them stand out?”
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                There have been many, but a few stand out for me personally,
                having spent over 3 decades in the development ecosystem. Our
                project with the World Bank on Skills Development & the National
                Skills Certification Framework, Our GIZ funded National
                Technical Education System Review and our Support to the
                National Board for Technical Education are amongst the best
                opportunities to impact young Nigerians and create a
                skill-centric workforce for Nigeria. We have also been able to
                support institutions such as ECOWAS in the development of the
                Agenda 2050, Support to Africa Development Bank(AfDB) in
                community engagement strategy, Support to UNICEF, UNDP, UNFAO
                are amongst the projects that are special to me because they
                create impact and can be scaled for local, national and regional
                impact
              </p>
            </div>
          </div>
        </section>

        {/* Key Projects & Partnerships */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Key Projects & Partnerships
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Among the firm’s prominent partnerships, which ones do you
                feel have brought the most transformation or recognition?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                I will say our partnership with the World Bank and ECOWAS, both
                are strategic for national and regional transformation and
                naturally brings the firm to limelight. But every partnership
                for us is strategic and taking seriously to ensure we deliver
                value and exceed client expectations at all times
              </p>
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q:How do you typically measure success or impact in these
                collaborative efforts—especially in social development or
                climate advocacy projects?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Success is relative while impact or outcomes are better defined
                through strategic objectives of projects, especially in social
                or human capital development. Our goals are primarily to meet
                the project strategic objectives and focus more on those
                objectives that will result in higher impacts and better
                outcomes. We typically explore effective collaborations in our
                approach to partnerships ensuring best value for money approach
                in most situations.
              </p>
            </div>
          </div>
        </section>

        {/* Leveraging UN Experience for Strategic Impact */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Leveraging UN Experience for Strategic Impact
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: You have an extensive background with the United Nations.
                What core lessons from that experience have been most beneficial
                to your consulting work now?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                My 22-year tenure at the United Nations, spanning five countries
                across three continents, provided invaluable experience in
                policy implementation, program evaluation, and strategic
                governance. Serving as the first African to chair the
                62-year-old UN Joint Staff Pensions Fund honed my leadership and
                decision-making skills on a global scale.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                The UN’s emphasis on teamwork, leveraging technology, and
                continuous performance evaluation directly informs our approach
                at Seismic Consulting. The ability to manage large-scale
                projects, navigate international partnerships, and implement
                evidence-based strategies has strengthened our consulting
                framework. These experiences have positioned us to provide
                high-impact solutions in program monitoring, evaluation, and
                policy advisory—ensuring our clients benefit from globally
                tested methodologies and best practices.
              </p>
            </div>
          </div>
        </section>

        {/* Strengthening Education & Workforce Development */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Strengthening Education & Workforce Development
          </h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: What are the biggest challenges in education reform, and how
                does Seismic Consulting address them?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                One of the biggest challenges is ensuring that education systems
                are aligned with industry needs. There is often a gap between
                academic training and the practical skills required in today’s
                workforce. At Seismic Consulting, we address this by working
                with government agencies, development partners, and private
                sector stakeholders to design competency-based education models
                that equip learners with real-world skills. Our projects focus
                on making technical and vocational education more relevant,
                scalable, and accessible.
              </p>
            </div>
          </div>
        </section>

        {/* Climate Advocacy & Social Development */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Climate Advocacy & Social Development
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q:Your profile highlights climate advocacy and social
                development. How do those priorities shape our firm’s consulting
                strategies?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                The two priorities are of both global and national concerns
                hence the focus, the Nation needs to demonstrate sufficient
                consciousness on climate change and its attendance consequences
                on food security, national safety and energy dependency, Social
                development strategies including human capital development needs
                will remain critical in the years ahead. As a development
                focused firm, we are constantly repositioning ourselves for
                opportunities in these ecosystems and ensuring that we remain
                thought leaders in the space.
              </p>
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q:When working with clients on issues like energy transition or
                education reform, how do you ensure both environmental
                sustainability and social equity?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Sustainability issues are no longer focused only on environment,
                it is now a universal issue that must be considered for all
                projects irrespective of the sector. Although energy transition
                and energy mapping issues tend to attract more environmental
                consciousness, sustainability is a global requirement and our
                firm is big on research and development to improve
                sustainability and create and atmosphere of inclusive coverage
                as we explore sustainable solutions for clients and industry.
                Social equity is the enemy of equality, especially in the
                African Continent where traditional values often blinds the
                equity agenda, we continue to push for accountability and build
                capacity for gender neutrality among others.
              </p>
            </div>
          </div>
        </section>

        {/* Collaboration & Leadership Style */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Collaboration & Leadership Style
          </h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q:Are there any leadership principles you’ve adopted from past
                experiences that help you bridge diverse viewpoints and drive
                impactful decisions?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Leadership is not just about holding a position; it’s about
                responsibility and impact. I foster collaboration by ensuring
                clear expectations in recruitment, project leadership, and team
                dynamics, emphasizing trust, synergy, and open communication. I
                lead by example and encourage others to challenge my ideas,
                reinforcing that leadership is about contribution, not
                hierarchy. This approach has cultivated a strong culture of
                mentorship within the firm, where team members grow into
                leaders, often advancing to higher responsibilities within and
                outside the organization.
              </p>
            </div>
          </div>
        </section>

        {/* Vision & Future Goals */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold">Vision & Future Goals</h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q:Looking ahead, what new markets, services, or technological
                innovations do you envision our firm embracing?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Recently, we have just been appointed to support the British
                Council in Human Capital Development across Sub-Saharan Africa,
                this new responsibility will challenge the firms current
                geographical limitations as we move to deliver on this key
                objective. We are therefore poised to establish presence in some
                of the regional locations in Africa and finalizing our presence
                in the United Kingdom before the end of the year. We are big on
                innovation and have a team of young Nigerians already creating
                disruptions in Technology, Agro-innovations, Fintech amongst
                others. We are building a global brand and we do not see limits
                in the years ahead.
              </p>
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q:How do you see your own role evolving to support these
                expansions and maintain our leadership in social development and
                climate advocacy?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                As a Snr Partner in the firm, I remain focused on strategic
                expansion, building high networth partnerships and ensuring we
                can attract the right talents to join and build with us
                irrespective of my personal passion for climate advocacy and
                social development..
              </p>
            </div>
          </div>
        </section>

        {/* Final Reflections */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">Final Reflections</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q:Could you share any guiding principles—or even a personal
                mantra—that influences how you address large-scale challenges?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                I believe in the words of the sage: Mathama Ghandi, That "An eye
                for an eye makes the world go blind". I constantly seek
                opportunities to do good things in great ways, gunning for
                impact and scaling up interventions that deliver better outcomes
                for Africa and the world. I`m lucky to get paid doing what I
                love!.
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Contact Section */}
      <div className="bg-gray-100 text-center py-6 px-6 md:px-12">
        <h4 className="text-xl font-semibold mb-4">
          Connect with Dr. Olusoji Adeniyi
        </h4>
        <div className="flex justify-center space-x-8 mt-6">
          <a
            href="https://www.linkedin.com/in/olusojiadeniyi"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80"
          >
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738335197/10091797_kdndmj.png"
              alt="LinkedIn Icon"
              className="w-12 h-12"
            />
          </a>
          <a
            href="mailto:sojiadeniyi@gmail.com"
            className="hover:opacity-80"
          >
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738335233/10506089.png_lggulu.png"
              alt="Email Icon"
              className="w-12 h-12"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router
import { paths } from "../../constants/paths";

const Methodology = () => {
  const methodologies = [
    {
      title: "Needs Assessment",
      description:
        "Embed sustainable practices within organizations to ensure the knowledge and skills gained during capacity-building initiatives remain effective over time.",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738072447/WhatsApp_Image_2025-01-28_at_13.19.48_bgcm8h.jpg", 
      link: `/consulting-services/capacity-building/${paths.needsAssessment}`, 
    },
    {
      title: "Program Design and Customization",
      description:
        "The process focuses on collaborative co-creation of tailored learning experiences that directly support the organization's strategic vision.",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738073552/WhatsApp_Image_2025-01-28_at_13.47.12_1_fkvquw.jpg",
      link: `/consulting-services/capacity-building/${paths.programDesignAndCustomization}`, 
    },
    {
      title: "Implementation",
      description:
        "Delivery of engaging, interactive, and hands-on training sessions.",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738073027/WhatsApp_Image_2025-01-28_at_13.00.34_r7lfum.jpg",
      link: `/consulting-services/capacity-building/${paths.implementation}`, 
    },
    {
      title: "Monitoring and Evaluation",
      description:
        "Regular assessment of program effectiveness and feedback loops for continuous improvement.",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738074482/WhatsApp_Image_2025-01-28_at_13.19.47_h86qjg.jpg", // Replace with actual image path
      link: `/consulting-services/capacity-building/${paths.monitoringAndEvaluation}`, 
    },
    {
      title: "Sustainability Planning",
      description:
        "Development of frameworks for ongoing learning and knowledge retention, empowering organizations to sustain and scale their initiatives independently.",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738074206/WhatsApp_Image_2025-01-28_at_13.17.35_xfkaiq.jpg", // Replace with actual image path
      link: `/consulting-services/capacity-building/${paths.sustainabilityPlanning}`, 
    },
  ];

  return (
    <section className="bg-white py-16">
      {/* Reduced Side Margins */}
      <div className="max-w-[90rem] mx-auto px-4 lg:px-12">
        <h2 className="text-2xl lg:text-3xl font-bold text-center text-gray-800 mb-6">
          Our Methodology
        </h2>
        <p className="text-lg text-center text-gray-600 mb-12 max-w-4xl mx-auto">
          By combining innovative methodologies, practical solutions, and a
          commitment to sustainability, we enable our clients to cultivate a
          culture of excellence, resilience, and continuous improvement.
        </p>

        {/* Grid Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* First Row: Three Items */}
          {methodologies.slice(0, 3).map((item, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-52 object-cover"
              />
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-600 text-sm mb-4">{item.description}</p>
                <Link to={item.link}>
                  <span className="text-purple-900 hover:text-purple-800 font-medium text-sm cursor-pointer">
                    Read More →
                  </span>
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Second Row: Two Wider Items */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8">
          {methodologies.slice(3).map((item, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-72 object-cover"
              />
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-600 text-sm mb-4">
                  {item.description}
                </p>
                <Link to={item.link}>
                  <span className="text-purple-900 hover:text-purple-800 font-medium text-sm cursor-pointer">
                    Read More →
                  </span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Methodology;

import React from 'react';

const FooterSection = () => {
  return (
    <footer className="relative bg-purple-50 text-gray-700 py-40 pt-28 px-4 md:px-32 font-['Work_Sans'] overflow-hidden">
      {/* Background Image only under the logo section */}
      <div className="absolute top-0 left-0 w-1/3 h-5/7 z-0 hidden lg:block">
        <img 
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745704/footer_bg_q2ox2e.png" 
          alt="Background Pattern"
          className="w-full h-full object-contain opacity-100" 
        />
      </div>

      {/* Content in front of the background */} 
      <div className="relative max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 z-10">
        {/* Left Column: Logo and Social Links */}
        <div className="text-center md:text-left">
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745709/scg_logo_dtunha.png" alt="SCG Logo" className="h-12 mb-4 mx-auto md:mx-0" />
          <p className="text-lg font-medium">
            Innovating Beyond Boundaries and Delivering  <br/>  Impact
          </p>
          <div className="mt-4">
            <p className="font-semibold">FOLLOW US ON</p>
            <div className="flex justify-center md:justify-start space-x-2 mt-4">
              <a href="https://www.facebook.com/share/15wuyHjfkB/?mibextid=wwXIfr" className="text-pink-200 p-2">
                <img 
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745704/facebook_npszud.png" 
                  alt="Facebook" 
                  className="h-7" 
                />
              </a>
              <a href="https://x.com/seismiccg" className="text-pink-200 p-2">
                <img 
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/twitter_zzdsgc.png" 
                  alt="Twitter" 
                  className="h-7"
                />
              </a>
              <a href="https://ng.linkedin.com/company/seismic-consulting-group?trk=public_post_feed-actor-image" className="text-pink-200 p-2">
                <img 
                  src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745706/linkedin_gdkkoq.png" 
                  alt="linkedin" 
                  className="h-7"
                />
              </a>
            </div>
          </div>
        </div>

        {/* Right Column: Quicklinks and Contact Us */}
        <div className="flex flex-col items-center md:items-start md:flex-row justify-between">
          {/* Quicklinks */}
          <div className="mr-0 md:mr-8 text-center md:text-left">
            <h3 className="font-semibold text-lg mb-4">QUICKLINKS</h3>
            <ul className="space-y-2">
  <li className="py-2">
    <a href="careers" className="hover:underline focus:outline-none">Careers</a>
  </li>
  <li className="py-2">
    <a href="/consulting-services" className="hover:underline focus:outline-none">Services</a>
  </li>
  <li className="py-2">
    <a href="the-team" className="hover:underline focus:outline-none">Our Team</a>
  </li>
  <li className="py-2">
    <a href="industries" className="hover:underline focus:outline-none">Industries</a>
  </li>
</ul>

          </div>

          {/* Contact Us */}
          <div className="ml-0 md:ml-20 mt-6 md:mt-0 text-center md:text-left">
            <h3 className="font-semibold text-lg mb-4">CONTACT US</h3>
            <div className="flex flex-col items-center md:items-start">
              <p className="flex items-center text-gray-600 mb-2 py-2">
                <span className="mr-2">
                  {/* Location Icon */}
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-purple-900" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2C8.1 2 5 5.1 5 9C5 15.3 12 22 12 22S19 15.3 19 9C19 5.1 15.9 2 12 2zM12 13.5C10.6 13.5 9.5 12.4 9.5 11C9.5 9.6 10.6 8.5 12 8.5C13.4 8.5 14.5 9.6 14.5 11C14.5 12.4 13.4 13.5 12 13.5z"/>
                  </svg>
                </span>
                8, Euphrate Crescent, <br /> Maitama, FCT-Abuja
              </p>
              <p className="flex items-center text-gray-600 mt-2 py-2">
                <span className="mr-2">
                  {/* Phone Icon */}
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-purple-900" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M2 6.5C2 3.5 6.2 2 12 2s10 1.5 10 4.5v11c0 3-4.2 4.5-10 4.5S2 20.5 2 17.5v-11z"/>
                    <path d="M8.7 9.8l3.6 2.5 3.6-2.5L12.3 12l3.6 2.5-3.6 2.5-3.6-2.5L8.7 12z"/>
                  </svg>
                </span>
                <a href="tel:+2349137135974" className="text-purple-900 font-semibold">
                  +234 913 713 5974
                </a>
              </p>
              <p className="flex items-center text-gray-600 mt-2 py-2">
                <span className="mr-2">
                  {/* Email Icon */}
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-purple-900" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20 4H4V20H20V4zM19 5L12 12L5 5H19z"/>
                  </svg>
                </span>
                <a href="mailto:info@seismicconsults.com" className="text-purple-900 font-semibold">
                  info@seismicconsults.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="relative z-10 mt-8 py-4 pr-4 md:ml-4 md:mr-0 text-center text-sm rounded-lg" style={{ backgroundColor: '#F2E6F2', height: 'auto' }}>
        <div className="flex justify-between items-center max-w-6xl mx-auto px-4">
          <div className="flex space-x-4">

            <a href="terms-of-service" className="hover:underline text-purple-800 text-lg">Terms of Service</a>
          </div>
          <p className="text-gray-700">&copy; 2024 Seismic Consults Ltd. All Rights Reserved.</p>
          <div className="flex space-x-4">
            <a href="privacy-policy" className="hover:underline text-purple-800 text-lg">Privacy Policy</a>
            <a href="cookie-policy" className="hover:underline text-purple-800 text-lg">Cookie Policy</a>
          </div>
        </div>
      </div>

      {/* Adjustments for Phone Screens */}
      <style jsx>{`
        @media (max-width: 639px) {
          footer {
            padding: 2rem 1rem;
            text-align: center;
            overflow-x: hidden; /* Prevent horizontal scrolling */
          }
          /* Hide background image on small screens */
          .absolute {
            display: none;
          }
          /* Adjust content layout */
          .relative.max-w-6xl.mx-auto.grid {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            padding: 0 1rem;
          }
          .relative.max-w-6xl.mx-auto > div:first-child {
            margin-bottom: 2rem;
          }
          /* Right Column Adjustments */
          .flex.justify-between.items-start {
            flex-direction: column;
            align-items: center;
            margin-left: 0;
          }
          .mr-0, .ml-0 {
            margin-left: 0;
            margin-right: 0;
            text-align: center;
          }
          /* Bottom Section Adjustments */
          .relative.z-10.mt-8.py-4.pr-4 {
            margin-left: 0;
            margin-right: 0;
            height: auto;
            padding: 1rem;
          }
          .relative.z-10.mt-8.py-4.pr-4 .flex {
            flex-direction: column;
            align-items: center;
          }
          .relative.z-10.mt-8.py-4.pr-4 .flex.space-x-4 {
            margin-bottom: 1rem;
          }
          .relative.z-10.mt-8.py-4.pr-4 .flex.space-x-4 a {
            margin: 0 0.5rem;
          }
        }
      `}</style>
    </footer>
  );
};

export default FooterSection;

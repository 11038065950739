import React from "react";

const CommunityEmpowerment = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="bg-white pb-16 mt-20">
        <section
          className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736846391/pexels-minan1398-853168_rc5ltf.jpg')", // Replace with your actual image URL
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          {/* Title Overlay for Large Screens */}
          <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
            <h1 className="text-3xl font-bold text-gray-800">
              Community Empowerment and Capacity Building
            </h1>
          </div>
        </section>

        {/* Title Under Image for Small Screens */}
        <div className="block lg:hidden mt-4">
          <h1 className="text-2xl font-bold text-gray-800 text-center">
            Community Empowerment and Capacity Building
          </h1>
        </div>
      </section>

      {/* Context Section */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed mb-6">
          Sustainable poverty reduction requires building the capacity of
          communities to manage and lead their own social protection
          initiatives. Seismic provides training, resources, and guidance to
          empower local leaders and organizations, fostering a culture of
          self-reliance and social equity.
        </p>
        <p className="text-xl text-gray-600 leading-relaxed">
          At Seismic Consulting Group, we understand that sustainable poverty
          reduction cannot be achieved through external interventions alone.
          True, lasting impact comes when communities themselves have the tools,
          knowledge, and confidence to lead and manage their own social
          protection initiatives. Our approach to Community Empowerment and
          Capacity Building focuses on equipping local leaders, organizations,
          and residents with the skills and resources needed to drive social
          change, fostering a culture of self-reliance, ownership, and social
          equity.
        </p>
      </section>

      {/* Long-Term Community Growth Section */}
      <section className="py-8 px-8 lg:px-64">
        <h2 className="text-5xl font-medium text-gray-800 mb-6">
          How Seismic Support Long-Term Community Growth
        </h2>
        <div className="space-y-10">
          {/* Leadership Training */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Leadership Training and Development
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              A key component of our approach is the identification and
              nurturing of local leadership. Seismic invests in training and
              mentorship programs for community leaders, empowering them with
              the skills necessary to manage social protection programs,
              advocate for community needs, and navigate complex local
              governance systems. This training covers areas such as project
              management, negotiation, community mobilization, and policy
              advocacy, ensuring that leaders are equipped to take on strategic
              roles in community development.
            </p>
          </div>

          {/* Building Organizational Capacity */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Building Organizational Capacity
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              We strengthen local organizations and institutions by providing
              them with the tools, resources, and knowledge to effectively
              implement and manage social protection initiatives. This includes
              offering organizational capacity-building workshops, improving
              internal processes, and providing financial management training
              that ensures the funds are used efficiently and transparently.We
              also work on enhancing the technical capacities of these
              organizations, whether it's in data collection, monitoring, or
              reporting, enabling them to deliver better services to their
              communities.
            </p>
          </div>

          {/* Resource Mobilization */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Resource Mobilization and Fundraising
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Empowering communities to secure their own funding is crucial for
              long-term sustainability. Seismic helps local organizations and
              community leaders develop effective strategies for resource
              mobilization, including designing fundraising campaigns,
              identifying new sources of financing, and building partnerships
              with both public and private sector actors. By diversifying
              funding sources and ensuring communities can generate their own
              resources, we reduce dependence on external donors and foster a
              culture of self-sufficiency.
            </p>
          </div>

          {/* Monitoring and Evaluation */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Monitoring, Evaluation, and Adaptation
            </h3>

  <p className="text-xl text-gray-600 leading-relaxed mb-6">
    Sustainable poverty reduction requires building the capacity of
    communities to manage and lead their own social protection
    initiatives. Seismic provides training, resources, and guidance to
    empower local leaders and organizations, fostering a culture of
    self-reliance and social equity.
  </p>
  <p className="text-xl text-gray-600 leading-relaxed">
    At Seismic Consulting Group, we understand that sustainable poverty
    reduction cannot be achieved through external interventions alone.
    True, lasting impact comes when communities themselves have the tools,
    knowledge, and confidence to lead and manage their own social
    protection initiatives. Our approach to Community Empowerment and
    Capacity Building focuses on equipping local leaders, organizations,
    and residents with the skills and resources needed to drive social
    change, fostering a culture of self-reliance, ownership, and social
    equity.
  </p>
          </div>
        </div>
      </section>

            {/* Seismic's Approach Section */}
            <section className="py-16 px-4 lg:px-20">
        <div className="max-w-[1200px] mx-auto">
          <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
            Seismic's Approach to Reducing Poverty
          </h2>
          <p className="text-lg text-gray-600 text-center mb-10">
            Our approach combines rigorous analysis with creative problem-solving
            to address the root causes of poverty and inequality.
          </p>

          {/* Row 1 */}
          <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
            {/* Box 1 - Image and Text */}
            <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851836/pexels-tima-miroshnichenko-6474494_nbhrkk.jpg"
                alt="Comprehensive Needs Assessment"
                className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
              />
              <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
                <div>
                  <h3 className="text-lg font-bold mb-2">
                    Comprehensive Needs Assessment and Analysis
                  </h3>
                  <p className="text-gray-600 text-md leading-relaxed">
                    By analyzing demographic data, economic trends, and social
                    indicators, we tailor our social protection strategies to
                    address root causes of poverty specific to each region.
                  </p>
                </div>
                <a
                  href="/consulting-services/social-protection/comprehensive-needs-assessment"
                  className="text-purple-700 font-bold text-md flex items-center mt-2"
                >
                  Learn More
                  <svg
                    className="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>

            {/* Box 2 - Text Only */}
            <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
              <div>
                <h3 className="text-lg font-bold mb-2">
                  Integrated Social Protection Frameworks
                </h3>
                <p className="text-gray-600 text-lg leading-relaxed">
                  We create holistic social protection frameworks that integrate
                  safety nets, job creation initiatives, and targeted social
                  programs. Our frameworks combine interventions into a cohesive
                  support system.
                </p>
              </div>
              <a
                href="/consulting-services/social-protection/social-protection-frameworks"
                className="text-purple-700 font-bold text-md flex items-center mt-2"
              >
                Learn More
                <svg
                  className="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>

              {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Innovative Financing Mechanisms
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Recognizing the financial challenges in funding large-scale social
            protection programs, Seismic leverages innovative financing models such
            as social impact bonds, micro-loans, and public-private partnerships.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/innovative-financing-mechanisms"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851169/pexels-weekendplayer-187041_voljsh.jpg"
          alt="Innovative Financing Mechanisms"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-1">
              Data-Driven Targeting and Predictive Analytics
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              In the modern landscape of social protection and poverty reduction,
              traditional methods identifying and reaching vulnerable populations
              can be inefficient, often leading to misallocation of resources.
            </p>
          </div>
          <a
            href="/consulting-services/social-protection/data-driven-targeting"
            className="text-purple-700 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
        </div>
      </section>
    </div>
  );
};

export default CommunityEmpowerment;

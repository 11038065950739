import React from "react";

const DigitalFinancialInclusion = () => {
  return (
    <div className="max-w-7xl mx-auto py-16 px-6 lg:px-12 mt-20">
      {/* Title Section */}
      <div className="mb-12">
        <h1 className="text-3xl lg:text-5xl font-bold text-gray-900 mb-4">
          Empowering Communities through Digital Financial Inclusion
        </h1>
        <p className="text-gray-700 text-lg">
          As the world embraces digital transformation, financial inclusion has
          become a cornerstone for fostering economic growth and reducing
          poverty. Digital financial inclusion refers to the delivery of
          financial services through digital platforms, enabling underserved and
          unbanked populations to participate in the formal economy.
        </p>
      </div>

      {/* Story Section */}
      <div className="mb-12">
        <h2 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-4">
          A Story of Transformation: A Journey to Financial Empowerment
        </h2>
        <p className="text-gray-700 text-lg">
          In a small village in northern Nigeria, Fatima, a widowed mother of
          three, struggled to make ends meet. She sold handmade pottery in her
          local market, but her income was meager and inconsistent. Without
          access to traditional banking services, Fatima couldn’t save money,
          access credit, or expand her business.
        </p>
        <p className="text-gray-700 text-lg mt-4">
          One day, a community workshop introduced her to a mobile money
          platform supported by a local fintech startup. Skeptical but
          desperate, Fatima registered for the service using her basic mobile
          phone. For the first time, she could securely store her earnings, send
          payments, and even apply for a small business loan—all without leaving
          her village.
        </p>
        <p className="text-gray-700 text-lg mt-4">
          Within months, Fatima’s life transformed. She used the loan to buy
          better tools and raw materials, increasing the quality and quantity of
          her pottery. With her new income, she could afford school fees for her
          children and even started saving for future needs. The digital
          platform didn’t just connect her to financial services; it empowered
          her to dream bigger and achieve more.
        </p>
      </div>

      {/* Importance Section */}
      <div className="mb-12">
        <h2 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-4">
          The Significance of Digital Financial Inclusion
        </h2>
        <p className="text-gray-700 text-lg">
          Financial exclusion remains a critical challenge in many parts of the
          world, particularly in developing countries. Millions of people lack
          access to traditional banking services due to barriers such as
          geographical limitations, high transaction costs, and lack of
          financial literacy. Digital financial inclusion addresses these gaps
          by:
        </p>
        <ul className="list-disc pl-6 mt-4 text-gray-700 text-lg space-y-2">
          <li>
            <strong>Expanding Access:</strong> Digital platforms eliminate the
            need for physical bank branches, making financial services
            accessible in remote and underserved areas.
          </li>
          <li>
            <strong>Lowering Costs:</strong> Mobile banking and fintech
            solutions reduce transaction costs, enabling affordable financial
            services for low-income populations.
          </li>
          <li>
            <strong>Fostering Economic Growth:</strong> Access to financial
            services allows individuals to save, invest, and participate in
            entrepreneurial activities, boosting local economies.
          </li>
          <li>
            <strong>Enhancing Resilience:</strong> Digital wallets and mobile
            payments provide a secure way to manage money, reducing risks
            associated with cash handling.
          </li>
        </ul>
      </div>

      {/* Challenges Section */}
      <div className="mb-12">
        <h2 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-4">
          Challenges and the Way Forward
        </h2>
        <p className="text-gray-700 text-lg">
          While digital financial inclusion holds immense potential, challenges
          such as digital illiteracy, cybersecurity risks, and infrastructure
          gaps persist. To address these issues:
        </p>
        <ul className="list-disc pl-6 mt-4 text-gray-700 text-lg space-y-2">
          <li>
            <strong>Invest in Infrastructure:</strong> Expand internet
            connectivity and mobile network coverage.
          </li>
          <li>
            <strong>Promote Digital Literacy:</strong> Equip individuals with
            the skills to use digital financial services effectively.
          </li>
          <li>
            <strong>Ensure Security and Privacy:</strong> Develop robust
            cybersecurity measures to protect users.
          </li>
          <li>
            <strong>Adopt Inclusive Policies:</strong> Design regulations that
            foster innovation while ensuring equitable access.
          </li>
        </ul>
      </div>

      {/* Key Components Section */}
      <div className="mb-12">
        <h2 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-4">
          Key Components of Digital Financial Inclusion
        </h2>
        <ul className="list-disc pl-6 text-gray-700 text-lg space-y-2">
          <li>
            <strong>Mobile Banking and Payment Systems:</strong> Mobile money
            services enable users to transfer funds, pay bills, and access
            credit through their phones.
          </li>
          <li>
            <strong>Fintech Innovations:</strong> Digital lending platforms
            offer quick and accessible credit options for small businesses and
            individuals.
          </li>
          <li>
            <strong>Digital Identity Systems:</strong> Biometric
            identification and digital IDs ensure secure and inclusive access to
            financial services.
          </li>
          <li>
            <strong>Financial Literacy:</strong> Education initiatives equip
            communities with the knowledge to use digital financial tools
            effectively.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DigitalFinancialInclusion;

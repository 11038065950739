import React from "react";

const InnovativeFinancingMechanisms = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="bg-white pb-16 mt-20">
        <section
          className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1733493169/pfm_hero_pocban.png')", // Replace with your actual image URL
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          {/* Title Overlay for Large Screens */}
          <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
            <h1 className="text-3xl font-bold text-gray-800">
              Innovative Financing Mechanisms
            </h1>
          </div>
        </section>

        {/* Title Under Image for Small Screens */}
        <div className="block lg:hidden mt-4">
          <h1 className="text-2xl font-bold text-gray-800 text-center">
            Innovative Financing Mechanisms
          </h1>
        </div>
      </section>

      {/* Context Section */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed mb-6">
          Recognizing the financial challenges in funding large-scale social protection
          programs, Seismic leverages innovative financing models such as social impact
          bonds, micro-loans, and public-private partnerships. These mechanisms attract
          private and institutional investments, amplifying the reach and impact of
          social programs without overwhelming government budgets.
        </p>
        <p className="text-xl text-gray-600 leading-relaxed">
          In the face of growing financial constraints, especially in developing regions,
          traditional funding models for social protection programs may fall short. At
          Seismic Consulting Group, we understand that to achieve significant, scalable
          impact, we must explore innovative financing mechanisms that can mobilize
          diverse funding sources while minimizing the burden on public finances. These
          models not only drive investment into social programs but also foster long-term
          sustainability and collaboration across sectors.
        </p>
      </section>

      {/* How We Execute Section */}
      <section className="py-8 px-8 lg:px-64">
        <h2 className="text-2xl lg:text-3xl font-medium text-gray-800 mb-6">
          Here's how we apply innovative financing mechanisms to enhance social
          protection and poverty reduction:
        </h2>
        <div className="space-y-10">
          {/* Social Impact Bonds */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Social Impact Bonds
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Social Impact Bonds are a groundbreaking financing model that ties the
              return on investment to the measurable success of social programs. Under
              this model, private investors provide upfront capital to fund social
              initiatives, such as education, healthcare, or employment training. If the
              program achieves its predetermined social outcomes, investors receive a
              return from the government or other stakeholders. This aligns financial
              incentives with social outcomes, encouraging performance-driven
              investments and scaling successful interventions.
            </p>
          </div>

          {/* Micro-Loans and Microfinance */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Micro-Loans and Microfinance
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Micro-loans provide small-scale, affordable loans to individuals or groups,
              often in underserved communities, to support income-generating activities
              such as starting or expanding small businesses. By tapping into local savings
              and enabling access to credit, microfinance encourages entrepreneurship and
              self-sufficiency. Seismic leverages micro-loans as a tool for economic
              empowerment, providing individuals the means to lift themselves out of
              poverty while creating sustainable livelihoods.
            </p>
          </div>

          {/* Public-Private Partnerships */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Public-Private Partnerships (PPPs)
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Public-private partnerships allow for collaboration between government
              agencies and private entities to fund and implement large-scale social
              protection programs. These partnerships bring together the expertise,
              resources, and innovation of the private sector with the reach and policy
              mandate of the public sector. Through PPPs, Seismic helps design and execute
              programs that improve access to healthcare, education, and employment
              opportunities while sharing the financial burden across stakeholders. These
              partnerships not only expand financial resources but also improve program
              efficiency and sustainability.
            </p>
          </div>

          {/* Blended Finance */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Blended Finance
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Blended finance is a mechanism that combines public, private, and
              philanthropic capital to finance development projects. This approach helps
              reduce the financial risks for private investors by leveraging public funds
              to attract private investments. In the context of social protection and
              poverty reduction, blended finance can fund initiatives like infrastructure
              development, social housing, or skills training programs, which provide
              long-term benefits for the community while attracting private capital to
              scale these efforts.
            </p>
          </div>

          {/* Impact Investment */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Impact Investment
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Seismic also leverages impact investment, where capital is invested into
              social enterprises or businesses that generate measurable social and
              environmental outcomes alongside financial returns. These investments
              support initiatives such as sustainable agriculture, renewable energy, or
              affordable housing, which create jobs, reduce poverty, and improve living
              standards. Impact investors are driven by the desire for both social impact
              and financial return, providing much-needed capital to scale social
              protection programs.
            </p>
          </div>

          {/* Crowdfunding and Community-Based Financing */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Crowdfunding and Community-Based Financing
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              In certain contexts, community-based financing and crowdfunding initiatives
              can also be effective tools for raising capital for specific projects. These
              models tap into local or global networks to gather small contributions that
              collectively fund a large-scale program. Seismic uses this model to mobilize
              resources for targeted projects, like disaster recovery, community health
              clinics, or educational scholarships, creating a sense of ownership and
              engagement among local stakeholders.
            </p>
          </div>
        </div>
      </section>
      {/* Seismic's Approach Section */}
<section className="py-16 px-4 lg:px-20">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Seismic's Approach to Reducing Poverty
    </h2>
    <p className="text-lg text-gray-600 text-center mb-10">
      Our approach combines rigorous analysis with creative problem-solving
      to address the root causes of poverty and inequality.
    </p>

    {/* Row 1 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 1 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851836/pexels-tima-miroshnichenko-6474494_nbhrkk.jpg"
          alt="Comprehensive Needs Assessment"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2">
              Comprehensive Needs Assessment and Analysis
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              By analyzing demographic data, economic trends, and social indicators,
              we tailor our social protection strategies to address root causes of
              poverty specific to each region.
            </p>
          </div>
          <a
            href="/consulting-services/social-protection/comprehensive-needs-assessment"
            className="text-purple-700 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>

      {/* Box 2 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Integrated Social Protection Frameworks
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            We create holistic social protection frameworks that integrate safety
            nets, job creation initiatives, and targeted social programs. Our
            frameworks combine interventions into a cohesive support system.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/integrated-social-protection"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>


    {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Community Empowerment and Capacity Building
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Sustainable poverty reduction requires building the capacity of
            communities to manage and lead their own social protection initiatives.
            Seismic provides training, resources, and guidance to empower local
            leaders and organizations, fostering a culture of self-reliance and
            social equity.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/community-empowerment"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Monitoring, Evaluation, and Adaptive Learning
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Seismic uses adaptive learning to refine and improve social protection
            programs over time, ensuring that each initiative remains responsive to
            evolving community needs and delivers measurable results.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/monitoring-evaluation"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default InnovativeFinancingMechanisms;

import React, { useState } from "react";

const EnergyEfficiency = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737623132/pexels-photo-532192_gajfif.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Energy Efficiency and Conservation
          </h1>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <p className="text-gray-700 text-lg mb-4">
          At Seismic Consulting Group, we understand that energy efficiency is not just a strategic goal—it is a critical necessity for organizations to remain competitive and sustainable in today’s rapidly evolving energy landscape.
        </p>
        <p className="text-gray-700 text-lg">
          As businesses face rising energy costs, stricter regulatory requirements, and growing pressure to minimize their environmental impact, improving energy efficiency is paramount. Our comprehensive approach enables organizations to reduce energy consumption, cut operational costs, and significantly lower their carbon footprint, while optimizing performance and ensuring long-term sustainability.
        </p>
      </div>

{/* Accordion Section */}
<div className="py-16 max-w-6xl mx-auto px-6 lg:px-20">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Customized Energy Efficiency Strategies
  </h2>
  <p className="text-gray-700 text-lg text-center mb-6">
    Every organization is unique, with its own energy needs, operational structure, and challenges. Our first step in promoting energy efficiency is conducting a detailed energy audit, which provides a clear, data-driven understanding of current energy usage patterns, inefficiencies, and opportunities for improvement. We analyze every facet of energy consumption within your operations—from lighting and HVAC systems to production processes and equipment use—and develop tailored solutions that prioritize the most impactful areas for optimization.
  </p>

  <div className="space-y-8">

    {/* Implementing Advanced Efficiency Technologies */}
    <div className="p-2">
      <h3 className="text-2xl font-semibold text-gray-900 mb-4">
        Implementing Advanced Efficiency Technologies
      </h3>
      <div>
        <p className="text-gray-700 text-lg mb-6">
          To enhance energy efficiency, we incorporate the latest in advanced technologies and innovative systems. Our solutions include:
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-2">
              Smart Building Technologies
            </h4>
            <p className="text-gray-700">
              These systems use sensors, automation, and data analytics to optimize energy use in real time. For example, intelligent lighting and HVAC systems can adjust based on occupancy, weather conditions, or real-time energy demand, ensuring that energy is only used when and where it is needed.
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-2">
              Energy Management Software
            </h4>
            <p className="text-gray-700">
              We implement software solutions that provide detailed insights into energy consumption, enabling organizations to monitor, manage, and report on energy use across all facilities.
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-2">
              High-Efficiency Equipment
            </h4>
            <p className="text-gray-700">
              Replacing outdated equipment with energy-efficient models, such as LED lighting, high-efficiency motors, and smart thermostats, can lead to significant reductions in energy use and maintenance costs.
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-2">
              Energy Storage Solutions
            </h4>
            <p className="text-gray-700">
              Incorporating energy storage systems, such as batteries, allows businesses to store excess energy generated from renewable sources or off-peak periods and use it during peak demand.
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* Optimizing Operational Processes */}
    <div className="p-2">
      <h3 className="text-2xl font-semibold text-gray-900 mb-4">
        Optimizing Operational Processes
      </h3>
      <div>
        <p className="text-gray-700 text-lg mb-6">
          Energy efficiency is not limited to technological upgrades—it also involves optimizing operational practices to reduce waste and improve productivity. We work with organizations to assess their processes and identify areas where energy consumption can be reduced without sacrificing performance or quality. This includes:
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-2">
              Process Optimization
            </h4>
            <p className="text-gray-700">
              Streamlining operations, such as improving the efficiency of manufacturing lines or data centers, can lead to substantial energy savings. We help identify and implement energy-efficient best practices, such as waste heat recovery systems, more efficient production schedules, and automation solutions.
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-2">
              Employee Engagement and Training
            </h4>
            <p className="text-gray-700">
              Educating employees about energy conservation practices and encouraging behavioral changes are key components of long-term energy efficiency. We provide training programs to foster a culture of energy mindfulness across all departments, from leadership to staff.
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* Environmental Impact and Sustainability Goals */}
    <div className="p-2">
      <h3 className="text-2xl font-semibold text-gray-900 mb-4">
        Environmental Impact and Sustainability Goals
      </h3>
      <p className="text-gray-700 text-lg">
        In addition to cost savings, improving energy efficiency directly contributes to an organization’s sustainability goals. By reducing energy consumption, companies not only lower their operational costs but also decrease their carbon emissions and overall environmental impact. We work with clients to align energy efficiency efforts with broader sustainability targets, helping them reduce their carbon footprint and contribute to global efforts in mitigating climate change.
      </p>
    </div>

    {/* Regulatory Compliance and Incentives */}
    <div className="p-2">
      <h3 className="text-2xl font-semibold text-gray-900 mb-4">
        Regulatory Compliance and Incentives
      </h3>
      <p className="text-gray-700 text-lg">
        As governments worldwide impose stricter environmental regulations, organizations must stay ahead of compliance requirements. Our team is well-versed in the latest energy efficiency regulations and incentive programs, ensuring that businesses can navigate these complexities while maximizing available financial benefits, such as rebates, tax credits, and energy performance certifications. By helping organizations meet compliance standards, we ensure they avoid potential penalties, enhance their brand image, and remain competitive in markets where sustainability is increasingly becoming a key differentiator.
      </p>
    </div>

    {/* Long-Term Financial Benefits */}
    <div className="p-2">
      <h3 className="text-2xl font-semibold text-gray-900 mb-4">
        Long-Term Financial Benefits
      </h3>
      <p className="text-gray-700 text-lg">
        Energy efficiency improvements lead to substantial long-term financial savings. From lower utility bills to reduced maintenance costs, businesses can see a significant return on investment (ROI) for energy efficiency projects. These savings can be reinvested in other areas of the business, enhancing productivity, innovation, and overall profitability. Additionally, adopting energy-efficient practices helps businesses hedge against future energy price volatility, ensuring stability in operating costs.
      </p>
    </div>

    {/* Continuous Monitoring and Improvement */}
    <div className="p-2">
      <h3 className="text-2xl font-semibold text-gray-900 mb-4">
        Continuous Monitoring and Improvement
      </h3>
      <p className="text-gray-700 text-lg">
        Energy efficiency is an ongoing process. As energy landscapes and technologies evolve, so too must an organization’s energy strategy. Seismic Consulting Group provides continuous monitoring and performance tracking to ensure that the implemented strategies continue to deliver optimal results. By leveraging advanced analytics and regular energy audits, we help businesses adjust and further improve efficiency over time.
      </p>
    </div>
  </div>
</div>

      {/* The Seismic Advantage Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-4">The Seismic Advantage</h2>
            <p className="text-gray-900 text-lg mb-4">
              Our approach to energy efficiency is comprehensive, data-driven, and highly customized, ensuring that every energy-saving opportunity is maximized.
            </p>
            <p className="text-gray-600 text-lg">
              Together, we ensure that your energy efficiency strategies contribute to both immediate and long-term success in a rapidly changing world.
            </p>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737623109/windrader-wind-power-fichtelberg-wind-park_wg6ctn.jpg"
              alt="The Seismic Advantage"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyEfficiency;


import React from "react";

export default function JibrinHarunaProfile() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero / Top Section */}
      <div className="relative w-full bg-gradient-to-r from-gray-900 to-gray-400 h-56 md:h-64 mt-20">
        <div className="max-w-7xl mx-auto px-4 md:px-8 h-full flex items-center">
          {/* Left Text */}
          <div className="text-white w-full md:w-1/2">
            <h1 className="text-xl md:text-2xl font-bold mb-2">
              Technical Team Lead
            </h1>
            <p className="text-sm md:text-base leading-relaxed">
              IT &amp; Digital Transformation Expert
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto py-12 px-6 md:px-12">

      <section className="mb-10">
  <p className="text-lg leading-relaxed">
    As the Technical Team Lead, Jibrin plays a pivotal role in shaping our
    organization’s digital transformation strategies. Drawing on expertise
    in cross-functional project management, He oversees innovative
    technology solutions—from AI-driven platforms to robust cloud
    infrastructures—that streamline operations and drive tangible business
    value. By maintaining close collaboration across departments, Jibrin
    ensures that each digital initiative aligns seamlessly with the
    organization’s broader objectives.
  </p>
  <p className="text-lg leading-relaxed mt-4">
    Having honed skills in data science, machine learning, and advanced
    analytics through diverse industry experiences, Jibrin leverages a
    strategic blend of technical acumen and leadership to foster
    cutting-edge solutions. This dual focus—on both the 'how' and the
    'why'—enables the IT team to address complex operational challenges
    while laying the groundwork for continuous innovation. By integrating
    emerging technologies and agile methodologies, He not only
    enhances process efficiency but also propels the organization toward
    achieving a competitive edge in today’s rapidly evolving digital
    landscape.
  </p>
</section>

        {/* Q&A Introduction */}
        <section className="mb-10">
          <p className="text-lg leading-relaxed">
            Below is an in-depth conversation exploring the Technical Team Lead’s
            responsibilities, cross-departmental coordination strategies, and the
            digital projects that power our firm’s consulting mission.
          </p>
        </section>

        {/* Position & Responsibilities */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Position &amp; Main Responsibilities
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Can you describe your position and the main responsibilities
                you hold within the IT department?
              </h3>
              <p className="text-lg mt-2 leading-relaxed mb-4">
              As Technical Team Lead, I oversee project planning by setting clear daily, weekly, and monthly goals, and then delegating tasks that align with both quality standards and strategic objectives. I also ensure transparent communication channels among team members, management, and clients—fostering alignment on project progress and delivering timely, high-quality outcomes.
              </p>
              <p className="text-lg mt-2 leading-relaxed mb-4">
              I take a proactive approach to risk management by identifying potential pitfalls early and scheduling targeted training sessions to drive continuous process improvements. Staying current with industry trends allows me to implement cutting-edge solutions and troubleshoot effectively. Lastly, I focus on motivating the team by encouraging open idea exchange, maintaining transparency about challenges and successes, and compiling detailed progress reports for key stakeholders.
              </p>

            </div>
          </div>
        </section>

        {/* Coordination with Other Departments */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Cross-Departmental Coordination
          </h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: How do you coordinate with other departments to ensure digital
                projects align with the company’s objectives?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                I coordinate with other departments by establishing a structured,
                multi-layered approach that ensures every digital project supports
                our company’s overarching objectives. For example, I initiate
                regular cross-functional alignment meetings where department
                heads—from IT and research to finance and operations—review
                project progress, discuss potential challenges, and adjust plans
                as needed. This collaborative forum guarantees that our technical
                efforts remain in sync with strategic business goals.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                I also proactively engage with each department to understand their
                unique challenges and priorities, integrating this feedback into
                the project roadmap. This way, we not only maintain alignment with
                our strategic goals but also empower teams to collaboratively
                solve problems, ensuring that our digital projects drive tangible
                business value across the organization.
              </p>
            </div>
          </div>
        </section>

        {/* Interest in Technology & Leadership Journey */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Passion for Technology &amp; Path to Leadership
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: What sparked your interest in technology, and how did you
                arrive at a leadership position in IT and digital transformation?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                My passion for technology began at an early age—fueled by the
                excitement of exploring how computers work and how code can
                transform ideas into reality. As I delved deeper into computer
                engineering studies and took on hands-on technical projects, I
                quickly realized that my curiosity extended beyond just writing
                code. I became fascinated by the way technology could drive
                business innovation and streamline operations, which naturally led
                me to pursue roles where I could blend technical expertise with
                strategic leadership.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                Early in my career, I actively sought out opportunities to
                implement digital solutions that solved real-world challenges.
                Whether it was automating routine tasks or integrating new
                software platforms, each experience provided valuable insights
                into both the potential of technology and the importance of
                aligning IT initiatives with broader business goals. Mentorship
                from experienced leaders further enriched my journey, helping me
                develop a holistic understanding of digital transformation and the
                ability to inspire and guide teams.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                Over time, as I transitioned from individual contributor roles to
                positions where I was responsible for leading cross-functional
                teams, I honed my skills in project management, collaboration, and
                strategic planning. This evolution was driven by a commitment to
                continuous learning and a genuine desire to leverage technology to
                create tangible business value. Today, my leadership is centered
                on ensuring that our digital projects not only meet technical
                standards but also align seamlessly with our company’s strategic
                objectives, thereby driving innovation and growth across the
                organization.
              </p>
            </div>
          </div>
        </section>

        {/* Previous Roles & Experiences */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Previous Roles &amp; Expertise
          </h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Could you share a bit about your previous roles or experiences
                that shaped your expertise?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                In my early career, I began as a data scientist at a rapidly
                growing tech startup. In that role, I was immersed in advanced
                data analysis, machine learning, and statistical modeling to
                derive actionable insights from complex datasets. I built
                predictive models that identified emerging market trends and
                optimized operational strategies—experiences that instilled in me
                a deep appreciation for data-driven decision-making and the
                transformative power of technology.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                Seeking to expand my impact, I later transitioned into a
                consulting role at a global advisory firm. There, I collaborated
                with cross-functional teams across industries such as healthcare,
                education, mining and finance. I led several digital
                transformation initiatives that integrated cloud computing,
                advanced analytics, and AI-powered automation into legacy systems.
                These projects not only enhanced operational efficiencies for our
                clients but also reinforced the critical connection between
                technology innovation and strategic business outcomes.
              </p>
            </div>
          </div>
        </section>

        {/* Current Digital Projects */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Key Digital Projects &amp; Innovations
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: What are some of the most important digital projects you’re
                currently overseeing?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Currently, I’m spearheading several digital initiatives that not
                only drive innovation within our firm but also amplify the value
                we deliver to our clients.
              </p>
              <ul className="list-disc list-inside mt-2 text-lg leading-relaxed">
                <li>
                  <strong>Classify:</strong> This AI-powered platform assists
                  educators in generating schemes of work, lesson plans, and
                  lesson notes that are aligned with Nigerian academic standards.
                  By streamlining curriculum creation and ensuring cultural
                  relevance, Classify not only transforms educational processes
                  but also provides a compelling case study on how digital
                  innovation can drive efficiency and quality improvements in the
                  education sector.
                </li>
                <li>
                  <strong>Transafe:</strong> Focused on urban transportation,
                  Transafe is an AI-driven Mobility-as-a-Service (MaaS) platform
                  designed to address the complexities of managing commercial
                  vehicle operations in Nigeria. It offers a comprehensive
                  ecosystem that enhances commuter experiences and delivers
                  actionable insights to stakeholders. This project demonstrates
                  our ability to apply digital solutions to complex logistical
                  challenges, aligning with our consulting mission by creating
                  scalable models for urban mobility transformation.
                </li>
                <li>
                  <strong>medAIDE:</strong> This comprehensive hospital management
                  system digitizes end-to-end workflows in healthcare. With
                  features like real-time SOAP Note transcription for
                  doctor-patient consultations, integrated appointment scheduling,
                  inventory management, and billing processes, medAIDE enhances
                  operational efficiency and patient care. Its success reinforces
                  our commitment to leveraging digital transformation to improve
                  public service delivery and streamline critical healthcare
                  operations.
                </li>
                <li>
                  <strong>PalmNews:</strong> Leveraging artificial intelligence,
                  PalmNews automatically summarizes news articles from reliable
                  sources into concise, personalized, and engaging summaries. By
                  transforming the way information is consumed and delivered, it
                  not only improves user engagement in the media space but also
                  serves as a practical example of how digital projects can
                  redefine communication channels and content delivery for modern
                  audiences.
                </li>
                <li>
                  <strong>MentalNg:</strong> Dedicated to the diagnosis and
                  understanding of mental health issues—specifically ADHD—MentalNg
                  uses AI to provide insightful predictions based on user inputs
                  through an intuitive web interface. This platform illustrates
                  our firm’s commitment to deploying digital solutions in the
                  healthcare domain, helping to improve diagnostic processes and
                  ultimately supporting better mental health outcomes.
                </li>
              </ul>
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: How do these projects support our firm’s broader consulting
                mission?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Together, these digital projects support our broader consulting
                mission by reinforcing our commitment to innovation and
                technology-led growth. They ensure that our consulting solutions
                are underpinned by the latest digital capabilities, enabling us to
                deliver measurable business outcomes and maintain a competitive
                edge in the market.
              </p>
            </div>
          </div>
        </section>

        {/* IT Department Structure */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            IT Department &amp; Team Organization
          </h2>
          <p className="text-lg leading-relaxed mb-6">
          Our IT department operates within a matrix structure that unites diverse specialists—such as UI/UX designers, front-end and back-end engineers, AI engineers, a project manager, and a data analyst—into a cohesive, cross-functional team. By leveraging agile methodologies and project management tools like Asana or Trello, we conduct daily stand-ups and periodic review sessions to ensure every stakeholder is aligned on goals and progress. This transparent, collaborative approach enables us to deliver high-impact, client-centric digital solutions that consistently advance our broader consulting mission.
          </p>
          <p className="text-lg leading-relaxed">
            To ensure that all these teams—ranging from technical developers to
            consultants and business analysts—stay aligned, we embrace agile
            methodologies and use integrated project management tools like Asana
            or Trello. We hold daily stand-ups and periodic cross-departmental
            review sessions that provide real-time updates, foster open
            communication, and allow us to quickly address any challenges. This
            integrated, transparent approach ensures that every team member is
            informed and working in concert towards our firm’s broader consulting
            mission, ultimately delivering high-impact, client-centric digital
            solutions.
          </p>
        </section>

        {/* Recent Digital Project Example: ASC */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Recent Process Improvements
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Can you highlight a recent digital project that significantly
                improved processes or client outcomes?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                One recent project that truly stands out is{" "}
                <strong>ASC</strong>—a comprehensive digital data collection tool
                deployed across Oyo, Katsina, and Adamawa states. ASC is designed
                to gather detailed information from both public and private
                primary, secondary, and junior secondary schools, streamlining the
                way educational data is collected and analyzed.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                A key feature of ASC is its offline-first capability. Field data
                collectors can capture information even in areas where internet
                connectivity is unreliable or unavailable. Once a connection is
                established, the system automatically synchronizes the data with
                our central database, ensuring that no critical information is
                lost and that the insights are updated in real time.
              </p>
            </div>
          </div>

          {/* Challenges & Solutions */}
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: What were the main challenges and how did you overcome them?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                One of the main challenges we faced was the inconsistent internet
                access in remote areas. Traditional digital tools often fail in
                such environments, leading to fragmented or delayed data
                collection. To overcome this, we developed ASC with robust offline
                functionality and a reliable synchronization mechanism. We also
                conducted thorough training sessions with local school
                administrators and data collectors to ensure they could easily
                navigate the tool and maintain high data quality.
              </p>
            </div>
          </div>
        </section>

        {/* AI/ML Background */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            AI &amp; Data Science Expertise
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: You have a background in [AI/ML/data science]. What drew you to
                that field originally?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                I’ve always been captivated by the transformative power of data
                and the potential of intelligent algorithms. Early on, I realized
                that AI and machine learning were not just about crunching
                numbers—they were about uncovering patterns and insights that
                could fundamentally change decision-making. This realization
                sparked my passion for the field and led me to pursue specialized
                training, hands-on projects, and continuous learning in AI/ML and
                data science.
              </p>
              <p className="text-lg mt-2 leading-relaxed">I use my AI and data analytics background to ensure our digital strategies are firmly rooted in data-driven insights, minimizing risks and maximizing client value. By staying current with emerging AI trends, I identify opportunities to automate repetitive tasks, streamline workflows, and improve operational efficiency—leading to scalable, high-impact solutions. In addition, I bridge the gap between technical capabilities and business objectives by collaborating with cross-functional teams, ensuring that every AI-powered initiative aligns with our broader consulting mission and yields measurable outcomes.</p>
              
            </div>
          </div>
        </section>

        {/* Future of IT Department */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            The Path Ahead for IT &amp; Digital Transformation
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Where do you see the IT department heading in the next few
                years? Are there any new technologies or methodologies you want to
                introduce?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Looking ahead, I see our IT department evolving into an agile,
                innovation-driven powerhouse that not only supports our digital
                projects but also serves as a strategic driver of business growth.
                Our focus will be on embracing emerging technologies and modern
                methodologies that enhance efficiency, foster collaboration, and
                ultimately deliver tangible client value.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                For example, we plan to deepen our integration of AI across our
                systems—not only to automate routine tasks and optimize our
                operations but also to build advanced data analytics capabilities
                that inform strategic decision-making. We’re exploring generative
                AI for content automation and predictive analytics, while also
                considering blockchain for enhanced security and transparency in
                our transaction processes.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                On the methodology side, we’re committed to further adopting agile
                and DevOps practices to accelerate our development cycles, improve
                cross-functional collaboration, and ensure rapid iteration. This
                includes leveraging cloud-native architectures, containerization,
                and microservices to build scalable, resilient systems that can
                adapt quickly to changing market conditions.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                Ultimately, our vision is to transform the IT department into a
                central innovation hub—one that continuously invests in cutting-
                edge technologies and promotes a culture of continuous learning.
              </p>
            </div>
          </div>

          {/* Team Expansion */}
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Do you anticipate expanding the team to support more complex
                digital initiatives?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                We do anticipate expanding our team as our digital initiatives
                grow in complexity. As we take on more multifaceted projects, our
                strategy involves both recruiting new talent and developing the
                skills of our current staff to ensure that we’re always at the
                forefront of technology innovation.
              </p>
            </div>
          </div>

          {/* Critical Skill Sets */}
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Which skill sets or roles are most critical as we grow?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
              We’re focusing on four critical skill areas as we expand. First, advanced data and AI specialists—including data scientists and AI/ML engineers—will drive innovation by turning raw data into insights and powering automation. Second, cloud architects and cybersecurity experts will ensure our systems are both scalable and secure. Third, agile practitioners and DevOps engineers help us maintain rapid, iterative development cycles and cross-functional collaboration. Finally, user-centric designers and frontend developers are key to creating intuitive, engaging digital experiences that directly impact user satisfaction and client success.
              </p>
            </div>
          </div>
        </section>

        {/* Impact on Clients & Industry */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Digital Transformation &amp; Industry Impact
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: How do you envision the firm’s digital transformation journey
                impacting its clients and the industry?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                I envision our firm’s digital transformation journey as a
                transformative force that not only reshapes our internal
                capabilities but also fundamentally elevates the way our clients
                do business and how the industry evolves. By integrating advanced
                technologies—such as AI, cloud computing, and data
                analytics—into our processes, we’re setting the stage for our
                clients to achieve new levels of efficiency, agility, and
                competitive differentiation. Our digital initiatives will empower
                organizations to uncover actionable insights, streamline
                operations, and create innovative business models, thereby
                transforming challenges into opportunities for growth.
              </p>
            </div>
          </div>

          {/* Leadership Legacy */}
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: What legacy or major milestone do you hope to achieve under
                your leadership?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Under my leadership, I aspire to establish a legacy of pioneering
                innovation. One major milestone I hope to achieve is the
                development of an integrated digital ecosystem that becomes the
                industry benchmark for delivering measurable outcomes. This
                ecosystem will seamlessly connect advanced analytics, realtime
                insights, and agile project management to drive both revenue
                growth and operational excellence. In doing so, our firm will not
                only reinforce its position as a trusted advisor in digital
                transformation consulting but also inspire a broader shift in the
                industry—demonstrating that the successful adoption of digital
                technologies leads to sustainable, competitive advantage.
              </p>
            </div>
          </div>
        </section>

        {/* Intersection of IT, Digital Transformation & AI */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Excitement for IT &amp; AI
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: What excites you most about working at the intersection of IT,
                digital transformation, and (occasionally) AI?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                What excites me most about working at the intersection of IT,
                digital transformation, and AI is the constant opportunity to
                redefine how businesses operate and how people live. The dynamic
                nature of this space means that every day brings new challenges
                and innovative solutions—from streamlining workflows with
                automation to creating smarter, more responsive systems that
                empower organizations to make data-driven decisions. I’m energized
                by the idea that even incremental technological improvements can
                have exponential impacts on efficiency, competitiveness, and
                customer satisfaction.
              </p>
            </div>
          </div>

          {/* Influences & Leadership Style */}
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Are there any personal or professional influences that have
                shaped your approach to technology?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                On a personal and professional level, my approach to technology
                has been shaped by influential thought leaders and mentors who
                stressed the importance of continuous learning and creative
                problem-solving. I’ve been inspired by leaders who advocate for an
                agile mindset and a user-centric design philosophy—principles that
                ensure technology serves as an enabler of positive change rather
                than an end in itself.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                My leadership style within the tech team is fundamentally
                collaborative and adaptive. I believe in leading by
                example—empowering team members to share ideas and take ownership
                of their work while maintaining open, transparent communication.
              </p>
              <p className="text-lg mt-2 leading-relaxed">
                In terms of values, my decision-making and innovation efforts are
                guided by integrity, accountability, and a commitment to
                data-driven insights. I prioritize ethical standards and
                transparency in all our projects, ensuring that every initiative
                is not only technically sound but also aligned with our broader
                business goals.
              </p>
            </div>
          </div>
        </section>

        {/* Career Growth Tips */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Tips for Aspiring Tech Professionals
          </h2>
          <div className="space-y-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: Any tips for those looking to grow their careers in IT, digital
                transformation, or AI?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
              Maintain a lifelong learning mindset by pursuing formal education, certifications, and continuous self-improvement in both technical and soft skills. Stay versatile by exploring diverse technologies—like AI, cloud computing, and data analytics—and integrating them into holistic solutions. Seek mentorship and cultivate a strong professional network to gain valuable guidance and uncover new opportunities. Finally, hone your problem-solving abilities through real-world challenges—internships, hackathons, or personal projects—to deepen your expertise and build confidence.
              </p>
            </div>
          </div>
        </section>

        {/* Digital Solutions & Broader Impact */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-6">
            Digital Solutions for Better Outcomes
          </h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">
                Q: How do you see digital solutions driving better outcomes—for
                our clients, our company, and society at large?
              </h3>
              <p className="text-lg mt-2 leading-relaxed">
                Looking toward a more digital future, I envision digital solutions
                as powerful catalysts for change across all facets of business and
                society. For our clients, leveraging digital tools means more
                agile operations, data-driven insights, and personalized customer
                experiences that drive competitive advantage. For our company, it
                transforms our internal processes, allowing us to innovate faster
                and work more collaboratively across teams. On a broader scale,
                digital transformation can democratize access to critical services
                such as healthcare, education, and government
                operations—ensuring that technological advances translate into
                improved outcomes and quality of life for society as a whole.
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Contact Jibrin */}
      <div className="bg-purple-50 text-center py-6 px-4 md:px-8">
        <h4 className="text-xl font-semibold mb-4">Contact Jibrin</h4>
        <div className="flex justify-center space-x-8 mt-8">
          {/* LinkedIn Icon Only */}
          <a
            href="https://www.linkedin.com/in/jibrinharuna"
            target="_blank"
            rel="noopener noreferrer"
            className="transition hover:opacity-80"
          >
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738335197/10091797_kdndmj.png"
              alt="LinkedIn Icon"
              className="w-12 h-12 object-contain"
            />
          </a>

          {/* Email Icon Only */}
          <a
            href="mailto:jibrinharuna07@gmail.com"
            className="transition hover:opacity-80"
          >
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738335233/10506089.png_lggulu.png"
              alt="Email Icon"
              className="w-12 h-12 object-contain"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useRef } from "react";

const KeyAreasOfServices = () => {
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef({}); // Create a ref object to store references to the sections

  const toggleSection = (section) => {
    setActiveSection((prev) => (prev === section ? null : section));
  };

  const scrollToSection = (section) => {
    // Scroll the section into view if it's active
    if (sectionRefs.current[section]) {
      sectionRefs.current[section].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const sections = [
    {
      title: "Project Performance Audits",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1739186113/pexels-photo-5716042_iialtx.jpg", 
      description:
        "We evaluate projects with a meticulous focus on aligning outcomes with objectives. Our audits go beyond identifying gaps to providing actionable recommendations that enhance efficiency and effectiveness.",
      keyDeliverables: [
        "Comprehensive audit reports tailored to project-specific indicators and benchmarks.",
        "Strategic guidance to align performance with overarching goals.",
      ],
      seismicValue:
        "We ensure accountability and continuous improvement, empowering our clients to deliver transformative results.",
    },
    {
      title: "Financial Compliance and Auditing",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1738745799/track-record-4_m802vr.png", 
      description:
        "Leveraging our deep expertise in financial systems and public financial management, we independently validate financial records, ensuring every dollar is accounted for and used optimally.",
      keyDeliverables: [
        "Detailed financial compliance reports with clear, actionable insights.",
        "Recommendations to strengthen financial governance and transparency.",
      ],
      seismicValue:
        "We instill confidence in stakeholders by ensuring financial integrity and resource optimization.",
    },
    {
      title: "Monitoring and Results Verification",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1739182750/pexels-photo-9301831_nh9lgb.jpg", 
      description:
        "We use a robust mix of field verification, technology-enabled monitoring, and evidence-based analysis to validate project reports and outcomes.",
      keyDeliverables: [
        "Accurate verification reports, underpinned by field data and stakeholder feedback.",
        "Insights to refine project strategies for better results.",
      ],
      seismicValue:
        "By ensuring that reported results reflect reality, we strengthen trust in project outcomes.",
    },
    {
      title: "Third-Party Monitoring",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1739186024/pexels-photo-572056_pua9mp.jpg", 
      description:
        "Our independent oversight delivers unbiased assessments, particularly in high-risk, remote, or inaccessible areas. Our expertise ensures that projects stay on track despite challenges.",
      keyDeliverables: [
        "Independent monitoring reports highlighting performance, compliance, and impact.",
        "Recommendations to address contextual challenges and risks.",
      ],
      seismicValue:
        "We act as trusted third-party verifiers, providing objective assurance that strengthens credibility with funders and beneficiaries alike.",
    },
    {
      title: "Risk Assessment and Mitigation",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1739187484/pexels-photo-5716034_l0pchl.jpg", 
      description:
        "We adopt a proactive approach to risk management, identifying potential threats early and crafting mitigation strategies that ensure seamless project execution.",
      keyDeliverables: [
        "Risk assessment reports with prioritized risks and mitigation plans.",
        "Tailored solutions to reduce vulnerabilities and enhance resilience.",
      ],
      seismicValue:
        "Our foresight and adaptability help clients navigate uncertainties with confidence, ensuring sustained progress.",
    },
    {
      title: "Process and System Assurance",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1738745787/track-record-3_a50vhj.png", 
      description:
        "We assess operational workflows and systems with precision, ensuring they meet international standards while aligning with project objectives.",
      keyDeliverables: [
        "Detailed evaluations of processes and systems with clear improvement recommendations.",
        "Validation of data systems for reliability and accuracy.",
      ],
      seismicValue:
        "We streamline operations and build robust frameworks that enable efficiency and accountability.",
    },
    {
      title: "Environmental and Social Safeguards Compliance",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1739187997/wk9qpn99rme6e6locb4i.jpg", 
      description:
        "Guided by a commitment to sustainability, we ensure that projects comply with environmental and social safeguard policies, minimizing negative impacts while maximizing benefits.",
      keyDeliverables: [
        "Compliance assessments with actionable recommendations for improvement.",
        "Monitoring reports on safeguard implementation and mitigation efforts.",
      ],
      seismicValue:
        "We help clients align with global sustainability standards, ensuring projects contribute positively to communities and ecosystems.",
    },
    {
      title: "Capacity Building for Accountability",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1738745793/insights4_rnvvxi.png", 
      description:
        "We invest in building local capacity, equipping stakeholders with the skills and tools needed to maintain high standards of accountability and performance.",
      keyDeliverables: [
        "Customized training programs for stakeholders and project teams.",
        "Frameworks for self-assessment and continuous learning.",
      ],
      seismicValue:
        "Our capacity-building initiatives create long-lasting impact, fostering a culture of accountability that drives sustained success.",
    },
  ];

  return (
    <div className="w-full bg-[#F9F5FB] py-16">
      <div className="max-w-6xl mx-auto px-6 lg:px-12">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Key Areas of Services
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            We bring a distinctive blend of expertise and precision to our
            Independent Verification and Assurance services, tailored to meet
            the unique needs of governments, development organizations, and
            corporate entities.
          </p>
        </div>

        <div className="space-y-4">
          {sections.map((section, index) => (
            <div key={index} className="border-b border-gray-300">
              <button
                onClick={() => {
                  toggleSection(section.title);
                  scrollToSection(section.title); // Scroll the section into view when clicked
                }}
                className="w-full text-left py-8 px-6 font-semibold text-lg flex justify-between items-center"
              >
                <span>{section.title}</span>
                <span
                  className="text-3xl font-bold"
                  style={{ color: "#600060" }} 
                >
                  {activeSection === section.title ? "-" : "+"}
                </span>
              </button>
              {activeSection === section.title && (
                <div ref={(el) => (sectionRefs.current[section.title] = el)} className="px-6 py-4 bg-white">
                  <div className="flex flex-col lg:flex-row gap-6 mb-6">
                    <div className="lg:w-1/2">
                      <img
                        src={section.image}
                        alt={section.title}
                        className="rounded-lg h-[200px] w-[400px]"
                      />
                    </div>
                    <div className="lg:w-1/2">
                      <h3 className="text-xl font-bold mb-4">
                        {section.title}
                      </h3>
                      <p className="text-gray-600 text-lg leading-relaxed">
                        {section.description}
                      </p>
                    </div>
                  </div>

                  <div className="mb-6">
                    <h4 className="text-lg font-bold mb-2">Key Deliverables</h4>
                    <ul className="space-y-4">
                      {section.keyDeliverables.map((item, idx) => (
                        <li key={idx} className="flex items-start gap-4 text-gray-600 text-lg">
                          <span className="w-4 h-4 flex-shrink-0 border-2 border-[#600060] bg-[#FCE4EC] rounded-full mt-2"></span>
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="text-lg font-bold mb-2">Seismic’s Value</h4>
                    <p className="text-gray-600 text-lg leading-relaxed">
                      {section.seismicValue}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyAreasOfServices;

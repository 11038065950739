// src/pages/PrivacyPolicy.jsx
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 mt-20">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
      <p className="text-gray-700 text-lg mb-4">
        This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this policy carefully. By using our website, you consent to the practices described herein.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Information We Collect</h2>
      <p className="text-gray-700 text-lg mb-4">
        We may collect personal information that you voluntarily provide to us when you register on our site, subscribe to our newsletter, or fill out a form. This may include your name, email address, and any other details you choose to share.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">How We Use Your Information</h2>
      <p className="text-gray-700 text-lg mb-4">
        The information we collect is used to operate and improve our website, provide you with personalized content, respond to inquiries, and send periodic emails. We use this data to ensure a better and more engaging user experience.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Sharing Your Information</h2>
      <p className="text-gray-700 text-lg mb-4">
        We do not sell or trade your personal information to third parties. We may share your information with trusted partners who assist us in operating our website or conducting our business, as long as those parties agree to keep your information confidential.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Your Rights</h2>
      <p className="text-gray-700 text-lg mb-4">
        You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please contact us using the details provided below.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Changes to This Policy</h2>
      <p className="text-gray-700 text-lg mb-4">
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this page periodically for the latest information.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Contact Us</h2>
      <p className="text-gray-700 text-lg">
        If you have any questions about this Privacy Policy, please contact us at{" "}
        <a href="mailto:email@example.com" className="text-purple-900">info@seismicconsults.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;

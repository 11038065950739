import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Insights = () => {
  const insights = [
    {
      category: 'Audit and Assurance',
      title: 'Independent Verification Agents (IVA): Bridging the Gap Between Goals and Outcomes',
      description:
        'In today’s dynamic business environment, transparency, accountability, and efficiency are crucial for success.',
      date: 'January 23, 2025',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738745786/insights2_kf4fpt.png',
      link: '/insights/iva-insight',
    },        
    {
      category: 'Brand & Communication',
      title: 'Crisis Communication in Consulting: Turning Challenges into Opportunities',
      description:
        'A solid reputation requires a resilient strategy: respond swiftly, take accountability, communicate transparently, and rebuild trust with actions that align with your values.',
      date: 'January 24, 2025',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738160404/pexels-photo-8970669_eukno6.jpg',
      link: '/insights/crisis-communication',
    },    
    {
      category: 'Management Consulting',
      title: 'Driving Sustainable Development Through Circular Economy Models',
      description:
        'Discover how circular economy principles offer transformative solutions by prioritizing sustainability, resource efficiency, and innovation for businesses and communities.',
      date: 'January 23, 2025',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738745786/rec_insights3_zju2pc.png',
      link: '/insights/circular-economy',
    },    
    {
      category: 'Technology Consulting',
      title: 'Enhancing Governance through Blockchain Technology',
      description:
        'Blockchain technology is redefining governance by fostering transparency, accountability, and efficiency. Learn how it’s transforming public administration globally.',
      date: 'January 28, 2025',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738160301/pexels-photo-2777898_gebpkw.jpg',
      link: '/insights/blockchain-governance', 
    },
    {
      category: 'Technology Consulting',
      title: 'Sustainable Urban Development through Smart City Solutions',
      description:
        'Smart city solutions offer transformative opportunities for urban environments by leveraging technology, data analytics, and innovation to create sustainable, efficient, and livable spaces.',
      date: 'January 30, 2025',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1737556545/pexels-photo-681335_zfc2zd.jpg',
      link: '/insights/smart-city-solutions',
    },    
  ];

  const recommended = [
    {
      title: 'Empowering Communities through Digital Financial Inclusion',
      date: 'January 30, 2025',
      category: 'Financial Inclusion',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1737556469/pexels-photo-210182_cp3euh.jpg',
      link: '/insights/digital-financial-inclusion',
    },    
    {
      title: 'Driving Change:   What It Really Takes',
      date: '15 Oct, 2024',
      category: 'Blockchain',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738745782/rec_insights1_om113y.png',
      link: '/insights/driving-change',
    },
    {
      title: 'Transforming Workforce Development through AR',
      date: '15 Oct, 2024',
      category: 'AR',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738745783/rec_insights2_p82ydx.png',
      link: '/insights/arworkforce-development',
    },
    {
      title: 'Enhancing Governance through Blockchain Technology',
      date: '15 Oct, 2024',
      category: 'Blockchain',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1738160301/pexels-photo-2777898_gebpkw.jpg',
      link: '/insights/blockchain-governance', 
      
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const insightsPerPage = 4;
  const totalPages = Math.ceil(insights.length / insightsPerPage);

  const displayedInsights = insights.slice(
    currentPage * insightsPerPage,
    (currentPage + 1) * insightsPerPage
  );

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <section className="relative w-full py-16 mt-20">
      {/* Featured Insights Section */}
      <div className="relative w-screen left-1/2 right-1/2 -ml-[50vw] -mr-[50vw]">
        {/* Image Section spanning the full width */}
        <div className="w-full h-auto sm:h-[400px] relative">
          <div className="absolute inset-0 bg-black opacity-40 z-0"></div>
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1734612712/pexels-kindelmedia-6774432_u6l8wi.jpg"
            alt="Featured Insights Background"
            className="w-full h-full object-cover"
          />
          {/* Dark Transparent Text Card for Large Screens */}
          <div
            className="absolute bottom-8 left-16 bg-black bg-opacity-10 p-6 shadow-md hidden sm:block z-10"
            style={{
              width: '450px', // Increased width for a larger card
              height: '220px', // Increased height for a larger card
            }}
          >
            <h2 className="text-3xl font-semibold text-white">Featured Insights</h2>
            <p className="text-md text-white mt-6">
              Stay ahead with our curated insights, case studies, and expert opinions.
              Explore the latest industry trends and strategies that drive success.
            </p>
          </div>
        </div>
      </div>

      {/* Insights Content */}
      <div className="max-w-7xl mx-auto px-6 mt-12">
        <h3 className="text-2xl font-semibold text-left mb-6">All Insights</h3>

        {/* Insights Content for Small Screens */}
        <div className="grid grid-cols-1 gap-8 mt-6 sm:hidden">
          {displayedInsights.map((insight, index) => (
            <div key={index} className="border border-gray-300 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-white">
  <img src={insight.image} alt={insight.title} className="w-full h-48 object-cover rounded-t-lg mb-4" />
  <p className="text-sm font-semibold text-purple-800 mb-2">{insight.category}</p>
  <h4 className="text-xl font-semibold mb-2 text-gray-800">{insight.title}</h4>
  <p className="text-gray-600 mb-4">{insight.description}</p>
  <p className="text-sm text-gray-500">{insight.date}</p>
  <Link
          to={insight.link}
          className="text-purple-900 font-semibold mt-4 inline-flex items-center"
        >
          Read more
          <svg
            className="w-5 h-5 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
</div>

          ))}
        </div>

        {/* Pagination Controls for Small Screens */}
        <div className="flex justify-between items-center mt-8 sm:hidden">
          <button
            onClick={prevPage}
            disabled={currentPage === 0}
            className={`px-4 py-2 text-white bg-purple-800 rounded-lg ${
              currentPage === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Previous
          </button>
          <p className="text-gray-600">
            Page {currentPage + 1} of {totalPages}
          </p>
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages - 1}
            className={`px-4 py-2 text-white bg-purple-800 rounded-lg ${
              currentPage === totalPages - 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Next
          </button>
        </div>

{/* Recommended Section for Small Screens */}
<div className="mt-12 sm:hidden">
  <h3 className="text-center text-xl font-semibold mb-6">Recommended</h3>
  <div className="grid grid-cols-1 gap-8">
    {recommended.map((item, index) => (
      <Link to={item.link} key={index}>
        <div className="border border-gray-300 p-4 rounded-lg bg-white flex hover:shadow-lg transition-shadow">
          <img
            src={item.image}
            alt={item.title}
            className="w-16 h-16 object-cover rounded-lg mr-4"
          />
          <div>
            <h4 className="text-sm font-semibold text-gray-800">{item.title}</h4>
            <p className="text-sm text-gray-500 mt-2">{item.date}</p>
            <p className="text-sm text-purple-800 mt-1">{item.category}</p>
          </div>
        </div>
      </Link>
    ))}
  </div>
</div>


{/* Insights and Recommended Section for Larger Screens */}
<div className="hidden sm:grid sm:grid-cols-3 gap-8 mt-6">
  {/* First Row: Two Insights and the Recommended Section */}
  <div className="col-span-2 grid grid-cols-2 gap-8">
    {insights.slice(0, 2).map((insight, index) => (
      <div
        key={index}
        className="border border-gray-300 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-white w-[397px] h-[586px]"
      >
        <img
          src={insight.image}
          alt={insight.title}
          className="w-full h-48 object-cover rounded-t-lg mb-4"
        />
        <p className="text-sm font-semibold text-purple-800 mb-2">{insight.category}</p>
        <h4 className="text-xl font-semibold mb-2 text-gray-800">{insight.title}</h4>
        <p className="text-gray-600 mb-4">{insight.description}</p>
        <p className="text-sm text-gray-500">{insight.date}</p>
        <Link
          to={insight.link}
          className="text-purple-900 font-semibold mt-4 inline-flex items-center"
        >
          Read more
          <svg
            className="w-5 h-5 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
      </div>
    ))}
  </div>

{/* Recommended Section */}
<div className="col-span-1">
  <div>
    <h4 className="text-xl font-normal mb-4">Recommended</h4> {/* Adjust spacing for the header */}
    {recommended.map((item, index) => (
      <Link to={item.link} key={index}>
        <div className="border border-gray-300 p-4 rounded-lg bg-white flex w-[386px] h-[123px] hover:shadow-lg transition-shadow mb-4"> {/* Added explicit margin-bottom */}
          <img
            src={item.image}
            alt={item.title}
            className="w-16 h-16 object-cover rounded-lg mr-4"
          />
          <div>
            <h4 className="text-sm font-semibold text-gray-800">{item.title}</h4>
            <p className="text-sm text-gray-500 mt-2">{item.date}</p>
            <p className="text-sm text-purple-800 mt-1">{item.category}</p>
          </div>
        </div>
      </Link>
    ))}
  </div>
</div>



</div>


        {/* Second Row of Insights */}
        <div className="hidden sm:grid sm:grid-cols-3 gap-8 mt-8">
          {insights.slice(2, 5).map((insight, index) => (
            <div
              key={index}
              className="border border-gray-300 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-white"
                style={{ width: '397px', height: '586px' }} 
            >
              <img
                src={insight.image}
                alt={insight.title}
                className="w-full h-48 object-cover rounded-t-lg mb-4"
              />
              <p className="text-sm font-semibold text-purple-800 mb-2">{insight.category}</p>
              <h4 className="text-xl font-semibold mb-2 text-gray-800">{insight.title}</h4>
              <p className="text-gray-600 mb-4">{insight.description}</p>
              <p className="text-sm text-gray-500">{insight.date}</p>
              <Link
          to={insight.link}
          className="text-purple-900 font-semibold mt-4 inline-flex items-center"
        >
          Read more
          <svg
            className="w-5 h-5 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Insights;

import React from 'react';

const CrisisCommunication = () => {
  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-20 py-16">
              {/* Hero Section */}
      <section
        className="relative w-full h-[500px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: "url('https://via.placeholder.com/1200x500')" }}
      >
        <div className="text-center">
          <h1 className="text-4xl font-bold">Crisis Communication in Consulting</h1>
          <p className="text-lg mt-4">Turning Challenges into Opportunities</p>
        </div>
      </section>
      <div className="mt-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-6">
        Crisis Communication in Consulting: Turning Challenges into Opportunities
      </h1>
      <p className="text-lg text-gray-600 italic mb-6">
        "A solid reputation requires a resilient strategy: respond swiftly, take accountability, communicate transparently, and rebuild trust with actions that align with your values." – Marcillina Okalor
      </p>

      <p className="text-lg text-gray-700 mb-4">
        In the world of consulting, where trust and expertise are the cornerstones of success, crises can emerge as unexpected tests of resilience and communication. Whether it’s a public relations disaster, operational mishap, or a sensitive issue involving a client, effective crisis communication can mean the difference between irreparable damage and a strengthened reputation.

      </p>
      </div>

      <h2 className="text-3xl font-semibold text-gray-900 mt-10 mb-4">
        Techniques for Managing Reputational Risks for Clients and Firms
      </h2>
      <p className="text-lg text-gray-700 mb-4">
        In a crisis, reputation is often the first casualty. Consulting firms, tasked with being the voice of reason and expertise, must adopt a proactive and strategic approach to mitigate reputational risks. Here are some key techniques:
      </p>

      <ul className="list-disc pl-6 text-gray-700 text-lg">
        <li>
          <strong>Rapid Response and Clarity:</strong> Crises demand swift action. Develop a clear, concise, and empathetic message within the first critical hours.
        </li>
      </ul>

      <h3 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
        Starbucks 2018 Crisis Case Study
      </h3>
      <p className="text-lg text-gray-700 mb-4">
        In 2018, Starbucks faced a significant crisis when two Black men were arrested at a Philadelphia store while waiting for a business associate. The incident, perceived as racially discriminatory, led to widespread public outrage and calls for boycotts. In response, Starbucks took immediate and comprehensive actions to address the situation:
      </p>
      <ul className="list-disc pl-6 text-gray-700 text-lg">
        <li>Public Apology and Accountability</li>
        <li>Policy Review and Changes</li>
        <li>Employee Training</li>
        <li>Community Engagement</li>
      </ul>

      <p className="text-lg text-gray-600 mt-4">
        Read more: <a href="https://www.theguardian.com/business/2018/apr/19/starbucks-black-men-feared-for-lives-philadelphia" className="text-purple-900 underline">Guardian Article</a>
      </p>

      <h2 className="text-3xl font-semibold text-gray-900 mt-10 mb-4">
        Effective Internal Communication During Crises
      </h2>
      <p className="text-lg text-gray-700 mb-4">
        Crises don’t just affect external stakeholders; they also ripple through internal teams. Poor communication can breed confusion, fear, and inefficiency. Here’s how consulting firms can ensure effective internal communication:
      </p>

      <ul className="list-disc pl-6 text-gray-700 text-lg">
        <li>Designate a Crisis Team</li>
        <li>Provide Transparent Updates</li>
        <li>Encourage Two-Way Communication Channels</li>
        <li>Training and Preparedness</li>
      </ul>

      <h2 className="text-3xl font-semibold text-gray-900 mt-10 mb-4">
        Building Trust Through Transparency and Accountability
      </h2>
      <p className="text-lg text-gray-700 mb-4">
        Transparency and accountability are the cornerstones of rebuilding and maintaining trust during a crisis. Here’s how consulting firms can leverage these principles:
      </p>

      <ul className="list-disc pl-6 text-gray-700 text-lg">
        <li>Own the Mistake</li>
        <li>Communicate Proactively</li>
        <li>Showcase Solutions</li>
        <li>Leverage Thought Leadership</li>
      </ul>

      <h2 className="text-3xl font-semibold text-gray-900 mt-10 mb-4">
        A Story of Resilience: Turning a Crisis into an Opportunity
      </h2>
      <p className="text-lg text-gray-700 mb-4">
        A boutique consulting firm faced backlash when a data breach compromised a client’s confidential information. The breach threatened the firm’s reputation and client relationships. However, the firm responded by turning the crisis into a case study in effective communication.
      </p>

      <h3 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
        The Tylenol Poisoning Case Study
      </h3>
      <p className="text-lg text-gray-700 mb-4">
        In 1982, Burson-Marsteller's handling of the Tylenol poisonings for Johnson & Johnson became a textbook case for effective crisis communication. The firm’s strategy included:
      </p>
      <ul className="list-disc pl-6 text-gray-700 text-lg">
        <li>Swift Action: Nationwide recall and public safety commitment.</li>
        <li>Transparency: Regular communication through media.</li>
        <li>Solution Showcase: Introduction of tamper-proof packaging.</li>
      </ul>
      <p className="text-lg text-gray-600 mt-4">
        Read more: <a href="https://en.wikipedia.org/wiki/Burson_%28company%29?utm" className="text-purple-900 underline">Wikipedia Article</a>
      </p>

      <h2 className="text-3xl font-semibold text-gray-900 mt-10 mb-4">
        Key Takeaways
      </h2>
      <ul className="list-disc pl-6 text-gray-700 text-lg">
        <li>Plan Ahead: Develop crisis communication protocols before a crisis occurs.</li>
        <li>Act Fast: Timely responses demonstrate competence and control.</li>
        <li>Stay Transparent: Honesty and openness build trust with stakeholders.</li>
        <li>Learn and Evolve: Every crisis is an opportunity to improve processes and systems.</li>
      </ul>

      <p className="text-lg text-gray-700 mt-10">
        Crises are inevitable, but with the right communication strategies, consulting firms can navigate them successfully—turning challenges into opportunities to reinforce their value and expertise.
      </p>

      <p className="text-lg text-gray-900 font-bold mt-6">Marcillina Okalor</p>
      <p className="text-lg text-gray-600">Brand & Communication Lead</p>
      <p className="text-lg text-gray-600">Seismic Consulting Group</p>
    </div>
  );
};

export default CrisisCommunication;

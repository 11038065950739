import React, { useState } from "react";

const TelemedicineDigitalHealth = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const expertiseData = [
    {
      title: "Comprehensive Telemedicine Solutions",
      description:
        "We specialize in creating tailored telemedicine platforms that meet the unique needs of healthcare providers, ranging from individual practitioners to large hospital systems. These platforms integrate features like:",
      services: [
        "Virtual Consultations: Secure, HIPAA-compliant video and chat platforms that facilitate real-time communication between patients and healthcare professionals.",
        "Remote Patient Monitoring: Devices and software solutions that allow healthcare providers to monitor patients’ health metrics, such as heart rate, blood pressure, and glucose levels, from a distance.",
        "AI-Powered Diagnostics: Advanced algorithms to assist in early detection and diagnosis of conditions, enhancing the precision and speed of treatment plans.",
        "E-Prescriptions: Integrated prescription systems that ensure patients receive the right medication promptly without visiting a pharmacy.",
      ],
      summary:
        "Our telemedicine solutions empower healthcare providers to deliver efficient, effective, and accessible care to their patients, regardless of location.",
    },
    {
      title: "Expanding Access to Underserved and Remote Areas",
      description:
        "Access to healthcare remains a critical challenge for many underserved and remote communities. Our telemedicine solutions are designed to overcome these barriers by:",
      services: [
        "Reducing the need for physical infrastructure, making healthcare services available in regions with limited medical facilities",
        "Empowering local health workers with digital tools to connect with specialists, enabling timely interventions",
        "Offering multilingual and culturally sensitive interfaces to ensure inclusivity and usability",
      ],
      summary:
        "By addressing the challenges of underserved and remote areas, we make healthcare accessible to everyone, fostering equity and inclusivity.",
    },
    {
      title: "Optimizing Operational Efficiency",
      description:
        "Telemedicine doesn't just connect patients and providers; it revolutionizes healthcare operations by:",
      services: [
        "Streamlining Appointment Scheduling: AI-powered systems reduce no-shows, optimize scheduling, and ensure providers’ time is used efficiently.",
        "Minimizing Administrative Burden: Automated data entry, billing, and reporting tools free up time for healthcare professionals to focus on patient care.",
        "Scalable Solutions: Cloud-based platforms enable rapid scalability, accommodating growing patient needs without additional overhead.",
      ],
      summary:
        "Our solutions enhance efficiency, allowing healthcare providers to focus on delivering quality care while reducing operational challenges.",
    },
    {
      title: "Reducing Costs Without Compromising Quality",
      description:
        "The economic burden of traditional healthcare delivery is significant for both providers and patients. Our telemedicine solutions address these cost challenges by:",
      services: [
        "Reducing the need for expensive hospital visits and inpatient care through effective remote management",
        "Lowering travel costs for patients and healthcare professionals",
        "Enhancing preventive care, leading to early detection of health issues and avoiding costly treatments for advanced conditions",
      ],
      summary:
        "Our cost-effective solutions ensure that quality care is affordable and accessible to all.",
    },
    {
      title: "Creating a Connected Patient Experience",
      description:
        "Healthcare is about more than treatment; it’s about relationships and trust. Our telemedicine platforms are designed to create a patient-centric experience that emphasizes:",
      services: [
        "Continuity of Care: Seamless integration with electronic health records (EHR) ensures that patient history is accessible, allowing providers to deliver personalized care.",
        "Accessibility and Convenience: Patients can consult with healthcare professionals from the comfort of their homes, reducing stress and increasing satisfaction.",
        "Engagement and Empowerment: Tools such as health tracking apps and educational resources empower patients to take an active role in managing their health.",
      ],
      summary:
        "Our patient-focused approach builds trust and engagement, ensuring a holistic healthcare experience.",
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737457125/pexels-edward-jenner-4031818_tweave.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Telemedicine and Digital Health
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Partnering with organizations to navigate the complexities of healthcare delivery, regulatory frameworks, and patient engagement.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Telemedicine and Digital Health
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16 mb-12">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Empowering Access, Enhancing Connectivity, Revolutionizing Care
        </h2>
        <p className="text-lg text-gray-700 mb-8">
          Seismic Consulting Group is at the forefront of transforming healthcare
          delivery through telemedicine and digital health solutions. By enabling
          healthcare providers to implement advanced telemedicine platforms, we
          bridge the gap between patients and care providers, ensuring that
          quality healthcare is accessible to everyone, regardless of location.
        </p>
        <p className="text-lg text-gray-700">
          Our approach goes beyond technology implementation; we design holistic
          solutions that address operational, logistical, and clinical challenges,
          paving the way for a seamless and scalable digital health ecosystem.
        </p>
      </div>

      {/* Key Areas of Expertise */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Key Areas of Expertise
        </h2>
        <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
          Seismic Consulting's Change Management & Strategy Consulting services
          deliver tangible, sustainable outcomes that drive long-term success.
        </p>
        {expertiseData.map((item, index) => (
          <div key={index} className="mb-12 border-b border-pink-100 pb-8">
            {/* Header Section */}
            <button
              onClick={() => toggleSection(index)}
              className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
            >
              {item.title}
              <span className="text-purple-900 text-3xl">
                {openSection === index ? "-" : "+"}
              </span>
            </button>
            {openSection === index && (
              <div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 text-lg mb-4">{item.description}</p>
                </div>

                {/* Services Section */}
                <div className="mt-6 px-6 lg:px-32">
                  <ul className="list-none text-gray-700 text-lg space-y-4">
                    {item.services.map((service, idx) => (
                      <li key={idx} className="relative pl-6">
                        <span
                          className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 rounded-full"
                        ></span>
                        <span className="font-bold">{service.split(":")[0]}:</span>{" "}
                        {service.split(":")[1]}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Summary Section */}
                <p className="text-gray-700 text-lg mt-6 px-6 lg:px-12">
                  {item.summary}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>

{/* Impact Section */}
<section className="bg-white py-16">
  <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
  <div>
  <img
    src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737456453/Assistance-to-a-child-in-one-of-the-health-units_wybuk1.jpg"
    alt="Impactful Results"
    className="w-full h-[450px] object-cover shadow-lg"
  />
</div>
    <div>
      <h2 className="text-3xl font-bold mb-4">Impactful Results</h2>
      <p className="text-gray-600 text-lg mb-4">
        Seismic Consulting Group’s telemedicine solutions have consistently delivered measurable outcomes, including:
      </p>
      <ul className="list-disc pl-5 space-y-2 text-gray-700 text-lg">
        <li>Increased access to healthcare for rural and underserved populations.</li>
        <li>Shortened patient wait times, improving satisfaction and trust in healthcare systems.</li>
        <li>Reduced strain on physical healthcare infrastructure, enabling providers to allocate resources more effectively.</li>
      </ul>
      <p className="text-gray-700 text-lg mt-6">
        With our telemedicine and digital health expertise, we are redefining the boundaries of healthcare delivery, ensuring that quality care is no longer a privilege but a universal right. Let us help you harness the power of technology to transform lives and communities.
      </p>
    </div>
  </div>
</section>

    </div>
  );
};

export default TelemedicineDigitalHealth;

import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess('');
    setError('');

    try {
      // Build URL-encoded form data with the correct key names
      const params = new URLSearchParams();
      params.append('first_name', formData.firstName);
      params.append('last_name', formData.lastName);
      params.append('phone_number', formData.phoneNumber);
      params.append('email', formData.email);
      params.append('message', formData.message);

      const response = await fetch('https://seismic-website-backend.onrender.com/api/send-a-message', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: params.toString(),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }
      
      setSuccess('Message sent successfully!');
      // Reset the form fields
      setFormData({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        message: '',
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-6 py-12 bg-gray-50 mt-20">
      <div className="max-w-6xl mx-auto">
        {/* Heading */}
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
        </div>

        {/* Two Paragraphs Side by Side */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-8 space-y-6 md:space-y-0 md:space-x-6">
          <p className="text-lg md:w-1/2">
            Whether you're looking to partner, consult, or stay connected with Seismic, be part of our journey toward transformative success.
          </p>
          <p className="text-lg md:w-1/2">
            Send us a mail at <a href="mailto:info@seismicconsults.com" className="text-purple-900 underline">info@seismicconsults.com</a>. We'd love to hear from you! Or use the form below to reach out, and we'll get back to you as soon as possible.
          </p>
        </div>

        {/* Contact Form */}
        <div className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-2xl font-bold text-center text-purple-900 mb-6">Send A Message</h2>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* First Name */}
              <div>
                <label className="block text-sm font-medium mb-2" htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-purple-900"
                />
              </div>
              {/* Last Name */}
              <div>
                <label className="block text-sm font-medium mb-2" htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Enter your last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-purple-900"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Phone Number */}
              <div>
                <label className="block text-sm font-medium mb-2" htmlFor="phoneNumber">Phone Number</label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  placeholder="Enter your phone number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-purple-900"
                />
              </div>
              {/* Email Address */}
              <div>
                <label className="block text-sm font-medium mb-2" htmlFor="email">Email Address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-purple-900"
                />
              </div>
            </div>
            {/* Message */}
            <div>
              <label className="block text-sm font-medium mb-2" htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                placeholder="How can we help you?"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-purple-900"
              ></textarea>
            </div>
            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                disabled={loading}
                className="px-24 py-3 bg-purple-900 text-white font-semibold shadow-md hover:bg-purple-900 transition disabled:opacity-50"
              >
                {loading ? "Sending..." : "Send"} <span className="ml-2">→</span>
              </button>
            </div>
            {success && <p className="text-green-600 text-center">{success}</p>}
            {error && <p className="text-red-600 text-center">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from 'react';
import MethodologySectionASD from './MethodologySectionASD';

const AdaptiveStrategyDevelopment = () => {
  return (
    <div className="py-16">
             {/* Hero Section */}
      <section 
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736871131/4_pnllia.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div
          className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
        >
          <h1 className="text-3xl font-bold text-gray-800">
          Adaptive Strategy Development
          </h1>
        </div>
      </section>
            {/* Title Under Image for Small Screens */}
            <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Adaptive Strategy Development
        </h1>
      </div>

      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-28">
        {/* Context Section */}
        <div className="mb-16">
          <p className="text-gray-600 text-xl leading-relaxed">
            In an era defined by constant evolution in market dynamics, regulatory landscapes, and technological innovation, Adaptive Strategy Development is central to Seismic’s approach to Change Management & Strategy Consulting. This methodology equips organizations with the tools and insights necessary to remain resilient, agile, and competitive, even in the face of uncertainty.
          </p>
        </div>

        {/* What We Do Section */}
        <div className="mb-12 pt-8">
          <h2 className="text-2xl font-bold text-center mb-8">What We Do</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Evolving Market Conditions",
                description:
                  "Rapid shifts in consumer demands and landscapes require businesses to stay proactive rather than reactive.",
                icon: "https://your-icon-url.com/market-icon.png",
              },
              {
                title: "Regulatory Shifts",
                description:
                  "Frequent changes in policies and compliance requirements can disrupt operations without a plan to adapt swiftly",
                icon: "https://your-icon-url.com/regulatory-icon.png",
              },
              {
                title: "Technological Advancement",
                description:
                  "Emerging technologies and digital transformation necessitate continuous innovation to prevent obsolescence.",
                icon: "https://your-icon-url.com/technology-icon.png",
              },
            ].map((item, index) => (
              <div key={index} className="text-center">
                <img
                  src={item.icon}
                  alt={item.title}
                  className="w-16 h-16 mx-auto mb-4"
                />
                <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                <p className="text-gray-600 text-xl leading-relaxed">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>

{/* Our Methodology Section */}
<MethodologySectionASD />

{/* Value to Clients Section */}
<div className="mb-12">
  <h2 className="text-2xl font-bold mb-6 text-gray-900 text-center">Value to Clients</h2>
  <h3 className="text-xl text-center mb-4 text-gray-600">
    To develop strategies that are both robust and adaptable, Seismic employs a structured yet flexible methodology
  </h3>
  <ul className="list-none space-y-8 text-lg leading-relaxed ml-16">
    <li className="flex items-start">
      <span
        className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
        style={{ backgroundColor: "#F9F1FA" }}
      ></span>
      <div>
        <strong className="text-xl text-gray-900">Pivot Effectively:</strong>{" "}
        <span className="text-gray-600 text-xl">
          Built-in flexibility and contingency plans allow organizations to shift priorities and resources swiftly in
          response to unexpected challenges or opportunities.
        </span>
      </div>
    </li>
    <li className="flex items-start">
      <span
        className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
        style={{ backgroundColor: "#F9F1FA" }}
      ></span>
      <div>
        <strong className="text-xl text-gray-900">Enhance Resilience:</strong>{" "}
        <span className="text-gray-600 text-xl">
          Robust strategies designed to withstand market volatility, regulatory upheavals, or technological disruptions.
        </span>
      </div>
    </li>
    <li className="flex items-start">
      <span
        className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
        style={{ backgroundColor: "#F9F1FA" }}
      ></span>
      <div>
        <strong className="text-xl text-gray-900">Seize Opportunities:</strong>{" "}
        <span className="text-gray-600 text-xl">
          By identifying and acting on emerging trends, organizations can gain competitive advantages and capitalize on
          innovation-driven growth.
        </span>
      </div>
    </li>
    <li className="flex items-start">
      <span
        className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
        style={{ backgroundColor: "#F9F1FA" }}
      ></span>
      <div>
        <strong className="text-xl text-gray-900">Future-Proof Operations:</strong>{" "}
        <span className="text-gray-600 text-xl">
          Long-term planning with adaptability ensures the organization remains resilient as industries and technologies
          evolve.
        </span>
      </div>
    </li>
  </ul>
</div>


 {/* Integration With Change Management */}
<div className="mb-12">
  <h2 className="text-2xl font-bold mb-6 text-gray-900 text-center">Integration With Change Management</h2>
  <h3 className="text-xl text-center mb-4 text-gray-600">
  Seismic’s adaptive strategies are seamlessly integrated into its broader Change Management framework to ensure successful implementation. This includes:
  </h3>
  <ul className="list-none space-y-8 text-lg leading-relaxed ml-16">
    <li className="flex items-start">
      <span
        className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
        style={{ backgroundColor: "#F9F1FA" }}
      ></span>
      <div>
        <strong className="text-xl text-gray-900">Communication Plans: </strong>{" "}
        <span className="text-gray-600 text-xl">
        Ensuring all stakeholders understand the strategy and their role in its execution.
        </span>
      </div>
    </li>
    <li className="flex items-start">
      <span
        className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
        style={{ backgroundColor: "#F9F1FA" }}
      ></span>
      <div>
        <strong className="text-xl text-gray-900">Training and Support:</strong>{" "}
        <span className="text-gray-600 text-xl">
         Equipping teams with the skills and tools needed to adapt to changes effectively.
        </span>
      </div>
    </li>
    <li className="flex items-start">
      <span
        className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
        style={{ backgroundColor: "#F9F1FA" }}
      ></span>
      <div>
        <strong className="text-xl text-gray-900">Performance Monitoring:</strong>{" "}
        <span className="text-gray-600 text-xl">
         Using KPIs and dashboards to track progress and adjust strategies in real-time.
        </span>
      </div>
    </li>
  </ul>
</div>


      </div>
      {/* Seismic’s expertise in Adaptive Strategy Development */}
<section className="bg-white py-16">
  <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-wrap lg:flex-nowrap gap-12 items-center">
    {/* Image Section */}
    <div className="flex-shrink-0 w-full lg:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736941030/pexels-fauxels-3184416_xyek5p.jpg"
        alt="Why Choose Seismic Consulting"
        className="w-full"
      />
    </div>

    {/* Text Section */}
    <div className="w-full lg:w-1/2">
      <h2 className="text-2xl font-medium mb-6">
        Seismic’s expertise in Adaptive Strategy Development
      </h2>
      <p className="text-gray-600 text-xl leading-relaxed mb-6">
        Seismic’s expertise in Adaptive Strategy Development lies in its ability to blend rigorous analysis with
        creative problem-solving. By balancing long-term vision with the agility to address immediate challenges,
        Seismic ensures that organizations not only survive but thrive in an ever-changing world.
      </p>
      <p className="text-gray-600 text-xl leading-relaxed">
        In a dynamic business environment, adaptability isn’t optional—it’s essential. With Seismic, organizations gain
        a trusted partner in crafting strategies that evolve with the times, delivering enduring value and sustained
        success.
      </p>
    </div>
  </div>
</section>

{/* Related Services Section */}
<div className="max-w-8xl mx-auto px-6 lg:px-12 mt-8 lg:mt-12 ml-8">
  <h2 className="text-2xl font-bold mb-12 text-center">Seismic’s Approach to Change Management</h2>
  <div className="grid md:grid-cols-3 gap-8">
    {[
      {
        title: "Cultural Transformation",
        description:
          "Helping organizations realign their culture to support strategic objectives, fostering trust, collaboration, and shared purpose.",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736932993/close-up-interracial-people-holding-hands_1_iw7zr7.jpg",
        link: "/consulting-services/change-management-page/cultural-transformation",
      },
      {
        title: "Digital Transformation",
        description:
          "Empowering organizations to embrace digital innovation, streamline operations, and enhance customer experiences.",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736932864/5_1_ldv5s1.jpg",
        link: "/consulting-services/change-management-page/digital-transformation",
      },
      {
        title: "Capacity Building for Change Leaders",
        description:
          "Equipping leaders with the tools and skills needed to inspire, guide, and sustain change within their organizations.",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736933370/8_1_q1oige.jpg",
        link: "/consulting-services/change-management-page/capacity-building-for-change-leaders",
      },
    ].map((item, index) => (
      <a
        key={index}
        href={item.link}
        className="block text-left hover:shadow-lg transition-shadow duration-300"
      >
        <img
          src={item.image}
          alt={item.title}
          className="mb-4 w-full h-[300px] object-cover"
        />
        <h3 className="text-lg font-bold mb-2">{item.title}</h3>
        <p className="text-gray-600 text-sm mb-2">{item.description}</p>
        <span className="text-purple-900 font-bold text-sm">Read More</span>
      </a>
    ))}
  </div>
</div>


    </div>
  );
};

export default AdaptiveStrategyDevelopment;

import React, { useState } from "react";

const FinancialKeyAreasOfExpertise = () => {
  const [innerOpen, setInnerOpen] = useState({});

  const toggleInner = (area, itemTitle) => {
    const key = `${area}-${itemTitle}`;
    setInnerOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const areas = [
    "Digital Transformation",
    "Customer-Centric Excellence",
    "Risk Management and Regulatory Compliance",
    "Sustainable Finance Leadership",
  ];

  const getContentForArea = (area) => {
    if (area === "Sustainable Finance Leadership") {
      return {
        title: area,
        description:
          "As global priorities shift toward sustainability, financial institutions face increasing pressure to align their operations with Environmental, Social, and Governance (ESG) principles. At Seismic Consulting, we recognize that sustainable finance is not just about compliance but about seizing new opportunities, mitigating long-term risks, and contributing to a better future. We partner with our clients to embed ESG principles into their strategies, creating impactful solutions that drive both financial and societal value.",
        collapsibleItems: [
          {
            title: "Building ESG-Compliant Portfolios",
            content: [
              {
                heading: "Portfolio Assessment and Restructuring",
                description:
                  "Analyzing current portfolios to identify alignment gaps with ESG standards and offering strategies to enhance compliance without sacrificing returns.",
              },
              {
                heading: "Thematic Investments",
                description:
                  "Incorporating themes like renewable energy, sustainable agriculture, and social housing into portfolios to align with long-term global sustainability goals.",
              },
              {
                heading: "Risk Mitigation",
                description:
                  "Identifying ESG-related risks and providing strategies to mitigate them.",
              },
            ],
          },
          {
            title: "Aligning with Global Sustainability Standards",
            content: [
              {
                heading: "International Frameworks",
                description:
                  "Guiding compliance with standards like the UNPRI, TCFD, and the SDGs.",
              },
              {
                heading: "Regulatory Adaptation",
                description:
                  "Preparing institutions to meet regional regulatory requirements, such as the EU SFDR.",
              },
              {
                heading: "Sustainability Reporting",
                description:
                  "Assisting in preparing transparent ESG reports that resonate with investors, regulators, and stakeholders.",
              },
            ],
          },
          {
            title: "Unlocking Opportunities in Green Finance and Impact Investing",
            content: [
              {
                heading: "Developing Green Financial Products",
                description:
                  "Structuring green bonds, sustainable loans, and ESG-aligned investment funds.",
              },
              {
                heading: "Market Positioning",
                description:
                  "Identifying untapped markets in renewable energy projects and sustainable infrastructure.",
              },
              {
                heading: "Impact Measurement",
                description:
                  "Implementing metrics to quantify the environmental and social impact of investments.",
              },
            ],
          },
          {
            title: "Delivering Impact That Matters",
            content: [
              {
                heading: "Measurable Impact",
                description:
                  "Creating measurable impact, fostering resilience, and driving societal progress.",
              },
              {
                heading: "Efficiency Gains",
                description:
                  "Sustainability initiatives often lead to operational efficiencies, cost reduction, and improved resource allocation.",
              },
              {
                heading: "Customer Empowerment",
                description:
                  "Embedding ESG principles to foster trust and empower customers to make sustainable choices.",
              },
              {
                heading: "Sustainable Growth",
                description:
                  "Unlocking long-term growth by expanding into high-growth markets and building resilient business models.",
              },
            ],
          },
        ],
      };
    } else if (area === "Digital Transformation") {
      return {
        title: area,
        description:
          "In today’s fast-evolving financial landscape, digital transformation is a necessity. At Seismic Consulting, we help financial institutions navigate this journey with deep expertise and cutting-edge technology solutions to deliver sustainable and scalable results.",
        collapsibleItems: [
          {
            title: "Streamlining Operational Workflows",
            content: [
              {
                heading: "Legacy Systems and Manual Processes",
                description:
                  "Legacy systems and manual processes hamper efficiency, increase costs, and limit agility.",
              },
              {
                heading: "Process Automation",
                description:
                  "Implementing robotic process automation (RPA) to reduce errors and improve turnaround times.",
              },
              {
                heading: "Integrated Ecosystems",
                description:
                  "Creating interoperability between legacy systems and new digital platforms through API integration.",
              },
              {
                heading: "Operational Intelligence",
                description:
                  "Leveraging advanced analytics to optimize processes and resource allocation.",
              },
            ],
          },
          {
            title: "Enhancing Scalability and Adaptability",
            content: [
              {
                heading: "Cloud Migration",
                description:
                  "Transitioning to cloud-based platforms for flexibility, security, and cost-effectiveness.",
              },
              {
                heading: "Agile Frameworks",
                description:
                  "Adopting agile methodologies for faster adaptation to market changes.",
              },
              {
                heading: "Emerging Technologies",
                description:
                  "Embedding AI, machine learning, and blockchain into workflows to foster innovation.",
              },
            ],
          },
          {
            title: "Introducing Innovative Digital Products and Services",
            content: [
              {
                heading: "Personalized Financial Products",
                description:
                  "Leveraging AI and big data to develop tailored investment plans, insurance products, and credit options.",
              },
              {
                heading: "Digital Banking Solutions",
                description:
                  "Building intuitive digital platforms for banking, lending, and wealth management.",
              },
              {
                heading: "Open Banking Capabilities",
                description:
                  "Enabling collaboration with third-party providers through open banking APIs.",
              },
              {
                heading: "Embedded Finance",
                description:
                  "Integrating financial services into non-financial platforms for enhanced accessibility.",
              },
            ],
          },
        ],
      };
    } else if (area === "Customer-Centric Excellence") {
      return {
        title: area,
        description:
          "Financial institutions must prioritize customer experience. By leveraging data analytics and AI, we help organizations anticipate needs, personalize offerings, and create seamless interactions that drive loyalty and growth.",
        collapsibleItems: [
          {
            title: "Omni-Channel Strategies",
            content: [
              {
                heading: "Unify Customer Journeys",
                description:
                  "Creating a seamless experience where data flows effortlessly across channels.",
              },
              {
                heading: "Leverage Digital Touchpoints",
                description:
                  "Optimizing mobile apps, websites, and chatbots for personalized interactions.",
              },
              {
                heading: "Bridge Physical and Digital Gaps",
                description:
                  "Providing in-branch staff with real-time insights for tailored customer service.",
              },
            ],
          },
          {
            title: "AI-Driven Insights",
            content: [
              {
                heading: "Customer Segmentation",
                description:
                  "Identifying distinct customer groups to tailor offerings effectively.",
              },
              {
                heading: "Predictive Analytics",
                description:
                  "Anticipating customer needs by analyzing data trends.",
              },
              {
                heading: "Behavior Analysis",
                description:
                  "Monitoring interactions to detect churn risk and enable timely interventions.",
              },
            ],
          },
          {
            title: "Enhanced Engagement",
            content: [
              {
                heading: "Tailored Recommendations",
                description:
                  "Offering personalized financial advice and product suggestions based on data insights.",
              },
              {
                heading: "Proactive Communication",
                description:
                  "Sending timely alerts and updates to keep customers informed and engaged.",
              },
              {
                heading: "Feedback-Driven Improvements",
                description:
                  "Continuously refining services through customer feedback and data analysis.",
              },
            ],
          },
        ],
      };
    } else {
      // Risk Management and Regulatory Compliance
      return {
        title: area,
        description:
          "With evolving regulations and emerging threats, effective risk management and compliance are essential. We design proactive frameworks that safeguard operations and enhance competitive positioning.",
        collapsibleItems: [
          {
            title: "Developing Robust Governance and Risk Policies",
            content: [
              {
                heading: "Enterprise Risk Management (ERM)",
                description:
                  "Creating an integrated ERM framework that aligns risk management with business objectives.",
              },
              {
                heading: "Policy Customization",
                description:
                  "Crafting policies tailored to each institution’s unique operational context.",
              },
              {
                heading: "Board-Level Engagement",
                description:
                  "Empowering leadership with robust governance structures for informed decision-making.",
              },
            ],
          },
          {
            title: "Optimizing Compliance Workflows with Automation and Advanced Analytics",
            content: [
              {
                heading: "RegTech Integration",
                description:
                  "Implementing tools to automate processes such as KYC, AML monitoring, and reporting.",
              },
              {
                heading: "Advanced Analytics",
                description:
                  "Using data analytics to predict compliance risks and generate actionable insights.",
              },
              {
                heading: "Workflow Automation",
                description:
                  "Designing systems to reduce human error and expedite compliance processes.",
              },
            ],
          },
          {
            title: "Mitigating Emerging Threats",
            content: [
              {
                heading: "Cybersecurity Frameworks",
                description:
                  "Implementing robust security measures including real-time threat detection and incident response.",
              },
              {
                heading: "Fraud Prevention Solutions",
                description:
                  "Deploying AI and machine learning to detect and prevent fraudulent activities.",
              },
              {
                heading: "Scenario Analysis and Stress Testing",
                description:
                  "Conducting tests to assess resilience against adverse scenarios.",
              },
            ],
          },
          {
            title: "Navigating Complex Regulatory Environments",
            content: [
              {
                heading: "Stay Informed",
                description:
                  "Monitoring global regulatory changes to stay ahead of new requirements.",
              },
              {
                heading: "Achieve Global Standards",
                description:
                  "Aligning operations with international frameworks like Basel III, GDPR, and ESG guidelines.",
              },
              {
                heading: "Enhance Reporting Accuracy",
                description:
                  "Streamlining regulatory reporting for timely and precise submissions.",
              },
            ],
          },
          {
            title: "Creating a Culture of Resilience",
            content: [
              {
                heading: "Ongoing Commitment",
                description:
                  "Establishing a continuous commitment to excellence in risk management.",
              },
              {
                heading: "Employee Engagement",
                description:
                  "Fostering a culture where every employee understands their role in mitigating risks.",
              },
              {
                heading: "Long-Term Success",
                description:
                  "Building resilient business models that adapt to evolving challenges.",
              },
            ],
          },
        ],
      };
    }
  };

  return (
    <div className="mx-auto px-4 lg:px-32 mt-8 lg:mt-16 max-w-6xl">
      {/* Page Title and Description */}
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold text-purple-900 mb-4">
          Key Areas of Expertise
        </h2>
        <p className="text-lg text-gray-600">
          Seismic Consulting’s Change Management & Strategy Consulting services
          deliver tangible, sustainable outcomes that drive long-term success.
        </p>
      </div>

      {/* List Each Area */}
      {areas.map((area) => {
        const areaContent = getContentForArea(area);
        return (
          <div key={area} className="mb-10 border-b pb-6">
            <h2 className="text-2xl font-bold text-purple-900 mb-2">
              {areaContent.title}
            </h2>
            <p className="text-gray-700 text-lg mb-4">
              {areaContent.description}
            </p>
            {areaContent.collapsibleItems.map((item) => {
              const key = `${area}-${item.title}`;
              return (
                <div key={item.title} className="mb-4">
                  <button
                    onClick={() => toggleInner(area, item.title)}
                    className="flex justify-between items-center w-full text-left text-xl font-semibold text-purple-900 bg-purple-50 py-3 px-4 rounded hover:bg-purple-50 transition"
                  >
                    <span>{item.title}</span>
                    <span className="text-2xl">{innerOpen[key] ? "-" : "+"}</span>
                  </button>
                  {innerOpen[key] && (
                    <div className="pl-6 mt-2 text-gray-600 text-base leading-relaxed">
                      {item.content.map((point, idx) => (
                        <div key={idx} className="mb-3">
                          <h4 className="font-semibold">{point.heading}</h4>
                          <p>{point.description}</p>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="border-t border-gray-300 mt-4"></div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default FinancialKeyAreasOfExpertise;

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const AuditAndFinancialControlSystems = () => {
  return (
    <div className="w-full bg-white">
{/* Hero Section */}
<section
  className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
  style={{ backgroundImage: "url('/PagesImages/afcs.png')" }}
>
  <div className="absolute inset-0 bg-black bg-opacity-30"></div>
  {/* Title Overlay for Large Screens */}
  <div
    className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
  >
    <h1 className="text-3xl font-bold text-gray-800 mt-4">
      Audit and Financial Control Systems
    </h1>

  </div>
</section>


   {/* Foreword Section */}
      <section className="max-w-4xl mx-auto py-16 px-6 lg:px-0">
                  {/* Title Below Image for Small Screens */}
<div className="block lg:hidden mt-4 mb-4 text-left">
  <h1 className="text-2xl font-bold text-gray-800">
  Audit and Financial Control Systems
  </h1>
</div>
      <h2 className="text-base lg:text-xl font-semibold text-gray-600 mt-8">
      Ensuring transparency and accountability in financial operations
    </h2> 
        <p className="text-lg text-gray-700 mb-8">
          At Seismic, we specialize in designing and implementing robust internal controls and audit systems that adhere to International Public Sector Accounting Standards (IPSAS), ensuring financial integrity and reducing the risk of corruption. Our comprehensive audit solutions provide public institutions with the tools they need for effective financial oversight.
        </p>
        <div className="flex flex-col lg:flex-row items-start gap-8 mt-20">
          <img
            src="/PagesImages/audit_small.jpg" // Replace with the correct small image path
            alt="Audit and Financial Integrity"
            className="w-full lg:w-5/12 object-cover"
          />
          <p className="text-lg text-gray-700 lg:w-7/12 mt-4">
            Seismic's audit services combined with cutting-edge technology empower governments to maintain transparency, accountability, and financial oversight, building public trust and credibility.
          </p>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16">
        <div className="max-w-4xl mx-auto px-6 lg:px-0">
          <h2 className="text-3xl font-semibold mb-8">Our Approach</h2>
          <ul className="space-y-8">
            {[
              {
                title: "Strengthening Financial Integrity through Internal and External Audits",
                description:
                  "Our audit services include both internal and external audits, ensuring public institutions adhere to financial policies, regulations, and international standards. We evaluate financial controls comprehensively, identifying compliance gaps and areas for improvement.",
              },
              {
                title: "Capacity Building for Sustainable Audit Functions",
                description:
                  "Seismic offers targeted training programs to build strong audit capabilities within public institutions. Our capacity-building initiatives empower auditors to conduct effective audits, uphold accountability, and maintain high standards of financial oversight.",
              },
              {
                title: "Leveraging Technology for Efficient Audit Processes",
                description:
                  "We utilize advanced audit management software and FMIS to enhance audit accuracy and efficiency. Our technology solutions enable real-time financial monitoring, fostering efficient collaboration between internal and external auditors.",
              },
              {
                title: "Risk-Based Auditing for Focused Oversight",
                description:
                  "Seismic's risk-based auditing approach focuses on high-risk financial areas, optimizing audit processes to ensure effective resource use. This proactive strategy strengthens overall financial management and fiscal stability.",
              },
              {
                title: "Global Expertise, Tailored Solutions",
                description:
                  "With decades of global experience, Seismic delivers customized audit solutions to meet unique governance needs. We work with diverse clients, from developing nations building foundational audit systems to advanced economies refining financial controls.",
              },
            ].map((approach, index) => (
              <li key={index} className="flex gap-4">
                <span className="text-purple-900 text-2xl">▶</span>
                <div className="text-lg font-semibold">
                  {approach.title}: <span className="font-normal text-gray-700">{approach.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

{/* Other Services Section */}
<section className="py-16">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-gray-800 mb-8">Other Services</h2>
    <p className="text-xl text-gray-600 mb-8">
      Helping clients bridge the fundamental gaps in the education system
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[
        {
          title: "Capacity Building and Training",
          description:
            "We provide collaboration-based training initiatives that promote continuous learning and improvement within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cbt}`,
        },
        {
          title: "Capital Investment Planning",
          description:
            "Our services assist governments in developing strategic capital investment plans aligned with sustainable development goals.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cip}`,
        },
        {
          title: "Financial Management Information Systems (FMIS)",
          description:
            "Seismic designs FMIS solutions that modernize public financial management, enhancing fiscal discipline and accountability.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.fmis}`,
        },
      ].map((service, index) => (
        <Link
          to={service.link}
          key={index}
          className="block bg-white p-6 shadow-md rounded-md text-left max-w-[450px] mx-auto"
        >
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            {service.title}
          </h3>
          <p className="text-lg text-gray-600 mb-4">
            {service.description}
          </p>

        </Link>
      ))}
    </div>
  </div>
</section>

    </div>
  );
};

export default AuditAndFinancialControlSystems;

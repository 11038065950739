// src/pages/DigitalHealthcare.jsx
import React from "react";

const DigitalHealthcare = () => {
  // Data for Digital Healthcare projects
  const projects = [
    {
      "title": "Mental NG",
      "overview": "Mental NG is an AI-powered platform that provides accessible, timely, and accurate mental health support. The platform leverages advanced AI technology to deliver personalized assessments, ensuring users can understand their mental well-being and receive the appropriate care. With its easy-to-use tools for early detection, diagnosis, and professional consultation, Mental NG enhances mental health management for everyone.",
      features: [
        "AI-Powered Assessment Engine – Advanced algorithms analyze user responses to assess mental health symptoms and provide personalized insights.",
        "Diagnostic Tool – Offers accurate, AI-driven diagnoses based on standardized tests and questionnaires, such as PHQ-9 for depression and GAD-7 for anxiety.",
        "Chat Module – A conversational engine where users can express their emotions and receive emotional support, available for free and accessible without account creation.",
        "Referral System – Recommends human therapists or mental health professionals when deeper care is needed, helping users connect with the right experts.",
        "Early Detection – Uses AI to identify early signs of mental health concerns, allowing for prompt intervention and better long-term management.",
        "Continuous Improvement – AI models are automatically retrained and validated by professionals to ensure the system stays accurate and effective."
      ]
    },    
    {
      title: "Hospital ERP",
      overview: `Hospital ERP is a comprehensive, digital management system designed to optimize and automate end-to-end hospital workflows. The system provides a centralized platform that enhances operational efficiency, patient care, and administrative processes in healthcare facilities.

One of its key features is a real-time SOAP Note transcription tool, which streamlines doctor-patient consultations, ensuring accurate and structured medical records. Additionally, the system integrates appointment scheduling, inventory management, and billing processes, creating a seamless hospital management experience.`,
      useCases: [
        "Hospitals & Clinics – Automates patient records, appointment scheduling, and billing, reducing paperwork and administrative burdens.",
        "Doctors & Medical Staff – Enhances clinical documentation with real-time SOAP Note transcription for better patient care.",
        "Pharmacy & Inventory Management – Tracks medications, medical supplies, and stock levels to prevent shortages and wastage.",
        "Finance & Billing Departments – Automates medical billing, insurance claims processing, and payment tracking for accurate revenue management.",
        "Patient Portals & Telemedicine – Provides secure patient access to medical records, prescriptions, and teleconsultations.",
        "Regulatory & Compliance Bodies – Ensures compliance with health standards, data protection laws, and hospital accreditation requirements.",
      ],
    },
  ];

  return (
    <section className="bg-gray-50">
      {/* Hero Section */}
      <div
        className="relative w-full h-[500px] sm:h-[600px] bg-black bg-cover bg-center flex items-center justify-center"
        style={{
          backgroundImage:
            "url('https://via.placeholder.com/1500x600?text=Digital+Healthcare')",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-teal-700 to-blue-900 opacity-80"></div>
        <div className="relative z-10 text-center px-4">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-white tracking-wide drop-shadow-lg">
            Digital Healthcare
          </h1>
          <p className="mt-4 text-xl sm:text-2xl text-gray-200 max-w-2xl mx-auto drop-shadow">
            Empowering healthcare with innovative digital solutions.
          </p>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="max-w-4xl mx-auto px-4 py-16 text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-6">
          Our Digital Healthcare Solutions
        </h2>
        <p className="text-gray-700 text-lg leading-relaxed">
          We are committed to transforming healthcare through digital innovation.
          Explore our flagship projects that enhance mental health care and streamline
          hospital management to deliver better patient outcomes.
        </p>
      </div>

      {/* Projects Section */}
      <div className="max-w-6xl mx-auto px-4 pb-16 space-y-16">
        {projects.map((project, idx) => (
          <div
            key={idx}
            className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105"
          >
            <h2 className="text-3xl font-bold text-blue-800 mb-4">
              {project.title}
            </h2>
            <div className="mb-6">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                Overview
              </h3>
              {project.overview.split("\n\n").map((para, i) => (
                <p key={i} className="text-gray-700 text-lg leading-relaxed mb-4">
                  {para}
                </p>
              ))}
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                {project.title === "Mental NG" ? "Features" : "Use Cases"}
              </h3>
              <ul className="mt-2 list-disc list-inside text-gray-700 space-y-3 text-lg">
                {(project.title === "Mental NG" ? project.features : project.useCases).map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DigitalHealthcare;

import React from "react";

function IntelligentOperations() {
  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Intelligent Operations and Smart Manufacturing
          </h1>
        </div>
      </section>

      {/* Introduction */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            At Seismic Consulting Group, we lead the transformation of
            manufacturing and operational processes using AI, IoT, and data
            analytics. Our solutions drive efficiency, reduce costs, and
            position businesses for sustained success in the digital era.
          </p>
        </div>
      </section>

      {/* IoT and Smart Asset Management */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            IoT and Smart Asset Management
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Seismic leverages IoT to optimize asset tracking, inventory
            management, and predictive maintenance—enabling real-time
            visibility into operational infrastructures.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="font-bold text-gray-800">
                Asset Tracking & Inventory
              </h3>
              <p className="text-gray-700">
                Implementing IoT for real-time monitoring of assets and
                inventory, optimizing utilization and minimizing losses.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">Predictive Maintenance</h3>
              <p className="text-gray-700">
                Using IoT sensor data to predict failures, reduce
                downtime, and extend equipment lifespan.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">Resource Optimization</h3>
              <p className="text-gray-700">
                IoT-driven insights streamline workflow scheduling and asset
                allocation for maximum efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Smart Factory Solutions */}
      <section className="bg-white py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            Smart Factory Solutions
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Seismic transforms traditional factories into smart, AI-integrated
            manufacturing environments that optimize processes and improve
            responsiveness.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="font-bold text-gray-800">Digital Twins</h3>
              <p className="text-gray-700">
                Creating virtual replicas of production lines for simulation,
                testing, and optimization.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">AI-Driven Maintenance</h3>
              <p className="text-gray-700">
                Using machine learning to analyze machinery data and predict
                failures before they occur.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">Real-Time Monitoring</h3>
              <p className="text-gray-700">
                Enabling live tracking of production efficiency, reducing
                waste, and increasing throughput.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* AI-Enabled Supply Chain Optimization */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-purple-900 mb-6">
            AI-Enabled Supply Chain Optimization
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Seismic deploys AI to predict demand, optimize inventory, and
            streamline logistics—ensuring greater supply chain efficiency.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="font-bold text-gray-800">Demand Forecasting</h3>
              <p className="text-gray-700">
                AI algorithms analyze market trends and historical data for
                precise inventory planning.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">Optimized Inventory</h3>
              <p className="text-gray-700">
                AI predicts stock levels, reducing overstock and stockouts,
                leading to improved cash flow.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-gray-800">
                Logistics & Distribution
              </h3>
              <p className="text-gray-700">
                AI-powered tools optimize routing and delivery scheduling to
                cut costs and enhance service levels.
              </p>
            </div>
          </div>
        </div>
      </section>

      
    </div>
  );
}

export default IntelligentOperations;

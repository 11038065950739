import React, { useState } from "react";

const expertiseData = [
  {
    title: "5G and Next-Generation Networks",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737458842/pexels-markus-winkler-1430818-18510433_jzlsyg.jpg",
    description: `The arrival of 5G technology has ushered in a new era of connectivity and performance, reshaping industries and unlocking new possibilities. At Seismic Consulting Group, we help organizations harness the transformative potential of 5G.`,
    services: [
      "Strategic Planning and Deployment: We design and execute comprehensive strategies for 5G rollout, ensuring that businesses capitalize on the opportunities of this groundbreaking technology.",
      "High-Performance Optimization: Leveraging advanced analytics to refine state-of-the-art networks, we optimize networks to support increased connectivity, automation, augmented reality (AR), virtual reality (VR), and telemedicine.",
      "Regulatory and Spectrum Management: We offer guidance on navigating complex regulatory landscapes, ensuring compliance while securing optimal allocation of spectrum resources.",
    ],
    summary: `By enabling faster, more reliable, and expansive connectivity, our clients are empowered to deliver groundbreaking services, open new revenue streams, and redefine customer experiences in an increasingly digital world.`,
  },
  {
    title: "Cybersecurity and Data Protection",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737459057/istockphoto-1412282189-612x612_m0vaw4.jpg",
    description: `The digital revolution brings unparalleled opportunities but also significant risks. Cyber threats are becoming increasingly sophisticated, and ensuring the security of sensitive data is crucial for organizations to maintain trust and operational continuity.`,
    services: [
      "Comprehensive Security Strategies: We develop robust, multi-layered security frameworks tailored to protect networks, systems, and sensitive data from evolving cyber threats.",
      "Advanced Threat Detection: By integrating AI-driven threat detection tools, we help organizations identify and mitigate potential vulnerabilities before they can be exploited.",
      "Regulatory Compliance: Our team ensures alignment with global and regional data protection regulations, including GDPR, HIPAA, and CCPA, minimizing legal risks and enhancing customer confidence.",
    ],
    summary: `With our solutions, clients can operate confidently in a secure digital environment, safeguarding their reputation and ensuring uninterrupted service delivery to their customers.`,
  },
  {
    title: "Digital Product Innovation",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733511908/pexels-onbab-29591863_ogwvp2.jpg",
    description: `Consumer expectations evolve rapidly, and businesses must innovate to stay competitive. At Seismic Consulting, we help companies conceptualize, design, and launch groundbreaking digital products that meet and exceed market demands.`,
    services: [
      "Product Ideation and Design: We work closely with clients to understand user needs and create innovative products that resonate with target audiences.",
      "Product Lifecycle Management: From prototyping and testing to market entry, we streamline the development process to ensure timely delivery and optimal performance.",
      "Integration of Emerging Technologies: Harnessing the power of AI, blockchain, and cloud computing, we create solutions that drive efficiency, scalability, and innovation.",
    ],
    summary: `Our clients establish themselves as market leaders, delivering transformative digital products that build customer loyalty and drive sustained growth in a competitive marketplace.`,
  },
  {
    title: "Smart Cities and IoT Solutions",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737459252/pexels-photo-681335_pg5myh.jpg",
    description: `Smart cities represent the future of urban living, where interconnected systems enhance quality of life, optimize resources, and drive sustainability. Seismic Consulting is a trusted partner in designing and implementing IoT-enabled solutions that transform urban environments.`,
    services: [
      "IoT Infrastructure Deployment: From smart lighting and waste management to energy-efficient grids and intelligent traffic systems, we enable cities to leverage IoT technologies for improved functionality.",
      "Data-Driven Decision-Making: We empower municipalities with real-time data analytics, enabling informed decisions that optimize resource allocation and enhance service delivery.",
      "Public-Private Collaboration: We foster partnerships between governments and private enterprises, ensuring scalable and sustainable implementation of smart city initiatives.",
    ],
    summary: `Our work transforms urban spaces into vibrant, connected communities. By fostering innovation and sustainability, we improve residents’ quality of life and help cities achieve their goals of efficiency and environmental stewardship.`,
  },
];

const KeyAreasOfExpertise = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-16">
      <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
        Key Areas of Expertise
      </h2>
      {expertiseData.map((item, index) => (
        <div key={index} className="mb-12 border-b border-pink-100 pb-8">
          {/* Header Section */}
          <button
            onClick={() => toggleSection(index)}
            className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
          >
            {item.title}
            <span className="text-purple-900 text-3xl">
              {openSection === index ? "-" : "+"}
            </span>
          </button>
          {openSection === index && (
            <div>
              {/* Image and Description */}
              <div className="flex flex-col lg:flex-row gap-8 mt-4">
  <img
    src={item.image}
    alt={item.title}
    className="w-full lg:w-1/2 h-48 object-cover shadow-lg rounded-lg"
  />
  <div className="lg:w-1/2">
    <p className="text-gray-700 text-lg mb-4">{item.description}</p>
  </div>
</div>


              {/* Divider */}
              <div className="border-t-2  my-6" style={{ borderColor: "#ECD9EC" }}></div>

              {/* Services Section */}
              <div className="mt-6 px-6 lg:px-32">
                <h3 className="text-xl font-medium mb-4">Our services include:</h3>
                <ul className="list-none text-gray-700 text-lg space-y-4">
                  {item.services.map((service, idx) => (
                    <li key={idx} className="relative pl-6">
                      <span
                        className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 rounded-full"
                      ></span>
                      <span className="font-bold">{service.split(":")[0]}:</span>{" "}
                      {service.split(":")[1]}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Summary Section */}
              <p className="text-gray-700 text-lg mt-6 px-6 lg:px-12">
                {item.summary}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default KeyAreasOfExpertise;

import React, { useState } from "react";

const MethodologySectionCM = () => {
  const [open, setOpen] = useState({
    stakeholderEngagement: false,
    roadmapDevelopment: false,
    monitoringEvaluation: false,
  });

  const toggleCollapse = (key) => {
    setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div className="mb-16">
      <h2 className="text-2xl font-bold mb-4 text-center pt-12">Our Methodology</h2>
      <h3 className="text-xl text-center mb-4 text-gray-600">
      Structured, Inclusive, and Adaptive
      Seismic’s Change Management Frameworks are underpinned by a thorough and systematic methodology that ensures every step of the process is optimized for success.
      </h3>
      {/* Divider Line */}
      <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-8 mt-8"></div>

      <div className="max-w-4xl mx-auto space-y-16 mt-12">
        {/* Stakeholder Analysis and Engagement */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("stakeholderEngagement")}
          >
            Stakeholder Analysis and Engagement
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.stakeholderEngagement ? "rotate-180" : ""
              }`}
            >
              {open.stakeholderEngagement ? "-" : "+"}
            </span>
          </button>
          {open.stakeholderEngagement && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Identification of Key Stakeholders:</strong> Analyze who will be impacted by the change and assess their influence, needs, and potential concerns.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Proactive Communication Plans:</strong> Tailor messaging to ensure clarity, transparency, and alignment with stakeholders’ expectations.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Engagement Strategies:</strong> Facilitate regular dialogues, workshops, and feedback sessions to foster trust and inclusivity.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Development of Detailed Roadmaps */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("roadmapDevelopment")}
          >
            Development of Detailed Roadmaps
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.roadmapDevelopment ? "rotate-180" : ""
              }`}
            >
              {open.roadmapDevelopment ? "-" : "+"}
            </span>
          </button>
          {open.roadmapDevelopment && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Change Readiness Assessments:</strong> Evaluate organizational preparedness for transformation to pinpoint areas requiring additional support.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Comprehensive Planning:</strong> Develop roadmaps that outline clear timelines, defined roles, and necessary resources.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Contingency Planning:</strong> Create backup plans to address unforeseen challenges, ensuring resilience throughout the process.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Continuous Monitoring and Evaluation */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("monitoringEvaluation")}
          >
            Continuous Monitoring and Evaluation
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.monitoringEvaluation ? "rotate-180" : ""
              }`}
            >
              {open.monitoringEvaluation ? "-" : "+"}
            </span>
          </button>
          {open.monitoringEvaluation && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Progress Tracking:</strong> Establish metrics and key performance indicators (KPIs) to measure progress and effectiveness.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Feedback Mechanisms:</strong> Implement systems for gathering real-time insights from stakeholders, enabling swift course corrections.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Iterative Adjustments:</strong> Refine strategies based on evaluations to ensure ongoing alignment with objectives.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>
      </div>
    </div>
  );
};

export default MethodologySectionCM;

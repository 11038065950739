// src/pages/DigitalFinancialServices.jsx
import React from "react";

const DigitalFinancialServices = () => {
  return (
    <section className="bg-gray-50">
{/* Hero Section */}
<div
  className="relative w-full h-[500px] sm:h-[600px] bg-black bg-cover bg-center flex items-center justify-center"
  style={{
    backgroundImage:
      "url('https://via.placeholder.com/1500x600?text=Digital+Financial+Services')",
  }}
>
  <div className="absolute inset-0 bg-gradient-to-r from-green-900 to-blue-900 opacity-80"></div>
  <div className="relative z-10 text-center px-4">
    <h1 className="text-4xl sm:text-5xl font-extrabold text-white tracking-wide drop-shadow-lg">
      Digital Financial Services
    </h1>
    <p className="mt-4 text-xl sm:text-2xl text-gray-200 max-w-2xl mx-auto drop-shadow">
      Enhancing financial planning and secure transactions for the education sector.
    </p>
  </div>
</div>


      {/* Introduction */}
      <div className="max-w-4xl mx-auto px-4 py-16 text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-6">
          Our Financial Services Solutions
        </h2>
        <p className="text-gray-700 text-lg leading-relaxed">
          We are committed to transforming the financial landscape in education. Explore our innovative digital solutions designed to optimize budgeting, enhance fiscal planning, and ensure secure, seamless transactions.
        </p>
      </div>

      {/* Projects Section */}
      <div className="max-w-6xl mx-auto px-4 pb-16 space-y-16">
        {/* Fiscal Budget and Planning System */}
        <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-green-800 mb-4">
            Fiscal Budget and Planning System for the Ministry of Education
          </h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Overview</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
              The Fiscal Budget and Planning System is a digital solution designed to enhance budget formulation, allocation, and financial planning within the Ministry of Education. The system provides real-time financial tracking, automated reporting, and data-driven decision-making to ensure efficient resource allocation for educational programs, infrastructure, and institutional development.
            </p>
            <p className="mt-4 text-gray-700 text-lg leading-relaxed">
              By integrating budget forecasting, expenditure monitoring, and compliance tracking, this system ensures transparency, accountability, and strategic financial planning in the education sector.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Use Cases</h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>
                <strong>Budget Formulation &amp; Allocation</strong> – Assists in preparing annual budgets and distributing funds efficiently across schools, agencies, and programs.
              </li>
              <li>
                <strong>Financial Tracking &amp; Monitoring</strong> – Enables real-time tracking of education expenditures, reducing waste and inefficiencies.
              </li>
              <li>
                <strong>Data-Driven Decision-Making</strong> – Provides financial analytics and reports to aid policymakers in resource planning and policy development.
              </li>
              <li>
                <strong>Grant &amp; Funding Management</strong> – Tracks domestic and international educational grants, ensuring proper utilization of funds.
              </li>
              <li>
                <strong>Compliance &amp; Auditing</strong> – Ensures that expenditures comply with government regulations and education sector priorities.
              </li>
              <li>
                <strong>Project &amp; Infrastructure Financing</strong> – Supports funding allocation for school renovations, technology upgrades, and capacity-building initiatives.
              </li>
            </ul>
          </div>
        </div>

        {/* Payment Gateway */}
        <div className="bg-white rounded-lg shadow-xl p-8 transform transition hover:scale-105">
          <h2 className="text-3xl font-bold text-green-800 mb-4">Payment Gateway</h2>
          <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Overview</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
              The Payment Gateway is a secure, in-house transaction processing tool designed to facilitate efficient, seamless, and fraud-resistant payments. It supports multi-currency transactions, ensuring global accessibility while providing a user-friendly API for easy integration into e-commerce platforms, financial institutions, and businesses.
            </p>
            <p className="mt-4 text-gray-700 text-lg leading-relaxed">
              Equipped with robust fraud detection mechanisms, the Payment Gateway ensures safe transactions, reducing risks associated with online payments, chargebacks, and unauthorized access. Its architecture prioritizes speed, security, and scalability, making it an ideal solution for merchants, fintech platforms, and digital businesses.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">Use Cases</h3>
            <ul className="mt-2 list-disc list-inside text-gray-700 space-y-3 text-lg">
              <li>
                <strong>E-commerce Platforms</strong> – Enables businesses to accept secure online payments with multiple payment options.
              </li>
              <li>
                <strong>Financial Institutions &amp; Banks</strong> – Integrates with banking systems to facilitate seamless fund transfers and payment processing.
              </li>
              <li>
                <strong>Subscription-Based Services</strong> – Supports automated recurring payments for SaaS businesses, memberships, and digital services.
              </li>
              <li>
                <strong>Retail &amp; POS Systems</strong> – Ensures smooth in-store and online transactions with multi-channel payment support.
              </li>
              <li>
                <strong>Peer-to-Peer (P2P) Transactions</strong> – Facilitates secure fund transfers between individuals across different payment platforms.
              </li>
              <li>
                <strong>Government &amp; Utility Payments</strong> – Provides a safe and scalable solution for tax collection, public sector transactions, and utility bill payments.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DigitalFinancialServices;

// src/pages/TermsAndConditions.jsx
import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="max-w-5xl mx-auto px-6 py-12 mt-20">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Terms and Conditions</h1>
      
      <p className="text-gray-700 text-lg mb-6">
        Welcome to [Your Company Name]. These Terms and Conditions ("Terms") govern your access to and use of our website and services. By accessing or using our website, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using our services.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">1. Introduction and Agreement</h2>
      <p className="text-gray-700 text-lg mb-6">
        This agreement constitutes a legally binding contract between you ("User") and [Your Company Name] ("Company", "we", "us", or "our"). By using our website, you acknowledge that you have read, understood, and agree to these Terms. These Terms apply to all users of our site and govern your use of our services.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">2. Definitions</h2>
      <p className="text-gray-700 text-lg mb-6">
        <strong>Service</strong> refers to any services, features, or content provided by Seismic Consulting Group on our website. <br />
        <strong>Content</strong> includes, but is not limited to, text, images, videos, and other materials available on the site.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">3. User Obligations</h2>
      <p className="text-gray-700 text-lg mb-4">
        As a user, you agree to:
      </p>
      <ul className="list-disc list-inside text-gray-700 text-lg mb-6">
        <li>Provide accurate and up-to-date information when required.</li>
        <li>Maintain the confidentiality of your account and password if applicable.</li>
        <li>Use our services only for lawful purposes and in accordance with these Terms.</li>
        <li>Not misuse or attempt to disrupt our website or its services.</li>
      </ul>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">4. Intellectual Property Rights</h2>
      <p className="text-gray-700 text-lg mb-6">
        All content, trademarks, logos, and intellectual property on this website are the property of Seismic Consulting Group or its licensors and are protected by applicable intellectual property laws. You agree not to reproduce, distribute, or create derivative works without our prior written consent.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">5. Prohibited Activities</h2>
      <p className="text-gray-700 text-lg mb-4">
        You agree not to engage in any of the following activities:
      </p>
      <ul className="list-disc list-inside text-gray-700 text-lg mb-6">
        <li>Using the Service for any illegal or unauthorized purpose.</li>
        <li>Interfering with or disrupting the operation of our website.</li>
        <li>Attempting to gain unauthorized access to any part of the Service.</li>
        <li>Employing automated means (robots, spiders, etc.) to access or collect data from our website.</li>
      </ul>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">6. Disclaimer of Warranties</h2>
      <p className="text-gray-700 text-lg mb-6">
        Our Service is provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind, either express or implied. Seismic Consulting Group does not warrant that the Service will be uninterrupted, error-free, or completely secure.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">7. Limitation of Liability</h2>
      <p className="text-gray-700 text-lg mb-6">
        In no event shall [Your Company Name] be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use or inability to use the Service, even if we have been advised of the possibility of such damages.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">8. Indemnification</h2>
      <p className="text-gray-700 text-lg mb-6">
        You agree to indemnify, defend, and hold harmless Seismic Consulting Group and its affiliates from any claims, liabilities, damages, and expenses (including legal fees) arising from your use of the Service or violation of these Terms.
      </p>
      
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">10. Changes to These Terms</h2>
      <p className="text-gray-700 text-lg mb-6">
        We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on this page. Your continued use of the Service constitutes your acceptance of the updated Terms.
      </p>
      
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">11. Contact Us</h2>
      <p className="text-gray-700 text-lg">
        If you have any questions about these Terms, please contact us at{" "}
        <a href="mailto:email@example.com" className="text-purple-900">info@seismicconsults.com</a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;

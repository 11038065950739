import React, { useState, useEffect } from "react";

const collapsibleData = {
  "Precision Agriculture": [
    {
      title: "IoT-Enabled Sensors",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737463924/pexels-jakubzerdzicki-27662922_z6rraa.jpg",
      description: `Internet of Things (IoT) sensors serve as the backbone of precision agriculture, providing real-time, granular data that informs every aspect of farm management. These sensors collect critical information, such as:`,
      points: [
        "Soil Moisture Levels: Ensuring optimal irrigation by delivering precise measurements that prevent overwatering or underwatering, conserving water resources, and improving crop health.",
        "Nutrient Content: Monitoring soil nutrient levels to guide fertilizer application, reducing excess use and mitigating nutrient runoff into surrounding ecosystems.",
        "Weather Conditions: Offering hyper-localized forecasts and real-time updates on temperature, humidity, and wind patterns, enabling proactive responses to changing conditions.",
      ],
    },
    {
      title: "Drones and Satellite Imagery",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1734696475/pexels-inmortal-producciones-108299-336232_1_dfjaec.jpg",
      description: `Drones and satellite technology revolutionize how farmers monitor and manage their crops by providing high-resolution imagery and actionable insights. Key benefits include:`,
      points: [
        "Crop Health Monitoring: Advanced imaging capabilities, such as multispectral and thermal imaging, detect early signs of pest infestations, nutrient deficiencies, or water stress, enabling timely corrective actions.",
        "Field Mapping and Analysis: Creating detailed field maps that identify variations in soil quality, plant density, and growth rates, guiding targeted applications of inputs like seeds, water, and fertilizers.",
        "Efficient Resource Allocation: Reducing waste and operational costs by pinpointing areas that require attention, ensuring that resources are applied where they are needed most.",
      ],
    },
    {
      title: "Data-Driven Insights",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736851169/pexels-weekendplayer-187041_voljsh.jpg",
      description: `Data is at the heart of precision agriculture, transforming raw information into actionable strategies. Through predictive analytics and AI-driven tools, we enable farmers to:`,
      points: [
        "Optimize Planting Schedules: Using historical and real-time data to determine the best planting and harvesting times, ensuring higher yields and better market timing.",
        "Irrigation Management: Implementing dynamic irrigation models that adjust water delivery based on real-time soil and weather data, reducing water usage and enhancing crop resilience.",
        "Fertilizer Application: Tailoring fertilizer applications to specific crop needs and soil conditions, maximizing nutrient efficiency while reducing environmental impact.",
      ],
    },
    {
        title: "Impact Beyond Yields",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737464070/pexels-photo-7782981_fqajfy.jpg",
        description: `Through our precision agriculture solutions, we help transform farming into a sustainable, resilient, and data-driven enterprise that can meet the demands of a growing global population. The benefits include:`,
        points: [
          "Environmental Conservation: Lower greenhouse gas emissions, reduced water consumption, and minimized chemical runoff contribute to healthier ecosystems.",
          "Enhanced Resilience: Real-time insights and predictive analytics enable farmers to adapt to climate variability and extreme weather conditions.",
          "Economic Growth: By optimizing resource use and reducing waste, farmers and agribusinesses achieve greater profitability and long-term viability.",
        ],
      },
  ],
  "Sustainable Farming Practices": [
    {
      title: "Organic Farming",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737464174/pexels-photo-3297455_dp5afc.jpg",
      description: `Organic farming embodies a commitment to reducing the environmental impact of agriculture while enhancing soil fertility and product quality. By guiding clients in the transition to organic practices, we enable them to:`,
      points: [
        "Eliminate Chemical Dependency: Avoid synthetic fertilizers, pesticides, and herbicides that degrade soil and pollute ecosystems.",
        "Improve Soil Fertility: Use crop rotations, cover crops, and organic amendments to boost soil organic matter, ensuring sustained nutrient availability and enhanced soil structure.",
        "Produce Healthier Crops: Cultivate food that meets increasing consumer demand for natural, chemical-free produce, opening access to premium markets and organic certification benefits.",
      ],
    },
    {
        title: "Agroforestry",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737464234/pexels-photo-7125689_e3dah4.jpg",
        description: `Agroforestry integrates trees and shrubs into farming landscapes, creating systems that benefit both agriculture and the environment. Our agroforestry strategies deliver:`,
        points: [
          "Enhanced Soil Stability: Tree roots reduce erosion and improve soil structure, protecting farmland from degradation and enhancing its productivity over time.",
          "Carbon Sequestration: Agroforestry systems act as carbon sinks, capturing atmospheric CO₂ and contributing to climate change mitigation efforts.",
          "Biodiversity Conservation: By creating diverse habitats, agroforestry supports pollinators, natural pest controllers, and other beneficial wildlife critical to ecosystem health.",
        ],
      },
      {
        title: "Regenerative Agriculture",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737464293/free-photo-of-a-close-up-of-apples-on-a-tree_kbjsql.jpg",
        description: `Regenerative agriculture is a holistic approach to farming that restores degraded ecosystems, enhances biodiversity, and improves the water cycle. Key practices include:`,
        points: [
          "Cover Cropping: Growing cover crops like legumes and grasses to protect soil from erosion, increase organic matter, and naturally replenish nutrients.",
          "No-Till Farming: Reducing or eliminating tillage to preserve soil structure, improve water retention, and reduce greenhouse gas emissions.",
          "Composting: Recycling organic waste into nutrient-rich compost that enhances soil fertility while reducing dependency on synthetic inputs.",
        ],
      },
      {
        title: "Impact Beyond the Farm",
        image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737464338/free-photo-of-trees-by-green-field-in-countryside_a6lfjv.jpg",
        description: `Sustainable farming practices offer benefits that extend far beyond individual operations:`,
        points: [
          "Environmental Conservation: Preserving soil, water, and biodiversity ensures the long-term health of ecosystems.",
          "Climate Resilience: By improving soil health and diversifying farming systems, clients can better withstand droughts, floods, and other climate challenges.",
          "Economic Opportunity: Sustainable farming reduces input costs, opens access to premium markets, and aligns with consumer trends toward ethical and environmentally responsible products.",
        ],
      },
  ],
  "Agri-Fintech Solutions": [
    {
      title: "Microloans and Innovative Financing Models",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737464832/Fintech-in-Agriculture_rkousb.jpg",
      description: `Smallholder farmers and agribusinesses often face significant barriers to accessing credit due to high costs, lack of collateral, and limited formal financial literacy. Seismic Consulting addresses these challenges by designing tailored microloan and financing programs that:`,
      points: [
        "Facilitate Investments in Modern Technologies: Providing smallholder farmers with the resources needed to adopt innovations like precision agriculture tools, high-yield seeds, and advanced irrigation systems.",
        "Introduce Flexible Repayment Terms: Developing repayment structures aligned with agricultural cycles, allowing farmers to repay loans after harvests.",
        "Enable Access to Credit for Underserved Communities: Partnering with financial institutions to extend credit to remote and underserved regions.",
      ],
    },
    {
      title: "Insurance Products for Risk Mitigation",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737464774/istockphoto-1270766965-612x612_is3fgn.jpg",
      description: `Seismic Consulting develops crop insurance and risk-sharing solutions tailored to the unique challenges of agriculture, including:`,
      points: [
        "Weather-Based Insurance: Leveraging satellite data and weather analytics to create index-based insurance products.",
        "Yield Protection Plans: Offering coverage that compensates farmers for yield losses caused by natural disasters, pests, or diseases.",
        "Market Price Insurance: Providing protection against price volatility in agricultural markets.",
      ],
    },
    {
      title: "Digital Payment Platforms",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737464928/pexels-ivan-samkov-7621136_nmsoot.jpg",
      description: `Efficient and secure financial transactions are critical for modernizing agriculture and ensuring financial inclusion. Our digital payment solutions transform how farmers and agribusinesses manage payments by:`,
      points: [
        "Streamlining Transactions: Facilitating seamless payments for seeds, fertilizers, equipment, and other inputs.",
        "Enhancing Transparency: Providing detailed transaction records that improve financial accountability.",
        "Expanding Financial Inclusion: Integrating mobile banking and e-wallet solutions to extend financial services to rural areas.",
      ],
    },
  ],
  "Supply Chain Optimization": [
    {
      title: "Traceability Systems",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737465116/electronic-inventory-management-concept-logistics-260nw-2235023207_qgcuvs.webp",
      description: `Traceability is no longer optional; it is a cornerstone of sustainable and ethical supply chains. By implementing advanced technologies such as blockchain and IoT, we enable agribusinesses to:`,
      points: [
        "Enhance Transparency: Provide end-to-end visibility across the supply chain.",
        "Ensure Food Safety: Quickly identify and address contamination sources or product recalls.",
        "Reduce Food Waste: Monitor product lifecycles and transportation conditions to prevent spoilage.",
      ],
    },
    {
      title: "Logistics Optimization",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737465214/pexels-photo-906982_otcfja.jpg",
      description: `Efficient logistics are critical for cost management, timely delivery, and reducing environmental impact. Seismic Consulting leverages analytics and technology-driven approaches to:`,
      points: [
        "Redesign Transportation Networks: Optimize routes, reduce fuel consumption, and minimize transit times.",
        "Improve Storage Solutions: Implement temperature-controlled storage facilities to preserve product quality.",
        "Enhance Distribution Systems: Coordinate inventory management and delivery processes to align with demand fluctuations.",
      ],
    },
    {
      title: "Sustainability Metrics and Monitoring Systems",
      image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1737465375/pexels-photo-7948031_cvmi9b.jpg",
      description: `Sustainability is a growing priority for agribusinesses. Our expertise ensures that organizations can:`,
      points: [
        "Track Environmental Impact: Measure carbon emissions, water usage, and energy consumption.",
        "Establish Compliance: Align with global sustainability standards.",
        "Set and Achieve Targets: Develop benchmarks and monitor progress against sustainability goals.",
      ],
    },
  ],
  // Other focus areas
};

const headings = {
  "Precision Agriculture": {
    title: "Our Expertise in Precision Agriculture",
    introduction: `Our expertise in precision agriculture encompasses a wide range of innovative tools and strategies tailored
    to the unique needs of each operation. By combining cutting-edge technology with actionable insights, we empower
    farmers to make data-driven decisions that improve efficiency, reduce waste, and adapt to the challenges of a changing climate.`,
  },
  "Sustainable Farming Practices": {
    title: "Cultivating Resilience and Stewardship in Agriculture",
    introduction: `        
        Our comprehensive approach to sustainable farming focuses on integrating innovative practices that prioritize 
        soil health, biodiversity, and environmental stewardship, empowering clients to adapt to changing climates 
        and meet the growing demand for eco-friendly, ethically produced products.`,
  },
"Agri-Fintech Solutions": {
  title: "Empowering Farmers and Agribusinesses Through Financial Innovation",
  introduction: `
    Access to financial resources is essential for driving growth, resilience, and innovation in agriculture. 
    At Seismic Consulting Group, we bridge the gap between financial institutions and agricultural stakeholders, 
    enabling farmers and agribusinesses to access tailored financial tools. Through innovative fintech solutions, 
    we foster economic empowerment, enhance financial inclusion, and build resilience against market and climate risks.
  `,
},
"Supply Chain Optimization": {
  title: "Transforming Agribusiness Supply Chains for Efficiency, Transparency, and Sustainability",
  introduction: `
    In today's fast-paced agricultural industry, supply chain optimization is crucial for reducing inefficiencies, 
    ensuring transparency, and promoting sustainability. At Seismic Consulting Group, we empower agribusinesses 
    to create resilient, traceable, and sustainable supply chains by integrating advanced technologies, enhancing logistics, 
    and fostering collaboration across the value chain. Our solutions help clients adapt to market demands, meet regulatory 
    standards, and achieve long-term success.
  `,
},
  // Other focus area headings and introductions
};

const AgricultureCollapsible = ({ activeFocus }) => {
  const [openSection, setOpenSection] = useState(null);
  const [currentSections, setCurrentSections] = useState(collapsibleData[activeFocus]);

  useEffect(() => {
    setCurrentSections(collapsibleData[activeFocus]);
    setOpenSection(null); // Reset open section when the active tab changes
  }, [activeFocus]);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-16">
      {/* Heading and Introduction */}
      <div className="text-left mb-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">
          {headings[activeFocus]?.title}
        </h2>
        <p className="text-gray-700 text-lg">
          {headings[activeFocus]?.introduction}
        </p>
      </div>

      {/* Collapsible Sections */}
      {currentSections.map((section, index) => (
        <div key={index} className="mb-8 border-b border-gray-300">
          <button
            onClick={() => toggleSection(index)}
            className="w-full text-left flex justify-between items-center py-6 text-2xl font-semibold text-gray-900"
          >
            {section.title}
            <span className="text-purple-900 text-3xl">
              {openSection === index ? "-" : "+"}
            </span>
          </button>
          {openSection === index && (
            <div className="pb-6">
              <div className="flex flex-col lg:flex-row items-start gap-8">
                <img
                  src={section.image}
                  alt={section.title}
                  className="w-full lg:w-5/12 h-36  object-cover shadow-lg rounded-lg"
                />
                <div className="lg:w-7/12">
                  <p className="text-gray-700 text-lg">{section.description}</p>
                </div>
              </div>

              {/* Divider */}
              <div className="border-t border-gray-300 my-6"></div>

              {/* Bullet Points */}
              <ul className="list-none space-y-3 text-gray-700 text-lg">
                {section.points.map((point, i) => (
                  <li key={i} className="flex items-start">
                    <span className="w-4 h-4 rounded-full border-2 border-purple-600 bg-pink-100 flex-shrink-0 mt-1"></span>
                    <p className="ml-4 leading-relaxed">{point}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AgricultureCollapsible;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const projects = [
  {
    id: "mla",
    title: "Monitoring Learning Achievement (MLA)",
    description: "Seismic develops custom KPIs and evaluation tools to track and assess student performance.Our MLA services enable schools and institutions to identify learning gaps and refine their educational strategies, ensuring that students reach their full potential. ",
    projectTitle: "Monitoring Learning Achievements (MLA) survey for Anambra Secondary Schools.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493166/mla_apo3mv.png",
  },
  {
    id: "egma",
    title: "Early Grade Mathematics Assessment (EGMA)",
    description: "We offer services in both Early Grade Reading Assessment (EGRA) and Early Grade Mathematics Assessment (EGMA) to enhance foundational literacy and numeracy skills among young learners.",
    projectTitle: "Early Grade Mathematics Assessment for Bauchi and Anambra SEPIP",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738141590/WhatsApp_Image_2025-01-28_at_09.59.19_chf9qn.jpg",
  },
  {
    id: "sdi-surveys",
    title: "Service Delivery Indicator Surveys (SDI)",
    description: "Seismic's SDI surveys gather critical feedback from students, parents, teachers, and administrators to measure the quality of educational services.",
    projectTitle: "Service Delivery Indicators (SDI) Survey for Anambra SEPIP World Bank/SEPIP.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1733493160/edu_reform_x3eqb9.jpg",
  },
  {
    id: "teacher-capacity",
    title: "Capacity Building for Teachers",
    description: "Our training programs upskill educators in modern teaching methodologies and technological tools to ensure pedagogical advancements and student engagement.",
    projectTitle: "Develop capacity enhancement plan for teaching staff in technical colleges supported by the IDEAS project.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "THE WORLD BANK",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738141590/WhatsApp_Image_2025-01-28_at_09.48.10_1_il1d65.jpg",
  },
];

const EducationProjectsExecuted = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  // Auto-cycle through slides
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 5000); // Adjust duration for slide change

    return () => clearInterval(interval);
  }, []);

  const currentProject = projects[currentProjectIndex];

  return (
    <section className="py-16 px-4 lg:px-2">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-800">Education Projects Executed</h2>
      </div>

      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-start gap-8">
        
        {/* Image on the Left */}
        <div className="lg:w-6/12 w-full transition-opacity duration-500 opacity-100">
          <img
            src={currentProject.image}
            alt="Project Image"
            className="w-full h-full object-cover shadow-lg"
            style={{ height: '450px' }}
          />
        </div>

        {/* Project Details on the Right */}
        <div className="lg:w-6/12 w-full flex flex-col">
          <div className="mb-4 pt-4 mt-4">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">{currentProject.title}</h3>
            <p className="text-lg text-gray-600 mb-3">{currentProject.description}</p>
          </div>

          {/* "Project" Label Positioned Above the Card */}
          <div className="text-left mb-2">
            <span className="text-gray-600 font-medium text-lg">Project</span>
          </div>

          {/* Project Details Card with Pink Border */}
          <div 
            className="border border-[#F2E6F2] p-6 relative"
            style={{ borderTopWidth: '8px', height: '200px' }}
          >
            <p className="text-lg font-semibold text-gray-800 mb-2">
              {currentProject.projectTitle}
            </p>
            <div className="flex items-center mb-4">
              <span className="text-gray-600 font-semibold mr-2">Client:</span>
              <img src={currentProject.clientImage} alt={currentProject.clientName} className="h-8" />
            </div>
            <Link
              to={`/consulting-services/education-reform/all-education-projects/${currentProject.id}`}
              className="text-purple-900 font-semibold inline-flex items-center"
            >
              View Achievement
              <svg
                className="w-5 h-5 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Slide Navigation Dots */}
      <div className="flex justify-center items-center gap-3 mt-6">
        {projects.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentProjectIndex(index)}
            className={`transition-all duration-300 ${
              index === currentProjectIndex ? 'bg-purple-800 w-8 h-3 rounded-lg' : 'bg-purple-300 w-4 h-4 rounded-full'
            }`}
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>

      {/* See All Projects Button */}
      <div className="text-center mt-8">
        <Link
          to="/consulting-services/education-reform/all-education-projects"
          className="inline-flex items-center mt-12 px-5 py-3 border border-purple-300 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
        >
          See all Education Projects →
        </Link>
      </div>
    </section>
  );
};

export default EducationProjectsExecuted;

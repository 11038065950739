import React from "react";

const RenewableEnergy = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://images.pexels.com/photos/1468419/pexels-photo-1468419.jpeg?auto=compress&cs=tinysrgb&w=800')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Renewable Energy and Sustainability
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Revolutionizing the way goods and people move, crafting innovative solutions
            that transform operational efficiency, cut costs, and champion environmental
            sustainability.
          </p>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Powering a Low-Carbon Future</h2>
        <p className="text-gray-700 text-lg mb-4">
          Seismic Consulting Group is at the forefront of guiding organizations globally through the
          complex transition to renewable energy and sustainable practices. As a trusted partner to
          energy companies, governments, and multinational organizations, we help pave the way for
          cleaner, more resilient, and economically viable energy systems.
        </p>
        <p className="text-gray-700 text-lg">
          Our comprehensive approach ensures a smooth shift from traditional fossil fuels to innovative,
          renewable solutions that emphasize energy security, environmental responsibility, and long-term
          economic growth.
        </p>
      </div>

{/* Impact Section */}
<div className="py-16 max-w-8xl mx-auto px-6 lg:px-20">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Our Impact: Driving Results Beyond Boundaries
  </h2>
  <p className="text-lg text-gray-600 text-center mb-8 max-w-3xl mx-auto">
    At Seismic Consulting Group, our commitment goes beyond consulting; it transforms industries. Through our renewable energy and sustainability initiatives, we have:
  </p>
  <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 text-center">
    <div>
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
        alt="Reduced Emissions"
        className="w-16 h-16 mx-auto"
      />
      <p className="text-gray-700 font-medium mt-4">
        Reduced clients' environmental impact by enabling measurable decreases in greenhouse gas emissions.
      </p>
    </div>
    <div>
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765626/person_7498074_e8j9wb.png"
        alt="Industry Leaders"
        className="w-16 h-16 mx-auto"
      />
      <p className="text-gray-700 font-medium mt-4">
        Positioned organizations as industry leaders in the global low-carbon economy.
      </p>
    </div>
    <div>
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554837/7993074.png_d9i4tw.png"
        alt="Regulatory Compliance"
        className="w-16 h-16 mx-auto"
      />
      <p className="text-gray-700 font-medium mt-4">
        Ensured regulatory compliance with evolving environmental standards and legislation.
      </p>
    </div>
    <div>
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png"
        alt="Fostered Innovation"
        className="w-16 h-16 mx-auto"
      />
      <p className="text-gray-700 font-medium mt-4">
        Fostered innovation by identifying and leveraging opportunities within the clean energy transition.
      </p>
    </div>
  </div>
</div>


{/* Key Areas of Expertise Section */}
<div className="py-16 px-4 lg:px-20">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Key Areas of Expertise
    </h2>
    <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
      Seismic Consulting's expertise in renewable energy and sustainability ensures economically viable energy systems while fostering innovation and resilience.
    </p>

    {/* Row 1 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 1 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737622978/pexels-photo-1108572_lwqihj.jpg"
          alt="Renewable Energy Integration"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2">
              Renewable Energy Integration
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              This integration is essential for reducing reliance on fossil fuels and minimizing carbon footprints, all while ensuring the stability and efficiency of energy grids.
            </p>
          </div>
          <a
            href="/industries/renewable-energy/renewable-energy-integration"
            className="text-purple-900 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>

      {/* Box 2 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">Energy Efficiency and Conservation</h3>
          <p className="text-gray-600 text-md leading-relaxed">
            As businesses face rising energy costs, stricter regulatory requirements, and growing pressure to minimize their environmental impact, improving energy efficiency is paramount.
          </p>
        </div>
        <a
          href="/industries/renewable-energy/energy-efficiency"
          className="text-purple-900 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>

    {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Sustainable Supply Chain Management
          </h3>
          <p className="text-gray-600 text-md leading-relaxed">
            Sustainability in the supply chain is more than just a trend—it’s a fundamental shift in how businesses operate, source materials, and deliver products to the market.
          </p>
        </div>
        <a
          href="/industries/renewable-energy/sustainable-supply-chain"
          className="text-purple-900 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737623037/pexels-photo-2869610_drufwi.jpg"
          alt="Climate Risk and Resilience Planning"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2">
              Climate Risk and Resilience Planning
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              As the impacts of climate change intensify, organizations face an increasing need to address not just environmental sustainability but also the resilience of their operations, infrastructure, and supply chains.
            </p>
          </div>
          <a
            href="/industries/renewable-energy/climate-resilience"
            className="text-purple-900 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


      {/* The Seismic Advantage Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-4">The Seismic Advantage</h2>
            <p className="text-gray-600 text-lg mb-4">
            Seismic Consulting Group provides a comprehensive suite of climate risk and resilience planning services that help organizations not only manage the risks posed by climate change but also turn these challenges into opportunities for growth and innovation. We equip businesses with the tools,
            knowledge, and strategies needed to anticipate climate impacts, safeguard their operations, and ensure long-term success in an unpredictable world. Through proactive resilience planning, we help organizations create robust, adaptive systems that endure and thrive amidst the complexities of climate change.
            </p>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733513684/pexels-andreea-ch-371539-1166643_wvlv96.jpg"
              alt="Why Choose Seismic Consulting Group"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default RenewableEnergy;

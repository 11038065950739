import React from 'react';

const IVAInsight = () => {
  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-20 py-16">
      {/* Hero Section */}
      <section
        className="relative w-full h-[500px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1200x500')", // Replace with actual image
        }}
      >
        <div className="text-center">
          <h1 className="text-4xl font-bold">Independent Verification Agents (IVA)</h1>
          <p className="text-lg mt-4">Bridging the Gap Between Goals and Outcomes</p>
        </div>
      </section>

      {/* Introduction */}
      <div className="mt-12">
        <h2 className="text-3xl font-bold text-gray-900">
          Independent Verification Agents (IVA): Bridging the Gap Between Goals and Outcomes
        </h2>
        <p className="text-gray-700 text-xl mt-4">
          In today’s dynamic business environment, where competition is fierce and stakeholders
          demand measurable outcomes, the principles of transparency, accountability, and efficiency
          have evolved from mere ideals to critical prerequisites for success. Businesses, consultancies,
          governments, and development organizations are under increasing pressure to demonstrate
          that resources are utilized effectively, objectives are met, and results are delivered with
          integrity. In this context, IVAs have emerged as a cornerstone of project oversight and
          evaluation.
        </p>
        <p className="text-gray-700 text-xl mt-4">
        IVAs are impartial third-party entities with a singular focus: ensuring that projects are
implemented in accordance with predefined objectives, contractual obligations, and quality
standards. This impartiality ensures evaluations are free from bias and assessments are both
objective and credible. By serving as an independent check-and-balance mechanism, IVAs
enhance trust among stakeholders, including funders, implementers, a
        </p>
      </div>

      {/* Quote Section */}
      <div className="my-12 bg-purple-100 p-8 rounded-lg shadow-md">
        <blockquote className="italic text-lg text-purple-900 border-l-4 border-purple-800 pl-4">
          “The single most important ingredient in the recipe for success is transparency because 
          transparency builds trust.” <br /> – Denise Morrison
        </blockquote>
      </div>

      {/* Challenges and Importance */}
      <div className="mt-12">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Challenges Without Proper Oversight</h3>
        <p className="text-gray-700 text-xl">
          The absence of robust audit and transparency mechanisms often leads to severe
          consequences for businesses and economies. Without adequate oversight, organizations are
          vulnerable to inefficiencies, fraud, and mismanagement. A lack of transparency erodes
          stakeholder trust, resulting in reputational damage and loss of investor confidence, which can
          lead to declining market value, reduced access to capital, and, in extreme cases, the collapse
          of enterprises.
        </p>
        <p className="text-gray-700 mt-4 text-xl">
          High-profile financial scandals like Enron and Wirecard serve as stark reminders of the
          devastation caused by unchecked operations. At a macroeconomic level, the absence of 
          transparency and audit mechanisms can hinder sustainable development. Governments and 
          donor-funded initiatives lose billions of dollars annually to corruption, resource misallocation, 
          and a lack of accountability.
        </p>
        <p className="text-gray-700 mt-4 text-xl">
        At a macroeconomic level, the absence of transparency and audit mechanisms can hinder
sustainable development. Governments and donor-funded initiatives lose billions of dollars
annually to corruption, resource misallocation, and a lack of accountability. When public funds
are siphoned away or misused, critical sectors such as healthcare, education, and
infrastructure suffer, directly affecting citizens' quality of life and economic progress.
        </p>
      </div>

      {/* Image Section */}
      <div className="my-12">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737990706/pexels-rdne-7821677_e32i3y.jpg"
          alt="Challenges Without Oversight"
          className="w-full rounded-lg shadow-lg"
        />
      </div>

      {/* IVAs Contribution */}
      <div className="mt-12">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">How IVAs Bridge the Gap</h3>
        <p className="text-gray-700 text-xl">
          IVAs provide a critical solution to these challenges by bridging the gap created by a lack of
          transparency and accountability. Acting as impartial third parties, their role goes beyond mere
          compliance checks. IVAs dive into the operational realities of projects, verifying adherence to
          timelines, budgets, and expected outcomes.
        </p>
        <p className="text-gray-700 mt-4 text-xl">
          They identify gaps between planned and actual performance, assess root causes of inefficiencies, 
          and offer actionable insights to address challenges before they escalate. This mitigates risks 
          and fosters continuous improvement, steering projects toward more effective and sustainable results.
        </p>
        <p className="text-gray-700 mt-4 text-xl">
        The role of IVAs is particularly impactful in high-stakes environments, such as government-
funded initiatives, donor-backed programs, and private sector investments. In these scenarios,
where significant financial resources and public trust are at stake, IVAs ensure that
transparency is not just a buzzword but a practiced reality. By holding project implementers
accountable and fostering open communication, IVAs help create a culture of integrity and
responsibility.
        </p>
      </div>

      {/* Seismic Consulting's Approach */}
      <div className="mt-12">
        <h2 className="text-3xl font-bold text-gray-900">
          Seismic Consulting Group's Approach
        </h2>
        <p className="text-gray-700 text-xl mt-4 ">
          Seismic Consulting Group stands at the intersection of consultancy and independent verification, 
          leveraging its expertise to deliver projects that uphold transparency, accountability, and measurable 
          outcomes. As an IVA, Seismic Consulting provides independent oversight that builds trust and ensures credibility.
        </p>
        <p className="text-gray-700 text-xl mt-4">
        The firm’s approach to verification extends beyond compliance checks to include proactive
risk mitigation, real-time reporting, and actionable insights. At the core of Seismic’s IVA
methodology is a commitment to evaluating whether projects align with their stated
objectives. Through comprehensive assessments, Seismic scrutinizes every stage of a project
to ensure that activities, deliverables, and outcomes are consistent with the original plan. This
process involves examining key performance indicators (KPIs), timelines, budgets, and
resource allocation to identify deviations and recommend corrective actions.
        </p>
      </div>

      {/* Conclusion */}
      <div className="mt-12 bg-gray-100 p-8 rounded-lg">
        <h2 className="text-2xl font-bold text-gray-900">Conclusion</h2>
        <p className="text-gray-700 text-xl mt-4">
          The impartial evaluations, actionable insights, and oversight provided by IVAs create a
          foundation of trust that benefits stakeholders at every level. Organizations like Seismic
          Consulting exemplify excellence in IVA services by combining technical expertise with
          innovative methodologies to ensure that projects achieve measurable and sustainable
          outcomes.
        </p>
        <p className="text-gray-700 text-xl mt-4">
          As global challenges evolve, the value of IVAs will only grow, providing the essential oversight 
          needed to bridge the gap between ambition and achievement. With IVAs at the helm, stakeholders 
          can be confident that their investments, efforts, and goals are protected and positioned for success.
        </p>
      </div>

      {/* Author Section */}
      <div className="mt-16 text-center">
        <h3 className="text-xl font-bold text-gray-900">Cletus Ochelebe</h3>
        <p className="text-gray-700">Research Team Lead, Seismic Consulting Group</p>
      </div>

    </div>
  );
};

export default IVAInsight;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";

function DigitalTransformationPage() {
  // Data for the approach tabs
  const approachTabs = [
    {
      title: "Strategic Digital Blueprint",
      content: `Every transformation journey starts with a blueprint. Seismic partners with 
        clients to develop an agile, forward-looking strategy that lays out each step 
        of the digital transformation. This involves a comprehensive assessment of 
        existing systems, processes, and goals to create a roadmap that anticipates 
        future demands. For instance, by adopting cloud-based solutions and digital 
        automation, we help clients enhance scalability while reducing operational costs.`,
    },
    {
      title: "End-to-End Integration",
      content: `We believe true transformation happens when digital solutions are woven into the fabric of the
organization. Our team integrates technology across departments, ensuring that new tools are
adopted effectively, employees are empowered, and processes are optimized. Whether
deploying machine learning algorithms for predictive analysis in supply chain management or
integrating CRM systems with data analytics for customer insights, our approach embeds
technology where it delivers maximum value.`,
    },
    {
      title: "Artificial Intelligence and Machine Learning Applications",
      content: `Our team harnesses the power of AI and ML to unlock new opportunities 
        for predictive analytics, personalized customer experiences, and process 
        optimization. By leveraging these technologies, we help clients gain 
        competitive advantages and generate measurable ROI.`,
    },
    {
      title: "Efficient Delivery and Agile Execution",
      content: `In the execution phase, we focus on agile methodologies to deliver solutions on time, at scale,
and with flexibility. Seismic’s expert project management ensures that every phase—from testing
to deployment—is streamlined, minimizing disruptions and maximizing results. For example,
when implementing AI-driven analytics, we create scalable, modular systems that adapt as your
business grows, ensuring long-term efficiency and quick adaptation to new market demands.`,
    },
  ];

  // Data for sectors grid (interactive cards)
  const sectors = [
    {
      title: "Healthcare Transformation",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738919646/Vector_glrlmz.png",
      description:
        "We drive innovation in healthcare by introducing cutting-edge solutions that streamline operations, enhance patient care, and improve accessibility, ensuring that every health institution is agile and equipped for future challenges.",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalHealthcare}`,
    },
    {
      title: "Educational Empowerment",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738919844/book-open-cover_7720118_1_y4ezaw.png",
      description:
        "Seismic brings digital innovation to the education sector, building robust platforms that enhance learning experiences, streamline administration, and enable institutions to stay competitive and relevant in the digital landscape.",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalEducation}`,
    },
    {
      title: "Financial Services Modernization",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738919887/Vector_1_eqzdev.png",
      description:
        "In the financial sector, we develop secure, data-driven solutions that increase efficiency, enhance customer experiences, and adapt swiftly to regulatory changes, ensuring institutions are both compliant and competitive.",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalFinancialServices}`,
    },
    {
      title: "Public Sector Advancement",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738919927/population-globe_17484627_1_vmpzra.png",
      description:
        "From local government to federal agencies, Seismic drives digital transformation that optimizes public services, improves transparency, and strengthens citizen engagement—creating government services that are as efficient as they are accessible.",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.publicSector}`,
    },
    {
      title: "Agricultural Revolution",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738919946/plant-care_15864922_1_kkytsr.png",
      description:
        "In agriculture, we’re pioneering digital solutions that maximize productivity, optimize supply chains, and support sustainable practices—enabling agricultural businesses to thrive in an increasingly digital world.",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalAgriculture}`,
    },
    {
      title: "Manufacturing Evolution",
      icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738919938/industry-alt_10801872_1_rnw6tf.png",
      description:
        "Our transformation strategies in manufacturing involve automating processes, enhancing operational efficiency, and creating agile systems that respond to market demands, leading to smarter, leaner production lines.",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.manufacturingEvolution}`,
    },
  ];

  // Data for expertise cards
  const expertiseCards = [
    {
      title: "Cloud and IT Infrastructure Transformation",
      description:
        "In the rapidly evolving digital landscape, a robust and adaptable IT infrastructure is essential for organizations aiming to innovate and stay ahead of the competition. We specialize in transforming your cloud and IT infrastructure to enhance scalability.",
      image:
        "https://res.cloudinary.com/dxhx45ing/image/upload/v1739191990/Image_4_ogfiug.png",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.cloudInfrastructurePage}`,
    },
    {
      title: "Artificial Intelligence and Machine Learning Applications",
      description:
        "Seismic leverages the power of AI and ML to unlock new opportunities for predictive analytics, personalized customer experiences, and process optimization. By integrating these technologies, we help businesses gain competitive advantages and achieve measurable ROI.",
      image:
        "https://res.cloudinary.com/dxhx45ing/image/upload/v1739192045/pexels-photo-2599244_eczs92.jpg",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.aIAndML}`,
    },
    {
      title: "Blockchain and Distributed Ledger Technology",
      description:
        "From initial assessment and strategy development to implementation and ongoing support, we provide end-to-end services that transform operations and deliver measurable, sustainable results.",
      image:
        "https://res.cloudinary.com/dxhx45ing/image/upload/v1739191921/project_Image_xeq4id.png",
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.blockchainTechnologyPage}`,
    },
  ];

  // State to manage selected tab in the desktop Digital Transformation Approach section
  const [selectedTab, setSelectedTab] = useState(0);
  // State for mobile accordion; creates an array of booleans (one per tab) initially set to false
  const [mobileAccordion, setMobileAccordion] = useState(approachTabs.map(() => false));

  // Toggle mobile accordion for a given index
  const toggleMobileAccordion = (index) => {
    setMobileAccordion((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // State to manage which sector card is expanded (if needed)
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleCard = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-12"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739192813/structure-light-led-movement-158826_swia20.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-20">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white">
            Digital Transformation
          </h1>
          <p className="text-base lg:text-lg text-white mt-4">
            Empowering businesses to grow and succeed by transforming their
            operations with innovative and future-ready digital solutions.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-12 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Digital Transformation
        </h1>
      </div>

      {/* Main Content Section */}
      <section className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-purple-900">
            Transforming Businesses with Innovative Digital Solutions
          </h2>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            In today’s world, digital transformation isn’t merely an option—it’s
            essential. You must have a bold vision and keys to open new pathways
            and uncover strategic possibilities that once seemed unimaginable.
            Seismic holds the vision and keys! Step into a future where
            technology is more than just a tool but a catalyst that propels your
            organization into boundless potential.
          </p>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            At Seismic Consulting Group, we don’t just spark digital change; we
            ignite a revolution that redefines how you do business, positioning
            you firmly at the forefront of industry leadership.
          </p>
          <p className="text-gray-700 mb-6 leading-relaxed text-lg">
            Seismic approaches digital transformation not as a mere upgrade but
            as a bold reawakening of what your organization can achieve. Imagine
            every corner of your organization, every process, and every
            interaction re-engineered, re-envisioned, and revitalized—creating a
            business that thinks, adapts, and grows smarter than ever before.
            Our expertise spans industries, empowering clients across the{" "}
            <strong>
              healthcare, education, financial services, public sector,
              agriculture, and manufacturing
            </strong>{" "}
            sectors. We work collaboratively to develop custom strategies that
            ensure technology is not just embraced but leveraged to deliver
            measurable outcomes and sustained success, leading with innovation.
            This is where your tomorrow begins—today.
          </p>
        </div>
      </section>

      {/* Secondary / Emphasis Section */}
      <section className="bg-gray-50 py-8">
        <div className="max-w-6xl mx-auto px-4 flex flex-col lg:flex-row lg:items-center lg:space-x-8">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <blockquote className="text-gray-800 text-lg leading-relaxed border-l-4 border-purple-900 pl-4 italic">
              Digital transformation is not a one-time project but an ongoing
              journey that keeps organizations competitive, adaptable to market
              disruptions, and poised to uncover new growth opportunities. It
              requires a strategic approach that seamlessly aligns technology
              initiatives with business goals, fosters employee engagement, and
              delivers measurable outcomes. At Seismic Consulting Group, we
              understand the intricacies of this journey and partner with our
              clients to ensure their transformation not only meets immediate
              objectives but also sets the foundation for sustained success.
            </blockquote>
          </div>
          <div className="lg:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733494510/techroom_lyp9zb.jpg"
              alt="Digital Transformation Example"
              className="w-full h-auto object-cover rounded shadow"
            />
          </div>
        </div>
      </section>

      {/* Our Digital Transformation Approach Section */}
      <section className="py-12 bg-white">
        <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-16">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our Digital Transformation Approach
            </h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              At Seismic Consulting Group, our digital transformation strategy
              is tailored to unlock the full potential of your business.
              Discover how our innovative, end-to-end solutions drive growth and
              future-proof your organization.
            </p>
          </div>

          {/* Desktop Version */}
          <div className="hidden lg:flex flex-col lg:flex-row">
            <div className="lg:w-1/3 w-full lg:pr-8">
              <div className="bg-[#F9F5FB] p-8 space-y-4">
                {approachTabs.map((tab, index) => (
                  <div key={tab.title}>
                    <button
                      onClick={() => setSelectedTab(index)}
                      className={`text-left py-6 px-4 font-semibold text-xl w-full ${
                        selectedTab === index
                          ? "bg-gradient-to-b from-[#2D002D] to-[#600060] text-white"
                          : "text-gray-800 hover:bg-purple-100"
                      } transition`}
                    >
                      {tab.title}
                    </button>
                    {index < approachTabs.length - 1 && (
                      <div className="border-t border-purple-200 w-full"></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="lg:w-2/3 w-full mt-16">
              <div className="p-8 bg-white shadow-md rounded-md">
                <h2 className="text-3xl font-bold mb-4">
                  {approachTabs[selectedTab].title}
                </h2>
                <p className="text-gray-700 text-xl leading-relaxed">
                  {approachTabs[selectedTab].content}
                </p>
              </div>
            </div>
          </div>

          {/* Mobile Version with Similar Design */}
          <div className="block lg:hidden">
            <div className="bg-[#F9F5FB] p-8 space-y-4">
              {approachTabs.map((tab, index) => (
                <div key={index} className="border-t border-purple-200">
                  <button
                    onClick={() => toggleMobileAccordion(index)}
                    className={`flex justify-between items-center w-full py-6 px-4 font-semibold text-xl transition ${
                      mobileAccordion[index]
                        ? "bg-gradient-to-b from-[#2D002D] to-[#600060] text-white"
                        : "text-gray-800 hover:bg-purple-100"
                    }`}
                  >
                    <span>{tab.title}</span>
                    <span className="text-2xl">
                      {mobileAccordion[index] ? "-" : "+"}
                    </span>
                  </button>
                  {mobileAccordion[index] && (
                    <div className="px-4 pb-6 bg-white">
                      <p className="text-gray-700 text-xl leading-relaxed">
                        {tab.content}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Our Expertise in Digital Transformation Section */}
      <section className="py-12 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-2">
            Our Expertise in Digital Transformation
          </h2>
          <p className="text-center text-gray-600 max-w-3xl mx-auto mb-8">
            Enabling businesses achieve growth and efficiency through digital
            transformation solutions.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {expertiseCards.map((card, idx) => (
              <div
                key={idx}
                className="bg-white rounded shadow-sm p-4 flex flex-col"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="mb-4 h-40 w-full object-cover rounded"
                />
                <h3 className="text-lg font-semibold mb-2 text-gray-800">
                  {card.title}
                </h3>
                <p className="text-sm text-gray-600 flex-1">
                  {card.description}
                </p>
                <div className="mt-4">
                  <a
                    href={card.link}
                    className="text-purple-800 font-medium text-sm flex items-center hover:text-purple-600 transition"
                  >
                    Read More
                    <svg
                      className="w-4 h-4 ml-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <Link
              to={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}`}
              className="inline-flex items-center px-5 py-3 border border-purple-800 text-purple-800 font-semibold rounded-lg hover:bg-purple-50 transition"
            >
              See all Services
              <svg
                className="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          </div>
        </div>
      </section>

      {/* Sectors Grid Section */}
      <section className="py-12 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-8">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
              Driving Unstoppable Transformation Across Sectors
            </h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              Seismic’s projects transcends the conventional. We combine
              breakthrough technologies with razor-sharp strategies, guiding our
              clients through a transformation journey that is as unstoppable as
              it is transformative. This journey is about dominating the digital
              age, setting new standards, and leading with innovation. With
              Seismic, transformation isn’t about today it’s about setting the
              stage for a future-ready organization that continuously adapts to
              market shifts and industry disruptions.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {sectors.map((sector, idx) => (
              <Link to={sector.link} key={idx}>
                <div
                  className="relative min-h-[300px] bg-gradient-to-b from-[#2D002D] to-[#600060]
                           text-white p-6 rounded overflow-hidden group transition"
                >
                  <div className="flex flex-col items-center justify-center h-full">
                    <img
                      src={sector.icon}
                      alt={sector.title}
                      className="w-20 h-20 object-contain mt-16"
                    />
                    <h3 className="text-xl font-bold text-center mt-4">
                      {sector.title}
                    </h3>
                  </div>
                  <div
                    className="absolute inset-0 bg-pink-100 bg-opacity-90 flex flex-col items-center justify-center text-center 
                             p-4 opacity-0 group-hover:opacity-100 transition duration-300"
                  >
                    <h3 className="text-gray-800 text-xl font-bold mb-2">
                      {sector.title}
                    </h3>
                    <p className="text-gray-800 text-sm sm:text-base leading-relaxed">
                      {sector.description}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Digital Transformation Experts Section */}
      <div className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
          Our Digital Transformation Experts
        </h2>
        <div className="flex justify-center gap-16 flex-wrap">
          {[
            {
              name: "John Doe",
              role: "Board Member, Expert Digital Transformation & Innovation",
              imageUrl: "https://via.placeholder.com/300x300?text=Misha+Pratap",
            },
            {
              name: "Jibrin Haruna",
              role: "Team Lead, Digital Transformation & Innovation",
              imageUrl:
                "https://res.cloudinary.com/dxhx45ing/image/upload/v1739452509/WhatsApp_Image_2025-02-13_at_14.14.24_ylnt3w.jpg",
            },
          ].map((expert, index) => (
            <div
              key={index}
              className="shadow-lg rounded-lg overflow-hidden text-left bg-white w-[280px]"
            >
              <img
                src={expert.imageUrl}
                alt={expert.name}
                className="w-full h-[300px] object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-gray-900">
                  {expert.name}
                </h3>
                <p className="text-gray-600 mt-1">{expert.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DigitalTransformationPage;

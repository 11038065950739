import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";

export default function HealthcareConsultingPage() {
  return (
    <div className="mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1740652101/pexels-photo-2383010_xj5qbb.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-10">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Healthcare &amp; Life Sciences
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Innovating healthcare delivery through strategy, technology, and
            patient-centric solutions.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 text-center">
        <h1 className="text-2xl font-bold text-gray-800">
          Healthcare &amp; Life Sciences
        </h1>
      </div>

      {/* Introduction */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
          Transforming Healthcare Through Strategic Insights &amp; Innovation
        </h2>
        <p className="text-lg text-gray-700 mb-4">
          Rapidly evolving market dynamics, regulatory forces, and consumer
          expectations are adding new layers of complexity to the healthcare
          industry. Our consulting expertise spans the entire healthcare
          ecosystem—from providers and payers to pharmaceutical and medical
          technology companies—ensuring we deliver tailored strategies that
          address each stakeholder's unique challenges.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Whether implementing advanced Hospital ERP systems, AI-driven mental
          health diagnostic platforms, or data-driven care models, we combine
          cross-disciplinary insights with cutting-edge technology to help you
          reduce costs, optimize digital solutions, and create sustainable value.
          Our goal is simple: empower you to deliver exceptional patient outcomes
          while staying competitive in a rapidly shifting landscape.
        </p>
      </div>


{/* Digital Projects Introduction */}
<div className="max-w-6xl mx-auto px-6 lg:px-12 mt-20">
  <div className="flex flex-col md:flex-row items-center gap-8">
    {/* Left Column: Image */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739800247/pexels-photo-5452300_klxp7s.jpg"
        alt="Digital Projects"
        className="w-full rounded-lg shadow-md"
      />
    </div>
    {/* Right Column: Text Content */}
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        Revolutionizing Healthcare with Technology
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        Our suite of digital projects is at the forefront of transforming healthcare delivery. From state-of-the-art Hospital ERP systems to AI-powered mental health diagnostics, our innovative solutions empower organizations to optimize operations, enhance patient care, and drive sustainable growth.
      </p>
      <Link
    to={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalHealthcare}`} 
        className="inline-flex items-center px-6 py-3 border border-purple-900 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
      >
        View Digital Projects
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  </div>
</div>


      {/* Existing "Why Choose Us", "Core Services", and "Results & Outcomes" sections follow... */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        <h3 className="text-2xl font-bold text-gray-900 text-center mb-8">
          Why Partner with Seismic Consulting?
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          <div>
            <div className="flex justify-center mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736942834/learning_7243265_exhpfw.png"
                alt="Depth of Expertise Icon"
                className="w-14 h-14"
              />
            </div>
            <h4 className="text-xl font-semibold text-purple-900 mb-2">
              Depth of Expertise
            </h4>
            <p className="text-gray-600">
              Our consultants bring global experience in clinical operations,
              healthcare IT, and life sciences—enabling us to meet the
              multi-faceted needs of modern healthcare organizations.
            </p>
          </div>
          <div>
            <div className="flex justify-center mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765626/person_7498074_e8j9wb.png"
                alt="Data-Driven Innovation Icon"
                className="w-14 h-14"
              />
            </div>
            <h4 className="text-xl font-semibold text-purple-900 mb-2">
              Data-Driven Innovation
            </h4>
            <p className="text-gray-600">
              We harness advanced analytics and AI to optimize care delivery,
              streamline administrative tasks, and enhance the overall patient
              journey.
            </p>
          </div>
          <div>
            <div className="flex justify-center mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
                alt="Patient-Centric Strategies Icon"
                className="w-14 h-14"
              />
            </div>
            <h4 className="text-xl font-semibold text-purple-900 mb-2">
              Patient-Centric Strategies
            </h4>
            <p className="text-gray-600">
              We align digital tools and consulting frameworks around patient
              experiences—ensuring positive outcomes, increased engagement, and
              long-term loyalty.
            </p>
          </div>
        </div>
      </div>

      <div className="py-16 px-4 lg:px-20">
        <div className="max-w-[1200px] mx-auto">
          <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
            Core Healthcare Consulting Services
          </h2>
          <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
            From hospital ERPs to AI-driven diagnostics, our healthcare offerings
            are designed to address complex challenges with strategic, technology-focused solutions.
          </p>

          {/* Service Cards */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="border border-gray-200 p-6 shadow-md">
              <h3 className="text-lg font-bold mb-2 text-purple-900">
                Hospital ERP Implementation
              </h3>
              <p className="text-gray-600 text-md leading-relaxed mb-4">
                Streamline hospital operations—from patient registration and
                billing to inventory management—through integrated ERP solutions
                that increase efficiency and reduce costs.
              </p>

            </div>

            <div className="border border-gray-200 p-6 shadow-md">
              <h3 className="text-lg font-bold mb-2 text-purple-900">
                Mental Health &amp; Neuro Diagnostics
              </h3>
              <p className="text-gray-600 text-md leading-relaxed mb-4">
                Deploy AI-powered platforms that help diagnose various brain and
                mental disorders. Enhance clinical accuracy, reduce diagnosis
                times, and empower healthcare providers with data-driven insights.
              </p>
            </div>

            <div className="border border-gray-200 p-6 shadow-md">
              <h3 className="text-lg font-bold mb-2 text-purple-900">
                Healthcare Strategy &amp; Compliance
              </h3>
              <p className="text-gray-600 text-md leading-relaxed mb-4">
                Navigate complex regulatory requirements and streamline healthcare
                delivery models. Our experts help you stay compliant while
                evolving your organization’s strategic roadmap.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Results & Outcomes */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
          {/* Text Section */}
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-medium mb-4">Real-World Impact</h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
              Our holistic approach to healthcare consulting goes beyond
              short-term fixes. We aim for sustainable transformations that
              improve patient outcomes, reduce administrative overhead, and drive
              better financial performance across the value chain.
            </p>
            <p className="text-gray-600 text-lg leading-relaxed">
              By integrating cutting-edge technologies like generative AI,
              advanced analytics, and patient-centric design, we empower you to
              respond effectively to evolving market demands and regulatory
              changes. The result is a more agile, responsive, and innovative
              organization—positioned for long-term growth in a rapidly changing
              healthcare landscape.
            </p>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739182785/pexels-photo-7580259_jeaycc.jpg"
              alt="Healthcare Consulting Impact"
              className="w-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

import React, { useState } from "react";

const DigitalPlatforms = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const expertiseData = [
    {
      title: "Achieve Real-Time Visibility",
      description:
        "Visibility is critical for ensuring efficient and transparent logistics operations. Our digital platforms provide businesses with tools to monitor the movement of goods, vehicles, and inventory in real time.",
      points: [
        "End-to-End Tracking: Advanced GPS technology, RFID systems, and IoT-enabled devices offer instant updates on the location and condition of goods throughout the supply chain.",
        "Dynamic Updates: Real-time data allows logistics managers to anticipate and address potential disruptions, such as delays, weather events, or mechanical issues.",
        "Enhanced Transparency: Stakeholders, from suppliers to customers, gain access to accurate, timely information, fostering trust and accountability.",
      ],
    },
    {
      title: "Streamline Route Planning",
      description:
        "Efficient routing is essential for reducing delivery times, lowering costs, and enhancing customer satisfaction. We integrate cutting-edge technologies into digital platforms to streamline route planning and execution:",
      points: [
        "Automated Route Optimization: AI-powered algorithms analyze traffic patterns, delivery priorities, and fuel efficiency to determine the most effective routes.",
        "Dynamic Rerouting: Real-time adjustments ensure deliveries remain on schedule despite unexpected disruptions, such as road closures or accidents.",
        "Cost and Environmental Impact Reduction: Optimized routes reduce fuel consumption and emissions, supporting sustainability goals while lowering operational expenses.",
      ],
    },
    {
      title: "Integrate End-to-End Processes",
      description:
        "Logistics often involves managing disparate systems and coordinating multiple stakeholders, which can create inefficiencies and miscommunication. Our centralized digital platforms connect every aspect of the logistics process, ensuring seamless integration:",
      points: [
        "Unified Systems: Bring together inventory management, transportation scheduling, warehouse operations, and customer service on a single platform.",
        "Collaborative Tools: Facilitate real-time communication between suppliers, carriers, and customers, ensuring alignment and reducing delays.",
        "Data Consolidation and Analytics: Aggregate data from all touchpoints to provide actionable insights for improving performance and identifying opportunities.",
      ],
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739186724/pexels-photo-2962069_xxblfc.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Digital Platforms for Logistics
          </h1>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Transforming Logistics with Cutting-Edge Digitization
        </h2>
        <p className="text-gray-700 text-lg mb-4">
          In today’s fast-paced and interconnected global economy, the logistics sector faces immense
          pressure to optimize operations, reduce costs, and meet rising customer expectations. At
          Seismic Consulting Group, we enable businesses to thrive in this dynamic environment by designing
          and implementing advanced digital platforms that revolutionize logistics processes. From real-time
          tracking to automated route optimization, our solutions empower organizations to operate with
          greater efficiency, transparency, and agility.
        </p>
      </div>

      {/* Expertise Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Key Areas of Expertise
        </h2>
        {expertiseData.map((item, index) => (
          <div key={index} className="mb-12 border-b border-pink-100 pb-8">
            <button
              onClick={() => toggleSection(index)}
              className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
            >
              {item.title}
              <span className="text-purple-900 text-3xl">
                {openSection === index ? "-" : "+"}
              </span>
            </button>
            {openSection === index && (
              <div>
                <p className="text-gray-700 text-lg mb-4">{item.description}</p>
                {item.points && (
                  <ul className="list-none text-gray-700 text-lg space-y-4 mt-4 px-6 lg:px-32">
                    {item.points.map((point, idx) => (
                      <li key={idx} className="relative pl-6">
                        <span
                          className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 border-purple-900 rounded-full"
                        ></span>
                        {point}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

{/* Benefits Section */}
<div className="py-16 max-w-8xl mx-auto px-6 lg:px-20">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Benefits of Digital Transformation in Logistics
  </h2>
  <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 text-center">
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546264/7498046.png_ejk71i.png"
          alt="Increased Efficiency"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Increased Efficiency</h3>
      <p className="text-gray-700 mt-2">
        Automating manual processes and optimizing resource allocation save time and reduce labor costs.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546776/7497716.png_lmqppk.png"
          alt="Enhanced Accuracy"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Enhanced Accuracy</h3>
      <p className="text-gray-700 mt-2">
        Digital tools reduce the likelihood of errors, ensuring orders are fulfilled correctly and on time.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png"
          alt="Improved Agility"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Improved Agility</h3>
      <p className="text-gray-700 mt-2">
        Real-time data and dynamic systems allow businesses to respond quickly to changing conditions and market demands.
      </p>
    </div>
    <div>
      <div className="w-16 h-16 mx-auto rounded-full flex items-center justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737559161/7243275.png_jylo3i.png"
          alt="Scalability"
          className="w-full h-full object-contain"
        />
      </div>
      <h3 className="text-xl font-bold mt-4">Scalability</h3>
      <p className="text-gray-700 mt-2">
        Digital platforms grow alongside businesses, accommodating increased demand and complexity without compromising performance.
      </p>
    </div>
  </div>
</div>

      {/* Why Seismic Section */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
          {/* Text Section */}
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-semibold mb-4">Driving the Future of Logistics with Digital Innovation</h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
            The logistics landscape is evolving rapidly, and digitization is no longer optional, as it’s a competitive
necessity. At Seismic Consulting Group, we deliver robust digital platforms tailored to the unique needs of
each business, equipping them with the tools to succeed in an increasingly complex and fast-paced
environment.
            </p>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
            Our solutions enable businesses to achieve real-time visibility, streamline operations, and foster
collaboration, ensuring they remain agile and resilient in the face of ever-changing market dynamics. With
our expertise, clients can unlock new efficiencies, deliver unparalleled customer experiences, and position
themselves as leaders in the digital logistics revolution.
            </p>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737549897/pexels-photo-6169870_gw6aai.jpg"
              alt="Urban Mobility Solutions"
              className="w-full"
            />
          </div>
        </div>
      </section>

      {/* Impact Beyond Boundaries Section */}
<div className="py-16 max-w-8xl mx-auto px-6 lg:px-20">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Impact Beyond Boundaries
  </h2>
  <p className="text-lg text-gray-600 text-center mb-8 max-w-3xl mx-auto">
    Our work in transportation and logistics drives measurable results that extend far beyond operational improvements. By partnering with Seismic Consulting Group, organizations achieve:
  </p>
  <ul className="list-disc list-inside text-lg text-gray-700 space-y-4 max-w-3xl mx-auto">
    <li>
      <strong>Operational Excellence:</strong> Streamlined processes, reduced inefficiencies, and enhanced reliability in logistics operations.
    </li>
    <li>
      <strong>Cost Savings:</strong> Lower transportation, storage, and delivery costs through optimized systems and sustainable practices.
    </li>
    <li>
      <strong>Environmental Leadership:</strong> Reduced carbon footprints and alignment with global sustainability goals, positioning businesses as leaders in green innovation.
    </li>
    <li>
      <strong>Resilience in a Dynamic Market:</strong> The agility to adapt to shifting consumer demands, regulatory changes, and technological advancements.
    </li>
  </ul>
</div>

    </div>
  );
};

export default DigitalPlatforms;

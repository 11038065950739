import React from "react";

const DigitalBusinessModelPage = () => {
  const sections = [
    {
      title: "E-commerce and Digital Marketplaces",
      description: `Seismic specializes in enabling businesses to establish or expand their online presence through robust 
      e-commerce solutions and digital marketplaces. We assist clients in designing seamless, user-friendly 
      platforms that enhance customer engagement and streamline transactions.`,
      image: "https://via.placeholder.com/600x300",
      insights: [
        {
          title: "Strategic Planning",
          content:
            "We work with you to define clear objectives, identify target markets, and develop a comprehensive roadmap for your online venture.",
        },
        {
          title: "Platform Development",
          content:
            "Our team builds scalable and secure e-commerce platforms tailored to your specific needs, integrating features like shopping carts, payment gateways, and inventory management systems.",
        },
        {
          title: "Customer Experience Optimization",
          content:
            "We focus on creating intuitive user interfaces and personalized experiences that increase customer satisfaction and loyalty.",
        },
        {
          title: "Data Analytics Integration",
          content:
            "By incorporating analytics tools, we help you gain insights into customer behavior, enabling data-driven decisions to refine your offerings and marketing strategies.",
        },
      ],
    },
    {
      title: "Subscription and As-a-Service Models",
      description: `We developed an e-commerce platform that expanded their reach to a global audience. By integrating advanced search functionalities and personalized recommendations, the client experienced a 50% increase in online sales within the first year. `,
      image: "https://via.placeholder.com/600x300",
      insights: [
        {
          title: "Business Model Design",
          content:
            "We help you conceptualize and structure subscription offerings that align with your value proposition and customer needs.",
        },
        {
          title: "Pricing Strategy Development",
          content:
            "Our experts analyze market trends and customer willingness to pay to craft competitive and profitable pricing models.",
        },
        {
          title: "Technology Implementation",
          content:
            "We deploy the necessary infrastructure to support subscription services, including billing automation, user dashboards, and service delivery platforms.",
        },
        {
          title: "Change Management",
          content:
            "Seismic provides training and support to ensure your team is equipped to operate and promote the new business model effectively.",
        },
      ],
    },
    {
      title: "Platform Business Models",
      description: `Seismic Consulting Group helps clients design and launch platform-based business models that connect 
      consumers with providers, creating value through network effects.`,
      image: "https://via.placeholder.com/600x300",
      insights: [
        {
          title: "Platform Strategy Development",
          content:
            "We define the platform's value proposition, identify key user groups, and establish mechanisms for network growth and engagement.",
        },
        {
          title: "Ecosystem Design",
          content:
            "Our team designs the architecture that facilitates interactions between stakeholders, including APIs, user interfaces, and transaction processes.",
        },
        {
          title: "Technology Enablement",
          content:
            "We implement scalable technologies that support platform operations, such as cloud services, database management, and security protocols.",
        },
        {
          title: "Growth and Monetization Tactics",
          content:
            "Seismic advises on strategies to attract users, foster community interactions, and monetize platform activities effectively.",
        },
      ],
    },
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] bg-gray-800 text-white flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1400x600')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-20">
            Digital Business Model Innovation
          </h1>
        </div>
      </section>

      {/* Content Section */}
      <div className="py-12 max-w-6xl mx-auto px-6">
        <p className="text-lg text-gray-600 text-center max-w-3xl mx-auto mb-12">
          At Seismic Consulting Group, we understand that digital transformation is not just about integrating 
          new technologies—it’s about reimagining how businesses deliver value in a digital world. We empower 
          organizations to adapt to changing market dynamics, unlock new revenue streams, and meet evolving 
          customer expectations.
        </p>

        {sections.map((section, index) => (
          <div key={index} className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              {section.title}
            </h2>
            <p className="text-lg text-gray-700 mb-6">{section.description}</p>
            {/* <img
              src={section.image}
              alt={section.title}
              className="w-full h-auto rounded-lg shadow-md mb-6"
            /> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {section.insights.map((insight, idx) => (
                <div key={idx} className="p-6 bg-gray-100 rounded-lg shadow">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {insight.title}
                  </h3>
                  <p className="text-gray-700">{insight.content}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DigitalBusinessModelPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const CapacityBuildingAndTraining = () => {
  return (
    <div className="w-full bg-white">
{/* Hero Section */}
<section
  className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-28"
  style={{ backgroundImage: "url('/PagesImages/cbt.jpg')" }}
>
  <div className="absolute inset-0 bg-black bg-opacity-30"></div>

  {/* Title Overlay for Large Screens */}
  <div
    className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
  >
    <h1 className="text-3xl font-bold text-gray-800 mt-4">
      Capacity Building and Training
    </h1>
  </div>
</section>




      {/* Foreword Section */}
      <section className="max-w-4xl mx-auto py-16 px-6 lg:px-0">
          {/* Title Below Image for Small Screens */}
<div className="block lg:hidden mt-4 mb-4 text-left">
  <h1 className="text-2xl font-bold text-gray-800">
    Capacity Building and Training
  </h1>
</div>
        <p className="text-lg text-gray-700 mb-8 mt-12">
          At Seismic, we recognize that effective Public Financial Management (PFM) reforms require robust systems, policies, and institutional capacity for long-term sustainability. As a global leader in capacity building for PFM, we partner with governments and public institutions to equip them with the skills, knowledge, and tools needed to manage public finances effectively.
        </p>
        <div className="flex flex-col lg:flex-row items-start gap-8 mt-20">
          <img
            src="/PagesImages/capacity_building_small.jpg" // Replace with the correct small image path
            alt="Capacity Building"
            className="w-full lg:w-5/12 object-cover"
          />
          <p className="text-lg text-gray-700 lg:w-7/12 mt-4">
            Seismic’s training programs emphasize collaboration and peer learning, creating a culture of continuous improvement within public institutions.
          </p>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16">
        <div className="max-w-4xl mx-auto px-6 lg:px-0">
          <h2 className="text-3xl font-semibold mb-8">Our Approach</h2>
          <ul className="space-y-8">
            {[
              {
                title: "Tailored Training Programs for Effective PFM Implementation",
                description:
                  "Seismic designs and delivers customized training programs aligned with the specific challenges, goals, and regulatory frameworks of each client, covering topics like budgeting, financial reporting, audit systems, and risk management.",
              },
              {
                title: "Targeted Training for Enhanced Fiscal Accountability",
                description:
                  "Our capacity-building programs address critical PFM challenges, providing specialized training in performance-based budgeting, audits, and financial risk management, equipping staff to manage public resources effectively.",
              },
              {
                title: "Building Institutional Capacity for Sustainable Reforms",
                description:
                  "Seismic works with governments to strengthen institutional frameworks that underpin successful PFM reforms, ensuring staff develop leadership, strategic thinking, and problem-solving skills essential for long-term sustainability.",
              },
              {
                title: "Global Expertise, Local Impact",
                description:
                  "Our global experience in PFM allows us to deliver capacity-building solutions that are locally relevant. We integrate best practices with local knowledge to create adaptable, impactful training programs for each government or institution.",
              },
              {
                title: "Empowering Public Sector Staff through Knowledge Transfer",
                description:
                  "Seismic’s programs emphasize hands-on learning, allowing public sector staff to apply their training in real-world scenarios, ensuring practical understanding and effective implementation of PFM concepts.",
              },
            ].map((approach, index) => (
              <li key={index} className="flex gap-4">
                <span className="text-purple-900 text-2xl">▶</span>
                <div className="text-lg font-semibold">
                  {approach.title}: <span className="font-normal text-gray-700">{approach.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

{/* Other Services Section */}
<section className="py-16">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-gray-800 mb-8">Other Services</h2>
    <p className="text-xl text-gray-600 mb-8">
      Helping clients bridge the fundamental gaps in the education system
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[
        {
          title: "Audit and Financial Control Systems",
          description:
            "Seismic offers audit and financial control solutions that strengthen transparency, accountability, and governance within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.afcs}`,
        },
        {
          title: "Capital Investment Planning",
          description:
            "We assist governments in developing strategic capital investment plans aligned with sustainable development goals, promoting economic growth.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cip}`,
        },
        {
          title: "Financial Management Information Systems (FMIS)",
          description:
            "Seismic designs FMIS solutions that modernize public financial management, enhancing fiscal discipline and accountability.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.fmis}`,
        },
      ].map((service, index) => (
        <Link
          to={service.link}
          key={index}
          className="block bg-white p-6 shadow-md rounded-md text-left max-w-[450px] mx-auto"
        >
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            {service.title}
          </h3>
          <p className="text-lg text-gray-600 mb-4">
            {service.description}
          </p>
        </Link>
      ))}
    </div>
  </div>
</section>

    </div>
  );
};

export default CapacityBuildingAndTraining;

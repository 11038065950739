import React from 'react';

const PartnersSection = () => {
  return (
    <section className="py-16 bg-white text-center">
      {/* Section Heading */}
      <h2 className="text-2xl font-semibold mb-10">
        Partnering With Organizations Across And Beyond Nigeria
      </h2>

      {/* Logos Carousel */}
      <div className="logo-container overflow-hidden mt-20">
        <div className="logo-track">
          {/* Logos with unique class names for individual styling */}
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745705/giz_logo_vwn73e.png" alt="GIZ Logo" className="logo giz-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745704/fg_g1cmwf.png" alt="Federal Government of Nigeria Logo" className="logo fg-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745706/nnpc_t4m5so.png" alt="NNPC Logo" className="logo nnpc-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png" alt="World Bank Logo" className="logo world-bank-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745706/nimasa_v0qnim.png" alt="NIMASA Logo" className="logo nimasa-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745705/gpe_oofbii.png" alt="GPE Logo" className="logo gpe-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/sbmc_nlqrup.png" alt="SBMC Logo" className="logo sbmc-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745707/oxfam_xpfe6v.png" alt="Oxfam Logo" className="logo oxfam-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745703/european-union-logo_m5mrci.avif" alt="European Union Logo" className="logo eu-logo" />

          {/* Repeat the same logos to make the loop seamless */}
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745705/giz_logo_vwn73e.png" alt="GIZ Logo" className="logo giz-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745704/fg_g1cmwf.png" alt="Federal Government of Nigeria Logo" className="logo fg-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745706/nnpc_t4m5so.png" alt="NNPC Logo" className="logo nnpc-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png" alt="World Bank Logo" className="logo world-bank-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745706/nimasa_v0qnim.png" alt="NIMASA Logo" className="logo nimasa-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745705/gpe_oofbii.png" alt="GPE Logo" className="logo gpe-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/sbmc_nlqrup.png" alt="SBMC Logo" className="logo sbmc-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745707/oxfam_xpfe6v.png" alt="Oxfam Logo" className="logo oxfam-logo" />
          <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745703/european-union-logo_m5mrci.avif" alt="European Union Logo" className="logo eu-logo" />
        </div>
      </div>

      {/* Style for animation and individual logo sizing */}
      <style jsx>{`
        .logo-container {
          position: relative;
          width: 100%;
          overflow: hidden;
        }

        .logo-track {
          display: flex;
          width: max-content;
          animation: scroll 15s linear infinite;
        }

        .logo {
          object-fit: contain; /* Ensures logos fit within the size constraints */
          margin-right: 6rem; /* Spacing between logos */
          max-height: 80px; /* Set a maximum height for all logos */
        }

        /* Individual logo styles */
        .giz-logo {
          max-width: 160px;
        }

        .fg-logo {
          max-width: 140px;
        }

        .nnpc-logo {
          max-width: 150px;
        }

        .world-bank-logo {
          max-width: 200px;
        }

        .nimasa-logo {
          max-width: 150px;
        }

        .gpe-logo {
          max-width: 180px;
        }

        .sbmc-logo {
          max-width: 80px;
        }

        .oxfam-logo {
          max-width: 140px;
        }

        .eu-logo {
          max-width: 170px;
        }

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        /* Adjustments for smaller screens */
        @media (max-width: 768px) {
          .logo {
            margin-right: 2rem; /* Reduce spacing between logos */
            max-height: 50px; /* Reduce the height of logos */
          }

          /* Adjust individual logo widths */
          .giz-logo {
            max-width: 100px;
          }

          .fg-logo {
            max-width: 90px;
          }

          .nnpc-logo {
            max-width: 100px;
          }

          .world-bank-logo {
            max-width: 120px;
          }

          .nimasa-logo {
            max-width: 100px;
          }

          .gpe-logo {
            max-width: 110px;
          }

          .sbmc-logo {
            max-width: 60px;
          }

          .oxfam-logo {
            max-width: 90px;
          }

          .eu-logo {
            max-width: 110px;
          }
        }
      `}</style>
    </section>
  );
};

export default PartnersSection;

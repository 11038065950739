import React from 'react';

const SustainableSupplyChainManagement = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737710805/pexels-photo-266429_tgy49z.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Sustainable Supply Chain Management
          </h1>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <p className="text-gray-700 text-lg mb-4">
          Sustainability in the supply chain is more than just a trend—it’s a fundamental shift in how
          businesses operate, source materials, and deliver products to the market. At Seismic Consulting
          Group, we understand that sustainable supply chain management is critical for achieving long-term
          success, reducing environmental impact, and meeting the growing demands for transparency and
          accountability from consumers, investors, and regulators alike. Our approach helps organizations
          develop supply chains that are not only efficient but also environmentally responsible, socially
          equitable, and economically viable.
        </p>
      </div>

      {/* Main Content Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Designing a Sustainable Supply Chain
        </h2>
        <p className="text-gray-700 text-lg text-left mb-6">
          Sustainability begins at the very beginning of the supply chain. Our process starts with a
          comprehensive analysis of your existing supply chain operations, including sourcing, production,
          transportation, and distribution. This in-depth evaluation allows us to identify inefficiencies,
          environmental impacts, and areas where improvements can be made.
        </p>
        <p className="text-gray-700 text-lg text-left mb-6">
          We collaborate with your team to design a tailored sustainability strategy that incorporates best
          practices, industry standards, and innovative solutions to ensure your supply chain is not only
          optimized but also future-proof. Our focus is on creating a supply chain that aligns with your
          organizational goals while contributing positively to environmental, social, and economic outcomes.
        </p>

        <div className="space-y-8">
          {/* Resource Optimization */}
          <div className="p-2">
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Resource Optimization
            </h3>
            <p className="text-gray-700 text-lg mb-6">
              Resource optimization is one of the key pillars of sustainable supply chain management. We work
              with organizations to reduce resource consumption, whether energy, water, raw materials, or labor—
              while maintaining operational efficiency. Key strategies include:
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">

              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Circular Economy
                </h4>
                <p className="text-gray-700">
                  We encourage the adoption of circular economy principles, where products are designed for reuse, recycling, and regeneration. This reduces dependency on virgin resources, lowers waste, and closes the loop on product life cycles, making supply chains more sustainable.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Energy and Water Efficiency
                </h4>
                <p className="text-gray-700">
                  By integrating energy-efficient practices and reducing water consumption across the supply chain, we help organizations cut operating costs and lower their environmental impact, all while improving overall sustainability.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Lean Manufacturing Principles
                </h4>
                <p className="text-gray-700">
                  By eliminating waste and improving process efficiency, we help organizations reduce their use of materials and energy, thus minimizing costs and environmental impact.
                </p>
              </div>
            </div>
          </div>

          {/* Ethical Sourcing and Fair-Trade Practices */}
          <div className="p-2">
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Ethical Sourcing and Fair-Trade Practices
            </h3>
            <p className="text-gray-700 text-lg mb-6">
              Sustainable supply chains are built on a foundation of ethically sourced materials and fair labor
              practices. At Seismic Consulting Group, we guide organizations in implementing ethical sourcing
              strategies that prioritize transparency, traceability, and responsibility across the entire supply chain.
              This involves:
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Supplier Audits and Risk Assessment
                </h4>
                <p className="text-gray-700">
                  We work with clients to assess their suppliers’ environmental and social practices. This includes evaluating their adherence to ethical sourcing standards, such as fair wages, safe working conditions, and responsible labor practices.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Sustainable Procurement Policies
                </h4>
                <p className="text-gray-700">
                  We help organizations create procurement policies that prioritize sustainable materials, products, and services. This could involve sourcing raw materials from suppliers that use environmentally responsible practices or working with manufacturers committed to ethical labor practices.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Certification and Standards Compliance
                </h4>
                <p className="text-gray-700">
                  To ensure adherence to sustainability goals, we help organizations obtain relevant certifications such as Fair Trade, Forest Stewardship Council (FSC), Rainforest Alliance, and Global Organic Textile Standard (GOTS). These certifications provide third-party validation that the supply chain meets recognized ethical and environmental standards.
                </p>
              </div>
            </div>
          </div>

          {/* Waste Reduction and Waste Management */}
          <div className="p-2">
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Waste Reduction and Waste Management
            </h3>
            <p className="text-gray-700 text-lg mb-6">
              A sustainable supply chain focuses on minimizing waste generation and ensuring proper disposal or
              recycling. By implementing zero-waste practices, we help organizations divert waste from landfills
              and reduce their overall environmental footprint. Our strategies include:
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Waste Audits
                </h4>
                <p className="text-gray-700">
                  We conduct comprehensive waste audits to identify key areas where waste is generated and develop strategies to reduce it. This could include reducing packaging, reusing materials, or finding sustainable alternatives for non-recyclable items.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Efficient Logistics and Packaging
                </h4>
                <p className="text-gray-700">
                  By optimizing transportation routes, improving load efficiency, and using sustainable packaging, we help organizations cut down on waste and reduce their carbon footprint. This not only reduces costs but also enhances operational efficiency.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Product End-of-Life Management
                </h4>
                <p className="text-gray-700">
                  We implement take-back programs and recycling initiatives that encourage consumers to return products at the end of their life cycle. This promotes a circular supply chain where products are reused, refurbished, or recycled, rather than discarded.
                </p>
              </div>
            </div>
          </div>

          {/* Carbon Footprint Reduction and Low-Carbon Logistics */}
          <div className="p-2">
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Carbon Footprint Reduction and Low-Carbon Logistics
            </h3>
            <p className="text-gray-700 text-lg mb-6">
              Reducing carbon emissions across the supply chain is essential in mitigating climate change and
              meeting corporate sustainability goals. We help organizations assess their carbon footprint across all
              stages of their supply chain—from sourcing and production to distribution and transportation—and
              develop low-carbon solutions that reduce greenhouse gas emissions. Key strategies include:
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Green Logistics and Transportation
                </h4>
                <p className="text-gray-700">
                  We help businesses optimize transportation routes and consider alternatives to traditional fossil-fuel-powered vehicles, such as electric vehicles (EVs) or alternative fuels. This reduces emissions from transportation, which is often one of the largest contributors to a supply chain’s carbon footprint.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Carbon Offsetting Programs
                </h4>
                <p className="text-gray-700">
                  For emissions that cannot be eliminated, we guide organizations in investing in carbon offset projects, such as reforestation or renewable energy initiatives, to balance out their environmental impact.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Sustainable Packaging Solutions
                </h4>
                <p className="text-gray-700">
                  We also focus on reducing the carbon footprint associated with packaging, helping businesses transition to eco-friendly packaging materials and optimize packaging design to reduce waste and transportation energy.
                </p>
              </div>
            </div>
          </div>

          {/* Supply Chain Resilience and Adaptability */}
          <div className="p-2">
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Supply Chain Resilience and Adaptability
            </h3>
            <p className="text-gray-700 text-lg mb-6">
              A sustainable supply chain is not only efficient and environmentally responsible but also resilient to
              disruptions, such as natural disasters, geopolitical shifts, or supply shortages. We work with
              organizations to enhance the resilience of their supply chain by:
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Diversifying Supply Sources
                </h4>
                <p className="text-gray-700">
                  By sourcing from multiple suppliers and regions, businesses can reduce the risks associated with dependency on a single supplier or location.
                </p>
              </div>
              <div className="p-4 border rounded-lg shadow-sm bg-purple-50">
                <h4 className="text-lg font-semibold text-gray-900 mb-2">
                  Sustainable Risk Management
                </h4>
                <p className="text-gray-700">
                  We help organizations build sustainability into their risk management strategies, ensuring that supply chains remain adaptable and able to respond to environmental or market changes.
                </p>
              </div>
            </div>
          </div>

          {/* Transparency and Reporting */}
          <div className="p-2">
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Transparency and Reporting
            </h3>
            <p className="text-gray-700 text-lg mb-6">
              In an era of increasing stakeholder demands for transparency, we help organizations develop
              comprehensive sustainability reporting systems that provide clear insights into supply chain
              performance. We implement tools and systems for tracking sustainability metrics, such as carbon
              emissions, waste generation, and resource usage, and assist with the disclosure of these metrics to
              stakeholders through detailed sustainability reports. This transparency helps build trust with
              consumers, investors, and regulators while showcasing a commitment to responsible business
              practices.
            </p>
          </div>
        </div>
      </div>

      {/* The Seismic Advantage Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-4">The Seismic Advantage</h2>
            <p className="text-gray-900 text-lg mb-4">
              By implementing sustainable supply chain management practices, Seismic Consulting Group helps
              organizations achieve operational efficiency, reduce environmental impact, and ensure long-term
              economic success. We empower businesses to make better sourcing decisions, enhance supplier
              relationships, and create supply chains that are not only resilient but also aligned with sustainability goals.
            </p>
            <p className="text-gray-600 text-lg">
              Together, we ensure that your supply chain strategies contribute to both immediate and long-term success in a rapidly changing world.
            </p>
          </div>
          <div>
            <img
              src="https://images.pexels.com/photos/1495580/pexels-photo-1495580.jpeg?auto=compress&cs=tinysrgb&w=800"
              alt="The Seismic Advantage"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SustainableSupplyChainManagement;
import React from "react";

export default function CareersPage() {
  return (
    <div className="mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1740651317/pexels-photo-6963857_yug2kr.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-10">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Careers At Seismic
          </h1>
        </div>
      </section>

      {/* Introduction */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12 text-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Shape the Future with Us
        </h2>
        <p className="max-w-3xl mx-auto text-lg text-gray-700 mb-4 text-left">
          At Seismic, we believe in the power of people to drive meaningful change. We are a team of thinkers, innovators, and problem-solvers committed to transforming businesses, industries, and communities. If you're passionate about making an impact, this is the place for you.
        </p>
      </div>

      {/* Why Join Seismic Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        <h3 className="text-2xl font-bold text-gray-900 text-center mb-8">
          Why Join Seismic?
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
          <div>
            <div className="flex justify-center mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png"
                alt="Innovative Environment Icon"
                className="w-14 h-14"
              />
            </div>
            <h4 className="text-xl font-semibold text-purple-900 mb-2">Innovative Environment</h4>
            <p className="text-gray-600">
              Work on groundbreaking projects that shape industries and create lasting change.
            </p>
          </div>
          <div>
            <div className="flex justify-center mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739525691/7243186.png_kilfba.png"
                alt="Growth & Development Icon"
                className="w-14 h-14"
              />
            </div>
            <h4 className="text-xl font-semibold text-purple-900 mb-2">Growth & Development</h4>
            <p className="text-gray-600">
              Gain access to mentorship, training, and career advancement opportunities.
            </p>
          </div>
          <div>
            <div className="flex justify-center mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png"
                alt="Collaborative Culture Icon"
                className="w-14 h-14"
              />
            </div>
            <h4 className="text-xl font-semibold text-purple-900 mb-2">Collaborative Culture</h4>
            <p className="text-gray-600">
              Be part of a diverse, dynamic team that values new ideas and different perspectives.
            </p>
          </div>
          <div>
            <div className="flex justify-center mb-4">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739525789/7993065.png_benm7i.png"
                alt="Meaningful Work Icon"
                className="w-14 h-14"
              />
            </div>
            <h4 className="text-xl font-semibold text-purple-900 mb-2">Meaningful Work</h4>
            <p className="text-gray-600">
              Make a real impact by working on projects that solve complex challenges and drive progress.
            </p>
          </div>
        </div>
      </div>

      {/* Work Culture Section */}
      <div className="bg-gray-50 py-12 mt-8">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col md:flex-row gap-12 items-center">
          {/* Text Section */}
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Work Culture</h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-4">
              At Seismic, we foster a culture of excellence, collaboration, and continuous learning. We value creativity, leadership, and initiative, encouraging every team member to bring their best ideas forward.
            </p>
            <p className="text-gray-600 text-lg leading-relaxed">
              Whether you're a seasoned professional or just starting your career, you'll find opportunities to grow and thrive.
            </p>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2">
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739528425/pexels-photo-789822_ftxnti.jpg"
              alt="Work Culture"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>

      <div className="mb-16 max-w-7xl mx-auto px-6 lg:px-6 mt-8">
  <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6">
    Career Opportunities at Seismic
  </h2>
  <p className="text-lg text-gray-600 text-center max-w-3xl mx-auto mb-8">
    Explore opportunities across our dynamic departments and become part of a team that drives meaningful change. 
    At Seismic, we foster a culture of excellence, innovation, and impact-driven work.
  </p>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
    {[
      {
        title: "Digital Transformation & Innovation",
        description: "Lead the future of digital solutions by integrating AI, automation, and data-driven technologies. Help organizations adapt to the evolving digital landscape while driving efficiency and impact.",
      },
      {
        title: "Research and Innovation",
        description: "Be at the forefront of evidence-based decision-making. Conduct in-depth research, analyze trends, and develop innovative strategies that shape industries and inform policy reforms.",
      },
      {
        title: "Monitoring & Evaluation",
        description: "Enhance the effectiveness of projects and policies by tracking key performance metrics. Drive continuous improvement, assess impact, and provide strategic recommendations to ensure sustainable success.",
      },
      {
        title: "Accounting",
        description: "Ensure financial integrity and sustainability. Manage budgets, optimize financial processes, and provide strategic insights that drive growth and operational excellence.",
      },
      {
        title: "Procurement",
        description: "Lead the way in supply chain optimization. Develop value-driven procurement strategies that enhance cost efficiency, transparency, and resilience in business operations.",
      },
      {
        title: "HR and Legal",
        description: "Shape the future of work by building a strong, engaged workforce. Develop policies, foster employee well-being, and ensure compliance with legal and ethical standards.",
      },
    ].map((dept, index) => (
      <div key={index} className="p-6 border border-gray-200 shadow-lg rounded-lg text-left">
        <h3 className="text-xl font-semibold text-purple-900 mb-2">{dept.title}</h3>
        <p className="text-gray-600 text-md">{dept.description}</p>
      </div>
    ))}
  </div>
</div>

<div className="bg-gray-50 py-16">
  <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-col-reverse md:flex-row gap-12 items-center">
    {/* Image Section */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739528596/pexels-photo-140945_hbpkwr.jpg"
        alt="Employee Value Proposition"
        className="w-full rounded-lg shadow-lg"
      />
    </div>

    {/* Text Section */}
    <div className="w-full md:w-1/2">
      <h2 className="text-2xl font-semibold mb-4 text-gray-900">Our Employee Value Proposition</h2>
      <p className="text-lg text-gray-700 mb-6">
        At Seismic, we recognize that our people are our greatest asset. We are committed 
        to providing an environment where talent thrives, ideas flourish, and careers grow.
      </p>
      <ul className="space-y-6">
        <li>
          <h3 className="text-lg font-semibold text-purple-900">Impact-Driven Work</h3>
          <p className="text-gray-600">
            Be part of projects that create real, lasting change across industries and communities.
          </p>
        </li>
        <li>
          <h3 className="text-lg font-semibold text-purple-900">Career Growth & Development</h3>
          <p className="text-gray-600">
            Access mentorship, continuous learning, and clear career progression opportunities.
          </p>
        </li>
        <li>
          <h3 className="text-lg font-semibold text-purple-900">Collaborative & Inclusive Culture</h3>
          <p className="text-gray-600">
            Work with diverse teams that encourage innovation and value different perspectives.
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>


  {/* Open Roles Section */}
  <div className="max-w-5xl mx-auto px-6 lg:px-12 mt-12 text-center mb-20">
    <h3 className="text-xl font-semibold text-gray-900 mb-2">Current Open Roles</h3>
    <p className="text-lg text-gray-600">
    At Seismic, we’re looking for passionate individuals to help us grow. Explore our open roles and take the next step in your career.


    </p>
    {/* Future Link to Zoho HR Section */}
    <a
      href="https://seismicconsults.zohorecruit.com/jobs/Careers" target="_blank" rel="noreferrer"
      className="mt-4 inline-block bg-purple-900 text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-800 transition duration-300"
      aria-disabled="true"
    >
      View Careers
    </a>
  </div>

    </div>
  );
}

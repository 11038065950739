import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const ConsultingSection = () => {
  const images = [
    { src: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733733613/pexels-cottonbro-6334064_ezpler.jpg", alt: "Education" },
    { src: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1733513942/pexels-pixabay-414837_awlvcr.jpg", alt: "Environmental Sustainablity" },
    { src: "https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1734696475/pexels-inmortal-producciones-108299-336232_1_dfjaec.jpg", alt: "Technology" },
   ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  const consultingServices = [
    { name: "Education Reform and Advisory", path: "/consulting-services/education-reform" },
    { name: "Public Financial Management", path: "/consulting-services/public-financial-management" },
    { name: "Digital Transformation", path: "/consulting-services/digital-transformation" },
    { name: "Governance Reforms", path: "/consulting-services/governance-reform" },
    { name: "Capacity Building", path: "/consulting-services/capacity-building" },
    { name: "Social Protection and Poverty Reduction", path: "/consulting-services/social-protection" },
    { name: "Mineral Exploration & Mining", path: "/consulting-services/mineral-exploration-and-mining-consulting-service" },
    { name: "Independent Verification", path: "/consulting-services/independent-verification" },
  

   
  ];

  return (
    <section className="flex flex-col lg:flex-row justify-between items-start py-8 lg:py-20 bg-white gap-10">
      {/* Title for Small Screens */}
      <div className="lg:hidden w-full text-center mb-0 mt-12 order-1">
        <h2 className="text-2xl font-semibold leading-snug mb-0">
          Innovating Beyond Boundaries and Delivering Impact
        </h2>
      </div>

      {/* Image with Carousel */}
      <div className="w-full lg:w-1/2 mb-4 lg:mb-0 order-2 lg:order-2 relative ">
      <img
  src={images[currentImageIndex].src}
  alt={images[currentImageIndex].alt}
  className="object-cover rounded-lg shadow-lg
   w-full lg:w-[750px] lg:h-[500px] sm:h-[300px] max-h-[550px] mx-auto"
/>

</div>


      {/* Text and Consulting Services */}
      <div className="w-full lg:w-1/2 lg:space-y-4 py-0 lg:py-0 order-3 lg:order-1">
        {/* Title for Large Screens */}
        <h2 className="text-3xl font-semibold leading-snug hidden lg:block">
          Innovating Beyond Boundaries and Delivering Impact
        </h2>

        <p className="text-lg text-gray-600">
          Empowering organizations, governments, and leaders to drive meaningful
          change through innovative, evidence-based strategies.
        </p>

        {/* Consulting Services */}
        <div>
          <h3 className="text-lg font-medium py-3">Consulting Services</h3>
          <div className="flex flex-wrap gap-2">
            {consultingServices.map((service) => (
              <Link
                to={service.path}
                key={service.name}
                className="whitespace-nowrap border border-[#600060] text-[#600060] font-medium text-sm lg:text-base px-2 lg:px-4 py-1 lg:py-2 rounded-full text-center"
              >
                {service.name}
              </Link>
            ))}
          </div>

          {/* View All Link */}
          <div className="mt-8">
            <Link
              to="/consulting-services"
              className="text-[#600060] font-semibold text-sm lg:text-base flex items-center"
            >
              View All
              <svg
                className="w-4 h-4 lg:w-5 lg:h-5 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultingSection;

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const EarlyChildhoodDevelopment = () => {
  return (
    <section className="bg-white pb-16 mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1733493159/ecd_ssu7nx.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div
          className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
        >
          <h1 className="text-3xl font-bold text-gray-800">
            Early Childhood Development (ECD)
          </h1>

        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Early Childhood Development (ECD)
        </h1>
      </div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto px-6 mt-8 lg:mt-24 text-center lg:text-left">
        <p className="text-gray-700 mb-8">
          Seismic Consulting Group provides specialized consulting services aimed at enhancing early childhood education by supporting the holistic development of children. Our services help education organizations manage core functions that cater to both child development and family support, ensuring children are prepared for future academic success and socio-emotional growth.
        </p>

        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Key Services</h3>
        <ul className="space-y-6 text-gray-700">
          <li>
            <div className="flex items-start">
              <span className="text-purple-800 mr-2">&#9654;</span>
              <div>
                <p className="font-semibold">Child Development Programs:</p>
                <p className="mt-1">
                  We design and implement programs focused on cognitive, emotional, and social development, ensuring children develop the foundational skills needed for lifelong learning.
            </p>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-start">
              <span className="text-purple-800 mr-2">&#9654;</span>
              <div>
                <p className="font-semibold">Family Engagement Strategies:</p>
                <p className="mt-1">
                  We provide consulting to improve family involvement in early education, focusing on strategies that support working families through flexible care solutions and family education programs.
            </p>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-start">
              <span className="text-purple-800 mr-2">&#9654;</span>
              <div>
                <p className="font-semibold">Integration of Health and Nutrition:</p>
                <p className="mt-1">
                  Seismic incorporates health, nutrition, and wellness initiatives into early childhood programs, ensuring that children receive comprehensive care that supports their physical and mental development.
            </p>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-start">
              <span className="text-purple-800 mr-2">&#9654;</span>
              <div>
                <p className="font-semibold">Technology Integration:</p>
                <p className="mt-1">
                  We offer expertise in implementing digital tools that foster personalized learning experiences, helping educators tailor instruction to the unique needs of each child.
            </p>
              </div>
            </div>
          </li>
        </ul>

        {/* Link to View Projects */}
        <div className="mt-8">
          <Link to="/education-reform/early-childhood-development/projects" className="text-purple-800 font-semibold hover:underline">
            View ECD Projects Executed →
          </Link>
        </div>
      </div>

      {/* Related Area of Focus Section */}
      <div className="bg-gray-50 py-12 mt-16">
        <div className="max-w-7xl mx-auto px-6">
          <h3 className="text-3xl font-semibold text-gray-800 text-center">Related Area of Focus</h3>
          <p className="text-center text-gray-600 mb-8">Shaping Education for Global Impact</p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      
            {/* Primary Education */}
            <Link
              to={`/${paths.consultingServices}/${paths.educationReform}/${paths.primaryEducation}`}
              className="bg-white shadow p-6 text-center"
            >
        <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745785/track-record-1_emzjef.jpg" alt="Primary Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Primary Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                We work closely with governments, schools, and NGOs to enhance curricula, teacher quality, and educational resources, ensuring all students have the opportunity to excel in the classroom and beyond.
              </p>
            </Link>

            {/* Secondary Education */}
            <Link
              to={`/${paths.consultingServices}/${paths.educationReform}/${paths.secondaryEducation}`}
              className="bg-white shadow p-6 text-center"
            >
        <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745789/secondary_education_dudw6p.jpg" alt="Secondary Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Secondary Education</h4>
              <p className="text-gray-600 text-sm mt-2">
          Our expertise in curriculum design, teacher development, and personalized learning ensures that students acquire both the academic and practical skills necessary for future employment and lifelong learning.
              </p>
            </Link>

            {/* Non-Formal Education and Lifelong Learning */}
            <Link
              to={`/${paths.consultingServices}/${paths.educationReform}/${paths.lifelongLearning}`}
              className="bg-white shadow p-6 text-center"
            >
        <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745778/lifelong_learning_g382fn.jpg" alt="Non-Formal Education and Lifelong Learning" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Non-Formal Education and Lifelong Learning</h4>
              <p className="text-gray-600 text-sm mt-2">
                These areas ensure that both traditional academic settings and alternative learning opportunities are optimized for success across diverse contexts.
              </p>
            </Link>

            {/* Higher Education */}
            <Link
              to={`/${paths.consultingServices}/${paths.educationReform}/${paths.higherEducation}`}
              className="bg-white shadow p-6 text-center"
            >
        <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745766/higher_education_vuklog.jpg" alt="Higher Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Higher Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                Seismic Consulting provides tailored solutions to ensure higher education institutions are equipped to meet the demands of the 21st-century workforce.
              </p>
            </Link>

          </div>
        </div>
      </div>
    </section>
  );
};

export default EarlyChildhoodDevelopment;

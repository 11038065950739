import React, { useState } from "react";

const PersonalizedMedicine = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const expertiseData = [
    {
      title: "Harnessing Genomics",
      description:
        "The foundation of personalized medicine lies in understanding the human genome. At Seismic Consulting, we:",
      services: [
        "Partner with life sciences companies to integrate genomic testing into their healthcare solutions, enabling precise identification of genetic predispositions to diseases like cancer, cardiovascular disorders, and diabetes.",
        "Assist in developing targeted therapies that align with a patient’s genetic profile, ensuring optimal treatment efficacy.",
        "Collaborate with research institutions to advance the study of rare genetic disorders, paving the way for innovative cures.",
      ],
    },
    {
      title: "Integrating Artificial Intelligence",
      description:
        "AI is revolutionizing personalized medicine by turning vast amounts of healthcare data into actionable insights. Our work includes:",
      services: [
        "Developing predictive models that forecast disease progression and treatment outcomes using AI-driven analytics.",
        "Automating the analysis of complex datasets from electronic health records (EHR), lab results, and imaging studies to support clinical decision-making.",
        "Enabling drug discovery through AI-powered identification of promising drug candidates, significantly reducing the time and cost involved in bringing new treatments to market.",
      ],
    },
    {
      title: "Leveraging Advanced Data Analytics",
      description:
        "Data is the lifeblood of personalized medicine. We help organizations maximize its potential by:",
      services: [
        "Building robust data ecosystems that integrate clinical, genomic, and lifestyle data to provide a comprehensive view of patient health.",
        "Applying machine learning algorithms to uncover patterns and correlations that inform precise treatment strategies.",
        "Enhancing real-time monitoring and feedback loops, enabling adaptive care plans that evolve with patient responses.",
      ],
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center"
        style={{
          backgroundImage:
            "url('')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
          Personalized Medicine
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
          Partnering with organizations to navigate the complexities of healthcare delivery, regulatory frameworks, and patient engagement.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Personalized Medicine
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Redefining Healthcare Through Precision and Innovation
        </h2>
        <p className="text-gray-700 text-lg mb-4">
          At Seismic Consulting Group, we are shaping the future of healthcare by pioneering
          personalized medicine solutions. Leveraging the latest advancements in genomics,
          artificial intelligence (AI), and data analytics, we help life sciences companies and
          healthcare providers create tailored treatment plans designed to meet the unique
          genetic, environmental, and lifestyle factors of individual patients.
        </p>
        <p className="text-gray-700 text-lg mb-4">
          This approach not only transforms patient outcomes but also streamlines drug discovery,
          improves treatment efficacy, and reduces overall healthcare costs, setting new standards
          in patient-centered care.
        </p>
        <h3 className="text-2xl font-semibold text-gray-900 mb-4 mt-8">
          What is Personalized Medicine?
        </h3>
        <p className="text-gray-700 font-medium text-lg mb-4">
          Personalized medicine, also known as precision medicine, moves away from the traditional “one-size-fits-all” approach to healthcare. Instead, it focuses on:
        </p>
        <ul className="list-disc list-inside text-gray-700 text-lg space-y-2">
          <li>
            Identifying unique biological markers (e.g., genetic variations, biomarkers) to predict
            disease risk and treatment responses.
          </li>
          <li>
            Customizing therapies based on individual characteristics, ensuring more accurate
            and effective interventions.
          </li>
          <li>
            Minimizing adverse effects by selecting treatments that align with a patient’s genetic
            makeup and medical history.
          </li>
        </ul>
      </div>

      {/* Expertise Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Our Expertise in Personalized Medicine
        </h2>
        <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
          Seismic Consulting's Change Management & Strategy Consulting services deliver tangible, sustainable outcomes that drive long-term success.
        </p>
        {expertiseData.map((item, index) => (
          <div key={index} className="mb-12 border-b border-pink-100 pb-8">
            <button
              onClick={() => toggleSection(index)}
              className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
            >
              {item.title}
              <span className="text-purple-900 text-3xl">
                {openSection === index ? "-" : "+"}
              </span>
            </button>
            {openSection === index && (
              <div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 text-lg mb-4">{item.description}</p>
                </div>

                {/* Services Section */}
                <div className="mt-6 px-6 lg:px-32">
                  <ul className="list-none text-gray-700 text-lg space-y-4">
                    {item.services.map((service, idx) => (
                      <li key={idx} className="relative pl-6">
                        <span
                          className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 rounded-full"
                        ></span>
                        {service}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Future of Personalized Medicine Section */}
      <section className="bg-white py-16">
  <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
  <div>
  <img
    src=""
    alt="Future of Personalized Medicine"
    className="w-full h-[450px] object-cover shadow-lg"
  />
</div>
    <div>
      <h2 className="text-3xl font-bold mb-4">The Future of Personalized Medicine with Seismic Consulting</h2>
      <p className="text-gray-600 text-lg mb-4">
      As precision medicine continues to evolve, we remain at the cutting edge, guiding life sciences companies and health care providers to harness its full potential. By integrating technology, data, and science, we are building a healthcare future that is not just innovative but profoundly personal.
      </p>
      <p className="text-gray-600 text-lg mt-6">
      Let Seismic Consulting Group help you unlock the transformative power of personalized medicine and redefine what’s possible in healthcare. Together, we can set new benchmarks for care and improve lives globally
      </p>
    </div>
  </div>
</section>

{/* Transformative Benefits Section */}
<div className="py-16 max-w-6xl mx-auto px-6 lg:px-12">
  <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
    Transformative Benefits of Personalized Medicine
  </h2>
  <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
    Discover the groundbreaking advantages of personalized medicine, reshaping the future of healthcare delivery.
  </p>
  {[
    {
      title: "Accelerating Drug Discovery",
      description: "Personalized medicine significantly shortens the path from bench to bedside by:",
      services: [
        "Focusing research efforts on therapies likely to succeed in specific patient populations.",
        "Reducing clinical trial failures by identifying optimal candidates based on genetic profiles.",
        "Facilitating regulatory approval through targeted, evidence-based results."
      ]
    },
    {
      title: "Enhancing Treatment Effectiveness",
      description: "Tailored therapies lead to superior outcomes by:",
      services: [
        "Increasing the likelihood of treatment success by matching interventions to individual patient characteristics.",
        "Reducing trial-and-error prescribing, leading to faster relief for patients.",
        "Minimizing the risk of adverse effects through precise drug selection and dosage adjustments."
      ]
    },
    {
      title: "Lowering Healthcare Costs",
      description: "Personalized medicine delivers economic advantages by:",
      services: [
        "Reducing unnecessary treatments and hospitalizations through early and accurate diagnoses.",
        "Optimizing resource allocation by focusing efforts on effective interventions.",
        "Preventing the escalation of chronic conditions through proactive, personalized care plans."
      ]
    },
    {
      title: "Driving Patient-Centered Care",
      description: "At the core of personalized medicine is the patient. Our solutions ensure a holistic, patient-centered approach by:",
      services: [
        "Empowering individuals with knowledge about their genetic and health profiles, enabling them to make informed decisions.",
        "Incorporating lifestyle and environmental factors into treatment plans for a truly comprehensive approach to health.",
        "Building trust and engagement through tailored healthcare experiences that resonate with each patient’s unique needs."
      ]
    },
    {
      title: "Impacting Healthcare Ecosystems",
      description: "Seismic Consulting Group’s personalized medicine initiatives have led to:",
      services: [
        "Breakthroughs in targeted cancer therapies that reduce tumor progression while minimizing side effects.",
        "Development of precision diagnostics that enable earlier detection of high-risk diseases.",
        "Creation of scalable models for integrating personalized medicine into public health strategies, ensuring equitable access to advanced treatments."
      ]
    }
  ].map((item, index) => (
    <div key={index} className="mb-12 border-b border-pink-100 pb-8">
      <button
        onClick={() => setOpenSection(openSection === index ? null : index)}
        className="flex justify-between items-center w-full text-left py-4 text-2xl font-semibold text-gray-900"
      >
        {item.title}
        <span className="text-purple-900 text-3xl">
          {openSection === index ? "-" : "+"}
        </span>
      </button>
      {openSection === index && (
        <div>
          {/* Description */}
          <div className="mt-4">
            <p className="text-gray-700 text-lg mb-4">{item.description}</p>
          </div>
          {/* Services Section */}
          <div className="mt-6 px-6 lg:px-32">
            <ul className="list-none text-gray-700 text-lg space-y-4">
              {item.services.map((service, idx) => (
                <li key={idx} className="relative pl-6">
                  <span
                    className="absolute top-2 left-0 w-3 h-3 bg-purple-100 border-2 rounded-full"
                  ></span>
                  {service}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  ))}
</div>

    </div>
  );
};

export default PersonalizedMedicine;

import React from 'react';
import HeroSection from '../LandingPageComponents/HeroSection';
import ConsultingSection from '../LandingPageComponents/ConsultingSection';
import ExpertiseSection from '../LandingPageComponents/ExpertiseSection';
//import VideoSection from '../LandingPageComponents/VideoSection';
import InsightsSection from '../LandingPageComponents/InsightsSection';
import ProjectsSection from '../LandingPageComponents/ProjectsSection';
import StatsSection from '../LandingPageComponents/StatsSection';
import TeamSection from '../LandingPageComponents/TeamSection';
import PartnersSection from '../LandingPageComponents/PartnersSection';


const LandingPage = () => {
  return (
    <div className="w-full"> {/* Adjusted padding for responsiveness */}
      <HeroSection /> 
        <div className='seismic_container'>
        <ConsultingSection />
        <ExpertiseSection />
        </div>
      <div className='seismic_container'>
      <ProjectsSection />
        <InsightsSection />
        <StatsSection />
        <TeamSection />
        <div>
        <PartnersSection />
        </div>
      </div>
 
    
    </div>
  );
};

export default LandingPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const CapitalInvestmentPlanning = () => {
  return (
    <div className="w-full bg-white">
      {/* Hero Section */}
      <section
  className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
  style={{ backgroundImage: "url('/PagesImages/cip.jpg')" }} // Replace with the correct hero image path
>
  <div className="absolute inset-0 bg-black bg-opacity-30"></div>

  {/* Title Overlay for Large Screens */}
  <div
    className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
  >
    <h1 className="text-3xl font-bold text-gray-800 mt-4">
      Capital Investment Planning
    </h1>
  </div>
</section>

      {/* Foreword Section */}
      <section className="max-w-4xl mx-auto py-16 px-6 lg:px-0">
                          {/* Title Below Image for Small Screens */}
<div className="block lg:hidden mt-4 mb-4 text-left">
  <h1 className="text-2xl font-bold text-gray-800">
  Capital Investment Planning
  </h1>
</div>
        <p className="text-lg text-gray-700 mb-8 mt-8">
          At Seismic, we are dedicated to helping governments and public institutions develop strategic capital investment plans that drive sustainable economic growth and align with long-term national development goals. As a global leader in Public Financial Management (PFM), we specialize in designing comprehensive budget planning instruments to ensure that public resources are allocated to priority sectors.
        </p>
        <div className="flex flex-col lg:flex-row items-start gap-8 mt-20">
          <img
            src="/PagesImages/capital_investment_small.jpg" // Replace with the correct small image path
            alt="Capital Investment"
            className="w-full lg:w-5/12 object-cover"
          />
          <p className="text-lg text-gray-700 lg:w-7/12 mt-4">
            Our planning instruments, including MTSS, MTEF, and MTPF, help governments manage investments over time, improving transparency, fiscal discipline, and the impact of public investments.
          </p>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16">
        <div className="max-w-4xl mx-auto px-6 lg:px-0">
          <h2 className="text-3xl font-semibold mb-8">Our Approach</h2>
          <ul className="space-y-8">
            {[
              {
                title: "Multiyear Budget Frameworks (MTPF) for Long-Term Planning",
                description:
                  "Seismic's MTPF provides a roadmap for managing public investments, ensuring projects are funded and future budget allocations align with national priorities. This approach enhances spending predictability, transparency, and public investment management.",
              },
              {
                title: "Optimizing Resource Allocation for Maximum Impact",
                description:
                  "Our capital investment planning ensures efficient resource use, prioritizing projects that drive economic growth, job creation, and public service improvements. We focus on maximizing the impact of public spending on society.",
              },
              {
                title: "Midterm Expenditure Framework (MTEF) for Fiscal Sustainability",
                description:
                  "Our MTEF provides a structured approach linking policy priorities with resource allocation over three to five years, supporting fiscal discipline and addressing critical development needs.",
              },
              {
                title: "Midterm Sector Strategies (MTSS) for Sectoral Alignment",
                description:
                  "Seismic’s MTSS services align sector strategies with national goals, helping governments prioritize investments within key sectors for maximum social and economic impact.",
              },
            ].map((approach, index) => (
              <li key={index} className="flex gap-4">
                <span className="text-purple-900 text-2xl">▶</span>
                <div className="text-lg font-semibold">
                  {approach.title}: <span className="font-normal text-gray-700">{approach.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
{/* Other Services Section */}
<section className="py-16">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-gray-800 mb-8">Other Services</h2>
    <p className="text-xl text-gray-600 mb-8">
      Helping clients bridge the fundamental gaps in the education system
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[
        {
          title: "Audit and Financial Control Systems",
          description:
            "Seismic provides audit and control solutions that reinforce transparency, accountability, and governance within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.afcs}`,
        },
        {
          title: "Capacity Building and Training",
          description:
            "Our targeted training programs equip public sector staff with skills for effective PFM implementation, ensuring sustainable fiscal management.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cbt}`,
        },
        {
          title: "Financial Management Information Systems (FMIS)",
          description:
            "Seismic designs FMIS solutions that modernize public financial management, enhancing fiscal discipline and accountability.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.fmis}`,
        },
      ].map((service, index) => (
        <Link
          to={service.link}
          key={index}
          className="block bg-white p-6 shadow-md rounded-md text-left max-w-[450px] mx-auto"
        >
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            {service.title}
          </h3>
          <p className="text-lg text-gray-600 mb-4">
            {service.description}
          </p>

        </Link>
      ))}
    </div>
  </div>
</section>

    </div>
  );
};

export default CapitalInvestmentPlanning;

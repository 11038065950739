import React, { useState } from "react";
import FinancialKeyAreasOfExpertise from "./FinancialKeyAreasOfExpertise";

const FinancialServices = () => {
  const [open, setOpen] = useState({});

  const toggleCollapse = (title) => {
    setOpen((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  return (
    <div className="py-16">
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739186564/pexels-photo-207153_k5vpto.jpg')" }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Financial Services
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Our solutions are designed to navigate the rapidly evolving financial landscape, fostering agility, operational efficiency, and resilience across all levels of the organization.
          </p>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Financial Services
        </h1>
      </div>


        {/* Context Section */}
        <div className="max-w-6xl mx-auto px-4 lg:px-12 mt-8 lg:mt-16">
        <div className="mb-12">
          <h2 className="text-xl lg:text-3xl lg:font-semibold font-medium lg:text-center lg:mb-8 text-left">
            Revolutionizing Financial Services for the Digital Age
          </h2>
          <p className="text-gray-600 lg:text-lg text-base leading-relaxed">
            Empowering financial institutions to thrive in the digital age, at SEISMIC CONSULTING, we combine deep industry expertise with innovative strategies to address complex challenges, foster sustainable growth, and reshape market dynamics. Partnering with banks, insurance companies, and fintech leaders, we deliver solutions that drive transformation and success. Our solutions are designed to navigate the rapidly evolving financial landscape, fostering agility, operational efficiency, and resilience across all levels of the organization.
          </p>
        </div>
        </div>

{/* Transforming Financial Institutions Section */}
<div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-16 mt-8"></div>
<div className="mx-auto px-4 lg:px-32 mt-8 lg:mt-16">
  <div className="flex flex-col lg:flex-row items-center gap-6 lg:gap-12">
    {/* Text Section */}
    <div className="lg:w-1/2 text-center lg:text-left">
      <h3 className="text-xl lg:text-2xl font-bold mb-4">
        Transforming Financial Institutions for a Digital-First Future
      </h3>
      {/* Image Section for Small Screens */}
      <div className="lg:hidden mb-4">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737623091/pexels-photo-207489_dmteha.jpg"
          alt="Transforming Financial Institutions"
          className="w-full rounded-lg"
        />
      </div>
      <p className="text-gray-600 text-base lg:text-lg leading-relaxed">
        Seismic Consulting delivers tailored, high-impact solutions to address the unique challenges of the financial services industry. Whether modernizing legacy systems or integrating advanced fintech solutions, we empower our clients to remain competitive, achieve regulatory compliance, and prioritize customer centricity in their operations.
      </p>
    </div>
    {/* Image Section for Larger Screens */}
    <div className="lg:w-1/2 hidden lg:block">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737623091/pexels-photo-207489_dmteha.jpg"
        alt="Transforming Financial Institutions"
        className="w-full rounded-lg"
      />
    </div>
  </div>
</div>


        {/* Key Areas of Expertise Section */}
<FinancialKeyAreasOfExpertise />



    </div>
  );
};

export default FinancialServices;

import React, { useState } from "react";
import AgricultureCollapsible from "./AgricultureCollapsible";

const AgricultureFocusAreas = () => {
  const [activeArea, setActiveArea] = useState("Precision Agriculture");

  const areas = [
    "Precision Agriculture",
    "Sustainable Farming Practices",
    "Agri-Fintech Solutions",
    "Supply Chain Optimization",
  ];

  const contentMap = {
    "Precision Agriculture": {
      title: "Revolutionizing Modern Farming for Efficiency and Sustainability",
      description: `
        In a rapidly evolving agricultural landscape, precision agriculture stands as a transformative approach 
        that enables farmers and agribusinesses to maximize productivity while minimizing resource use and environmental impact. 
        At Seismic Consulting Group, we specialize in integrating advanced precision agriculture technologies 
        that not only enhance yields but also align farming practices with sustainability goals and economic viability.
      `,
    },
    "Sustainable Farming Practices": {
      title: "Cultivating Resilience and Stewardship in Agriculture",
      description: `
        Sustainable farming is more than a method—it is a commitment to nurturing the land, preserving biodiversity, 
        and ensuring the long-term viability of agricultural systems. At Seismic Consulting Group, we collaborate 
        with farmers, agribusinesses, and organizations to adopt sustainable farming techniques that 
        not only enhance productivity but also restore ecological balance and safeguard the planet’s natural resources.
      `,
    },
    "Agri-Fintech Solutions": {
      title: "Empowering Farmers and Agribusinesses Through Financial Innovation",
      description: `
        Access to financial resources is essential for driving growth, resilience, and innovation in agriculture. 
        Recognizing the financial challenges faced by farmers and agribusinesses, Seismic Consulting Group develops 
        and implements cutting-edge agri-fintech solutions that enhance financial inclusion, unlock opportunities for 
        investment, and build resilience against risks.
      `,
    },
    "Supply Chain Optimization": {
      title: "Transforming Agribusiness Supply Chains for Efficiency, Transparency, and Sustainability",
      description: `
        The modern agricultural supply chain is a complex ecosystem that spans farm production, processing, logistics, 
        and delivery to the end consumer. At Seismic Consulting Group, we recognize that optimizing these intricate 
        networks is essential for agribusinesses to remain competitive in a rapidly evolving market.
      `,
    },
  };
  

  const seismicAdvantage = {
    "Precision Agriculture": [
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Customized Solutions",
        description:
          "Developing tailored precision agriculture systems that align with the specific goals, crops, and geographies of each client.",
      },
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Training and Support",
        description:
          "Equipping farmers and agribusinesses with the skills and knowledge needed to leverage advanced tools effectively.",
      },
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Scalability and Accessibility",
        description:
          "Designing solutions that are scalable for large agribusinesses yet accessible for smallholder farmers, ensuring widespread adoption and impact.",
      },
    ],
    "Sustainable Farming Practices": [
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Customized Transition Plans",
        description:
          "Developing step-by-step guides for integrating sustainable practices into existing operations, minimizing disruption while maximizing impact.",
      },
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Education and Training",
        description:
          "Providing farmers and agribusinesses with the knowledge and skills needed to implement and maintain sustainable methods effectively.",
      },
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Market Alignment",
        description:
          "Assisting clients in positioning their sustainably produced goods to meet consumer preferences and comply with regulatory standards.",
      },
    ],
    "Agri-Fintech Solutions": [
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Microloans and Innovative Financing",
        description:
          "Enabling smallholder farmers to access credit through flexible, tailored financing solutions designed to meet their unique needs.",
      },
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Insurance Products for Risk Mitigation",
        description:
          "Providing farmers with protection against weather variability, market fluctuations, and yield losses through specialized insurance solutions.",
      },
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Digital Payment Platforms",
        description:
          "Transforming how farmers manage financial transactions, ensuring efficiency, transparency, and broader financial inclusion.",
      },
    ],
    "Supply Chain Optimization": [
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Traceability Systems",
        description:
          "Implementing technologies like blockchain and IoT to ensure transparency, enhance food safety, and reduce waste across the supply chain.",
      },
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Logistics Optimization",
        description:
          "Leveraging AI-driven tools and real-time data to optimize transportation routes, improve storage solutions, and streamline distribution.",
      },
      {
        icon: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png",
        title: "Sustainability Monitoring Systems",
        description:
          "Tracking environmental impact metrics such as carbon emissions and water usage to promote compliance and continuous improvement.",
      },
    ],
  };
  

  return (
    <section className="max-w-6xl mx-auto px-6 lg:px-12 mt-16 lg:mt-24">
      {/* Title and Subtitle */}
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-900">Key Areas of Focus</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          Seismic Consulting’s Change Management & Strategy Consulting services deliver tangible, sustainable outcomes that drive long-term success.
        </p>
      </div>

      <div className="flex flex-col lg:flex-row">
        {/* Sidebar */}
        <div className="lg:w-1/3 w-full lg:pr-8">
          <div className="bg-[#F9F5FB] p-8 space-y-4">
            {areas.map((area) => (
              <div key={area}>
                <button
                  onClick={() => setActiveArea(area)}
                  className={`text-left py-6 px-4 font-semibold text-xl w-full ${
                    activeArea === area
                      ? "bg-gradient-to-b from-[#2D002D] to-[#600060] text-white"
                      : "text-gray-800 hover:bg-purple-100"
                  }`}
                >
                  {area}
                </button>
                <div className="border-t border-purple-200 w-full"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Content Area */}
        <div className="lg:w-2/3 w-full">
          <div className="p-8 bg-white shadow-md rounded-md">
            <h2 className="text-3xl font-bold mb-4">
              {contentMap[activeArea].title}
            </h2>
            <p className="text-gray-700 text-lg mb-6 whitespace-pre-line">
              {contentMap[activeArea].description}
            </p>

            {/* Seismic Advantage Section */}
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              The Seismic Advantage in {activeArea}
            </h3>
            <p className="text-gray-600 text-lg mb-6">
              Seismic Consulting Group offers tailored strategies that address the unique challenges 
              and opportunities of each client, ensuring the seamless adoption of sustainable farming practices.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
              {seismicAdvantage[activeArea].map((item, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img src={item.icon} alt={item.title} className="w-16 h-16 mb-4" />
                  <h4 className="text-lg font-semibold text-gray-900">
                    {item.title}
                  </h4>
                  <p className="text-gray-600 text-base">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <AgricultureCollapsible activeFocus={activeArea} />
    </section>
  );
};

export default AgricultureFocusAreas;

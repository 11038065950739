import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

const StatsSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const statsRef = useRef(null);

  // Handle intersection detection
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.unobserve(statsRef.current); // Only trigger once
        }
      },
      { threshold: 0.3 } // Trigger when 50% of the section is visible
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);

  // Handle hover detection
  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  return (
    <section
      ref={statsRef}
      className="py-16 bg-white text-center"
      onMouseEnter={handleMouseEnter} // Trigger when hovered
    >
      {/* Stats Grid */}
      <div className="grid grid-cols-2 gap-y-12 gap-x-8 mb-12 stats-grid">
        {/* First Stat */}
        <div className="flex items-center justify-end space-x-4">
          <h3 className="text-4xl font-bold text-purple-900 stats-number">
            {isVisible ? <CountUp end={100} duration={10} /> : '100'}+
          </h3>
          <p className="text-xl text-gray-600 leading-tight stats-text">
            Successful Projects <br /> Delivered
          </p>
        </div>

        {/* Second Stat */}
        <div className="flex items-center justify-start space-x-4">
          <h3 className="text-4xl font-bold text-purple-900 stats-number">
            {isVisible ? <CountUp end={20} duration={10} /> : '20'}%
          </h3>
          <p className="text-xl text-gray-600 leading-tight stats-text">
            Cost Reduction <br /> Across Sectors
          </p>
        </div>

        {/* Third Stat */}
        <div className="flex items-center justify-end space-x-4 ml-1">
          <h3 className="text-4xl font-bold text-purple-900 stats-number">
            {isVisible ? <CountUp end={10} duration={10} /> : '10'}+
          </h3>
          <p className="text-xl text-gray-600 leading-tight stats-text">
            Regions Within and <br /> Beyond Nigeria
          </p>
        </div>

        {/* Fourth Stat */}
        <div className="flex items-center justify-start space-x-4">
          <h3 className="text-4xl font-bold text-purple-900 stats-number">
            {isVisible ? <CountUp end={95} duration={10} /> : '95'}%
          </h3>
          <p className="text-xl text-gray-600 leading-tight stats-text">
            Client Satisfaction <br /> Rate
          </p>
        </div>
      </div>

      {/* Map Image with markers */}
      <div className="relative flex justify-center">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745706/map_xwjabm.png"
          alt="Map"
          className="w-full max-w-4xl opacity-30 map-image"
        />

        {/* Marker for America */}
        <div
          className="absolute text-purple-900"
          style={{ top: "60%", left: "30%" }}
        >
          <svg
            className="w-8 h-8 text-purple-900"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C8.13401 2 5 5.13401 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.381 0-2.5-1.119-2.5-2.5S10.619 6.5 12 6.5s2.5 1.119 2.5 2.5S13.381 11.5 12 11.5z"
            />
          </svg>
          <span className="block text-sm">America</span>
        </div>

        {/* Marker for Europe */}
        <div
          className="absolute text-purple-900"
          style={{ top: "15%", left: "54%" }}
        >
          <svg
            className="w-8 h-8 text-purple-900"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C8.13401 2 5 5.13401 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.381 0-2.5-1.119-2.5-2.5S10.619 6.5 12 6.5s2.5 1.119 2.5 2.5S13.381 11.5 12 11.5z"
            />
          </svg>
          <span className="block text-sm">Europe</span>
        </div>

        {/* Marker for Africa */}
        <div
          className="absolute text-purple-900"
          style={{ top: "45%", left: "45%" }}
        >
          <svg
            className="w-8 h-8 text-purple-900"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C8.13401 2 5 5.13401 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.381 0-2.5-1.119-2.5-2.5S10.619 6.5 12 6.5s2.5 1.119 2.5 2.5S13.381 11.5 12 11.5z"
            />
          </svg>
          <span className="block text-sm">Africa</span>
        </div>

        {/* Marker for Asia */}
        <div
          className="absolute text-purple-900"
          style={{ top: "25%", left: "70%" }}
        >
          <svg
            className="w-8 h-8 text-purple-900"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C8.13401 2 5 5.13401 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.381 0-2.5-1.119-2.5-2.5S10.619 6.5 12 6.5s2.5 1.119 2.5 2.5S13.381 11.5 12 11.5z"
            />
          </svg>
          <span className="block text-sm">Asia</span>
        </div>
         {/* Smaller Random Markers for African Countries */}
         <div
          className="absolute text-purple-900"
          style={{ top: "47%", left: "48%" }}
        >
          <svg
            className="w-3 h-3 text-purple-900" 
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C8.13401 2 5 5.13401 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.381 0-2.5-1.119-2.5-2.5S10.619 6.5 12 6.5s2.5 1.119 2.5 2.5S13.381 11.5 12 11.5z"
            />
          </svg>
        </div>

        <div
          className="absolute text-purple-900"
          style={{ top: "50%", left: "43%" }}
        >
          <svg
            className="w-3 h-3 text-purple-900" 
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C8.13401 2 5 5.13401 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.381 0-2.5-1.119-2.5-2.5S10.619 6.5 12 6.5s2.5 1.119 2.5 2.5S13.381 11.5 12 11.5z"
            />
          </svg>
        </div>

        <div
          className="absolute text-purple-900"
          style={{ top: "52%", left: "50%" }}
        >
          <svg
            className="w-3 h-3 text-purple-900" 
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C8.13401 2 5 5.13401 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.381 0-2.5-1.119-2.5-2.5S10.619 6.5 12 6.5s2.5 1.119 2.5 2.5S13.381 11.5 12 11.5z"
            />
          </svg>
        </div>

        <div
          className="absolute text-purple-900"
          style={{ top: "55%", left: "45%" }}
        >
          <svg
            className="w-3 h-3 text-purple-900" 
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C8.13401 2 5 5.13401 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.381 0-2.5-1.119-2.5-2.5S10.619 6.5 12 6.5s2.5 1.119 2.5 2.5S13.381 11.5 12 11.5z"
            />
          </svg>
        </div>
      </div>

      {/* Adjustments for Phone Screens */}
      <style jsx>{`
        @media (max-width: 639px) {
          .stats-grid {
            grid-template-columns: 1fr 1fr !important; /* Keep two columns on small screens */
            gap-y: 12px !important;
            gap-x: 16px !important;
            margin-bottom: 12px !important;
          }
          .stats-number {
            font-size: 1.5rem !important; /* Adjust font size */
          }
          .stats-text {
            font-size: 0.85rem !important; /* Adjust text size */
            line-height: 1.2rem !important; /* Adjust line height */
          }
          .stats-text br {
            display: block !important; /* Keep line breaks */
          }
        }
      `}</style>
    </section>
  );
};

export default StatsSection;

import React from 'react';

const TeamSection = () => {
  return (
    <section className="relative w-full bg-white py-14">
      <div className="text-center mb-8 px-4 lg:px-0">
        <h1 className="text-3xl lg:text-4xl font-bold text-black">
          Our Partners Trust Us to Deliver Innovative, Lasting Impact
        </h1>
      </div>

      {/* Original UI for Larger Screens */}
      <div className="hidden lg:block">
        {/* Right Image (Aligned to the Bottom and Slight Overlap) */}
        <div className="ml-auto w-3/4" style={{ height: '500px' }}>
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745723/team_r9rsil.png"
            alt="Team Working"
            className="w-full h-full object-cover"
          />
        </div>
        {/* Text Card (Positioned on the Lower Half, Overlapping the Image) */}
        <div
          className="absolute bg-white shadow-lg p-8 z-10"
          style={{
            bottom: '5%',
            left: '2%', 
            width: '40%',
            transform: 'translateY(0%)',
          }}
        >
          <h2 className="text-2xl font-semibold leading-snug">
            Creating enduring, positive change
          </h2>
          <p className="text-lg text-gray-600 mt-4">
            Our people are at the core of our success. We combine diverse expertise, innovative tools,
            and a deep understanding of local contexts to develop solutions that are evidence-based,
            sustainable, and results-driven.
          </p>

          {/* Team Images with Overlap */}
          <div className="flex items-center mt-6">
            <div className="relative">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random4_v6jr7w.jpg"
                alt="Team Member"
                className="w-10 h-10 rounded-full object-cover border-2 border-white"
                style={{ zIndex: 4 }}
              />
            </div>
            <div className="relative -ml-3">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random2_iweorz.jpg"
                alt="Team Member"
                className="w-10 h-10 rounded-full object-cover border-2 border-white"
                style={{ zIndex: 3 }}
              />
            </div>
            <div className="relative -ml-3">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745707/random1_rytv1l.jpg"
                alt="Team Member"
                className="w-10 h-10 rounded-full object-cover border-2 border-white"
                style={{ zIndex: 2 }}
              />
            </div>
            <div className="relative -ml-3">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random6_yxycv1.jpg"
                alt="Team Member"
                className="w-10 h-10 rounded-full object-cover border-2 border-white"
                style={{ zIndex: 1 }}
              />
            </div>

            {/* +15 Icon */}
            <div
              className="w-10 h-10 rounded-full border-2 border-white flex items-center justify-center -ml-3"
              style={{
                backgroundImage: 'url("https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random3_icbfd9.jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 0,
              }}
            >
              <span className="text-white text-xs font-semibold">+15</span>
            </div>
          </div>

          {/* Meet the Team Link */}
          <div className="mt-4">
            <a href="about/the-team" className="text-purple-900 font-semibold flex items-center">
              Meet our People
              <svg
                className="w-5 h-5 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      {/* Adjusted UI for Phone Screens */}
      <div className="block lg:hidden">
        {/* Image */}
        <div className="w-full">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745723/team_r9rsil.png"
            alt="Team Working"
            className="w-full h-auto object-cover"
          />
        </div>
        {/* Text Content */}
        <div className="p-4">
          <h2 className="text-xl font-semibold leading-snug">
            Creating enduring, positive change
          </h2>
          <p className="text-base text-gray-600 mt-4">
            Our people are at the core of our success. We combine diverse expertise, innovative tools,
            and a deep understanding of local contexts to develop solutions that are evidence-based,
            sustainable, and results-driven.
          </p>

          {/* Team Images with Overlap */}
          <div className="flex items-center justify-center mt-6">
            <div className="relative">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random4_v6jr7w.jpg"
                alt="Team Member"
                className="w-8 h-8 rounded-full object-cover border-2 border-white"
                style={{ zIndex: 4 }}
              />
            </div>
            <div className="relative -ml-2">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random2_iweorz.jpg"
                alt="Team Member"
                className="w-8 h-8 rounded-full object-cover border-2 border-white"
                style={{ zIndex: 3 }}
              />
            </div>
            <div className="relative -ml-2">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random5_llb9uz.jpg"
                alt="Team Member"
                className="w-8 h-8 rounded-full object-cover border-2 border-white"
                style={{ zIndex: 2 }}
              />
            </div>
            <div className="relative -ml-2">
              <img
                src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random6_yxycv1.jpg"
                alt="Team Member"
                className="w-8 h-8 rounded-full object-cover border-2 border-white"
                style={{ zIndex: 1 }}
              />
            </div>

            {/* +15 Icon */}
            <div
              className="w-8 h-8 rounded-full border-2 border-white flex items-center justify-center -ml-2"
              style={{
                backgroundImage: 'url("https://res.cloudinary.com/dxhx45ing/image/upload/v1738745708/random3_icbfd9.jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 0,
              }}
            >
              <span className="text-white text-xs font-semibold">+15</span>
            </div>
          </div>

          {/* Meet the Team Link */}
          <div className="mt-4 text-center">
            <a
              href="about/the-team"
              className="text-purple-900 font-semibold flex items-center justify-center"
            >
              Meet our People
              <svg
                className="w-5 h-5 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { projectsData } from '../../data/projectsData';
import { paths } from '../../constants/paths';

const ProjectDetail = () => {
  const { projectId } = useParams();

  // Find the project data based on projectId
  let project = null;
  for (const category in projectsData) {
    const foundProject = projectsData[category].find(
      (proj) => proj.id === projectId
    );
    if (foundProject) {
      project = foundProject;
      break;
    }
  }

  // If the project is not found, display a message
  if (!project) {
    return (
      <div className="py-16 px-6 text-center mt-20">
        <h1 className="text-2xl font-bold">Project Not Found</h1>
      </div>
    );
  }

  return (
    <section className="bg-white pb-16 mt-28">
      {/* Hero Section */}
 {/* Hero Section */}
 <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage: `url('${project.image || '/placeholder.jpg'}')`,
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div
          className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
        >
          <h1 className="text-3xl font-bold text-gray-800">{project.title}</h1>
        </div>
      </section>
      {/* Project Content */}
      <div className="py-16 px-6 max-w-5xl mx-auto space-y-8">
        <section>
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Overview</h2>
          <div className="text-lg text-gray-700 leading-relaxed mb-8">
            {Array.isArray(project.overview) ? (
              project.overview.map((paragraph, index) => (
                <p key={index} className="mb-4">{paragraph}</p>
              ))
            ) : (
              <p>{project.overview}</p>
            )}
          </div>
        </section>

        <section>
  <p className="text-xl font-semibold text-gray-700 mb-4">{project.description}</p>
  <p className="text-gray-500 text-sm mb-6 text-center">
    Year: {project.year || 'N/A'} | Role: {project.role || 'N/A'} | Client: 
    {project.clientLogo ? (
      <img 
        src={project.clientLogo} 
        alt={`${project.client} Logo`} 
        className="inline-block h-6 ml-2 align-middle" 
      />
    ) : (
      <strong className="ml-1">{project.client || 'N/A'}</strong>
    )}
  </p>
</section>


        <div className="mb-8">
          <img
            src={project.image || "/placeholder.jpg"} // Ensure a fallback image
            alt="Project Detail"
            className="w-full h-[300px] object-cover rounded-lg shadow-lg"
          />
        </div>

        <section>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Objectives</h3>
          <ul className="space-y-6 text-gray-700">
            {project.objectives?.map((objective, index) => (
              <li key={index}>
                <div className="flex items-start">
                  <span className="text-purple-800 mr-2">&#9654;</span>
                  <div>{objective}</div>
                </div>
              </li>
            ))}
          </ul>
        </section>

{/* Related Projects Section */}
<section className="mt-10 text-center">
  <h3 className="text-2xl font-semibold text-gray-800 mb-4">Related Projects</h3>
  <p className="text-md text-gray-600 mb-8">Helping clients bridge the fundamental gaps in the education system</p>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
    {Object.values(projectsData)
      .flat()
      .filter((relatedProject) => relatedProject.id !== projectId)
      .slice(0, 3)
      .map((relatedProject, idx) => (
        <Link
                to={`/${paths.consultingServices}/${paths.educationReform}/${paths.allEducationProjects}/${relatedProject.id}`}
          key={idx}
          className="bg-white overflow-hidden"
        >
          <img
            src={relatedProject.image}
            alt={relatedProject.title}
            className="w-full h-[220px] object-cover"
          />
          <div className="p-4">
            <h5 className="text-lg font-bold text-gray-800">{relatedProject.title}</h5>
            <p className="text-gray-600 text-sm">
              {relatedProject.description.length > 100
                ? `${relatedProject.description.substring(0, 100)}...`
                : relatedProject.description}
            </p>
            {relatedProject.year && <p className="text-gray-500 text-xs mt-2">{relatedProject.year}</p>}
          </div>
        </Link>
              
      ))}
  </div>
</section>
      </div>
    </section>
  );
};

export default ProjectDetail;

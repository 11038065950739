import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const PrimaryEducation = () => {
  return (
    <section className="bg-white pb-16 mt-20">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1734687061/pexels-kwakugriffn-14554004_nokfjr.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div
          className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
        >
          <h1 className="text-3xl font-bold text-gray-800">
            Primary Education
          </h1>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Primary Education
        </h1>
      </div>

      {/* Main Content */}
      <div className="max-w-5xl mx-auto px-6 mt-8 lg:mt-24 text-center lg:text-left">
        <p className="text-gray-700 mb-8">
          Seismic’s consulting services in primary education are designed to improve foundational learning, literacy, and numeracy outcomes. We work closely with governments, schools, and NGOs to enhance curricula, teacher quality, and educational resources, ensuring all students have the opportunity to excel in the classroom and beyond.
        </p>

        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Key Services</h3>
        <ul className="space-y-6 text-gray-700">
          <li>
            <div className="flex items-start">
              <span className="text-purple-800 mr-2">&#9654;</span>
              <div>
                <p className="font-semibold">Curriculum Development and Enhancement:</p>
                <p className="mt-1">
                  Seismic works with education stakeholders to develop globally competitive curricula that focus on both core academic subjects and the critical soft skills required in the 21st-century workforce.
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-start">
              <span className="text-purple-800 mr-2">&#9654;</span>
              <div>
                <p className="font-semibold">Teacher Training and Professional Development:</p>
                <p className="mt-1">
                  We help build teacher capacity through comprehensive training programs, ensuring teachers are equipped with modern pedagogical techniques and access to digital tools that enhance student learning.
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-start">
              <span className="text-purple-800 mr-2">&#9654;</span>
              <div>
                <p className="font-semibold">Equity in Education:</p>
                <p className="mt-1">
                  Our consulting services include strategies for ensuring equitable access to quality education, with particular attention to marginalized groups and children with disabilities.
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-start">
              <span className="text-purple-800 mr-2">&#9654;</span>
              <div>
                <p className="font-semibold">Student Assessment Systems:</p>
                <p className="mt-1">
                  We implement sophisticated data and measurement systems to track student progress, ensuring data-driven decision-making that drives academic improvement.
                </p>
              </div>
            </div>
          </li>
        </ul>

        {/* Link to View Projects */}
        <div className="mt-8">
          <Link to="/education-reform/primary-education/projects" className="text-purple-800 font-semibold hover:underline">
            View Primary Education Projects Executed →
          </Link>
        </div>
      </div>

      {/* Related Area of Focus Section */}
      <div className="bg-gray-50 py-12 mt-16">
        <div className="max-w-7xl mx-auto px-6">
          <h3 className="text-3xl font-semibold text-gray-800 text-center">Related Area of Focus</h3>
          <p className="text-center text-gray-600 mb-8">Shaping Education for Global Impact</p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <Link to={`/${paths.consultingServices}/${paths.educationReform}/${paths.earlyChildhoodDevelopment}`} className="bg-white shadow p-6 text-center">
              <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1733493159/ecd_ssu7nx.jpg" alt="Early Childhood Development" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Early Childhood Development</h4>
              <p className="text-gray-600 text-sm mt-2">
                Supporting holistic development by focusing on child development and family support for future academic success.
              </p>
            </Link>

            <Link to={`/${paths.consultingServices}/${paths.educationReform}/${paths.secondaryEducation}`} className="bg-white shadow p-6 text-center">
              <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745703/consult3_tdo25f.png" alt="Secondary Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Secondary Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                Preparing students for success in higher education with curriculum reform and technology integration.
              </p>
            </Link>

            <Link to={`/${paths.consultingServices}/${paths.educationReform}/${paths.tvet}`} className="bg-white shadow p-6 text-center">
              <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738745250/WhatsApp_Image_2025-02-05_at_09.45.49_vsk3mb.jpg" alt="Technical and Vocational Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Technical and Vocational Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                Developing skills through vocational training that align with labor market needs.
              </p>
            </Link>

            <Link to={`/${paths.consultingServices}/${paths.educationReform}/${paths.higherEducation}`} className="bg-white shadow p-6 text-center">
              <img src="https://res.cloudinary.com/dxhx45ing/image/upload/v1734689220/istockphoto-858465172-612x612_gwv5de.jpg" alt="Higher Education" className="w-full h-40 object-cover mb-4"/>
              <h4 className="font-bold text-gray-800">Higher Education</h4>
              <p className="text-gray-600 text-sm mt-2">
                Equipping higher education institutions to meet workforce demands through curriculum and technology integration.
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrimaryEducation;

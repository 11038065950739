import React, { useState } from "react";

const MethodologySectionCBCL = () => {
  const [open, setOpen] = useState({
    leadershipDevelopment: false,
    mentorshipCoaching: false,
    peerNetworking: false,
    skillReinforcement: false,
  });

  const toggleCollapse = (key) => {
    setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div className="mb-16">
      <h2 className="text-2xl font-bold mb-4 text-center pt-12">Our Methodology</h2>
      <h3 className="text-xl text-center mb-4 text-gray-600">
      Leadership development programs focused on change management and decision-making. Mentorship and coaching for key stakeholders. Seismic’s methodology for Capacity Building for Change Leaders combines theoretical knowledge with practical application, ensuring that leaders are well-prepared to meet the challenges of transformation.
      </h3>
      {/* Divider Line */}
      <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-8 mt-8"></div>

      <div className="max-w-4xl mx-auto space-y-16 mt-12">
        {/* Leadership Development Programs */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("leadershipDevelopment")}
          >
            Leadership Development Programs
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.leadershipDevelopment ? "rotate-180" : ""
              }`}
            >
              {open.leadershipDevelopment ? "-" : "+"}
            </span>
          </button>
          {open.leadershipDevelopment && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl">Customized Training Modules:</strong> Tailored programs focus on key aspects of change management, including:
                  <ul className="list-disc pl-6 mt-2">
                    <li>Understanding the psychology of change.</li>
                    <li>Developing strategic decision-making skills under uncertainty.</li>
                    <li>Enhancing emotional intelligence and conflict resolution abilities.</li>
                  </ul>
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl">Scenario-Based Learning:</strong> Practical exercises and simulations that mirror real-life challenges, enabling leaders to apply their learning in safe environments.
                </div>
              </li>
            </ul>
          )}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Mentorship and Coaching */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("mentorshipCoaching")}
          >
            Mentorship and Coaching
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.mentorshipCoaching ? "rotate-180" : ""
              }`}
            >
              {open.mentorshipCoaching ? "-" : "+"}
            </span>
          </button>
          {open.mentorshipCoaching && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl">Individualized Support:</strong> One-on-one coaching sessions for key stakeholders to address specific challenges and develop personalized strategies.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl">Mentorship Programs:</strong> Pair emerging leaders with experienced mentors to foster growth, confidence, and a culture of continuous learning.
                </div>
              </li>
            </ul>
          )}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Peer Networking and Collaboration */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("peerNetworking")}
          >
            Peer Networking and Collaboration
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.peerNetworking ? "rotate-180" : ""
              }`}
            >
              {open.peerNetworking ? "-" : "+"}
            </span>
          </button>
          {open.peerNetworking && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl">Collaboration Forums:</strong> Facilitate forums and workshops where leaders can exchange ideas, share experiences, and build collaborative strategies for change.
                </div>
              </li>
            </ul>
          )}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Ongoing Skill Reinforcement */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("skillReinforcement")}
          >
            Ongoing Skill Reinforcement
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.skillReinforcement ? "rotate-180" : ""
              }`}
            >
              {open.skillReinforcement ? "-" : "+"}
            </span>
          </button>
          {open.skillReinforcement && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div>
                  <strong className="text-xl">Follow-Up Training:</strong> Provide follow-up sessions, resource materials, and refresher courses to ensure continuous skill development.
                </div>
              </li>
            </ul>
          )}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>
      </div>
    </div>
  );
};

export default MethodologySectionCBCL;

import React from 'react';
import { Link } from 'react-router-dom';


const ConsultingServices = () => {
  const services = [
    {
      title: 'Education Reform and Advisory',
      description:
        'Our work in education reform has enabled governments and institutions to achieve significant improvements in student performance, access to education, and overall system efficiency.',
      link: '/consulting-services/education-reform',
    },
    {
      title: 'Public Financial Management (PFM)',
      description:
        'Our PFM solutions have empowered governments to achieve significant fiscal discipline, enhance public service delivery, and restore public trust in financial governance.',
      link: '/consulting-services/public-financial-management',
    },
    {
      title: 'Independent Verification and Assurance',
      description:
        'By setting new standards in independent verification, Seismic Consulting has helped clients achieve unparalleled levels of transparency and accountability.',
       link: '/consulting-services/independent-verification',
    },
    {
      title: 'Social Protection and Poverty Reduction',
      description:
        'Our innovative approaches to social protection have led to measurable reductions in poverty and inequality, empowering communities to break the cycle of poverty.',
       link: '/consulting-services/social-protection',
    },
    {
      title: 'Governance Reforms',
      description:
        'Our governance reforms have empowered governments to operate more transparently and effectively, delivering tangible benefits to their citizens.',
      link: '/consulting-services/governance-reform', 
    },
    {
      title: 'Change Management & Strategy Consulting',
      description:
        'Our approach to change management has enabled organizations to successfully implement transformative initiatives, driving long-term growth and sustainability.',
      link: '/consulting-services/change-management-page',
    },
    {
      title: 'Digital Transformation Consulting',
      description:
        'Our digital development initiatives have empowered organizations and governments to embrace the digital age, leading to improved service delivery, greater transparency, and enhanced economic opportunities.',
      link: '/consulting-services/digital-transformation',
    },
    {
      title: 'Capacity Building & Human Resource Development',
      description:
        'Our capacity-building initiatives have transformed organizations by equipping them with the skills and knowledge needed to thrive in a competitive landscape.',
       link: '/consulting-services/capacity-building',
    },
    {
      title: 'Healthcare & Life Sciences',
      description:
        'We specialize in designing and implementing advanced solutions—from hospital ERPs to AI-driven mental health diagnostics—that help healthcare organizations enhance patient outcomes, optimize operations, and thrive in a rapidly evolving industry.',
      link: '/consulting-services/healthcare-consulting',
    },
    {
      title: 'Mineral Exploration & Mining Investment Facilitation',
      description:
        'With extensive expertise in strategic planning, resource assessment, and policy development, our mission is to align industry best practices with your organizational objectives, ensuring sustainable growth and profitability. ',
      link: '/consulting-services/mineral-exploration-and-mining-consulting-service',
    }
    
  ];
  
  

  return (
    <section className="relative w-full bg-white pt-32 py-16">
      {/* Large Screen Design */}
      <div className="hidden lg:block relative w-full max-w-full mx-auto z-5 mb-24">
        {/* Right-Side Image */}
        <div className="absolute top-0 right-0 w-full h-[450px] overflow-hidden">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738151200/WhatsApp_Image_2025-01-29_at_12.45.48_kdd3ya.jpg" 
            alt="Consulting Background"
            className="w-full h-full object-cover"
            style={{ position: 'absolute', right: 0 }} // Ensures it stretches to the right
          />
        </div>

        {/* Overlapping Card */}
        <div
          className="absolute bg-white p-8 z-20"
          style={{
            width: '42%', // Card width
            top: '280px', // Adjust this as needed to ensure overlap with the image
            left: '0%', // Move the card to the right by adjusting this value
            height: '270px',
          }}
        >
          <h2 className="text-3xl md:text-2xl font-bold text-gray-800 ml-24">
            Innovating Beyond Boundaries and Delivering Impact
          </h2>
          <p className="text-lg text-gray-600 mt-4 ml-24">
            Empowering organizations, governments, and leaders to drive meaningful change through innovative, evidence-based strategies.
          </p>
        </div>
      </div>

      {/* Small Screen Design */}
      <div className="block lg:hidden">
        {/* Card First */}
        <div className="bg-white p-8 z-20 mb-6 text-center">
          <h2 className="text-3xl font-semibold text-gray-800">
            Innovating Beyond Boundaries and Delivering Impact
          </h2>

        </div>

        {/* Image Second */}
        <div className="w-full mb-6">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738151200/WhatsApp_Image_2025-01-29_at_12.45.48_kdd3ya.jpg"
            alt="Consulting Background"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>

      {/* Consulting Services Section */}
      <div className="relative z-10 max-w-7xl mx-auto mt-12 lg:mt-80 lg:pt-60 text-center">
        <h3 className="text-3xl font-semibold text-center text-gray-800 mb-2">
          Our Consulting Services
        </h3>
        <p className="text-center text-lg text-gray-600 mb-8">
          We partner with businesses across diverse industries.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
  {services.map((service, index) => (
    <div
      key={index}
      className="border border-gray-200 p-6 rounded-lg hover:shadow-lg transition-shadow bg-white text-center lg:text-left"
    >
      <h4 className="text-xl font-semibold text-gray-800 mb-4">
        {service.title}
      </h4>
      <p className="text-gray-600 text-base mb-4">
        {service.description}
      </p>
      <Link
        to={service.link}
        className="text-purple-900 font-semibold inline-flex items-center"
      >
        Learn More
        <svg
          className="w-5 h-5 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  ))}
</div>

      </div>
    </section>
  );
};

export default ConsultingServices;

import React from 'react';

export default function WhatWeDoPage() {
  return (
    <div className="flex flex-col w-full mt-20">
      {/* Top Section with Background Image & Overlay */}
      <div className="relative h-[450px] w-full bg-cover bg-center" 
        style={{
          backgroundImage: `url("https://res.cloudinary.com/dxhx45ing/image/upload/v1740657473/pexels-photo-1103970_wyk39k.jpg")`
        }}
      >
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black/60" />
        
        {/* Text Overlay */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
          What We Do
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
          We are driven by the conviction that impactful change is achieved 
            through strategic thinking, collaborative innovation, and unwavering 
            commitment to delivering results.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-4 md:px-8 py-10 text-gray-800 leading-relaxed">
        <p className="mb-4 text-xl">
          We are more than just a consultancy – As trusted partner we help our clients drive change.
          Our clients choose us because we provide strategic insights, technical expertise, 
          and help our clients to deliver impactful solutions that align with their vision 
          and goals. We take pride in our long-term relationships and success stories 
          that span across industries, regions, and sectors. Whether a government agency 
          or a private organization, we help improve public service delivery 
          and operational efficiency. We also assist development agencies with their 
          large-scale projects to deliver results. Seismic Consulting is equipped 
          to help its clients achieve their objectives.
        </p>
        <p className="mb-4 text-xl">
          Our professionals are committed to delivering tailored, evidence-based solutions 
          through projects that address complex challenges such as 
          <strong> Monitoring Learning Achievement (MLA)</strong>, 
          <strong> Early Grade Reading Assessment (EGRA)</strong>, 
          <strong> Digital Skilling &amp; Technology Integration</strong> 
          in education; World Bank 
          <strong> State Education Programme Investment Project (SEPIP)</strong> 
          in public financial management, and 
          <strong> Training on Skills Acquisition and Entrepreneurship for Unskilled 
          Women &amp; Youths</strong> in digital transformation.
        </p>
        <p className="mb-4 text-xl">
          Our team has partnered with leading global organizations, including the World Bank, 
          Ministry of Education, GIZ, and the M4Edu initiative, driving sustainable, 
          scalable progress where it matters most.
        </p>
      </div>

      {/* Our Figures */}
      <div className="bg-gray-50 py-10">
        <div className="max-w-4xl mx-auto px-4 md:px-8">
          <h2 className="text-2xl md:text-3xl font-bold text-center mb-4">
            Our Figures
          </h2>
          <p className="text-center text-gray-700 max-w-2xl mx-auto mb-8">
            Our professionals are committed to delivering tailored, evidence-based solutions 
            that address complex challenges in our services.
          </p>

          <div className="flex flex-col md:flex-row justify-around items-center gap-8">
            {/* Figure 1 */}
            <div className="text-center">
              <h3 className="text-4xl font-bold text-purple-800 mb-1">500+</h3>
              <p className="text-gray-700 max-w-xs">
                With over 500 projects completed, we have a proven track record 
                of leading change across our services.
              </p>
            </div>
            {/* Figure 2 */}
            <div className="text-center">
              <h3 className="text-4xl font-bold text-purple-800 mb-1">100%</h3>
              <p className="text-gray-700 max-w-xs">
                At Seismic, we have a 100% commitment to digital transformation, 
                understanding its pivotal role in reshaping organizations 
                and enhancing efficiency across sectors.
              </p>
            </div>
            {/* Figure 3 */}
            <div className="text-center">
              <h3 className="text-4xl font-bold text-purple-800 mb-1">80+</h3>
              <p className="text-gray-700 max-w-xs">
                With a team of experts, staff &amp; partners, Seismic brings together 
                diverse skills and deep expertise across sectors, in 36 cities 
                and 6 countries.
              </p>
            </div>
          </div>
        </div>
      </div>

 {/* Our Values Section */}
<div className="py-2 mb-20">
  <div className="max-w-7xl mx-auto px-4 md:px-8">
    <h2 className="text-2xl md:text-3xl font-bold text-center mb-4">Our Values</h2>
    <p className="text-center text-gray-700 max-w-2xl mx-auto mb-8">
      Seismic has a number of internal core ethics, which support our vision 
      and shape our organizational culture.
    </p>

    {/* 6-Item Grid */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {/* 1. Service */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765626/person_7498074_e8j9wb.png"
            alt="Service Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Service</h3>
        <p className="text-gray-700">
          We are dedicated to serving our clients with unwavering commitment 
          and excellence.
        </p>
      </div>

      {/* 2. Integrity */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737556289/7278881.png_pl8sa1.png"
            alt="Integrity Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Integrity</h3>
        <p className="text-gray-700">
          We maintain the highest ethical standards in all our dealings and actions.
        </p>
      </div>

      {/* 3. Client-Centricity */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738333933/7497595_bvlqkh.png"
            alt="Client-Centricity Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Client-Centricity</h3>
        <p className="text-gray-700">
          We place our clients at the heart of everything we do, ensuring 
          their needs are met with tailored solutions.
        </p>
      </div>

      {/* 4. Innovation */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737554966/7498861.png_rddzuh.png"
            alt="Innovation Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Innovation</h3>
        <p className="text-gray-700">
          We foster a culture of creativity and forward-thinking, allowing 
          us to anticipate and solve complex problems.
        </p>
      </div>

      {/* 5. Efficiency */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737546264/7498046.png_ejk71i.png"
            alt="Efficiency Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Efficiency</h3>
        <p className="text-gray-700">
          We are committed to maximizing productivity while minimizing resource waste, 
          ensuring that every project is completed on time, within budget, 
          and with exceptional quality.
        </p>
      </div>

      {/* 6. Sustainability */}
      <div className="flex flex-col items-start">
        <div className="w-12 h-12 mb-4 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
            alt="Sustainability Icon"
            className="w-full h-full object-cover"
          />
        </div>
        <h3 className="text-lg font-semibold mb-2">Sustainability</h3>
        <p className="text-gray-700">
          This is at the core of our mission. We believe in creating solutions 
          that not only address present challenges but also preserve 
          resources for future generations.
        </p>
      </div>
    </div>
  </div>
</div>


<section className="bg-white py-20">
  <div className="max-w-7xl mx-auto px-6 lg:px-12">
    <h2 className="text-3xl font-bold text-center mb-6">
      What Makes us Different
    </h2>
    <p className="text-center text-gray-600 mb-12 leading-relaxed max-w-4xl mx-auto">
      Seismic has a number of internal core ethics, which support our vision 
      and shape our organizational culture.
    </p>

    {/* First Row: 3 Cards */}
    <div className="grid md:grid-cols-3 gap-8">
      {/* 1 */}
      <div className="border border-gray-200 p-6 text-center">
        <h3 className="text-lg font-medium mb-4">
          Global Expertise with Local Insight
        </h3>
        <p className="text-gray-600 text-sm leading-relaxed">
          Our ability to work across multiple regions, including Africa 
          and the Middle East, allows us to tailor solutions that address 
          local contexts while drawing from global standards.
        </p>
      </div>

      {/* 2 */}
      <div className="border border-gray-200 p-6 text-center">
        <h3 className="text-lg font-medium mb-4">
          Holistic Approach to Consulting
        </h3>
        <p className="text-gray-600 text-sm leading-relaxed">
          Our comprehensive approach allows us to tackle complex challenges 
          from multiple angles, ensuring sustainable results with 
          end-to-end solutions across various sectors.
        </p>
      </div>

      {/* 3 */}
      <div className="border border-gray-200 p-6 text-center">
        <h3 className="text-lg font-medium mb-4">
          Innovative and Technology-Driven Solutions
        </h3>
        <p className="text-gray-600 text-sm leading-relaxed">
          Seismic is at the forefront of integrating cutting-edge technologies 
          like AI, data analytics, and digital transformation 
          into our consulting practices.
        </p>
      </div>
    </div>

    {/* Second Row: 2 Cards (centered) */}
    <div className="mt-12 flex flex-col md:flex-row justify-center items-center md:space-x-8 space-y-8 md:space-y-0">
      {/* 4 */}
      <div className="border border-gray-200 p-6 text-center flex-none w-80">
        <h3 className="text-lg font-medium mb-4">Deep Social Impact</h3>
        <p className="text-gray-600 text-sm leading-relaxed">
          Our commitment goes beyond just business success. We aim to foster 
          social change and drive development in sectors that have 
          a tangible impact on communities.
        </p>
      </div>

      {/* 5 */}
      <div className="border border-gray-200 p-6 text-center flex-none w-80">
        <h3 className="text-lg font-medium mb-4">Proven Track Record</h3>
        <p className="text-gray-600 text-sm leading-relaxed">
          Our track record, combined with our deep understanding of complex 
          international projects, enables us to guide clients through 
          the most demanding initiatives.
        </p>
      </div>
    </div>
  </div>
</section>


    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const PublicFinancePolicyAssessment = () => {
  return (
    <div className="w-full bg-white">
<section
  className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
  style={{ backgroundImage: "url('/PagesImages/pfpa.jpg')" }}
>
  <div className="absolute inset-0 bg-black bg-opacity-30"></div>

  {/* Title Overlay for Large Screens */}
  <div
    className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
  >
    <h1 className="text-3xl font-bold text-gray-800 mt-4">
      Public Finance Policy Assessment and Formulation
    </h1>
  </div>

</section>

      {/* Foreword Section */}
      <section className="max-w-4xl mx-auto py-16 px-6 lg:px-0">

  {/* Title Below Image for Small Screens */}
  <div className="block lg:hidden mt-4 text-left">
    <h1 className="text-2xl font-bold text-gray-800">
      Public Finance Policy Assessment and Formulation
    </h1>
  </div>
        <p className="text-lg text-gray-700 mb-8 mt-8">
        We are dedicated to helping governments and public institutions achieve fiscal stability and promote economic growth through comprehensive public finance policy assessment and formulation services. Our global leadership in Public Financial Management (PFM) is reflected in our ability to deliver tailored policy solutions that respond to the evolving needs of economies at all stages of development.
        </p>
        <div className="flex flex-col lg:flex-row items-start gap-8 mt-20">
          <img
            src="/PagesImages/public_finance_small.jpg" // Replace with the correct small image path
            alt="Public Finance Innovation"
            className="w-full lg:w-5/12 object-cover"
          />
          <p className="text-lg text-gray-700 lg:w-7/12 mt-4">
            Seismic combines technical knowledge with strategic insights to ensure that public finance policies contribute to broad-based economic development.
          </p>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16">
        <div className="max-w-4xl mx-auto px-6 lg:px-0">
          <h2 className="text-3xl font-semibold mb-8">Our Approach</h2>
          <ul className="space-y-8">
            {[
              {
                title: "Expert Support for Comprehensive Policy Evaluation",
                description:
                  "Seismic brings a wealth of experience in evaluating public finance policies using internationally recognized frameworks, such as the Public Expenditure and Financial Accountability (PEFA) assessment tool. This robust, evidence-based framework enables us to provide governments with a clear understanding of their current financial management practices, highlighting areas of strength and identifying critical weaknesses that may hinder fiscal performance. Our assessments provide the foundation for strategic reforms across budgeting, revenue collection, expenditure management, debt sustainability, and audit practices.",
              },
              {
                title: "Guiding Policy Reforms for Fiscal Stability",
                description:
                  "At Seismic, we understand that sound financial policy is the bedrock of fiscal stability. Using insights from our assessments, we help governments formulate and implement resilient, transparent, and effective policies for managing public finances. We work closely with finance officials, development partners, and stakeholders to design policies that enhance resource allocation, debt management, expenditure control, and revenue mobilization. Our goal is to strengthen fiscal governance systems, aligning public financial policies with macroeconomic objectives of stability, growth, and social equity.",
              },
              {
                title: "Driving Economic Growth through Thoughtful Policy Formulation",
                description:
                  "Beyond achieving fiscal stability, Seismic's approach to public finance policy formulation is geared toward fostering sustainable economic growth. We believe that well-formulated financial policies are essential for creating an enabling environment for investment, innovation, and job creation. From reforming tax policies to streamlining expenditure frameworks and improving fiscal transparency, Seismic's public finance solutions are designed to optimize public spending and unlock the full potential of government resources for growth-enhancing activities.",
              },
              {
                title: "Global Expertise, Local Solutions",
                description:
                  "Seismic's global presence and vast experience enable us to navigate diverse economic landscapes and governance structures. We provide customized policy advice that reflects local realities, ensuring that reform strategies are feasible, practical, and fiscally sound. Our policy formulation services integrate international best practices, giving governments the advantage of global knowledge while building systems relevant to their unique contexts.",
              },
              {
                title: "Building Transparent and Accountable Financial Systems",
                description:
                  "At the core of Seismic's work is the promotion of transparency and accountability in public financial management. Effective public finance policies enhance trust from citizens, development partners, and international financial institutions in a government's ability to manage resources responsibly. Through our policy assessment and formulation services, Seismic is committed to building stronger, more accountable financial systems worldwide.",
              },
            ].map((approach, index) => (
              <li key={index} className="flex gap-4">
                <span className="text-purple-900 text-2xl">▶</span>
                <div className="text-lg font-semibold">
                  {approach.title}: <span className="font-normal text-gray-700">{approach.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
{/* Other Services Section */}
<section className="py-16">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-gray-800 mb-8">Other Services</h2>
    <p className="text-xl text-gray-600 mb-8">
      Helping clients bridge the fundamental gaps in the education system
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[
        {
          title: "Audit and Financial Control Systems",
          description:
            "Seismic offers a comprehensive suite of audit and financial control solutions that strengthen transparency, accountability, and governance within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.afcs}`,
        },
        {
          title: "Capacity Building and Training",
          description:
            "Our training initiatives emphasize collaboration and peer learning, fostering continuous improvement within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cbt}`,
        },
        {
          title: "Capital Investment Planning",
          description:
            "We help governments and institutions develop strategic capital investment plans aligned with national development goals, promoting economic growth and resource optimization.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cip}`,
        },
      ].map((service, index) => (
        <Link
          to={service.link}
          key={index}
          className="block bg-white p-6 shadow-md rounded-md text-left max-w-[450px] mx-auto"
        >
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            {service.title}
          </h3>
          <p className="text-lg text-gray-600 mb-4 ">
            {service.description}
          </p>
        </Link>
      ))}
    </div>
  </div>
</section>



    </div>
  );
};

export default PublicFinancePolicyAssessment;

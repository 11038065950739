import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';
import { projectsData } from '../../data/projectsData';

const AllEducationProjects = () => {
  return (
    <section className="bg-white mt-20">
{/* Hero Section */}
<div className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center"
  style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1734357866/pexels-weadd-127254644-10043511_irnc7o.jpg')" }}
>
  <div
    className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 bg-black bg-opacity-20 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]"
  >
    <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
      Our Track Record
    </h1>
    <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
      Our Education Project demonstrates our commitment to delivering transformative outcomes across the entire educational ecosystem.
    </p>
  </div>
  </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto py-16 px-4 lg:px-2">
        <div className="flex justify-between items-center mb-8 border-b border-gray-300 pb-4">
          <h3 className="text-2xl font-bold text-gray-800">All Projects</h3>
          {/* Placeholder for Browse Dropdowns */}
          <div className="flex space-x-4">
            <select className="border p-2 rounded">
              <option>Industries</option>
            </select>
            <select className="border p-2 rounded">
              <option>Services</option>
            </select>
          </div>
        </div>

        {/* Categories */}
        {Object.keys(projectsData).map((category, index) => (
          <div key={index} className="mb-12">
            <h4 className="text-xl font-semibold text-gray-800 mb-4">{category}</h4>

            {/* Grid Layout */}
            {index === 0 || index === 2 ? ( // Rows with three columns
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 border-b border-gray-300 pb-6 mb-6">
                {projectsData[category].map((project, idx) => (
                  <Link
                  to={`/${paths.consultingServices}/${paths.educationReform}/${paths.allEducationProjects}/${project.id}`}
  key={idx}
  className="bg-white overflow-hidden"
                  >
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-[220px] object-cover"
                    />
                    <div className="p-4">
                      <h5 className="text-lg font-bold text-gray-800">{project.title}</h5>
                      <p className="text-gray-600 text-sm">
                        {project.description.length > 100
                          ? `${project.description.substring(0, 100)}...`
                          : project.description}
                      </p>
                      {project.year && <p className="text-gray-500 text-xs mt-2">{project.year}</p>}
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              // Rows with two wider columns
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 border-b border-gray-300 pb-6 mb-6">
                {projectsData[category].map((project, idx) => (
                  <Link
                    to={`/${paths.consultingServices}/${paths.educationReform}/${paths.allEducationProjects}/${project.id}`}
                    key={idx}
                    className="bg-white overflow-hidden"
                  >
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-[260px] object-cover"
                    />
                    <div className="p-4">
                      <h5 className="text-lg font-bold text-gray-800">{project.title}</h5>
                      <p className="text-gray-600 text-sm">
                        {project.description.length > 100
                          ? `${project.description.substring(0, 100)}...`
                          : project.description}
                      </p>
                      {project.year && <p className="text-gray-500 text-xs mt-2">{project.year}</p>}
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}

        {/* Pagination */}
        <div className="flex justify-center items-center mt-8 space-x-2">
          <button className="p-2 bg-gray-200 rounded">&lt;</button>
          <button className="p-2 bg-purple-800 text-white rounded">1</button>
          <button className="p-2 bg-gray-200 rounded">2</button>
          <button className="p-2 bg-gray-200 rounded">3</button>
          <button className="p-2 bg-gray-200 rounded">4</button>
          <button className="p-2 bg-gray-200 rounded">&gt;</button>
        </div>
      </div>
    </section>
  );
};

export default AllEducationProjects;

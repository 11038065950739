import React from "react";

const ComprehensiveNeedsAssessment = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="bg-white pb-16 mt-20">
        <section
          className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736851836/pexels-tima-miroshnichenko-6474494_nbhrkk.jpg')", // Replace with your actual image URL
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          {/* Title Overlay for Large Screens */}
          <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
            <h1 className="text-3xl font-bold text-gray-800">
              Comprehensive Needs Assessment and Analysis
            </h1>
          </div>
        </section>

        {/* Title Under Image for Small Screens */}
        <div className="block lg:hidden mt-4">
          <h1 className="text-2xl font-bold text-gray-800 text-center">
            Comprehensive Needs Assessment and Analysis
          </h1>
        </div>
      </section>

      {/* Context Section */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed mb-6">
          Seismic begins every project with an in-depth assessment of the target
          community's needs, challenges, and unique socioeconomic conditions. By analyzing demographic data, economic trends, and social indicators, we tailor our social protection strategies to address root causes of poverty specific to each region.
        </p>
        <p className="text-xl text-gray-600 leading-relaxed">
          At Seismic Consulting Group, we believe that effective social protection and poverty reduction strategies begin with a thorough understanding of the communities we aim to serve. Comprehensive Needs Assessment and Analysis is the cornerstone of our approach, ensuring that every intervention is both relevant and impactful.
        </p>
      </section>

      {/* How We Execute Section */}
      <section className="py-8 px-8 lg:px-64">
        <h2 className="text-2xl lg:text-3xl font-medium text-gray-800 mb-6">
          Here's how we execute this critical phase:
        </h2>
        <div className="space-y-10">
          {/* Holistic Community Profiling */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Holistic Community Profiling
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              We gather detailed data on the target community's demographics,
              economic conditions, cultural norms, and social structures. This
              includes understanding income levels, employment trends, access
              to basic services, and prevailing vulnerabilities. This profiling
              helps us paint a complete picture of the community's current
              state.
            </p>
          </div>

          {/* Data-Driven Insights */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Data-Driven Insights
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              By analyzing key social and economic indicators such as poverty
              rates, education levels, health statistics, and housing
              conditions, we identify systemic challenges and trends. This
              data-driven approach allows us to pinpoint the root causes of
              poverty rather than just addressing surface-level symptoms.
            </p>
          </div>

          {/* Customized Strategy Development */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Customized Strategy Development
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Once data is collected and analyzed, we tailor social protection
              programs to address the community's specific needs. This could
              involve designing interventions to improve access to education,
              healthcare, livelihood opportunities, or social safety nets,
              based on identified gaps and opportunities.
            </p>
          </div>

          {/* Sustainability and Resilience */}
          <div>
            <h3 className="text-2xl font-medium text-gray-800 mb-2">
              Sustainability and Resilience
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed">
              Our assessments consider long-term sustainability and resilience.
              By understanding the underlying causes of vulnerabilities, we
              create programs that empower communities to thrive independently,
              reducing dependency over time.
            </p>
          </div>
        </div>
      </section>

      {/* Conclusion */}
      <section className="py-8 px-8 lg:px-64">
        <p className="text-xl text-gray-600 leading-relaxed">
          Seismic's Comprehensive Needs Assessment and Analysis ensures that our
          social protection and poverty reduction initiatives are not
          one-size-fits-all solutions but are finely tuned to the unique
          realities of each community we serve. This approach maximizes impact
          and fosters sustainable progress.
        </p>
      </section>

      {/* Seismic's Approach Section */}
<section className="py-16 px-4 lg:px-20">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Seismic's Approach to Reducing Poverty
    </h2>
    <p className="text-lg text-gray-600 text-center mb-10">
      Our approach combines rigorous analysis with creative problem-solving
      to address the root causes of poverty and inequality.
    </p>


    {/* Row 1 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 1 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Innovative Financing Mechanisms
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Recognizing the financial challenges in funding large-scale social
            protection programs, Seismic leverages innovative financing models such
            as social impact bonds, micro-loans, and public-private partnerships.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/innovative-financing-mechanisms"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 2 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851169/pexels-weekendplayer-187041_voljsh.jpg"
          alt="Innovative Financing Mechanisms"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-1">
              Data-Driven Targeting and Predictive Analytics
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              In the modern landscape of social protection and poverty reduction,
              traditional methods identifying and reaching vulnerable populations
              can be inefficient, often leading to misallocation of resources.
            </p>
          </div>
          <a
            href="/consulting-services/social-protection/data-driven-targeting"
            className="text-purple-700 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>

    {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Community Empowerment and Capacity Building
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Sustainable poverty reduction requires building the capacity of
            communities to manage and lead their own social protection initiatives.
            Seismic provides training, resources, and guidance to empower local
            leaders and organizations, fostering a culture of self-reliance and
            social equity.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/community-empowerment"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Monitoring, Evaluation, and Adaptive Learning
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Seismic uses adaptive learning to refine and improve social protection
            programs over time, ensuring that each initiative remains responsive to
            evolving community needs and delivers measurable results.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/monitoring-evaluation"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default ComprehensiveNeedsAssessment;

import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";
import TrackRecordSection from "./TrackRecordSection";
import KeyAreasOfService from "./KeyAreasOfService";

const SocialProtectionPovertyReduction = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
        style={{ backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/q_auto,f_auto/v1736853319/pexels-pixabay-280221_x97nzd.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Hero Content */}
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-10">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Social Protection and Poverty Reduction
          </h1>
          <p className="text-base lg:text-lg text-white mt-4 lg:mt-6">
            Envision a world where social equity is both a goal and a reality.
          </p>
        </div>
      </section>

            {/* Title Under Image for Small Screens */}
            <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Social Protection and Poverty Reduction
        </h1>
      </div>

      {/* Introduction Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-12">
        <p className="text-lg lg:text-xl text-gray-600 leading-relaxed">
          Envision a world where social equity is both a goal and a
          reality—Seismic proffer this harmonious balance. Guided by this
          vision, we are dedicated to transforming aspirations into actionable
          outcomes. At Seismic Consulting, we believe that fairness and
          opportunity are universal, hence, we work tirelessly to attain
          solutions. Through our expertise in social protection, we design and
          implement systems that uplift the vulnerable, empower communities, and
          ensure that equity is not just an ideal, but a lived experience for
          all. At Seismic our approach to social protection and poverty
          reduction is grounded in the belief that true change requires more
          than temporary solutions—it demands transformative, forward-thinking
          strategies that address the underlying drivers of poverty and
          inequality.
        </p>
        <p className="text-lg lg:text-xl text-gray-600 leading-relaxed mt-4">
          We design and implement integrated frameworks that empower communities
          to move beyond survival and toward sustainable growth. By combining
          rigorous analysis with inventive problem-solving, we pinpoint root
          issues, unlocking pathways for inclusive development and economic
          resilience. Our solutions are not just programs; they are lifelines
          that create lasting social impact, equipping communities with the
          tools they need to break cycles of poverty and uplift future
          generations.
        </p>
      </div>


{/* Seismic's Approach Section */}
<section className="py-16 px-4 lg:px-20">
  <div className="max-w-[1200px] mx-auto">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Seismic's Approach to Reducing Poverty
    </h2>
    <p className="text-lg text-gray-600 text-center mb-10 max-w-3xl mx-auto">
      Our approach combines rigorous analysis with creative problem-solving
      to address the root causes of poverty and inequality.
    </p>

    {/* Row 1 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 1 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738672918/WhatsApp_Image_2025-02-04_at_13.40.18_jlz7m6.jpg"
          alt="Comprehensive Needs Assessment"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-2">
              Comprehensive Needs Assessment and Analysis
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              By analyzing demographic data, economic trends, and social indicators,
              we tailor our social protection strategies to address root causes of
              poverty specific to each region.
            </p>
          </div>
          <a
            href="/consulting-services/social-protection/comprehensive-needs-assessment"
            className="text-purple-900 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>

      {/* Box 2 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Integrated Social Protection Frameworks
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            We create holistic social protection frameworks that integrate safety
            nets, job creation initiatives, and targeted social programs. Our
            frameworks combine interventions into a cohesive support system.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/integrated-social-protection"
          className="text-purple-900 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>

    {/* Row 2 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8 mb-8">
      {/* Box 3 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[503px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Innovative Financing Mechanisms
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Recognizing the financial challenges in funding large-scale social
            protection programs, Seismic leverages innovative financing models such
            as social impact bonds, micro-loans, and public-private partnerships.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/innovative-financing-mechanisms"
          className="text-purple-900 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 4 - Image and Text */}
      <div className="flex flex-col lg:flex-row border border-gray-200 shadow-md w-full lg:w-[713px] h-auto lg:h-[280px]">
        <img
          src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736851169/pexels-weekendplayer-187041_voljsh.jpg"
          alt="Innovative Financing Mechanisms"
          className="w-full lg:w-[50%] h-[200px] lg:h-full object-cover"
        />
        <div className="p-4 lg:p-6 flex flex-col justify-between w-full">
          <div>
            <h3 className="text-lg font-bold mb-1">
              Data-Driven Targeting and Predictive Analytics
            </h3>
            <p className="text-gray-600 text-md leading-relaxed">
              In the modern landscape of social protection and poverty reduction,
              traditional methods identifying and reaching vulnerable populations
              can be inefficient, often leading to misallocation of resources.
            </p>
          </div>
          <a
            href="/consulting-services/social-protection/data-driven-targeting"
            className="text-purple-900 font-bold text-md flex items-center mt-2"
          >
            Learn More
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>

    {/* Row 3 */}
    <div className="flex flex-wrap lg:flex-nowrap gap-8">
      {/* Box 5 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Community Empowerment and Capacity Building
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Sustainable poverty reduction requires building the capacity of
            communities to manage and lead their own social protection initiatives.
            Seismic provides training, resources, and guidance to empower local
            leaders and organizations, fostering a culture of self-reliance and
            social equity.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/community-empowerment"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>

      {/* Box 6 - Text Only */}
      <div className="border border-gray-200 p-4 lg:p-6 flex flex-col justify-between shadow-md w-full lg:w-[608px] h-auto lg:h-[280px]">
        <div>
          <h3 className="text-lg font-bold mb-2">
            Monitoring, Evaluation and Adaptive Learning
          </h3>
          <p className="text-gray-600 text-lg leading-relaxed">
            Seismic uses adaptive learning to refine and improve social protection
            programs over time, ensuring that each initiative remains responsive to
            evolving community needs and delivers measurable results.
          </p>
        </div>
        <a
          href="/consulting-services/social-protection/monitoring-evaluation"
          className="text-purple-700 font-bold text-md flex items-center mt-2"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</section>


<KeyAreasOfService />


{/* Target Audience Section */}
<div className="bg-gradient-to-b from-[#2D002D] to-[#600060] text-white py-16">
  <div className="max-w-7xl mx-auto px-4 lg:px-8">
    <h2 className="text-2xl lg:text-3xl font-bold text-center mb-6">
      Target Audience
    </h2>
    <p className="text-lg text-center mb-10">
      Seismic's social protection and poverty reduction services are ideal for:
    </p>

    {/* Single Row for Audience Items */}
    <div className="flex flex-wrap justify-between items-start gap-8">
      {/* Audience Item 1 */}
      <div className="text-center w-full sm:w-[20%]">
        <div className="w-16 h-16 mx-auto mb-4">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736764835/library_7953870_vrjebo.png"
            alt="Government Agencies"
            className="w-full h-full object-contain"
          />
        </div>
        <h3 className="text-md font-bold mb-4">Government Agencies</h3>
        <p className="text-sm lg:text-base">
          National and local governments seeking to implement large-scale social
          protection programs with impactful results.
        </p>
      </div>

      {/* Audience Item 2 */}
      <div className="text-center w-full sm:w-[20%]">
        <div className="w-16 h-16 mx-auto mb-4">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765308/ocean_7365098_itzra9.png"
            alt="International Development Organizations"
            className="w-full h-full object-contain"
          />
        </div>
        <h3 className="text-md font-bold mb-4">
          International Development Organizations
        </h3>
        <p className="text-sm lg:text-base">
          NGOs and international agencies looking to partner with experienced
          consultants for effective poverty reduction initiatives.
        </p>
      </div>

      {/* Audience Item 3 */}
      <div className="text-center w-full sm:w-[20%]">
        <div className="w-16 h-16 mx-auto mb-4">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765436/network_7273327_dkhnzz.png"
            alt="Multilateral Financial Institutions"
            className="w-full h-full object-contain"
          />
        </div>
        <h3 className="text-md font-bold mb-4">
          Multilateral Financial Institutions
        </h3>
        <p className="text-sm lg:text-base">
          Institutions like the World Bank and regional development banks that
          fund social protection projects and are looking for strategic partners
          to ensure success.
        </p>
      </div>

      {/* Audience Item 4 */}
      <div className="text-center w-full sm:w-[20%]">
        <div className="w-16 h-16 mx-auto mb-4">
          <img
            src="https://res.cloudinary.com/dxhx45ing/image/upload/v1736765499/search_7501627_llk7w8.png"
            alt="Corporate Social Responsibility Programs"
            className="w-full h-full object-contain"
          />
        </div>
        <h3 className="text-md font-bold mb-4">
          Corporate Social Responsibility Programs
        </h3>
        <p className="text-sm lg:text-base">
          Corporations aiming to fulfill their CSR obligations through
          sustainable social protection initiatives that align with their
          values.
        </p>
      </div>
    </div>
  </div>
</div>

<TrackRecordSection />

{/* Empowered Communities Section */}
<section className="flex flex-wrap lg:flex-nowrap items-stretch">
  {/* Text Section */}
  <div className="w-full lg:w-1/2 bg-gradient-to-b from-[#2D002D] to-[#600060] text-white p-12 flex items-center">
    <div>
      <h2 className="text-3xl font-bold mb-6 leading-tight">
        Empowering Communities and Creating Opportunities That Last
      </h2>
      <p className="text-lg leading-relaxed mb-4">
        At Seismic Consulting, we believe that lasting social impact is built on a foundation of data-driven strategies, innovative financing, and community empowerment. Our social protection and poverty reduction services go beyond traditional models, creating solutions that address immediate needs while laying the groundwork for long-term resilience.
      </p>
      <p className="text-lg leading-relaxed">
        We partner with clients to unlock the potential of every community, fostering an environment where social equity and economic opportunity can thrive.
      </p>
    </div>
  </div>

  {/* Image Section */}
  <div className="w-full lg:w-1/2 flex items-stretch">
    <img
      src="https://res.cloudinary.com/dxhx45ing/image/upload/v1738673558/WhatsApp_Image_2025-02-03_at_23.15.19_jgkjlz.jpg"
      alt="Empowered Communities"
      className="w-full h-full object-cover"
    />
  </div>
</section>

{/* Digital Projects Introduction for Public Sector */}
<div className="max-w-7xl mx-auto px-6 lg:px-12 mt-20 mb-12">
  <div className="flex flex-col md:flex-row items-center gap-8">
    {/* Left Column: Image */}
    <div className="w-full md:w-1/2">
      <img
        src="https://res.cloudinary.com/dxhx45ing/image/upload/v1739189708/WhatsApp_Image_2025-02-05_at_14.48.29_1_ddsdtf.jpg"
        alt="Public Sector Projects"
        className="w-full rounded-lg shadow-md"
      />
    </div>
    {/* Right Column: Text Content */}
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        Empowering Public Sector Transformation
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        Our digital projects in the public sector focus on driving social protection and poverty reduction. Leveraging innovative technologies and data-driven insights, we enable government agencies to enhance service delivery, foster accountability, and create targeted solutions for vulnerable communities.
      </p>
      <Link
        to={`/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.publicSector}`}
        className="inline-flex items-center px-6 py-3 border border-purple-900 text-purple-900 font-semibold rounded-lg hover:bg-purple-50 transition"
      >
        View Public Sector Projects
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  </div>
</div>


{/* Our Social Protection Experts Section
<section className="py-16 px-6 lg:px-12 max-w-7xl mx-auto">
  <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
    Our Social Protection Experts
  </h2>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
    {[
          {
            name: "",
            role: "",
            image: "",
          },
     {
      name: "Tara McCourt",
      role: "Board Member, Expert Education Reform",
      image: "",
    },
    {
      name: "Dr. Olusoji Adeniyi",
      role: "Senior Partner, Expert Education & Capacity Building ",
      image: "",
    },
    ].map((expert, index) => (
      <div key={index} className="shadow-lg rounded-lg overflow-hidden text-left bg-white">
        <img
          src={expert.image}
          alt={expert.name}
          className="w-full h-[300px] object-cover"
        />
        <div className="p-4">
          <h3 className="text-xl font-bold text-gray-900">{expert.name}</h3>
          <p className="text-gray-600 mt-1">{expert.role}</p>
        </div>
      </div>
    ))}
  </div>
</section> */}

    </div>
  );
};

export default SocialProtectionPovertyReduction;

import React from 'react';

const ClimateRiskAndResiliencePlanning = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-20"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1737711289/free-photo-of-dramatic-nighttime-forest-fire-in-california_dd5hca.jpg')",
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white mt-4 lg:mt-12">
            Climate Risk and Resilience Planning
          </h1>
        </div>
      </section>

      {/* Introduction Section */}
      <div className="mt-16 mb-12 max-w-6xl mx-auto px-6 lg:px-20">
        <p className="text-gray-700 text-lg mb-4">
          As the impacts of climate change intensify, organizations face an increasing need to address not just
          environmental sustainability but also the resilience of their operations, infrastructure, and supply
          chains. Climate-related risks such as extreme weather events, rising sea levels, temperature
          fluctuations, and shifting regulatory landscapes can have far-reaching consequences for businesses
          across industries. At Seismic Consulting Group, we provide actionable strategies that help
          organizations identify, assess, and mitigate these risks, ensuring that they remain resilient, adaptable,
          and well-positioned for long-term success in an era of uncertainty.
        </p>
      </div>

      {/* Main Content Section */}
      <div className="py-16 max-w-6xl mx-auto px-6 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Understanding Climate Risks
        </h2>
        <p className="text-gray-700 text-lg text-left mb-6">
          The first step in climate resilience planning is to understand the specific climate risks that could
          impact an organization. These risks vary significantly depending on geographic location, industry,
          and operational model. They can include:
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Transition Risks</h4>
            <p className="text-gray-700">
              These risks are related to the shift toward a low-carbon economy, which can lead to regulatory changes, evolving market demands, and technological disruptions. Examples include increased carbon taxes, evolving environmental regulations, and shifting consumer preferences toward sustainable products.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Physical Risks</h4>
            <p className="text-gray-700">
              These include both acute risks, such as extreme weather events (hurricanes, floods, wildfires, etc.), and chronic risks, such as rising temperatures, sea-level rise, and changing precipitation patterns.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Social and Economic Risks</h4>
            <p className="text-gray-700">
              Climate change also has significant social and economic consequences, such as supply chain disruptions, labor force impacts, and the growing need for sustainable business practices.
            </p>
          </div>
        </div>
        <p className="text-gray-700 text-lg text-left mb-6 mt-6">
          We work with organizations to assess these risks in the context of their specific operations, supply
          chains, and geographical locations, helping to identify vulnerabilities and opportunities for
          improvement.
        </p>

        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">
          Risk Assessment and Impact Analysis
        </h2>
        <p className="text-gray-700 text-lg text-left mb-6">
          Effective resilience planning requires a comprehensive and data-driven approach to risk assessment.
          We conduct detailed climate risk assessments that include:
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Scenario Analysis</h4>
            <p className="text-gray-700">
              We evaluate how different climate change scenarios—such as various degrees of global warming, sea-level rise, and shifting weather patterns—could impact your organization. This helps identify potential disruptions, such as infrastructure damage, operational slowdowns, or supply chain bottlenecks.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Vulnerability Mapping</h4>
            <p className="text-gray-700">
              We work with organizations to map out their vulnerabilities, identifying assets, resources, and operations most at risk from climate impacts. This could include physical infrastructure, such as buildings or transportation networks, as well as intangible assets like intellectual property, talent, and market access.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Financial Impact Modeling</h4>
            <p className="text-gray-700">
              Our team uses advanced modeling techniques to quantify the financial impacts of potential climate risks. This allows organizations to better understand the economic costs of disruptions and make informed decisions about investments in resilience.
            </p>
          </div>
        </div>

        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6 mt-12">
          Mitigation and Adaptation Strategies
        </h2>
        <p className="text-gray-700 text-lg text-left mb-6">
          Once risks have been identified and assessed, the next step is to develop strategies for mitigation
          (reducing the likelihood and severity of risks) and adaptation (adjusting operations to cope with
          inevitable climate impacts). These strategies ensure that organizations can continue to function
          effectively in the face of climate challenges.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">

          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Infrastructure Adaptation</h4>
            <p className="text-gray-700">
              We work with organizations to plan and implement infrastructure changes that increase resilience to climate impacts. This could involve elevating buildings in flood-prone areas, retrofitting structures to withstand extreme weather conditions, or investing in climate-resistant materials. We also consider integrating green infrastructure solutions, such as urban green spaces, permeable pavements, and rainwater harvesting systems, to reduce vulnerability to climate change.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Supply Chain Resilience</h4>
            <p className="text-gray-700">
              We help businesses build climate-resilient supply chains by diversifying suppliers, evaluating transportation routes for vulnerability, and ensuring that suppliers themselves are prepared for climate risks. Additionally, we help identify alternative materials and sources of production that can reduce exposure to climate-related disruptions.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Water and Resource Management</h4>
            <p className="text-gray-700">
              In regions facing increasing water stress or other resource constraints, we develop strategies to ensure efficient water use, rainwater harvesting, and resource conservation. This could also include improving energy efficiency to reduce reliance on energy grids vulnerable to climate disruptions.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Disaster Preparedness and Response Plans</h4>
            <p className="text-gray-700">
              We assist organizations in developing disaster preparedness plans that outline clear, actionable steps to respond to extreme weather events and other climate-related crises. This includes creating emergency response protocols, establishing crisis communication plans, and ensuring business continuity during periods of disruption.
            </p>
          </div>
        </div>

        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6 mt-12">
          Climate-Resilient Business Operations
        </h2>
        <p className="text-gray-700 text-lg text-left mb-6">
          To ensure long-term resilience, we help organizations integrate climate considerations into their core
          business operations. This involves:
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Resilient Business Models</h4>
            <p className="text-gray-700">
              We work with businesses to build models that incorporate climate risks into their strategic and operational planning. This includes adopting sustainable sourcing practices, reducing reliance on vulnerable resources, and exploring low-carbon technologies to future-proof operations.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Workforce Resilience</h4>
            <p className="text-gray-700">
              A resilient workforce is key to an organization’s ability to withstand climate disruptions. We assist in developing training programs that help employees understand climate risks and develop skills to adapt to changing conditions. This could also involve revising workplace policies to ensure that employees have the flexibility and support they need during climate-related disruptions.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Climate-Related Financial Disclosures</h4>
            <p className="text-gray-700">
              We guide organizations in adhering to the Task Force on Climate-related Financial Disclosures (TCFD) recommendations, ensuring that they report on their climate risks and resilience efforts transparently. This disclosure not only helps meet regulatory requirements but also boosts investor confidence by showcasing a proactive approach to climate risks.
            </p>
          </div>
        </div>

        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6 mt-12">
          Long-Term Resilience and Sustainable Growth
        </h2>
        <p className="text-gray-700 text-lg text-left mb-6">
          Climate resilience is not just about surviving climate challenges, it’s about thriving in a world that’s
          increasingly defined by environmental uncertainty. Our focus is on ensuring that climate resilience
          becomes an integral part of your long-term growth strategy. We help organizations transition to more
          sustainable business practices that not only mitigate risks but also unlock new opportunities. This
          includes:
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Innovative Technologies</h4>
            <p className="text-gray-700">
              We explore opportunities to integrate climate-smart technologies such as renewable energy, energy-efficient systems, and green building technologies, which reduce vulnerability to climate change while supporting sustainability goals.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Sustainable Investment Strategies</h4>
            <p className="text-gray-700">
              We help businesses identify and invest in long-term projects that build resilience, such as sustainable infrastructure, renewable energy initiatives, and climate-adaptive technologies.
            </p>
          </div>
          <div className="p-4 border rounded-lg bg-purple-50 border-purple-600">
            <h4 className="text-lg font-semibold text-gray-900 mb-2">Market Positioning and Reputation</h4>
            <p className="text-gray-700">
              Organizations that lead in climate resilience are better positioned to differentiate themselves in the marketplace. We assist in positioning your organization as a leader in climate responsibility, which can improve customer loyalty, attract investment, and build brand equity.
            </p>
          </div>
        </div>
      </div>

      {/* The Seismic Advantage Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-4">The Seismic Advantage</h2>
            <p className="text-gray-900 text-lg mb-4">
              Seismic Consulting Group provides a comprehensive suite of climate risk and resilience planning
              services that help organizations not only manage the risks posed by climate change but also turn
              these challenges into opportunities for growth and innovation. We equip businesses with the tools,
              knowledge, and strategies needed to anticipate climate impacts, safeguard their operations, and
              ensure long-term success in an unpredictable world. Through proactive resilience planning, we
              help organizations create robust, adaptive systems that endure and thrive amidst the complexities
              of climate change.
            </p>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dxhx45ing/image/upload/v1737623037/pexels-photo-2869610_drufwi.jpg"
              alt="The Seismic Advantage"
              className="w-full h-[450px] object-cover shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClimateRiskAndResiliencePlanning;
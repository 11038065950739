// Layout.js
import React from 'react';
import Navbar from './Navbar';
import FooterSection from './FooterSection';
import ContactSection from './LandingPageComponents/ContactSection';

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />  {/* Navbar will be at the top of every page */}
      <main>{children}</main> {/* This will be the main content of each page */}
      <ContactSection />
      <FooterSection />  {/* Footer will be at the bottom of every page */}
    </div>
  );
};

export default Layout;

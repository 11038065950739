import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const services = [
    {
      title: 'Cloud and IT Infrastructure Transformation',
      description:
        'In the rapidly evolving digital landscape, a robust and adaptable IT infrastructure is essential for organizations aiming to innovate and stay ahead of the competition. We specialize in transforming your cloud and IT infrastructure to enhance scalability.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739191990/Image_4_ogfiug.png',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.cloudInfrastructurePage}`,
    },
    {
        title: 'Artificial Intelligence and Machine Learning Applications',
        description:
          'Seismic leverages the power of AI and ML to unlock new opportunities for predictive analytics, personalized customer experiences, and process optimization. By integrating these technologies, we help businesses gain competitive advantages and achieve measurable ROI.',
        image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739192045/pexels-photo-2599244_eczs92.jpg',
        link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.aIAndML}`,
      },
    {
        title: 'Blockchain and Distributed Ledger Technology',
        description:
          'From initial assessment and strategy development to implementation and ongoing support, we provide end-to-end services that transform operations and deliver measurable, sustainable results.',
        image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739191921/project_Image_xeq4id.png',
        link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.blockchainTechnologyPage}`,
      },
    {
      title: 'Enhanced Customer Engagement',
      description:
        'By implementing advanced CRM systems integrated with Artificial Intelligence (AI), we anticipate customer needs and preferences, enhancing engagement, satisfaction, and loyalty.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739189703/WhatsApp_Image_2025-02-05_at_14.46.33_mxkyle.jpg',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.enhancedCustomerEngagementPage}`,
    },
    {
      title: 'Agile Enterprise Development',
      description:
        'Modern enterprises operate in a fast-paced environment where flexibility and responsiveness are crucial for success. Our team of experts designs and implements scalable solutions that enhance operational agility and efficiency.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739189711/WhatsApp_Image_2025-02-05_at_14.48.29_dffqqm.jpg',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.agileEnterpriseDevelopmentPage}`,
    },
    {
      title: 'Digital Business Model Innovation',
      description:
        'Digital Business Model Innovation empowers organizations to adapt to changing market dynamics, unlock new revenue streams, and meet evolving customer expectations.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739191791/Image_3_bjfqra.png',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.digitalBusinessModel}`,
    },
    {
      title: 'Intelligent Operations and Smart Manufacturing',
      description:
        'By implementing advanced automation technologies, AI-driven analytics, and IoT, we help manufacturers improve operational efficiency, reduce costs, and create smarter production workflows.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739189708/WhatsApp_Image_2025-02-05_at_14.48.29_1_ddsdtf.jpg',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.intelligentOperations}`,
    },
    {
      title: 'Workforce Enablement and Digital Culture',
      description:
        'Our team helps organizations cultivate a digital-first mindset, providing training and tools that enhance workforce productivity, collaboration, and overall employee experience.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739189700/WhatsApp_Image_2025-02-05_at_14.44.27_1_hlrknl.jpg',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.workforceEnablement}`,
    },
    {
      title: 'Digital Marketing and Brand Transformation',
      description:
        'Leveraging AI-driven insights, automation, and data analytics, we enhance brand positioning, customer targeting, and marketing effectiveness for organizations aiming to maximize their digital reach.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739191529/Image_1_l1gpms.png',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.digitalMarketing}`,
    },
    {
      title: 'Sustainable Digital Solutions',
      description:
        'We help businesses integrate eco-friendly and sustainable digital solutions, aligning technology with environmental and social responsibility goals for long-term impact.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739189709/WhatsApp_Image_2025-02-05_at_14.48.29_2_kqkruf.jpg',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.sustainableDigitalSolutionsPage}`,
    },
    {
      title: 'Governance, Risk, and Compliance (GRC) Transformation',
      description:
        'Our GRC solutions enable businesses to navigate regulatory challenges, implement risk management frameworks, and maintain compliance with industry best practices.',
      image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739189713/WhatsApp_Image_2025-02-05_at_14.48.30_xjalqi.jpg',
      link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.gRCTransformationPage}`,
    },
    {
        title: 'Data-Driven Decision Making',
        description:
          'Our expertise lies in harnessing this invaluable resource to empower our clients with actionable insights and strategic foresight. Through advanced big data analytics, predictive modeling, and machine learning, we enable organizations to make informed decisions that drive growth and innovation.',
        image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739191415/Image_iq9akl.png',
        link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.digitalDriven}`,
      },
      {
        title: 'Process Automation and Operational Efficiency',
        description:
          'Enhancing operational efficiency through intelligent process automation is a cornerstone of our digital transformation services. We leverage advanced technologies to streamline workflows, reduce operational costs, and empower employees to focus on strategic initiatives that drive innovation and growth.',
        image: 'https://res.cloudinary.com/dxhx45ing/image/upload/v1739191591/Image_2_jj6ioc.png',
        link: `/${paths.consultingServices}/${paths.digitalTransformationPage}/${paths.digitalServicesPage}/${paths.processAutomation}`,
      },
      
  ];
  
  

const DigitalServicesPage = () => {
  return (
    <div className="flex flex-col w-full bg-white">
      {/* Hero Section */}

      <section
        className="relative w-full h-[550px] bg-gray-800 text-white flex items-center bg-cover bg-center mt-12"
        style={{
          backgroundImage: "url('')", // Replace with actual background image URL
        }}
      >
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px] bg-black bg-opacity-20">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white">
          Our Expertise in Digital Transformation
          </h1>
        </div>
      </section>

      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-12 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
        Our Expertise in Digital Transformation
        </h1>
      </div>


      {/* Services Grid */}
      <section className="py-12 max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {services.map((service, idx) => (
            <div key={idx} className="bg-white shadow-md rounded-lg overflow-hidden">
              <img src={service.image} alt={service.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{service.title}</h3>
                <p className="text-gray-600 text-sm mb-4">{service.description}</p>
                <Link
                  to={service.link}
                  className="text-purple-800 font-medium text-sm flex items-center hover:text-purple-600 transition"
                >
                  Read More
                  <svg
                    className="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default DigitalServicesPage;

import React from "react";
import { Link } from "react-router-dom";
import MethodologySectionDT from "./MethodologySectionDT";
import { paths } from "../../constants/paths";

const DigitalTransformationCM = () => {
  return (
    <div className="py-16">
      {/* Hero Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1736932864/5_1_ldv5s1.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Title Overlay for Large Screens */}
        <div className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20">
          <h1 className="text-3xl font-bold text-gray-800">
            Digital Transformation
          </h1>
        </div>
      </section>
      {/* Title Under Image for Small Screens */}
      <div className="block lg:hidden mt-4 md:text-center">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Digital Transformation
        </h1>
      </div>

      <div className="max-w-6xl mx-auto px-6 lg:px-12 mt-8 lg:mt-28">
        {/* Context Section */}
        <div className="mb-16">
          <p className="text-gray-600 text-xl leading-relaxed">
            In today’s fast-evolving technological landscape, Digital
            Transformation is not just a competitive advantage—it’s a necessity.
            As part of Seismic’s Change Management & Strategy Consulting, we
            empower organizations to embrace digital innovation, streamline
            operations, and enhance customer experiences. This transformative
            process aligns cutting-edge technology with business objectives,
            fostering agility, resilience, and sustained growth.
          </p>
        </div>

        {/* What We Do Section */}
        <div className="mb-12 pt-8">
          <h2 className="text-2xl font-bold text-center mb-8">What We Do</h2>
          <p className="text-gray-600 text-xl leading-relaxed mb-8">
            Our expertise in digital strategy helps organizations harness the
            power of technology to streamline operations, innovate, and improve
            customer experiences Seismic specializes in helping organizations
            unlock the potential of digital technologies to revolutionize their
            operations and achieve their goals. Our expertise spans multiple
            dimensions of digital strategy:
          </p>
          <ul className="list-none space-y-6 text-lg text-gray-800 leading-relaxed">
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Streamlining Operations:</strong> We
                enable organizations to optimize workflows, reduce
                inefficiencies, and enhance productivity using advanced
                technological tools.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Innovating Solutions:</strong> By
                integrating emerging technologies like artificial intelligence
                (AI), cloud computing, and Internet of Things (IoT), we help
                businesses reimagine their products, services, and processes.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">
                  Enhancing Customer Experiences:
                </strong>{" "}
                Leveraging data analytics and customer insights, we craft
                solutions that offer personalized, seamless, and superior
                customer interactions.
              </div>
            </li>
          </ul>
        </div>

        {/* Our Methodology Section */}
        <MethodologySectionDT />

        {/* Value to Clients Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6 text-gray-900 text-center">
            Value to Clients
          </h2>
          <ul className="list-none space-y-6 text-lg text-gray-800 leading-relaxed">
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Enhance Agility:</strong> By
                adopting adaptable technologies and streamlined processes,
                businesses can respond quickly to changing market dynamics and
                customer demands.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Become Data-Driven:</strong>{" "}
                Actionable insights from advanced analytics drive informed
                decisions that drive better outcomes.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Drive Innovation:</strong>{" "}
                Harnessing emerging technologies allows businesses to innovate
                their products and services, staying ahead of competitors.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">Improve Efficiency:</strong>{" "}
                Automation and process optimization free up resources for
                strategic initiatives.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">
                  Enhance Customer Experiences :
                </strong>{" "}
                Tailored, tech-driven customer interactions foster loyalty and
                satisfaction, strengthening market positioning.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">
                  Enhance Customer Experience :
                </strong>{" "}
                Tailored, tech-driven customer interactions foster loyalty and
                satisfaction, strengthening market positioning.
              </div>
            </li>
            <li className="flex items-start">
              <span
                className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                style={{ backgroundColor: "#F9F1FA" }}
              ></span>
              <div>
                <strong className="text-xl">
                  Ensure Scalability and Sustainability:
                </strong>{" "}
                Scalable solutions ensure the organization remains prepared for
                growth and future technological advancements.
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* Seismic’s expertise in Digital Transformation */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-12 flex flex-wrap lg:flex-nowrap gap-12 items-center">
          {/* Image Section */}
          <div className="flex-shrink-0 w-full lg:w-1/2">
            <img
              src=""
              alt="Why Choose Seismic Consulting"
              className="w-full"
            />
          </div>

          {/* Text Section */}
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl font-medium mb-6">
              Seismic’s expertise in Digital Transformation
            </h2>

            <p className="text-gray-600 text-xl leading-relaxed">
              Seismic’s approach to Digital Transformation is not just about
              implementing technology—it’s about integrating innovation into the
              organization’s DNA. With a focus on tailored solutions, seamless
              execution, and measurable outcomes, we ensure every transformation
              project empowers clients to thrive in the digital era. From
              building smarter processes to delivering exceptional customer
              experiences, Seismic guides organizations on a transformative
              journey toward greater resilience, relevance, and growth.
            </p>
          </div>
        </div>
      </section>

      {/* Related Services Section */}
      <div className="max-w-8xl mx-auto px-6 lg:px-12 mt-8 lg:mt-12 ml-8">
        <h2 className="text-2xl font-bold mb-12 text-center">Seismic’s Approach to Change Management</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {[
                    {
                      title: "Adaptive Strategy Development",
                      description:
                        "In an era defined by constant evolution in market dynamics, regulatory landscapes, and technological innovation, Adaptive Strategy Development is central to Seismic's approach to Change Management & Strategy Consulting.",
                      image:
                        "https://res.cloudinary.com/dxhx45ing/image/upload/v1736871131/4_pnllia.jpg",
                      link: `/consulting-services/${paths.changeManagementPage}/${paths.adaptiveStrategyDevelopment}`,
                    },
{
                title: "Change Management Frameworks",
                description:
                  "Through robust Change Management Frameworks, we help businesses navigate transitions seamlessly, ensuring that people, processes, and systems adapt cohesively to new realities.",
                image:
                  "https://res.cloudinary.com/dxhx45ing/image/upload/v1736933098/9_1_lgkpzt.jpg",
                link: `/consulting-services/${paths.changeManagementPage}/${paths.changeManagementFrameworks}`,
              },
            {
              title: "Capacity Building for Change Leaders",
              description:
                "Equipping leaders with the tools and skills needed to inspire, guide, and sustain change within their organizations.",
              image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1736933370/8_1_q1oige.jpg",
              link: "/consulting-services/change-management-page/capacity-building-for-change-leaders",
            },
          ].map((item, index) => (
            <a
              key={index}
              href={item.link}
              className="block text-left hover:shadow-lg transition-shadow duration-300"
            >
              <img
                src={item.image}
                alt={item.title}
                className="mb-4 w-full h-[300px] object-cover"
              />
              <h3 className="text-lg font-bold mb-2">{item.title}</h3>
              <p className="text-gray-600 text-sm mb-2">{item.description}</p>
              <span className="text-purple-900 font-bold text-sm">Read More</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DigitalTransformationCM;

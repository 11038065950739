import React, { useState, useEffect } from "react";

const projects = [
  {
    id: "seefor-project",
    title: "Track Record: Results-Driven Impact",
    description:
      "With expertise in data-driven strategy, innovative financing, and community empowerment, our work with government and international partners tackles immediate needs while fostering resilience for the future.",
    projectTitle:
      "Technical Assistance in the Implementation of the World Bank-State Employment and Expenditure for Results (SEEFOR) for Bayelsa, Edo, Delta, and Rivers State",
    clientImage:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1733494509/world_bank_v3jcqh.png",
    clientName: "THE WORLD BANK",
    image:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1736851681/needs-assessment-namapa-nampula-mozambique_fpf4rf.jpg",
  },
  {
    id: "mscrp-project",
    title: "Technical Expertise for Crisis Recovery",
    description:
      "We supported multi-sectoral recovery initiatives to restore community infrastructure and services in conflict-affected areas.",
    projectTitle:
      "Technical Assistance in the implementation of the World Bank Multi-Sectoral Crises Recovery Project - Yobe State",
    clientImage:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1733494509/world_bank_v3jcqh.png",
    clientName: "World Bank and State Ministry of Urban and Regional Planning",
    image:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1736848539/p4h_social-protection-health-wellbeing_2024.x23411_wbpxdj.png",
  },
  {
    id: "cct-program",
    title: "Enhancing Social Safety Nets",
    description:
      "We played a pivotal role in implementing Conditional Cash Transfer programs under the MDGs, addressing immediate needs and empowering vulnerable communities.",
    projectTitle:
      "Technical Assistance in the Implementation of the Conditional Cash Transfer Program in Nigeria under the Millennium Development Goals (MDGs)",
    clientImage:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1738229864/phxmkt1kfkpxwk41wcs0.jpg",
    clientName:
      "National Planning Commission",
    image:
      "https://res.cloudinary.com/dxhx45ing/image/upload/v1736846391/pexels-minan1398-853168_rc5ltf.jpg",
  },
];

const TrackRecordSection = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  const currentProject = projects[currentProjectIndex];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="py-16 px-4 lg:px-20 bg-white">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-start gap-8">
        {/* Image */}
        <div className="lg:w-6/12 w-full">
          <img
            src={currentProject.image}
            alt="Project Image"
            className="w-full h-[400px] object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Content */}
        <div className="lg:w-6/12 w-full">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            {currentProject.title}
          </h2>
          <p className="text-lg text-gray-600 mb-6 leading-relaxed">
            {currentProject.description}
          </p>
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              Project
            </h3>
            <div className="border border-purple-200 p-6 rounded-lg">
              <p className="text-lg text-gray-800 font-semibold mb-4">
                {currentProject.projectTitle}
              </p>
              <div className="flex items-center">
                <span className="text-gray-600 font-medium mr-2">Clients:</span>
                <img
                  src={currentProject.clientImage}
                  alt={currentProject.clientName}
                  className="h-8 mr-2"
                />
                <span className="text-gray-600">{currentProject.clientName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dots Navigation */}
      <div className="flex justify-center mt-6">
        {projects.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentProjectIndex(index)}
            className={`transition-all duration-300 ${
              index === currentProjectIndex
                ? "bg-purple-800 w-8 h-3 rounded-lg"
                : "bg-purple-300 w-4 h-4 rounded-full"
            }`}
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>
    </section>
  );
};

export default TrackRecordSection;

import React from "react";

const BlockchainGovernance = () => {
  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <section
        className="w-full h-[500px] bg-cover bg-center flex items-center justify-center text-white"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/dxhx45ing/image/upload/v1739191921/project_Image_xeq4id.png')",
        }}
      >
        <div className="bg-black bg-opacity-50 p-8 rounded-md max-w-4xl text-center mt-32">
          <h1 className="text-4xl font-bold mb-4">
            Enhancing Governance through Blockchain Technology
          </h1>
          <p className="text-lg">
            Transforming public administration with transparency, accountability, and efficiency through blockchain innovation.
          </p>
        </div>
      </section>

      {/* Content Section */}
      <div className="max-w-5xl mx-auto py-16 px-6 lg:px-20 bg-white mt-20">
        <h2 className="text-2xl font-bold mb-6">
          Understanding Blockchain Technology
        </h2>
        <p className="text-gray-700 leading-7 mb-6">
          Blockchain is a decentralized, distributed ledger system that records transactions in a secure and transparent manner. Unlike traditional centralized systems, blockchain operates on a peer-to-peer network, making it nearly tamper-proof due to its cryptographic algorithms and consensus mechanisms.
        </p>

        <img
          src="https://example.com/blockchain-diagram.jpg"
          alt="Blockchain Diagram"
          className="w-full rounded-lg mb-8"
        />

        <h2 className="text-2xl font-bold mb-6">
          Real-World Applications of Blockchain in Governance
        </h2>
        <p className="text-gray-700 leading-7 mb-6">
          Blockchain technology has been successfully implemented in various governance systems worldwide. Here are three notable examples:
        </p>

        {/* Example 1 */}
        <div className="mb-8">
          <h3 className="text-xl font-semibold text-purple-800 mb-4">
            Land Registry in Ghana
          </h3>
          <p className="text-gray-700 leading-7">
            Ghana introduced a blockchain-based land registry system to address land disputes. By digitizing land records, blockchain ensures transparency and reduces fraudulent claims.
          </p>
        </div>

        {/* Example 2 */}
        <div className="mb-8">
          <h3 className="text-xl font-semibold text-purple-800 mb-4">
            Voting Systems in Estonia
          </h3>
          <p className="text-gray-700 leading-7">
            Estonia implemented a blockchain-based e-voting system, enabling citizens to securely vote online. This increased voter turnout and trust in electoral processes.
          </p>
        </div>

        {/* Example 3 */}
        <div className="mb-8">
          <h3 className="text-xl font-semibold text-purple-800 mb-4">
            Combating Corruption in Nigeria
          </h3>
          <p className="text-gray-700 leading-7">
            Nigeria is exploring blockchain for financial transparency and election integrity. A pilot project in Lagos digitized government records, enhancing trust in public services.
          </p>
        </div>

        <h2 className="text-2xl font-bold mb-6">
          The Role of Seismic Consulting
        </h2>
        <p className="text-gray-700 leading-7 mb-6">
          Seismic Consulting leads the way in leveraging blockchain technology to enhance governance systems. Through strategic partnerships, the firm provides tailored solutions that ensure transparency and accountability.
        </p>

        <blockquote className="bg-purple-100 border-l-4 border-purple-800 text-purple-900 p-6 mb-8 rounded-md">
          <p className="italic">
            “Blockchain technology offers a unique opportunity to redefine governance by fostering transparency, accountability, and efficiency.”
          </p>
        </blockquote>

        <h2 className="text-2xl font-bold mb-6">Challenges and the Way Forward</h2>
        <p className="text-gray-700 leading-7 mb-6">
          While blockchain holds immense promise, its implementation comes with challenges such as technical complexity, cost, and resistance to change. Governments must invest in capacity building, collaborate with the private sector, and develop regulatory frameworks to harness blockchain's potential.
        </p>

        <h2 className="text-2xl font-bold mb-6">Conclusion</h2>
        <p className="text-gray-700 leading-7">
          Blockchain technology offers a path to improved governance through transparency and accountability. With Seismic Consulting’s expertise, governments can embrace blockchain innovation to build systems that serve people more effectively.
        </p>
      </div>

    </div>
  );
};

export default BlockchainGovernance;

import React, { useState } from "react";

const MethodologySectionCT = () => {
  const [open, setOpen] = useState({
    workshops: false,
    employeeEngagement: false,
    alignment: false,
  });

  const toggleCollapse = (key) => {
    setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div className="mb-16">
      <h2 className="text-2xl font-bold mb-4 text-center pt-12">Our Methodology</h2>
      <h3 className="text-xl text-center mb-4 text-gray-600">
      Facilitate workshops and leadership coaching to instill a shared vision. Engage employees at all levels to build trust and ensure buy-in. Seismic employs a systematic and inclusive process to ensure cultural transformation is both impactful and sustainable.

      </h3>
      {/* Divider Line */}
      <div className="border-t-2 border-purple-100 w-3/4 mx-auto mb-8 mt-8"></div>

      <div className="max-w-4xl mx-auto space-y-16 mt-12">
        {/* Facilitating Workshops and Leadership Coaching */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("workshops")}
          >
            Facilitating Workshops and Leadership Coaching
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.workshops ? "rotate-180" : ""
              }`}
            >
              {open.workshops ? "-" : "+"}
            </span>
          </button>
          {open.workshops && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Vision-Casting Sessions:</strong> Engaging leadership to articulate and champion a shared vision for the desired organizational culture.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Leadership Development:</strong> Coaching leaders to model behaviors that embody the new culture, as their actions set the tone for the entire organization.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Cultural Awareness Training:</strong> Equipping leaders with tools to identify and address resistance while nurturing inclusivity and collaboration.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Engaging Employees at All Levels */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("employeeEngagement")}
          >
            Engaging Employees at All Levels
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.employeeEngagement ? "rotate-180" : ""
              }`}
            >
              {open.employeeEngagement ? "-" : "+"}
            </span>
          </button>
          {open.employeeEngagement && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Inclusive Dialogue:</strong> Hosting workshops, focus groups, and surveys to gather employee insights, ensuring their voices are integral to the transformation process.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Trust-Building Activities:</strong> Encouraging transparency and open communication to foster trust and commitment to change.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Change Ambassadors:</strong> Identifying and empowering cultural ambassadors within the organization to advocate for and exemplify the desired cultural values.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>

        {/* Aligning Policies and Practices */}
        <div className="pl-4">
          <button
            className="flex justify-between items-center w-full text-left text-2xl font-medium text-gray-900 focus:outline-none"
            onClick={() => toggleCollapse("alignment")}
          >
            Aligning Policies and Practices
            <span
              className={`text-3xl font-bold text-purple-900 ${
                open.alignment ? "rotate-180" : ""
              }`}
            >
              {open.alignment ? "-" : "+"}
            </span>
          </button>
          {open.alignment && (
            <ul className="list-none space-y-6 mt-6 pl-8 text-lg text-gray-800">
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Revising Organizational Norms:</strong> Updating policies, procedures, and reward systems to align with the desired culture.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Behavioral Reinforcement:</strong> Introducing recognition programs and incentives that encourage adherence to new cultural norms.
                </div>
              </li>
              <li className="flex items-start">
                <span
                  className="w-5 h-5 flex-shrink-0 border-2 border-purple-900 rounded-full mt-1 mr-4"
                  style={{ backgroundColor: "#F9F1FA" }}
                ></span>
                <div className="text-lg">
                  <strong className="text-xl">Measuring Progress:</strong> Establishing benchmarks for cultural transformation and using regular assessments to measure progress against goals.
                </div>
              </li>
            </ul>
          )}
          {/* Divider Line */}
          <div className="border-t-2 border-purple-100 mt-8"></div>
        </div>
      </div>
    </div>
  );
};

export default MethodologySectionCT;

import React from "react";

const SustainabilityPlanning = () => {
  return (
    <div className="bg-white">
      {/* Header Section */}
      <section
        className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1440x516')", // Replace with the actual image URL
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="absolute bottom-8 left-4 lg:bottom-16 lg:left-24 p-4 lg:p-8 shadow-lg z-10 w-full lg:w-[600px]">
          <h1 className="text-2xl lg:text-4xl font-semibold text-white">
            Sustainability Planning
          </h1>
          <p className="text-base lg:text-lg text-white mt-4">
            Development of Frameworks for Ongoing Learning and Knowledge
            Retention, and Empowering Organizations to Sustain and Scale Their
            Initiatives Independently.
          </p>
        </div>
      </section>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto px-6 lg:px-12 py-12">
        {/* Introductory Text */}
        <div className="mb-12">
          <p className="text-lg lg:text-xl text-gray-600 leading-relaxed">
            In Seismic’s approach to Capacity Building & Human Resource
            Development, Sustainability Planning ensures that the benefits of
            training and development programs are not short-lived but instead
            lead to enduring transformation within organizations. By focusing on
            long-term impact, Seismic empowers organizations to maintain,
            enhance, and scale their initiatives independently, reducing
            dependency on external support.
          </p>
        </div>

        {/* Key Areas */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Development of Frameworks for Ongoing Learning and Knowledge
            Retention
          </h2>
          <h3 className="text-lg font-semibold text-gray-600 mb-4">
            Seismic creates tailored frameworks that embed continuous learning
            and skill development into an organization’s operational culture.
            These frameworks are designed to ensure that knowledge and expertise
            gained during training are retained, institutionalized, and expanded
            over time. Key elements include:
          </h3>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Knowledge Management Systems (KMS):</strong> Seismic helps
              organizations develop robust KMS that document training materials,
              best practices, and key learnings. These systems serve as
              centralized repositories where employees can access resources for
              ongoing learning.
            </li>
            <li>
              <strong>Learning Continuity Plans:</strong> Frameworks are
              established to integrate ongoing training into organizational
              workflows. This includes scheduling regular refresher courses,
              developing new learning modules as needs evolve, and integrating
              capacity-building goals into performance reviews.
            </li>
            <li>
              <strong>Mentorship and Peer Learning Programs:</strong> Seismic
              encourages organizations to create internal mentorship networks
              where experienced employees guide and support newer team members.
              This peer-learning approach fosters collaboration and ensures that
              institutional knowledge is passed down effectively. Digital
              Learning Platforms: By leveraging technology, Seismic helps
              organizations set up e-learning platforms that offer on-demand
              access to training modules, assessments, and development
              resources. These platforms enhance knowledge retention and allow
              employees to learn at their own pace.
            </li>
            <li>
              <strong>Train-the-Trainer Models:</strong>To sustain learning
              momentum, Seismic equips select employees with the skills and
              tools needed to become internal trainers. These individuals act as
              champions of capacity building, ensuring that knowledge
              dissemination continues even after the initial training phase.
            </li>
          </ul>
        </div>

        {/* Empowering Organizations */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Empowering Organizations to Sustain and Scale Their Initiatives
            Independently
          </h2>
          <h3 className="text-lg font-semibold text-gray-600 mb-4">
            Seismic prioritizes building organizational self-reliance by
            empowering teams with the tools, strategies, and confidence needed
            to independently manage and expand their capacity-building efforts.
            This involves:
          </h3>
          <ul className="list-disc pl-5 space-y-4 text-gray-600">
            <li>
              <strong>Institutionalizing Best Practices:</strong> Seismic works
              with organizations to embed proven practices and processes into
              their standard operations. This institutionalization ensures that
              training outcomes are not lost over time but become integral to
              the organization’s functioning.
            </li>
            <li>
              <strong>Capacity-Building Roadmaps:</strong> Customized roadmaps
              are developed to guide organizations in scaling their
              capacity-building efforts. These roadmaps outline clear steps,
              timelines, and milestones for achieving long-term goals, including
              workforce development and leadership succession planning.
            </li>
            <li>
              <strong>Scalability Strategies:</strong> Seismic provides
              organizations with strategies for expanding their initiatives to
              reach more employees, departments, or regions. This includes
              designing modular training programs, replicable frameworks, and
              scalable delivery methods.
            </li>
            <li>
              <strong>Resource Mobilization Training:</strong> Organizations are
              trained in securing the necessary resources—financial, human, and
              technological—to sustain their initiatives. Seismic offers
              guidance on grant applications, budgeting, and forming strategic
              partnerships to support long-term goals.
            </li>
            <li>
              <strong>Cultural Transformation:</strong> Seismic emphasizes the
              importance of fostering a learning culture within organizations.
              This involves aligning leadership, policies, and incentives to
              prioritize employee development and continuous improvement.
            </li>
          </ul>
        </div>

        {/*  Key Tools and Strategies for Sustainability */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Key Tools and Strategies for Sustainability
          </h2>
          <h3 className="text-lg font-medium text-gray-600 mb-2">
            Seismic employs a variety of tools and strategies to ensure that
            organizations are equipped for independent and sustainable capacity
            building:
          </h3>
          <ul className="list-disc pl-5 space-y-4 text-gray-600 mt-4">
            <li>
              <strong>Actionable Frameworks: </strong> Simple yet effective
              frameworks, such as competency matrices and skill gap analysis
              tools, enable organizations to monitor and address their evolving
              training needs.
            </li>
            <li>
              <strong>Feedback Integration: </strong>Continuous feedback from
              employees and stakeholders is incorporated into the sustainability
              plan to ensure relevance and adaptability over time.
            </li>
            <li>
              <strong> Technology-Enabled Scalability:</strong> Seismic helps
              organizations harness technology—such as AI-driven learning
              platforms and data analytics—to predict future training needs,
              evaluate outcomes, and improve efficiency.
            </li>
            <li>
              <strong> Collaborative Networks: </strong>Seismic encourages
              organizations to establish partnerships with other institutions,
              enabling resource-sharing and collective learning opportunities.
            </li>
          </ul>
        </div>

        {/* Monitoring Sustainability */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Monitoring Sustainability and Measuring Success
          </h2>
          <h3 className="text-lg font-medium text-gray-600 mb-2">
            Even after completing training programs, Seismic remains committed
            to helping organizations monitor the sustainability of their
            initiatives. This involves:
          </h3>
          <ul className="list-disc pl-5 space-y-4 text-gray-600 mt-4">
            <li>
              <strong>Periodic Assessments:</strong> Seismic conducts follow-up
              evaluations to measure the effectiveness of sustainability
              strategies and identify areas for improvement.
            </li>
            <li>
              <strong>Benchmarking Progress:</strong> Organizations are
              supported in comparing their growth against industry standards and
              internal benchmarks, ensuring continuous alignment with long-term
              objectives.
            </li>
            <li>
              <strong>Success Metrics:</strong> Metrics such as employee
              retention, productivity improvements, leadership development, and
              program scalability are tracked to evaluate the overall impact of
              sustainability planning.
            </li>
          </ul>
        </div>

        {/* Final Overview Section */}
        <div className="bg-white py-12">
          <hr className="border-gray-300" />
          <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center gap-8 py-8 px-6 lg:px-12">
            <div className="lg:w-6/12 w-full">
              <img
                src="https://via.placeholder.com/600x400"
                alt="Sustainability Planning"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="lg:w-6/12 w-full">
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                Planning for Long-Term Success
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Sustainability Planning is a cornerstone of Seismic’s
                methodology, ensuring that the capacity-building programs it
                delivers leave a lasting legacy. By equipping organizations with
                the tools, frameworks, and strategies for ongoing learning and
                independent scalability, Seismic empowers its clients to achieve
                transformative growth and resilience. This approach not only
                enhances the immediate effectiveness of training initiatives but
                also establishes a foundation for enduring success and
                innovation.
              </p>
            </div>
          </div>
          <hr className="border-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default SustainabilityPlanning;

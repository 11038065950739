import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../constants/paths';

const FMIS = () => {
  return (
    <div className="w-full bg-white">
{/* Hero Section */}
<section
  className="relative w-full h-[450px] sm:h-[550px] bg-gray-800 text-white bg-cover bg-center mt-20"
  style={{ backgroundImage: "url('/PagesImages/fmis.jpg')" }} // Replace with the correct FMIS hero image path
>
  <div className="absolute inset-0 bg-black bg-opacity-30"></div>

  {/*Title Overlay for Large Screens */}
  <div
    className="hidden lg:block absolute bottom-[-60px] left-1/2 transform -translate-x-1/2 bg-white p-8 max-w-3xl text-center z-20"
  >
    <h1 className="text-3xl font-bold text-gray-800 mt-4">
    Financial Management Information Systems (FMIS)
    </h1>
  </div>
</section>


      {/* Foreword Section */}
      <section className="max-w-4xl mx-auto py-16 px-6 lg:px-0">
                {/* Title Below Image for Small Screens */}
                <div className="block lg:hidden mt-4 mb-4 text-left">
          <h1 className="text-2xl font-bold text-gray-800">
          Financial Management Information Systems (FMIS)
          </h1>
        </div>
        <p className="text-lg text-gray-700 mb-8 mt-12">
          At Seismic, we are at the forefront of transforming public financial
          management through the design and implementation of cutting-edge
          Financial Management Information Systems (FMIS). As a global leader,
          we specialize in delivering FMIS solutions that modernize and enhance
          public management processes. Our systems are designed to streamline
          budgeting, enable real-time tracking of expenditures, optimize
          resource allocation, and forecast revenues with unmatched accuracy and
          efficiency.
        </p>
        <div className="flex flex-col lg:flex-row items-start gap-8 mt-20">
          <img
            src="/PagesImages/fmis2.jpg"
            alt="FMIS Innovation"
            className="w-full lg:w-5/12 object-cover"
          />
          <p className="text-lg text-gray-700 lg:w-7/12 mt-4">
            With Seismic's FMIS, we drive innovation in public financial
            management, empowering governments and institutions to achieve
            fiscal sustainability, improve service delivery, and enhance
            governance outcomes.
          </p>
        </div>
      </section>

      {/* Our Approach Section - Adjusted Font Style */}
      <section className="py-16">
        <div className="max-w-4xl mx-auto px-6 lg:px-0">
          <h2 className="text-3xl font-semibold mb-8">Our Approach</h2>
          <ul className="space-y-8">
            {[
              {
                title: "Streamlining Budgeting Processes",
                description:
                  "Our FMIS solutions simplify and standardize the budgeting process across governmental and institutional frameworks, ensuring that budgets are prepared, reviewed, and approved with greater ease. This enables timely adjustments and adherence to fiscal discipline.",
              },
              {
                title: "Real-Time Tracking and Transparency",
                description:
                  "Seismic's FMIS enhances the capacity to track expenditures in real time, providing immediate insights into spending patterns and variances from planned budgets, promoting transparency and accountability at every government level.",
              },
              {
                title: "Revenue Forecasting and Resource Optimization",
                description:
                  "Our FMIS solutions are uniquely positioned to forecast revenues effectively, ensuring that resource allocation aligns with revenue projections and optimizes expenditures.",
              },
              {
                title: "Global Reach, Local Impact",
                description:
                  "Seismic has successfully implemented FMIS across diverse regions, ensuring adaptability to local governance structures, regulations, and financial environments.",
              },
              {
                title: "Driving Accountability and Efficiency",
                description:
                  "Our FMIS solutions serve as a cornerstone for PFM reforms, promoting efficiency, reducing fiscal deficits, and enhancing transparency and accountability in public finance.",
              },
            ].map((approach, index) => (
              <li key={index} className="flex gap-4">
                <span className="text-purple-900 text-2xl">▶</span>
                <div className="text-lg font-semibold">
                  {approach.title}: <span className="font-normal text-gray-700">{approach.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

 {/* Other Services Section */}
<section className="py-16">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-gray-800 mb-8">Other Services</h2>
    <p className="text-xl text-gray-600 mb-8">
      Helping clients bridge the fundamental gaps in the education system
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[
        {
          title: "Audit and Financial Control Systems",
          description:
            "As a global leader in Public Financial Management, Seismic offers a comprehensive suite of audit and financial control solutions that strengthen transparency, accountability, and governance within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.afcs}`,
        },
        {
          title: "Capacity Building and Training",
          description:
            "Our training initiatives emphasize collaboration and peer learning, encouraging participants to share experiences, insights, and solutions, fostering continuous learning within public institutions.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cbt}`,
        },
        {
          title: "Capital Investment Planning",
          description:
            "We help governments and institutions develop strategic capital investment plans aligned with national development goals, promoting economic growth and resource optimization.",
          link: `/${paths.consultingServices}/${paths.pfm}/${paths.cip}`,
        },
      ].map((service, index) => (
        <Link
          to={service.link}
          key={index}
          className="block bg-white p-6 shadow-md rounded-md text-left max-w-[450px] mx-auto"
        >
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            {service.title}
          </h3>
          <p className="text-lg text-gray-600 mb-4">
            {service.description}
          </p>
        </Link>
      ))}
    </div>
  </div>
</section>

    </div>
  );
};

export default FMIS;

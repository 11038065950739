import React, { useState, useEffect } from 'react';

const projects = [
  {
    description: "World Bank State Education Programme Investment Project (SEPIP) Project Conducted Public Expenditure Tracking to enhance education funding transparency in Nigeria.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "World Bank",
    achievementLink: "#",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738160775/WhatsApp_Image_2025-01-28_at_13.13.44_z6folg.jpg"
  },
  {
    description: "World Bank Nigeria Partnership for Education Project (NIPEP) Projects Conducted Public Expenditure Tracking to enhance education funding transparency in Nigeria.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "World Bank",
    achievementLink: "#",
    image: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738160881/WhatsApp_Image_2025-01-28_at_13.13.45_2_mesbvb.jpg"
  },
  {
    description: "EU Support to Federal Governance Reform Programme (EU-SUFEGOR) Supported governance reforms to strengthen the Federal Government's capacity for financial management.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745703/european-union-logo_m5mrci.avif",
    clientName: "EU",
    achievementLink: "#",
    image: "/PagesImages/track-record-3.png"
  },
  {
    description: "State and Local Governance Reform (SLOGOR) Improved governance and fiscal responsibility at state and local levels, driving accountability and resource management.",
    clientImage: "https://res.cloudinary.com/dxhx45ing/image/upload/v1738745711/world_bank_u390bp.png",
    clientName: "World Bank",
    achievementLink: "#",
    image: "/PagesImages/track-record-4.jpg"
  }
];

const PFMTrackRecord = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  // Auto-cycle through slides
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    }, 5000); // Adjust duration for slide change

    return () => clearInterval(interval);
  }, []);

  const currentProject = projects[currentProjectIndex];

  return (
    <section className="py-16 px-4 lg:px-2">
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-start gap-8">
        
        {/* Image on the Left */}
        <div className="lg:w-6/12 w-full transition-opacity duration-1000 opacity-100">
          <img
            src={currentProject.image}
            alt="Track Record"
            className="w-full h-full object-cover shadow-lg"
            style={{ height: '450px' }}
          />
        </div>

        {/* Title Section Above the Project Card */}
        <div className="lg:w-6/12 w-full flex flex-col">
          <div className="mb-4 pt-4 mt-4">
            <h2 className="text-3xl font-medium text-gray-800 mb-2">Our Track Record</h2>
            <p className="text-lg text-gray-600 mb-3">
              Delivering impactful results in collaboration with key global and regional partners
            </p>
          </div>

          {/* "Project" Label Positioned Above the Card */}
          <div className="text-left mb-2">
            <span className="text-gray-600 font-medium text-lg">Project</span>
          </div>

          {/* Project Details Card with Pink Border */}
          <div 
  className="border border-[#F2E6F2] p-6 relative"
  style={{ borderTopWidth: '8px', height: 'auto', minHeight: '250px' }}
>
  <p className="text-lg text-gray-600 mb-4 break-words">
    {currentProject.description}
  </p>
  <div className="flex items-center mb-4">
    <span className="text-gray-600 font-semibold mr-2">Client:</span>
    <img 
      src={currentProject.clientImage} 
      alt={currentProject.clientName} 
      className="h-8 max-w-full" 
    />
  </div>
  {/* <a
    href={currentProject.achievementLink}
    className="text-purple-900 font-semibold inline-flex items-center md:mt-0"
    style={{
      wordWrap: "break-word", // Ensures the link text wraps properly
    }}
  >
    View Achievement
    <svg
      className="w-5 h-5 ml-2"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
    </svg>
  </a> */}
</div>
        </div>
      </div>

      {/* Slide Navigation Dots */}
      <div className="flex justify-center items-center gap-3 mt-6">
        {projects.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentProjectIndex(index)}
            className={`transition-all duration-300 ${
              index === currentProjectIndex ? 'bg-purple-800 w-8 h-3 rounded-lg' : 'bg-purple-300 w-4 h-4 rounded-full'
            }`}
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>

    </section>
  );
};

export default PFMTrackRecord;
